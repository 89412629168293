import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
} from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link, useNavigate } from "react-router-dom";
import BtnWithIcon from "../../UI/Btn/BtnWithIcon";
import Btn from "../../UI/Btn/Btn";
import Modal from "../../UI/Modal/Modal";
import Input from "../../UI/Input/Input";
import Line from "../../UI/Line/Line";
import PageHeader from "../../UI/PageHeader/PageHeader";
import SubscriptionCard from "../../UI/SubscriptionCard/SubscriptionCard";
import { formatNumber, responseMessage } from "../../../utility";
import {
    ALERT_MESSAGES,
    EVENTS,
    PAYMENT,
    packagePrices,
    packages,
} from "../../../config/variables";
import { endLoading, get, post } from "../../../config/fetch";
import * as actions from "../../../store/actions/index";
import Chbx from "../../UI/Checkbox/Checkbox";
import { basic, noSub, optimum, premium } from "../../../config/subscriptions";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./SubscriptionAndServiceModal.module.css";

const renderServiceSummaryName = (service) => {
    switch (service) {
        case "HOTLINE":
            return "A hotline hívásának";
        case "DUTY":
            return "Az orvosi ügyelet hívásának";
        case "CHAT":
            return "Az orvosi chat";
        case "SECOND_OPINION":
            return "Az orvosi másodvélemény";
        case "PRESCRIPTION":
            return "E-recept";
        default:
            return "";
    }
};

const renderFreeServiceText = (service) => {
    switch (service) {
        case "HOTLINE":
        case "DUTY":
            return "díjmentes hívás";
        case "PRESCRIPTION":
        case "CHAT":
        case "SECOND_OPINION":
            return "díjmentes üzenet";
        default:
            return "";
    }
};

const renderServiceInitText = (service) => {
    switch (service) {
        case "HOTLINE":
            return "A gombra kattintva tárcsázhatja a hotline-t, rendszerünk a számlájáról pedig automatikusan levonja a megjelölt összeget";
        case "DUTY":
            return "A gombra kattintva tárcsázhatja az ügyeletet, rendszerünk a számlájáról pedig automatikusan levonja a megjelölt összeget";
        case "PRESCRIPTION":
        case "CHAT":
        case "SECOND_OPINION":
            return "A gombra kattintva rendszerünk kézbesíti az üzeneted a címzettnek, a számládról pedig automatikusan levonja a megjelölt összeget";
        default:
            return "";
    }
};

const SubscriptionAndServiceModal = (props) => {
    const navigate = useNavigate();
    const [selectedPackage, setSelectedPackage] = useState(props.subscription);
    const [userPayment, setUserPayment] = useState(null);
    const [termsAccepted, toggleTerms] = useState(false);
    const [terms2Accepted, toggleTerms2] = useState(false);
    const couponRef = useRef();
    const [userEligibleTrial, setUserEligibleTrial] = useState(false);
    const [openSubscriptions, toggleSubscriptions] = useState(true);
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        if (!props.open && !props.ignoreSubscription) {
            setSelectedPackage(props.subscription);
        }
    }, [props.ignoreSubscription, props.open, props.subscription]);

    useEffect(() => {
        if (selectedPackage != props.outerSelectedPackage) {
            setSelectedPackage(props.outerSelectedPackage);
        }
    }, [selectedPackage, props.outerSelectedPackage]);

    const getPrices = useCallback(() => {
        post("api/v1/subscription/check-user-service-price", {
            service: props.data.service,
            packageId: selectedPackage,
            appointmentId: props.data.appointmentId || null,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        setUserPayment(response.data.data);

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, [props.data.appointmentId, props.data.service, selectedPackage]);

    useEffect(() => {
        if (props.open === 1) {
            get("api/v1/user/is-trial-eligible", false).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setUserEligibleTrial(response.data.eligible);

                        break;
                }
            });
        }
        if (props.open === 2) {
            setUserPayment(false);
            toggleTerms(false);
            toggleTerms2(false);
            getPrices();
        }
    }, [props.open, getPrices]);

    const selectButtonHandler = (pckg) => {
        if (pckg >= props.subscription) {
            setSelectedPackage(pckg);
            if (props.selectCallback) {
                props.selectCallback(pckg);
            }
        }
    };

    const redeemCoupon = () => {
        const code = couponRef.current.value;
        if (code) {
            post("api/v1/coupon/redeem-coupon", { couponCode: code })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            couponRef.current.value = "";
                            responseMessage(
                                "A kupon beváltása megtörtént!",
                                "Sikeres beváltás!"
                            );
                            redeemedCouponHandler();

                            break;
                        case "WRONG_CODE":
                            responseMessage("Hibás kuponkód!");

                            break;
                        case "ALREADY_REDEEMED":
                            responseMessage(
                                "Ezt a kupont már beváltottad egyszer!"
                            );

                            break;
                        case "ONLY_WITHOUT_SUBSCRIPTION":
                            responseMessage(
                                "Ezt a kupont csak előfizetés nélkül lehet beváltani!"
                            );

                            break;
                        case "ONLY_SUBSCRIPTION_COUPON_ACCEPTED":
                            responseMessage(
                                "Csak előfizetésre vonatkozó kupont válthatsz be!"
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const redeemedCouponHandler = () => getPrices();

    const close = () => {
        if (props.closeModal) {
            props.closeModal();
        } else {
            props.toggleSubscriptionAndServiceModal(false);
        }
    };

    const renderBasicButton = () => {
        if (selectedPackage === 2) {
            return (
                <BtnWithIcon selected onClick={() => selectButtonHandler(2)} />
            );
        }
        if (userEligibleTrial) {
            return (
                <Btn
                    color="yellow"
                    text="Kipróbálás"
                    onClick={() => selectButtonHandler(2)}
                />
            );
        }

        return false;
    };

    const initService = () => {
        if (props.subscription === selectedPackage) {
            props.normalInit();
        } else {
            let formData = new FormData();
            let sendObject;
            if (
                (props.data.service === "CHAT" ||
                    props.data.service === "SECOND_OPINION" ||
                    props.data.service === "PRESCRIPTION") &&
                !props.chatIdentifier
            ) {
                let chatData = props.getChatData();
                for (let key in chatData) {
                    if (key !== "files") {
                        formData.append(key, chatData[key]);
                    }
                }
                if (chatData.files.length > 0) {
                    let i = 0;
                    for (let f of chatData.files) {
                        formData.append(`file${i++}`, f);
                    }
                }
                formData.append("packageId", selectedPackage);
                sendObject = formData;
            } else {
                sendObject = {
                    service: props.data.service,
                    packageId: selectedPackage,
                    identifier: props.chatIdentifier,
                    inChat: !!props.chatIdentifier,
                };
            }

            post("api/v1/payment/init-subscribe-and-service", sendObject)
                .then((response) => {
                    endLoading();
                    props.toggleResponseModal(false);
                    switch (response.data.responseCode) {
                        case "OK":
                            props.succesfulSubscribeAndServiceCallback();

                            break;
                        case "EMPTY_DEFAULT_CARD": {
                            const serviceText = `${
                                EVENTS[props.data.service]
                            } és ${packages[selectedPackage]} előfizetés`;
                            props.toggleServicePaymentModal(
                                true,
                                userPayment.summaryAmount,
                                props.data.service,
                                null,
                                response.data.identifier,
                                selectedPackage,
                                serviceText
                            );

                            break;
                        }
                        case "MISSING_DATA":
                            responseMessage(
                                "Hiányzó számlázási adatok! Kérjük, először számlázási adataid add meg.",
                                null,
                                () => navigate(i18nRoute("routes.profile.user"))
                            );

                            break;
                        case "MISSING_PERSONAL_DATA":
                            responseMessage(
                                "Hiányzó személyes adatok! Kérjük, először személyes adataid add meg.",
                                null,
                                () => navigate(i18nRoute("routes.profile.user"))
                            );

                            break;
                        case "MISSING_MESSAGE":
                            responseMessage(
                                "Az üzenet mező üresen maradt! Néhány mondatban foglald össze kérdésed orvosunknak, hogy mihamarabb segíteni tudjon."
                            );

                            break;
                        case "PAYMENT_FAILED":
                            responseMessage(PAYMENT.PAYMENT_FAILED);

                            break;
                        case "INVALID_FILE_QTY":
                            responseMessage(
                                "Figyelem! Csak 1 dokumentum feltöltése lehetséges."
                            );

                            break;
                        case "INVALID_FILE_SIZE":
                            responseMessage(
                                "Figyelem! A dokumentum mérete maximum 10MB lehet."
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                    endLoading();
                });
        }
    };

    const isDisabledButton = () => {
        if (userPayment.summaryAmount > 0) {
            if (termsAccepted && terms2Accepted) {
                return false;
            }
        } else {
            if (termsAccepted) {
                return false;
            }
        }

        return true;
    };

    const cancelClick = () => {
        props.selectCallback(props.subscription);
        props.toggleSubscriptionAndServiceModal(0);
    };

    const renderPrice = () => {
        if (props.data.servicePrices.length > 0) {
            if (
                typeof props.data.servicePrices[selectedPackage] === "object" &&
                props.data.servicePrices[selectedPackage].length > 0
            ) {
                if (
                    props.data.freeOccasions &&
                    props.data.freeOccasions.length > 0 &&
                    props.data.freeOccasions[selectedPackage] > 0 &&
                    props.data.servicePrices[selectedPackage][0] !== 0 &&
                    props.data.servicePrices[selectedPackage][1] !== 0
                ) {
                    return `${
                        props.data.freeOccasions[selectedPackage]
                    } ingyenes, utána ${formatNumber(
                        props.data.servicePrices[selectedPackage][0] || 0
                    )} / ${formatNumber(
                        props.data.servicePrices[selectedPackage][1] || 0
                    )} Ft`;
                } else if (
                    props.data.servicePrices[selectedPackage][0] === 0 &&
                    props.data.servicePrices[selectedPackage][1] === 0
                ) {
                    return "Díjmentes";
                }

                return `${formatNumber(
                    props.data.servicePrices[selectedPackage][0] || 0
                )} / ${formatNumber(
                    props.data.servicePrices[selectedPackage][1] || 0
                )} Ft`;
            }
            /*
            if (
                props.data.freeOccasions &&
                props.data.freeOccasions.length > 0 &&
                props.data.freeOccasions[selectedPackage] > 0
            ) {
                return `${
                    props.data.freeOccasions[selectedPackage]
                } ingyenes, utána ${formatNumber(
                    props.data.servicePrices[selectedPackage] || 0
                )} Ft`;
            }
            */
            // Free occasions, if 1 it is "Korlátlan"
            if (
                props.data.freeOccasions &&
                props.data.freeOccasions.length > 0 &&
                props.data.freeOccasions[selectedPackage] > 0
            ) {
                return `Korlátlan`;
            }

            return `${formatNumber(
                props.data.servicePrices[selectedPackage] || 0
            )} Ft`;
        }
    };

    return (
        <>
            <Modal
                open={props.open === 1}
                key="SubscriptionAndServiceModal1"
                closeModal={close}
                fullWidth
                desktopCloseIcon
                dialogClass={classes.modalBody}
            >
                <div
                    className={`${classes.subscriptionsContainer} ${
                        !openSubscriptions && classes.closed
                    }`}
                >
                    <PageHeader
                        text="Előfizetések"
                        style={{ marginBottom: "2rem" }}
                    />
                    <div className={classes.subscriptions}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <SubscriptionCard
                                    open={props.subscriptionDetails}
                                    openClass={classes.card}
                                    buttonComponent={
                                        selectedPackage === 1 && (
                                            <BtnWithIcon
                                                selected
                                                onClick={() =>
                                                    selectButtonHandler(1)
                                                }
                                            />
                                        )
                                    }
                                    onButtonClick={() => selectButtonHandler(1)}
                                    buttonText="Kiválasztás"
                                    name="Vendég"
                                    price={packagePrices[1]}
                                    buttonDisabled={props.subscription > 1}
                                    data={noSub}
                                ></SubscriptionCard>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SubscriptionCard
                                    open={props.subscriptionDetails}
                                    openClass={classes.card}
                                    buttonComponent={renderBasicButton()}
                                    onButtonClick={() => selectButtonHandler(2)}
                                    buttonText="Kiválasztás"
                                    name="Basic"
                                    yellowText={
                                        userEligibleTrial
                                            ? "30 napig ingyenes"
                                            : false
                                    }
                                    hasTrialPeriod={userEligibleTrial}
                                    price={packagePrices[2]}
                                    buttonDisabled={props.subscription > 2}
                                    data={basic}
                                ></SubscriptionCard>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SubscriptionCard
                                    openClass={classes.card}
                                    buttonComponent={
                                        selectedPackage === 3 && (
                                            <BtnWithIcon
                                                selected
                                                onClick={() =>
                                                    selectButtonHandler(3)
                                                }
                                            />
                                        )
                                    }
                                    onButtonClick={() => selectButtonHandler(3)}
                                    buttonText="Kiválasztás"
                                    name="Optimum"
                                    open={props.subscriptionDetails}
                                    greenText="Ajánlott"
                                    price={packagePrices[3]}
                                    buttonDisabled={props.subscription > 3}
                                    data={optimum}
                                ></SubscriptionCard>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SubscriptionCard
                                    openClass={classes.card}
                                    name="Premium"
                                    buttonText="Kiválasztás"
                                    open={props.subscriptionDetails}
                                    buttonComponent={
                                        selectedPackage === 4 && (
                                            <BtnWithIcon
                                                selected
                                                onClick={() =>
                                                    selectButtonHandler(4)
                                                }
                                            />
                                        )
                                    }
                                    onButtonClick={() => selectButtonHandler(4)}
                                    price={packagePrices[4]}
                                    data={premium}
                                ></SubscriptionCard>
                            </Grid>
                        </Grid>
                    </div>
                    {!openSubscriptions && (
                        <div
                            className={classes.overlay}
                            onClick={() => toggleSubscriptions(true)}
                        >
                            <p>Előfizetések megjelenítése</p>
                        </div>
                    )}
                </div>
                <div className={classes.summary}>
                    <p>
                        {renderServiceSummaryName(props.data.service)} díja a
                        kiválasztott előfizetéssel:
                    </p>
                    <p className={classes.price}>{renderPrice()}</p>
                    <p className={classes.infoText}>
                        {" "}
                        A megadott árak tájékoztató jellegűek, kupon, egyéb
                        kedvezmény és korábbi előfizetés esetén eltérhet.
                    </p>
                    <BtnWithIcon
                        color="yellow"
                        text="Megerősítem"
                        className={classes.confirmButton}
                        onClick={() =>
                            props.toggleSubscriptionAndServiceModal(0)
                        }
                    />
                    <p className={`${classes.link} link`} onClick={cancelClick}>
                        Lépés kihagyása
                    </p>
                </div>
            </Modal>
            <Modal
                open={props.open === 2}
                key="SubscriptionAndServiceModal2"
                closeModal={close}
                fullWidth
                desktopCloseIcon
                closeContainerDesktopStyle={styles.paymentClose}
                dialogClass={classes.modalBody}
            >
                <div className={classes.payment}>
                    <PageHeader text="Fizetés" style={{ marginTop: 0 }} />
                    <div className={classes.inner}>
                        {userPayment && (
                            <p className={classes.itemContainer}>
                                <span className={classes.quantity}>1 db</span>
                                <span className={classes.item}>
                                    <span className={classes.itemName}>
                                        {EVENTS[props.data.service]}:{" "}
                                    </span>
                                    <span className={classes.itemPrice}>
                                        {userPayment.extraService ||
                                        userPayment.includedInPackage ||
                                        userPayment.otherDiscount
                                            ? 0
                                            : formatNumber(
                                                  userPayment.originalPrice
                                              )}{" "}
                                        Ft
                                    </span>
                                    <br />
                                    {(userPayment.normalPrice ||
                                        userPayment.extraService ||
                                        userPayment.includedInPackage ||
                                        userPayment.otherDiscount) && (
                                        <span className={classes.itemInfo}>
                                            Előfizetésben foglalt{" "}
                                            {renderFreeServiceText(
                                                props.data.service
                                            )}
                                        </span>
                                    )}
                                </span>
                            </p>
                        )}
                        {userPayment?.coupon?.couponCode && (
                            <p className={classes.itemContainer}>
                                <span className={classes.quantity}>1 db</span>
                                <span className={classes.item}>
                                    <span className={classes.itemName}>
                                        Kupon kedvezmény (
                                        {userPayment.coupon.couponCode}):{" "}
                                    </span>
                                    <span className={classes.itemPrice}>
                                        -
                                        {formatNumber(
                                            userPayment.coupon.discount
                                        )}{" "}
                                        Ft
                                    </span>{" "}
                                    <br />
                                </span>
                            </p>
                        )}
                        {userPayment &&
                            (userPayment.desiredPackagePrice ||
                                userPayment.desiredPackagePrice === 0) && (
                                <p className={classes.itemContainer}>
                                    <span className={classes.quantity}>
                                        1 db
                                    </span>
                                    <span className={classes.item}>
                                        <span className={classes.itemName}>
                                            {packages[
                                                selectedPackage
                                            ].toUpperCase() || ""}{" "}
                                            Előfizetés:
                                        </span>{" "}
                                        <span className={classes.itemPrice}>
                                            {formatNumber(
                                                userPayment.desiredPackagePriceWithoutCoupon
                                            )}{" "}
                                            Ft / hó
                                            <br />
                                            {props.subscription > 1 && (
                                                <span
                                                    className={classes.itemInfo}
                                                >
                                                    Jelenlegi előfizetés és a
                                                    kívánt előfizetés
                                                    különbözete
                                                </span>
                                            )}
                                        </span>
                                    </span>
                                </p>
                            )}
                        {userPayment?.subscriptionCoupon && (
                            <p className={classes.itemContainer}>
                                <span className={classes.quantity}>1 db</span>
                                <span className={classes.item}>
                                    <span className={classes.itemName}>
                                        Kupon kedvezmény előfizetésre (
                                        {
                                            userPayment.subscriptionCoupon
                                                .couponCode
                                        }
                                        ):{" "}
                                    </span>
                                    <span className={classes.itemPrice}>
                                        -
                                        {formatNumber(
                                            userPayment.subscriptionCoupon
                                                .discount
                                        )}{" "}
                                        Ft
                                    </span>{" "}
                                    <br />
                                </span>
                            </p>
                        )}
                    </div>
                    <Line className={classes.line} />
                    <div className={classes.inner}>
                        <Chbx
                            onChange={(checked) => toggleTerms(checked)}
                            label={
                                <p>
                                    Elolvastam és elfogadom az{" "}
                                    <Link
                                        className="link"
                                        to={"/adatvedelmi_nyilatkozat"}
                                        target="_blank"
                                    >
                                        általános adatkezelési irányelveket
                                    </Link>{" "}
                                    és a{" "}
                                    <Link
                                        className="link"
                                        to={i18nRoute("routes.terms")}
                                        target="_blank"
                                    >
                                        felhasználási feltételeket.
                                    </Link>
                                </p>
                            }
                        />
                        {userPayment && userPayment.summaryAmount > 0 && (
                            <Chbx
                                onChange={(checked) => toggleTerms2(checked)}
                                label={
                                    <p>
                                        Elfogadom, hogy a T-DOC Service Kft.
                                        által a www.t-doc.hu felhasználói
                                        adatbázisában tárolt alábbi személyes
                                        adataim átadásra kerüljenek a BIG FISH
                                        Payment Services Kft, mint
                                        adatfeldolgozó részére.
                                    </p>
                                }
                            />
                        )}
                    </div>
                    <div className={classes.inner}>
                        <Accordion
                            square={true}
                            classes={{
                                root: classes.accordion,
                                // expanded: classes.accordionExpanded,
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon style={styles.chevron} />
                                }
                            >
                                <div className={classes.accordionInner}>
                                    Kupon beváltása
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.accordionDetailsInner}>
                                    <div className={classes.inputContainer}>
                                        <p className={classes.label}>
                                            Kuponkód
                                        </p>
                                        <Input
                                            bigger
                                            className={classes.input}
                                            type="email"
                                            ref={couponRef}
                                        />
                                    </div>
                                    <Btn
                                        color="green"
                                        text="Beváltás"
                                        className={classes.redeemButton}
                                        onClick={redeemCoupon}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                {userPayment && (
                    <div className={classes.summary}>
                        <p>Fizetendő:</p>
                        <p className={classes.price}>
                            {formatNumber(userPayment.summaryAmount)} Ft
                        </p>
                        <p className={classes.subscriptionInitText}>
                            {renderServiceInitText(props.data.service)}
                        </p>
                        <BtnWithIcon
                            color="yellow"
                            text="Fizetés"
                            disabled={isDisabledButton()}
                            className={classes.confirmButton}
                            onClick={initService}
                        />
                    </div>
                )}
            </Modal>
        </>
    );
};

const styles = {
    paymentClose: {
        backgroundColor: "#F8F8F8",
    },
};

const mapStateToProps = (state) => ({
    // open: state.ui.showSubscriptionAndServiceModal,
    // data: state.ui.subscriptionAndServiceModal,
    subscription: state.user.subscription,
    subscriptionDetails: state.ui.subscriptionDetails,
});

const mapDispatchToProps = (dispatch) => ({
    toggleServicePaymentModal: (
        show,
        price,
        service,
        preScript,
        identifier,
        packageId,
        serviceText
    ) =>
        dispatch(
            actions.toggleServicePaymentModal(
                show,
                price,
                service,
                preScript,
                identifier,
                packageId,
                serviceText
            )
        ),
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
    // toggleSubscriptionAndServiceModal: show => dispatch(actions.toggleSubscriptionAndServiceModal(show))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriptionAndServiceModal);
