import { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../UI/PageHeader/PageHeader";
import Line from "../../UI/Line/Line";
import { Icons } from "../../../assets/Theme";
import Qualifications from "../../UI/Qualifications/Qualifications";
import { removeAccents } from "../../../utility";
import { get } from "../../../config/fetch";
import Modal from "../../UI/Modal/Modal";
import * as actions from "../../../store/actions/index";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./QualificationsModal.module.css";

let originalQualifications = [];
const isMobile = window.innerWidth <= 959;

const QualificationsModal = (props) => {
    const navigate = useNavigate();
    const [qualifications, setQualifications] = useState(
        originalQualifications
    );
    const [qualificationsLoaded, setQualificationsLoaded] = useState(false);
    const { i18nRoute } = useTranslation();

    const formatText = (text) => removeAccents(text).toLowerCase().trim();

    const onSearch = (text) => {
        if (text.length > 2) {
            let searchValue = formatText(text);
            let matches = [];
            for (let q of originalQualifications) {
                if (formatText(q.name).indexOf(searchValue) > -1) {
                    matches.push(q);
                }
            }
            setQualifications([...matches]);
        } else {
            setQualifications([...originalQualifications]);
        }
    };

    const sortQualifications = (qualifications) =>
        qualifications.sort((a, b) => a.name.localeCompare(b.name, "hu"));

    useEffect(() => {
        if (props.open) {
            originalQualifications = [];
            setQualifications([]);
            setQualificationsLoaded(false);
            get("api/v1/medical/get-qualifications", false)
                .then((response) => {
                    if (response.data.responseCode === "OK") {
                        const sortedQualifications = sortQualifications(
                            response.data.data
                        );
                        originalQualifications = [...sortedQualifications];
                        setQualifications(sortedQualifications);
                        setQualificationsLoaded(true);
                    }
                })
                .catch((error) => console.error(error));
        }
    }, [props.open]);

    const onQualificationClick = (url) => {
        close();
        navigate(`${i18nRoute("routes.qualifications.base")}/${url}`);
    };

    const close = () => {
        if (props.closeModal) {
            props.closeModal();
        } else {
            props.toggleQualificationsModal(false);
        }
    };

    return (
        <Modal
            open={props.open}
            dialogClass={classes.modalBody}
            closeModal={close}
            paperScrollPaperClass={classes.outer}
            maxWidth="md"
            fullWidth
        >
            <PageHeader
                text="Szakterület választó"
                withClose={!isMobile}
                closeBackground="#fff"
                className={classes.pageHeader}
                closeFunction={close}
            />
            <div className={classes.inner}>
                <div className={classes.inputContainer}>
                    <input
                        type="text"
                        className={classes.input}
                        placeholder="Szakterületek keresése"
                        onChange={(e) => onSearch(e.target.value)}
                    />
                    <img
                        src={Icons.searchGreen}
                        className={classes.searchIcon}
                        alt=""
                    />
                </div>
            </div>
            <Line className={classes.line} />
            <div className={classes.qualifications}>
                {qualificationsLoaded ? (
                    <Qualifications
                        qualifications={qualifications}
                        onQualificationClick={onQualificationClick}
                    />
                ) : (
                    <div className={classes.skeletons}>
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                        <Skeleton
                            animation="wave"
                            height={30}
                            classes={{ root: classes.skeleton }}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleQualificationsModal: (show) =>
        dispatch(actions.toggleQualificationsModal(show)),
});

export default connect(null, mapDispatchToProps)(QualificationsModal);
