import { Box, Container, Grid, Hidden } from "@material-ui/core";
import classes from "../styles.module.css";
import { useResolution } from "../../../ui/utils";
import BtnWithIcon from "../../../components/UI/Btn/BtnWithIcon";
import ChatIconDesktop from "../assets/ChatIconDesktop.svg";
import ChatIconMobile from "../assets/ChatIconMobile.svg";
import { Colors } from "../../../assets/Theme";

interface Props {
    writeMessage: () => void;
}

const PromotionSection = ({ writeMessage }: Props) => {
    const isTablet = useResolution("tablet");

    return (
        <section
            style={{
                backgroundColor: isTablet ? "#F8F8F8" : "white",
                color: Colors.darkBlue,
            }}
        >
            <Container>
                <Box py={isTablet ? 8 : 12}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Hidden mdUp>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    mb={4}
                                >
                                    <img src={ChatIconMobile} alt="Ikon" />
                                </Box>
                            </Hidden>
                            <Box textAlign={isTablet && "center"}>
                                <Box
                                    maxWidth={isTablet ? "100%" : "58rem"}
                                    mb={5}
                                >
                                    <h2
                                        style={{
                                            lineHeight: isTablet
                                                ? "normal"
                                                : "48px",
                                            fontSize: isTablet
                                                ? "26px"
                                                : "36px",
                                        }}
                                    >
                                        Írj üzenetet szakorvosnak most csak 3
                                        490 Ft-ért!
                                    </h2>
                                </Box>
                                <Box
                                    maxWidth={isTablet ? "100%" : "60rem"}
                                    mb={4}
                                >
                                    <h4
                                        style={{
                                            lineHeight: isTablet
                                                ? "normal"
                                                : "48px",
                                            fontSize: isTablet
                                                ? "26px"
                                                : "36px",
                                        }}
                                    >
                                        Már több, mint 15 szakterületen
                                    </h4>
                                </Box>
                            </Box>
                            <Box mb={5} textAlign={isTablet && "center"}>
                                <BtnWithIcon
                                    color="yellow"
                                    text="Üzenetet írok"
                                    className={classes.button}
                                    onClick={writeMessage}
                                />
                            </Box>
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" justifyContent="center">
                                    <img src={ChatIconDesktop} alt="Ikon" />
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Box>
                        <Box mb={3} pt={isTablet ? 0 : 2}>
                            <h5
                                style={{
                                    textTransform: "uppercase",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                }}
                            >
                                Promóció részletei:
                            </h5>
                        </Box>
                        <Box>
                            <p style={{ fontSize: "17px" }}>
                                Promóció részeletei: A szakorvosi chat 3 490
                                Ft-os díja minden olyan felhasználóra érvényes,
                                akik korábban még nem vettek igénybe szakorvosi
                                chat vagy Covid-19 chat szolgáltatást. Akik
                                korábban már igénybe vették orvosi chat vagy
                                Covid-19 chat szolgáltatásunkat, azok számára a
                                chat listaáron/előfizetésébe foglalt áron tudjuk
                                biztosítani. Az első szakorvosi chat
                                felhasználását követően a szakorvosi chat-et
                                minden felhasználó listaáron/előfizetésébe
                                foglalt áron tudja igénybe venni. A szakorvosi
                                chat-et regisztrációt és a szükséges adatok
                                megadását követően tudja igénybevenni. Első
                                igénybevételnek bármely szakterületen első
                                alkalommal megküldött üzenetváltást tekintjük,
                                így az első üzenetváltást nem külön-külön kell
                                értelmezni szakterületenként.
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </section>
    );
};

export default PromotionSection;
