import { useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PageHeader from "../../UI/PageHeader/PageHeader";
import { Icons } from "../../../assets/Theme";
import SubscriptionCard from "../../UI/SubscriptionCard/SubscriptionCard";
import Btn from "../../UI/Btn/Btn";
import Input from "../../UI/Input/Input";
import { endLoading, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import { basic, noSub, optimum, premium } from "../../../config/subscriptions";
import Modal from "../../UI/Modal/Modal";
import BtnWithIcon from "../../UI/Btn/BtnWithIcon";
import {
    formatNumber,
    getPackageName,
    responseMessage,
} from "../../../utility";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./FreeSubscriptionModal.module.css";

const BenefitElement = (props) => (
    <div className={classes.benefit}>
        <div className={classes.greenCircle}>
            <img src={Icons.tickGreen} className={classes.tick} alt="" />
        </div>
        <span className={classes.benefitTitle}>{props.title}</span>
        <span className={classes.benefitText}>{props.text}</span>
    </div>
);

const FreeSubscriptionModal = (props) => {
    const [selectedPackage, setSelectedPackage] = useState(1);
    const [packagePrices, setPackagePrices] = useState([
        null,
        0,
        4990,
        9990,
        29990,
    ]);
    const [couponRedeemed, setCouponRedeemed] = useState(false);
    const couponRef = useRef();
    const { i18nRoute } = useTranslation();

    const selectButtonHandler = (packageId) => {
        props.openUserDataModal();
        props.setUserDataByKey("selectedPackage", packageId);
        props.setUserDataByKey("packagePrice", packagePrices[packageId]);
        setSelectedPackage(packageId);
    };

    const redeemCoupon = () => {
        const code = couponRef.current.value;
        if (code) {
            post("api/v1/coupon/redeem-coupon", { couponCode: code })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage(
                                successfulText(response.data.couponData),
                                "Sikeres beváltás!"
                            );
                            redeemedCouponHandler(response.data.couponData);

                            break;
                        case "WRONG_CODE":
                            responseMessage("Hibás kuponkód!");

                            break;
                        case "ALREADY_REDEEMED":
                            responseMessage(
                                "Ezt a kupont már beváltottad egyszer!"
                            );

                            break;
                        case "ONLY_WITHOUT_SUBSCRIPTION":
                            responseMessage(
                                "Ezt a kupont csak előfizetés nélkül lehet beváltani!"
                            );

                            break;
                        case "ONLY_SUBSCRIPTION_COUPON_ACCEPTED":
                            responseMessage(
                                "Csak előfizetésre vonatkozó kupont válthatsz be!"
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const getDiscount = (coupon) => {
        switch (+coupon.type) {
            case 1:
                return `Ingyenes használat ${coupon.discountIntervalDays} napig`;
            case 2:
                return `Első hónapra ${formatNumber(
                    coupon.discountAmount
                )} Ft kedvezmény`;
            case 3:
                return `Első hónapra ${coupon.discountPercentage}% kedvezmény`;
        }
    };

    const successfulText = (coupon) => (
        <div className={classes.successfulRedeem}>
            <p>
                Csomag: <span>{getPackageName(coupon.packageId)}</span>
            </p>
            <p>
                Kedvezmény: <span>{getDiscount(coupon)}</span>
            </p>
        </div>
    );

    const redeemedCouponHandler = (coupon) => {
        setCouponRedeemed(true);
        props.setUserDataByKey("redeemHash", coupon.redeem_hash);

        setSelectedPackage(+coupon.packageId);
        let prices = [...packagePrices];
        let newPrice;
        switch (+coupon.type) {
            case 1:
                newPrice = 0;

                break;
            case 2:
                newPrice = prices[coupon.packageId] - coupon.discountAmount;

                break;
            case 3:
                newPrice = Math.floor(
                    (prices[coupon.packageId] *
                        (100 - coupon.discountPercentage)) /
                        100
                );

                break;
        }
        newPrice = newPrice > 0 ? newPrice : 0;
        prices[coupon.packageId] = newPrice;
        setPackagePrices(prices);
    };

    const closeModalHandler = () => {
        props.setUserDataByKey("selectedPackage", selectedPackage);
        props.setUserDataByKey("packagePrice", packagePrices[selectedPackage]);
        props.closeModal();
    };

    return (
        <Modal
            open={props.open}
            dialogClass={classes.modalBody}
            closeModal={closeModalHandler}
            paperScrollPaperClass={classes.outer}
            mobileCloseDisabled
        >
            <div className={classes.topHalf}>
                <CloseIcon style={styles.close} onClick={closeModalHandler} />
                <div className={classes.commercial}>
                    <h2>T-DOC előfizetés most 30 napig díjmentesen!</h2>
                    <h3>
                        A díjmentes időszakot követően csak 4 990 Ft / hónap,
                        amit bármikor lemondhat
                    </h3>
                    <BtnWithIcon
                        color="yellow"
                        text="Kipróbálom"
                        className={classes.button}
                        onClick={() => selectButtonHandler(2)}
                    />
                    <p>
                        <Link to={i18nRoute("routes.terms")} target="_blank">
                            Általános szerződési feltételek.
                        </Link>{" "}
                        Az 1 hónapos díjmentes időszak csak az újonnan
                        regisztráló felhasználókra érvényes.
                    </p>
                </div>
            </div>
            <div className={classes.secondHalf}>
                <PageHeader text="Mire jó az előfizetés?" />
                <div className={classes.benefitsContainer}>
                    <BenefitElement
                        title="Korlátlan segítség"
                        text="Egészségügyi hotline szolgáltatásunk minden nap díjmentesen rendelkezésére áll."
                    />
                    <BenefitElement
                        title="Az egész családnak"
                        text="A csomagba foglalt szolgáltatások és kedvezmények családtagjaira is érvényesek"
                    />
                    <BenefitElement
                        title="Kedvezményes szolgáltatási díjak"
                        text="Minden szolgáltatásunk kedvezményes áron érhető el az előfizetéseken belül."
                    />
                    <BenefitElement
                        title="Privát egészségügyi asszisztens"
                        text="Segítünk kiigazodni, hogy kihez és milyen intézményhez érdemes fordulni bizonyos egészségügyi panaszok esetén"
                    />
                </div>
                <div className={classes.subscriptions}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <SubscriptionCard
                                open={props.subscriptionDetails}
                                openClass={classes.card}
                                buttonComponent={
                                    selectedPackage === 1 && (
                                        <BtnWithIcon
                                            selected
                                            onClick={() =>
                                                selectButtonHandler(1)
                                            }
                                        />
                                    )
                                }
                                onButtonClick={() => selectButtonHandler(1)}
                                buttonText="Kiválasztás"
                                name="Vendég"
                                description={noSub.text}
                                price={packagePrices[1]}
                                data={noSub}
                            ></SubscriptionCard>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <SubscriptionCard
                                open={props.subscriptionDetails}
                                openClass={classes.card}
                                buttonComponent={
                                    selectedPackage === 2 ? (
                                        <BtnWithIcon
                                            selected
                                            onClick={() =>
                                                selectButtonHandler(2)
                                            }
                                        />
                                    ) : (
                                        <Btn
                                            color="yellow"
                                            text="Kipróbálás"
                                            onClick={() =>
                                                selectButtonHandler(2)
                                            }
                                        />
                                    )
                                }
                                onButtonClick={() => selectButtonHandler(2)}
                                buttonText="Kiválasztás"
                                name="Basic"
                                yellowText="30 napig ingyenes"
                                hasTrialPeriod
                                description={basic.text}
                                price={packagePrices[2]}
                                data={basic}
                            ></SubscriptionCard>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <SubscriptionCard
                                openClass={classes.card}
                                buttonComponent={
                                    selectedPackage === 3 && (
                                        <BtnWithIcon
                                            selected
                                            onClick={() =>
                                                selectButtonHandler(3)
                                            }
                                        />
                                    )
                                }
                                onButtonClick={() => selectButtonHandler(3)}
                                buttonText="Kiválasztás"
                                name="Optimum"
                                open={props.subscriptionDetails}
                                greenText="Ajánlott"
                                description={optimum.text}
                                price={packagePrices[3]}
                                data={optimum}
                            ></SubscriptionCard>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <SubscriptionCard
                                openClass={classes.card}
                                name="Premium"
                                buttonText="Kiválasztás"
                                open={props.subscriptionDetails}
                                buttonComponent={
                                    selectedPackage === 4 && (
                                        <BtnWithIcon
                                            selected
                                            onClick={() =>
                                                selectButtonHandler(4)
                                            }
                                        />
                                    )
                                }
                                onButtonClick={() => selectButtonHandler(4)}
                                description={premium.text}
                                price={packagePrices[4]}
                                data={premium}
                            ></SubscriptionCard>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={12} md={4}>
                            {!couponRedeemed ? (
                                <>
                                    <div className={classes.inputContainer}>
                                        <p className={classes.label}>
                                            Kuponkód beváltása
                                        </p>
                                        <Input
                                            bigger
                                            className={classes.input}
                                            type="email"
                                            ref={couponRef}
                                        />
                                    </div>
                                    <Btn
                                        color="green"
                                        text="Beváltás"
                                        className={classes.redeemButton}
                                        onClick={redeemCoupon}
                                    />
                                </>
                            ) : (
                                <Btn
                                    color="green"
                                    text="Tovább"
                                    className={classes.redeemButton}
                                    onClick={() =>
                                        selectButtonHandler(selectedPackage)
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                    <BtnWithIcon
                        color="transparent-green"
                        text="Lépés kihagyása"
                        className={classes.button}
                        onClick={props.closeModal}
                    />
                </div>
            </div>
        </Modal>
    );
};

const styles = {
    close: {
        position: "absolute",
        top: "2rem",
        right: "2rem",
        width: "2rem",
        height: "2rem",
        color: "#fff",
    },
};

// export default FreeSubscriptionModal;

const mapStateToProps = (state) => ({
    subscriptionDetails: state.ui.subscriptionDetails,
});

export default connect(mapStateToProps, null)(FreeSubscriptionModal);
