import axios from "axios";
import { getEnv } from "tdoc-common";
import store from "../store";
import * as actions from "../store/actions/index";
import { clearAuth } from "../features/auth/slice";

const instance = axios.create({
    baseURL: `https://${getEnv("HOSTNAME_BACKEND")}`,
});

const notAuthenticatedInstance = axios.create({
    baseURL: `https://${getEnv("HOSTNAME_BACKEND")}`,
});

export const get = (url, withLoading = true) => {
    if (withLoading) {
        store.dispatch(actions.startLoad());
    }

    return instance.get(`/${url}`, {
        headers: { Token: store.getState().auth.token },
    });
};

export const post = (
    url,
    options = {},
    withLoading = true,
    checkAuthentication = true
) => {
    if (withLoading) {
        store.dispatch(actions.startLoad());
    }
    if (checkAuthentication) {
        return instance.post(`/${url}`, options, {
            headers: { Token: store.getState().auth.token },
        });
    }

    return notAuthenticatedInstance.post(`/${url}`, options, {
        headers: { Token: store.getState().auth.token },
    });
};

export const patch = (
    url,
    options = {},
    withLoading = true,
    checkAuthentication = true
) => {
    if (withLoading) {
        store.dispatch(actions.startLoad());
    }
    if (checkAuthentication) {
        return instance.patch(`/${url}`, options, {
            headers: { Token: store.getState().auth.token },
        });
    }

    return notAuthenticatedInstance.post(`/${url}`, options, {
        headers: { Token: store.getState().auth.token },
    });
};

//Válasz elkapása: nincs belogolva a user, kijelentkeztés
instance.interceptors.response.use((response) => {
    if (response.data.responseCode === "NOT_AUTHENTICATED") {
        store.dispatch(clearAuth());
        endLoading();

        return Promise.reject("NOT_AUTHENTICATED");
    }

    return response;
});

export const endLoading = () => store.dispatch(actions.endLoad());

export const startLoading = () => store.dispatch(actions.startLoad());
