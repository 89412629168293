import { useNavigate } from "react-router-dom";
import { Icons } from "../../../assets/Theme";
import Btn from "../../../components/UI/Btn/Btn";
import useAuth from "../../../features/auth/useAuth";
import classes from "../../../components/Navigation/Toolbar/Toolbar.module.css";

const NotificationBadgeAndMenu = ({
    notificationCounter,
    mobileNotificationsClick,
    showNotifications,
    notifications,
    renderNotificationElement,
}) => {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    return (
        <div
            style={{
                position: "relative",
                zIndex: 999,
            }}
        >
            {isAuthenticated && (
                <div
                    className={classes.notificationsContainer}
                    onClick={mobileNotificationsClick}
                    style={{
                        width: "4rem",
                        height: "4rem",
                        borderTopLeftRadius: 100,
                        borderTopRightRadius: 100,
                        borderBottomLeftRadius: showNotifications ? 0 : 100,
                        borderBottomRightRadius: showNotifications ? 0 : 100,
                        border: "1px solid rgba(38, 61, 80, 0.1)",
                    }}
                >
                    <img
                        alt=""
                        src={Icons.notificationSmallBlack}
                        className={classes.icon}
                        style={{ width: "1.6rem" }}
                    />
                    {notificationCounter > 0 && (
                        <div className={classes.counterContainer}>
                            <span className={classes.counter}>
                                {notificationCounter}
                            </span>
                        </div>
                    )}
                </div>
            )}
            {showNotifications && (
                <div style={{ position: "absolute" }}>
                    <div
                        className={classes.elementsInner}
                        style={{ backgroundColor: "#fff" }}
                    >
                        <p
                            className={classes.notificationHeader}
                            style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid var(--grey2)",
                            }}
                        >
                            Értesítések
                        </p>
                        {notifications?.map((element) =>
                            renderNotificationElement(element)
                        )}
                    </div>
                    <div style={{ backgroundColor: "#fff", padding: "2rem" }}>
                        <Btn
                            color="green"
                            text="Összes előzmény"
                            onClick={() => navigate("/elozmenyek")}
                            style={{
                                height: 40,
                                width: "100%",
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 50,
                                fontSize: "14px",
                                fontFamily: "var(--segoe-semibold)",
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationBadgeAndMenu;
