import { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Colors, Fonts, Icons, Images } from "../../../assets/Theme";
import ServicePage from "../../../components/UI/ServicePage/ServicePage";
import CustomSlider from "../../../components/UI/CustomSlider/CustomSlider";
import { endLoading, post, startLoading } from "../../../config/fetch";
import { ALERT_MESSAGES, PAYMENT } from "../../../config/variables";
import { formatNumber, responseMessage } from "../../../utility";
import PaymentModal from "../../../components/Modals/PaymentModal/PaymentModal";
import { Config } from "../../../config/config";
import * as actions from "../../../store/actions/index";
import {
    ecommerceAddCart,
    ecommerceCheckout,
    ecommerceProductDetail,
    ecommercePurchase,
} from "../../../config/ecommerce";
import SubscriptionAndServiceModal from "../../../components/Modals/SubscriptionAndServiceModal/SubscriptionAndServiceModal";
import useTranslation from "../../../features/i18n/useTranslation";
import useAuth from "../../../features/auth/useAuth";
import useQuery from "../../../features/util/useQuery";

let price = null;
let subscription = null;

const Hotline = (props) => {
    const navigate = useNavigate();
    const query = useQuery();
    const { isAuthenticated } = useAuth();
    const [packages, setPackages] = useState(null);
    const [paymentModal, togglePaymentModal] = useState(false);
    const [hotlineAvailable, setHotlineAvailable] = useState(false);
    const people = [];
    const [userSubscribed, setUserSubscribed] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(1);
    const [originalSubscription, setOriginalSubscription] = useState(false);
    const [subscriptionAndServiceModal, toggleSubscriptionAndServiceModal] =
        useState(0);
    const [subscriptionAndServiceModalData, setSubscriptionAndServiceModal] =
        useState({
            freeOccasions: null,
            servicePrices: [],
            service: null,
        });
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        let utcTime = new Date().getTime();
        let utcTimeSeconds = Math.floor(utcTime / 1000);
        axios
            .get(
                `https://maps.googleapis.com/maps/api/timezone/json?location=47.49571,19.05507&timestamp=${utcTimeSeconds}&key=${Config.googleApiKey}`
            )
            .then((response) => {
                if (response.data.status === "OK") {
                    let hungaryTime =
                        utcTimeSeconds +
                        response.data.rawOffset +
                        response.data.dstOffset;
                    let hungaryHour = new Date(
                        hungaryTime * 1000
                    ).getUTCHours();
                    setHotlineAvailable(hungaryHour >= 8 && hungaryHour < 20);
                }
            });
    }, []);

    useEffect(() => {
        paymentCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paymentCallback = () => {
        if (query.has("TransactionId")) {
            const transactionId = query.get("TransactionId");
            const immediateToken = query.get("immediateToken");
            const oneClickEnabled = query.get("oneClickEnabled");
            const subscription = query.get("subscription");
            const price = query.get("price");

            post("api/v1/payment/payment-init-callback", {
                transactionId,
                cardName: "Bankkártya",
                immediateToken,
                oneClick: oneClickEnabled,
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            endLoading();
                            ecommercePurchase("HOTLINE", price, subscription);
                            responseMessage(
                                succesFullResponseText(
                                    response.data.ProviderTransactionId
                                )
                            );
                            navigate("/service-initiated?service=hotline", {
                                replace: true,
                            });

                            break;
                        case "PAYMENT_ERROR":
                            switch (response.data.errorCode) {
                                case "ERROR":
                                    responseMessage(
                                        unSuccesfullResponseText(
                                            response.data.ProviderTransactionId
                                        )
                                    );
                                    endLoading();

                                    break;
                                case "CANCELED":
                                    responseMessage(
                                        "Megszakítottad a fizetési folyamatot. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "TIMEOUT":
                                    responseMessage(
                                        "Túllépted a tranzakció elindításakor megengedett biztonságos időkaput. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "PENDING":
                                    startLoading();
                                    setTimeout(paymentCallback, 3000);

                                    break;
                            }
                            navigate(i18nRoute("routes.services.hotline"), {
                                replace: true,
                            });

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                            endLoading();
                            navigate(i18nRoute("routes.services.hotline"), {
                                replace: true,
                            });
                    }

                    localStorage.removeItem("newCardName");
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    navigate(i18nRoute("routes.services.hotline"), {
                        replace: true,
                    });
                });
        }
    };

    const succesFullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikeres tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
        </>
    );

    const unSuccesfullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikertelen tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
            <p style={{ marginBottom: "1rem" }}>
                Kérjük, ellenőrizze a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adott meg, a
                visszautasítás okának kivizsgálása érdekében kérjük,
                szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
            </p>
        </>
    );

    const processPackages = (packages, subscribedIndex, dynamicData = null) => {
        setSelectedPackage(+subscribedIndex);
        setOriginalSubscription(+subscribedIndex);
        const _packages = [];
        const transactionId = query.get("TransactionId");
        //Ha fizetésről tér vissza, ne fusson le mégegyszer
        if (!transactionId) {
            if (dynamicData) {
                ecommerceProductDetail(
                    "HOTLINE",
                    dynamicData.price,
                    +dynamicData.userPackage
                );
            } else {
                ecommerceProductDetail(
                    "HOTLINE",
                    packages[0].price,
                    subscribedIndex
                );
            }
        }
        const packagePrices = [null];
        const freeOccasions = [null];

        packages.forEach((p, i) => {
            packagePrices.push(p.price);
            freeOccasions.push(p.freePerMonth);
            if (dynamicData && subscribedIndex - 1 == i) {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        dynamicData.price !== 0
                            ? `${formatNumber(dynamicData.price)} Ft`
                            : `Korlátlan`,
                    subtext:
                        dynamicData.price === 0 &&
                        dynamicData.remainingServices > 0
                            ? `Hátralévő: ${dynamicData.remainingServices}`
                            : "",
                });
            } else {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    // price: p.freePerMonth > 0 ? `${p.freePerMonth} ingyenes` : `${formatNumber(p.price)} Ft`,
                    price:
                        p.price != 0
                            ? `${formatNumber(p.price)} Ft`
                            : "Korlátlan",
                    subtext:
                        p.freePerMonth > 0
                            ? p.price > 0
                                ? `*utána ${formatNumber(
                                      p.price
                                  )} Ft /beszélgetés`
                                : ""
                            : "",
                });
            }
        });
        setSubscriptionAndServiceModal({
            service: "HOTLINE",
            servicePrices: packagePrices,
            freeOccasions,
        });

        return _packages;
    };

    useEffect(() => {
        getPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPrices = () => {
        if (isAuthenticated) {
            const promise1 = post(
                "api/v1/subscription/get-service-prices-by-user",
                { service: "HOTLINE" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });
            const promise2 = post(
                "api/v1/subscription/check-user-service-price",
                { service: "HOTLINE" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        price = response.data.data.price;
                        setUserSubscribed(response.data.data.price < 1);

                        return response.data;
                }
            });
            Promise.all([promise1, promise2]).then((responses) => {
                endLoading();
                const response1 = responses[0];
                const response2 = responses[1];
                let _packages = processPackages(
                    response1.packages,
                    response1.subscription ? response1.subscription : 1,
                    response2.data
                );
                setPackages(_packages);
            });
        } else {
            post("api/v1/subscription/get-service-prices-by-user", {
                service: "HOTLINE",
            }).then((response) => {
                endLoading();
                if (response.data.responseCode === "OK") {
                    let _packages = processPackages(
                        response.data.packages,
                        response.data.subscription
                            ? response.data.subscription
                            : 1
                    );
                    setPackages(_packages);
                }
            });
        }
    };

    const startButtonHandler = () => {
        if (isAuthenticated) {
            post("api/v1/subscription/check-user-service-price", {
                service: "HOTLINE",
            })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            price = response.data.data.price;
                            subscription = response.data.data.userPackage;
                            ecommerceAddCart(
                                "HOTLINE",
                                response.data.data.price,
                                response.data.data.userPackage
                            );
                            toggleSubscriptionAndServiceModal(2);

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                });
        } else {
            props.toggleLoginModal(true);
        }
    };

    const initCall = () => {
        ecommerceCheckout("HOTLINE", price, subscription);
        post("api/v1/call/init-call", { type: "HOTLINE" })
            .then((response) => {
                endLoading();
                toggleSubscriptionAndServiceModal(0);
                switch (response.data.responseCode) {
                    case "OK":
                        ecommercePurchase("HOTLINE", price, subscription);
                        navigate(`/service-initiated?service=hotline`);

                        break;
                    case "PAYMENT_FAILED":
                        responseMessage(PAYMENT.PAYMENT_FAILED);

                        break;
                    case "EMPTY_DEFAULT_CARD":
                        post("api/v1/subscription/check-user-service-price", {
                            service: "HOTLINE",
                        })
                            .then((response) => {
                                endLoading();
                                switch (response.data.responseCode) {
                                    case "OK":
                                        props.toggleServicePaymentModal(
                                            true,
                                            response.data.data.summaryAmount,
                                            "HOTLINE"
                                        );

                                        break;
                                    default:
                                        responseMessage(
                                            ALERT_MESSAGES.errorTryAgain
                                        );

                                        break;
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                endLoading();
                            });

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const redeemCoupon = (code) => {
        if (code) {
            post("api/v1/coupon/redeem-coupon", { couponCode: code })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("A kupont sikeresen beváltottad!");
                            getPrices();

                            break;
                        case "WRONG_CODE":
                            responseMessage("Hibás kuponkód!");

                            break;
                        case "ALREADY_REDEEMED":
                            responseMessage(
                                "Ezt a kupont már beváltottad egyszer!"
                            );

                            break;
                        case "ONLY_WITHOUT_SUBSCRIPTION":
                            responseMessage(
                                "Ezt a kupont csak előfizetés nélkül lehet beváltani!"
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const resetSubscription = () => setSelectedPackage(originalSubscription);

    const onPackageClick = (pckg) => {
        setSelectedPackage(pckg);
        toggleSubscriptionAndServiceModal(1);
    };

    return (
        <ServicePage
            icon={Icons.hotlineGreen}
            name="Egészségügyi hotline"
            description="Napi 12 órában - hétvégén is - elérhető egészségügyi hotline, mely kisebb egészségügyi kérdéseid megválaszolásában, időpontok foglalásában nyújt számodra segítséget."
            image={Images.hotline}
            imageAlt="Egészségügyi hotline, személyes egészségügyi asszisztens | T-DOC"
            packages={packages}
            selectedPackage={selectedPackage}
            originalSubscription={originalSubscription}
            importantInfoIcon={Images.gearIcon}
            importantInfoText="FIGYELEM! A szolgáltatás jelenleg nem elérhető. Az Egészségügyi Hotline minden nap 08:00 és 20:00 között hívható."
            showImportantInfo={!hotlineAvailable}
            showImportantInfo2={hotlineAvailable}
            buttonText="Kérem a telefonszámot"
            buttonCallback={startButtonHandler}
            buttonDisabled={!hotlineAvailable}
            packageResetClick={resetSubscription}
            packageClick={onPackageClick}
        >
            <h1 style={{ ...styles.title, fontSize: "3rem" }}>
                Egészségügyi hotline
            </h1>

            <h2 style={styles.title}>
                Az Egészségügyi Hotline elérhetősége: 08:00 – 20:00
            </h2>
            <p style={styles.text}>
                <span style={styles.strong}>
                    Az év 365 napján 08-20:00 között működik vonalunk,
                </span>{" "}
                ahol egészségügyi végzettséggel rendelkező kollégáink várják
                hívásodat, ha nem vagy biztos abban, hogy melyik szolgáltatás
                lenne a megfelelőbb számodra vagy csak kisebb, orvost nem
                igénylő kérdésben lenne szükséged megnyugtatásra.
            </p>
            <p style={styles.text}>
                Munkatársaink gyorsan eldöntik, hogy{" "}
                <span style={styles.strong}>
                    panaszoddal szükséges-e azonnal orvossal beszélned, vagy
                    jobb megoldást kínál egy orvosi chat vagy másodvélemény
                </span>{" "}
                egy probléma megoldásában.
            </p>
            <p style={styles.text}>
                Hogyan érheted el a szolgáltatást? Csupán annyi a dolgod, hogy{" "}
                <span style={styles.strong}>
                    egy gyors regisztráció után basic, optimum vagy premium
                    előfizetéseink keretén belül igénybe veszed az egészség
                    hotline-t.
                </span>{" "}
                Kérd a telefonszámot és tárcsázd ügyfélszolgálatunkat reggel 8
                és este 8 óra között! Ha a három előfizetés közül választasz,
                akkor{" "}
                <span style={styles.strong}>
                    korlátlanul hívhatod a T-DOC személyes egészségügyi
                    asszisztensed az év bármely napján,
                </span>{" "}
                akár hétvégén vagy ünnepnapokon is. Abban az esetben, ha
                azonnali segítségre van szükséged, tanácstalan vagy egészségügyi
                állapotoddal kapcsolatban, akkor regisztráció nélkül, díj
                ellenében is igénybe veheted szolgáltatásunkat.
            </p>

            <h2 style={styles.title}>
                Milyen esetekben lehet segítségedre az egészség hotline?
            </h2>
            <p style={styles.text}>
                Ha előfordult már Veled, hogy elsőre nem a megfelelő
                szakrendelésre látogattál el, amivel értékes időt és pénzt
                veszítettél, akkor jó helyen jársz. Kollégáink segítséget
                nyújtanak akkor is, ha bizonytalan vagy abban, hogy panaszoddal
                kihez fordulj. Még akkor is segítségedre tudunk lenni, ha épp
                idegen környezetben, akár külföldön vagy, mégis szükséged lenne
                orvosi ellátásra, tanácsra. Gyorsan megtaláljuk a problémádra a
                hozzád legközelebb található, és legjobb ellátást biztosító
                egészségügyi intézményt.
            </p>
            <p style={styles.text}>
                Az egészség hotline csapata már az első ponttól fogja a kezed! A
                betegség beazonosítása után átirányít a megfelelő szakorvoshoz,
                akár időpontot is foglal számodra a szakrendelésre. Bízd magad a
                T-DOC-ra, és juss el a gyógyulásig a lehető legrövidebb úton!
            </p>
            <p style={styles.text}>
                Az egészségügyi hotline nem minősül orvosi konzultációnak.
            </p>

            <h2 style={styles.title}>Az egészségügyi hotline csapata</h2>
            <p style={styles.text}>
                Egészség hotline csapatunk egészségügyi múlttal rendelkező,
                segítőkész, udvarias és vidám egyénekből áll össze, akik
                legnagyobb tudásukkal igyekeznek megoldást keresni problémáidra.
                Minden bejövő hívást 100%-os diszkrécióval kezelnek, így teljes
                bizalommal fordulhatsz hozzájuk kérdéseiddel a hét minden
                napján.
            </p>
            <CustomSlider data={people} withAvailability people />
            <PaymentModal
                closeModal={() => togglePaymentModal(false)}
                open={paymentModal}
                userSubscribed={userSubscribed}
                infoText="A folytatásra kattintva tárcsázhatja a hotlinet, rendszerünk a számlájáról pedig automatikusan levonja a megjelölt összeget"
                boxTitle={
                    !price
                        ? "Előfizetésben fogalt hotline hívás"
                        : "Előfizetés hotline hívás díja"
                }
                price={price}
                couponHandler={redeemCoupon}
                onContinueClick={initCall}
            />
            <SubscriptionAndServiceModal
                outerSelectedPackage={selectedPackage}
                selectCallback={setSelectedPackage}
                normalInit={initCall}
                open={subscriptionAndServiceModal}
                data={subscriptionAndServiceModalData}
                toggleSubscriptionAndServiceModal={
                    toggleSubscriptionAndServiceModal
                }
                succesfulSubscribeAndServiceCallback={() =>
                    navigate("/service-initiated?service=hotline")
                }
            />
        </ServicePage>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontWeight: 700,
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
        marginBottom: "1rem",
    },
    strong: {
        fontWeight: 700,
    },
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
    toggleServicePaymentModal: (show, price, service) =>
        dispatch(actions.toggleServicePaymentModal(show, price, service)),
});

export default connect(null, mapDispatchToProps)(Hotline);
