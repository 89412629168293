import { useEffect, useState } from "react";
import { groupByFirstLetter } from "../../../utility";
import classes from "./Qualifications.module.css";

const Qualifications = (props) => {
    const [grouppedData, setGrouppedData] = useState(
        groupByFirstLetter(props.qualifications, "name")
    );

    useEffect(() => {
        setGrouppedData(groupByFirstLetter(props.qualifications, "name"));
    }, [props.qualifications]);

    const getColumnCount = () => {
        if (Object.keys(grouppedData).length > 0) {
            if (window.innerWidth > 959) {
                return 4;
            } else if (window.innerWidth > 600) {
                return 3;
            }

            return 2;
        }

        return 1;
    };

    const grouppedQualifications = () => {
        if (Object.keys(grouppedData).length > 0) {
            return Object.keys(grouppedData).map((element) => (
                <div className={classes.qualificationGroup} key={element}>
                    <p className={classes.qualificationGroupTitle}>{element}</p>
                    {grouppedData[element].map((el) => (
                        <p
                            className={classes.qualificationGroupElement}
                            key={el.id}
                            onClick={() =>
                                props.onQualificationClick(el.url, el.medioId)
                            }
                        >
                            {el.name}
                        </p>
                    ))}
                </div>
            ));
        }
        if (props.altNoMatch) {
            return (
                <p className={classes.altNoMatch}>
                    Nem található a keresnek megfelelő szakterület.
                </p>
            );
        }

        return (
            <p className={classes.noMatch}>
                Sajnáljuk, de az alábbi kifejezésre nincs találat.
            </p>
        );
    };

    return (
        <div className={classes.container} style={props.style}>
            <div
                className={classes.qualifications}
                style={{ columnCount: getColumnCount() }}
            >
                {grouppedQualifications()}
            </div>
        </div>
    );
};

export default Qualifications;
