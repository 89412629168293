import cn from "classnames";
import classes from "./Headline.module.css";

const Headline = ({ children, className, style }) => (
    <div className={cn(classes.headline, className)} style={style}>
        {children}
    </div>
);

export default Headline;
