import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import * as actions from "../../../store/actions/index";
import { useHumed } from "../../../ui/hooks/useHumed";
import classes from "./ResponseModal.module.css";

const cx = classNames.bind(classes);

const ResponseModal = (props) => {
    const isHumed = useHumed();
    const [open, toggleOpen] = useState(props.open);

    useEffect(() => {
        if (open != props.open) {
            toggleOpen(props.open);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const closeModal = (event, reason) => {
        if (
            reason === "backdropClick" &&
            (props.responseModal.cancelButton ||
                props.responseModal.disableBackdropClick)
        ) {
            return;
        }

        props.toggleResponseModal(false);
    };

    const confirmButtonHandler = () => {
        closeModal();
        if (
            props.responseModal.confirmButtonCallback &&
            typeof props.responseModal.confirmButtonCallback === "function"
        ) {
            props.responseModal.confirmButtonCallback();
        }
    };

    const cancelButtonHandler = () => {
        closeModal();
        if (
            props.responseModal.cancelButtonCallback &&
            typeof props.responseModal.cancelButtonCallback === "function"
        ) {
            props.responseModal.cancelButtonCallback();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <span className={classes.title}>
                    {props.responseModal.title}
                </span>
            </DialogTitle>
            <DialogContent className={classes.content}>
                {props.responseModal.content && (
                    <p>{props.responseModal.content}</p>
                )}
                {props.responseModal.renderContent
                    ? props.responseModal.renderContent
                    : ""}
            </DialogContent>
            <DialogActions>
                {props.responseModal.confirmButton && (
                    <Button
                        classes={{
                            label: cx(classes.buttonText, { humed: isHumed }),
                        }}
                        onClick={confirmButtonHandler}
                        color="primary"
                    >
                        {props.responseModal.confirmButtonText}
                    </Button>
                )}
                {props.responseModal.cancelButton && (
                    <Button
                        classes={{
                            label: cx(classes.buttonText, { humed: isHumed }),
                        }}
                        onClick={cancelButtonHandler}
                        color="primary"
                    >
                        {props.responseModal.cancelButtonText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    open: state.ui.showResponseModal,
    responseModal: state.ui.responseModal,
});

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show) => dispatch(actions.toggleResponseModal(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseModal);
