import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import classes from "./AlertLine.module.css";

const AlertLine = (props) => {
    const onClickHandler = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <div
            className={`${classes.AlertLine} ${props.className}`}
            style={props.style}
            onClick={onClickHandler}
        >
            <img src={props.icon} className={classes.icon} alt="" />
            <p className={classes.text}>{props.text}</p>
            {props.actionText && (
                <div className={classes.actionTextContainer}>
                    <p className={classes.actionText}>{props.actionText}</p>
                    <ChevronRightIcon classes={{ root: classes.rightIcon }} />
                </div>
            )}
        </div>
    );
};

export default AlertLine;
