import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { UserAvatar as UserAvatarIcon } from "@carbon/icons-react";
import { useIsNha } from "tdoc-common";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import * as actions from "../../../store/actions";
import { endLoading, get, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import { basic, noSub, optimum, premium } from "../../../config/subscriptions";
import { formatNumber, responseMessage } from "../../../utility";
import useAuth from "../../../features/auth/useAuth";
import classes from "./Subscriptions.module.css";
// refacted layout UI elements to display Paperish design
import useTranslation from "../../../features/i18n/useTranslation";
import Alert from "../atoms/Alert";
import ScrollContainer from "../atoms/ScrollContainer";
import Paper from "../atoms/Paper";
import PaperSection from "../atoms/PaperSection";
import Headline from "../atoms/Headline";
import Tabs from "../molecules/Tabs";
import PageSubHeader from "../atoms/PageSubHeader";
import SubscriptionCard from "../organisms/SubscriptionCard";
import SubscriptionAccordion from "../organisms/SubscriptionAccordion";
import "../typo.css";
import NhSubscriptionAccordion from "../organisms/NhSubscriptionAccordion";

const subs = {
    1: noSub,
    2: basic,
    3: optimum,
    4: premium,
};

const Subscriptions = ({ toggleLoginModal, toggleResponseModal }) => {
    const { isAuthenticated, userId } = useAuth();
    const { i18nRoute } = useTranslation();
    const navigate = useNavigate();
    const { isNhaUser } = useIsNha();

    const scrollRef = useRef();

    const [userData, setUserData] = useState({
        user: {},
        cards: [],
    });

    const [nhSubs, setNhSubs] = useState([]);

    const [subscriptionData, setSubscriptionData] = useState({
        subscription: null,
        nextSubscription: null,
    });

    useEffect(() => {
        getUserData();
        getNhSubs();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getUserData = () => {
        get("user/get-user-data")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        delete response.data.userSubscription.id;

                        setUserData({
                            user: response.data.user,
                            cards: response.data.cards,
                        });
                        setSubscriptionData({
                            subscription: {
                                ...response.data.subscription,
                                ...response.data.userSubscription,
                            },
                            nextSubscription:
                                response.data.userSubscription
                                    .next_month_package,
                        });

                        break;
                    }
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const getNhSubs = () => {
        if (!isNhaUser) {
            return;
        }
        get(`api/users/${userId}/nhsubscriptions`)
            .then((response) => {
                switch (response.status) {
                    case 200: {
                        setNhSubs(
                            response.data.filter(
                                (sub) => Date.parse(sub.expiresAt) > new Date()
                            )
                        );

                        break;
                    }
                    default: {
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                    }
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const buttonClickHandler = (packageId) => {
        if (isAuthenticated) {
            if (+packageId > +subscriptionData.subscription.id) {
                post("api/v1/subscription/init-subscription-change", {
                    packageId: parseInt(packageId),
                })
                    .then((response) => {
                        endLoading();
                        switch (response.data.responseCode) {
                            case "OK":
                                toggleResponseModal(true, {
                                    title: "Csomag váltás",
                                    content: `Jó döntés!\n Nagyobb előfizetésre szeretnél váltani. A váltás megerősítését követően az elsődleges kártyádat beterheljük a jelenlegi előfizetésed és a kiválasztott előfizetés közötti árkülönbözetet (${formatNumber(
                                        response.data.scale
                                    )} Ft). Kattints a Megerősítem gombra, és rögtön élvezheted új előfizetésed minden előnyét.`,
                                    confirmButton: true,
                                    confirmButtonText: "Megerősítem",
                                    confirmButtonCallback: () =>
                                        subscriptionChangeHandler(packageId),
                                    cancelButton: true,
                                    cancelButtonText: "Mégsem",
                                    cancelButtonCallback: null,
                                    disableBackdropClick: false,
                                });

                                break;
                            default:
                                responseMessage(ALERT_MESSAGES.errorTryAgain);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        endLoading();
                    });
            } else {
                toggleResponseModal(true, {
                    title: "Csomag váltás",
                    content: `Biztosan kisebb előfizetésre szeretnél váltani? Kisebb előfizetésre történő váltás esetén jelenlegi csomagod előnyei hónapfordulóval elvesznek. Megerősítés esetén jelenlegi előfizetésed a hónap fordulóig tudod használni, ezt követően aktiváljuk kisebb előfizetésed. Új előfizetésed díja: ${formatNumber(
                        subs[packageId].price
                    )} Ft.`,
                    confirmButton: true,
                    confirmButtonText: "Megerősítem",
                    confirmButtonCallback: () =>
                        subscriptionChangeHandler(packageId),
                    cancelButton: true,
                    cancelButtonText: "Mégsem",
                    cancelButtonCallback: null,
                    disableBackdropClick: false,
                });
            }
        } else {
            toggleLoginModal(true);
        }
    };

    const subscriptionChangeHandler = (packageId) => {
        post("api/v1/subscription/change-subscription", {
            packageId: parseInt(packageId),
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("");

                        toggleResponseModal(true, {
                            content: `Előfizetését sikeresen módosította!`,
                            confirmButton: true,
                            confirmButtonText: "Rendben",
                            confirmButtonCallback: getUserData,
                            cancelButton: false,
                            disableBackdropClick: true,
                        });

                        break;
                    }
                    case "PAYMENT_FAILED":
                        responseMessage(
                            "Hiba lépett fel fizetés közben. Kérjük, próbáld meg újból!"
                        );

                        break;
                    case "EMPTY_DEFAULT_CARD":
                        responseMessage("Nincs megadva elsődleges kártya.");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const cancelSubscriptionHandler = () => {
        toggleResponseModal(true, {
            title: "Előfizetés lemondása",
            content:
                "Biztosan le szeretnéd mondani az előfizetésed? Amennyiben igen, előfizetésed hónapfordulójáig még élvezheted annak minden előnyét. A hónapfordulót követően szolgáltatásainkat alap áron tudod majd igénybe venni.",
            confirmButton: true,
            confirmButtonText: "Megerősítem",
            confirmButtonCallback: () => subscriptionChangeHandler(1),
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });
    };

    const handleAlert = () => {
        navigate(i18nRoute("routes.profile.base", {}));
    };

    return (
        <div className={classes.intro}>
            <Container>
                <Paper>
                    <Headline>
                        <Breadcrumbs />
                    </Headline>

                    <Tabs
                        tabs={[
                            {
                                label: "Aktív előfizetéseim",
                                scrollToId: "#my-subscriptions",
                                isHidden: !isAuthenticated,
                            },
                            {
                                label: "Távkonzultációs csomagok",
                                scrollToId: "#remote-subscriptions",
                            },
                            {
                                label: "Adatalapú csomagok",
                                scrollToId: "#data-driven-subscriptions",
                                isHidden: true,
                            },
                        ]}
                        scrollRef={scrollRef}
                    />
                    <ScrollContainer ref={scrollRef}>
                        {isAuthenticated && subscriptionData?.subscription && (
                            <PaperSection id="my-subscriptions" isInverted>
                                {userData.cards.length === 0 && (
                                    <Alert
                                        label="Figyelem!"
                                        button={
                                            <button
                                                onClick={handleAlert}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "1rem",
                                                    backgroundColor:
                                                        "var(--green)",
                                                    borderColor: "var(--green)",
                                                    color: "#fff",
                                                    fontFamily:
                                                        "var(--segoe-semibold)",
                                                    borderRadius: "100px",
                                                    marginLeft: "auto",
                                                    border: "unset",
                                                    padding: "0.8rem 2.4rem",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <UserAvatarIcon />
                                                <span>Fiók beállításai</span>
                                            </button>
                                        }
                                    >
                                        <p>
                                            Kérjük rögzítsen a felhasználói fiók{" "}
                                            <i
                                                style={{
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                Fizetési beállítások
                                            </i>{" "}
                                            pontjában egy bankkártyát!
                                        </p>
                                    </Alert>
                                )}
                                <PageSubHeader
                                    className={classes.PageSubHeader}
                                >
                                    Aktív előfizetéseim
                                </PageSubHeader>
                                {nhSubs.length > 0 &&
                                    nhSubs.map((sub) => (
                                        <NhSubscriptionAccordion
                                            subscriptionData={sub}
                                            key={sub.id}
                                        />
                                    ))}
                                <SubscriptionAccordion
                                    cards={userData.cards}
                                    subs={subs}
                                    subscriptionData={subscriptionData}
                                    setSubscriptionData={setSubscriptionData}
                                    subscriptionChangeHandler={
                                        subscriptionChangeHandler
                                    }
                                    buttonClickHandler={buttonClickHandler}
                                    cancelSubscriptionHandler={
                                        cancelSubscriptionHandler
                                    }
                                />
                            </PaperSection>
                        )}
                        <PaperSection id="remote-subscriptions">
                            <PageSubHeader className={classes.PageSubHeader}>
                                Távkonzultációs csomagok
                            </PageSubHeader>
                            <p className={classes.text}>
                                Egy előfizetés, számtalan megoldás egészségügyi
                                problémádra!
                                <br />
                                Előfizetéseink nem csupán egy ember, de akár az
                                egész család biztonságát is jelenthetik. Az
                                előfizetéseken belül elérhető ingyenes, valamint
                                kedvezményes szolgáltatások az előfizetőre,
                                illetve a vele egyháztartásban élőkre is
                                érvényesek.
                                <br />
                                Az elérhető szolgáltatások listája és részletes
                                információi ide kattintva érhetők el.
                                <br />
                                <strong>
                                    Válaszd ki a számodra megfelelő
                                    előfizetésünket, és tudd biztonságban az
                                    egész családot!
                                </strong>
                                <br />
                                <br />
                            </p>
                            <Grid container spacing={1}>
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    className={classes.subscriptionCardGrid}
                                >
                                    <SubscriptionCard
                                        name="Vendég"
                                        packageId={1}
                                        cards={userData.cards}
                                        className={classes.card}
                                        description={noSub.text}
                                        price={noSub.price}
                                        data={noSub}
                                        subscriptionData={subscriptionData}
                                        onButtonClick={buttonClickHandler}
                                        cancelSubscriptionHandler={
                                            cancelSubscriptionHandler
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    className={classes.subscriptionCardGrid}
                                >
                                    <SubscriptionCard
                                        name="Basic"
                                        packageId={2}
                                        cards={userData.cards}
                                        className={classes.card}
                                        yellowText="30 napig ingyenes"
                                        hasTrialPeriod
                                        description={basic.text}
                                        price={basic.price}
                                        data={basic}
                                        subscriptionData={subscriptionData}
                                        onButtonClick={buttonClickHandler}
                                        cancelSubscriptionHandler={
                                            cancelSubscriptionHandler
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    className={classes.subscriptionCardGrid}
                                >
                                    <SubscriptionCard
                                        name="Optimum"
                                        packageId={3}
                                        cards={userData.cards}
                                        className={classes.card}
                                        greenText="Ajánlott"
                                        description={optimum.text}
                                        price={optimum.price}
                                        data={optimum}
                                        subscriptionData={subscriptionData}
                                        onButtonClick={buttonClickHandler}
                                        cancelSubscriptionHandler={
                                            cancelSubscriptionHandler
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    className={classes.subscriptionCardGrid}
                                >
                                    <SubscriptionCard
                                        name="Premium"
                                        packageId={4}
                                        cards={userData.cards}
                                        className={classes.card}
                                        description={premium.text}
                                        price={premium.price}
                                        data={premium}
                                        subscriptionData={subscriptionData}
                                        onButtonClick={buttonClickHandler}
                                        cancelSubscriptionHandler={
                                            cancelSubscriptionHandler
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </PaperSection>

                        {/* <PaperSection id="data-driven-subscriptions">
                            <PageSubHeader className={classes.PageSubHeader}>
                                Adatalapú csomagok
                            </PageSubHeader>
                            <p className={classes.text}>
                                A adatalapú csomagok sed ut perspiciatis unde
                                omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium, totam rem
                                aperiam, eaque ipsa quae ab illo inventore
                                veritatis et quasi architecto beatae vitae dicta
                                sunt explicabo. Nemo enim ipsam voluptatem quia
                                voluptas sit aspernatur aut odit aut fugit, sed
                                quia
                            </p>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <p>1. kártya</p>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <p>2. kártya</p>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <p>3. kártya</p>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <p>4. kártya</p>
                                </Grid>
                            </Grid>
                        </PaperSection> */}
                    </ScrollContainer>
                </Paper>
            </Container>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(Subscriptions);
