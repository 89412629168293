import { Icons } from "../../../assets/Theme";
import classes from "./NotificationWarning.module.css";

const NotificationWarning = (props) => (
    <div className={classes.notification} style={{ ...props.style }}>
        <img className={classes.icon} src={Icons.exclamationBlack} alt="" />
    </div>
);

export default NotificationWarning;
