import classes from "./styles.module.css";

interface Props {
    text: string;
    white?: boolean;
}
const PageHeader = ({ text, white }: Props) => (
    <div className={classes.pageHeader}>
        <div className={classes.line}></div>
        <span
            className={classes.text}
            style={{ color: white ? "white" : "inherit" }}
        >
            {text}
        </span>
        <div className={classes.line}></div>
    </div>
);

export default PageHeader;
