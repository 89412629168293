import { Tooltip as BaseTooltip } from "@material-ui/core";
import classes from "./Tooltip.module.css";

const Tooltip = ({
    title,
    arrow,
    children,
    placement = "top-end",
    isDisabled = false,
}) => {
    if (isDisabled) {
        return children;
    }

    return (
        <BaseTooltip
            placement={placement}
            title={title}
            arrow={arrow}
            classes={{
                arrow: classes.arrow,
                tooltip: classes.tooltip,
            }}
        >
            {children}
        </BaseTooltip>
    );
};

export default Tooltip;
