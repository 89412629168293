import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Calendar as CalendarIcon,
    Chat as ChatIcon,
    Choices as ChoicesIcon,
    Delivery as DeliveryIcon,
    Email as EmailIcon,
    Notification as NotificationIcon,
    Settings as SettingsIcon,
} from "@carbon/icons-react";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import { endLoading, get } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import { responseMessage } from "../../../utility";
import NextHealthMenu from "../../../features/nexthealth/NextHealthMenu";
import useTranslation from "../../../features/i18n/useTranslation";
import Paper from "../atoms/Paper";
import Btn from "../../UI/Btn/Btn";
import useWindowDimensions from "../../../ui/hooks/useWindowDimensions";
import classes from "./Sidebar.module.css";

const Sidebar = () => {
    const { width: vw } = useWindowDimensions();
    const [isOpen, setOpen] = useState(true);
    const [employer, setEmployer] = useState({
        isLoading: true,
        data: null,
    });
    const navigate = useNavigate();
    const { i18nRoute } = useTranslation();
    const counter = useRef({ messages: 0 });

    const countMessages = () => {
        get("api/v1/event/get-user-chat-channels")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        counter.current.messages =
                            response.data.channels.filter(
                                (message) => message.patient_unread === 1
                            ).length;

                        break;
                    default:
                        console.log(
                            "Error in countMessages:",
                            response.data.responseCode
                        );

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    useEffect(() => {
        countMessages();
    }, []);

    useEffect(() => {
        setOpen(vw > 959);
    }, [vw]);

    useEffect(() => {
        get("user/get-employer")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setEmployer({
                            isLoading: false,
                            data: response.data.employer,
                        });

                        break;
                    default:
                        setEmployer({
                            isLoading: false,
                            data: null,
                        });
                        console.log(
                            "Error in countMessages:",
                            response.data.responseCode
                        );

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                setEmployer({
                    isLoading: false,
                    data: null,
                });
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);
    const handleRoute = useCallback(
        (path) => {
            navigate(i18nRoute(path));
            setOpen(false);
        },
        [navigate, setOpen, i18nRoute]
    );

    return (
        <Paper className={classes.sidebar}>
            <ul className={classes.menu}>
                <li
                    className={classes.placeholder}
                    onClick={() => setOpen((s) => !s)}
                >
                    <ChoicesIcon />
                    <span>Navigáció</span>
                    <ArrowDropDownIcon
                        fontSize="large"
                        classes={{ root: classes.icon }}
                    />
                </li>

                <li
                    style={{
                        display: isOpen ? "flex" : "none",
                    }}
                    onClick={() => handleRoute("routes.history.base")}
                >
                    <NotificationIcon />
                    <span>Értesítések</span>
                    {/* <span className={classes.badge}>1</span> */}
                </li>
                <li
                    style={{
                        display: isOpen ? "flex" : "none",
                    }}
                    onClick={() => handleRoute("routes.profile.messages")}
                >
                    <ChatIcon />
                    <span className={classes.label}>Üzenetek</span>
                    {counter.current.messages > 0 && (
                        <span className={classes.badge}>
                            {counter.current.messages}
                        </span>
                    )}
                </li>
                <li
                    style={{
                        display: isOpen ? "flex" : "none",
                    }}
                    onClick={() => handleRoute("routes.profile.appointments")}
                >
                    <CalendarIcon />
                    <span className={classes.label}>Időpontok</span>
                </li>
                {employer.data?.delivery === 1 && (
                    <>
                        <li
                            className={classes["mobile-menu"]}
                            onClick={() =>
                                handleRoute("routes.profile.medicine-delivery")
                            }
                        >
                            <DeliveryIcon />
                            <span className={classes.label}>
                                Gyógyszer kiszállítás
                            </span>
                        </li>
                        {/* I know it's not correct solution but atm we do not have time */}
                        {window.location.pathname.includes(
                            "gyogyszer-kiszallitas"
                        ) && (
                            <li
                                className={classes["mobile-menu"]}
                                style={{
                                    paddingLeft: 60,
                                    height: 30,
                                }}
                                onClick={() => {
                                    navigate(
                                        i18nRoute(
                                            "routes.profile.prescription-redeems"
                                        )
                                    );
                                }}
                            >
                                Igények
                            </li>
                        )}
                    </>
                )}

                <NextHealthMenu
                    style={{
                        display: isOpen ? "flex" : "none",
                    }}
                    badgeClass={classes.badge}
                />

                <li
                    style={{
                        display: isOpen ? "flex" : "none",
                    }}
                    onClick={() => handleRoute("routes.profile.base")}
                >
                    <SettingsIcon />
                    <span className={classes.label}>Fiók beállításai</span>
                </li>
                <li
                    style={{
                        display: isOpen ? "flex" : "none",
                    }}
                    onClick={() => handleRoute("routes.profile.invite")}
                >
                    <EmailIcon />
                    <span className={classes.label}>Barátok meghívása</span>
                </li>
            </ul>
            <Btn
                className={classes.button}
                onClick={() => handleRoute("routes.subscriptions")}
            >
                Előfizetések kezelése
            </Btn>
        </Paper>
    );
};

export default Sidebar;
