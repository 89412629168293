import cn from "classnames";
import classes from "./Paper.module.css";

const Paper = ({ children, className, style }) => (
    <div className={cn(classes.paper, className)} style={style}>
        {children}
    </div>
);

export default Paper;
