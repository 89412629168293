import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../assets/Theme";
import AlertLine from "../../components/UI/AlertLine/AlertLine";
import Breadcrumbs from "../../components/UI/Breadcrumbs/Breadcrumbs";
import PageHeader from "../../components/UI/PageHeader/PageHeader";
import ListingCard from "../../components/UI/ListingCard/ListingCard";
import Line from "../../components/UI/Line/Line";
import { endLoading, get } from "../../config/fetch";
import { removeAccents } from "../../utility";
import useTranslation from "../../features/i18n/useTranslation";
import classes from "./Doctors.module.css";
import DoctorFilter from "./DoctorFilter/DoctorFilter";

let filters = {
    abc: null,
    qualification: null,
    institution: null,
    service: null,
    language: null,
};

const Doctors = () => {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [filteredDoctors, setFilteredDoctors] = useState([]);
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        get("api/v1/medical/get-doctors").then((response) => {
            endLoading();
            setDoctors(response.data.data);
            setFilteredDoctors(response.data.data);
            setIsLoaded(true);
        });
    }, []);

    const formatText = (text) =>
        removeAccents(text)
            .toLowerCase()
            .trim()
            .replace(/\s+/g, "")
            .replace(".", "");

    const filterByQualification = (doctors, id) =>
        doctors.filter((doctor) =>
            doctor.qualifications.some((q) => q.id == id)
        );

    const filterByInstitution = (doctors, id) =>
        doctors.filter((doctor) => doctor.institutions.some((q) => q.id == id));

    const filterByLanguage = (doctors, id) =>
        doctors.filter((doctor) => doctor.languages.some((q) => q.id == id));

    const filterByService = (doctors, service) =>
        doctors.filter((doctor) => doctor[service] == "1");

    const filterByAbc = (doctors, arr) => {
        let filteredDoctors;
        const startCode = arr[0];
        const endCode = arr[1];
        filteredDoctors = doctors.filter((doctor) => {
            const code = doctor.lastName
                .toLowerCase()
                .substring(0, 1)
                .charCodeAt(0);

            return code >= startCode && code <= endCode;
        });

        return filteredDoctors;
    };

    const onFilterChange = (value, filter) => {
        switch (filter) {
            case "abc":
                if (value === 0) {
                    filters["abc"] = null;
                } else {
                    filters["abc"] = value;
                }

                break;
            case "qualification":
                if (value === 0) {
                    filters["qualification"] = null;
                } else {
                    filters["qualification"] = value;
                }

                break;
            case "institution":
                if (value === 0) {
                    filters["institution"] = null;
                } else {
                    filters["institution"] = value;
                }

                break;
            case "service":
                if (value === 0) {
                    filters["service"] = null;
                } else {
                    filters["service"] = value;
                }

                break;
            case "language":
                if (value === 0) {
                    filters["language"] = null;
                } else {
                    filters["language"] = value;
                }

                break;
            default:
                break;
        }
        let filteredDoctorArr = doctors;
        for (const f in filters) {
            if (filters[f]) {
                switch (f) {
                    case "abc":
                        filteredDoctorArr = filterByAbc(
                            filteredDoctorArr,
                            filters[f]
                        );

                        break;
                    case "qualification":
                        filteredDoctorArr = filterByQualification(
                            filteredDoctorArr,
                            filters[f]
                        );

                        break;
                    case "institution":
                        filteredDoctorArr = filterByInstitution(
                            filteredDoctorArr,
                            filters[f]
                        );

                        break;
                    case "service":
                        filteredDoctorArr = filterByService(
                            filteredDoctorArr,
                            filters[f]
                        );

                        break;
                    case "language":
                        filteredDoctorArr = filterByLanguage(
                            filteredDoctorArr,
                            filters[f]
                        );

                        break;
                    default:
                        break;
                }
            }
        }
        setFilteredDoctors(filteredDoctorArr);
    };

    const onSearchTextChange = (text) => {
        if (text.length < 3) {
            setFilteredDoctors(doctors);

            return;
        }
        let filteredDoctorArr = doctors.filter((doctor) => {
            const nameString = formatText(doctor.name);
            const qualificationArr = doctor.qualifications.map((q) => q.name);
            const qualificationString = formatText(qualificationArr.join(""));

            return (
                nameString.includes(text) || qualificationString.includes(text)
            );
        });
        setFilteredDoctors(filteredDoctorArr);
    };

    const onAlertClick = () => navigate(i18nRoute("routes.services.duty"));

    const resetFilters = () => {
        setFilteredDoctors(doctors);
        filters = {
            abc: null,
            qualification: null,
            institution: null,
            service: null,
            language: null,
        };
    };

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs />
                <PageHeader text="Orvosaink" style={styles.PageHeader} />
                <AlertLine
                    className={classes.AlertLine}
                    icon={Icons.dutyBlack}
                    text="Ha azonnal orvossal szeretnél beszélni, hívd az év 365 napján 12 órában elérhető általános orvosi konzultációt.                    "
                    actionText="Hívás indítása"
                    style={{ marginBottom: "4rem" }}
                    onClick={onAlertClick}
                />
                {isLoaded && (
                    <DoctorFilter
                        doctorsData={doctors}
                        onSearchTextChange={(text) => onSearchTextChange(text)}
                        onFilterChange={(value, filter) =>
                            onFilterChange(value, filter)
                        }
                        onResetFilters={resetFilters}
                    />
                )}
                <Line
                    style={{ backgroundColor: "#263D50", marginBottom: "4rem" }}
                ></Line>
                <div className={classes.doctorsContainer}>
                    {filteredDoctors.map((element) => (
                        <div key={element.id} className={classes.cardContainer}>
                            <ListingCard
                                type="doctor"
                                onClick={() =>
                                    navigate({
                                        pathname: `/orvosaink/${element.profileLink}`,
                                    })
                                }
                                image={element.image}
                                name={element.name}
                                qualification={element.qualifications
                                    .map((q) => q.name)
                                    .join(", ")}
                            />
                        </div>
                    ))}
                    {filteredDoctors.length === 0 && isLoaded && (
                        <span className={classes.noMatch}>
                            Nem található a keresésnek megfelelő orvos.
                        </span>
                    )}
                </div>
            </div>
        </Container>
    );
};

const styles = {
    PageHeader: {
        marginBottom: "3rem",
    },
};

export default Doctors;
