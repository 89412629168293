import { Box } from "@material-ui/core";
import Btn from "../../../../components/UI/Btn/Btn";

interface Props {
    type: "contained" | "outlined";
    url: string;
}

const ForwardButton = ({ type, url }: Props) => {
    const handleClick = (url: string) => {
        window.location.href = url;
    };

    const styles = {
        color: "white",
        borderColor: "white",
        height: "54px",
        width: "384px",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
    };

    return (
        <Box mt={3}>
            <Btn
                style={{ ...styles }}
                color={type === "contained" ? "green" : "transparent"}
                onClick={() => handleClick(url)}
            >
                Tovább
            </Btn>
        </Box>
    );
};

export default ForwardButton;
