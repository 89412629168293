import * as actionTypes from "../actions/actionTypes";

const initialState = {
    basic: true,
    analitics: false,
    cookieSet: false,
};

const setCookieSetting = (state, action) => {
    let cookieSettings = JSON.parse(localStorage.getItem("cookieSettings"));
    cookieSettings[action.cookie] = action.enabled;
    cookieSettings["cookieSet"] = true;
    localStorage.setItem("cookieSettings", JSON.stringify(cookieSettings));

    return {
        ...state,
        [action.cookie]: action.enabled,
        cookieSet: true,
    };
};

const setCookieSettings = (state, action) => {
    localStorage.setItem("cookieSettings", JSON.stringify(action.cookies));

    return {
        ...state,
        ...action.cookies,
    };
};

const reducer = (state = initialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case actionTypes.SET_COOKIE:
            return setCookieSetting(state, action);
        case actionTypes.SET_COOKIE_SETTINGS:
            return setCookieSettings(state, action);
    }

    return state;
};

export default reducer;
