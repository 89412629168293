import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "@material-ui/core";
import Breadcrumbs from "../../components/UI/Breadcrumbs/Breadcrumbs";
import CardContainer from "../../components/UI/CardContainer/CardContainer";
import { endLoading, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import useQuery from "../../features/util/useQuery";
import classes from "./DocumentPage.module.css";

interface DocumentHtml {
    content: string;
}

const DocumentPage = () => {
    const query = useQuery();
    const location = useLocation();
    const [document, setDocument] = useState<DocumentHtml>();
    const inApp = query.get("app");

    useEffect(() => {
        let documentName;

        if (location.pathname === "/aszf") {
            documentName = "TERMS_AND_CONDITIONS";
        } else if (location.pathname === "/adatvedelmi_nyilatkozat") {
            documentName = "PRIVACY_POLICY";
        } else {
            return;
        }

        post("api/v1/document/get-document", { name: documentName })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setDocument(response.data.document);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, [location.pathname]);

    return (
        <Container>
            <div className={classes.inner}>
                {!inApp ? <Breadcrumbs /> : null}

                <CardContainer className={classes.card}>
                    {document && (
                        <div
                            // className={classes.content}
                            className="editor-container document-edited"
                            // style={props.contentContainerStyle}
                            dangerouslySetInnerHTML={{
                                __html: document.content,
                            }}
                        ></div>
                    )}
                </CardContainer>
            </div>
        </Container>
    );
};

export default DocumentPage;
