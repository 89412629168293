import { useRef } from "react";
import Slider from "react-slick";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Grid, Hidden } from "@material-ui/core";
import PersonIntro from "../PersonIntro/PersonIntro";
import classes from "./CustomSlider.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const CustomSlider = (props) => {
    const navigate = useNavigate();
    const slider = useRef(null);
    const sliderSettings = {
        dots: false,
        arrows: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: props.slides ? props.slides[0] : 4,
        infinite: props.data.length > (props.slides ? props.slides[0] : 4),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: props.slides ? props.slides[1] : 3,
                    infinite:
                        props.data.length >
                        (props.slides ? props.slides[1] : 3),
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: props.slides ? props.slides[2] : 2,
                    infinite:
                        props.data.length >
                        (props.slides ? props.slides[2] : 2),
                },
            },
        ],
    };

    if (props.data && props.data.length > 0) {
        return (
            <Grid container>
                <Hidden smDown>
                    <Grid item md={1}>
                        <div
                            className={`${classes.navigation} ${classes.navigationLeft}`}
                            onClick={() => slider.current.slickPrev()}
                        >
                            <ChevronLeftIcon classes={{ root: classes.icon }} />
                        </div>
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={10}>
                    <div
                        className={classes.sliderContainer}
                        style={props.style}
                    >
                        <Slider {...sliderSettings} ref={slider}>
                            {props.data.map((item) => {
                                if (props.people) {
                                    let qualifications = [];
                                    if (item.qualifications) {
                                        for (let q of item.qualifications) {
                                            qualifications.push(q.name);
                                        }
                                    }

                                    return (
                                        <div
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/orvosaink/${item.profileLink}`,
                                                })
                                            }
                                            key={item.id}
                                        >
                                            <PersonIntro
                                                image={item.image}
                                                name={item.name}
                                                withAvailability={
                                                    props.withAvailability
                                                }
                                                available={item.available}
                                                qualification={
                                                    qualifications.length > 0
                                                        ? qualifications
                                                              .sort((a, b) =>
                                                                  a.localeCompare(
                                                                      b
                                                                  )
                                                              )
                                                              .join(", ")
                                                        : item.qualification
                                                }
                                                style={styles.person}
                                            />
                                        </div>
                                    );
                                }

                                return (
                                    <div key={item.id}>
                                        {props.renderFunction(item)}
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </Grid>
                <Hidden smDown>
                    <Grid item md={1}>
                        <div
                            className={`${classes.navigation} ${classes.navigationRight}`}
                            onClick={() => slider.current.slickNext()}
                        >
                            <ChevronRightIcon
                                classes={{ root: classes.icon }}
                            />
                        </div>
                    </Grid>
                </Hidden>

                <Hidden mdUp>
                    <Grid item xs={12}>
                        <div className={classes.navigationContainer}>
                            <div
                                className={`${classes.navigation} ${classes.navigationLeft}`}
                                onClick={() => slider.current.slickPrev()}
                            >
                                <ChevronLeftIcon
                                    classes={{ root: classes.icon }}
                                />
                            </div>
                            <div
                                className={`${classes.navigation} ${classes.navigationRight}`}
                                onClick={() => slider.current.slickNext()}
                            >
                                <ChevronRightIcon
                                    classes={{ root: classes.icon }}
                                />
                            </div>
                        </div>
                    </Grid>
                </Hidden>
            </Grid>
        );
    }

    return false;
};

const styles = {
    person: {
        width: "95%",
        marginLeft: "auto",
        marginRight: "auto",
    },
};

export default CustomSlider;
