import { combineReducers, configureStore } from "@reduxjs/toolkit";
import "../assets/reset.css";
import "../index.css";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
    useDispatch as useDispatchBase, // eslint-disable-line @typescript-eslint/no-restricted-imports
    useSelector as useSelectorBase, // eslint-disable-line @typescript-eslint/no-restricted-imports
} from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { baseApi as api } from "tdoc-common";
import type { Dispatch } from "@reduxjs/toolkit";
import { authReducer } from "../features/auth/slice";
import commonReducer from "./reducers/common";
import uiReducer from "./reducers/ui";
import cookieReducer from "./reducers/cookie";
import chatReducer from "./reducers/chat";
import userReducer from "./reducers/user";
import reservationReducer from "./reducers/reservation";
import functionsReducer from "./reducers/functions";
import { toggleLoginModal } from "./actions";

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    common: commonReducer,
    ui: uiReducer,
    cookie: cookieReducer,
    chat: chatReducer,
    user: userReducer,
    reservation: reservationReducer,
    functions: functionsReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            thunk: {
                extraArgument: {
                    onAuthRequired: (dispatch: Dispatch) => {
                        dispatch(toggleLoginModal(true));
                    },
                },
            },
        }).concat(api.middleware),
});

setupListeners(store.dispatch);

type AppDispatch = typeof store.dispatch;
export const useDispatch = () => useDispatchBase<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorBase;

export default store;
