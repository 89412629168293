import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../../UI/Modal/Modal";
import Button from "../../UI/Button/Button";
import Chbx from "../../UI/Checkbox/Checkbox";
import { Images } from "../../../assets/Theme";
import { formatNumber, responseMessage } from "../../../utility";
import { endLoading, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import * as actions from "../../../store/actions/index";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./ServicePaymentModal.module.css";

// TODO: bejelentkezési modal után hova irányítsuk a usert
const ServicePaymentModal = (props) => {
    const navigate = useNavigate();
    const [oneClick, toggleOneClick] = useState(false);
    const [termsAccepted, toggleTerms] = useState(false);
    const [terms2Accepted, toggleTerms2] = useState(false);
    const { i18nRoute } = useTranslation();

    const buttonContinueHandler = () => {
        if (oneClick) {
            if (!termsAccepted || !terms2Accepted) {
                responseMessage(
                    "Az adattovábbítási- és az ismétlődő fizetésről történő nyilatkozat elfogadása kötelező!"
                );

                return false;
            }
            if (props.preScript) {
                props.preScript();
            }
            initPayment();
        } else {
            if (!termsAccepted) {
                responseMessage(
                    "Az adattovábbítási nyilatkozat elfogadása kötelező!"
                );

                return false;
            }
            if (props.preScript) {
                props.preScript();
            }
            initPayment();
        }
    };

    const returnServiceName = (service) => {
        if (props.serviceText) {
            return props.serviceText;
        }
        switch (service) {
            case "HOTLINE":
                return "Egészségügyi hotline";
            case "DUTY":
                return "Orvosi ügyelet";
            case "CHAT":
                return "Orvosi chat";
            case "SECOND_OPINION":
                return "Orvosi másodvélemény";
            case "PRESCRIPTION":
                return "E-recept";
            case "OUTPATIENT":
                return "Járóbeteg időpontfoglalás";
            case "VIDEO_CONSULTATION":
                return "Videó konzultáció";
            default:
                return props.service;
        }
    };

    const initPayment = () => {
        localStorage.setItem("newCardName", "Bankkártya");
        post("api/v1/payment/add-card", {
            addType: !props.packageId ? "service" : "subscription_and_service",
            service: props.service,
            from: window.location.href,
            oneClick,
            identifier: props.identifier,
            packageId: props.packageId,
            appointmentData: props.appointmentData,
            appointmentId: props.appointmentData
                ? props.appointmentData.id
                : null,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        if (
                            props.service === "OUTPATIENT" ||
                            props.service === "VIDEO_CONSULTATION"
                        ) {
                            sessionStorage.setItem(
                                `reservation${response.data.transactionId}`,
                                JSON.stringify(props.appointmentData)
                            );
                        }
                        window.location.href = response.data.url;

                        break;
                    case "MISSING_DATA":
                        responseMessage(
                            "Hiányzó számlázási adatok! Kérjük, először számlázási adataid add meg.",
                            null,
                            () => navigate(i18nRoute("routes.profile.user"))
                        );

                        break;
                    case "MISSING_PERSONAL_DATA":
                        responseMessage(
                            "Hiányzó személyes adatok! Kérjük, először személyes adataid add meg.",
                            null,
                            () => navigate(i18nRoute("routes.profile.user"))
                        );

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                endLoading();
                console.error(error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const backHandler = () => props.toggleServicePaymentModal(false);

    return (
        <Modal
            open={props.open}
            key="ServicePaymentModal"
            closeModal={backHandler}
        >
            <div className={`${classes.ServicePaymentModal}`}>
                <div className={classes.ServicePaymentModalInner}>
                    <div className={classes.headerRow}>
                        <div className={classes.line}></div>
                        <span className={classes.header}>Fizetés</span>
                        <div className={classes.line}></div>
                    </div>
                    <p className={classes.payment}>
                        {returnServiceName(props.service)}:{" "}
                        {props.price && formatNumber(props.price)} Ft
                    </p>
                    <Chbx
                        style={{ alignSelf: "flex-start" }}
                        onChange={(checked) => toggleOneClick(checked)}
                        label={
                            <p>
                                Bankkártya mentése a későbbi könnyebb fizetés
                                érdekében.
                            </p>
                        }
                    />
                    <Chbx
                        onChange={(checked) => toggleTerms(checked)}
                        label={
                            <p>
                                Elfogadom, hogy a T-DOC Service Kft. (1138
                                Budapest, Népfürdő utca 22. B. ép. 15. em.)
                                által a www.t-doc.hu (a továbbiakban kereskedő)
                                felhasználói adatbázisában tárolt alábbi
                                személyes adataim átadásra kerüljenek a BIG FISH
                                Payment Services Kft. (székhely: 1066 Budapest,
                                Nyugati tér 1-2.), mint adatfeldolgozó részére.
                                A továbbított adatok köre: név-előtag,
                                vezetéknév, keresztnév, IP-cím, számlázási cím,
                                szállítási cím, telefonszám, e-mail cím,
                                bankkártyaszám első hat és utolsó négy
                                számjegye, bankkártya márkája. Az adattovábbítás
                                célja: a fizetési tranzakciókhoz szükséges
                                adatkommunikáció lebonyolítása a kereskedő és a
                                fizetési szolgáltató rendszere között, illetve
                                az adatok tárolása a tranzakciós logokban a
                                tranzakciós adatok kereskedő részére történő
                                visszakereshetőségének biztosítása céljából. Az
                                adatfeldolgozó által végzett adatfeldolgozási
                                tevékenység jellege és célja a SimplePay
                                Adatkezelési tájékoztatóban, az alábbi linken
                                tekinthető meg:{" "}
                                <a
                                    href="http://simplepay.hu/vasarlo-aff"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    http://simplepay.hu/vasarlo-aff
                                </a>
                            </p>
                        }
                    />
                    {oneClick && (
                        <Chbx
                            onChange={(checked) => toggleTerms2(checked)}
                            label={
                                <p>
                                    Az ismétlődő bankkártyás fizetés
                                    (továbbiakban: „Ismétlődő fizetés”) egy, a
                                    SimplePay által biztosított bankkártya
                                    elfogadáshoz tartozó funkció, mely azt
                                    jelenti, hogy a Vásárló által a
                                    regisztrációs tranzakció során megadott
                                    bankkártyaadatokkal a jövőben újabb
                                    fizetéseket lehet kezdeményezni a
                                    bankkártyaadatok újbóli megadása nélkül.
                                    <br />
                                    Az Ismétlődő fizetés igénybevételéhez jelen
                                    nyilatkozat elfogadásával Ön hozzájárul,
                                    hogy a sikeres regisztrációs tranzakciót
                                    követően jelen webshopban (https://t-doc.hu)
                                    kezdeményezett későbbi fizetések a
                                    bankkártyaadatok újbóli megadása és az Ön
                                    tranzakciónként hozzájárulása nélkül a
                                    Kereskedő által kezdeményezve történjenek.{" "}
                                    <br />
                                    Figyelem(!): a bankkártyaadatok kezelése a
                                    kártyatársasági szabályoknak megfelelően
                                    történik. A bankkártyaadatokhoz sem a
                                    Kereskedő, sem a SimplePay nem fér hozzá.
                                    <br />
                                    A Kereskedő által tévesen vagy jogtalanul
                                    kezdeményezett ismétlődő fizetéses
                                    tranzakciókért közvetlenül a Kereskedő
                                    felel, Kereskedő fizetési szolgáltatójával
                                    (SimplePay) szemben bármilyen
                                    igényérvényesítés kizárt.
                                    <br />
                                    Jelen tájékoztatót átolvastam, annak
                                    tartalmát tudomásul veszem és elfogadom.
                                </p>
                            }
                        />
                    )}
                    <div style={{ textAlign: "center" }}>
                        <img
                            className={classes.paymentImage}
                            src={Images.simple}
                            alt=""
                        />
                        <img
                            className={classes.paymentImage}
                            src={Images.bigfish}
                            alt=""
                        />
                        <p className={classes.paymentInfo}>
                            Az online fizetést az OTP Simple Pay és a Big Fish
                            Payment Gateway biztosítja.
                        </p>
                    </div>
                    <Button
                        text="Tovább a fizetési oldalra"
                        className={classes.button}
                        onClick={buttonContinueHandler}
                    />
                    <p className={classes.link} onClick={backHandler}>
                        Mégsem
                    </p>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    open: state.ui.servicePaymentModal.show,
    price: state.ui.servicePaymentModal.price,
    service: state.ui.servicePaymentModal.service,
    preScript: state.ui.servicePaymentModal.preScript,
    identifier: state.ui.servicePaymentModal.identifier,
    packageId: state.ui.servicePaymentModal.packageId,
    serviceText: state.ui.servicePaymentModal.serviceText,
    appointmentData: state.ui.servicePaymentModal.appointmentData,
});

const mapDispatchToProps = (dispatch) => ({
    toggleServicePaymentModal: (show) =>
        dispatch(actions.toggleServicePaymentModal(show)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServicePaymentModal);
