import { Container } from "@material-ui/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardContainer from "../UI/CardContainer/CardContainer";
import PageHeader from "../UI/PageHeader/PageHeader";
import Button from "../UI/Button/Button";
import { Icons } from "../../assets/Theme";
import useTranslation from "../../features/i18n/useTranslation";
import useQuery from "../../features/util/useQuery";
import classes from "./ServiceInitiated.module.css";

const ServiceInitiated = () => {
    const navigate = useNavigate();
    const query = useQuery();
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const callBody = (
        <div className={classes.body}>
            <img
                src={Icons.phoneMessageGreen}
                className={classes.bodyIcon}
                alt=""
            />
            <p className={classes.bodyText}>
                A hívás indításához szükséges számot elküldtük az általad
                megadott telefonszámra. A folytatáshoz kérjük tárcsázd az alábbi
                telefonszámot:
            </p>
            <p className={classes.bodyText}>
                <a href="tel:+3615333150" className={classes.link}>
                    +36 1 533 31 50
                </a>
            </p>
        </div>
    );

    const callBodyHotline = (
        <div className={classes.body}>
            <img
                src={Icons.phoneMessageGreen}
                className={classes.bodyIcon}
                alt=""
            />
            <p className={classes.bodyText}>
                A hívás indításához szükséges számot elküldtük az általad
                megadott telefonszámra. A folytatáshoz kérjük tárcsázd az alábbi
                telefonszámot:
            </p>
            <p className={classes.bodyText}>
                <a href="tel:+3615333145" className={classes.link}>
                    +36 1 533 31 45
                </a>
            </p>
        </div>
    );

    const chatBody = (
        <div className={classes.body}>
            <p className={classes.text}>Átlagos válaszidő:</p>
            <p className={classes.responseTime}>Kevesebb, mint 48 óra</p>
            <img
                src={Icons.envelopeGreen}
                className={classes.bodyIcon}
                alt=""
            />
            <p className={classes.bodyText}>
                Üzeneteit és más előzményeket bármikor megtekintheti a Saját
                fiókjában.
            </p>
        </div>
    );

    const secondOpinionBody = (
        <div className={classes.body}>
            <p className={classes.text}>Átlagos válaszidő:</p>
            <p className={classes.responseTime}>Kevesebb, mint 72 óra</p>
            <img
                src={Icons.envelopeGreen}
                className={classes.bodyIcon}
                alt=""
            />
            <p className={classes.bodyText}>
                Üzeneteit és más előzményeket bármikor megtekintheti a Saját
                fiókjában.
            </p>
        </div>
    );

    const renderBody = () => {
        const service = query.get("service");
        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        switch (service) {
            case "chat":
            case "prescription":
                return chatBody;
            case "second-opinion":
                return secondOpinionBody;
            case "hotline":
                return callBodyHotline;
            case "duty":
                return callBody;
        }
    };

    const buttonClickHandler = () =>
        navigate(i18nRoute("routes.profile.history"));

    return (
        <Container>
            <div className={classes.innerContainer}>
                <CardContainer className={classes.cardContainer}>
                    <PageHeader text="Elküldve" style={styles.pageHeader} />
                    <div className={classes.tickContainer}>
                        <img
                            src={Icons.tickGreen}
                            className={classes.tick}
                            alt=""
                        />
                    </div>
                    {renderBody()}
                    <Button
                        text="Kész"
                        className={classes.button}
                        onClick={buttonClickHandler}
                    />
                </CardContainer>
            </div>
        </Container>
    );
};

const styles = {
    pageHeader: {
        marginTop: 0,
        marginBottom: "3rem",
    },
};

export default ServiceInitiated;
