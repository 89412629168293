import type { ContentBlock } from "../../../ui/models";
import { ContentTypes } from "../../../ui/models";

export const mockApiPayload: ContentBlock[] = [
    {
        type: ContentTypes.CAROUSEL,
        images: [
            "https://img.pixers.pics/pho_wat(s3:700/FO/31/27/80/86/700_FO31278086_6a465dc4378e23a6fa2d2400f76395be.jpg,648,700,cms:2018/10/5bd1b6b8d04b8_220x50-watermark.png,over,428,650,jpg)/stickers-surgeons-in-operative-room.jpg.jpg",
            "https://png.pngtree.com/illustrations/20190321/ourlarge/pngtree-hospital-doctors-120-first-aid-medical-png-image_25674.jpg",
            "https://us.123rf.com/450wm/chudtsankov/chudtsankov1308/chudtsankov130800439/21699409-smiling-mad-scientist-or-professor-holding-a-frog.jpg?ver=6",
        ],
    },
    {
        type: ContentTypes.TEXTFIELD,
        text: "<h1>Not contained with no url</h1><h2>Not contained with no url</h2><h3>Not contained with no url</h3><h4>Not contained with no url</h4>Not contained with no url<br /><small>Not contained with no url</small>",
    },
    {
        type: ContentTypes.TEXTFIELD,
        text: "<h1>Contained with no url</h1><h2>Contained with no url</h2><h3>Contained with no url</h3><h4>Contained with no url</h4>Contained with no url<br /><small>Contained with no url</small>",
        contained: true,
    },
    {
        type: ContentTypes.TEXTFIELD,
        text: "<h1>Not contained with url</h1><h2>Not contained with url</h2><h3>Not contained with url</h3><h4>Not contained with url</h4>Not contained with url<br /><small>Not contained with url</small>",
        url: "https://motley.hu/",
    },
    {
        type: ContentTypes.TEXTFIELD,
        text: "<h1>Contained with url</h1><h2>Contained with url</h2><h3>Contained with url</h3><h4>Contained with url</h4>Contained with url<br /><small>Contained with url</small>",
        contained: true,
        url: "https://motley.hu/",
    },
    {
        type: ContentTypes.CAROUSEL,
        sectionLabel: "Carousel section",
        images: [
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMQvIDUZyZUxYY5AOwfFWuIaEHqCdt1QP5ghd7-p6z54MjTZguHe_0OASH_jcRdg4ssNk&usqp=CAU",
            "https://us.123rf.com/450wm/chudtsankov/chudtsankov1207/chudtsankov120700003/14466447-happy-doctor-holding-syringe-and-waving-for-greetings.jpg?ver=6",
            "https://st.depositphotos.com/1695366/1397/v/380/depositphotos_13979309-stock-illustration-cartoon-doctor-with-a-patient.jpg?forcejpeg=true",
        ],
    },
    {
        type: ContentTypes.TEXTFIELD,
        sectionLabel: "Textfield section",
        text: "<h1>Lorem ipsum 2</h1><h2>Lorem ipsum 2</h2><h3>Lorem ipusm 2</h3><h4>Lorem ipsum 2</h4>Lorem ipsum 2<br /><small>Lorem ipsum 2</small>",
    },
    {
        type: ContentTypes.GALLERY,
        sectionLabel: "Gallery section",
        galleryDescription:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        galleryItems: [
            {
                image: "https://img.pixers.pics/pho_wat(s3:700/FO/31/27/80/86/700_FO31278086_6a465dc4378e23a6fa2d2400f76395be.jpg,648,700,cms:2018/10/5bd1b6b8d04b8_220x50-watermark.png,over,428,650,jpg)/stickers-surgeons-in-operative-room.jpg.jpg",
                title: "Cím 1",
                description: "Leírás 1",
            },
            {
                image: "https://png.pngtree.com/illustrations/20190321/ourlarge/pngtree-hospital-doctors-120-first-aid-medical-png-image_25674.jpg",
                title: "Cím 2",
                description: "Leírás 2",
            },
            {
                image: "https://us.123rf.com/450wm/chudtsankov/chudtsankov1308/chudtsankov130800439/21699409-smiling-mad-scientist-or-professor-holding-a-frog.jpg?ver=6",
                title: "Cím 3",
                description: "Leírás 3",
            },
            {
                image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMQvIDUZyZUxYY5AOwfFWuIaEHqCdt1QP5ghd7-p6z54MjTZguHe_0OASH_jcRdg4ssNk&usqp=CAU",
                title: "Cím 4",
                description: "Leírás 4",
            },
            {
                image: "https://us.123rf.com/450wm/chudtsankov/chudtsankov1207/chudtsankov120700003/14466447-happy-doctor-holding-syringe-and-waving-for-greetings.jpg?ver=6",
                title: "Cím 5",
                description: "Leírás 5",
            },
            {
                image: "https://st.depositphotos.com/1695366/1397/v/380/depositphotos_13979309-stock-illustration-cartoon-doctor-with-a-patient.jpg?forcejpeg=true",
                title: "Cím 6",
                description: "Leírás 6",
            },
        ],
    },
    {
        type: ContentTypes.IMAGEWITHTEXT,
        sectionLabel: "Image with text section",
        image: "https://img.pixers.pics/pho_wat(s3:700/FO/31/27/80/86/700_FO31278086_6a465dc4378e23a6fa2d2400f76395be.jpg,648,700,cms:2018/10/5bd1b6b8d04b8_220x50-watermark.png,over,428,650,jpg)/stickers-surgeons-in-operative-room.jpg.jpg",
        text: "<h1>Lorem ipsum lorem ipsum lorem ipsum</h1><h4>lorem ipsum lorem ipsum lorem ipsum</h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum libero nec ornare tempor. Aenean tincidunt erat id tristique maximus. Aliquam iaculis tristique massa, ut bibendum neque dapibus et. Aliquam sollicitudin ex quis volutpat volutpat. Etiam at risus id mauris pellentesque posuere. Quisque convallis magna lectus, vel facilisis risus blandit id. Fusce at pharetra dui, vitae semper dui. Nunc a maximus elit. Curabitur consequat ipsum quis pharetra dictum. Duis ac elementum orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum libero nec ornare tempor. Aenean tincidunt erat id tristique maximus. Aliquam iaculis tristique massa, ut bibendum neque dapibus et. Aliquam sollicitudin ex quis volutpat volutpat. Etiam at risus id mauris pellentesque posuere. Quisque convallis magna lectus, vel facilisis risus blandit id. Fusce at pharetra dui, vitae semper dui. Nunc a maximus elit.",
        url: "https://motley.hu/",
    },
    {
        type: ContentTypes.SINGLEIMAGE,
        sectionLabel: "Single Image section",
        image: "https://img.pixers.pics/pho_wat(s3:700/FO/31/27/80/86/700_FO31278086_6a465dc4378e23a6fa2d2400f76395be.jpg,648,700,cms:2018/10/5bd1b6b8d04b8_220x50-watermark.png,over,428,650,jpg)/stickers-surgeons-in-operative-room.jpg.jpg",
    },
    {
        type: ContentTypes.VIDEOSECTION,
        sectionLabel: "Video section",
        video: "https://www.youtube.com/embed/A-6Iy8F0Z4c",
        description:
            "<h1>Lorem ipsum</h1><h2>Lorem ipsum</h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum libero nec ornare tempor. Aenean tincidunt erat id tristique maximus. Aliquam iaculis tristique massa, ut bibendum neque dapibus et. Aliquam sollicitudin ex quis volutpat volutpat. Etiam at risus id mauris pellentesque posuere. Quisque convallis magna lectus, vel facilisis risus blandit id. Fusce at pharetra dui, vitae semper dui. Nunc a maximus elit. Curabitur consequat ipsum quis pharetra dictum. Duis ac elementum orci.",
    },
    {
        type: ContentTypes.FORMSECTION,
        sectionLabel: "Form section",
        description:
            "Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    },
];
