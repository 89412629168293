import { useEffect, useRef, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { endLoading, get, post } from "../../../config/fetch";
import { useLogoutMutation } from "../../../features/auth/api";
import useTranslation from "../../../features/i18n/useTranslation";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import useQuery from "../../../features/util/useQuery";
import classes from "./Profile.module.css";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
// refacted layout UI elements to display Paperish design
import ScrollContainer from "../atoms/ScrollContainer";
import Paper from "../atoms/Paper";
import PaperSection from "../atoms/PaperSection";
import Headline from "../atoms/Headline";
import Tabs from "../molecules/Tabs";
// import PageSubHeader from "../atoms/PageSubHeader";
import PaymentSettings from "../organisms/PaymentSettings";
import NotificationSettings from "../organisms/NotificationSettings";
import SecuritySettings from "../organisms/SecuritySettings";
// import DiseasesSettings from "../organisms/DiseasesSettings";
import ProfileSettings from "../organisms/ProfileSettings";
import InnerLayout from "../templates/InnerLayout";
import "../typo.css";
import UserDataModal from "../../../components/Modals/UserDataModal/UserDataModal";
import SubscriptionPaymentModal from "../../../components/Modals/SubscriptionPaymentModal/SubscriptionPaymentModal";
import FreeSubscriptionModal from "../../../components/Modals/FreeSubscriptionModal/FreeSubscriptionModal";

const Profile = (props) => {
    const scrollRef = useRef(null);
    const [logout] = useLogoutMutation();
    const navigate = useNavigate();
    const query = useQuery();

    const [freeSubscriptionModalOpen, toggleFreeSubscriptionModal] =
        useState(false);
    //Az első reges adatbekérő modal
    const [userDataModalStep, setUserDataModalStep] = useState(-1);
    const [userData, setUserData] = useState({
        birthPlace: null,
        birthDate: new Date(),
        securityNumber: null,
        mothersName: null,
        billingName: null,
        country: "Magyarország",
        zip: null,
        settlement: null,
        street: null,
        houseNumber: null,
        floorDoor: null,
        taxNumber: null,
        selectedPackage: null,
        packagePrice: null,
        redeemHash: null,
    });

    const { i18nRoute } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const firstLogin = query.get("firstLogin");
        if (firstLogin === "true") {
            // setUserDataModalStep(0);
            toggleFreeSubscriptionModal(true);
        }
    }, [query]);

    useEffect(() => {
        get("user/get-user-data")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        props.setSubscription(+response.data.subscription.id);

                        break;
                    }
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeFreeSubscriptionModal = () => {
        toggleFreeSubscriptionModal(false);
        setUserDataModalStep(0);
    };

    const closeModalHandler = () => {
        setUserDataModalStep(-1);
        navigate(i18nRoute("routes.profile.base"), { replace: true });
    };

    const setUserDataByKey = (key, value) =>
        setUserData((prevState) => ({
            ...prevState,
            [key]: value,
        }));

    const deleteProfile = () =>
        post("api/v1/user/delete-profile")
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Felhasználói fiók törölve!");
                        logout();
                        navigate("/");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const deleteProfileHandler = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content:
                "Biztosan törölni szeretnéd felhasználói fiókod? A fiók törlésével minden adatot törlünk, a folyamat nem visszavonható.",
            confirmButton: true,
            confirmButtonText: "Törlés",
            confirmButtonCallback: deleteProfile,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    return (
        <div className={classes.intro}>
            <Container>
                <InnerLayout>
                    <Paper>
                        <Headline>
                            <Breadcrumbs />
                        </Headline>

                        <Tabs
                            tabs={[
                                {
                                    label: "Személyes adatok",
                                    scrollToId: "#profile-settings",
                                },
                                /* {
                                label: "Alapbetegségek",
                                scrollToId: "#diseases-settings",
                            }, */
                                {
                                    label: "Fizetési beállítások",
                                    scrollToId: "#payment-settings",
                                },
                                {
                                    label: "Biztonsági beállítások",
                                    scrollToId: "#security-settings",
                                },
                                {
                                    label: "Értesítések beállításai",
                                    scrollToId: "#notification-settings",
                                },
                            ]}
                            scrollRef={scrollRef}
                        />
                        <ScrollContainer ref={scrollRef}>
                            <PaperSection
                                id="profile-settings"
                                className={classes.PaperSection}
                            >
                                <ProfileSettings />
                            </PaperSection>
                            {/* <PaperSection
                            id="diseases-settings"
                            className={classes.PaperSection}
                        >
                            <PageSubHeader className={classes.PageSubHeader}>
                                Van vagy volt-e az alábbiak közül bármilyen
                                betegsége?
                            </PageSubHeader>
                            <DiseasesSettings />
                        </PaperSection> */}
                            <PaperSection
                                id="payment-settings"
                                className={classes.PaperSection}
                            >
                                <PaymentSettings />
                            </PaperSection>
                            <PaperSection
                                id="security-settings"
                                className={classes.PaperSection}
                            >
                                <SecuritySettings />
                            </PaperSection>
                            <PaperSection
                                id="notification-settings"
                                className={classes.PaperSection}
                            >
                                <NotificationSettings />
                            </PaperSection>
                            <PaperSection
                                id="delete-profile"
                                className={classes.PaperSection}
                            >
                                <Grid container item xs={12}>
                                    <Grid item xs={12} md={8}>
                                        <p>Törölni szeretné a fiókját?</p>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        style={{ textAlign: "right" }}
                                    >
                                        <button
                                            onClick={deleteProfileHandler}
                                            className={classes.btnDeleteProfile}
                                        >
                                            Fiók törlése
                                        </button>
                                    </Grid>
                                </Grid>
                            </PaperSection>
                        </ScrollContainer>
                    </Paper>
                </InnerLayout>
            </Container>
            {/* MODALS */}
            <UserDataModal
                open={userDataModalStep === 0 || userDataModalStep === 1}
                userData={userData}
                setUserDataByKey={setUserDataByKey}
                closeModal={closeModalHandler}
                userDataModalStep={userDataModalStep}
                setUserDataModalStep={setUserDataModalStep}
                toggleFreeSubscriptionModal={toggleFreeSubscriptionModal}
            />
            <SubscriptionPaymentModal
                open={userDataModalStep === 3}
                closeModal={closeModalHandler}
                userData={userData}
                setUserDataByKey={setUserDataByKey}
                setUserDataModalStep={setUserDataModalStep}
            />
            <FreeSubscriptionModal
                open={freeSubscriptionModalOpen}
                closeModal={closeFreeSubscriptionModal}
                setUserDataByKey={setUserDataByKey}
                openUserDataModal={() => setUserDataModalStep(0)}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
    setSubscription: (subscription) =>
        dispatch(actions.setSubscription(subscription)),
});

export default connect(null, mapDispatchToProps)(Profile);
