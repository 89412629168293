import { Box } from "@material-ui/core";
import { Element } from "react-scroll";
import { useNavigate } from "react-router-dom";
import useTranslation from "../i18n/useTranslation";
import HeroSection from "./sections/HeroSection";
import ChatServiceSection from "./sections/ChatServiceSection";
import BenefitListSection from "./sections/BenefitListSection";
import PromotionSection from "./sections/PromotionSection";

const MedicalChatLanding = () => {
    const { i18nRoute } = useTranslation();
    const navigate = useNavigate();

    const writeMessage = () =>
        navigate(i18nRoute("routes.services.chatStart", {}));

    return (
        <>
            <HeroSection writeMessage={writeMessage} />
            <Box pt={16} bgcolor="#F8F8F8">
                <Element name="section2"></Element>
            </Box>
            <ChatServiceSection writeMessage={writeMessage} />
            <BenefitListSection />
            <PromotionSection writeMessage={writeMessage} />
        </>
    );
};

export default MedicalChatLanding;
