import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import classNames from "classnames/bind";
import { useHumed } from "../../../../ui/hooks/useHumed";
import classes from "./Button.module.css";

const cx = classNames.bind(classes);

const Button = (props) => {
    const isHumed = useHumed();

    return (
        <div
            className={cx(classes.button, { humed: isHumed })}
            onClick={props.onClick}
        >
            <span style={{ marginLeft: props.textMargin }}>{props.text}</span>
            <KeyboardArrowRightIcon className={classes.arrowIcon} />
        </div>
    );
};

export default Button;
