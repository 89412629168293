import type { ReactNode } from "react";
import { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { ErrorBoundary } from "./features/sentry";
import store from "./store";
import Progress from "./components/UI/Progress";
import AuthWrapper from "./features/auth/AuthWrapper";

interface Props {
    children: ReactNode;
}

const theme = createTheme();

const Root = ({ children }: Props) => (
    <ErrorBoundary>
        <Provider store={store}>
            <Suspense fallback={<Progress />}>
                <BrowserRouter>
                    <AuthWrapper>
                        <ThemeProvider theme={theme}>{children}</ThemeProvider>
                    </AuthWrapper>
                </BrowserRouter>
            </Suspense>
        </Provider>
    </ErrorBoundary>
);

export default Root;
