import CloseIcon from "@material-ui/icons/Close";
import { Colors } from "../../../assets/Theme";
import classes from "./PageHeader.module.css";

const PageHeader = ({
    text,
    mainHeader,
    withClose,
    closeBackground,
    closeFunction,
    className,
    style,
    textStyle,
}) => (
    <div className={`${classes.pageHeader} ${className}`} style={style}>
        {mainHeader ? (
            <h1 className={classes.text} style={textStyle}>
                {text}
            </h1>
        ) : (
            <span className={classes.text} style={textStyle}>
                {text}
            </span>
        )}

        {withClose && (
            <div
                style={{
                    ...styles?.closeContainer,
                    backgroundColor: closeBackground,
                }}
                className={classes.closeContainer}
                onClick={closeFunction}
            >
                <CloseIcon style={styles.close} />
            </div>
        )}
    </div>
);

const styles = {
    close: {
        width: "3rem",
        height: "3rem",
        color: Colors.darkBlue,
    },
    closeContainer: {
        position: "absolute",
        top: "0.8rem",
        right: 0,
        paddingLeft: "1rem",
        paddingRight: "1.5rem",
    },
};

export default PageHeader;
