import { Container } from "@material-ui/core";
// refacted layout UI elements to display Paperish design
import InnerLayout from "../templates/InnerLayout";
import Paper from "../atoms/Paper";
import Breadcrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Headline from "../atoms/Headline";
import useTranslation from "../../../features/i18n/useTranslation";
import { endLoading, post } from "../../../config/fetch";
import classes from "./Webshop.module.css";
import "../../../components/Facelift/typo.css";
import Btn from "../../UI/Btn/Btn";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { responseMessage } from "../../../utility";

const CheckBox = ({ onChange, children }) => (
    <label
        style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "30px",
            marginTop: "10px",
            flexDirection: "row",
        }}
    >
        <input
            style={{
                width: "20px",
                maxWidth: "20px",
                height: "20px",
                maxHeight: "20px",
                marginRight: "10px",
            }}
            type="checkbox"
            onChange={onChange}
        />
        <span>{children}</span>
    </label>
);

const NewPrescriptionRedeem = () => {
    const navigate = useNavigate();
    const { i18nRoute } = useTranslation();

    const [consentOne, setConsentOne] = useState(false);
    const [consentTwo, setConsentTwo] = useState(false);

    const buttonIsDisabled = !consentOne || !consentTwo;

    return (
        <div className={classes.intro}>
            <Container>
                <InnerLayout>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 12,
                        }}
                    >
                        <Paper>
                            <Headline>
                                <Breadcrumbs />
                            </Headline>
                            <div>
                                <div
                                    style={{
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        gap: 12,
                                        fontSize: "20px",
                                    }}
                                >
                                    E-recept kiváltás és kiszállítás
                                </div>
                                <div
                                    style={{
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                    }}
                                >
                                    Az e-recept kiváltás és szállítási igény
                                    leadásával hozzájárulok ahhoz, hogy
                                    gyógyszerelésemhez szükséges
                                    szolgáltatásokhoz személyes adataimat a
                                    T-DOC Service Kft. partner gyógyszertárhoz
                                    továbbítsa.
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <CheckBox
                                        onChange={(e) => {
                                            setConsentOne(e.target.checked);
                                        }}
                                    >
                                        Elolvastam, megértettem és elfogadom az{" "}
                                        <a href="https://t-doc.hu/aszf">
                                            Általános szerződési feltételeket
                                        </a>
                                        , valamint kijelentem, hogy megértettem
                                        a gyógyszerkiszállítás szolgáltatás
                                        feltételeit.
                                    </CheckBox>
                                    <CheckBox
                                        onChange={(e) => {
                                            setConsentTwo(e.target.checked);
                                        }}
                                    >
                                        Elolvastam, megértettem és elfogadom az{" "}
                                        <a href="https://t-doc.hu/adatvedelmi_nyilatkozat">
                                            https://t-doc.hu/adatvedelmi_nyilatkozat
                                        </a>{" "}
                                        oldalon található adatkezelési
                                        tájékoztatót.
                                    </CheckBox>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "40px",
                                        marginBottom: "40px",
                                    }}
                                >
                                    <Btn
                                        className={classes.button}
                                        onClick={() =>
                                            post(
                                                "api/webshop/prescription-redeem"
                                            )
                                                .then((response) => {
                                                    if (response) {
                                                        responseMessage(
                                                            'Sikeres igényleadás! A leadott igények státuszát az "Igények" menüpontban találod.'
                                                        );
                                                        navigate(
                                                            i18nRoute(
                                                                "routes.profile.medicine-delivery"
                                                            )
                                                        );
                                                    }
                                                    endLoading();
                                                })
                                                .catch((error) => {
                                                    responseMessage(
                                                        error?.response?.data
                                                            ?.message ??
                                                            "Ismeretlen hiba történt, kérjük próbálja újra később!"
                                                    );
                                                    endLoading();
                                                })
                                        }
                                        disabled={buttonIsDisabled}
                                        style={
                                            buttonIsDisabled
                                                ? {
                                                      backgroundColor:
                                                          "#929EA7",
                                                      cursor: "not-allowed",
                                                      color: "white !important",
                                                  }
                                                : {}
                                        }
                                    >
                                        Igény leadása
                                    </Btn>

                                    <Btn
                                        className={classes.button}
                                        onClick={() =>
                                            navigate(
                                                i18nRoute(
                                                    "routes.profile.medicine-delivery"
                                                )
                                            )
                                        }
                                        color="transparent"
                                    >
                                        Vissza
                                    </Btn>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </InnerLayout>
            </Container>
        </div>
    );
};

export default NewPrescriptionRedeem;
