import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import { formatNumber } from "../reservationUtilities";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import {
    ecommerceCheckout,
    ecommercePurchase,
} from "../../../config/ecommerce";
import { endLoading, get, post, startLoading } from "../../../config/fetch";
import { Icons } from "../../../assets/Theme";
import * as actions from "../../../store/actions/index";
import SubscriptionAndServiceModal from "../../Modals/SubscriptionAndServiceModal/SubscriptionAndServiceModal";
import { useDispatch } from "../../../store";
import useAuth from "../../../features/auth/useAuth";
import { login } from "../../../features/auth/slice";
import useQuery from "../../../features/util/useQuery";
import { useHumed } from "../../../ui/hooks/useHumed";
import AlertLine from "./AlertLine/AlertLine";
import BillingForm from "./BillingForm/BillingForm";
import PersonalForm from "./PersonalForm/PersonalForm";
import DataForm from "./DataForm/DataForm";
import Button from "./Button/Button";
import Checkbox from "./Checkbox/Checkbox";
import Textarea from "./Textarea/Textarea";
import InfoRow from "./InfoRow/InfoRow";
import { ReactComponent as TickSvg } from "./tick.svg";
import PageHeader from "./PageHeader/PageHeader";
import classes from "./ReservationSummary.module.css";

const cx = classNames.bind(classes);

const ReservationSummary = (props) => {
    // http://localhost:3000/idopont-kivalasztva?selectedTime=2021-07-19%2017%3A30&doctor=Dr.%20Schuller%20J%C3%A1nos&institution=TritonLife%20Medical%20Center%20Genium&institutionAddress=Budapest%2C%20Th%C3%B6k%C3%B6ly%20%C3%BAt%20137%2C%201146%20Magyarorsz%C3%A1g&service=F%C3%BCl-orr-g%C3%A9g%C3%A9szeri%20szakorvosi%20vizsg%C3%A1lat&price=28000
    const isHumed = useHumed();

    const query = useQuery();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth();

    const [selectedPackage, setSelectedPackage] = useState(1);

    const [subscriptionAndServiceModal, toggleSubscriptionAndServiceModal] =
        useState(0);
    const [
        subscriptionAndServiceModalData,
        setSubscriptionAndServiceModalData,
    ] = useState({
        freeOccasions: null,
        servicePrices: [],
        service: null,
        appointmentId: null,
    });
    const [comment, setComment] = useState("");

    const [paymentAmount, setPaymentAmount] = useState(null);

    const { search } = useLocation();
    const urlQuery = useMemo(() => decodeURIComponent(search), [search]);
    const params = useMemo(() => new URLSearchParams(urlQuery), [urlQuery]);

    const transId = useMemo(() => params.get("TransactionId"), [params]);

    const appointmentData = useMemo(
        () =>
            transId
                ? JSON.parse(sessionStorage.getItem(`reservation${transId}`))
                : null,
        [transId]
    );

    useEffect(() => {
        if (!transId) {
            return;
        }

        setComment(appointmentData.comment);
        toggleOtherPatient(appointmentData.otherPatient);
        if (appointmentData.otherPatient) {
            setOtherPatientData(appointmentData.otherPatient);
        }
    }, [appointmentData, transId]);

    const getReservationData = (key) =>
        appointmentData ? appointmentData[key] : params.get(key);

    const id = getReservationData("id");
    const date = getReservationData("selectedTime");
    const doctor = getReservationData("doctor");
    const doctorId = getReservationData("doctorId");
    const institution = getReservationData("institution");
    const institutionId = getReservationData("institutionId");
    const address = getReservationData("institutionAddress");
    const service = getReservationData("service");
    const serviceId = getReservationData("serviceId");
    const price = getReservationData("price");
    const isVideo = getReservationData("isVideo");
    const doctorImage = appointmentData
        ? appointmentData["doctorImage"]
        : urlQuery.substr(urlQuery.indexOf("doctorImage") + 12);

    const [otherPatient, toggleOtherPatient] = useState(false);
    const [otherPatientData, setOtherPatientData] = useState(null);
    const [missingData, setMissingData] = useState({
        personal: false,
        billing: false,
    });
    const [userData, setUserData] = useState({
        personal: null,
        billing: null,
    });

    const dataRef = useRef();
    const personalRef = useRef();
    const billingRef = useRef();

    const onReservationClick = () => {
        if (isHumed) {
            // Skip payment
            initReservation();

            return;
        }

        let personal = null;
        let billing = null;
        if (missingData.personal) {
            personal = personalRef.current.data;
        }
        if (missingData.billing) {
            billing = billingRef.current.data;
            if (!billing.country || billing.country === "") {
                billing.country = "Magyarország";
            }
        }

        if (personal || billing) {
            post("api/v1/reservation/update-user-data", {
                personal,
                billing,
            })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            setMissingData({
                                personal: null,
                                billing: null,
                            });
                            sendReservation();

                            break;
                        case "MISSING_DATA":
                            responseMessage("Minden adat megadása kötelező!");

                            break;
                        case "WRONG_PHONE":
                            responseMessage("Hibás email!");

                            break;
                        case "WRONG_EMAIL":
                            responseMessage("Hibás telefonszám!");

                            break;
                        case "EMAIL_TAKEN":
                            responseMessage("Email foglalt!");

                            break;
                        case "PHONE_TAKEN":
                            responseMessage("Telefonszám foglalt!");

                            break;
                        case "INVALID_SECURITY_NUMBER":
                            responseMessage("Hibás TAJ szám!");

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => console.error(error));
        } else {
            sendReservation();
        }
    };

    const sendReservation = () => {
        if (otherPatient) {
            const data = dataRef.current.data;
            let error = false;

            if (data["noTaj"]) {
                if (!data["idNumber"]) {
                    error = true;
                }
            } else {
                if (!data["tajNumber"]) {
                    error = true;
                }
            }

            if (!error) {
                for (const info in data) {
                    if (
                        info !== "idNumber" &&
                        info !== "tajNumber" &&
                        info !== "noTaj"
                    ) {
                        if (!data[info]) {
                            error = true;

                            break;
                        }
                    }
                }
            }

            if (error) {
                responseMessage(
                    "A megadott páciens minden adatának kitöltése kötelező!"
                );
            } else {
                startPayment();

                return false;
            }
        } else {
            startPayment();

            return false;
        }
    };

    const startPayment = () => {
        const service =
            isVideo === "true" ? "VIDEO_CONSULTATION" : "OUTPATIENT";

        post("api/v1/subscription/check-user-service-price", {
            service,
            appointmentId: id,
        }).then((response) => {
            switch (response.data.responseCode) {
                case "OK":
                    ecommerceCheckout(
                        service,
                        response.data.data.summaryAmount,
                        response.data.data.userPackage
                    );

                    setPaymentAmount(response.data.data.summaryAmount);
                    setSelectedPackage(+response.data.data.userPackage);
                    setSubscriptionAndServiceModalData({
                        ...subscriptionAndServiceModalData,
                        service,
                        appointmentId: id,
                    });
                    toggleSubscriptionAndServiceModal(2);

                    break;
                default:
                    responseMessage(ALERT_MESSAGES.errorTryAgain);

                    break;
            }
            endLoading();
        });
    };

    const initReservation = () =>
        post("api/v1/reservation/init-reservation", {
            service: isVideo === "true" ? "VIDEO_CONSULTATION" : "OUTPATIENT",
            selectedTime: date,
            appointmentId: id,
            otherPatient: otherPatient ? dataRef.current.data : null,
            comment,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        ecommercePurchase(
                            service,
                            paymentAmount,
                            selectedPackage
                        );
                        navigate(
                            `/sikeres-foglalas?selectedTime=${date}&doctor=${doctor}&service=${service}`
                        );

                        break;
                    case "BLOCK":
                        responseMessage("Nincs jogosultsága foglalást leadni");
                        navigate(-1);

                        break;
                    case "SELECTED_TIME_ALREADY_RESERVED":
                        responseMessage(
                            "A kiválasztott időpontot időközben lefoglalták. Kérünk válassz új időpontot!",
                            "Hibás foglalás",
                            () =>
                                navigate(
                                    `/idopontfoglalas?selectedDoctor=${doctorId}&selectedService=${serviceId}&selectedInstitution=${institutionId}&noCache=true`
                                )
                        );

                        break;
                    case "EMPTY_DEFAULT_CARD": {
                        const appointmentData = {
                            id,
                            selectedTime: date,
                            service,
                            serviceId,
                            doctor,
                            doctorId,
                            institution,
                            institutionId,
                            institutionAddress: address,
                            doctorImage,
                            price,
                            isVideo,
                            otherPatient: otherPatient
                                ? dataRef.current.data
                                : null,
                            comment,
                        };
                        props.toggleServicePaymentModal(
                            true,
                            paymentAmount,
                            isVideo === "true"
                                ? "VIDEO_CONSULTATION"
                                : "OUTPATIENT",
                            appointmentData
                        );

                        break;
                    }
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                if (isHumed) {
                    navigate(-1);
                }
            });

    useEffect(() => {
        const hash = query.get("hash");
        if (hash) {
            post("auth/verify-email", { hash })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            dispatch(
                                login({
                                    userId: response.data.id,
                                    token: response.data.token,
                                })
                            );
                            props.setSubscription(1);

                            break;
                        case "ALREADY_VERIFIED":
                            break;
                        default:
                            responseMessage("Hibás vagy nem létező hash!");

                            break;
                    }
                })
                .catch((error) => console.error(error));
        }
    }, [dispatch, props, query]);

    const checkUserDatas = useCallback(() => {
        get("api/v1/reservation/checkout-data-check")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        break;
                    case "BLOCK":
                        responseMessage("Nincs jogosultsága foglalást leadni");
                        navigate(-1);

                        break;
                    case "MISSING_DATA":
                        setMissingData({
                            personal: response.data.error.personal,
                            billing: response.data.error.billing,
                        });
                        setUserData({
                            personal: response.data.data.personal,
                            billing: response.data.data.billing,
                        });

                        break;
                    default:
                        break;
                }
                endLoading();
            })
            .catch(() => {
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                endLoading();
                if (isHumed) {
                    navigate(-1);
                }
            });
    }, [navigate, isHumed]);

    const paymentCallback = useCallback(() => {
        if (query.has("TransactionId")) {
            const transactionId = query.get("TransactionId");
            const immediateToken = query.get("immediateToken");
            const oneClickEnabled = query.get("oneClickEnabled");
            const subscription = query.get("subscription");
            const price = query.get("price");
            const service = query.get("service");
            post("api/v1/payment/payment-init-callback", {
                transactionId,
                cardName: "Bankkártya",
                immediateToken,
                oneClick: oneClickEnabled,
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            endLoading();
                            ecommercePurchase(service, price, subscription);
                            responseMessage(
                                succesFullResponseText(
                                    response.data.ProviderTransactionId
                                )
                            );
                            navigate(
                                `/sikeres-foglalas?selectedTime=${date}&doctor=${doctor}&service=${service}`,
                                { replace: true }
                            );

                            break;
                        case "PAYMENT_ERROR":
                            switch (response.data.errorCode) {
                                case "ERROR":
                                    responseMessage(
                                        unSuccesfullResponseText(
                                            response.data.ProviderTransactionId
                                        )
                                    );
                                    endLoading();

                                    break;
                                case "CANCELED":
                                    responseMessage(
                                        "Megszakítottad a fizetési folyamatot. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "TIMEOUT":
                                    responseMessage(
                                        "Túllépted a tranzakció elindításakor megengedett biztonságos időkaput. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "PENDING":
                                    startLoading();
                                    setTimeout(paymentCallback, 3000);

                                    break;
                            }

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                            endLoading();
                    }

                    localStorage.removeItem("newCardName");
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                });
        }
    }, [date, doctor, navigate, query]);

    useEffect(() => {
        paymentCallback();
    }, [paymentCallback]);

    const succesFullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikeres tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
        </>
    );

    const unSuccesfullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikertelen tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
            <p style={{ marginBottom: "1rem" }}>
                Kérjük, ellenőrizze a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adott meg, a
                visszautasítás okának kivizsgálása érdekében kérjük,
                szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
            </p>
        </>
    );

    useEffect(() => {
        if (isAuthenticated) {
            checkUserDatas();
        }
    }, [isAuthenticated, checkUserDatas]);

    return (
        <div style={{ backgroundColor: "#fff" }}>
            <div
                style={{
                    maxWidth: "1280px",
                    margin: "0 auto",
                    padding: "2.4rem",
                }}
            >
                <PageHeader text="Időpont kiválasztva" />
            </div>
            <div style={{ textAlign: "center" }}>
                <div className={cx(classes.tick, { humed: isHumed })}>
                    <TickSvg />
                </div>
            </div>{" "}
            <div style={{ marginBottom: "3rem" }}>
                <InfoRow
                    name="Időpont:"
                    value={date ? date.replace(/-/g, ".") : ""}
                />
                {doctor && (
                    <InfoRow
                        name="Orvos:"
                        value={doctor}
                        noBG={true}
                        image={doctorImage}
                    />
                )}
                {isVideo === "false" && (
                    <>
                        <InfoRow
                            name="Helyszín:"
                            value={institution ?? "-"}
                            extraText={address}
                        />
                        <InfoRow
                            name="Vizsgálat:"
                            value={service}
                            noBG={true}
                        />
                        {price > 0 && (
                            <InfoRow
                                name="Ár:"
                                value={`${formatNumber(price)} Ft`}
                            />
                        )}
                    </>
                )}
                {isVideo === "true" && (
                    <>
                        <InfoRow name="Vizsgálat:" value={service} />
                        {price > 0 && (
                            <InfoRow
                                name="Ár:"
                                value={`${formatNumber(price)} Ft`}
                                noBG={true}
                            />
                        )}
                    </>
                )}
            </div>
            {(missingData.personal || missingData.billing) && (
                <Grid container justifyContent="center">
                    <Grid item md={6} xs={12}>
                        <AlertLine
                            text="Figyelem! A foglaláshoz kérünk töltsd ki az alábbi hiányzó adatokat!"
                            style={{ marginBottom: "4rem" }}
                            icon={Icons.exclamationBlack}
                        />
                    </Grid>
                </Grid>
            )}
            {missingData.personal && userData.personal && (
                <Grid
                    container
                    justifyContent="center"
                    style={{ marginBottom: "3rem", padding: "0 2rem" }}
                >
                    <Grid item md={6} xs={12}>
                        <PersonalForm
                            ref={personalRef}
                            data={userData.personal}
                            mainTitle="Hiányzó személyes adatok"
                        />
                    </Grid>
                </Grid>
            )}
            {missingData.billing && userData.billing && (
                <Grid
                    container
                    justifyContent="center"
                    style={{ marginBottom: "3rem", padding: "0 2rem" }}
                >
                    <Grid item md={6} xs={12}>
                        <BillingForm
                            ref={billingRef}
                            data={userData.billing}
                            mainTitle="Hiányzó számlázási adatok"
                            defaultBillingName={`${userData.personal.last_name} ${userData.personal.first_name}`}
                        />
                    </Grid>
                </Grid>
            )}
            {!isAuthenticated ? (
                <>
                    <p className={classes.text}>
                        A foglalás véglegesítéséhez bejelentkezés szükséges
                    </p>
                    <div className={classes.btnWrapper}>
                        <Button
                            text="Bejelentkezés"
                            onClick={props.toggleLoginModal}
                        />
                        <Button
                            text="Regisztráció"
                            onClick={props.toggleRegistrationModal}
                        />
                    </div>
                </>
            ) : (
                <Grid
                    container
                    justifyContent="center"
                    style={{ marginBottom: "0rem", padding: "0 2rem" }}
                >
                    <Grid item md={6} xs={12}>
                        <p className={classes.textLabel}>Megjegyzés szövege</p>
                        <Textarea
                            rows={5}
                            value={comment}
                            onChange={(value) => setComment(value)}
                            maxLength="500"
                        />
                        <p className={classes.charInfo}>
                            {500 - comment.length} karaktert írhat még
                        </p>
                        {!isHumed && (
                            <Checkbox
                                label="Nem én vagyok a páciens, másnak foglalok"
                                checked={otherPatient}
                                onChange={(checked) =>
                                    toggleOtherPatient(checked)
                                }
                            />
                        )}
                        {otherPatient && (
                            <>
                                <p className={classes.text}>
                                    FONTOS! Kérjük az alábbiakban annak a
                                    páciensnek az adatait adja meg, akinek az
                                    ellátást foglalja
                                </p>
                                <DataForm
                                    ref={dataRef}
                                    inputData={otherPatientData}
                                />
                            </>
                        )}
                        <div className={classes.btnWrapper}>
                            <Button
                                text="Lefoglalom"
                                onClick={() => onReservationClick()}
                            />
                        </div>
                    </Grid>
                </Grid>
            )}
            <SubscriptionAndServiceModal
                outerSelectedPackage={selectedPackage}
                normalInit={initReservation}
                open={subscriptionAndServiceModal}
                data={subscriptionAndServiceModalData}
                toggleSubscriptionAndServiceModal={
                    toggleSubscriptionAndServiceModal
                }
                succesfulSubscribeAndServiceCallback={() => undefined}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    subscription: state.user.subscription,
});

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
    toggleRegistrationModal: (show) =>
        dispatch(actions.toggleRegistrationModal(show)),
    toggleServicePaymentModal: (show, price, service, appointmentData) =>
        dispatch(
            actions.toggleServicePaymentModal(
                show,
                price,
                service,
                null,
                null,
                null,
                null,
                appointmentData
            )
        ),
    setSubscription: (subscription) =>
        dispatch(actions.setSubscription(subscription)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationSummary);
