import classes from "./PersonIntro.module.css";

const PersonIntro = (props) => (
    <div className={classes.PersonIntro} style={props.style}>
        <div className={classes.imageContainer}>
            <img src={props.image} className={classes.image} alt="" />
        </div>
        <p className={classes.name}>{props.name}</p>
        {props.qualification && (
            <p className={classes.qualification}>{props.qualification}</p>
        )}
    </div>
);

export default PersonIntro;
