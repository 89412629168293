import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
    ArrowLeft as ArrowLeftIcon,
    ArrowRight as ArrowRightIcon,
} from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import cn from "classnames";
import PersonIntro from "./PersonIntro";
import classes from "./CustomSlider.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../slick.css";

const isMobile = window.innerWidth <= 959;

const CustomSlider = (props) => {
    const navigate = useNavigate();
    const slider = useRef(null);
    const sliderSettings = {
        dots: false,
        arrows: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: props.slides ? props.slides[0] : 4,
        infinite: props.data.length > (props.slides ? props.slides[0] : 4),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: props.slides ? props.slides[1] : 3,
                    infinite:
                        props.data.length >
                        (props.slides ? props.slides[1] : 3),
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: props.slides ? props.slides[2] : 2,
                    infinite:
                        props.data.length >
                        (props.slides ? props.slides[2] : 2),
                },
            },
        ],
    };

    if (props.data && props.data.length > 0) {
        return (
            <Grid container>
                {props.data.length > (isMobile ? 2 : 4) && (
                    <div className={cn(classes.controller, classes["pull-up"])}>
                        <div
                            className={classes.navigation}
                            onClick={() => slider.current.slickPrev()}
                        >
                            <ArrowLeftIcon classes={{ root: classes.icon }} />
                        </div>
                        <div
                            className={classes.navigation}
                            onClick={() => slider.current.slickNext()}
                        >
                            <ArrowRightIcon classes={{ root: classes.icon }} />
                        </div>
                    </div>
                )}

                <Grid item xs={12}>
                    <div
                        className={classes.sliderContainer}
                        style={props.style}
                    >
                        <Slider {...sliderSettings} ref={slider}>
                            {props.data.map((item) => {
                                if (props.people) {
                                    let qualifications = [];
                                    if (item.qualifications) {
                                        for (let q of item.qualifications) {
                                            qualifications.push(q.name);
                                        }
                                    }

                                    return (
                                        <div
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/orvosaink/${item.profileLink}`,
                                                })
                                            }
                                            key={item.id}
                                        >
                                            <PersonIntro
                                                image={item.image}
                                                name={item.name}
                                                withAvailability={
                                                    props.withAvailability
                                                }
                                                available={item.available}
                                                qualification={
                                                    qualifications.length > 0
                                                        ? qualifications
                                                              .sort((a, b) =>
                                                                  a.localeCompare(
                                                                      b
                                                                  )
                                                              )
                                                              .join(", ")
                                                        : item.qualification
                                                }
                                            />
                                        </div>
                                    );
                                }

                                return (
                                    <div key={item.id}>
                                        {props.renderFunction(item)}
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </Grid>
            </Grid>
        );
    }

    return false;
};

export default CustomSlider;
