import * as actionTypes from "../actions/actionTypes";

const initialState = {
    subscription: null,
};

const setSubscription = (state, action) => ({
    ...state,
    subscription: action.subscription,
});

const reducer = (state = initialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case actionTypes.SET_SUBSCRIPTION:
            return setSubscription(state, action);
    }

    return state;
};

export default reducer;
