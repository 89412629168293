import { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames/bind";
import Popper from "@material-ui/core/Popper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classes from "./InstitutionSelect.module.css";

const cx = classNames.bind(classes);

const InstitutionSelect = (props) => {
    const [value, setValue] = useState(null);
    const onSelectChange = (event, newValue) => {
        setValue(newValue);
        if (props.onChange) {
            if (!newValue) {
                props.onChange(null);
            } else {
                props.onChange(newValue.id || false);
            }
        }
    };

    useEffect(() => {
        if (props.options?.length) {
            if (props.selectedId) {
                for (let o of props.options) {
                    if (o.id == props.selectedId) {
                        setValue(o);

                        break;
                    }
                }
            } else if (props.selectedId === null) {
                console.error("null param");
                setValue(null);
            }
        }
    }, [props.options, props.selectedId]);

    const popperStyles = makeStyles(() =>
        createStyles({
            root: {
                "& .MuiAutocomplete-listbox": {
                    fontSize: "1.6rem",
                },
            },
        })
    );

    const noOptionStyles = makeStyles({
        noOptions: {
            fontSize: "1.6rem",
        },
        inputRoot: {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#1FBA9C",
            },
        },
        label: {
            "& .MuiFormLabel-root": {
                color: "rgba(0, 0, 0, 0.54)",
            },
        },
        groupLabel: {
            fontSize: "1.6rem",
        },
    });

    const CustomPopper = function (props) {
        const classes = popperStyles();

        return (
            <Popper {...props} className={classes.root} placement="bottom" />
        );
    };

    const styles = noOptionStyles();

    return (
        <div className={classes.select}>
            <Autocomplete
                classes={{
                    root: cx(props.rootClass, "selectRoot"),
                    inputRoot: cx(styles.inputRoot, classes.inputRoot),
                    endAdornment: classes.endAdornment,
                    popupIndicator: classes.popupIndicator,
                    inputFocused: classes.inputFocused,
                    noOptions: styles.noOptions,
                    groupLabel: styles.groupLabel,
                }}
                PopperComponent={CustomPopper}
                disableClearable={false}
                value={value}
                getOptionSelected={(option, value) => option.id == value.id}
                onChange={(event, newValue) => onSelectChange(event, newValue)}
                noOptionsText={props.noOptionsText}
                options={props.options.sort(
                    (a, b) => -b.city.localeCompare(a.city)
                )}
                getOptionLabel={(option) => (option ? option.name : "")}
                style={{ width: "100%" }}
                groupBy={(option) => option.city}
                renderInput={(params) => (
                    <TextField
                        className={styles.label}
                        {...params}
                        label={props.label}
                        variant="outlined"
                    />
                )}
            />
        </div>
    );
};

export default InstitutionSelect;
