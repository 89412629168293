import { connect } from "react-redux";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import Footer from "../../components/Footer/Footer";
import * as actions from "../../store/actions/index";
import { useHumed } from "../../ui/hooks/useHumed";
import classes from "./Layout.module.css";

const Layout = (props) => {
    const isHumed = useHumed();

    if (isHumed) return props.children;

    return (
        <div
            onClick={() => props.toggleNotifications(false)}
            style={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Toolbar />
            <main className={classes.main}>{props.children}</main>
            <div style={{ width: "100%", flex: 2 }}></div>
            <Footer />
        </div>
    );
};
const mapDispatchToProps = (dispatch) => ({
    toggleNotifications: (show) => dispatch(actions.toggleNotifications(show)),
});
export default connect(null, mapDispatchToProps)(Layout);
