import { createElement } from "react";
import cn from "classnames";
import classes from "./PageSubHeader.module.css";

const PageSubHeader = ({ children, tag = "h2", className, style }) => {
    const Component = () =>
        createElement(
            tag,
            {
                className: cn(classes.subheader, className),
                style,
            },
            children
        );

    return <Component />;
};

export default PageSubHeader;
