import { Box, Container } from "@material-ui/core";
import { Colors } from "../../../../assets/Theme";
import type { TextField } from "../../../../ui/models";
import DangerousHtml from "../../../../ui/layout/DangerousHtml";
import PageHeader from "../../../../ui/layout/PageHeader";
import ForwardButton from "./ForwardButton";

interface Props {
    contentBlock: TextField;
}

const TextBlock = ({ contentBlock }: Props) => {
    const { contained, text, url } = contentBlock;

    return (
        <Box
            {...(contained
                ? { color: "white", bgcolor: Colors.green }
                : { color: Colors.darkBlue })}
            py={5}
            textAlign="center"
            my={4}
        >
            <Container>
                {contentBlock.sectionLabel && (
                    <PageHeader text={contentBlock.sectionLabel} />
                )}
                <Box>
                    <DangerousHtml>{text}</DangerousHtml>
                </Box>
                {url && (
                    <ForwardButton
                        type={contained ? "outlined" : "contained"}
                        url={url}
                    />
                )}
            </Container>
        </Box>
    );
};

export default TextBlock;
