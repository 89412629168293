import { useState } from "react";
import cn from "classnames";
import classes from "./Tabs.module.css";

const Tabs = ({ tabs, className, innerClassName, style, scrollRef }) => {
    const [activeTab, setActiveTab] = useState(
        tabs.findIndex(({ isHidden }) => !isHidden)
    );

    const handleActiveTab = (index) => {
        if (scrollRef.current && tabs) {
            const parent = scrollRef.current;
            const scrollToId = tabs[index]?.scrollToId?.replace("#", "");

            if (scrollToId) {
                parent.childNodes.forEach((child) => {
                    if (child.id === scrollToId) {
                        parent.scrollTo({
                            top: child.offsetTop,
                            behavior: "smooth",
                        });
                    }
                });
            }
        }

        setActiveTab(index);
    };

    if (!tabs || tabs.length < 1) {
        return null;
    }

    return (
        <ul className={cn(classes.tabs, className)} style={style}>
            {tabs.map(
                (tab, index) =>
                    !tab.isHidden && (
                        <li
                            key={`paper-tabs-item-${index}`}
                            onClick={() => handleActiveTab(index)}
                            className={cn(classes.tab, innerClassName, {
                                [classes.active]:
                                    tab.active || index === activeTab,
                            })}
                        >
                            {tab.label}
                        </li>
                    )
            )}
        </ul>
    );
};

export default Tabs;
