import { get } from "./config/fetch";
import store from "./store";
import * as actions from "./store/actions/index";
import { MONTHS } from "./config/variables";

export const responseMessage = (
    content,
    title = null,
    callback = null,
    withCancel = false
) =>
    store.dispatch(
        actions.toggleResponseModal(true, {
            title,
            content,
            confirmButtonCallback: callback,
            cancelButton: withCancel,
        })
    );

export const returnWithZero = (number) => (number < 10 ? "0" + number : number);

export const convertDate = (date, separator = "-", withHours = false) => {
    try {
        if (!withHours) {
            return (
                date.getFullYear() +
                separator +
                returnWithZero(date.getMonth() + 1) +
                separator +
                returnWithZero(date.getDate())
            );
        }

        // console.log(date);
        return (
            date.getFullYear() +
            separator +
            returnWithZero(date.getMonth() + 1) +
            separator +
            returnWithZero(date.getDate()) +
            " " +
            returnWithZero(date.getHours()) +
            ":" +
            returnWithZero(date.getMinutes())
        );
    } catch (error) {
        return null;
    }
};

export const capitalize = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

export const formatNumber = (x) =>
    x || x === 0 ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "";

export const formatDate = (date) => {
    let _date;
    if (typeof date === "object") {
        _date = date;
    } else {
        _date = new Date(date.replace(" ", "T"));
    }

    return (
        capitalize(MONTHS[_date.getMonth()]) +
        " " +
        _date.getDate() +
        ". " +
        returnWithZero(_date.getHours()) +
        ":" +
        returnWithZero(_date.getMinutes())
    );
};

export const formatDateWithYear = (date) => {
    let _date;
    if (typeof date === "object") {
        _date = date;
    } else {
        _date = new Date(date.replace(" ", "T"));
    }

    return (
        _date.getFullYear() +
        " " +
        capitalize(MONTHS[_date.getMonth()]) +
        " " +
        _date.getDate() +
        ". " +
        returnWithZero(_date.getHours()) +
        ":" +
        returnWithZero(_date.getMinutes())
    );
};

export const removeAccents = (string) =>
    string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const groupByFirstLetter = (data, nameKey = null) => {
    let grouppedData = {
        "A, Á": [],
        B: [],
        "C, Cs": [],
        D: [],
        "E, É": [],
        F: [],
        "G, Gy": [],
        H: [],
        "I, Í": [],
        J: [],
        K: [],
        "L, Ly": [],
        M: [],
        "N, Ny": [],
        "O, Ó": [],
        "Ö, Ő": [],
        P: [],
        Q: [],
        R: [],
        "S, Sz": [],
        "T, Ty": [],
        "U, Ú": [],
        "Ü, Ű": [],
        V: [],
        X: [],
        Y: [],
        "Z, Zs": [],
    };

    if (nameKey) {
        for (let d of data) {
            let firstLetter = removeAccents(
                d[nameKey].substr(0, 1)
            ).toLowerCase();
            for (let key in grouppedData) {
                let groupFirstLetter = removeAccents(
                    key.substr(0, 1)
                ).toLowerCase();
                if (groupFirstLetter === firstLetter) {
                    grouppedData[key].push(d);

                    break;
                }
            }
        }
    } else {
        for (let d of data) {
            let firstLetter = removeAccents(d.substr(0, 1)).toLowerCase();
            for (let key in grouppedData) {
                let groupFirstLetter = removeAccents(
                    key.substr(0, 1)
                ).toLowerCase();
                if (groupFirstLetter === firstLetter) {
                    grouppedData[key].push(d);

                    break;
                }
            }
        }
    }
    let clearedGrouppedData = {};

    for (let key in grouppedData) {
        if (grouppedData[key].length) {
            clearedGrouppedData[key] = grouppedData[key];
        }
    }

    return clearedGrouppedData;
};

export const getBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        return reader.result;
    };
    reader.onerror = function (error) {
        console.error("Error: ", error);

        return false;
    };
};

export const getPackageName = (packageId) => {
    // eslint-disable-next-line default-case
    switch (+packageId) {
        case 1:
            return "Előfizetés nélkül";
        case 2:
            return "Basic";
        case 3:
            return "Optimum";
        case 4:
            return "Premium";
    }
};

export const getServiceName = (service) => {
    switch (service) {
        case "HOTLINE":
            return "Hotline hívása";
        case "DUTY":
            return "Általános orvosi konzultáció";
        case "CHAT":
            return "Orvosi chat";
        case "SECOND_OPINION":
            return "Orvosi másodvélemény";
        case "OUTPATIENT":
            return "Járóbeteg időpontfoglalás";
        case "VIDEO_CONSULTATION":
            return "Videókonzultáció időpontfoglalás";
        default:
            return "";
    }
};

export const setYellowBar = () =>
    get("api/v1/config/get-yellow-bar", false)
        .then((response) => {
            if (response.data.responseCode === "OK") {
                let data = {};
                for (let d of response.data.data) {
                    data[d.name] = d;
                }
                let obj = {
                    visitor: {
                        text: data.visitor_text.value,
                        linkText: data.visitor_link_text.value,
                        target: data.visitor_target.value,
                        show: data.visitor_show.value === "true",
                    },
                    loggedIn: {
                        text: data.logged_in_text.value,
                        linkText: data.logged_in_link_text.value,
                        target: data.logged_in_target.value,
                        show: data.logged_in_show.value === "true",
                    },
                    subscriber: {
                        text: data.subscriber_text.value,
                        linkText: data.subscriber_link_text.value,
                        target: data.subscriber_target.value,
                        show: data.subscriber_show.value === "true",
                    },
                };
                store.dispatch(actions.setYellowBar(obj));
            }
        })
        .catch((error) => console.error(error));

export const isSafari = () =>
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isWeekend = () => {
    const dayOfWeek = new Date().getDay();

    // 6 = Saturday, 0 = Sunday
    return dayOfWeek === 6 || dayOfWeek === 0;
};

export const checkCoordinateDistance = (p1, p2, maxDistance) => {
    const rad = (x) => (x * Math.PI) / 180;

    const getDistance = (p1, p2) => {
        const R = 6378137; // Earth’s mean radius in meter
        const dLat = rad(p2.lat - p1.lat);
        const dLong = rad(p2.lng - p1.lng);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(p1.lat)) *
                Math.cos(rad(p2.lat)) *
                Math.sin(dLong / 2) *
                Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // returns the distance in meter
    };

    const distance = getDistance(p1, p2);

    return distance <= maxDistance;
};

export function check_webp_feature(feature, callback) {
    const kTestImages = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        animation:
            "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
    };
    const img = new Image();
    img.onload = function () {
        const result = img.width > 0 && img.height > 0;
        callback(feature, result);
    };
    img.onerror = function () {
        callback(feature, false);
    };
    img.src = "data:image/webp;base64," + kTestImages[feature];
}

export const makeInitials = (name) => {
    if (!name) {
        return;
    }

    let monogramArray = [];

    name.split(" ").forEach((part) => {
        if (monogramArray.length < 2) {
            monogramArray.push(part.toUpperCase().charAt(0));
        }
    });

    return monogramArray.join("");
};

export const dispatchPrescriptionRedeemStatusText = (status) => {
    switch (status.toLowerCase()) {
        case "new":
            return "Új";
        case "in_progress":
            return "Feldolgozás alatt";
        case "on_order":
            return "Rendelés alatt";
        case "no_prescription":
            return "Nincs recept";
        case "completed":
            return "Kész";
        case "failed":
            return "Sikertelen";
        case "declined":
            return "Elutasítva";
        case "closed":
            return "Lezárt";
        default:
            return "N/A";
    }
};

export const dispatchPrescriptionRedeemStatusColor = (status) => {
    switch (status.toLowerCase()) {
        case "new":
            return "green";
        case "in_progress":
            return "blue";
        case "on_order":
            return "orange";
        case "no_prescription":
            return "purple";
        case "completed":
            return "green";
        case "failed":
            return "red";
        case "declined":
            return "red";
        case "closed":
            return "grey";
        default:
            return "grey";
    }
};
