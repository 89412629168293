import { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import InfoRow from "../InfoRow/InfoRow";
import CustomMarker from "../CustomMarker/CustomMarker";
import SvgMarkerGreen from "../../../assets/images/markerGreen.svg";
import SvgMarkerRed from "../../../assets/images/markerRed.svg";
import { Colors, Fonts, Icons } from "../../../assets/Theme";
import classes from "./DutyMap.module.css";

const mapContainerStyle = {
    width: "100%",
    height: "600px",
};

const DutyMap = (props) => {
    const google = window.google;

    const [selected, setSelected] = useState(null);
    const [dragCenter, setDragCenter] = useState(null);

    const mapRef = useRef();
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            panTo(props.searchedAddress.lat, props.searchedAddress.lng);
            setSelected(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchedAddress]);

    useEffect(() => {
        if (props.selectedDuty !== null) {
            setSelected(props.selectedDuty);
            panTo(+props.selectedDuty.lat, +props.selectedDuty.lng);
        }
        if (props.selectedDuty === null) {
            setSelected(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedDuty]);

    const panTo = useCallback((lat, lng, zoom = 13) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(zoom);
    }, []);

    const options = {
        disableDefaultUI: true,
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
        },
        styles: [
            {
                featureType: "poi.attraction",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "poi.government",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "poi.park",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "poi.place_of_worship",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "poi.school",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "poi.sports_complex",
                stylers: [{ visibility: "off" }],
            },
        ],
    };

    const handleDragSearch = () => {
        props.onDragSearch(dragCenter);
        setDragCenter(null);
    };

    return (
        <GoogleMap
            clickableIcons={false}
            mapContainerStyle={mapContainerStyle}
            zoom={12}
            center={props.userCoords}
            options={options}
            onLoad={onMapLoad}
            onDragEnd={() => {
                if (mapRef.current) {
                    const lat = mapRef.current.getCenter().lat();
                    const lng = mapRef.current.getCenter().lng();
                    setDragCenter({ lat, lng });
                }
            }}
        >
            {selected && (
                <div className={classes.container}>
                    <InfoRow
                        data={selected}
                        onMap={true}
                        distance={props.distance}
                        mapStyle={{ color: "white" }}
                    />
                </div>
            )}
            {dragCenter && (
                <div className={classes.searchContainer}>
                    <div
                        className={classes.searchBtn}
                        onClick={handleDragSearch}
                    >
                        <img
                            src={Icons.searchGreen}
                            className={classes.searchIcon}
                            alt=""
                        />
                        <p style={styles.text}>Keresés ezen a területen</p>
                    </div>
                </div>
            )}
            {props.userCoords && !props.searchedAddress && (
                <Marker
                    position={{
                        lat: props.userCoords.lat,
                        lng: props.userCoords.lng,
                    }}
                ></Marker>
            )}
            {props.searchedAddress && (
                <Marker
                    position={{
                        lat: props.searchedAddress.lat,
                        lng: props.searchedAddress.lng,
                    }}
                ></Marker>
            )}
            {props.data.map((d) => {
                if (d.image) {
                    return (
                        <CustomMarker
                            key={d.id}
                            position={{ lat: +d.lat, lng: +d.lng }}
                            color={
                                selected && selected.id == d.id
                                    ? "#ED1124"
                                    : "#1FBA9C"
                            }
                            image={d.image}
                            onClick={() => {
                                setSelected(d);
                                props.selectedOnMap(d);
                                panTo(+d.lat, +d.lng);
                            }}
                        />
                    );
                }

                return (
                    <Marker
                        key={d.id}
                        position={{ lat: +d.lat, lng: +d.lng }}
                        icon={{
                            url:
                                selected && selected.id == d.id
                                    ? SvgMarkerRed
                                    : SvgMarkerGreen,
                            scaledSize: new window.google.maps.Size(40, 40),
                            scale: 0.1,
                            fillOpacity: 1,
                            strokeColor:
                                selected && selected.id == d.id
                                    ? "#ED1124"
                                    : "#1FBA9C",
                            fillColor:
                                selected && selected.id == d.id
                                    ? "#ED1124"
                                    : "#1FBA9C",
                        }}
                        onClick={() => {
                            setSelected(d);
                            props.selectedOnMap(d);
                            panTo(+d.lat, +d.lng);
                        }}
                    />
                );
            })}
        </GoogleMap>
    );
};

const styles = {
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
        marginBottom: "0.5rem",
        // color: '#929EA7',
        opacity: 1,
        marginTop: "1rem",
    },
};

export default DutyMap;
