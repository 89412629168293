import useMediaQuery from "@material-ui/core/useMediaQuery";
import type { Theme } from "@material-ui/core/styles";

type Resolution = "tablet" | "mobile";

export const useResolution = (resolution: Resolution): boolean =>
    useMediaQuery((theme: Theme) => {
        switch (resolution) {
            case "tablet":
                return theme.breakpoints.down(959);
            case "mobile":
                return theme.breakpoints.down(559);
        }
    });
// we use the 'sm' / 'md' mui breakpoints in other files as well, but these two magic numbers are not coherent with the base mui breakpoints
