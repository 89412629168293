import type { ReactNode } from "react";
import { useEffect } from "react";
import { useResetState } from "@madmedical/sdk";
import { useIsNha } from "tdoc-common";
import useAuth from "../auth/useAuth";
import {
    getStorageValue,
    removeStorageValue,
    setStorageValue,
} from "../util/storage";
import useQuery from "../util/useQuery";
import { useStoreInviteMutation } from "./api";

const STORAGE_KEY = "nhaInvite";

type Invite = {
    hash: string;
    senderUserId: number;
};

interface Props {
    children: ReactNode;
}

const NextHealthInviteWrapper = ({ children }: Props) => {
    const query = useQuery();
    const { isAuthenticated, userId } = useAuth();
    const { isNhaUser, refetch: refetchIsNha } = useIsNha();
    const [storeInvite] = useStoreInviteMutation();
    const nhaReset = useResetState();

    useEffect(() => {
        const hash = query.get("nhaInviteHash");
        const senderUserId = query.get("senderUserId");

        const invite =
            hash && senderUserId
                ? { hash, senderUserId: parseInt(senderUserId) }
                : getStorageValue<Invite>(STORAGE_KEY);
        if (!invite) {
            return;
        }

        if (!isAuthenticated) {
            setStorageValue<Invite>(STORAGE_KEY, invite);

            return;
        }

        if (userId === invite.senderUserId) {
            // Skip self-invite, leave in storage for next login
            return;
        }

        void (async () => {
            await storeInvite({ hash: invite.hash }).unwrap();
            removeStorageValue(STORAGE_KEY);
            await nhaReset();
            if (!isNhaUser) {
                await refetchIsNha();
            }
        })();
    }, [
        isAuthenticated,
        nhaReset,
        query,
        storeInvite,
        userId,
        isNhaUser,
        refetchIsNha,
    ]);

    return <>{children}</>;
};

export default NextHealthInviteWrapper;
