import { forwardRef, useEffect, useState } from "react";
import { Hidden } from "@material-ui/core";
import { connect } from "react-redux";
import Card from "../Card/Card";
import { formatNumber } from "../../../utility";
import Btn from "../Btn/Btn";
import SubscriptionDetails from "../SubscriptionDetails/SubscriptionDetails";
import * as actions from "../../../store/actions";
import classes from "./SubscriptionCard.module.css";

const SubscriptionCard = forwardRef((props, ref) => {
    const [open, toggleOpen] = useState(props.open);

    const onButtonClick = () => {
        if (props.onButtonClick) {
            props.onButtonClick();
        }
    };

    useEffect(() => {
        toggleOpen(props.open);
    }, [props.open]);

    return (
        <Card
            className={`${classes.card} ${
                props.yellowText && classes.yellowTextCard
            } ${props.greenText && classes.greenTextCard} ${props.className} ${
                open ? props.openClass : ""
            }`}
            style={props.style}
            ref={ref}
        >
            {props.yellowText && (
                <div className={classes.extraTextContainer}>
                    <span className={classes.yellowText}>
                        {props.yellowText}{" "}
                        <span className={classes.bottomTriangle}></span>
                    </span>
                </div>
            )}
            {props.greenText && (
                <div className={classes.extraTextContainer}>
                    <span className={classes.greenText}>
                        {props.greenText}{" "}
                        <span className={classes.bottomTriangle}></span>
                    </span>
                </div>
            )}
            <div>
                <div className={classes.nameRow}>
                    <p className={classes.name}>{props.name}</p>
                    <Hidden mdUp>
                        <div className={classes.priceContainer}>
                            {props.hasTrialPeriod && (
                                <p className={classes.trialText}>
                                    A díjmentes időszak után
                                </p>
                            )}
                            <p
                                className={`${classes.price} ${
                                    props.hasTrialPeriod
                                        ? classes.trialPrice
                                        : ""
                                }`}
                            >
                                {formatNumber(props.price || 0)} Ft / hó
                            </p>
                        </div>
                    </Hidden>
                </div>
                {props.description && (
                    <p className={classes.description}>{props.description}</p>
                )}
                <Hidden mdUp>
                    <div className={classes.buttonAndDetails}>
                        {props.buttonComponent || (
                            <Btn
                                text={props.buttonText || "Megvásárlás"}
                                color="transparent-green"
                                disabled={props.buttonDisabled}
                                onClick={onButtonClick}
                                className={classes.button}
                            />
                        )}
                        <p
                            className={classes.toggle}
                            onClick={() => {
                                toggleOpen(!open);
                                props.toggleSubscriptionDetails(!open);
                            }}
                        >
                            {" "}
                            {open
                                ? "Részletek elrejtése"
                                : "Részletek megjelenítése"}
                        </p>
                    </div>
                </Hidden>
                <div className={classes.contentContainer}>
                    {props.data && (
                        <SubscriptionDetails
                            data={props.data}
                            name={props.name}
                            open={open}
                        />
                    )}
                </div>
            </div>
            <Hidden smDown>
                <p
                    className={classes.toggle}
                    onClick={() => {
                        toggleOpen(!open);
                        props.toggleSubscriptionDetails(!open);
                    }}
                >
                    {" "}
                    {open ? "Részletek elrejtése" : "Részletek megjelenítése"}
                </p>
            </Hidden>
            <div>
                <Hidden mdDown>
                    {props.hasTrialPeriod && (
                        <p className={classes.trialText}>
                            A díjmentes időszak után
                        </p>
                    )}
                    <p
                        className={`${classes.price} ${
                            props.hasTrialPeriod ? classes.trialPrice : ""
                        }`}
                    >
                        {formatNumber(props.price || 0)} Ft / hó
                    </p>
                </Hidden>
                <Hidden smDown>
                    <div className={classes.buttonAndDetails}>
                        {props.buttonComponent || (
                            <Btn
                                text={props.buttonText || "Megvásárlás"}
                                color="transparent-green"
                                disabled={props.buttonDisabled}
                                onClick={onButtonClick}
                                className={classes.button}
                            />
                        )}
                    </div>
                </Hidden>
            </div>
        </Card>
    );
});

SubscriptionCard.displayName = "SubscriptionCard";

const mapDispatchToProps = (dispatch) => ({
    // toggleLoginModal: show => dispatch(actions.toggleLoginModal(show)),
    toggleSubscriptionDetails: (show) =>
        dispatch(actions.toggleSubscriptionDetails(show)),
});

export default connect(null, mapDispatchToProps)(SubscriptionCard);
