import { Container } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import CardContainer from "../UI/CardContainer/CardContainer";
import PageHeader from "../UI/PageHeader/PageHeader";
import Button from "../UI/Button/Button";
import { Icons } from "../../assets/Theme";
import useTranslation from "../../features/i18n/useTranslation";
import classes from "./ReservationEnd.module.css";

const ReservationEnd = () => {
    const navigate = useNavigate();
    const { i18nRoute } = useTranslation();

    return (
        <Container>
            <div className={classes.innerContainer}>
                <CardContainer className={classes.cardContainer}>
                    <PageHeader
                        text="Sikeres foglalás!"
                        style={styles.pageHeader}
                    />
                    <div className={classes.tickContainer}>
                        <img
                            src={Icons.tickGreen}
                            className={classes.tick}
                            alt=""
                        />
                    </div>

                    <Button
                        text="Profilom"
                        className={classes.button}
                        onClick={() =>
                            navigate(i18nRoute("routes.profile.base"))
                        }
                    />
                </CardContainer>
            </div>
        </Container>
    );
};

const styles = {
    pageHeader: {
        marginTop: 0,
        marginBottom: "3rem",
    },
};

export default ReservationEnd;
