import { baseApi } from "tdoc-common";
import { setSubscription } from "../../store/actions";
import { clearAuth, login } from "./slice";
import type { HumedLoginPayload, LoginPayload } from "./model";

const api = baseApi.injectEndpoints({
    endpoints: (build) => ({
        humedLogin: build.mutation<LoginPayload, HumedLoginPayload>({
            query: (payload) => ({
                url: "humed-login",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                const { data: loginPayload } = await queryFulfilled;

                dispatch(login({ ...loginPayload, storeIn: "session" }));
            },
        }),
        logout: build.mutation<void, void>({
            query: () => ({
                url: "v1/auth/logout",
                method: "POST",
            }),
            async onQueryStarted(_, { dispatch }) {
                dispatch(setSubscription(null));
                dispatch(clearAuth());
            },
        }),
    }),
});

export const { useLogoutMutation, useHumedLoginMutation } = api;
