import type { CSSProperties } from "react";
import { useMenu } from "@madmedical/sdk";
import { withNextHealthEnabled } from "tdoc-common";

interface Props {
    style?: CSSProperties;
}

const NextHealthMenu = ({ style }: Props) => {
    const menu = useMenu();

    return (
        <>
            {menu.map(({ Icon, title, onPress }) => (
                <li
                    style={style}
                    key={`next-health-menu-${title}`}
                    onClick={onPress}
                >
                    <Icon size={16} />
                    <span>{title}</span>
                </li>
            ))}
        </>
    );
};

export default withNextHealthEnabled(NextHealthMenu);
