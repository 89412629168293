interface Props {
    text: string[];
}

const ChatPromotion = ({ text }: Props) => (
    <svg
        id="plecsni"
        xmlns="http://www.w3.org/2000/svg"
        width="172"
        height="85.162"
        viewBox="0 0 172 85.162"
    >
        <rect id="Rectangle_4942" width="172" height="69" fill="#ffd943" />
        <text
            id="chat_promotion"
            data-name={text}
            transform="translate(86 21.5)"
            fill="#263d50"
            fontSize="14"
            fontFamily="Roboto-Black, Roboto"
            fontWeight="800"
        >
            <tspan x="-29.449" y="0">
                {text[0]}{" "}
            </tspan>
            <tspan x="-64.702" y="19">
                {text[1]}{" "}
            </tspan>
            <tspan x="-49.906" y="38">
                {text[2]}
            </tspan>
            <tspan x="0" y="57"></tspan>
        </text>
        <path
            id="Path_10847"
            d="M7,0l7,12L-4.734,6.775Z"
            transform="translate(154.607 75.063) rotate(-60)"
            fill="#e9c844"
        />
    </svg>
);

export default ChatPromotion;
