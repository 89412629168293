import { Grid } from "@material-ui/core";
import { intlFormat } from "date-fns";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import Tag from "../atoms/Tag";
import Avatar from "../atoms/Avatar";
import { EVENTS, EVENT_FLAGS } from "../../../config/variables";
import { formatNumber, makeInitials } from "../../../utility";
import classes from "./EventRow.module.css";

const generateName = (payload) => {
    const payloadObj = JSON.parse(payload);

    if (payloadObj?.doctorName) {
        return payloadObj.doctorName;
    }

    if (payloadObj?.doctor) {
        return payloadObj.doctor;
    }

    if (payloadObj?.type === "nextHealth") {
        return payloadObj.subject;
    }

    return null;
};

const generateText = (payload, event_name) => {
    const payloadObj = JSON.parse(payload);

    if (payloadObj === null) {
        return null;
    }

    if (event_name === "PRESCRIPTION") {
        return `Felírt készítmény: ${
            payloadObj.medicines
        } | Összeg: ${formatNumber(payloadObj?.price.summaryAmount)} Ft`;
    }

    switch (payloadObj?.type) {
        case "newChatMessage":
            return payloadObj.author + " új üzenetet küldött";
        case "newPrescriptionMessage":
            return payloadObj.author + " új üzenetet küldött";
        case "recommendedService":
            return payloadObj.service + " szolgáltatást ajánlottak neked";
        case "newRatingAvailable":
            return "Értékeld legutóbb igénybevett szolgáltatásod";
        case "nextHealth":
            return payloadObj.subject || payloadObj.content;
        default:
            return "";
    }
};

const EventRowDoctor = ({
    created_at,
    event_name,
    event_id,
    text,
    message_body,
    payload,
    patient_unread,
    chatData,
}) => {
    const navigate = useNavigate();
    const handleMakeInitial = () => {
        const name = generateName(payload);

        return makeInitials(name);
    };

    const handleRoute = () => {
        if (EVENT_FLAGS.includes(event_name)) {
            if (event_name === "CHAT") {
                navigate(`/elozmenyek/orvosi-chat/${event_id}`);
            } else if (event_name === "SECOND_OPINION") {
                navigate(`/elozmenyek/e-masodvelemeny/${event_id}`);
            } else if (event_name === "PRESCRIPTION") {
                navigate(`/elozmenyek/e-recept/${event_id}`);
            }
        }
    };

    return (
        <Grid
            container
            className={cn(classes.row, classes.hasPointer, {
                [classes.unread]:
                    patient_unread === 1 || chatData?.patient_unread === 1,
            })}
            onClick={handleRoute}
            data-component="EventRowDoctor"
        >
            <Grid item xs={12}>
                <div className={classes.innerRow}>
                    <div className={classes.avatar}>
                        <Avatar
                            size="xl"
                            initials={handleMakeInitial()}
                            // source="https://randomuser.me/api/portraits/men/36.jpg"
                        />
                    </div>
                    <div className={classes.content}>
                        <div className={classes.bar}>
                            <div className={classes.info}>
                                {typeof generateName(payload) === "string" && (
                                    <h3 className={classes.title}>
                                        {generateName(payload)}
                                    </h3>
                                )}
                                <div className={classes.date}>
                                    <p>
                                        {intlFormat(
                                            new Date(created_at),
                                            {
                                                weekday: "long",
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            },
                                            {
                                                locale: "hu-HU",
                                            }
                                        )}
                                    </p>
                                </div>
                                <div className={classes.indicators}>
                                    <div className={classes.tag}>
                                        <Tag variant="outlined">
                                            {EVENTS[event_name]}
                                        </Tag>
                                    </div>
                                    {/*
                                    <div className={classes.tag}>
                                        <Tag
                                            variant="outlined"
                                            icon={<AttachmentIcon />}
                                        >
                                            1
                                        </Tag>
                                    </div>
                                    */}
                                    {/*
                                    <div className={classes.badge}>
                                        <StatusBadge size="xl" level={1} />
                                    </div>
                                    */}
                                </div>
                            </div>
                            <div className={classes.date}>
                                <p>
                                    {intlFormat(
                                        new Date(created_at),
                                        {
                                            weekday: "long",
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        },
                                        {
                                            locale: "hu-HU",
                                        }
                                    )}
                                </p>
                            </div>
                        </div>

                        {(text ||
                            message_body ||
                            typeof generateText(payload, event_name) ===
                                "string") && (
                            <div className={classes.text}>
                                <p>
                                    {text ||
                                        message_body ||
                                        generateText(payload, event_name)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default EventRowDoctor;
