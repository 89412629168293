import { baseApi } from "../store";

const api = baseApi.injectEndpoints({
    endpoints: (build) => ({
        isNhaUser: build.query<{ isNhaUser: boolean }, void>({
            query: () => "isnhuser",
        }),
    }),
});

export const { useIsNhaUserQuery } = api;
