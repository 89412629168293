import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
    dispatchPrescriptionRedeemStatusColor,
    dispatchPrescriptionRedeemStatusText,
    formatDateWithYear,
} from "../../../../utility";
import classes from "../../pages/Webshop.module.css";
import Btn from "../../../UI/Btn/Btn";
import useTranslation from "../../../../features/i18n/useTranslation";
import { endLoading, patch } from "../../../../config/fetch";

const CheckBox = ({ onChange, text, checked }) => (
    <label
        style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            flexDirection: "row",
        }}
    >
        <input
            style={{
                width: "20px",
                maxWidth: "20px",
                height: "20px",
                maxHeight: "20px",
                marginRight: "5px",
            }}
            checked={checked}
            type="checkbox"
            onChange={onChange}
            onClick={(e) => {
                e.stopPropagation();
            }}
        />
        <span
            style={{
                display: "flex",
                alignItems: "center",
            }}
        >
            {text}
        </span>
    </label>
);

const Select = ({ items, onChange, value }) => (
    <select
        style={{
            width: "60px",
            height: "30px",
        }}
        onChange={onChange}
        value={value}
        onClick={(e) => {
            e.stopPropagation();
        }}
    >
        {items.map((item, idx) => (
            <option key={idx} value={item.value}>
                {item.label}
            </option>
        ))}
    </select>
);

const ReadOnlyPrescription = ({ redeem }) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            paddingLeft: "10px",
        }}
    >
        <span style={{ fontWeight: "bold", marginBottom: "10 px" }}>
            Részletek:
        </span>
        <div
            style={{
                marginLeft: "5px",
            }}
        >
            {redeem.prescriptionRedeemProducts.length === 0 && (
                <div>Nincs felírt termék</div>
            )}
            {redeem.prescriptionRedeemProducts.map((product) => (
                <div
                    key={product.piqProductId}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                        marginBottom: "5px",
                    }}
                >
                    <div
                        style={{
                            width: "50px",
                            marginRight: "5px",
                        }}
                    >
                        {product.quantity} db
                    </div>
                    <div>{product.name}</div>
                    <div
                        style={{
                            width: "50px",
                            marginRight: "5px",
                        }}
                    >
                        {product.price * product.quantity} Ft
                    </div>
                </div>
            ))}
        </div>
    </div>
);

const EditablePrescription = ({ redeem }) => {
    const [cart, setCart] = useState(
        redeem.prescriptionRedeemProducts.reduce(
            (acc, product) => ({
                ...acc,
                [product.piqProductId]: {
                    checked: true,
                    quantity: product.quantity,
                    price: product.price,
                    priceApproval: product.priceApproval,
                },
            }),
            {}
        )
    );

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                paddingLeft: "10px",
            }}
        >
            <span style={{ fontWeight: "bold", marginBottom: "10 px" }}>
                Részletek:
            </span>
            <div
                style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                }}
            >
                {redeem.prescriptionRedeemProducts.length === 0 && (
                    <div>Nincs felírt termék</div>
                )}
                <div
                    style={{
                        overflow: "auto",
                        height: "170px",
                        maxWidth: "100%",
                    }}
                >
                    {redeem.prescriptionRedeemProducts.map((product) => (
                        <div
                            key={product.piqProductId}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "5px",
                            }}
                        >
                            <CheckBox
                                text={product.name + " " + product.piqProductId}
                                onChange={(e) => {
                                    setCart((prevCart) => ({
                                        ...prevCart,
                                        [product.piqProductId]: {
                                            ...prevCart[product.piqProductId],
                                            checked: e.target.checked,
                                        },
                                    }));
                                }}
                                checked={cart[product.piqProductId].checked}
                            />
                            <Select
                                items={[
                                    ...Array(product.quantity + 1).keys(),
                                ].map((i) => ({
                                    value: i,
                                    label: i,
                                }))}
                                value={cart[product.piqProductId].quantity}
                                onChange={(e) => {
                                    setCart((prevCart) => ({
                                        ...prevCart,
                                        [product.piqProductId]: {
                                            ...prevCart[product.piqProductId],
                                            quantity: e.target.value,
                                        },
                                    }));
                                }}
                            />
                            <div>
                                {cart[product.piqProductId].price *
                                    cart[product.piqProductId].quantity}{" "}
                                Ft
                            </div>
                        </div>
                    ))}
                </div>
                {Object.values(cart).length > 0 && (
                    <Btn
                        style={{
                            width: "80%",
                        }}
                        disabled={
                            !Object.values(cart).some(
                                (product) =>
                                    product.checked && product.quantity > 0
                            )
                        }
                        onClick={(e) => {
                            patch(
                                `api/webshop/prescription-redeem/${redeem.id}/products`,
                                {
                                    products: Object.entries(cart)
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        .filter(
                                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                            ([_, product]) => product.checked
                                        )
                                        .map(([productId, product]) => ({
                                            piqProductId: productId,
                                            quantity: product.quantity,
                                            price: product.price,
                                            priceApproval:
                                                product.priceApproval,
                                        })),
                                }
                            )
                                .then((response) => {
                                    if (response.status === 200) {
                                        window.location =
                                            response.data.redirectUrl;
                                    } else {
                                        alert(
                                            "Hiba történt a módosítás során."
                                        );
                                    }
                                    endLoading();
                                })
                                .catch((error) => {
                                    alert(
                                        error?.data?.message ??
                                            "Ismeretlen hiba történt. Kérjük próbálkozzon később."
                                    );
                                    endLoading();
                                });
                            e.stopPropagation();
                        }}
                    >
                        Kosárba
                    </Btn>
                )}
            </div>
        </div>
    );
};

const ExtendedRedeemRow = ({ redeem, onRowClicked }) => {
    const navigate = useNavigate();
    const { i18nRoute } = useTranslation();

    return (
        <Grid
            container
            style={{
                border: "1px solid black",
                width: "95%",
                height: "300px",
                marginBottom: "-1px",
                justifyContent: "center",
                paddingTop: "5px",
            }}
            onClick={onRowClicked}
        >
            <Grid item xs={12} md={4}>
                {redeem.status === "in_progress" ? (
                    <EditablePrescription redeem={redeem} />
                ) : (
                    <ReadOnlyPrescription redeem={redeem} />
                )}
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                style={{
                    display: "flex",
                    justifyContent: "left",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                        }}
                    >
                        {formatDateWithYear(redeem.createdAt)}
                    </div>
                    <div
                        style={{
                            marginTop: "10px",
                            marginLeft: "5px",
                            marginRight: "5px",
                        }}
                    >
                        <span>Gyógyszerész megjegyzése: </span>
                        <div
                            style={{
                                marginTop: "5px",
                                overflow: "auto",
                                maxHeight: "180px",
                                maxWidth: "100%",
                            }}
                        >
                            {redeem.note ?? "Nincs megjegyzés"}
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                style={{
                    justifyContent: "center",
                    verticalAlign: "top",
                }}
            >
                <div
                    style={{
                        color: dispatchPrescriptionRedeemStatusColor(
                            redeem.status
                        ),
                        marginBottom: "30px",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {dispatchPrescriptionRedeemStatusText(redeem.status)}
                </div>
                <div
                    style={{
                        marginBottom: "10px",
                    }}
                >
                    Más receptre is szüksége lenne?
                </div>
                <div
                    style={{
                        marginBottom: "10px",
                    }}
                >
                    Igényelje e-recept szolgáltatásunkon keresztül.
                </div>
                <Btn
                    className={classes.button}
                    style={{
                        width: "80%",
                    }}
                    onClick={(e) => {
                        navigate(i18nRoute("routes.services.prescription"));
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    E-recept
                </Btn>
            </Grid>
        </Grid>
    );
};
export default ExtendedRedeemRow;
