import type { ComponentProps } from "react";
import useIsNha from "./useIsNha";

export default <Props extends JSX.IntrinsicAttributes>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: ComponentProps<any>
) => {
    const WithNextHealthEnabled = (props: Props) => {
        const { isNhaUser } = useIsNha();

        return isNhaUser ? <Component {...props} /> : null;
    };

    return WithNextHealthEnabled;
};
