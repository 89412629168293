import { useEffect, useState } from "react";
import { Colors, Fonts, Icons, Images } from "../../../assets/Theme";
import ServicePage from "../../../components/UI/ServicePage/ServicePage";
import { endLoading, post } from "../../../config/fetch";
import { ecommerceProductDetail } from "../../../config/ecommerce";
import ServiceFilter from "../ServiceFilter/ServiceFilter";

const OutPatient = () => {
    const [packages, setPackages] = useState(null);

    useEffect(() => {
        post("api/v1/subscription/get-service-prices-by-user", {
            service: "OUTPATIENT",
        }).then((response) => {
            endLoading();
            if (response.data.responseCode === "OK") {
                let _packages = processPackages(
                    response.data.packages,
                    response.data.subscription
                );
                setPackages(_packages);
            }
        });
    }, []);

    const processPackages = (packages, subscribedIndex) => {
        ecommerceProductDetail("OUTPATIENT", 0, subscribedIndex);
        const _packages = [];
        packages.forEach((p, i) =>
            _packages.push({
                name:
                    i !== 0
                        ? `${p.packageName} előfizetéssel`
                        : "Előfizetés nélkül",
                price:
                    i !== 0
                        ? `Akár ${p.price} % kedvezmény`
                        : `${p.price} % kedvezmény`,
                selected: subscribedIndex - 1 == i,
            })
        );

        return _packages;
    };

    return (
        <ServicePage
            icon={Icons.reservationGreen}
            name="Járóbeteg ellátás időpontfoglalás"
            description="Jókor - jó helyen - szakértő kezekben. Személyes vizithez Magyarország legjobb magánegészségügyi intézményeiben praktizáló szakorvosgárda időpontjai közül válogathatsz. "
            image={Images.idopont}
            packages={packages}
            showImportantInfo={false}
            noButton={true}
            buttonText="Válassz szakterületet!"
            buttonDisabled={false}
        >
            <ServiceFilter type="outpatient" withInstitution={true} />

            <p style={styles.title}>
                Online időpontfoglalás járóbetegellátásra
            </p>
            <p style={styles.text}>
                Amit digitális úton nem tudunk biztosítani számodra, azt a
                klasszikus értelemben vett szakorvosi vizsgálattal egészítjük ki
                a legmagasabb szakmai színvonalat képviselő partnerintézményeink
                egyikében. Több mint 20 szakterület, több mint 50 orvos szabad
                időpontjai közül választhatod ki azt, mely számodra a
                legideálisabb.{" "}
            </p>
        </ServicePage>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontWeight: 700,
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
    },
};

export default OutPatient;
