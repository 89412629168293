import { Container } from "@material-ui/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/UI/Breadcrumbs/Breadcrumbs";
import Card from "../../components/UI/Card/Card";
import PrescriptionRoom from "../PrescriptionRoom/PrescriptionRoom";
import classes from "./PrescriptionPage.module.css";

const PrescriptionPage = () => {
    const { identifier } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let renderContainer = (content) => {
        if (window.innerWidth < 600) {
            return <div>{content}</div>;
        }

        return <Card className={classes.card}>{content}</Card>;
    };

    return (
        <Container>
            <div className={classes.ChatPageInner}>
                <Breadcrumbs
                // replaceKeys={!secondOpinion ? { 'chat': 'chat' } : { 'chat': 'second-opinion' }}
                />
                {renderContainer(<PrescriptionRoom identifier={identifier} />)}
            </div>
        </Container>
    );
};

export default PrescriptionPage;
