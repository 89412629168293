import type { FetchArgs } from "@reduxjs/toolkit/query/react";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import type { Dispatch } from "@reduxjs/toolkit";
import { getEnv } from "../config";
import type { StateAuth } from "./model";

const getResponseCode = (error: FetchBaseQueryError | undefined) => {
    if (!error) {
        return undefined;
    }

    if (error.status === "PARSING_ERROR") {
        return error.originalStatus;
    }

    return error.status;
};

interface ThunkExtraArgument {
    readonly onAuthRequired: (dispatch: Dispatch) => void;
}

const baseQuery = retry(
    async (args: string | FetchArgs, api, extraOptions) => {
        const result = await fetchBaseQuery({
            baseUrl: `https://${getEnv("HOSTNAME_BACKEND")}/api/`,
            prepareHeaders: (headers, { getState }) => {
                const token = (getState() as StateAuth).auth.token;

                if (token) {
                    headers.set("Token", token);
                }

                return headers;
            },
        })(args, api, extraOptions);

        const responseCode = getResponseCode(result.error);

        // 401 suggests token has expired, so we pop up or redirect to login
        if (responseCode === 401) {
            (api.extra as ThunkExtraArgument).onAuthRequired(api.dispatch);
        }

        // bail out of re-tries for client-side errors – unauth, validation etc
        if (responseCode && 400 <= responseCode && responseCode < 500) {
            retry.fail(result.error);
        }

        return result;
    },
    { maxRetries: 3 }
);

export default createApi({
    baseQuery,
    refetchOnReconnect: true,
    endpoints: () => ({}),
    tagTypes: [],
});
