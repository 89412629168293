import * as actionTypes from "./actionTypes";

export const setCookie = (cookie, enabled) => ({
    type: actionTypes.SET_COOKIE,
    cookie,
    enabled,
});

export const setCookieSettings = (cookies) => ({
    type: actionTypes.SET_COOKIE_SETTINGS,
    cookies,
});
