import { Container, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import CardContainer from "../../../components/UI/CardContainer/CardContainer";
import { Icons } from "../../../assets/Theme";
import Line from "../../../components/UI/Line/Line";
import CustomSwitch from "../../../components/UI/CustomSwitch/CustomSwitch";
import { endLoading, get, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import classes from "./NotificationSettings.module.css";

const NotificationSettings = () => {
    // const [notificationsEditing, setNotificationsEditing] = useState(false);
    const [settings, setSettings] = useState({
        newsletter: null,
        inAppNotifications: null,
        emailNotifications: null,
    });

    useEffect(() => {
        get("user/get-profile-summary")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setSettings({
                            newsletter: response.data.user.newsletter,
                            inAppNotifications:
                                response.data.user.inAppNotifications,
                            emailNotifications:
                                response.data.user.emailNotifications,
                        });

                        break;
                    default:
                        break;
                }
                endLoading();
            })
            .catch((error) => console.error(error));
    }, []);

    const changeNotificationSettings = (value, type) => {
        let data = { ...settings };
        switch (type) {
            case "email":
                data = { ...settings, emailNotifications: value };
                setSettings({ ...settings, emailNotifications: value });

                break;
            case "newsletter":
                data = { ...settings, newsletter: value };
                setSettings({ ...settings, newsletter: value });

                break;
            case "inApp":
                data = { ...settings, inAppNotifications: value };
                setSettings({ ...settings, inAppNotifications: value });

                break;
            default:
                break;
        }
        saveNotificationsHandler(data);
    };

    const saveNotificationsHandler = (data) =>
        post("user/update-user-notifications", { data })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs />
                <CardContainer className={classes.card}>
                    <Grid container>
                        <Grid sm={2} xs={12} item>
                            <img
                                src={Icons.notificationsGreen}
                                alt=""
                                style={{ fill: "#f0f" }}
                                className={classes.icon}
                            />
                        </Grid>
                        <Grid sm={8} xs={12} item>
                            <h2 className={classes.header}>Értesítések</h2>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={2} xs={12}></Grid>
                        <Grid item sm={8} xs={12}>
                            <div>
                                <div className={classes.textContainer}>
                                    <div className={classes.innerContainer}>
                                        <div>
                                            <p className={classes.title}>
                                                E-mail értesítések (ajánlott)
                                            </p>
                                            <p className={classes.cardText}>
                                                Szolgáltatással kapcsolatos
                                                legfontosabb értesítések
                                                (értesítés beérkező üzenetekről,
                                                közelgő időpontokról, sikertelen
                                                kártyás fizetésről)
                                            </p>
                                        </div>
                                        {settings.emailNotifications !==
                                            null && (
                                            <div className={classes.switch}>
                                                <CustomSwitch
                                                    onChangeSwitch={(value) =>
                                                        changeNotificationSettings(
                                                            value,
                                                            "email"
                                                        )
                                                    }
                                                    style={{
                                                        marginTop: "2rem",
                                                    }}
                                                    enabled={
                                                        settings.emailNotifications
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Line />
                            </div>
                            <div>
                                <div className={classes.textContainer}>
                                    <div className={classes.innerContainer}>
                                        <div>
                                            <p className={classes.title}>
                                                Szolgáltatással kapcsolatos
                                                hírek és egyedi ajánlatok
                                            </p>
                                            <p className={classes.cardText}>
                                                E-mail és alkalmazáson belüli
                                                értesítések
                                            </p>
                                        </div>
                                        {settings.newsletter !== null && (
                                            <div className={classes.switch}>
                                                <CustomSwitch
                                                    onChangeSwitch={(value) =>
                                                        changeNotificationSettings(
                                                            value,
                                                            "newsletter"
                                                        )
                                                    }
                                                    style={{
                                                        marginTop: "2rem",
                                                    }}
                                                    enabled={
                                                        settings.newsletter
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Line />
                            </div>
                        </Grid>
                    </Grid>
                </CardContainer>
            </div>
        </Container>
    );
};

export default NotificationSettings;
