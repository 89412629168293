import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import classes from "./ServiceCard2.module.css";

const VerticalBody = (props) => (
    <>
        <img src={props.icon} className={classes.icon} alt="" />
        <div className={classes.textCol}>
            <div className={classes.titleContainer}>
                <span className={classes.title}>{props.title}</span>
                <ChevronRightIcon style={styles.chevron} />
            </div>
            <span className={classes.text}>{props.text}</span>
        </div>
    </>
);

const ServiceCard2 = (props) => (
    <div
        className={`${classes.serviceCard} ${
            props.type === "vertical" ? classes.vertical : classes.horizontal
        }`}
        onClick={props.onClick}
    >
        {props.type === "vertical" && (
            <VerticalBody
                icon={props.icon}
                title={props.title}
                text={props.text}
            />
        )}
    </div>
);

const styles = {
    chevron: {
        width: "3rem",
        height: "3rem",
    },
};

export default ServiceCard2;
