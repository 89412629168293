import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { Box, Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import type { AxiosError, AxiosResponse } from "axios";
import { endLoading, post } from "../../../../config/fetch";
import { ALERT_MESSAGES } from "../../../../config/variables";
import { responseMessage } from "../../../../utility";
import { useResolution } from "../../../../ui/utils";
import BtnWithIcon from "../../../../components/UI/Btn/BtnWithIcon";
import type { FormSection } from "../../../../ui/models";
import { Colors } from "../../../../assets/Theme";
import PageHeader from "../../../../ui/layout/PageHeader";
import useTranslation from "../../../i18n/useTranslation";
import CheckBox from "./CheckBox";
import TextArea from "./TextArea";
import classes from "./styles.module.css";
import InputField from "./InputField";

interface Props {
    contentBlock: FormSection;
}

const FormBlock = ({ contentBlock }: Props) => {
    interface FormData {
        name: string;
        email: string;
        phone: string;
        message: string;
    }

    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const [isTermAccepted, setTermAccepted] = useState(false);

    const [sent, setSent] = useState(false);

    const isTablet = useResolution("tablet");
    const { i18nRoute } = useTranslation();

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => setFormData({ ...formData, [event.target.name]: event.target.value });

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (!isTermAccepted) {
            responseMessage(
                "Az ÁSZF és az Adatkezelési nyilatkozat elfogadása kötelező"
            );

            return;
        }

        if (!isValidPhoneNumber(formData.phone)) {
            responseMessage("Hibás telefonszám!");

            return;
        }

        post("api/v1/contact/create-message", {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            message: formData.message,
        })
            .then((response: AxiosResponse) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setSent(true);
                        endLoading();

                        break;
                    case "MISSING_DATA":
                        responseMessage("Minden adat megadása kötelező!");
                        endLoading();

                        break;
                    case "WRONG_EMAIL":
                        responseMessage("Hibás email!");
                        endLoading();

                        break;
                    case "WRONG_PHONE":
                        responseMessage("Hibás telefonszám!");
                        endLoading();

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();
                }
            })
            .catch((error: AxiosError) => {
                console.error(error);
                endLoading();
            });

        if (sent) setFormData({ name: "", email: "", phone: "", message: "" });
    };

    return (
        <Container>
            <Box color={Colors.darkBlue} my={4}>
                {contentBlock.sectionLabel && (
                    <PageHeader text={contentBlock.sectionLabel} />
                )}
                <Box textAlign="center">{contentBlock.description}</Box>
                <Grid container>
                    <Grid item sm={2} md={3}></Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <form onSubmit={handleSubmit}>
                            <Box px={2} py={2}>
                                <Box>
                                    <InputField
                                        name="name"
                                        label="Név"
                                        type="text"
                                        placeholder="Minta János"
                                        required
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box>
                                    <InputField
                                        name="email"
                                        label="Email cím"
                                        type="email"
                                        placeholder="vezeteknev@minta.hu"
                                        required
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box>
                                    <InputField
                                        name="phone"
                                        label="Telefon"
                                        type="tel"
                                        placeholder="+36 30 123 45 67"
                                        required
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box>
                                    <TextArea
                                        name="message"
                                        label="Üzenet"
                                        placeholder="Ide írja be üzenetét"
                                        required
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </Box>

                                <Box
                                    onClick={() =>
                                        setTermAccepted(!isTermAccepted)
                                    }
                                    pt={3}
                                    pb={6}
                                >
                                    <CheckBox
                                        checked={isTermAccepted}
                                        label={
                                            <Box
                                                pl={2}
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: "16px",
                                                }}
                                            >
                                                Elolvastam és elfogadom az{" "}
                                                <Link
                                                    className={classes.link}
                                                    to={
                                                        "/adatvedelmi_nyilatkozat"
                                                    }
                                                    target="_blank"
                                                >
                                                    általános adatkezelési
                                                    irányelveket
                                                </Link>{" "}
                                                és a{" "}
                                                <Link
                                                    className={classes.link}
                                                    to={i18nRoute(
                                                        "routes.terms"
                                                    )}
                                                    target="_blank"
                                                >
                                                    felhasználási feltételeket.
                                                </Link>
                                            </Box>
                                        }
                                    />
                                </Box>
                                <Box px={!isTablet && 10}>
                                    <BtnWithIcon
                                        color="green"
                                        text="Űrlap küldése"
                                        style={{ width: "100%" }}
                                        type="submit"
                                    />
                                </Box>
                            </Box>
                        </form>
                    </Grid>
                    <Grid item sm={2} md={3}></Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default FormBlock;
