import { baseApi } from "../store";
import type { UpdateCaseRequest } from "./model";

const api = baseApi.injectEndpoints({
    endpoints: (build) => ({
        updateCase: build.mutation<void, UpdateCaseRequest>({
            query: ({ caseId, ...body }) => ({
                url: `cases/${caseId}`,
                method: "PATCH",
                body,
            }),
        }),
    }),
});

export const { useUpdateCaseMutation } = api;
