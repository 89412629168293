import type { RootState } from "../../store";
import { useSelector } from "../../store";

const useAuth = () => {
    const state = useSelector((state: RootState) => state.auth);

    return {
        isLoading: state.isAuthLoading,
        isAuthenticated: !!state.token,
        token: state.token,
        userId: state.userId,
    };
};

export default useAuth;
