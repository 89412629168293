import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { endLoading, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import useQuery from "../../features/util/useQuery";
import classes from "./VerifyNewsletter.module.css";

const VerifyNewsletter = () => {
    const query = useQuery();
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        const hash = query.get("hash");
        if (hash) {
            post("api/v1/auth/verify-newsletter", { hash })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            setVerified(true);

                            break;
                        default:
                            responseMessage("Hibás vagy nem létező hash!");

                            break;
                    }
                })
                .catch((error) => console.error(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.VerifyNewsletter}>
            <Container>
                <div className={classes.VerifyNewsletterInner}>
                    {verified && (
                        <>
                            <h3>Sikeres megerősítés</h3>
                            <p>Kedves Felhasználó!</p>
                            <p>
                                A hírlevélre való feliratkozását sikeresen
                                megerősítette!
                            </p>
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default VerifyNewsletter;
