import { useEffect, useRef } from "react";
import { Container } from "@material-ui/core";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/UI/Breadcrumbs/Breadcrumbs";
import CardContainer from "../../components/UI/CardContainer/CardContainer";
import Button from "../../components/UI/Button/Button";
import * as actions from "../../store/actions/index";
import useTranslation from "../../features/i18n/useTranslation";
import useAuth from "../../features/auth/useAuth";
import classes from "./HowTo.module.css";

const HowTo = (props) => {
    const location = useLocation();
    const { isAuthenticated } = useAuth();
    const hotlineRef = useRef();
    const chatRef = useRef();
    const videoRef = useRef();
    const outpatientRef = useRef();
    const eReceptRef = useRef();
    const connectRef = useRef();
    const { i18nRoute } = useTranslation();

    const jumpTo = (target) => {
        let element = null;
        switch (target) {
            case "hotline":
                element = hotlineRef.current;

                break;
            case "chat":
                element = chatRef.current;

                break;
            case "video":
                element = videoRef.current;

                break;
            case "outpatient":
                element = outpatientRef.current;

                break;
            case "erecept":
                element = eReceptRef.current;

                break;

            case "connect":
                element = connectRef.current;

                break;
        }
        if (element) {
            const top = element.offsetTop;

            window.scrollTo({ top: top - 100, left: 0, behavior: "smooth" });
        }
    };

    useEffect(() => {
        const hash = location.hash;
        if (hash && hash !== "") {
            jumpTo(hash.substr(1));
        }
    }, [location.hash]);

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs />
                <CardContainer className={classes.card}>
                    <h2 className={classes.title}>Hogyan működik?</h2>
                    <ol
                        className={`${classes.blackText} ${classes.list} ${classes.navigation}`}
                    >
                        <li>
                            <Link
                                to={i18nRoute("routes.howTo", {
                                    routeHash: "#hotline",
                                })}
                            >
                                Egészségügyi hotline és általános orvosi
                                konzultáció
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={i18nRoute("routes.howTo", {
                                    routeHash: "#chat",
                                })}
                            >
                                Orvosi chat és másodvélemény
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={i18nRoute("routes.howTo", {
                                    routeHash: "#video",
                                })}
                            >
                                Videókonzultáció
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={i18nRoute("routes.howTo", {
                                    routeHash: "#outpatient",
                                })}
                            >
                                Időpontfoglalás járóbetegellátásra
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={i18nRoute("routes.howTo", {
                                    routeHash: "#erecept",
                                })}
                            >
                                E-recept igénylés
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={i18nRoute("routes.howTo", {
                                    routeHash: "#connect",
                                })}
                            >
                                Eszközök csatlakoztatása
                            </Link>
                        </li>
                    </ol>
                    <h2 className={classes.greenText} ref={hotlineRef}>
                        Egészségügyi hotline és általános orvosi konzultáció
                    </h2>
                    <p>
                        Az{" "}
                        <Link
                            to={i18nRoute("routes.services.hotline")}
                            className={classes.link}
                        >
                            egészségügyi hotline
                        </Link>{" "}
                        és az{" "}
                        <Link
                            to={i18nRoute("routes.services.duty")}
                            className={classes.link}
                        >
                            általános orvosi konzultáció
                        </Link>{" "}
                        telefonon hívható, hangalapú szolgáltatásaink közé
                        tartoznak. A hívás kezdeményezéséhez minden esetben
                        regisztrációval kell rendelkezned, valamint be kell
                        jelentkezned saját fiókodba.{" "}
                    </p>
                    <p>
                        Ezt követően a kiválasztott szolgáltatás aloldalán
                        kattints a „Kérem a telefonszámot” gombra. Előfizetőként
                        az egészségügyi hotline-t a{" "}
                        <span className={classes.strong}>
                            nyitvatartási idő alatt bármikor díjmentesen
                            hívhatod.
                        </span>{" "}
                        Optimum és Premium előfizetésünk esetében{" "}
                        <span className={classes.strong}>
                            az általános orvosi konzultáció is korlátlanul
                            hívható.
                        </span>{" "}
                    </p>
                    <p>
                        A fizetési feltételek és ÁSZF elfogadását követően
                        rendszerünk megjeleníti a hívható telefonszámot,
                        valamint sms-ben is kiküldi. Mobileszközön a telefonszám
                        megjelenik a fizetés/kredit felhasználást követően, amit
                        azonnal tárcsázhatsz.
                    </p>
                    <p className={classes.subheader}>
                        Lépésről-lépésre röviden:
                    </p>
                    <ol className={`${classes.blackText} ${classes.list}`}>
                        <li>
                            Regisztráció/bejelentkezés (kötelező adatok:
                            személyes és számlázási adatok, bankkártya adatok)
                        </li>
                        <li>
                            Szolgáltatás oldalon „Kérem a telefonszámot” gombra
                            történő kattintás.
                        </li>
                        <li>
                            Fizetés vagy előfizetésen belüli szolgáltatás
                            felhasználás
                        </li>
                        <li>
                            Telefonszám tárcsázása, melyet sms-ben kiküldünk
                            és/vagy megjelenítünk a képernyődön
                        </li>
                        <li>Kollégánkkal történő konzultáció</li>
                    </ol>
                    <p style={{ marginTop: "3rem" }}>
                        Nem kaptál választ kérdésedre? Nézz körül a{" "}
                        <Link
                            to={i18nRoute("routes.faq.base")}
                            className={classes.link}
                        >
                            súgóközpontunkban!
                        </Link>
                    </p>
                    <h2 className={classes.greenText} ref={chatRef}>
                        Orvosi chat és másodvélemény
                    </h2>
                    <p>
                        Az{" "}
                        <Link
                            to={i18nRoute("routes.services.chat")}
                            className={classes.link}
                        >
                            orvosi chat
                        </Link>{" "}
                        és{" "}
                        <Link
                            to={i18nRoute("routes.services.secondOpinion")}
                            className={classes.link}
                        >
                            másodvélemény
                        </Link>{" "}
                        chat alapú szolgáltatásaink közé tartoznak. Mindkét
                        esetben gyorsan, kényelmesen és{" "}
                        <span className={classes.strong}>
                            100% diszkréció mellett kérdezhetsz orvosainktól
                        </span>{" "}
                        üzenet formájában.{" "}
                    </p>
                    <p>
                        Az üzenet mellé az orvosi chat esetében lehetőséged van
                        1 db fájlt csatolnod (max. 50 Mb), a másodvélemény
                        esetében csupán a feltöltendő fájl méretét korlátozzuk
                        (max. 50 Mb). Új üzenet küldést regisztrált
                        felhasználóként, saját profilodba történő belépést
                        követően tudsz indítani.{" "}
                    </p>
                    <p>
                        A szolgáltatások aloldalán, az „Üzenet küldése” gombra
                        történő kattintással teheted meg az első lépést. Ezt
                        követően rendszerünk átirányít az üzenetküldő felületre,
                        ahol kiválaszthatod a szakterületet, valamint azon belül
                        az orvost, akinek fel szeretnéd tenni kérdésed. Ha nem
                        tudod pontosan, hogy mely szakterülethez kapcsolódik
                        problémád, vagy kit válassz az orvosok közül, akkor
                        válaszd az „Egyéb” kategóriát és/vagy a „Nem választok
                        orvost”. Ez esetben ügyeletes orvosunk az üzenet
                        tartalma alapján a megfelelő szakemberhez irányítja
                        kérdésed.{" "}
                    </p>
                    <p>
                        Fogalmazd meg egészségügyi problémáddal, panaszoddal
                        kapcsolatos kérdéseid, szükség esetén csatolj egy
                        dokumentumot (pl. fotó a problémás területről, korábbi
                        leletet, ambuláns lapot, diagnosztikai lapot stb.), majd
                        kattints az „Üzenet küldése” gombra.{" "}
                    </p>
                    <p>
                        Az elküldött üzeneted tartalmát, valamint az{" "}
                        <span className={classes.strong}>
                            orvos válaszát is saját fiókodon belül,
                        </span>{" "}
                        az előzmények menüpontban találod meg. Amint az orvos
                        válaszol, e-mailben és fiókodon belül rendszerüzenetben
                        is értesítünk róla.
                    </p>
                    <p className={classes.subheader}>
                        Lépésről-lépésre röviden:
                    </p>
                    <ol className={`${classes.blackText} ${classes.list}`}>
                        <li>
                            Regisztráció/bejelentkezés (kötelező adatok:
                            személyes és számlázási adatok, bankkártya adatok)
                        </li>
                        <li>
                            Szolgáltatás oldalon „Üzenet küldése” gombra történő
                            kattintás.{" "}
                        </li>
                        <li>
                            Szakterület/Orvos kiválasztása, kérdés/üzenet
                            megfogalmazása és üzenet mezőbe történő begépelése.
                        </li>
                        <li>
                            Fizetés/kredit felhasználás és ÁSZF/Adatvédelmi
                            nyilatkozat elfogadása.
                        </li>
                        <li>
                            48/72 órán belül az orvostól érkezett válaszról
                            e-mailben és saját fiókodon belül rendszerüzenet
                            formájában értesítünk.
                        </li>
                        <li>
                            Saját profil/Előzmények menüpontban az orvos által
                            küldött válasz, csatolmány megtekintése.{" "}
                        </li>
                    </ol>
                    <p style={{ marginTop: "3rem" }}>
                        Nem kaptál választ kérdésedre? Nézz körül a{" "}
                        <Link
                            to={i18nRoute("routes.faq.base")}
                            className={classes.link}
                        >
                            súgóközpontunkban!
                        </Link>
                    </p>
                    <h2 className={classes.greenText} ref={videoRef}>
                        Videókonzultáció
                    </h2>
                    <p>
                        A{" "}
                        <Link
                            to={i18nRoute("routes.services.videoConsultation")}
                            className={classes.link}
                        >
                            videókonzultáció
                        </Link>{" "}
                        az audiovizuális szolgáltatásaink közé tartozik. A
                        konzultáció során lehetőséged van látható és hallható
                        tüneteidet is megmutatni orvosunknak, aki így pontosabb
                        képet kaphat a probléma felderítéséhez.{" "}
                        <span className={classes.strong}>
                            Az orvosi videókonzultáció tervezhető,
                        </span>{" "}
                        hiszen mindig előre lefoglalt időpontban valósul meg.{" "}
                    </p>
                    <p>
                        Az orvos és/vagy szakterület kiválasztását követően a
                        foglalási rendszerben kiválaszthatod az elérhető
                        időpontok közül a számodra legideálisabbat. Egy
                        szakterületen belül több orvos kollégával is dolgozunk,
                        így ha nem találsz számodra ideális időpontot az
                        elsőnél, nézz körül a többi orvosunk időpontjai között
                        is.
                    </p>
                    <p>
                        A videókonzultációs időpontok több irányból is
                        elérhetők. Kereshetjük szakterület alapján, de
                        kiválaszthatjuk a számunkra legszimpatikusabb szakorvost
                        is. Szakterület szerinti választást követően
                        megjelenítjük az adott szakterületen elérhető összes
                        orvos időpontjait. Orvos oldalról érkezve az adott
                        szakorvos időpontjait láthatjuk.{" "}
                    </p>
                    <p>
                        Amint sikerült kiválasztanod a megfelelő vizsgálatot és
                        időpontot, úgy következő lépésben{" "}
                        <span className={classes.strong}>
                            megjegyzést írhatsz az orvosnak, illetve
                            dokumentumot is csatolhatsz a foglaláshoz
                        </span>{" "}
                        (abban az esetben, ha nyitott ennek lehetősége). Ha más
                        személy részére szeretnéd a konzultációt kérni, a „Nem
                        én vagyok a páciens, másnak foglalok” fület bepipálva
                        kitöltheted az ide vonatkozó adatokat. A foglalás
                        véglegesítése a Fizetéssel történik meg. Sikeres
                        tranzakció esetén (az alapértelmezett kártyád kerül
                        beterhelésre a kiválasztott vizsgálat összegével)
                        visszaléphetsz Saját fiókodba, ahol az előzmények
                        menüpont alatt, a Foglalt időpontokra szűrve{" "}
                        <span className={classes.strong}>
                            megtekintheted időpontfoglalásod részleteit.
                        </span>
                    </p>
                    <p className={classes.subheader}>
                        Lépésről-lépésre röviden:
                    </p>
                    <ol className={`${classes.blackText} ${classes.list}`}>
                        <li>
                            Regisztráció/bejelentkezés (kötelező adatok:
                            személyes és számlázási adatok, bankkártya adatok)
                        </li>
                        <li>
                            A/Szolgáltatás oldalon válassz szakterületet vagy
                            orvost. <br />
                            B/Szakterület oldalról érkezve válaszd ki a
                            videókonzultáció szolgáltatásunkat. <br />
                            C/Orvos oldalon videókonzultáció gombra történő
                            kattintás.
                        </li>
                        <li>Időpont kiválasztása.</li>
                        <li>
                            Megjegyzés/dokumentum feltöltése (ha engedélyezett).
                            Más személynek történő foglalás esetén adatok
                            kitöltése.
                        </li>
                        <li>Fizetés és ÁSZF/Adattájékoztató elfogadása.</li>
                    </ol>
                    <h2 className={classes.greenText} ref={outpatientRef}>
                        Időpontfoglalás járóbeteg ellátásra
                    </h2>
                    <p>
                        A{" "}
                        <Link
                            to={i18nRoute("routes.services.outPatient")}
                            className={classes.link}
                        >
                            járóbetegellátás
                        </Link>{" "}
                        időpontfoglalási rendszerünk a klasszikus értelemben
                        vett személyes orvosi vizitre történő időpontfoglalást
                        jelenti, melyet partnerintézményeink biztosítanak
                        számodra. Az időpontfoglalást indíthatod intézmény
                        kiválasztásával, orvos kiválasztásával és szakterület
                        kiválasztásával is. Ez csakis Tőled függ, hogy számodra
                        melyik a legideálisabb útvonal. Ha már kiválasztottad az
                        orvost és a hozzá tartozó vizsgálatot, akkor már csak az
                        időpont fixálása van hátra.
                    </p>
                    <p>
                        A járóbetegellátásra történő időpontfoglalás gyors és
                        egyszerű. A szakterület és vizsgálati típus
                        kiválasztását követően jelennek meg a szabad időpontok.
                        Amennyiben nincs szabad időpontot a választott orvoshoz,
                        nézd meg ugyan azon szakterületen dolgozó kollégáinak
                        szabad időpontjait is.{" "}
                        <span className={classes.strong}>
                            A foglalás véglegesítésével egyben a szolgáltatás
                            díja is kifizetésre kerül,
                        </span>{" "}
                        melyet a rendszer az alapértelmezett kártyára terhel be.
                        A sikeres tranzakció után a Saját fiókodon belül tudod
                        módosítani a foglalásaidat.
                    </p>
                    <p className={classes.subheader} ref={eReceptRef}>
                        Lépésről-lépésre röviden:
                    </p>
                    <ol className={`${classes.blackText} ${classes.list}`}>
                        <li>
                            Regisztráció/bejelentkezés (kötelező adatok:
                            személyes és számlázási adatok, bankkártya adatok)
                        </li>
                        <li>
                            A/Szolgáltatás oldalon válassz szakterületet vagy
                            orvost. <br />
                            B/Szakterület oldalról érkezve válaszd ki a
                            videókonzultáció szolgáltatásunkat. <br />
                            C/Orvos oldalon videókonzultáció gombra történő
                            kattintás.
                        </li>
                        <li>
                            Szakterület/Vizsgálat/Orvos/Helyszín kiválasztása
                        </li>
                        <li>Időpont kiválasztása.</li>
                        <li>
                            Összegző oldalon szükség esetén megjegyzés írása az
                            orvos számára.
                        </li>
                        <li>Időpont lefoglalása és fizetés. </li>
                        <li>
                            Partner intézményeink sok szeretettel várnak
                            szakrendelésükre.
                        </li>
                    </ol>
                    <p>
                        Rendszerünk a vizsgálat díját automatikusan beterheli
                        foglalás esetén. A bankkártyás fizetésről partner
                        intézményünk számlát küld ki e-mail címedre.
                    </p>

                    <h2
                        className={classes.greenText}
                        ref={eReceptRef}
                        id="erecept"
                    >
                        E-recept igénylés
                    </h2>
                    <p>
                        E-receptírás szolgáltatásunk azon páciensek számára
                        nyújt segítséget, akik otthonuk kényelméből szeretnének
                        olyan receptet felíratni, melyet rendszeresen, orvosi
                        javaslatra szednek. A felírt gyógyszerek esetében minden
                        esetben szükséges megelőző orvosi konzultáció, melyről a
                        páciensnek a dokumentációt a receptigénylés során
                        rendelkezésre kell bocsátania.{" "}
                    </p>
                    <p>
                        A receptírás feltételeiről az{" "}
                        <Link
                            to={i18nRoute("routes.services.prescription")}
                            className={classes.link}
                        >
                            e-recept
                        </Link>{" "}
                        szolgáltatásunk felületén olvashat bővebben.
                    </p>
                    <p className={classes.subheader}>
                        Lépésről-lépésre röviden:
                    </p>
                    <ol className={`${classes.blackText} ${classes.list}`}>
                        <li>
                            Regisztráció/bejelentkezés (kötelező adatok:
                            személyes és számlázási adatok, bankkártya adatok)
                        </li>
                        <li>
                            <Link
                                to={i18nRoute("routes.services.prescription")}
                            >
                                E-recept szolgáltatás
                            </Link>{" "}
                            oldalán kattints az üzenet küldése gombra.
                        </li>
                        <li>
                            Válaszd ki a szakterületet (ha nem tudod konkrétan,
                            milyen szakterületre van szükséged, válaszd az egyéb
                            kategóriát).
                        </li>
                        <li>
                            Írd meg nekünk, milyen gyógyszert szeretnél
                            igényelni.{" "}
                        </li>
                        <li>
                            Írd meg nekünk az indoklást, hogy mire hivatkozva
                            szeretnéd az adott gyógyszert felíratni.{" "}
                        </li>
                        <li>
                            Ambuláns lap, vizsgálati lap feltöltéséhez kattints
                            az Új dokumentum csatolása gombra.{" "}
                        </li>
                    </ol>
                    <p>
                        Kollégáink a beküldött e-recept igényt áttekintik és
                        ellenőrzik annak jogosultságát. Szükség esetén további
                        információkat, dokumentumokat kérhetnek be. Amennyiben a
                        receptigény nem felel meg a feltételeknek, úgy a
                        receptigénylés visszautasításra kerülhet.
                    </p>

                    <h2
                        className={classes.greenText}
                        ref={connectRef}
                        id="connect"
                    >
                        Eszközök csatlakoztatása
                    </h2>
                    <p>
                        A T-DOC alkalmazásához számos okos- és orvostechnikai
                        eszközt csatlakoztathatsz. A sikeresen csatlakoztatott
                        eszközök által mért (és alkalmazás számára
                        engedélyezett) adatok mérései elérhetővé válnak számodra
                        az Analízis központban. Ráadásul nem csak számodra, de
                        szakorvosod, valamint az általad meghatalmazott
                        személyek számára is elérhetővé válhatnak.
                    </p>
                    <p>
                        Eszközt csak abban az esetben tudsz párosítani, ha
                        rendelkezel valamely{" "}
                        <Link
                            to={i18nRoute("routes.subscriptions")}
                            className={classes.link}
                        >
                            adatalapú telemedicina előfizetésünkkel
                        </Link>
                        .
                    </p>
                    <p className={classes.subheader}>
                        Eszközök csatlakoztatása lépésről-lépésre röviden:
                    </p>

                    <ol className={`${classes.blackText} ${classes.list}`}>
                        <li>
                            Töltsd le a T-DOC mobil applikációt (
                            <a
                                href="https://play.google.com/store/apps/details?id=hu.tdoc.tdoc&hl=hu_HU"
                                target="_blank"
                                className={classes.link}
                                style={{
                                    color: "#11a2ed",
                                    textDecoration: "underline",
                                }}
                                rel="noreferrer"
                            >
                                Android
                            </a>
                            ,{" "}
                            <a
                                href="https://apps.apple.com/hu/app/t-doc/id1601837228?l=hu&utm_source=fb&utm_medium=cover&fbclid=IwAR3TOOzCLKlwRyjYJCm1ICkcu5WJMPnVJPkIMB1Xv5cBKe_2lIMrHoE782U"
                                target="_blank"
                                className={classes.link}
                                style={{
                                    color: "#11a2ed",
                                    textDecoration: "underline",
                                }}
                                rel="noreferrer"
                            >
                                iOS
                            </a>
                            )
                        </li>
                        <li>Regisztrálj/Jelentkezz be</li>
                        <li>
                            A kezdőoldalon kattints az Analízis központra /
                            Kattints a jobb felső oldalon található hamburger
                            menüre, majd válaszd ki az Analízis központ menüt,
                            majd az Eszközök menüpontot
                        </li>
                        <li>
                            Az összes hozzáadható felhőalapú, valamint
                            párosítható bluetooth eszközt az Összes eszköz
                            gombra történő kattintással éred el.
                        </li>
                        <li>Válaszd ki a csatlakoztatni kívánt eszközt.</li>
                        <li>
                            Kattints az Eszköz csatlakoztatása gombra.
                            <ol>
                                <li>
                                    Felhőalapú eszközök esetén, amennyiben
                                    elérhető az applikáció (pl. Apple Health) az
                                    eszközödön, úgy a rendszer automatikusan
                                    megkezdi a csatlakoztatást. A készüléked az
                                    adott felhőalapú eszköz által közvetíthető
                                    adatok listájához engedélyt fog kérni. Az
                                    engedély megadását követően a felhőalapú
                                    szolgáltatás sikeresen csatlakozik. Aktív
                                    kapcsolat esetén az adott felhőalapú eszköz
                                    mellett zöld pont jelenik meg.
                                </li>
                                <li>
                                    Bluetooth eszköz csatlakoztatása esetén
                                    először győződj meg arról, hogy a
                                    Bluetooth-kapcsolat engedélyezve van a
                                    telefonodon és a csatlakoztatni kívánt
                                    eszköz be van kapcsolva. Ezt követően az
                                    eszköz adatlapján nyomj az &quot;Eszköz
                                    csatlakoztatása&quot; gombra. Az applikáció
                                    eszköz típustól függően megkezdi a
                                    párosítást, vagy megjeleníti számodra a
                                    csatlakoztatási protokollt.
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <p>
                        Sikeres csatlakozás esetén az Analízis központon belül
                        néhány percen belül megjelennek a méréseid. Amennyiben
                        elakadnál, vagy további kérdésed merülne fel, kérjük
                        látogasd meg{" "}
                        <Link
                            to={i18nRoute("routes.faq.base")}
                            className={classes.link}
                        >
                            Súgóközpontunkat
                        </Link>
                        .
                    </p>

                    {!isAuthenticated && (
                        <Button
                            text="Regisztráció"
                            className={classes.button}
                            onClick={() => props.toggleRegistrationModal(true)}
                        />
                    )}
                </CardContainer>
            </div>
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleRegistrationModal: (show) =>
        dispatch(actions.toggleRegistrationModal(show)),
});

export default connect(null, mapDispatchToProps)(HowTo);
