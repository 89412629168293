import { Container, Grid } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Images } from "../../../assets/Theme";
import Breadcrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import { endLoading, post } from "../../../config/fetch";
// refacted layout UI elements to display Paperish design
import ScrollContainer from "../atoms/ScrollContainer";
import Paper from "../atoms/Paper";
import PaperSection from "../atoms/PaperSection";
import Headline from "../atoms/Headline";
import Tabs from "../molecules/Tabs";
import PageHeader from "../molecules/PageHeader";
import PageSubHeader from "../atoms/PageSubHeader";
import QualificationServiceCard from "../organisms/QualificationServiceCard";
import ListingCard from "../organisms/ListingCard";
import CustomSlider from "../molecules/CustomSlider";
import classes from "./Qualification.module.css";
import "../typo.css";

const Qualification = () => {
    const scrollRef = useRef();
    const params = useParams();
    const [data, setData] = useState({
        longName: "",
        subheader: "",
        name: "",
        image: Images.placeholder,
        description: "",
        medio_outpatient_id: null,
        medio_video_id: 1,
        id: null,
        chatAvailable: false,
        secondOpinionAvailable: false,
    });
    const [doctors, setDoctors] = useState([]);
    const [institutions, setInstitutions] = useState([]);

    useEffect(() => {
        let qualification = params.qualification;
        if (qualification) {
            post("api/v1/medical/get-qualification-data", {
                qualificationURL: qualification,
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            setData(response.data.data);
                            post(
                                "api/v1/medical/get-doctors-by-qualification",
                                { qualificationId: response.data.data.id },
                                false
                            )
                                .then((response) => {
                                    switch (response.data.responseCode) {
                                        case "OK":
                                            for (let d of response.data.data) {
                                                let qualifications = [];
                                                for (let q of d.qualifications) {
                                                    qualifications.push(q.name);
                                                }
                                                d.qualification =
                                                    qualifications.join(", ");
                                            }
                                            setDoctors(response.data.data);

                                            break;
                                    }
                                })
                                .catch((error) => console.error(error));
                            post(
                                "api/v1/medical/get-institutions-by-qualification",
                                { qualificationId: response.data.data.id },
                                false
                            )
                                .then((response) => {
                                    switch (response.data.responseCode) {
                                        case "OK":
                                            setInstitutions(response.data.data);

                                            break;
                                    }
                                })
                                .catch((error) => console.error(error));

                            break;
                        default:
                            break;
                    }
                    endLoading();
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                });
        }
    }, [params.qualification]);

    return (
        <>
            <div className={classes.intro}>
                <Container>
                    <Paper>
                        <Headline>
                            <Breadcrumbs
                                replaceKeys={{
                                    [params.qualification]: data.name,
                                }}
                            />
                        </Headline>
                        <Tabs
                            tabs={[
                                {
                                    label: "Szolgáltatások",
                                    scrollToId: "#services",
                                    isVisible: data.name !== "",
                                },
                                {
                                    label: "Orvosok",
                                    scrollToId: "#doctors",
                                    isVisible: doctors.length > 0,
                                },
                                {
                                    label: "Intézmények",
                                    scrollToId: "#institutions",
                                    isVisible: institutions.length > 0,
                                },
                                {
                                    label: "Leírás",
                                    scrollToId: "#description",
                                    isVisible: data.description,
                                },
                            ]}
                            scrollRef={scrollRef}
                        />
                        <ScrollContainer ref={scrollRef}>
                            {data.name !== "" && (
                                <PaperSection id="services">
                                    <PageHeader
                                        text={data.longName || data.name}
                                        className={classes.PageHeader}
                                        mainHeader
                                    />
                                    <PageSubHeader
                                        className={classes.PageHeader}
                                    >
                                        Válasszon az elérhető szolgáltatásaink
                                        közül
                                    </PageSubHeader>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={3}>
                                            <QualificationServiceCard
                                                type="chat"
                                                ids={{
                                                    qualificationId: data.id,
                                                    outpatientId:
                                                        data.medio_outpatient_id,
                                                    videoconsultationId:
                                                        data.medio_video_id,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <QualificationServiceCard
                                                type="second-opinion"
                                                ids={{
                                                    qualificationId: data.id,
                                                    outpatientId:
                                                        data.medio_outpatient_id,
                                                    videoconsultationId:
                                                        data.medio_video_id,
                                                }}
                                            />
                                        </Grid>

                                        {data.medio_video_id && (
                                            <Grid item xs={12} md={3}>
                                                <QualificationServiceCard
                                                    type="video-consultation"
                                                    ids={{
                                                        qualificationId:
                                                            data.id,
                                                        outpatientId:
                                                            data.medio_outpatient_id,
                                                        videoconsultationId:
                                                            data.medio_video_id,
                                                    }}
                                                />
                                            </Grid>
                                        )}

                                        {data.medio_outpatient_id && (
                                            <Grid item xs={12} md={3}>
                                                <QualificationServiceCard
                                                    type="outpatient"
                                                    ids={{
                                                        qualificationId:
                                                            data.id,
                                                        outpatientId:
                                                            data.medio_outpatient_id,
                                                        videoconsultationId:
                                                            data.medio_video_id,
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </PaperSection>
                            )}

                            {doctors && doctors.length > 0 && (
                                <PaperSection id="doctors">
                                    <PageSubHeader
                                        className={classes.PageHeader}
                                    >
                                        Orvosok - {data.name}
                                    </PageSubHeader>
                                    <CustomSlider people data={doctors} />
                                </PaperSection>
                            )}

                            {institutions && institutions.length > 0 && (
                                <PaperSection id="institutions">
                                    <PageSubHeader
                                        className={classes.PageHeader}
                                    >
                                        Intézmények - {data.name}
                                    </PageSubHeader>
                                    <CustomSlider
                                        data={institutions}
                                        renderFunction={(item) => (
                                            <ListingCard
                                                className={classes.ListingCard}
                                                type="institution"
                                                image={item.image}
                                                name={item.name}
                                                city={item.city}
                                                outPatient={
                                                    +item.out_patient_available
                                                }
                                                inPatient={
                                                    +item.in_patient_available
                                                }
                                            />
                                        )}
                                    />
                                </PaperSection>
                            )}

                            {data.description && (
                                <PaperSection id="description">
                                    <div
                                        className="typo-container"
                                        dangerouslySetInnerHTML={{
                                            __html: data.description,
                                        }}
                                    />
                                </PaperSection>
                            )}

                            {data.image && (
                                <PaperSection>
                                    <img
                                        src={data.image}
                                        className={classes.mainImage}
                                        alt=""
                                    />
                                </PaperSection>
                            )}
                        </ScrollContainer>
                    </Paper>
                </Container>
            </div>
        </>
    );
};

export default Qualification;
