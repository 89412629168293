// previously <Input type="taj" />
import cn from "classnames";
import Cleave from "cleave.js/react";
import classes from "./InputMask.module.css";

const InputMask = ({
    name,
    value,
    type,
    options,
    style,
    className,
    bigger,
    placeholder,
    required,
    disabled,
    onChange,
}) => {
    const handleChange = (e) => {
        onChange?.(e);
    };

    return (
        <Cleave
            name={name}
            value={value}
            type={type || "text"}
            options={options}
            className={cn(classes.input, className, {
                [classes.bigger]: bigger,
            })}
            style={style}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            onChange={handleChange}
        />
    );
};

export default InputMask;
