import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Grid,
    Hidden,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as actions from "../../../store/actions/index";
import { Icons } from "../../../assets/Theme";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./FooterInfo.module.css";

const FooterInfo = (props) => {
    const { i18nRoute } = useTranslation();

    return (
        <div className={classes.FooterInfo}>
            <Container>
                <Hidden smDown>
                    <Grid container spacing={4}>
                        <Grid item sm={3}>
                            <p className={classes.colHeader}>
                                Szolgáltatásaink
                            </p>
                            <Link
                                className={classes.link}
                                to={i18nRoute("routes.services.hotline")}
                            >
                                Egészség Hotline
                            </Link>
                            <Link
                                className={classes.link}
                                to={i18nRoute("routes.services.duty")}
                            >
                                Orvos hívása
                            </Link>
                            <Link
                                className={classes.link}
                                to={i18nRoute("routes.services.chat")}
                            >
                                Orvosi chat
                            </Link>
                            <Link
                                className={classes.link}
                                to={i18nRoute("routes.services.secondOpinion")}
                            >
                                Orvosi másodvélemény
                            </Link>
                            <Link
                                className={classes.link}
                                to={i18nRoute(
                                    "routes.services.videoConsultation"
                                )}
                            >
                                Videókonzultáció
                            </Link>
                            <Link
                                className={classes.link}
                                to={i18nRoute("routes.services.outPatient")}
                            >
                                Járóbeteg ellátás
                            </Link>
                            <Link
                                className={classes.link}
                                to={"/ugyeletkereso"}
                            >
                                Ügyeletkereső
                            </Link>
                        </Grid>
                        <Grid item sm={3}>
                            <p className={classes.colHeader}>Információk</p>
                            <Link
                                className={classes.link}
                                to={i18nRoute("routes.terms")}
                            >
                                ÁSZF
                            </Link>
                            <Link
                                className={classes.link}
                                to={"/adatvedelmi_nyilatkozat"}
                            >
                                Adatvédelem
                            </Link>
                            <Link className={classes.link} to={"/elofizetesek"}>
                                Előfizetési csomagok
                            </Link>
                            <Link className={classes.link} to={"/rolunk"}>
                                Rólunk
                            </Link>
                            <Link
                                className={classes.link}
                                to={i18nRoute("routes.faq.base")}
                            >
                                Súgóközpont
                            </Link>
                        </Grid>
                        <Grid item sm={3}>
                            <p className={classes.colHeader}>
                                Felhasználói fiók
                            </p>
                            <p
                                className={classes.link}
                                onClick={() => props.toggleLoginModal(true)}
                            >
                                Bejelentkezés
                            </p>
                            <p
                                className={classes.link}
                                onClick={() =>
                                    props.toggleRegistrationModal(true)
                                }
                            >
                                Regisztráció
                            </p>
                        </Grid>
                        <Grid item sm={3}>
                            <p className={classes.colHeader}>Social média</p>
                            <a
                                className={`${classes.link} ${classes.socialLink}`}
                                href="https://www.facebook.com/T-DOC-107254264459520"
                            >
                                <img
                                    src={Icons.facebook}
                                    className={classes.icon}
                                    alt=""
                                />
                                Facebook
                            </a>
                            <a
                                className={`${classes.link} ${classes.socialLink}`}
                                href="https://www.instagram.com/t_doc_hungary/ "
                            >
                                <img
                                    src={Icons.instagram}
                                    className={classes.icon}
                                    alt=""
                                />
                                Instagram
                            </a>
                            <a
                                className={`${classes.link} ${classes.socialLink}`}
                                href="https://www.linkedin.com/company/t-doc-service/"
                            >
                                <img
                                    src={Icons.linkedin}
                                    className={classes.icon}
                                    alt=""
                                />
                                LinkedIn
                            </a>
                            <a
                                className={`${classes.link} ${classes.socialLink}`}
                                href="https://www.youtube.com/channel/UC7Qbmz4QwapZmSJne6LRcWg/featured "
                            >
                                <img
                                    src={Icons.youtube}
                                    className={classes.icon}
                                    alt=""
                                />
                                YouTube
                            </a>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Accordion
                        square={true}
                        classes={{
                            root: classes.accordion,
                            expanded: classes.accordionExpanded,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon style={styles.chevron} />
                            }
                            classes={{
                                expanded: classes.accordionExpanded,
                                root: classes.accordionSummary,
                            }}
                        >
                            <div className={classes.accordionInner}>
                                Szolgáltatásaink
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.accordionMore}>
                                <Link
                                    className={classes.link}
                                    to={i18nRoute("routes.services.hotline")}
                                >
                                    Egészség Hotline
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={i18nRoute("routes.services.duty")}
                                >
                                    Orvos hívása
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={i18nRoute("routes.services.chat")}
                                >
                                    Orvosi chat
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={i18nRoute(
                                        "routes.services.secondOpinion"
                                    )}
                                >
                                    Orvosi másodvélemény
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={i18nRoute(
                                        "routes.services.videoConsultation"
                                    )}
                                >
                                    Videókonzultáció
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={i18nRoute("routes.services.outPatient")}
                                >
                                    Járóbeteg ellátás
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={"/ugyeletkereso"}
                                >
                                    Ügyeletkereső
                                </Link>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square={true}
                        classes={{
                            root: classes.accordion,
                            expanded: classes.accordionExpanded,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon style={styles.chevron} />
                            }
                            classes={{
                                expanded: classes.accordionExpanded,
                                root: classes.accordionSummary,
                            }}
                        >
                            <div className={classes.accordionInner}>
                                Információk
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.accordionMore}>
                                <Link
                                    className={classes.link}
                                    to={i18nRoute("routes.terms")}
                                >
                                    ÁSZF
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={"/adatvedelmi_nyilatkozat"}
                                >
                                    Adatvédelem
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={"/elofizetesek"}
                                >
                                    Előfizetési csomagok
                                </Link>
                                <Link className={classes.link} to={"/rolunk"}>
                                    Rólunk
                                </Link>
                                <Link
                                    className={classes.link}
                                    to={i18nRoute("routes.faq.base")}
                                >
                                    Súgóközpont
                                </Link>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square={true}
                        classes={{
                            root: classes.accordion,
                            expanded: classes.accordionExpanded,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon style={styles.chevron} />
                            }
                            classes={{
                                expanded: classes.accordionExpanded,
                                root: classes.accordionSummary,
                            }}
                        >
                            <div className={classes.accordionInner}>
                                Felhasználói fiók
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.accordionMore}>
                                <p
                                    className={classes.link}
                                    onClick={() => props.toggleLoginModal(true)}
                                >
                                    Bejelentkezés
                                </p>
                                <p
                                    className={classes.link}
                                    onClick={() =>
                                        props.toggleRegistrationModal(true)
                                    }
                                >
                                    Regisztráció
                                </p>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square={true}
                        classes={{
                            root: classes.accordion,
                            expanded: classes.accordionExpanded,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon style={styles.chevron} />
                            }
                            classes={{
                                expanded: classes.accordionExpanded,
                                root: classes.accordionSummary,
                            }}
                        >
                            <div className={classes.accordionInner}>
                                Social média
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.accordionMore}>
                                <a
                                    className={`${classes.link} ${classes.socialLink}`}
                                    href="https://www.facebook.com/T-DOC-107254264459520"
                                >
                                    <img
                                        src={Icons.facebook}
                                        className={classes.icon}
                                        alt=""
                                    />
                                    Facebook
                                </a>
                                <a
                                    className={`${classes.link} ${classes.socialLink}`}
                                    href="https://www.instagram.com/t_doc_hungary/ "
                                >
                                    <img
                                        src={Icons.instagram}
                                        className={classes.icon}
                                        alt=""
                                    />
                                    Instagram
                                </a>
                                <a
                                    className={`${classes.link} ${classes.socialLink}`}
                                    href="https://www.linkedin.com/company/t-doc-service/"
                                >
                                    <img
                                        src={Icons.linkedin}
                                        className={classes.icon}
                                        alt=""
                                    />
                                    LinkedIn
                                </a>
                                <a
                                    className={`${classes.link} ${classes.socialLink}`}
                                    href="https://www.youtube.com/channel/UC7Qbmz4QwapZmSJne6LRcWg/featured "
                                >
                                    <img
                                        src={Icons.youtube}
                                        className={classes.icon}
                                        alt=""
                                    />
                                    YouTube
                                </a>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </Hidden>
            </Container>
        </div>
    );
};

const styles = {
    chevron: {
        width: "2.5rem",
        height: "2.5rem",
        color: "#fff",
    },
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
    toggleRegistrationModal: (show) =>
        dispatch(actions.toggleRegistrationModal(show)),
});

export default connect(null, mapDispatchToProps)(FooterInfo);
