import EventRowAlert from "./EventRowAlert";
import EventRowDefault from "./EventRowDefault";
import EventRowDoctor from "./EventRowDoctor";
import {
    APPOINTMENT_FLAGS,
    EVENT_FLAGS,
    SYSTEM_FLAGS,
} from "../../../config/variables";
import EventRowAppointment from "./EventRowAppointment";

const EventRowRenderer = ({ event }) => {
    const payload = event?.payload && JSON.parse(event.payload);
    const alert = payload?.type === "nextHealth" && payload.alertLevel;

    if (alert) {
        return <EventRowAlert {...payload} />;
    }

    if ([...SYSTEM_FLAGS].includes(event?.event_name)) {
        return <EventRowDefault {...event} />;
    }

    if ([...EVENT_FLAGS].includes(event?.event_name)) {
        return <EventRowDoctor {...event} />;
    }

    if ([...APPOINTMENT_FLAGS].includes(event?.event_name)) {
        return <EventRowAppointment {...event} />;
    }

    return null;
};

export default EventRowRenderer;
