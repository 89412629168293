import { Link } from "react-router-dom";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./classes.module.css";

const Services = () => {
    const { i18nRoute } = useTranslation();

    return (
        <>
            <h3 className={classes.title} id="szolgaltatas-igenybevetele">
                Nem tudom igénybevenni a szolgáltatásokat. Miért?
            </h3>
            <p className={classes.text}>
                Szolgáltatásainkat csak regisztrált felhasználók tudják
                igénybevenni. Regisztrációt követően ki kell tölteni a
                számlázási adatokat, valamint meg kell adni a bankkártya
                adatokat is. Ezt követően a szolgáltatásokat igénybe tudod
                venni. Minden szolgáltatásnak egyedi a folyamata, melyekről{" "}
                <Link className={classes.link} to={i18nRoute("routes.howTo")}>
                    ide kattintva olvashatsz.
                </Link>
            </p>
            <h3 className={classes.title} id="sms-hotline">
                Nem kaptam meg az sms-t a hotline hívás indítását követően.
            </h3>
            <p className={classes.text}>
                Ha nem kaptad meg a hívásindításhoz szükséges SMS-t, kérd az
                sms-es újraküldését. A telefonszám megjelenik a fizetést
                követően, így azt tárcsázni tudod telefonodról.
            </p>
            <h3 className={classes.title} id="sms-ugyelet">
                Nem kaptam meg az sms-t az orvosi ügyelet hívás indítást
                követően.
            </h3>
            <p className={classes.text}>
                Ha nem kaptad meg a hívásindításhoz szükséges SMS-t, kérd az
                sms-es újraküldését. A telefonszám megjelenik a fizetést
                követően, így azt tárcsázni tudod telefonodról.
            </p>
            <h3 className={classes.title} id="uzenetek-elerese">
                Hol érem el az orvostól kapott üzeneteimet?
            </h3>
            <p className={classes.text}>
                Az orvostól kapott üzeneteket a saját fiókodban, az előzmények
                pontban találod meg.{" "}
            </p>
        </>
    );
};

export default Services;
