import Card from "../Card/Card";
import Btn from "../Btn/Btn";
import SubscriptionSelect from "../SubscriptionSelect/SubscriptionSelect";
import { Icons } from "../../../assets/Theme";
import classes from "./ServicePage.module.css";

const ServicePage = (props) => {
    const buttonCallback = () => {
        if (!props.buttonDisabled && props.buttonCallback) {
            props.buttonCallback();
        }
    };

    if (window.innerWidth > 599) {
        return (
            <Card className={classes.ServicePage} style={props.style}>
                <div className={classes.header}>
                    <div className={classes.headerInfoCol}>
                        <img src={props.icon} className={classes.icon} alt="" />
                        <p className={classes.name}>{props.name}</p>
                        <p className={classes.description}>
                            {props.description}
                        </p>
                    </div>
                    <img
                        src={props.image}
                        className={classes.image}
                        alt={props.imageAlt}
                    />
                </div>
                <div className={classes.subscriptionSelectContainer}>
                    {props.packages &&
                        props.packages.map((item, index) => (
                            <SubscriptionSelect
                                selected={index + 1 === props.selectedPackage}
                                data={item}
                                cancellable={
                                    props.selectedPackage === index + 1 &&
                                    props.selectedPackage !=
                                        props.originalSubscription
                                }
                                packageResetClick={props.packageResetClick}
                                packageClick={() =>
                                    props.packageClick(index + 1)
                                }
                                hoverable={
                                    index + 1 >= props.originalSubscription
                                }
                                asterisk={
                                    props.asterisks && props.asterisks[index]
                                }
                                key={item.name}
                            />
                        ))}
                </div>
                {props.packageInfoText && (
                    <p className={classes.packageInfoText}>
                        {props.packageInfoText}
                    </p>
                )}
                {props.packageInfo2Text && (
                    <p
                        className={classes.packageInfoText}
                        style={{ marginTop: "-2rem" }}
                    >
                        {props.packageInfo2Text}
                    </p>
                )}
                {props.showImportantInfo && (
                    <div className={classes.importantInfoContainer}>
                        <img
                            src={props.importantInfoIcon}
                            className={classes.importantInfoIcon}
                            alt=""
                        />
                        <p className={classes.importantInfoText}>
                            {props.importantInfoText}
                        </p>
                    </div>
                )}
                {props.showImportantInfo2 && (
                    <div className={classes.importantInfoContainer}>
                        <img
                            src={Icons.phoneMessageBlack}
                            className={classes.importantInfoIcon2}
                            alt=""
                        />
                        <p className={classes.importantInfoText}>
                            FIGYELEM! Ez a hívás csak telefonról indítható,
                            ezért a tárcsázáshoz szükséges telenfonszámot
                            rendszerünk a készülékedre fogja elküldeni.{" "}
                        </p>
                    </div>
                )}
                {props.noButton ? null : (
                    <Btn
                        className={`${classes.button} ${
                            props.buttonDisabled && classes.disabled
                        }`}
                        onClick={buttonCallback}
                    >
                        {props.buttonText}
                    </Btn>
                )}
                <div
                    className={classes.content}
                    style={props.contentContainerStyle}
                >
                    {props.children}
                </div>
            </Card>
        );
    }

    return (
        <div className={classes.ServicePage} style={props.style}>
            <div className={classes.header}>
                <div className={classes.headerInfoCol}>
                    <img src={props.icon} className={classes.icon} alt="" />
                    <p className={classes.name}>{props.name}</p>
                    <p className={classes.description}>{props.description}</p>
                </div>
                <img
                    src={props.image}
                    className={classes.image}
                    alt={props.imageAlt}
                />
            </div>
            <div className={classes.subscriptionSelectContainer}>
                {props.packages &&
                    props.packages.map((item, index) => (
                        <SubscriptionSelect
                            selected={index + 1 === props.selectedPackage}
                            data={item}
                            cancellable={
                                props.selectedPackage === index + 1 &&
                                props.selectedPackage !=
                                    props.originalSubscription
                            }
                            packageResetClick={props.packageResetClick}
                            packageClick={() => props.packageClick(index + 1)}
                            hoverable={index + 1 >= props.originalSubscription}
                            key={item.name}
                        />
                    ))}
            </div>
            {props.showImportantInfo && (
                <div className={classes.importantInfoContainer}>
                    <img
                        src={props.importantInfoIcon}
                        className={classes.importantInfoIcon}
                        alt=""
                    />
                    <p className={classes.importantInfoText}>
                        {props.importantInfoText}
                    </p>
                </div>
            )}
            {props.noButton ? null : (
                <Btn
                    className={`${classes.button} ${
                        props.buttonDisabled && classes.disabled
                    }`}
                    onClick={buttonCallback}
                >
                    {props.buttonText}
                </Btn>
            )}
            <div
                className={classes.content}
                style={props.contentContainerStyle}
            >
                {props.children}
            </div>
        </div>
    );
};

export default ServicePage;
