import classes from "./LoginModal.module.css";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getEnv } from "tdoc-common";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "../../../features/auth/slice";
import { useDispatch } from "../../../store";
import useTranslation from "../../../features/i18n/useTranslation";
import { ALERT_MESSAGES } from "../../../config/variables";
import { responseMessage } from "../../../utility";
import Modal from "../../UI/Modal/Modal";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import { Images } from "../../../assets/Theme";
import SocialButton from "../../UI/SocialButton/SocialButton";
import * as actions from "../../../store/actions/index";
import { endLoading, post, startLoading } from "../../../config/fetch";

// TODO: bejelentkezési modal után hova irányítsuk a usert
const LoginModal = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [open, toggleOpen] = useState(props.open);
    const [userDatas, setUserDatas] = useState({});
    const { i18nRoute } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        toggleOpen(props.open);
    }, [props.open]);

    const loginHandler = () =>
        post("auth/login", {
            email: userDatas.email,
            password: userDatas.password,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        props.setUserPhone(response.data.phone);
                        close();
                        props.toggleVerificationModal(true);

                        break;
                    case "MISSING_EMAIL_OR_PASSWORD":
                        responseMessage(
                            "Nem adott meg email címet vagy jelszót!"
                        );

                        break;
                    case "EMAIL_OR_PASSWORD_DONT_MATCH":
                        responseMessage(
                            "Nem létező emailcím - jelszó párosítás!"
                        );

                        break;
                    case "EMAIL_NOT_VERIFIED":
                        responseMessage(
                            "A bejelentkezéshez erősítsd meg az email címed! Az ehhez szükséges linket a regisztráció befejezésekor küldött emailünkben találod."
                        );

                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const facebookCallback = (response) => {
        endLoading();
        post("auth/facebook-login", { facebookId: response.id })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        dispatch(
                            login({
                                userId: response.data.id,
                                token: response.data.token,
                            })
                        );
                        close();
                        if (
                            location.pathname.startsWith(
                                i18nRoute("routes.services.base")
                            )
                        ) {
                            let currentPage = location.pathname;
                            navigate({ pathname: "/empty" });
                            navigate(
                                { pathname: currentPage },
                                { replace: true }
                            );
                        } else {
                            navigate(
                                i18nRoute("routes.profile.base", {
                                    routeHash: `?firstLogin=${response.data.firstLogin}`,
                                })
                            );
                        }

                        break;
                    case "USER_NOT_FOUND":
                        responseMessage(
                            "Nem található felhasználó az alábbi Facebook fiókkal! Kérjük, először regisztráljon!"
                        );

                        break;
                    case "EMPTY_ID":
                    case "SYSTEM_ERROR":
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const googleCallBack = (response) => {
        startLoading();
        post("auth/google-login", { googleId: response.tokenId })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        dispatch(
                            login({
                                userId: response.data.id,
                                token: response.data.token,
                            })
                        );
                        close();

                        if (
                            location.pathname.startsWith(
                                i18nRoute("routes.services.base")
                            )
                        ) {
                            let currentPage = location.pathname;
                            navigate({ pathname: "/empty" });
                            navigate(
                                { pathname: currentPage },
                                { replace: true }
                            );
                        } else {
                            navigate(
                                i18nRoute("routes.profile.base", {
                                    routeHash: `?firstLogin=${response.data.firstLogin}`,
                                })
                            );
                        }

                        break;
                    case "USER_NOT_FOUND":
                        responseMessage(
                            "Nem található felhasználó az alábbi Google fiókkal! Kérjük, először regisztráljon!"
                        );

                        break;
                    case "EMPTY_ID":
                    case "SYSTEM_ERROR":
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const forgotPasswordHandler = () =>
        post("auth/forgot-password", { email: userDatas.forgotEmail })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage(
                            "A megadott emal címre kiküldtünk egy emailt a további teendőkkel!"
                        );

                        break;
                    case "NO_EMAIL_FOUND":
                        responseMessage("Nem található ilyen email cím!");

                        break;
                    case "EMPTY_OR_WRONG_EMAIL":
                        responseMessage("Hibás vagy hiányzó email cím!");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const slider = useRef(null);

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        swipe: false,
    };

    const onRegistrationClick = () => {
        props.toggleLoginModal(false);
        props.toggleRegistrationModal(true);
    };

    const close = () => {
        if (props.closeModal) {
            props.closeModal();
        } else {
            props.toggleLoginModal(false);
        }
    };

    return (
        <Modal open={open} key={open} closeModal={close}>
            <div className={classes.LoginModal}>
                <Slider {...sliderSettings} ref={slider}>
                    <div className={classes.LoginModalInner}>
                        <div className={classes.headerRow}>
                            <div className={classes.line}></div>
                            <span className={classes.header}>
                                Bejelentkezés
                            </span>
                            <div className={classes.line}></div>
                        </div>
                        <div className={classes.inputContainer}>
                            <p className={classes.label}>Email cím</p>
                            <Input
                                bigger
                                className={classes.input}
                                onChange={(e) =>
                                    setUserDatas({
                                        ...userDatas,
                                        email: e.target.value,
                                    })
                                }
                                type="email"
                            />
                        </div>
                        <div className={classes.inputContainer}>
                            <p className={classes.label}>Jelszó</p>
                            <Input
                                bigger
                                type="password"
                                className={classes.input}
                                onChange={(e) =>
                                    setUserDatas({
                                        ...userDatas,
                                        password: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <Button
                            onClick={() => loginHandler()}
                            className={classes.button}
                            rightIcon={Images.arrow}
                        >
                            Belépés
                        </Button>
                        <div
                            className={`${classes.headerRow} ${classes.smaller}`}
                        >
                            <div className={classes.line}></div>
                            <span className={classes.header}>
                                Bejelentkezés más fiókkal
                            </span>
                            <div className={classes.line}></div>
                        </div>
                        <GoogleLogin
                            clientId={getEnv("GOOGLE_CLIENT_ID")}
                            onSuccess={(response) => googleCallBack(response)}
                            onFailure={(response) => console.error(response)}
                            render={(renderProps) => (
                                <SocialButton
                                    onClick={renderProps.onClick}
                                    text="Google fiókkal"
                                    image={Images.google}
                                    style={styles.socialButton}
                                />
                            )}
                        />
                        <FacebookLogin
                            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                            autoLoad={false}
                            fields="name,email,picture"
                            onClick={() => startLoading()}
                            callback={(response) => facebookCallback(response)}
                            render={(renderProps) => (
                                <SocialButton
                                    onClick={renderProps.onClick}
                                    text="Facebook fiókkal"
                                    image={Images.facebook}
                                    style={styles.socialButton}
                                />
                            )}
                        />
                        <div className={classes.linkRow}>
                            <span
                                className={classes.link}
                                onClick={onRegistrationClick}
                            >
                                Új fiók létrehozása
                            </span>
                        </div>
                        <div className={classes.linkRow}>
                            <span
                                className={classes.link}
                                onClick={() => slider.current.slickNext()}
                            >
                                Nem tud bejelentkezni?
                            </span>
                        </div>
                    </div>
                    <div className={classes.LoginModalInner}>
                        <div className={classes.headerRow}>
                            <div className={classes.line}></div>
                            <span className={classes.header}>
                                Elfelejtette jelszavát?
                            </span>
                            <div className={classes.line}></div>
                        </div>
                        <p className={classes.infoText}>
                            Kérjük, adja meg regisztrált email címét! A megadott
                            címre a rendszer kiküld egy linket, mellyel meg
                            tudja változtatni a jelszavát.
                        </p>
                        <div className={classes.inputContainer}>
                            <p className={classes.label}>Emal cím</p>
                            <Input
                                bigger
                                className={classes.input}
                                onChange={(e) =>
                                    setUserDatas({
                                        ...userDatas,
                                        forgotEmail: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <Button
                            onClick={() => forgotPasswordHandler()}
                            style={{ marginTop: "4rem" }}
                            className={classes.button}
                            rightIcon={Images.arrow}
                        >
                            Küldés
                        </Button>
                    </div>
                </Slider>
            </div>
        </Modal>
    );
};

const styles = {
    socialButton: {
        marginTop: "2rem",
    },
};

const mapDispatchToProps = (dispatch) => ({
    toggleVerificationModal: (show) =>
        dispatch(actions.toggleVerificationModal(show)),
    setUserPhone: (phone) => dispatch(actions.setUserPhone(phone)),
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
    toggleRegistrationModal: (show) =>
        dispatch(actions.toggleRegistrationModal(show)),
});

export default connect(null, mapDispatchToProps)(LoginModal);
