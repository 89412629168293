import { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import CardContainer from "../../../components/UI/CardContainer/CardContainer";
import Line from "../../../components/UI/Line/Line";
import Input from "../../../components/UI/Input/Input";
import SaveButton from "../../../components/UI/SaveButton/SaveButton";
import { Icons } from "../../../assets/Theme";
import { endLoading, get, post } from "../../../config/fetch";
import { convertDate, responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import classes from "./Security.module.css";
import DeviceRow from "./DeviceRow/DeviceRow";

const Security = () => {
    const [devices, setDevices] = useState(null);
    const [passwordModifiedAt, setPasswordModifiedAt] = useState(null);
    const [passwordEditing, setPasswordEditing] = useState(false);
    const [passwords, setPasswords] = useState({
        oldPassword: null,
        password1: null,
        password2: null,
    });

    useEffect(() => {
        get("user/get-password-and-devices").then((response) => {
            switch (response.data.responseCode) {
                case "OK":
                    setDevices(response.data.devices);
                    setPasswordModifiedAt(response.data.passwordModified);

                    break;
                default:
                    break;
            }
            endLoading();
        });
    }, []);

    const savePassWordHandler = () => {
        if (passwords.password1 === null || passwords.password2 === null) {
            return false;
        }
        if (passwords.password1.length < 6 || passwords.password2.length < 6) {
            responseMessage(
                "A jelszónak legalább 6 karakter hosszúságúnak kell lennie!"
            );

            return false;
        }
        if (!passwords.oldPassword) {
            responseMessage("A régi jelszó megadása kötelező!");

            return false;
        }
        if (
            passwords.password1 &&
            passwords.password2 &&
            passwords.password1 === passwords.password2
        ) {
            post("auth/change-user-password", {
                password: passwords.password1,
                oldPassword: passwords.oldPassword,
            })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            setPasswordModifiedAt(convertDate(new Date(), "."));
                            setPasswordEditing(false);

                            break;
                        case "EMPTY_PASSWORD":
                            responseMessage("A jelszó nem maradhat üresen!");

                            break;
                        case "MISSING_OLD_PASSWORD":
                            responseMessage(
                                "A régi jelszó nem maradhat üresen!"
                            );

                            break;
                        case "INVALID_OLD_PASSWORD":
                            responseMessage("A régi jelszó helytelen!");

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        } else {
            responseMessage("A jelszavak nem egyeznek meg!");
        }
    };

    const handleDeviceLogout = (id) =>
        post("user/logout-device", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres kijelentkezés!");
                        const modifiedDevices = devices.filter(
                            (d) => d.id != id
                        );
                        setDevices(modifiedDevices);

                        break;
                    }
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const passwordInput = (
        <>
            <Grid container>
                <Grid item>
                    <p className={classes.label}>Régi jelszó</p>
                    <Input
                        type="passwordRegister"
                        style={styles.passwordInput}
                        onChange={(e) =>
                            setPasswords({ ...passwords, oldPassword: e })
                        }
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="flex-end">
                <Grid item>
                    <p className={classes.label}>Új jelszó</p>
                    <Input
                        type="passwordRegister"
                        style={styles.passwordInput}
                        onChange={(e) =>
                            setPasswords({ ...passwords, password1: e })
                        }
                    />
                </Grid>
                <Grid item>
                    <p className={classes.label}>Új jelszó mégegyszer</p>
                    <Input
                        type="passwordRegister"
                        style={styles.passwordInput}
                        onChange={(e) =>
                            setPasswords({ ...passwords, password2: e })
                        }
                    />
                </Grid>
            </Grid>
        </>
    );

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs />
                <CardContainer className={classes.card}>
                    <Grid container>
                        <Grid sm={2} xs={12} item>
                            <img
                                src={Icons.safetyGreen}
                                className={classes.mainIcon}
                                alt=""
                            />
                        </Grid>
                        <Grid sm={8} xs={12} item>
                            <h2 className={classes.header}>
                                Bejelentkezés és biztonság
                            </h2>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item sm={2} xs={12}></Grid>
                        <Grid item sm={8} xs={12}>
                            {devices && passwordModifiedAt ? (
                                <>
                                    <div>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <p className={classes.title}>
                                                    Jelszó
                                                </p>
                                            </Grid>
                                            <Grid item>
                                                <p
                                                    className={classes.text}
                                                    onClick={() =>
                                                        setPasswordEditing(true)
                                                    }
                                                >
                                                    Frissítés
                                                </p>
                                            </Grid>
                                        </Grid>
                                        {!passwordEditing ? (
                                            <p className={classes.cardText}>
                                                Utolsó frissítés:{" "}
                                                {passwordModifiedAt
                                                    .substr(0, 10)
                                                    .replace(/-/g, ".")}
                                            </p>
                                        ) : (
                                            <div style={{ fontSize: "1.6rem" }}>
                                                {passwordInput}
                                                <SaveButton
                                                    withCancel
                                                    saveHandler={
                                                        savePassWordHandler
                                                    }
                                                    cancelHandler={() =>
                                                        setPasswordEditing(
                                                            false
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <Line style={{ marginTop: "4rem" }} />
                                    <p className={classes.title}>
                                        Bejelentkezett eszközök
                                    </p>
                                    {devices.map((d) => (
                                        <DeviceRow
                                            key={d.id}
                                            icon={
                                                d.device_type === "Desktop"
                                                    ? Icons.desktopBlack
                                                    : Icons.mobileBlack
                                            }
                                            platform={d.platform}
                                            browser={d.browser}
                                            date={d.created_at.replace(
                                                /-/g,
                                                "."
                                            )}
                                            onClick={() =>
                                                handleDeviceLogout(d.id)
                                            }
                                        />
                                    ))}
                                    <Line style={{ marginTop: "4rem" }} />
                                </>
                            ) : (
                                <Skeleton variant="rect" height={800} />
                            )}
                        </Grid>
                    </Grid>
                </CardContainer>
            </div>
        </Container>
    );
};

const styles = {
    passwordInput: {
        marginBottom: "2rem",
        width: "30rem",
        maxWidth: "100%",
    },
};

export default Security;
