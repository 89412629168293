import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Switch from "../molecules/Form/Switch";
import { endLoading, get, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import classes from "./NotificationSettings.module.css";

const NotificationSettings = () => {
    const [settings, setSettings] = useState({
        newsletter: null,
        inAppNotifications: null,
        emailNotifications: null,
    });

    useEffect(() => {
        get("user/get-profile-summary")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setSettings({
                            newsletter: response.data.user.newsletter,
                            inAppNotifications:
                                response.data.user.inAppNotifications,
                            emailNotifications:
                                response.data.user.emailNotifications,
                        });

                        break;
                    default:
                        break;
                }
                endLoading();
            })
            .catch((error) => console.error(error));
    }, []);

    const changeNotificationSettings = (value, type) => {
        let data = { ...settings };
        switch (type) {
            case "email":
                data = { ...settings, emailNotifications: value };
                setSettings({ ...settings, emailNotifications: value });

                break;
            case "newsletter":
                data = { ...settings, newsletter: value };
                setSettings({ ...settings, newsletter: value });

                break;
            case "inApp":
                data = { ...settings, inAppNotifications: value };
                setSettings({ ...settings, inAppNotifications: value });

                break;
            default:
                break;
        }
        saveNotificationsHandler(data);
    };

    const saveNotificationsHandler = (data) =>
        post("user/update-user-notifications", { data })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <Grid
            container
            direction="column"
            spacing={5}
            className={classes.wrapper}
        >
            {settings.inAppNotifications && (
                <Grid container item xs={12}>
                    <Grid item xs={12} md={8}>
                        <p>Applikáció értesítései</p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Switch
                            value={settings.inAppNotifications}
                            onChange={(value) =>
                                changeNotificationSettings(value, "inApp")
                            }
                        />
                    </Grid>
                </Grid>
            )}

            {settings.emailNotifications !== null && (
                <Grid container item xs={12}>
                    <Grid item xs={12} md={8}>
                        <p>Email értesítések</p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Switch
                            value={settings.emailNotifications}
                            onChange={(value) =>
                                changeNotificationSettings(value, "email")
                            }
                        />
                    </Grid>
                </Grid>
            )}

            {settings.newsletter !== null && (
                <Grid container item xs={12}>
                    <Grid item xs={12} md={8}>
                        <p>
                            Szolgáltatással kapcsolatos hírek és egyedi
                            ajánaltok
                        </p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Switch
                            value={settings.newsletter}
                            onChange={(value) =>
                                changeNotificationSettings(value, "newsletter")
                            }
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default NotificationSettings;
