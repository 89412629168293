import { Grid } from "@material-ui/core";
import {
    dispatchPrescriptionRedeemStatusColor,
    dispatchPrescriptionRedeemStatusText,
    formatDateWithYear,
} from "../../../../utility";

const SimpleRedeemRow = ({ redeem, onRowClicked }) => (
    <Grid
        container
        style={{
            border: "1px solid black",
            width: "95%",
            height: "40px",
            marginBottom: "-1px",
            cursor: "pointer",
        }}
        onClick={onRowClicked}
    >
        <Grid
            item
            xs={12}
            md={4}
            style={{
                margin: "auto",
                paddingLeft: "5px",
                paddingTop: "8px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                height: "40px",
                width: "100%",
            }}
        >
            {redeem.prescriptionRedeemProducts.length > 0
                ? redeem.prescriptionRedeemProducts
                      .map((product) => product.name)
                      .join(", ")
                : "Nincs termék"}
        </Grid>
        <Grid
            item
            xs={12}
            md={4}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {formatDateWithYear(redeem.createdAt)}
        </Grid>
        <Grid
            item
            xs={12}
            md={4}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dispatchPrescriptionRedeemStatusColor(redeem.status),
                fontWeight: "bold",
            }}
        >
            {dispatchPrescriptionRedeemStatusText(redeem.status)}
        </Grid>
    </Grid>
);
export default SimpleRedeemRow;
