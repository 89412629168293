import classes from "./classes.module.css";

const Techonology = () => (
    <>
        <h3 className={classes.title} id="bongeszok">
            Milyen böngészővel tudom használni a T-DOC honlapját?{" "}
        </h3>
        <p className={classes.text}>
            Google Chrome, Mozilla Firefox, Safari, Opera
        </p>
    </>
);

export default Techonology;
