import { Link } from "react-router-dom";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./classes.module.css";

const Payments = () => {
    const { i18nRoute } = useTranslation();

    return (
        <>
            <h3 className={classes.title} id="ceges-szamla">
                Tudok céges számlát is kérni?
            </h3>
            <p className={classes.text}>
                Igen, a saját fiókodon belül meg tudod adni a számlázási
                adatokat, ahol kitölthető a cég neve is.
            </p>
            <h3 className={classes.title} id="kartya-hozzaadas">
                Hogy tudom hozzáadni a bankkártyám?
            </h3>
            <p className={classes.text}>
                A bankkártyádat a regisztrációt, és regisztráció megerősítését
                követően a saját fiókodban, a „Fizetési módok” menüpontban tudod
                biztonságosan hozzáadni.
            </p>
            <h3 className={classes.title} id="kartya-modositas">
                Hol tudom megváltoztatni a kártya adataim?
            </h3>
            <p className={classes.text}>
                Regisztrációt követően a saját fiókjában bejelentkezve, a{" "}
                <Link
                    className={classes.link}
                    to={i18nRoute("routes.profile.payments", {
                        defaultValue: "/profile/payments",
                    })}
                >
                    Fizetési módok
                </Link>{" "}
                menüponton belül tudod módosítani bankkártyád adatait.{" "}
            </p>
            <h3 className={classes.title} id="tobb-kartya">
                Hozzá tudok adni több bankkártyát is a fiókomhoz?
            </h3>
            <p className={classes.text}>
                Igen, több bankkártya is hozzáadható. A kártyákat külön el is
                tudod nevezni, valamint kiválaszthatod, hogy melyik legyen az
                alapérelmezett kártyád is.Ezt bármikor meg tudod változatni.
            </p>
            <h3 className={classes.title} id="elofizetes-levonas">
                Mikor kerül levonásra az előfizetés díja a kártyámról?
            </h3>
            <p className={classes.text}>
                Az előfizetés díja 3 nappal az időszakforduló előtt kerül
                levonásra. Ezzel szeretnénk azt biztosítani, hogy
                szolgáltatásaink mindig elérhetőek legyenek számodra.{" "}
            </p>
            <h3 className={classes.title} id="elofizetes-lemondas">
                Hogyan tudom lemondani az előfizetésemet?
            </h3>
            <p className={classes.text}>
                Az előfizetésed bármikor le tudod mondani a saját fiókodba
                bejelentkezve, az{" "}
                <Link
                    className={classes.link}
                    to={i18nRoute("routes.profile.subscriptions")}
                >
                    Előfizetések
                </Link>{" "}
                menüpontban. Az előfizetésed lemondásával a kifizetett hónap
                szolgáltatásai természetesen aktívak maradnak az időszak végéig.
            </p>
        </>
    );
};

export default Payments;
