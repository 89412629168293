import { ShoppingCart as ShoppingCartIcon } from "@carbon/icons-react";
import { endLoading, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import classes from "./WebshopToolbar.module.css";

const WebshopCart = ({ cart = [] }) => {
    const redirectToPiqCart = () => {
        post("api/v1/user/create-one-time-token")
            .then((res) => {
                switch (res.data.responseCode) {
                    case "OK":
                        window.open(
                            `https://patika.tritonlife.hu/kosar-megtekintes/?oneTimeToken=${res.data.token}`,
                            "_blank"
                        );
                        endLoading();

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className={classes.info}>
            <div className={classes.icon}>
                {cart.items.length > 0 && (
                    <span className={classes.productCounter}>
                        {cart.items.length}
                    </span>
                )}
                <ShoppingCartIcon size="32" />
            </div>
            <div>
                <h4 className={classes.headline}>Kosaram</h4>
                <p className={classes.text}>{cart.items.length} termék</p>
                <p className={classes.text}>Összesen: {cart.total} Ft</p>
                <span
                    className={classes.link}
                    rel="noreferrer"
                    onClick={() => {
                        redirectToPiqCart();
                    }}
                >
                    Részletek
                </span>
            </div>
        </div>
    );
};

export default WebshopCart;
