import { Box, Container, List, ListItem } from "@material-ui/core";
import { useResolution } from "../../../ui/utils";
import PageHeader from "../../../ui/layout/PageHeader/index";
import PipeIcon from "../assets/Pipe.svg";
import { benefitList } from "../content/benefitListSection";

const BenefitListSection = () => {
    const isTablet = useResolution("tablet");

    return (
        <section
            style={{
                backgroundImage: "linear-gradient(106deg, #41B490, #0086BB)",
                color: "white",
            }}
        >
            <Box pt={2}>
                {isTablet ? (
                    <PageHeader text="Miért érdemes chatet választani?" white />
                ) : (
                    <Container>
                        <PageHeader
                            text="Miért érdemes chatet választani?"
                            white
                        />
                    </Container>
                )}
            </Box>
            <Container>
                <Box
                    pb={6}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    color="white"
                >
                    <Box
                        width={isTablet ? "100%" : "51%"}
                        mr={isTablet ? 0 : 8}
                    >
                        <List>
                            {benefitList.map((listItem, index) => (
                                <ListItem key={index} disableGutters>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        py={1}
                                    >
                                        <Box pt={1} pr={2}>
                                            <img
                                                src={PipeIcon}
                                                alt="PipeIcon"
                                            />
                                        </Box>
                                        <Box>
                                            <h6>{listItem}</h6>
                                        </Box>
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Container>
        </section>
    );
};

export default BenefitListSection;
