import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { endLoading, get, post } from "../../../config/fetch";
import { convertDate, responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import PageSubHeader from "../atoms/PageSubHeader";
import Input from "../molecules/Form/Input";
import SecurityNumberInput from "../molecules/Form/SecurityNumberInput";
import DateInput from "../molecules/Form/DateInput";
import PhoneInput from "../molecules/Form/PhoneInput";
import Radio from "../molecules/Form/Radio";
import Checkbox from "../molecules/Form/Checkbox";
import Tooltip from "../atoms/Tooltip";
import Btn from "../../UI/Btn/Btn";
import classes from "./ProfileSettings.module.css";

const ProfileSettings = () => {
    const [userDatas, setUserDatas] = useState({
        name: "",
        gender: "",
        birthDate: "",
        birthPlace: "",
        securityNumber: "",
        idNumber: "",
        noTAJ: false,
        mothersName: "",
        email: "",
        emailVerified: false,
        phone: "",
        country: "",
        zip: "",
        settlement: "",
        street: "",
        houseNumber: "",
        floorDoor: "",
    });

    useEffect(() => {
        get("user/get-profile-summary").then((response) => {
            const { responseCode, user } = response.data;

            switch (responseCode) {
                case "OK":
                    setUserDatas({
                        name: user.name,
                        birthDate: user.birthDate,
                        birthPlace: user.birthPlace,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        phone: user.phone,
                        gender: user.gender,
                        securityNumber: user.securityNumber,
                        idNumber: user.idNumber,
                        mothersName: user.mothersName,
                        country: user.country || "Magyarország",
                        zip: user.zip,
                        settlement: user.settlement,
                        street: user.street,
                        houseNumber: user.houseNumber,
                        floorDoor: user.floorDoor,
                        noTAJ: !user.securityNumber && user.idNumber,
                    });

                    break;

                default:
                    break;
            }
            endLoading();
        });
    }, []);

    const handleSecurityNumberOwnership = (e) => {
        setUserDatas((prevState) => ({
            ...prevState,
            securityNumber: e.target.checked ? "" : prevState.securityNumber,
            noTAJ: e.target.checked,
        }));
    };

    const handleProfileDataSave = () => {
        if (!isValidPhoneNumber(userDatas.phone)) {
            responseMessage("Hibás telefonszám!");

            return false;
        }

        const phoneNumber = parsePhoneNumber(userDatas.phone);
        let phoneCountry;
        if (phoneNumber) {
            phoneCountry = phoneNumber.country;
        }

        post("user/update-user-all-personal-data", {
            data: {
                birthDate: userDatas.birthDate
                    ? convertDate(new Date(userDatas.birthDate))
                    : "",
                birthPlace: userDatas.birthPlace,
                mothersName: userDatas.mothersName,
                securityNumber: userDatas.securityNumber,
                idNumber: userDatas.idNumber,
                gender: userDatas.gender,
                email: userDatas.email,
                phone: userDatas.phone,
                country: userDatas.country,
                zip: userDatas.zip,
                settlement: userDatas.settlement,
                street: userDatas.street,
                houseNumber: userDatas.houseNumber,
                floorDoor: userDatas.floorDoor,
                phoneCountry,
            },
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");

                        break;
                    case "EMPTY_BIRTH_DATE":
                        responseMessage("A születési idő megadása kötelező!");

                        break;
                    case "EMPTY_BIRTH_PLACE":
                        responseMessage("A születési hely megadása kötelező!");

                        break;
                    case "EMPTY_SECURITY_OR_ID_NUMBER":
                        responseMessage(
                            "A TAJ vagy személyi igazolvány szám megadása kötelező!"
                        );

                        break;
                    case "EMPTY_MOTHERS_NAME":
                        responseMessage("Kérjük adja meg édesanyja nevét!");

                        break;
                    case "EMPTY_EMAIL":
                        responseMessage("Kérjük adja meg valós email cimét!");

                        break;
                    case "EMPTY_PHONE":
                        responseMessage("Kérjük adja meg valós telefonszámát!");

                        break;
                    case "WRONG_EMAIL":
                        responseMessage("Hibás e-mail formátum!");

                        break;
                    case "WRONG_PHONE":
                        responseMessage("Hibás telefonszám formátum!");

                        break;
                    case "EMAIL_TAKEN":
                        responseMessage("A megadott e-mail cím foglalt!");

                        break;
                    case "PHONE_TAKEN":
                        responseMessage("A megadott telefonszám foglalt!");

                        break;
                    case "INVALID_SECURITY_NUMBER":
                        responseMessage("A megadott TAJ szám hibás!");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                endLoading();
            });
    };

    const handleInputChange = ({ target }) => {
        setUserDatas((prevState) => ({
            ...prevState,
            [target.name]: target.value,
        }));
    };

    return (
        <div className={classes.profileSettings}>
            <Grid container spacing={2} style={{ marginBottom: "0.8rem" }}>
                <Grid item xs={12} md={6}>
                    <label className={classes.formLabel}>Teljes név</label>
                    <Input
                        required
                        name="name"
                        value={userDatas.name}
                        onChange={handleInputChange}
                        disabled={userDatas.name !== ""}
                    />
                </Grid>
                {/* TODO: needs refact on backend to handle two fields instead of one */}
                {/*<Grid item xs={12} md={6}>
                    <label className={classes.formLabel}>Vezetéknév</label>
                    <Input name="firstName" />
                </Grid>
                 <Grid item xs={12} md={6}>
                    <label className={classes.formLabel}>Keresztnév</label>
                    <Input name="lastName" />
                </Grid> */}
                <Grid item xs={12} md={6}>
                    <label className={classes.formLabel}>Születési hely</label>
                    <Input
                        required
                        name="birthPlace"
                        value={userDatas.birthPlace}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label className={classes.formLabel}>Születési dátum</label>
                    <DateInput
                        required
                        name="birthDate"
                        value={userDatas.birthDate}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label className={classes.formLabel}>Születési nem</label>
                    <div className={classes.formRow}>
                        <Radio
                            name="gender"
                            value="Férfi"
                            label="Férfi"
                            checked={userDatas.gender === "Férfi"}
                            onChange={handleInputChange}
                        />
                        <Radio
                            name="gender"
                            value="Nő"
                            label="Nő"
                            checked={userDatas.gender === "Nő"}
                            onChange={handleInputChange}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <label className={classes.formLabel}>
                        Anyja leánykori neve
                    </label>
                    <Input
                        required
                        name="mothersName"
                        value={userDatas.mothersName}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: "0.8rem" }}>
                <Grid item xs={12} md={6}>
                    <div style={{ opacity: userDatas.noTAJ ? 0.5 : 1 }}>
                        <label className={classes.formLabel}>
                            <span>TAJ szám</span>
                            <Tooltip
                                isDisabled={userDatas.noTAJ}
                                title="TAJ számod 9 számjegyből áll. Amennyiben nem rendelkezel TAJ számmal, úgy az alsó négyzet kijelölését követően add meg igazolványszámod."
                            >
                                <InfoOutlinedIcon
                                    style={{ fontSize: "1.9rem" }}
                                />
                            </Tooltip>
                        </label>
                        <SecurityNumberInput
                            required
                            name="securityNumber"
                            value={userDatas.securityNumber}
                            onChange={handleInputChange}
                            disabled={userDatas.noTAJ}
                        />
                    </div>
                    <Checkbox
                        name="noTAJ"
                        value="0"
                        label="Nincs TAJ számom"
                        isRaw
                        checked={userDatas.noTAJ}
                        onChange={handleSecurityNumberOwnership}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: "0.8rem" }}>
                <Grid item xs={12} md={6}>
                    <label className={classes.formLabel}>
                        <span>Igazolvány szám</span>
                        <Tooltip title="Amennyiben nem rendelkezik TAJ számmal, adja meg személyigazolványának számát, vagy útlevelének számát.">
                            <InfoOutlinedIcon style={{ fontSize: "1.9rem" }} />
                        </Tooltip>
                    </label>
                    <Input
                        required
                        name="idNumber"
                        value={userDatas.idNumber}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: "0.8rem" }}>
                <Grid item xs={12} md={6}>
                    <label className={classes.formLabel}>
                        <span>Email cím</span>
                        <Tooltip title="Kérjük, hogy azt az e-mail címed add meg, melyhez mindig hozzáférsz, és fogadni tudod az általunk küldött értesítéseket.">
                            <InfoOutlinedIcon style={{ fontSize: "1.9rem" }} />
                        </Tooltip>
                    </label>
                    <Input
                        required
                        name="email"
                        type="email"
                        value={userDatas.email}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label className={classes.formLabel}>
                        <span>Telefonszám</span>
                        <Tooltip title="Kérjük, hogy annak a készüléknek a telefonszámát add meg, mellyel az alkalmazást használni szeretnéd. Belépéshez szükséges azonosítót, egyes szolgáltatásokról történő értesítéseket erre a számra küldjük, valamint a hívásokat is erről a telefonszámról tudod majd indítani.">
                            <InfoOutlinedIcon style={{ fontSize: "1.9rem" }} />
                        </Tooltip>
                    </label>
                    <PhoneInput
                        required
                        name="phone"
                        value={userDatas.phone}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: "0.8rem" }}>
                <Grid item xs={12} md={6}>
                    <PageSubHeader>Lakcím</PageSubHeader>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <label className={classes.formLabel}>
                        <span>Ország</span>
                    </label>
                    <Input
                        required
                        name="country"
                        value={userDatas.country}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <label className={classes.formLabel}>
                        <span>Irányítószám</span>
                    </label>
                    <Input
                        required
                        name="zip"
                        value={userDatas.zip}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label className={classes.formLabel}>
                        <span>Település</span>
                    </label>
                    <Input
                        required
                        name="settlement"
                        value={userDatas.settlement}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <label className={classes.formLabel}>
                        <span>Utca</span>
                    </label>
                    <Input
                        required
                        name="street"
                        value={userDatas.street}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <label className={classes.formLabel}>
                        <span>Házszám</span>
                    </label>
                    <Input
                        required
                        name="houseNumber"
                        value={userDatas.houseNumber}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <label className={classes.formLabel}>
                        <span>Emelet / Ajtó</span>
                    </label>
                    <Input
                        name="floorDoor"
                        value={userDatas.floorDoor}
                        onChange={handleInputChange}
                    />
                </Grid>
                {/* TODO: For a better validation here comes the design */}
                {/* <Grid item xs={12} md={4}>
                    <label className={cn(classes.formLabel, "validationError")}>
                        <span>Sample error label / field</span>
                    </label>
                    <Input
                        name="sampleField"
                        value="Sample value"
                        className="validationError"
                    />
                </Grid> */}
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Btn
                            className={classes.button}
                            onClick={handleProfileDataSave}
                        >
                            Adatok mentése
                        </Btn>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ProfileSettings;
