import { Grid } from "@material-ui/core";
import WebshopProductCard from "../molecules/WebshopProductCard";
import classes from "./WebshopProducts.module.css";

const WebshopProducts = ({ products }) => {
    if (products?.length === 0) {
        return null;
    }

    return (
        <div className={classes.products}>
            <Grid container spacing={2}>
                {products.map((product, index) => (
                    <Grid
                        key={`webshop-product-card-${index}`}
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                    >
                        <WebshopProductCard {...product} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default WebshopProducts;
