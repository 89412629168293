import Grid from "@material-ui/core/Grid";
import classes from "./InfoRow.module.css";

const InfoRow = (props) => (
    <Grid container justifyContent="center">
        <Grid
            item
            container
            justifyContent="space-between"
            className={classes.infoWrapper}
            md={6}
            xs={12}
            style={{ backgroundColor: props.noBG ? null : "#F8F8F8" }}
            alignItems="center"
        >
            <Grid item>
                <span className={classes.text}>{props.name}</span>
            </Grid>
            <Grid item>
                <div className={classes.textWrapper}>
                    <div className={classes.innerWrapper}>
                        <span className={classes.text}>{props.value}</span>
                        {props.extraText && (
                            <span
                                className={classes.text}
                                style={{ color: "#929EA7" }}
                            >
                                {props.extraText}
                            </span>
                        )}
                    </div>
                    {props.withDot && <div className={classes.dot}></div>}
                    {props.image && (
                        <div className={classes.imageContainer}>
                            <img src={props.image} alt="" />{" "}
                        </div>
                    )}
                </div>
            </Grid>
        </Grid>
    </Grid>
);

export default InfoRow;
