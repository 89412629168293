import { useState } from "react";
import CarouselComponent from "react-slick";
import Box from "@material-ui/core/Box";
import { Container } from "@material-ui/core";
import { useResolution } from "../../../../ui/utils";
import ArrowIcon from "../ArrowIcon";
import type { Gallery, GalleryItem } from "../../../../ui/models";
import Btn from "../../../../components/UI/Btn/Btn";
import { Colors } from "../../../../assets/Theme";
import PageHeader from "../../../../ui/layout/PageHeader";

interface Props {
    contentBlock: Gallery;
}

const GalleryBlock = ({ contentBlock }: Props) => {
    const { galleryItems, galleryDescription } = contentBlock;

    const [showAllImages, setAllImages] = useState(false);

    const isMobile = useResolution("mobile");

    const slidesToShow = (slidesToShow: number) =>
        slidesToShow > galleryItems.length ? galleryItems.length : slidesToShow;

    const toggleImages = () => setAllImages(!showAllImages);

    const carouselSlideSettings = (numberOfSlides: number) => ({
        ...(showAllImages
            ? {
                  slidesPerRow: slidesToShow(numberOfSlides),
                  rows: galleryItems.length / slidesToShow(numberOfSlides),
              }
            : {
                  slidesToShow: slidesToShow(numberOfSlides),
              }),
    });

    const gallery = (
        <Box color={Colors.darkBlue} my={4}>
            {contentBlock.sectionLabel && (
                <PageHeader text={contentBlock.sectionLabel} />
            )}
            <Box display="flex" justifyContent="center">
                <Box
                    width={isMobile ? "100%" : "60%"}
                    textAlign="center"
                    pb={isMobile ? 4 : 8}
                >
                    {galleryDescription}
                </Box>
            </Box>
            <CarouselComponent
                autoplay={true}
                autoplaySpeed={showAllImages ? 0 : 4000}
                speed={showAllImages ? 0 : 1000}
                nextArrow={
                    <ArrowIcon
                        type="gallery"
                        direction={isMobile ? "prev" : "next"}
                    />
                }
                prevArrow={
                    <ArrowIcon
                        type="gallery"
                        direction={isMobile ? "next" : "prev"}
                    />
                }
                {...carouselSlideSettings(4)}
                responsive={[
                    {
                        breakpoint: 959,
                        settings: {
                            ...carouselSlideSettings(3),
                        },
                    },
                    {
                        breakpoint: 599,
                        settings: {
                            ...carouselSlideSettings(2),
                        },
                    },
                ]}
            >
                {galleryItems.map((galleryItem: GalleryItem, index) => (
                    <Box key={index} px={1} mb={!isMobile && 4}>
                        <Box
                            height={isMobile ? "108px" : "209px"}
                            mt={isMobile ? "5rem" : "0"}
                        >
                            <img
                                style={{ objectFit: "cover" }}
                                src={galleryItem.image}
                                height="100%"
                                width="100%"
                                alt=""
                            />
                        </Box>
                        <h6>{galleryItem.title}</h6>
                        <h5>{galleryItem.description}</h5>
                    </Box>
                ))}
            </CarouselComponent>

            <Box my={5}>
                <Btn color="transparent" onClick={toggleImages}>
                    {showAllImages
                        ? "Kevesebb kép megjelenítése"
                        : "Összes kép megtekintése"}
                </Btn>
            </Box>
        </Box>
    );

    return (
        <Container>
            {isMobile ? (
                gallery
            ) : (
                <Container style={{ maxWidth: "90%" }}>{gallery}</Container>
            )}
        </Container>
    );
};

export default GalleryBlock;
