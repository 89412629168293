import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import { endLoading, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import * as actions from "../../store/actions/index";
import Button from "../../components/UI/Button/Button";
import useTranslation from "../../features/i18n/useTranslation";
import { useDispatch } from "../../store";
import { login } from "../../features/auth/slice";
import useQuery from "../../features/util/useQuery";
import classes from "./VerifyEmail.module.css";

const VerifyEmail = (props) => {
    const navigate = useNavigate();
    const query = useQuery();
    const dispatch = useDispatch();
    const [verified, setVerified] = useState(false);
    const { i18nRoute } = useTranslation();

    const isAlreadyLoggedIn = !!useSelector((state) => state.auth.token);

    useEffect(() => {
        const hash = query.get("hash");

        if (isAlreadyLoggedIn) {
            responseMessage(
                "Egy felhasználó már be van jelentkezve. Jelentkezz ki az email megerősítéséhez!"
            );
            navigate(`${i18nRoute("routes.profile.base")}`, { replace: true });

            return;
        }

        if (!hash) {
            responseMessage("Hiányzó hash paraméter!");

            return;
        }

        post("auth/verify-email", { hash })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        setVerified(true); // TODO: Ez nem volt itt, úgyis átirányítunk, nem is kell sztem.
                        dispatch(
                            login({
                                userId: response.data.id,
                                token: response.data.token,
                            })
                        );
                        navigate(
                            `${i18nRoute("routes.profile.base")}${
                                response.data.firstLogin
                                    ? "?firstLogin=true"
                                    : ""
                            }`,
                            { replace: true }
                        );

                        break;
                    default:
                        responseMessage("Hibás vagy nem létező hash!");

                        break;
                }
            })
            .catch((error) => console.error(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.VerifyEmail}>
            <Container>
                <div className={classes.VerifyEmailInner}>
                    {verified && (
                        <>
                            <h3>Sikeres megerősítés</h3>
                            <p>Kedves Felhasználó!</p>
                            <p>Email címét sikeresen megerősítette!</p>
                            <Button
                                text="Bejelentkezés"
                                onClick={() => props.toggleLoginModal(true)}
                            />
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
});

export default connect(null, mapDispatchToProps)(VerifyEmail);
