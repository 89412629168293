// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/jsx-filename-extension */
import classes from "./classes.module.css";

const Analyze = () => (
    <>
        <h3 className={classes.title} id="okoseszkozok">
            Milyen okoseszköz gyártók eszközei használhatók?
        </h3>
        <p className={classes.text}>
            Jelenleg az Apple, Garmin, Polar, Withings, Wear OS by Google és
            Xiaomi okoseszközei használhatók az Adatalapú telemedicina
            szolgáltatás keretében. Ezenkívül támogatjuk a Google Fit
            szinkronizációt is.
        </p>
        <p className={classes.text}>
            A támogatott eszközök részletes listáját a partnerünk weboldalán
            érheted el:
            <a
                className={classes.link}
                href="https://www.nexthealth.app/tamogatott-eszkozok"
            >
                https://www.nexthealth.app/tamogatott-eszkozok
            </a>
        </p>
        <h3 className={classes.title} id="bluetooth-eszkozok">
            Mik a jelenleg kompatibilis Bluetooth eszközök?
        </h3>
        <p className={classes.text}>
            Jelenleg a következő Bluetooth eszközök kompatibilisek az Adatalapú
            telemedicina szolgáltatással:
        </p>
        <ul className={classes.list}>
            <li>Accu-Chek Instant (vércukormérő)</li>
            <li>BerryMed BM1000B (véroxigénszint-mérő)</li>
            <li>Lepulse Lescale F4 (mérleg)</li>
            <li>Wellue AirBP2 (vérnyomásmérő)</li>
            <li>Wellue B02T (vérnyomásmérő)</li>
            <li>Wellue Checkme O2 Max (véroxigénszint-mérő)</li>
            <li>Wellue Oxyfit PO6 (véroxigénszint-mérő)</li>
            <li>Wellue AOJ-20A (hőmérő)</li>
        </ul>
        <h3 className={classes.title} id="kompatibilis-eszkozok">
            Hol tudom csatlakoztatni a kompatibilis eszközeimet?
        </h3>
        <p className={classes.text}>
            Saját okoseszközeidet, illetve a megvásárolt orvostechnikai
            eszközöket a T-DOC alkalmazásban (
            <a
                className={classes.link}
                href="https://play.google.com/store/apps/details?id=hu.tdoc.tdoc&hl=en_US&pli=1"
            >
                Android
            </a>
            ,{" "}
            <a
                className={classes.link}
                href="https://apps.apple.com/hu/app/t-doc/id1601837228?l=hu&utm_source=fb&utm_medium=cover&fbclid=IwAR3TOOzCLKlwRyjYJCm1ICkcu5WJMPnVJPkIMB1Xv5cBKe_2lIMrHoE782U"
            >
                iOS
            </a>
            ) tudod csatlakoztatni. A T-DOC alkalmazás menüjében az Analízis
            központ / Eszközök / Összes eszköz oldalon, ezen belül pedig a
            csatlakoztatni kívánt eszközre kattintva teheted meg mindössze
            néhány kattintással.
            <br />
            <br />
            Felhívjuk figyelmedet, hogy csak aktív előfizetés esetén éred el a
            menüpontot.
        </p>
        <h3 className={classes.title} id="hany-eszkoz">
            Hány eszközt tudok egyidejűleg csatlakoztatni?
        </h3>
        <p className={classes.text}>
            T-DOC előfizetéstől, illetve vásárolt szolgáltatástól függ, hogy
            mennyi eszközt csatlakoztatsz egyidejűleg a fiókodhoz.
        </p>
        <h3 className={classes.title} id="technikail-problema">
            Technikai probléma, elakadás esetén hol kaphatok segítséget?
        </h3>
        <p className={classes.text}>
            Technikai segítséget közvetlenül a partnerünk Call centerénél
            kaphatsz:{" "}
            <a className={classes.link} href="mailto:office@nexthealth.app ">
                office@nexthealth.app{" "}
            </a>
            e-mail címen vagy munkaidőben a +36304056889 telefonszámon. <br />
            <br />
            Egészségügyi jellegű kérdésekben továbbra is a T-DOC Hotline lesz
            segítségedre.
        </p>
        <h3 className={classes.title} id="adatkuldes">
            Mi a teendő, ha nem akarom, hogy az eszközöm továbbra is adatokat
            küldjön a T-DOC rendszerébe?
        </h3>
        <p className={classes.text}>
            Ebben az esetben nincs más teendőd, mint a T-DOC alkalmazásban
            lecsatlakoztatni az érintett eszközt. Felhőalapú adatszolgáltató
            esetén közvetlenül is visszavonhatod hozzájárulásodat az érintett
            szolgáltató felhasználói fiókjába belépve.
        </p>
    </>
);

export default Analyze;
