import { useState } from "react";
import { Hidden, Link } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Btn from "../../UI/Btn/Btn";
import { Icons } from "../../../assets/Theme";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./QualificationServiceCard.module.css";

const isMobile = window.innerWidth <= 959;

const getServiceImage = (service) => {
    const services = {
        hotline: Icons.hotlineGreen,
        duty: Icons.dutyGreen,
        chat: Icons.messageExchangeGreen,
        "second-opinion": Icons.opinionGreen,
        outpatient: Icons.reservationGreen,
        "video-consultation": Icons.videoConsultationGreen,
    };

    return services[service];
};

const getServiceName = (service) => {
    const services = {
        hotline:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In aliquam sed nisl a condimentum. Pellentesque vestibulum sodales est, vel viverra mi consequat quis.",
        duty: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In aliquam sed nisl a condimentum. Pellentesque vestibulum sodales est, vel viverra mi consequat quis.",
        chat: "Orvosi chat",
        "second-opinion": "Másodvélemény",
        outpatient: "Járóbeteg ellátás",
        "video-consultation": "Videókonzultáció",
    };

    return services[service];
};

const getServiceText = (service) => {
    const services = {
        hotline: "Egészségügyi hotline",
        duty: "Orvosi ügyelet",
        chat: "Konkrét probléma, tünet megvitatása, friss lelet vagy diagnózis kiértékelése esetén javasoljuk.",
        "second-opinion":
            "Műtét előtt, komplex leletek, diagnózisok kiértékelése, valamint terápiás vagy gyógyszeres kezeléseket megelőzően javasoljuk",
        outpatient:
            "Személyes jelenlétet igénylő vizsgálatok esetén javasoljuk. Intézmények keresése és időpontfoglalás",
        "video-consultation":
            "Személyes jelenlétet nem igénylő vizsgálatok, látható tünetek, kontroll vizsgálatok, tanácsadás és konzultáció esetén javasoljuk.",
    };

    return services[service];
};

const getDetails = (service) => {
    const services = {
        hotline: undefined,
        duty: undefined,
        chat: (
            <>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>Üzenetküldés</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>Kép és fájl feltöltés</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>100% hiteles orvosi válasz</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>100% diszkréció</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>24-48 órán belüli válasz</p>
                </li>
            </>
        ),
        "second-opinion": (
            <>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>Üzenetküldés</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>Kép és fájl feltöltés</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>100% hiteles orvosi válasz</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>100% diszkréció</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>72 órán belüli válasz</p>
                </li>
            </>
        ),
        outpatient: (
            <>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>Időpont akár 24 órán belül</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>Akár 15% kedvezmény a vizsgálatokra</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>Szakterületek széles lefedettsége</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>Megbízható egészségügyi intézmények</p>
                </li>
            </>
        ),
        "video-consultation": (
            <>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>Időpont akár 48 órán belül</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>Egyszerű és gyors rendszerhasználat</p>
                </li>
                <li className={classes.detail}>
                    <img
                        src={Icons.tickGreen}
                        className={classes.tick}
                        alt=""
                    />
                    <p>100% diszkréció</p>
                </li>
            </>
        ),
    };

    return services[service];
};

const QualificationServiceCard = (props) => {
    const navigate = useNavigate();
    const [showDetails, toggleDetails] = useState(false);
    const { i18nRoute } = useTranslation();

    const onButtonClick = (service) => {
        switch (service) {
            case "hotline":
            case "duty":
                return;
            case "chat":
                navigate(
                    i18nRoute("routes.services.chatStart", {
                        routeHash: `?qualification=${props.ids.qualificationId}`,
                    })
                );

                break;
            case "second-opinion":
                navigate(
                    i18nRoute("routes.services.secondOpinionStart", {
                        routeHash: `?qualification=${props.ids.qualificationId}`,
                    })
                );

                break;
            case "outpatient":
                initOutpatinet();

                return;
            case "video-consultation":
                initVideo();

                return;
        }
    };

    const initVideo = () =>
        navigate(
            `/idopontfoglalas?selectedQualification=${props.ids.videoconsultationId}&onlyVideo=true`
        );

    const initOutpatinet = () =>
        navigate(
            `/idopontfoglalas?selectedQualification=${props.ids.outpatientId}&onlyOutpatient=true`
        );

    const onCardClick = (props) => {
        if (isMobile) {
            onButtonClick(props.type);
        }
    };

    return (
        <>
            <aside
                className={classes.service}
                onClick={() => onCardClick(props)}
            >
                <header>
                    <img
                        src={getServiceImage(props.type)}
                        className={classes.icon}
                        alt=""
                    />
                    <h3 className={classes.name}>
                        {getServiceName(props.type)}
                    </h3>
                </header>
                <p className={classes.text}>{getServiceText(props.type)}</p>

                {showDetails && (
                    <ul className={classes.details}>
                        {getDetails(props.type)}
                    </ul>
                )}

                <footer>
                    {/* TODO: nincs adat az árhoz, de a designon szerepel */}
                    {/* <div className={classes.price}>ár</div> */}
                    {getDetails(props.type) ? (
                        <Hidden smDown>
                            <p
                                className={`link ${classes.link}`}
                                onClick={() => toggleDetails(!showDetails)}
                            >
                                {showDetails
                                    ? "Részletek elrejtése"
                                    : "Részletek"}
                            </p>
                        </Hidden>
                    ) : (
                        <Link
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                onButtonClick(props.type);
                            }}
                            className={classes.link}
                        >
                            Kiválasztás
                        </Link>
                    )}
                </footer>
                {showDetails && (
                    <Btn
                        onClick={() => onButtonClick(props.type)}
                        className={classes.button}
                    >
                        Kiválasztás
                    </Btn>
                )}
            </aside>
        </>
    );
};

export default QualificationServiceCard;
