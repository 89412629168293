import { Dialog, DialogContent, Hidden } from "@material-ui/core";
import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { responseMessage } from "../../../utility";

const Modal = (props) => {
    const [open, setOpen] = useState(props.open);
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const closeModal = (event, reason) => {
        if (reason === "backdropClick" && props.disableBackdropClick) {
            return;
        }

        if (props.confirmClose) {
            responseMessage(
                "Biztosan bezárja az ablakot? Az adatok nem kerülnek mentésre.",
                "Megerősítés szükséges!",
                props.closeModal,
                true
            );
        } else {
            props.closeModal();
        }
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="simple-dialog-title"
            onClose={closeModal}
            classes={{ paperScrollPaper: props.paperScrollPaperClass }}
            maxWidth={props.maxWidth || "lg"}
            fullWidth={props.fullWidth}
        >
            {!props.mobileCloseDisabled && (
                <Hidden smUp>
                    <div style={styles.closeContainer} onClick={closeModal}>
                        <CloseIcon style={styles.closeICon} />
                    </div>
                </Hidden>
            )}
            {props.desktopCloseIcon && (
                <div
                    style={{
                        ...styles.closeContainerDesktop,
                        ...props.closeContainerDesktopStyle,
                    }}
                    onClick={closeModal}
                >
                    <CloseIcon style={styles.closeIconDesktop} />
                </div>
            )}
            <DialogContent
                style={props.style}
                classes={{ root: props.dialogClass }}
            >
                {props.children}
            </DialogContent>
        </Dialog>
    );
};

const styles = {
    closeContainer: {
        // borderWidth: '1px',
        // borderStyle: 'solid',
        // borderColor: '#7E9591',
        // borderRadius: '50%',
        width: "3rem",
        height: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        right: "0",
        top: "0",
        zIndex: 1001,
        backgroundColor: "#fff",
    },
    closeContainerDesktop: {
        // borderWidth: '1px',
        // borderStyle: 'solid',
        // borderColor: '#7E9591',
        // borderRadius: '50%',
        width: "4rem",
        height: "4rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        right: "1rem",
        top: "1rem",
        zIndex: 1001,
        backgroundColor: "#fff",
    },
    closeIconDesktop: {
        width: "2rem",
        height: "2rem",
    },
};

export default Modal;
