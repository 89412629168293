import { useEffect, useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckIcon from "@material-ui/icons/Check";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { endLoading, post } from "../../../config/fetch";
import * as actions from "../../../store/actions/index";
import { Colors, Images } from "../../../assets/Theme";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import Modal from "../../UI/Modal/Modal";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./RegistrationModal.module.css";

const RegistrationModal = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [open, toggleOpen] = useState(props.open);
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const emptyUserData = {
        firstName: "",
        lastName: "",
        email: props.email || "",
        facebookId: null,
        googleId: null,
        couponCode: null,
    };
    const [userData, setUserData] = useState(emptyUserData);

    const [userDefaultData, setUserDefaultData] = useState({
        firstName: null,
        lastName: null,
        email: null,
    });
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        if (props.email) {
            setUserData({
                ...userData,
                email: props.email,
                inviteHash: props.inviteHash,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.email]);

    useEffect(() => {
        if (!props.email && props.open) {
            setUserData(emptyUserData);
            setUserDefaultData({
                firstName: null,
                lastName: null,
                email: null,
            });
        }
        toggleOpen(props.open);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const slider = useRef(null);
    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        swipe: false,
    };

    const validateCoupon = () => {
        post("api/v1/coupon/validate-coupon", {
            couponCode: userData.couponCode,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        slider.current.slickNext();

                        break;
                    case "WRONG_CODE":
                        responseMessage("Hibás kuponkód!");

                        break;
                }
            })
            .catch((error) => {
                endLoading();
                console.error(error);
            });
    };

    const button0Handler = () => {
        if (!userData.lastName || !userData.firstName) {
            responseMessage("A vezetéknév és keresztnév megadása kötelező!");

            return false;
        }

        if (userData.couponCode) {
            validateCoupon();

            return false;
        }

        slider.current.slickNext();
    };

    const button1Handler = () => {
        if (!userData.email) {
            responseMessage("Az email cím megadása kötelező!");

            return false;
        }
        if (!userData.phone) {
            responseMessage("A telefonszám megadása kötelező!");

            return false;
        }
        if (!isValidPhoneNumber(userData.phone)) {
            responseMessage("Hibás telefonszám!");

            return false;
        }
        const phoneNumber = parsePhoneNumber(userData.phone);
        let phoneCountry;
        if (phoneNumber) {
            phoneCountry = phoneNumber.country;
        }
        post("api/v1/auth/check-identifiers", {
            email: userData.email,
            phone: userData.phone,
            phoneCountry,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        if (!userData.googleId && !userData.facebookId) {
                            slider.current.slickNext();
                        } else {
                            slider.current.slickGoTo(3);
                        }

                        break;
                    case "WRONG_EMAIL":
                        responseMessage("Nem megfelelő email cím!");

                        break;
                    case "WRONG_PHONE":
                        responseMessage("Hibás telefonszám!");

                        break;
                    case "EMAIL_TAKEN":
                        responseMessage("A megadott email cím már foglalt");

                        break;
                    case "PHONE_TAKEN":
                        responseMessage("A megadott telefonszám már foglalt!");

                        break;
                }
            })
            .catch((error) => {
                endLoading();
                console.error(error);
            });
    };

    const back1Handler = () => slider.current.slickPrev();

    const button2Handler = () => {
        if (!userData.password || !userData.password2) {
            responseMessage("Nem adott meg jelszót");

            return false;
        }
        if (userData.password !== userData.password2) {
            responseMessage("A megadott jelszavak nem egyeznek!");

            return false;
        }
        if (userData.password.length < 6) {
            responseMessage(
                "A jelszónak legalább 6 karakter hosszúságúnak kell lennie!"
            );

            return false;
        }
        slider.current.slickNext();
    };

    const back2Handler = () => {
        if (!userData.googleId && !userData.facebookId) {
            slider.current.slickPrev();
        } else {
            slider.current.slickGoTo(1);
        }
    };

    const button3Handler = () => {
        // slider.current.slickNext()
        if (!checkbox1) {
            responseMessage("Az ÁSZF elfogadása kötelező!");

            return false;
        }
        registration();
    };

    const back3Handler = () => slider.current.slickPrev();

    const button4Handler = () => slider.current.slickNext();

    const registration = () => {
        if (!userData.lastName || !userData.firstName) {
            responseMessage("A vezetéknév és keresztnév megadása kötelező!");
            slider.current.slickGoTo(0);

            return false;
        }
        if (!userData.email) {
            responseMessage("Az email cím megadása kötelező!");
            slider.current.slickGoTo(1);

            return false;
        }
        if (!userData.phone) {
            responseMessage("A telefonszám cím megadása kötelező!");
            slider.current.slickGoTo(1);

            return false;
        }
        if (
            (!userData.password || !userData.password2) &&
            !userData.facebookId &&
            !userData.googleId
        ) {
            responseMessage("Nem adott meg jelszót");
            slider.current.slickGoTo(2);

            return false;
        }
        if (userData.password !== userData.password2) {
            responseMessage("A megadott jelszavak nem egyeznek");
            slider.current.slickGoTo(2);

            return false;
        }
        if (!checkbox1) {
            responseMessage("Az ÁSZF elfogadása kötelező");
            slider.current.slickGoTo(3);

            return false;
        }
        const phoneNumber = parsePhoneNumber(userData.phone);
        let phoneCountry;
        if (phoneNumber) {
            phoneCountry = phoneNumber.country;
        }
        let reservationSummary = null;
        if (location.pathname === "/idopont-kivalasztva") {
            reservationSummary = location.search.slice(1);
        }

        post("auth/register", {
            lastName: userData.lastName,
            firstName: userData.firstName,
            email: userData.email,
            phone: userData.phone,
            phoneCountry,
            password: userData.password,
            newsletter: checkbox2,
            facebookId: userData.facebookId,
            googleId: userData.googleId,
            inviteHash: userData.inviteHash,
            reservationSummary,
            campaign: sessionStorage.getItem("campaign") || null,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        navigate(
                            `/registration-end?newsletter=${checkbox2}&newsletterHash=${response.data.newsletterHash}&userId=${response.data.userId}&couponCode=${userData.couponCode}`
                        );
                        close();

                        break;
                    case "MISSING_LAST_OR_FIRST_NAME":
                        props.toggleResponseModal(true, {
                            content: "Hiányzó vezeték- és/vagy keresztnév!",
                            confirmButtonCallback: () =>
                                slider.current.slickGoTo(0),
                            disableBackdropClick: true,
                        });

                        break;
                    case "MISSING_PASSWORD":
                        props.toggleResponseModal(true, {
                            content: "A jelszó megadása kötelező!",
                            confirmButtonCallback: () =>
                                slider.current.slickGoTo(2),
                            disableBackdropClick: true,
                        });

                        break;
                    case "MISSING_EMAIL":
                        props.toggleResponseModal(true, {
                            content: "Az email cím megadása kötelező!",
                            confirmButtonCallback: () =>
                                slider.current.slickGoTo(1),
                            disableBackdropClick: true,
                        });

                        break;
                    case "WRONG_EMAIL":
                        props.toggleResponseModal(true, {
                            content: "Nem megfelelő email cím!",
                            confirmButtonCallback: () =>
                                slider.current.slickGoTo(1),
                            disableBackdropClick: true,
                        });

                        break;
                    case "MISSING_PHONE":
                        props.toggleResponseModal(true, {
                            content: "A telefonszám megadása kötelező!",
                            confirmButtonCallback: () =>
                                slider.current.slickGoTo(1),
                            disableBackdropClick: true,
                        });

                        break;
                    case "WRONG_PHONE":
                        props.toggleResponseModal(true, {
                            content: "Hibás telefonszám!",
                            confirmButtonCallback: () =>
                                slider.current.slickGoTo(1),
                            disableBackdropClick: true,
                        });

                        break;
                    case "EMAIL_TAKEN":
                        props.toggleResponseModal(true, {
                            content: "A megadott email cím már foglalt",
                            confirmButtonCallback: () =>
                                slider.current.slickGoTo(1),
                            disableBackdropClick: true,
                        });

                        break;
                    case "PHONE_TAKEN":
                        props.toggleResponseModal(true, {
                            content: "A megadott telefonszám már foglalt!",
                            confirmButtonCallback: () =>
                                slider.current.slickGoTo(1),
                            disableBackdropClick: true,
                        });

                        break;
                    case "SYSTEM_ERROR":
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                console.error(error);
            });
    };

    const GreenCheckbox = withStyles({
        root: {
            color: "#1FBA9C",
            "&$checked": {
                color: "#1FBA9C",
            },
            fontSize: "2.5rem",
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const setUserDataByKey = (key, value) =>
        setUserData({ ...userData, [key]: value });

    const close = () => {
        if (props.closeModal) {
            props.closeModal();
        } else {
            props.toggleRegistrationModal(false);
        }
    };

    const onLoginClick = () => {
        props.toggleRegistrationModal(false);
        props.toggleLoginModal(true);
    };

    return (
        <Modal open={open} key={open} closeModal={close}>
            <div className={classes.RegistrationModal}>
                <Slider {...sliderSettings} ref={slider}>
                    <div className={classes.RegistrationModalInner}>
                        <div className={classes.headerRow}>
                            <div className={classes.line}></div>
                            <span className={classes.header}>
                                Fiók létrehozása
                            </span>
                            <div className={classes.line}></div>
                        </div>
                        <p className={classes.counter}>1/4</p>
                        <p className={classes.infoText}>
                            Hogy felvehesd orvosoddal a kapcsolatot, fontos,
                            hogy a valós neved add meg. Személyes adataid nem
                            lesznek nyilvánosak. A biztonságos adatkezelésről
                            bővebben{" "}
                            <Link
                                className={classes.link}
                                to={"/adatvedelmi_nyilatkozat"}
                                target="_blank"
                            >
                                itt olvashatsz.
                            </Link>
                        </p>
                        <div className={classes.inputContainer}>
                            <p className={classes.label}>Vezetéknév</p>
                            <Input
                                defaultValue={userDefaultData.lastName}
                                bigger
                                className={classes.input}
                                onChange={(e) =>
                                    setUserDataByKey("lastName", e.target.value)
                                }
                            />
                        </div>
                        <div className={classes.inputContainer}>
                            <p className={classes.label}>Keresztnév</p>
                            <Input
                                bigger
                                defaultValue={userDefaultData.firstName}
                                className={classes.input}
                                onChange={(e) =>
                                    setUserDataByKey(
                                        "firstName",
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                        <Button
                            className={classes.button}
                            rightIcon={Images.arrow}
                            onClick={button0Handler}
                        >
                            Folytatás
                        </Button>
                        <div className={classes.linkRow}>
                            <span className={classes.loginText}>
                                Már van fiókod?{" "}
                            </span>
                            <span
                                className={classes.link}
                                onClick={onLoginClick}
                            >
                                Bejelentkezés
                            </span>
                        </div>

                        <div
                            className={classes.headerRow}
                            style={{ marginTop: "5rem" }}
                        >
                            <div className={classes.line}></div>
                            <span className={classes.header}>
                                Kuponkód megadása
                            </span>
                            <div className={classes.line}></div>
                        </div>
                        <Input
                            defaultValue={userDefaultData.lastName}
                            bigger
                            className={classes.input}
                            style={{ marginTop: "5rem" }}
                            onChange={(e) =>
                                setUserDataByKey("couponCode", e.target.value)
                            }
                        />
                    </div>

                    <div className={classes.RegistrationModalInner}>
                        <div className={classes.headerRow}>
                            <div className={classes.line}></div>
                            <span className={classes.header}>
                                Fiók létrehozása
                            </span>
                            <div className={classes.line}></div>
                        </div>
                        <p className={classes.counter}>2/4</p>
                        <p className={classes.infoText}>
                            Így tudsz majd bejelentkezni a fiókodba, és a
                            megadott elérhetőségen keresztül vesszük majd fel
                            veled a kapcsolatot.{" "}
                            <Link
                                className={classes.link}
                                to={i18nRoute("routes.terms")}
                                target="_blank"
                            >
                                Miért adjam meg a telefonszámom?
                            </Link>
                        </p>
                        {!userData.facebookId && !userData.googleId && (
                            <div className={classes.inputContainer}>
                                <p className={classes.label}>Email cím</p>
                                <Input
                                    bigger
                                    className={classes.input}
                                    type="email"
                                    defaultValue={userDefaultData.email}
                                    onChange={(e) =>
                                        setUserDataByKey(
                                            "email",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        )}
                        <div className={classes.inputContainer}>
                            <p className={classes.label}>Telefonszám</p>

                            <Input
                                bigger
                                type="phone"
                                className={classes.input}
                                onChange={(e) => setUserDataByKey("phone", e)}
                            />
                        </div>
                        <Button
                            onClick={button1Handler}
                            className={classes.button}
                            rightIcon={Images.arrow}
                        >
                            Folytatás
                        </Button>
                        <div className={classes.linkRow}>
                            <span
                                className={`${classes.link} ${classes.greenLink}`}
                                onClick={back1Handler}
                            >
                                Visszalépés
                            </span>
                        </div>
                    </div>
                    <div className={classes.RegistrationModalInner}>
                        <div className={classes.headerRow}>
                            <div className={classes.line}></div>
                            <span className={classes.header}>
                                Fiók létrehozása
                            </span>
                            <div className={classes.line}></div>
                        </div>
                        <p className={classes.counter}>3/4</p>
                        <p className={classes.infoText}>
                            Tedd biztonságossá fiókod egy erős jelszóval.
                        </p>
                        <div className={classes.inputContainer}>
                            <p className={classes.label}>Jelszó</p>
                            <Input
                                bigger
                                className={classes.input}
                                type="passwordRegister"
                                value="dfsfd"
                                onChange={(e) =>
                                    setUserDataByKey("password", e)
                                }
                            />
                        </div>
                        <div className={classes.inputContainer}>
                            <p className={classes.label}>Jelszó mégegyszer</p>
                            <Input
                                bigger
                                className={classes.input}
                                type="passwordRegister"
                                onChange={(e) =>
                                    setUserDataByKey("password2", e)
                                }
                            />
                        </div>
                        <Button
                            className={classes.button}
                            rightIcon={Images.arrow}
                            onClick={button2Handler}
                        >
                            Folytatás
                        </Button>
                        <div className={classes.linkRow}>
                            <span
                                className={`${classes.link} ${classes.greenLink}`}
                                onClick={back2Handler}
                            >
                                Visszalépés
                            </span>
                        </div>
                    </div>
                    <div className={classes.RegistrationModalInner}>
                        <div className={classes.headerRow}>
                            <div className={classes.line}></div>
                            <span className={classes.header}>
                                Fiók létrehozása
                            </span>
                            <div className={classes.line}></div>
                        </div>
                        <p className={classes.counter}>4/4</p>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <GreenCheckbox
                                        name="checkbox1"
                                        checked={checkbox1}
                                        icon={
                                            <CheckBoxOutlineBlankIcon
                                                style={{ fontSize: "2rem" }}
                                            />
                                        }
                                        checkedIcon={
                                            <CheckBoxIcon
                                                style={{ fontSize: "2rem" }}
                                            />
                                        }
                                        onChange={(event) =>
                                            setCheckbox1(event.target.checked)
                                        }
                                    />
                                }
                                label={
                                    <span>
                                        Elolvastam és elfogadom az{" "}
                                        <Link
                                            className={classes.link}
                                            to={"/adatvedelmi_nyilatkozat"}
                                            target="_blank"
                                        >
                                            általános adatkezelési irányelveket
                                        </Link>{" "}
                                        és a{" "}
                                        <Link
                                            className={classes.link}
                                            to={i18nRoute("routes.terms")}
                                            target="_blank"
                                        >
                                            felhasználási feltételeket.
                                        </Link>
                                    </span>
                                }
                                classes={{ label: classes.checkboxLabel }}
                                style={{ marginBottom: "3rem" }}
                            />
                            <FormControlLabel
                                control={
                                    <GreenCheckbox
                                        name="checkbox2"
                                        checked={checkbox2}
                                        icon={
                                            <CheckBoxOutlineBlankIcon
                                                style={{ fontSize: "2rem" }}
                                            />
                                        }
                                        checkedIcon={
                                            <CheckBoxIcon
                                                style={{ fontSize: "2rem" }}
                                            />
                                        }
                                        onChange={(event) =>
                                            setCheckbox2(event.target.checked)
                                        }
                                    />
                                }
                                label="Hozzájárulok ahhoz, hogy e-mail tájékoztatást kapjak a T-DOC legújabb egészségügyi szolgáltatásairól, valamint hogy személyre szabott marketing üzenetekkel megkeressenek."
                                classes={{ label: classes.checkboxLabel }}
                                style={{ marginBottom: "3rem" }}
                            />
                        </FormGroup>
                        <Button
                            className={classes.button}
                            rightIcon={Images.arrow}
                            onClick={button3Handler}
                        >
                            Folytatás
                        </Button>
                        <div className={classes.linkRow}>
                            <span
                                className={`${classes.link} ${classes.greenLink}`}
                                onClick={back3Handler}
                            >
                                Visszalépés
                            </span>
                        </div>
                    </div>
                    <div className={classes.RegistrationModalInner}>
                        <div className={classes.headerRow}>
                            <div className={classes.line}></div>
                            <span className={classes.header}>Elkészült</span>
                            <div className={classes.line}></div>
                        </div>
                        <div className={classes.circle}>
                            <CheckIcon
                                style={{
                                    fontSize: "5rem",
                                    color: Colors.green,
                                }}
                            />
                        </div>
                        <p className={classes.infoText}>
                            Létrehoztuk új felhasználói fiókod:
                        </p>
                        <p className={classes.registeredEmail}>
                            {userData.email}
                        </p>
                        <p
                            className={classes.infoText}
                            style={{ marginBottom: "10rem" }}
                        >
                            Kérjük kattints az Emailben elküldött megerősítő
                            linkre, hogy minden szolgáltatásunk elérhetővé
                            váljon a számodra. A személyes adataid és
                            elérhetőségeid bármikor megváltoztathatod a{" "}
                            <Link
                                to={i18nRoute("routes.profile.base")}
                                className={classes.link}
                            >
                                Fiókbeállításokban.
                            </Link>
                        </p>
                        <Button
                            onClick={button4Handler}
                            className={classes.button}
                            rightIcon={Images.arrow}
                        >
                            Folytatás
                        </Button>
                    </div>
                </Slider>
            </div>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
    toggleRegistrationModal: (show) =>
        dispatch(actions.toggleRegistrationModal(show)),
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
});

export default connect(null, mapDispatchToProps)(RegistrationModal);
