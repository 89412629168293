import { Route, Routes } from "react-router-dom";
import Profile from "../../components/Facelift/pages/Profile";
import Events from "../../components/Facelift/pages/Events";
import Messages from "../../components/Facelift/pages/Messages";
import Appointments from "../../components/Facelift/pages/Appointments";
import Webshop from "../../components/Facelift/pages/Webshop";
import NewPrescriptionRedeem from "../../components/Facelift/pages/NewPrescriptionRedeem";
import PrescriptionRedeems from "../../components/Facelift/pages/PrescriptionRedeems";
import UserSettings from "./UserSettings/UserSettings";
import classes from "./NewMyProfile.module.css";
import NotificationSettings from "./NotificationSettings/NotificationSettings";
import Invite from "./Invite/Invite";
import Security from "./Security/Security";
import PaymentSettings from "./PaymentSettings/PaymentSettings";

const NewMyProfile = () => (
    <div className={classes.MyProfile}>
        <Routes>
            <Route path="" element={<Profile />} />
            <Route path="user" element={<UserSettings />} />
            <Route path="invite" element={<Invite />} />
            <Route path="notifications" element={<NotificationSettings />} />
            <Route path="elozmenyek" element={<Events />} />
            <Route path="gyogyszer-kiszallitas">
                <Route index element={<Webshop />} />
                <Route path="igenyek" element={<PrescriptionRedeems />} />
            </Route>
            <Route path="recept-kivaltas" element={<NewPrescriptionRedeem />} />
            <Route path="uzenetek" element={<Messages />} />
            <Route path="idopontok">
                <Route index element={<Appointments />} />
                <Route path=":reservationId" element={<Appointments />} />
            </Route>
            <Route path="security" element={<Security />} />
            <Route path="payment-methods" element={<PaymentSettings />} />
        </Routes>
    </div>
);

export default NewMyProfile;
