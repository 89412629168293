import * as actionTypes from "../actions/actionTypes";

const initialState = {
    postLoad: {},
    preLoad: {},
};

const setPostLoadFunction = (state, action) => ({
    ...state,
    postLoad: {
        ...state.postLoad,
        [action.fncName]: action.fnc,
    },
});

const reducer = (state = initialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case actionTypes.SET_POST_LOAD_FUNCTION:
            return setPostLoadFunction(state, action);
    }

    return state;
};

export default reducer;
