import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Line from "../../../../components/UI/Line/Line";
import { Images } from "../../../../assets/Theme";
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import Chbx from "../../../../components/UI/Checkbox/Checkbox";
import { endLoading, post } from "../../../../config/fetch";
import { ALERT_MESSAGES } from "../../../../config/variables";
import * as actions from "../../../../store/actions/index";
import { responseMessage } from "../../../../utility";
import NotificationWarning from "../../../../components/UI/NotificationWarning/NotificationWarning";
import useTranslation from "../../../../features/i18n/useTranslation";
import classes from "./CardInfos.module.css";

const CardRow = (props) => (
    <Grid
        container
        justifyContent="space-between"
        style={{ marginBottom: "2rem" }}
    >
        <Grid item xs={12}>
            <Grid container>
                {props.editing && (
                    <Grid item sm={1} xs={12}>
                        <DeleteIcon
                            style={styles.deleteIcon}
                            className={classes.icon}
                            onClick={props.onDeleteCardClick}
                        />
                    </Grid>
                )}
                <Grid item sm={4} xs={12}>
                    <p
                        className={classes.cardText}
                        style={{ fontWeight: props.default ? "bold" : "" }}
                    >
                        {props.name}
                        {props.default ? (
                            <span>{` (alapértelmezett)`}</span>
                        ) : null}
                    </p>
                </Grid>
                {props.editing && !props.default && (
                    <Grid item sm={7} xs={12}>
                        <span
                            className={classes.setDefault}
                            onClick={props.onSetDefaultClick}
                        >
                            Beállítás alapértelmezettként
                        </span>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                <p className={classes.cardText}>
                    Hozzáadva:{" "}
                    {props.createdAt.replace(/-/g, ".").substr(0, 11)}
                </p>
            </Grid>
        </Grid>
    </Grid>
);

const CardInfos = (props) => {
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);
    const [cards, setCards] = useState(props.cards);
    const [termsAccepted, toggleTerms] = useState(false);
    const [terms2Accepted, toggleTerms2] = useState(false);
    const [newCardName, setNewCardName] = useState(null);
    const [addingCard, setAddingCard] = useState(false);
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        setCards(props.cards);
    }, [props.cards]);

    const deleteCardHandler = (token) =>
        post("api/v1/payment/delete-card", { token })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres törlés!");
                        const modifiedCards = cards.filter(
                            (c) => c.tdoc_token !== token
                        );
                        setCards(modifiedCards);
                        setEditing(false);

                        break;
                    }
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const deleteCardClick = (token, isDefault) => {
        let content;
        if (isDefault) {
            content =
                "Biztosan törli a kártyát? Törlés esetén, amennyiben van, a következő felvitt kártyája lesz az alapértelmezett kártya. Ezt bármikor módosíthatja.";
        } else {
            content = "Biztosan törli a kártyát? Ez nem visszavonható!";
        }
        props.deleteCard(token, content, deleteCardHandler);
    };

    const setDefaultClick = (token) =>
        post("api/v1/payment/set-default-card", { token })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK": {
                        const modifiedCards = cards.map((c) => {
                            if (c.tdoc_token === token) {
                                return { ...c, defaultCard: 1 };
                            }

                            return { ...c, defaultCard: 0 };
                        });
                        modifiedCards.sort(
                            (a, b) => b.defaultCard - a.defaultCard
                        );
                        responseMessage("Sikeres beállítás!");
                        setCards(modifiedCards);
                        setEditing(false);

                        break;
                    }
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const initPayment = () => {
        localStorage.setItem(
            "newCardName",
            (newCardName && newCardName.trim()) || "Bankkártya"
        );
        post("api/v1/payment/add-card")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        window.location.href = response.data.url;

                        break;
                    case "MISSING_DATA":
                        responseMessage(
                            "Hiányzó számlázási adatok! Kérjük, először számlázási adataid add meg.",
                            null,
                            () =>
                                navigate(
                                    i18nRoute("routes.profile.paymentMethods")
                                )
                        );

                        break;
                    case "MISSING_PERSONAL_DATA":
                        responseMessage(
                            "Hiányzó személyes adatok! Kérjük, először személyes adataid add meg.",
                            null,
                            () => navigate(i18nRoute("routes.profile.user"))
                        );

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                endLoading();
                console.error(error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const addButtonHandler = () => {
        if (!termsAccepted || !terms2Accepted) {
            responseMessage(
                "Az adattovábbítási- és az ismétlődő fizetésről történő nyilatkozat elfogadása kötelező!"
            );
        } else {
            initPayment();
        }
    };

    const addCardElement = (
        <div className={classes.addCardElement}>
            <p className={classes.label}>
                Kártya elnevezése a könnyebb azonosítás miatt, pl. „Apa
                kártyája”
            </p>
            <Input
                placeholder="Kártya elnevezése"
                className={classes.cardName}
                onChange={(e) => setNewCardName(e.target.value)}
            />
            <Chbx
                onChange={(checked) => toggleTerms(checked)}
                label={
                    <p>
                        Elfogadom, hogy a T-DOC Service Kft. (1138 Budapest,
                        Népfürdő utca 22. B. ép. 15. em.) által a www.t-doc.hu
                        (a továbbiakban kereskedő) felhasználói adatbázisában
                        tárolt alábbi személyes adataim átadásra kerüljenek a
                        BIG FISH Payment Services Kft. (székhely: 1066 Budapest,
                        Nyugati tér 1-2.), mint adatfeldolgozó részére. A
                        továbbított adatok köre: név-előtag, vezetéknév,
                        keresztnév, IP-cím, számlázási cím, szállítási cím,
                        telefonszám, e-mail cím, bankkártyaszám első hat és
                        utolsó négy számjegye, bankkártya márkája. Az
                        adattovábbítás célja: a fizetési tranzakciókhoz
                        szükséges adatkommunikáció lebonyolítása a kereskedő és
                        a fizetési szolgáltató rendszere között, illetve az
                        adatok tárolása a tranzakciós logokban a tranzakciós
                        adatok kereskedő részére történő visszakereshetőségének
                        biztosítása céljából. Az adatfeldolgozó által végzett
                        adatfeldolgozási tevékenység jellege és célja a
                        SimplePay Adatkezelési tájékoztatóban, az alábbi linken
                        tekinthető meg:{" "}
                        <a
                            href="http://simplepay.hu/vasarlo-aff"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            http://simplepay.hu/vasarlo-aff
                        </a>
                    </p>
                }
            />
            <Chbx
                onChange={(checked) => toggleTerms2(checked)}
                label={
                    <p>
                        Az ismétlődő bankkártyás fizetés (továbbiakban:
                        „Ismétlődő fizetés”) egy, a SimplePay által biztosított
                        bankkártya elfogadáshoz tartozó funkció, mely azt
                        jelenti, hogy a Vásárló által a regisztrációs tranzakció
                        során megadott bankkártyaadatokkal a jövőben újabb
                        fizetéseket lehet kezdeményezni a bankkártyaadatok
                        újbóli megadása nélkül.
                        <br />
                        Az Ismétlődő fizetés igénybevételéhez jelen nyilatkozat
                        elfogadásával Ön hozzájárul, hogy a sikeres
                        regisztrációs tranzakciót követően jelen webshopban
                        (https://t-doc.hu) kezdeményezett későbbi fizetések a
                        bankkártyaadatok újbóli megadása és az Ön
                        tranzakciónként hozzájárulása nélkül a Kereskedő által
                        kezdeményezve történjenek. <br />
                        Figyelem(!): a bankkártyaadatok kezelése a
                        kártyatársasági szabályoknak megfelelően történik. A
                        bankkártyaadatokhoz sem a Kereskedő, sem a SimplePay nem
                        fér hozzá.
                        <br />
                        A Kereskedő által tévesen vagy jogtalanul kezdeményezett
                        ismétlődő fizetéses tranzakciókért közvetlenül a
                        Kereskedő felel, Kereskedő fizetési szolgáltatójával
                        (SimplePay) szemben bármilyen igényérvényesítés kizárt.
                        <br />
                        Jelen tájékoztatót átolvastam, annak tartalmát tudomásul
                        veszem és elfogadom.
                    </p>
                }
            />
            <p className={classes.infoLabel}>
                Kártyája hozzáadásához 100 Ft-ot terhelünk egyenlegére, melyet
                rögtön vissza is térítünk.
            </p>
            <Button
                rightIcon={Images.arrow}
                style={styles.addButton}
                onClick={addButtonHandler}
            >
                <span style={styles.buttonText}>Hozzáadás</span>
            </Button>
            <div style={{ textAlign: "center", marginTop: "3rem" }}>
                <img
                    className={classes.paymentImage}
                    src={Images.simple}
                    alt=""
                />
                <img
                    className={classes.paymentImage}
                    src={Images.bigfish}
                    alt=""
                />
                <p className={classes.paymentInfo}>
                    Az online fizetést az OTP Simple Pay és a Big Fish Payment
                    Gateway biztosítja.
                </p>
            </div>
        </div>
    );

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <div className={classes.titleContainer}>
                        {props.empty && (
                            <NotificationWarning
                                style={{ marginRight: "1rem" }}
                            />
                        )}
                        <p className={classes.title}>Bankkártya adatok</p>
                    </div>
                </Grid>
                <Grid item>
                    <p
                        className={classes.linkText}
                        onClick={() => {
                            setEditing((prev) => !prev);
                            setAddingCard(false);
                        }}
                    >
                        {!editing ? "Szerkesztés" : "Mégsem"}
                    </p>
                </Grid>
            </Grid>
            {cards.map((c) => (
                <CardRow
                    key={c.id}
                    editing={editing}
                    name={c.name}
                    default={c.defaultCard}
                    createdAt={c.created_at}
                    onDeleteCardClick={() =>
                        deleteCardClick(c.tdoc_token, c.defaultCard)
                    }
                    onSetDefaultClick={() => setDefaultClick(c.tdoc_token)}
                />
            ))}
            {cards.length === 0 && (
                <p className={classes.cardText}>Nincs megadva</p>
            )}
            {addingCard && editing && addCardElement}
            {!addingCard && editing && (
                <Button
                    rightIcon={Images.arrow}
                    style={styles.button}
                    onClick={() => setAddingCard(true)}
                >
                    <span style={styles.buttonText}>Új kártya hozzáadása</span>
                </Button>
            )}
            <Line style={{ marginTop: "4rem" }} />
        </>
    );
};

const styles = {
    deleteIcon: {
        color: "red",
        fontSize: "1.6rem",
        marginRight: "2rem",
    },
    button: {
        marginTop: "4rem",
        lineHeight: "3.2rem",
    },
    buttonText: {
        fontSize: "1.4rem",
    },
    addButton: {
        lineHeight: "3.2rem",
        marginLeft: "auto",
        marginRight: "auto",
    },
};

const mapStateToProps = (state) => ({
    cardName: state.ui.cardName,
});

const mapDispatchToProps = (dispatch) => ({
    deleteCard: (cardToken, content, confirmButtonCallback) =>
        dispatch(
            actions.toggleResponseModal(true, {
                title: "Megerősítés szükséges",
                content,
                cancelButton: true,
                confirmButtonCallback: () => confirmButtonCallback(cardToken),
            })
        ),
    setCardName: (name) => dispatch(actions.setCardName(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardInfos);
