import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Colors, Fonts, Icons, Images } from "../../../assets/Theme";
import ServicePage from "../../../components/UI/ServicePage/ServicePage";
import { endLoading, get, post } from "../../../config/fetch";
import * as actions from "../../../store/actions/index";
import { formatNumber, responseMessage } from "../../../utility";
import {
    ecommerceAddCart,
    ecommerceProductDetail,
} from "../../../config/ecommerce";
import { ALERT_MESSAGES } from "../../../config/variables";
import useTranslation from "../../../features/i18n/useTranslation";
import useAuth from "../../../features/auth/useAuth";
import useQuery from "../../../features/util/useQuery";

let price = null;
let subscription;

const Prescription = ({ toggleLoginModal }) => {
    const query = useQuery();
    const { isAuthenticated } = useAuth();
    const [packages, setPackages] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(1);
    const { i18nRoute } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            const promise1 = post(
                "api/v1/subscription/get-service-prices-by-user",
                { service: "PRESCRIPTION" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });
            const promise2 = post(
                "api/v1/subscription/check-user-service-price",
                { service: "PRESCRIPTION" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });
            Promise.all([promise1, promise2]).then((responses) => {
                endLoading();
                const response1 = responses[0];
                const response2 = responses[1];
                let _packages = processPackages(
                    response1.packages,
                    response1.subscription ? response1.subscription : 1,
                    response2.data
                );
                setPackages(_packages);
            });
        } else {
            post("api/v1/subscription/get-service-prices-by-user", {
                service: "PRESCRIPTION",
            }).then((response) => {
                endLoading();
                if (response.data.responseCode === "OK") {
                    let _packages = processPackages(
                        response.data.packages,
                        response.data.subscription
                            ? response.data.subscription
                            : 1
                    );
                    setPackages(_packages);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const processPackages = (packages, subscribedIndex, dynamicData = null) => {
        setSelectedPackage(+subscribedIndex);
        const _packages = [];
        const transactionId = query.get("TransactionId");
        //Ha fizetésről tér vissza, ne fusson le mégegyszer
        if (!transactionId) {
            if (dynamicData) {
                ecommerceProductDetail(
                    "PRESCRIPTION",
                    dynamicData.price,
                    dynamicData.userPackage
                );
                price = dynamicData.price;
                subscription = dynamicData.userPackage;
            } else {
                ecommerceProductDetail(
                    "PRESCRIPTION",
                    packages[0].price,
                    subscribedIndex
                );
                price = packages[0].price;
                subscription = subscribedIndex;
            }
        }
        packages.forEach((p, i) => {
            if (dynamicData && subscribedIndex - 1 == i) {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        dynamicData.price !== 0
                            ? `${formatNumber(dynamicData.price)} Ft`
                            : `Ingyenes`,
                    subtext:
                        dynamicData.price === 0 &&
                        dynamicData.remainingServices > 0
                            ? `Hátralévő: ${dynamicData.remainingServices}`
                            : "",
                });
            } else {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        p.freePerMonth > 0
                            ? `${p.freePerMonth} ingyenes`
                            : `${formatNumber(p.price)} Ft`,
                    subtext:
                        p.freePerMonth > 0
                            ? `*utána ${formatNumber(p.price)} Ft /beszélgetés`
                            : "",
                });
            }
        });

        return _packages;
    };

    const onClickButton = () => {
        checkAddress();
    };

    const onPackageClick = () => {
        if (isAuthenticated) {
            navigate(i18nRoute("routes.profile.paymentMethods"));
        } else {
            navigate("/elofizetesek");
        }
    };

    const checkAddress = async () => {
        try {
            const { data } = await get("api/v1/user/check-address");
            endLoading();

            switch (data.responseCode) {
                case "OK":
                    console.log("====", data.responseCode);
                    ecommerceAddCart("PRESCRIPTION", price, subscription);
                    navigate("/szolgaltatasok/e-recept/start");

                    break;

                case "ADDRESS_MISSING":
                    console.log("====", data.responseCode);
                    responseMessage(
                        "A szolgáltatás igénybevételéhez, és a recept felírásához kérünk add meg lakcím adataidat a saját fiókod, személyes adatok beállításaiban.",
                        null,
                        () => navigate(i18nRoute("routes.profile.user"))
                    );

                    break;

                default:
                    break;
            }
        } catch (error) {
            endLoading();

            if (error === "NOT_AUTHENTICATED" && !isAuthenticated) {
                toggleLoginModal(true);
            } else {
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            }
        }
    };

    return (
        <ServicePage
            icon={Icons.prescriptionGreen}
            name="E-recept"
            description="E-receptírás várakozás, sorban állás és felesleges utazás nélkül. A receptet elektronikusan felküldjük a felhőbe, mely segítségével gyógyszereid bármely gyógyszertárban át tudod venni. "
            image={Images.eRecept}
            imageAlt="E-recept | T-DOC"
            packages={packages}
            selectedPackage={selectedPackage}
            originalSubscription={selectedPackage}
            packageClick={onPackageClick}
            showImportantInfo={false}
            buttonText="Üzenet küldése"
            buttonCallback={onClickButton}
            // packageInfoText='* Eredeti ár: 9 990 Ft. A kedvezményes ár 2021.07.31-ig érvényes.'
            // asterisks={['*']}
            buttonDisabled={false}
        >
            <h1 style={{ ...styles.title, fontSize: "3rem" }}>E-recept</h1>

            <h2 style={styles.title}>
                Hogyan működik az e-receptírás szolgáltatásunk?
            </h2>
            <p style={styles.text}>
                Az e-receptírás funkció hasonlóan működik, mint az orvosi chat
                szolgáltatásunk. Az e-recept felírás igénylése során lehetőséged
                van megírni üzeneted/indoklásod az orvosnak, valamint feltölteni
                egy ambuláns szkennelt vagy befotózott példányát. Az
                e-receptigény beküldését követően kollégáink ellenőrzik, hogy
                valóban jogosult vagy-e az adott gyógyszerre. Amennyiben igen,
                úgy{" "}
                <span style={styles.strong}>
                    az e-receptet feltöltik a felhőbe,
                </span>{" "}
                amiről válaszüzenetben tájékoztatnak. Ezt követően már nincs más
                dolgod, mint ellátogatni{" "}
                <span style={styles.strong}>
                    a legközelebbi patikába, és kiváltanod a gyógyszereid.
                </span>
            </p>

            <h2 style={styles.title}>E-receptírás feltételei</h2>
            <p style={styles.text}>
                Az e-recept írásánál csakúgy, mint a papír alapú recept
                esetében, orvosaink a vényköteles gyógyszereket indokolt
                esetben, konzultációt követően írják fel. Abban az esetben
                kerülhet kiállításra az e-recept, ha:<br></br>
                1.) Az orvos személyes vagy videókonzultáció keretében úgy
                ítéli, hogy a gyógyszeres kezelés indokolt a páciens esetében.
                <br></br>
                2.) A páciens ambuláns lappal (hivatalos orvosi dokumentummal,
                pl. ambuláns lap, zárójelentés, háziorvos igazolás) igazolni
                tudja, hogy szakorvosi javaslatra, rendszeresen szednie kell az
                adott gyógyszert. A gyógyszerfelírás konzultációt követően
                történhet meg ez esetben is.<br></br>
                <br></br>
                Amennyiben kollégáink úgy ítélik meg, hogy a fentiek közül egyik
                sem valósult meg, első körben mindig „hiánypótlást” kérnek (pl.
                lemaradt csatolmány, indoklás, egyéb.). Amennyiben ez a
                hiánypótlás megtörténik, és az e-recept felírható,{" "}
                <span style={styles.strong}>
                    a felhőbe történő feltöltést követően azonnal jelezzük.
                </span>{" "}
                A jogosulatlan receptigény felírásának visszautasítását
                fenntartjuk.
            </p>
            <h2 style={styles.title}>
                Mit nem írunk fel erecept szolgáltatásunkon belül?
            </h2>
            <p style={styles.text}>
                - Szakorvosi javaslatra vagy szakorvosi/kórházi diagnózist
                követően járóbetegellátásban adható gyógyszerek<br></br>-
                Jogszabályban meghatározott kábítószerként és pszichotrop
                anyagként minősített gyógyszereket<br></br>- Tápszereket,
                gyógyászati segédeszközöket<br></br>
            </p>
            <h2 style={styles.title}>E-recept kiváltása</h2>
            <p style={styles.text}>
                Hogyan lehet kiváltani az e-receptet? Egyszerűen!<br></br>
                <span style={styles.strong}>
                    Az e-recept kiváltása egyszerűbb, mint azt gondolnánk.
                </span>{" "}
                A felhőbe feltöltött recepteket az összes gyógyszertár le tudja
                kérdezni, így bármelyikbe is térsz be, úgy ki tudod váltani a
                felírt gyógyszereket.{" "}
                <span style={styles.strong}>
                    A kiváltáshoz TAJ-kártyádra és személyazonosságodat igazoló
                    okmányra lesz szükséged.
                </span>{" "}
                Ezek felmutatásával a patikus jogosult a felírt gyógyszert
                kiadni.
            </p>
            <h2 style={styles.title}>E-recept kiváltása másnak</h2>
            <p style={styles.text}>
                Kiváltható-e az e-recept másnak is?{" "}
                <span style={styles.strong}>Igen, kiváltható.</span> Ennek három
                útja van.<br></br>
                1. A gyógyszerre jogosult páciens TAJ számának közlésével,
                valamint az e-receptet kiváltó személy azonosításával.<br></br>
                2. Az EESZT-ben rögzített meghatalmazás segítségével. Amennyiben
                a gyógyszerre jogosult páciens EESZT profilján rögzítve van
                meghatalmazott, úgy annak a TAJ számának ismeretével, és
                személyazonosításával együtt kiváltható a gyógyszer.<br></br>
                3. Az EESZT-ben rögzített törvényes képviseleti jog alapján.
                Amennyiben a kiváltó személy a törvényes képviselő, úgy a
                TAJ-szám közlésével és személyazonosságának igazolásával
                kiválthatja a képviselt páciens részére az e-recept segítségével
                felírt gyógyszereket.
            </p>
            <h2 style={styles.title}>
                Hogyan ellenőrizhetem, hogy felírták-e az e-receptet?
            </h2>
            <p style={styles.text}>
                Az elektronikusan, felhőbe felküldött e-recept ellenőrzése
                legalább olyan egyszerű, mint annak kiváltása. Ennek három módja
                lehetséges:<br></br>
                1. A www.eeszt.gov.hu lakossági portálra történő belépéssel,
                ahol az egészségügyi adatok menüpont alatt megtekinthetők a
                felírt receptek.<br></br>
                2. Bármely gyógyszertárban az e-recept kiváltásának feltételei
                mellett.<br></br>
                3. Orvos által, e-recept felírásáról való igazolás
                kiállításával. Ha egy ilyen igazolást kiállít az orvos, szinte
                biztosak lehetünk benne, hogy az e-receptünk a felhőben van, és
                kiváltható a gyógyszertárban.
            </p>
        </ServicePage>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontWeight: 700,
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
        marginTop: "3rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
        marginBottom: "1rem",
    },
    strong: {
        fontWeight: 700,
    },
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
});

export default connect(null, mapDispatchToProps)(Prescription);
