import { Grid } from "@material-ui/core";

const HeaderRow = () => (
    <Grid
        container
        style={{
            height: "50px",
            width: "95%",
            fontWeight: "bold",
        }}
    >
        <Grid
            item
            xs={12}
            md={4}
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            Gyógyszer neve
        </Grid>
        <Grid
            item
            xs={12}
            md={4}
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            Igénylés időpontja
        </Grid>
        <Grid
            item
            xs={12}
            md={4}
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            Státusz
        </Grid>
    </Grid>
);
export default HeaderRow;
