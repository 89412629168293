import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
// import { Attachment as AttachmentIcon } from "@material-ui/icons";
import { intlFormat } from "date-fns";
import cn from "classnames";
import Tag from "../atoms/Tag";
import Avatar from "../atoms/Avatar";
import classes from "./EventRow.module.css";
import { EVENTS } from "../../../config/variables";
import { makeInitials } from "../../../utility";

const EventRowAppointment = ({ reservationData, created_at }) => {
    const navigate = useNavigate();
    if (!reservationData) {
        return null;
    }

    const handleMakeInitial = () => makeInitials(reservationData.doctorName);

    const handleRoute = () => {
        navigate(`/profile/idopontok/${reservationData.reservationId}`);
    };

    return (
        <Grid
            container
            className={cn(classes.row, classes.hasPointer, {
                [classes.unread]: false, // isUnread needs to check (chatData.patient_unread)
            })}
            onClick={handleRoute}
        >
            <Grid item xs={12}>
                <div className={classes.innerRow}>
                    <div className={classes.avatar}>
                        <Avatar
                            size="xl"
                            initials={handleMakeInitial()}
                            // source="https://randomuser.me/api/portraits/men/36.jpg"
                        />
                    </div>
                    <div className={classes.content}>
                        <div className={classes.bar}>
                            <div className={classes.info}>
                                <h3 className={classes.title}>
                                    {reservationData.doctorName}
                                </h3>
                                <div className={classes.date}>
                                    <p>
                                        {intlFormat(
                                            new Date(created_at),
                                            {
                                                weekday: "long",
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            },
                                            {
                                                locale: "hu-HU",
                                            }
                                        )}
                                    </p>
                                </div>
                                <div className={classes.indicators}>
                                    <div className={classes.tag}>
                                        <Tag variant="outlined">
                                            {EVENTS[reservationData.type]}
                                        </Tag>
                                    </div>
                                    {/*
                                    <div className={classes.tag}>
                                        <Tag
                                            variant="outlined"
                                            icon={<AttachmentIcon />}
                                        >
                                            1
                                        </Tag>
                                    </div>
                                    */}
                                    <div className={classes.text}>
                                        <p>
                                            Azonosító:{" "}
                                            {reservationData.reservationId}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.date}>
                                <p>
                                    {intlFormat(
                                        new Date(created_at),
                                        {
                                            weekday: "long",
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        },
                                        {
                                            locale: "hu-HU",
                                        }
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className={classes.text}>
                            <p>
                                {reservationData.specializationName} -{" "}
                                {intlFormat(
                                    new Date(reservationData.reservationTime),
                                    {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                    },
                                    {
                                        locale: "hu-HU",
                                    }
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default EventRowAppointment;
