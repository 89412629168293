import { useEffect } from "react";
import useQuery from "../../features/util/useQuery";
import { getStorageValue, setStorageValue } from "../../features/util/storage";

const KEY = "humed";

export const useHumed = () => {
    const query = useQuery();
    const isHumedInQuery = query.has(KEY);

    useEffect(() => {
        if (!isHumedInQuery) {
            return;
        }

        setStorageValue(KEY, true, "session");
    }, [isHumedInQuery]);

    return isHumedInQuery || getStorageValue(KEY, "session");
};
