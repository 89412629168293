import classes from "./classes.module.css";

const ConnectDevice = () => (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
        <h3 className={classes.title} id="meres-gyakorisaga">
            Hányszor kell mérést végeznem?
        </h3>
        <p className={classes.text}>
            Minden méréstípus, például a vércukor aloldalon, szerepel egy
            alapértelmezett mérési protokoll, amelyet kezelőorvosod szabadon
            felülbírálhat. Minden esetben kövesd a kezelőorvosod utasításait.
        </p>

        <h3 className={classes.title} id="grafikon-megjelenese">
            Nem látom az eszközzel végzett mérésemet a grafikonon, mi a teendő?
        </h3>
        <p className={classes.text}>
            Alapesetben az eszközzel végzett mérések pár percen belül
            megjelennek a grafikonon, illetve a mérési listában. Egyes esetekben
            előfordulhat, hogy 15 percre is szükség van, amire az adatok
            megjelennek a T-DOC rendszerében. Utóbbi eset a felhőalapú
            szolgáltatóknál fordulhat elő gyakrabban. Bluetooth eszköz esetén,
            amennyiben az csatlakoztatva van a telefonnal, pár másodpercen belül
            szinkronizálódnak az adatok.
            <br />
            <br />
            Kérlek ellenőrizd, hogy a telefonodon van-e internet elérés, illetve
            engedélyezve van-e a Bluetooth kapcsolat.
        </p>

        <h3 className={classes.title} id="meres-modositasa">
            Módosíthatom utólag az elvégzett mérés eredményét?
        </h3>
        <p className={classes.text}>
            Okos- vagy orvostechnikai eszközzel végzett mérés módosítására nincs
            lehetőség, ugyanakkor a kézi adatrögzítést utólag is tudod
            töröltként megjelölni, majd újra rögzítheted a helyes mérési
            eredményt.
        </p>

        <h3 className={classes.title} id="vevcukor-veglegesites">
            A vércukor mérés után miért kell véglegesítenem a mérésemet?
        </h3>
        <p className={classes.text}>
            Vércukor mérés, az előírt protokoll alapján, naponta többször
            történik, ugyanakkor nem mindegy, hogy a mérésre ténylegesen mikor
            került sor, étkezés előtt, vagy étkezést követően. A mérés ideje
            befolyásolja a vércukorszint alakulását és így a helyes
            kiértékelését is.
        </p>

        <h3 className={classes.title} id="adatok-letoltese">
            Csak vérnyomásnál és vércukornál tudom letölteni az adatokat, ez
            miért van?
        </h3>
        <p className={classes.text}>
            A T-DOC lehetőséget biztosít a papíralapú vérnyomás- és vércukor
            napló kiváltására. Orvosi szempontból ezek szerepe kiemelkedő, így
            első körben ezek letöltésére nyílik lehetőség.
            <br />
            <br /> A funkció jelenleg csak a t-doc.hu webes felületen érhető el.
        </p>

        <h3 className={classes.title} id="tobb-eszkoz">
            Mi történik, ha több kompatibilis eszközzel is végzek mérést?
        </h3>
        <p className={classes.text}>
            Természetesen nem probléma, ha több kompatibilis eszközt is
            csatlakoztatsz a fiókodhoz, és az sem jelent gondot, ha egy-egy
            méréstípust például véroxigénszintet több eszköz is rögzít. A
            mérések feldolgozása a háttérben többszörösen összetett algoritmus
            alapján történik, amely során kiszűrésre kerülnek a duplikációk,
            valamint a mérési pontosság alapján meghatározásra kerül az eszközök
            prioritása is.
        </p>
        <h3 className={classes.title} id="manualis-rogzites">
            Kézzel is rögzíthetem a méréseimet?
        </h3>
        <p className={classes.text}>
            Természetesen, néhány méréstípushoz tartozó mérést kézzel is
            rögzíthetsz. Ez abban az esetben lehet járható út, ha egyik eszközöd
            sem kompatibilis az Adatalapú telemedicina szolgáltatással, de
            kezelőorvosoddal szeretnéd megosztani a külső eszközből származó
            adatokat.
        </p>

        <h3 className={classes.title} id="manualis-rogzites-meresek">
            Milyen méréseket vihetek fel manuálisan is?
        </h3>
        <p className={classes.text}>
            Kézzel az alábbi, külső mérőeszközből származó adatokat tudod
            rögzíteni a T-DOC-ban, amennyiben nincs kompatibilis eszközöd:
        </p>
        <ul className={classes.list}>
            <li>testsúly (kg)</li>
            <li>testmagasság (cm)</li>
            <li>testhőmérséklet (°C)</li>
            <li>vérnyomás (Hgmm)</li>
            <li>vércukor (mmol/L)</li>
            <li>véroxigénszint SpO2 (%)</li>
        </ul>
        <h3 className={classes.title} id="manualis-adatrogzites-hol">
            Hol tudom a külső mérésemet kézzel megadni?
        </h3>
        <p className={classes.text}>
            Külső eszközből származó méréseidet az adott méréstípus oldalán a
            Manuális adatrögzítés gombra kattintva tudod megtenni. Például, ha
            vércukor értékedet szeretnéd kézzel rögzíteni, akkor azt a vércukor
            aloldalon lesz lehetőséged rögzíteni. Ennek elérése: Analízis
            központ / Egészség / Vércukor kártya / Manuális adatrögzítés.
            <br />
            <br />
            Felhívjuk figyelmedet, hogy csak aktív előfizetés esetén éred el a
            menüpontot.
        </p>

        <h3 className={classes.title} id="manualis-adatrogzites-riasztasok">
            Kézzel rögzített mérésekre vonatkozóan is kaphatok riasztásokat?
        </h3>
        <p className={classes.text}>
            Természetesen igen. De a grafikonokon, illetve a mérések listában
            ezeket az értékeket eltérő színnel és címkével jelenítjük meg
            számodra.
        </p>

        <h3 className={classes.title} id="manualis-rogzites-grafikon">
            A grafikonon máshogy jelenik meg a kézzel rögzített mérés, ez mit
            jelent?
        </h3>
        <p className={classes.text}>
            Az eltérő vizuális megjelenés mindössze annyit jelent, hogy segítsen
            megkülönböztetni a manuálisan rögzített méréseket számodra és a
            kezelőorvosod számára.
        </p>
        <h3 className={classes.title} id="orvosi-kiertekeles-riasztasok">
            Mi a különbség az orvosi kiértékelés és az aktuális riasztások
            között?
        </h3>
        <p className={classes.text}>
            Az orvosi kiértékeléseket a Kiértékelések menüpontban találod. Ezen
            szakvéleményeket minden esetben a kezelőorvosod készíti el, és
            általában több mérés figyelembevételével állapítja meg a diagnózist.
            <br />
            <br />
            Ezzel szemben a riasztásokhoz és figyelmeztetésekhez kapcsolódó
            automatikus kiértékelést (elemzést) a rendszer automatikusan és
            mérésenként állapítja meg a Nálad szereplő, vagyis a személyre
            szabott riasztási szintek alapján. Az egyes mérésekhez tartozó
            riasztási címkéket a mérések listában találod.
        </p>

        <h3 className={classes.title} id="kiertekeles-kerdes">
            Kérdésem van a kiértékeléssel kapcsolatban, kit kereshetek?
        </h3>
        <p className={classes.text}>
            Amennyiben kérdésed van a kezelőorvosod által készített
            kiértékeléssel kapcsolatban, akkor a T-DOC Egészségügyi hotline
            csapata áll rendelkezésedre.
        </p>
        <h3 className={classes.title} id="megjegyzes-lathatosaga">
            A megjegyzéseimet ki láthatja?
        </h3>
        <p className={classes.text}>
            Megjegyzéseket a Téged ellátó orvosok, illetve szükség esetén az
            Egészségügyi hotline tekintheti meg.
        </p>

        <h3 className={classes.title} id="megjegyzes-orvosnak">
            Tudok megjegyzést írni egy konkrét orvosnak is?
        </h3>
        <p className={classes.text}>
            Nem tudsz egy konkrét orvosnak címezni egy megjegyzést.
            Konzultációhoz válaszd a chat, vagy a videókonzultáció
            szolgáltatásokat.
        </p>

        <h3 className={classes.title} id="korabbi-megjegyzesek">
            Hol találom a korábbi megjegyzéseket?
        </h3>
        <p className={classes.text}>
            A megjegyzéseket az adott méréstípus oldalán tudod visszanézni,
            ehhez használhatod a jobb felső sarokban lévő dátumválasztót.
        </p>
        <h3 className={classes.title} id="papir-alapu-vernyomas-vercukornaplo">
            Papír alapon is kérte tőlem az orvosom, hol tudom letölteni?
        </h3>
        <p className={classes.text}>
            Amennyiben kezelőorvosod papíralapú verziót is kért tőled, akkor
            természetesen azokat letöltheted az adott méréstípus pl. vérnyomás
            aloldalról, az „Adatok letöltése” gombra kattintva. Kérlek vedd
            figyelmedbe, hogy a letöltés azokat a méréseket és megjegyzéseket
            fogja tartalmazni, amely időintervallumot előzetesen beállítottál a
            grafikon feletti dátumválasztónál.
        </p>
        <h3 className={classes.title} id="idointervallum-vercukor">
            Milyen időintervallumra vonatkozóan tudom letölteni a
            vérnyomás/vércukornaplót?
        </h3>
        <p className={classes.text}>
            Szabadon meghatározhatod a letölteni kívánt naplók időintervallumát,
            amely történhet napi, heti, vagy havi nézetben is, amelyet a
            grafikon feletti dátumválasztónál tudsz beállítani.
        </p>
        <h3 className={classes.title} id="megjegyzes-vercukor">
            Hogyan tudok megjegyzést rögzíteni a vérnyomás/vércukornaplóba?
        </h3>
        <p className={classes.text}>
            Megjegyzéseket az adott méréstípus pl. vércukor aloldalán tudsz
            rögzíteni, amely megjegyzés megjelenik a letöltött naplóban is.
        </p>
    </>
);

export default ConnectDevice;
