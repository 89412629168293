import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Client } from "twilio-chat";
import { useNavigate } from "react-router-dom";
import Textarea from "../../components/UI/Textarea/Textarea";
import UploadedFile from "../../components/UI/UploadedFile/UploadedFile";
import Button from "../../components/UI/Button/Button";
import { Icons } from "../../assets/Theme";
import { endLoading, post, startLoading } from "../../config/fetch";
import { formatNumber, responseMessage } from "../../utility";
import { ALERT_MESSAGES, PAYMENT } from "../../config/variables";
import * as actions from "../../store/actions/index";
import PaymentModal from "../../components/Modals/PaymentModal/PaymentModal";
import { ecommercePurchase } from "../../config/ecommerce";
import Btn from "../../components/UI/Btn/Btn";
import SubscriptionAndServiceModal from "../../components/Modals/SubscriptionAndServiceModal/SubscriptionAndServiceModal";
import useQuery from "../../features/util/useQuery";
import PrescriptionWindow from "../PrescriptionWindow/PrescriptionWindow";
import classes from "./PrescriptionRoom.module.css";

let chatClient;
let currentChannel;
let file;
let channelSID;
let doctorName;
let participants;

const PrescriptionRoom = (props) => {
    const navigate = useNavigate();
    const query = useQuery();
    const identifier = props.identifier;

    const [messages, setMessages] = useState([]);
    const messagesRef = useRef();
    const inputRef = useRef();
    const fileInputRef = useRef();
    const [fileName, setFileName] = useState("");
    const [channelClosed, setChannelClosed] = useState(false);
    const [paymentModal, togglePaymentModal] = useState(false);
    const [userSubscribed, setUserSubscribed] = useState(false);
    const [chatPayed, setChatPayed] = useState(true);
    const [paymentPending, setPaymentPending] = useState(false);
    const [price, setPrice] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(1);
    const [originalSubscription, setOriginalSubscription] = useState(false);
    const [subscriptionAndServiceModalData, setSubscriptionAndServiceModal] =
        useState({
            freeOccasions: null,
            servicePrices: [],
            service: null,
            chatIdentifier: null,
        });

    const [withSubscriptionPrice, setWithSubscriptionPrice] = useState(false);

    const [reopenState, setReopenState] = useState(0);

    messagesRef.current = messages;

    const openPayModal = (price) => {
        if (price !== null) {
            props.toggleResponseModal(true, {
                renderContent: unPayedBody(),
                title: `Fizetés (${formatNumber(price)} Ft)`,
                confirmButton: false,
                disableBackdropClick: false,
            });
        }
    };

    useEffect(() => {
        const transactionId = query.get("TransactionId");
        //Belépés a channelbe, acces token kérés
        post("api/v1/prescription/enter-channel", { identifier })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        //Access token alapján csatlakozás a service-hez
                        channelSID = response.data.channelSID;
                        doctorName = response.data.doctor;
                        participants = response.data.participants;
                        setOriginalSubscription(+response.data.subscription);
                        createClient(response.data.accessToken);

                        if (response.data.payed == "0") {
                            setSubscriptionAndServiceModal({
                                freeOccasions: null,
                                servicePrices: [],
                                service: "PRESCRIPTION",
                                chatIdentifier: identifier,
                            });
                            setChatPayed(false);
                            setPaymentPending(true);
                            setChannelClosed(true);
                            setPrice(response.data.price);
                            if (
                                !transactionId &&
                                !response.data.withSubscription
                            ) {
                                setSelectedPackage(+response.data.subscription);
                                openPayModal(response.data.price);
                            } else if (
                                !transactionId &&
                                response.data.withSubscription
                            ) {
                                setSelectedPackage(
                                    +response.data.withSubscription
                                        .desiredPackageId
                                );
                                setWithSubscriptionPrice(
                                    response.data.withSubscription.summaryAmount
                                );
                                props.toggleResponseModal(true, {
                                    renderContent: unPayedBodyWithSubscription(
                                        response.data.price,
                                        response.data.withSubscription
                                            .summaryAmount
                                    ),
                                    title: `Fizetés szükséges`,
                                    confirmButton: false,
                                    disableBackdropClick: false,
                                });
                            }
                        }
                        switch (+response.data.status) {
                            case 0:
                                break;
                        }

                        break;

                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();

                        break;
                }
                // startButtonHandler(true, response.data.status !== '2' ? 'CHAT' : 'SECOND_OPINION');
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        paymentCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setEverythingToOpen = (transactionId = false) => {
        const _service = query.get("service");
        const payedAmount = query.get("price");
        const _subscription = query.get("subscription");
        setReopenState(0);
        setChatPayed(true);
        setPaymentPending(false);
        setChannelClosed(false);
        endLoading();
        if (transactionId) {
            responseMessage(succesFullResponseText(transactionId));
        } else {
            responseMessage("Sikeres tranzakció!");
        }
        ecommercePurchase(_service, payedAmount, _subscription);
    };

    const paymentCallback = () => {
        if (query.has("TransactionId")) {
            const transactionId = query.get("TransactionId");
            const immediateToken = query.get("immediateToken");
            const oneClickEnabled = query.get("oneClickEnabled");
            post("api/v1/payment/payment-init-callback", {
                transactionId,
                cardName: "Bankkártya",
                immediateToken,
                oneClick: oneClickEnabled,
                identifier,
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            setEverythingToOpen(
                                response.data.ProviderTransactionId
                            );

                            break;
                        case "PAYMENT_ERROR":
                            switch (response.data.errorCode) {
                                case "ERROR":
                                    responseMessage(
                                        unSuccesfullResponseText(
                                            response.data.ProviderTransactionId
                                        )
                                    );
                                    setPaymentPending(false);
                                    endLoading();

                                    break;
                                case "CANCELED":
                                    responseMessage(
                                        "Megszakítottad a fizetési folyamatot. Próbáld meg újra.",
                                        null,
                                        () => window.location.reload()
                                    );
                                    setPaymentPending(false);
                                    endLoading();

                                    break;
                                case "TIMEOUT":
                                    responseMessage(
                                        "Túllépted a tranzakció elindításakor megengedett biztonságos időkaput. Próbáld meg újra."
                                    );
                                    setPaymentPending(false);
                                    endLoading();

                                    break;
                                case "PENDING":
                                    startLoading();
                                    setPaymentPending(true);
                                    setTimeout(paymentCallback, 3000);

                                    break;
                            }

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                            endLoading();
                    }

                    localStorage.removeItem("newCardName");
                    navigate(`/elozmenyek/e-recept/${identifier}`, {
                        replace: true,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    navigate(`/elozmenyek/e-recept/${identifier}`, {
                        replace: true,
                    });
                });
        }
    };

    const succesFullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikeres tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
        </>
    );

    const unSuccesfullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikertelen tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
            <p style={{ marginBottom: "1rem" }}>
                Kérjük, ellenőrizze a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adott meg, a
                visszautasítás okának kivizsgálása érdekében kérjük,
                szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
            </p>
        </>
    );

    const createClient = (accessToken) =>
        Client.create(accessToken)
            .then((client) => {
                chatClient = client;
                //Aktuális channel kikeresése
                getUserChannels();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const paginatorLoop = (paginator, channels) => {
        if (paginator.hasNextPage) {
            paginator.nextPage().then((result) => {
                paginator = result;
                channels = [...channels, ...result.items];
                paginatorLoop(paginator, channels);
            });
        } else {
            matchChannels(channels);
        }
    };

    const getUserChannels = () =>
        chatClient.getUserChannelDescriptors().then(function (paginator) {
            let channels = [];
            channels = [...channels, ...paginator.items];
            //Az aszinkron függvény egészen addíg hívja meg önmagát az új result függvényében, amíg a result szerint nincs több channel
            paginatorLoop(paginator, channels);
        });

    const matchChannels = (channels) => {
        for (let i = 0; i < channels.length; i++) {
            const channel = channels[i];
            if (channel.sid === channelSID) {
                channel
                    .getChannel()
                    // eslint-disable-next-line no-loop-func
                    .then((response) => {
                        // eslint-disable-next-line no-loop-func
                        currentChannel = response;
                        // console.log(currentChannel);
                        if (response.attributes.closed) {
                            setChannelClosed(true);
                            //Channel üzeneteinek lekérése
                            getMessages(true);
                        } else {
                            //Channel üzeneteinek lekérése
                            getMessages();
                        }

                        //Beérkező üzenet eseménykezelője
                        // eslint-disable-next-line no-loop-func
                        currentChannel.on("messageAdded", (message) => {
                            // eslint-disable-next-line no-loop-func
                            addMessage(message);
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                    });
            }
        }
    };

    const getMessages = (isClosed = false) =>
        currentChannel.getMessages().then((response) => {
            endLoading();
            if (isClosed) {
                let messages = response.items;
                const closedMessage = {
                    type: "closed",
                    sid: channelSID,
                    author: doctorName,
                    dateCreated: new Date(),
                    body: `${doctorName} lezárta a beszélgetést.`,
                };
                const test = [...messages, closedMessage];
                setMessages(test);
            } else {
                setMessages(response.items);
            }
        });

    let addMessage = (message) =>
        setMessages([...messagesRef.current, message]);

    const sendMessage = (message) => {
        if (fileName !== "") {
            const formData = new FormData();
            formData.append("file", file);
            if (message) {
                currentChannel.sendMessage(message);
                setTimeout(() => currentChannel.sendMessage(formData), 500);
            } else {
                currentChannel.sendMessage(formData);
            }
        } else if (message) {
            currentChannel.sendMessage(message);
        }
    };

    const onSendButtonClick = () => {
        let message = inputRef.current.value;
        sendMessage(message);
        inputRef.current.value = null;
        setFileName("");
        file = null;
    };

    const onFileSelect = () => fileInputRef.current.click();

    const fileChange = (e) => {
        if (e?.target.files[0]) {
            file = e.target.files[0];
            setFileName(file.name);
        } else {
            setFileName("");
            file = null;
        }
    };

    const documentMessageComponent = () => {
        if (["image/png", "image/jpeg"].includes(file?.type)) {
            return (
                <div className={classes.documentMessageContainer}>
                    <img
                        src={URL.createObjectURL(file)}
                        className={classes.imageMessage}
                        alt=""
                    />
                    <span className={classes.uploadFileName}>{file.name}</span>
                    <span
                        className={classes.delete}
                        onClick={() => fileChange(null)}
                    >
                        Mégsem
                    </span>
                </div>
            );
        }

        return (
            <div className={classes.documentMessageContainer}>
                <img
                    src={Icons.attachmentBlack}
                    className={classes.iconMessage}
                    alt=""
                />
                <span className={classes.uploadFileName}>{file.name}</span>
                <span
                    className={classes.delete}
                    onClick={() => fileChange(null)}
                >
                    Mégsem
                </span>
            </div>
        );
    };

    const deleteUploadedFile = (message) =>
        props.toggleResponseModal(true, {
            content: "Biztosan törli a feltöltött fájlt?",
            confirmButtonText: "Törlés",
            confirmButtonCallback: () => onDeleteConfirm(message),
            cancelButton: true,
            cancelButtonText: "Mégsem",
        });

    const onDeleteConfirm = (message) => message.remove();

    const renderUploadedFiles = () => {
        let files = [];
        for (let m of messages) {
            if (m.type === "media") {
                files.push(
                    <UploadedFile
                        media={m.media}
                        key={m.media.filename}
                        name={m.media.filename}
                        className={classes.uploadedFile}
                        deleteEnabled={false}
                        onDeleteClick={() => deleteUploadedFile(m)}
                    />
                );
            }
        }

        return files;
    };

    const unPayedBodyButtonClick = () => {
        props.toggleResponseModal(false);
        togglePaymentModal(true);
    };

    const unPayedBody = () => (
        <>
            <p>
                A kezdeményezett üzenetváltást még nem fizetted ki, ezért ezt
                nem tudjuk az orvos felé továbbítani. Az alábbi gombra kattintva
                teheted ezt meg.
            </p>
            <div className={classes.statusChangeButtons}>
                <Button
                    className={classes.statusChangeButton}
                    text="Fizetés"
                    onClick={unPayedBodyButtonClick}
                ></Button>
            </div>
        </>
    );

    const justOpen = () => setReopenState(1);

    const reopenAndSubscribe = () => setReopenState(2);

    const unPayedBodyWithSubscription = (
        priceOnlyService = 0,
        priceWithSubscription = 0
    ) => (
        <>
            <p>
                A kezdeményezett üzenetváltást még nem fizetted ki, ezért ezt
                nem tudjuk az orvos felé továbbítani. Lehetőséged van ezt a
                jelenlegi előfizetésed keretein belül (
                {formatNumber(priceOnlyService)} Ft) megtenned, vagy a
                kiválasztott előfizetési csommaggal együtt (
                {formatNumber(priceWithSubscription)} Ft).
            </p>
            <Btn
                color="green"
                text="Csak a szolgáltatás fizetése"
                style={{ marginTop: "2rem" }}
                onClick={justOpen}
            />
            <Btn
                color="green"
                text="Előfizetés és szolgáltatás fizetése"
                style={{ marginTop: "2rem" }}
                onClick={() => reopenAndSubscribe()}
            />
        </>
    );

    const redeemCoupon = (code) => {
        if (code) {
            post("api/v1/coupon/redeem-coupon", { couponCode: code })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("A kupont sikeresen beváltottad!");
                            startButtonHandler(false, "PRESCRIPTION");

                            break;
                        case "WRONG_CODE":
                            responseMessage("Hibás kuponkód!");

                            break;
                        case "ALREADY_REDEEMED":
                            responseMessage(
                                "Ezt a kupont már beváltottad egyszer!"
                            );

                            break;
                        case "ONLY_WITHOUT_SUBSCRIPTION":
                            responseMessage(
                                "Ezt a kupont csak előfizetés nélkül lehet beváltani!"
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const startButtonHandler = (withoutPayment = false, service) =>
        post("api/v1/subscription/check-user-service-price", { service })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        setPrice(response.data.data.price);
                        setUserSubscribed(response.data.data.price < 1);
                        if (!withoutPayment) {
                            togglePaymentModal(true);
                        }

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });

    const payForChat = () => {
        const service = "PRESCRIPTION";
        post("api/v1/chat/pay-for-chat", {
            service,
            identifier,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres fizetés!");
                        setChatPayed(true);
                        setChannelClosed(false);
                        togglePaymentModal(false);
                        ecommercePurchase(
                            service,
                            price,
                            response.data.subscription
                        );
                        setReopenState(0);

                        break;
                    case "PAYMENT_FAILED":
                        responseMessage(PAYMENT.PAYMENT_FAILED);

                        break;
                    case "EMPTY_DEFAULT_CARD":
                        props.toggleServicePaymentModal(
                            true,
                            price,
                            service,
                            null,
                            identifier
                        );

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    return (
        <div className={classes.ChatRoom} style={props.style}>
            <PrescriptionWindow
                // secondOpinion={secondOpinion}
                identifier={props.identifier}
                // otherName={doctorName}
                messages={messages}
                author={props.userId}
                participants={participants}
            ></PrescriptionWindow>
            {!channelClosed && (
                <div className={classes.fileContainer}>
                    {renderUploadedFiles()}
                </div>
            )}
            {!channelClosed && (
                <>
                    <div className={classes.textareaContainer}>
                        <Textarea
                            rows={5}
                            placeholder="Üzenet"
                            ref={inputRef}
                            customRender={fileName !== ""}
                            customRenderFunction={documentMessageComponent}
                        />
                    </div>
                    <div className={classes.buttons}>
                        <input
                            type="file"
                            className={classes.uploadInput}
                            ref={fileInputRef}
                            onChange={fileChange}
                        />

                        <Button
                            className={classes.button}
                            onClick={onFileSelect}
                        >
                            <div className={classes.uploadButtonInner}>
                                <img
                                    src={Icons.attachmentGreen}
                                    className={classes.uploadIcon}
                                    alt=""
                                />
                                <span className={classes.buttonText}>
                                    Új dokumentum csatolása
                                </span>
                            </div>
                        </Button>
                        <Button
                            className={classes.sendButton}
                            onClick={onSendButtonClick}
                            text="Üzenet küldése"
                        />
                    </div>
                </>
            )}

            {!chatPayed && paymentPending && (
                <p className={classes.channelClosed}>
                    Fizetés folyamatban, kérjük várjon...
                </p>
            )}

            {!chatPayed && !withSubscriptionPrice && !paymentPending && (
                <p
                    className={classes.channelClosed}
                    onClick={() => setReopenState(1)}
                >{`Az üzenetküldés a meghiúsult bankkártyás fizetés miatt sajnos sikertelen volt. Kérjük próbáld meg újra. Sikeres fizetést követően a kiválasztott szakorvos azonnal megkapja üzeneted. Fizetendő összeg: ${
                    price ? formatNumber(price) : ""
                } Ft`}</p>
            )}

            {!chatPayed && withSubscriptionPrice && !paymentPending && (
                <p
                    className={classes.channelClosed}
                >{`Az üzenetküldés a meghiúsult bankkártyás fizetés miatt sajnos sikertelen volt. Kérjük próbáld meg újra. Sikeres fizetést követően a kiválasztott szakorvos azonnal megkapja üzeneted. `}</p>
            )}
            {!chatPayed && withSubscriptionPrice && (
                <p
                    className={classes.channelClosed}
                    onClick={() => setReopenState(1)}
                >{`Fizetendő összeg csak a szolgáltatás kifizetésével: ${
                    price ? formatNumber(price) : ""
                } Ft`}</p>
            )}

            {!chatPayed && withSubscriptionPrice && !paymentPending && (
                <p
                    className={classes.channelClosed}
                    onClick={() => setReopenState(2)}
                >{`Fizetendő összeg előfizetéssel együtt: ${
                    price ? formatNumber(withSubscriptionPrice) : ""
                } Ft`}</p>
            )}
            {!chatPayed && (
                <PaymentModal
                    closeModal={() => togglePaymentModal(false)}
                    open={paymentModal}
                    userSubscribed={userSubscribed}
                    infoText="A folytatásra kattintva rendszerünk kézbesíti az üzeneted a címzettnek, a számládról pedig automatikusan levonja a megjelölt összeget"
                    boxTitle={
                        !price
                            ? "Előfizetésben fogalt díjmentes üzenet"
                            : "Előfizetés üzenetváltási díja"
                    }
                    price={price}
                    couponHandler={redeemCoupon}
                    onContinueClick={payForChat}
                />
            )}
            <SubscriptionAndServiceModal
                outerSelectedPackage={originalSubscription}
                selectCallback={setSelectedPackage}
                normalInit={payForChat}
                open={reopenState === 1 ? 2 : 0}
                data={subscriptionAndServiceModalData}
                toggleSubscriptionAndServiceModal={setReopenState}
                succesfulSubscribeAndServiceCallback={setEverythingToOpen}
            />
            <SubscriptionAndServiceModal
                outerSelectedPackage={selectedPackage}
                ignoreSubscription={true}
                selectCallback={setSelectedPackage}
                normalInit={payForChat}
                chatIdentifier={props.identifier}
                open={reopenState === 2 ? 2 : 0}
                data={subscriptionAndServiceModalData}
                toggleSubscriptionAndServiceModal={setReopenState}
                succesfulSubscribeAndServiceCallback={setEverythingToOpen}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    setChatAccessToken: (serviceId, channelSID, accessToken) =>
        dispatch(
            actions.setChatAccessToken(serviceId, channelSID, accessToken)
        ),
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
    toggleServicePaymentModal: (show, price, service, preScript, identifier) =>
        dispatch(
            actions.toggleServicePaymentModal(
                show,
                price,
                service,
                preScript,
                identifier
            )
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionRoom);
