import { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Container, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Breadcrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import { endLoading, get } from "../../../config/fetch";
import { ALERT_MESSAGES, EVENT_FLAGS } from "../../../config/variables";
import { responseMessage } from "../../../utility";
import InnerLayout from "../templates/InnerLayout";
import ScrollContainer from "../atoms/ScrollContainer";
import Paper from "../atoms/Paper";
import PaperSection from "../atoms/PaperSection";
import Headline from "../atoms/Headline";
import FilterSelect from "../molecules/FilterSelect";
import EventRowDoctor from "../organisms/EventRowDoctor";
import classes from "./Events.module.css";
import "../../../components/Facelift/typo.css";

const items = [
    {
        label: "Összes üzenet",
        value: [...EVENT_FLAGS],
    },
    { label: "Orvosi chat", value: ["CHAT"] },
    { label: "Másodvélemény", value: ["SECOND_OPINION"] },
    { label: "E-recept", value: ["PRESCRIPTION"] },
];

// Component
const Messages = () => {
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [messages, setMessages] = useState([]);

    const getMessages = useCallback(
        () =>
            get("api/v1/event/get-user-chat-channels")
                .then((response) => {
                    let sortedMessages;
                    switch (response.data.responseCode) {
                        case "OK":
                            sortedMessages = sortMessages(
                                response.data.channels
                            );
                            setMessages(sortedMessages);
                            setFilteredMessages(sortedMessages);

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                    endLoading();
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                }),
        []
    );

    useEffect(() => {
        getMessages();
    }, [getMessages]);

    const sortMessages = (messages) => {
        const sorted = messages.sort((a, b) =>
            new Date(a.last_message_created_at.replace(/-/g, "/")) >
            new Date(b.last_message_created_at.replace(/-/g, "/"))
                ? 1
                : -1
        );

        return sorted.reverse();
    };

    const handleFilterSelect = useCallback(
        (item) => {
            const filtered = messages.filter(({ event_name }) => {
                if (!event_name) {
                    return false;
                }

                return item.value.includes(event_name);
            });

            setFilteredMessages(filtered);
        },
        [messages, setFilteredMessages]
    );

    const resetFilteredMessages = () => setFilteredMessages(messages);

    return (
        <div className={classes.intro}>
            <Container>
                <InnerLayout>
                    <Paper>
                        <Headline>
                            <Breadcrumbs />
                        </Headline>
                        <Headline>
                            <Grid container spacing={5}>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className={classes.toolbarFirst}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <FilterSelect
                                        items={items}
                                        onSelect={handleFilterSelect}
                                    />
                                </Grid>
                            </Grid>
                        </Headline>

                        <ScrollContainer>
                            <PaperSection className={classes.noPadding}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {filteredMessages?.length > 0 ? (
                                            filteredMessages.map((event) => (
                                                <Fragment
                                                    key={`event-row-message-${event.id}`}
                                                >
                                                    <EventRowDoctor
                                                        {...event}
                                                    />
                                                </Fragment>
                                            ))
                                        ) : (
                                            <div
                                                style={{
                                                    padding:
                                                        "2rem 1.5rem 2rem 2rem",
                                                }}
                                            >
                                                <Alert
                                                    classes={{
                                                        root: classes.alert,
                                                        message:
                                                            classes.alertMessage,
                                                    }}
                                                    severity="info"
                                                    action={
                                                        <Button
                                                            color="inherit"
                                                            size="large"
                                                            onClick={
                                                                resetFilteredMessages
                                                            }
                                                        >
                                                            Visszaállítás
                                                        </Button>
                                                    }
                                                >
                                                    Nem találtunk a szűrésnek
                                                    megfelelő üzenetet.
                                                </Alert>
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </PaperSection>
                        </ScrollContainer>
                    </Paper>
                </InnerLayout>
            </Container>
        </div>
    );
};

export default Messages;
