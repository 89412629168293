import { useCallback, useEffect, useState } from "react";
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { Container } from "@material-ui/core";
import "./App.css";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { NextHealthContent } from "@madmedical/sdk";
import { NextHealthWrapper } from "tdoc-common";
import Layout from "./hoc/Layout/Layout";
import * as actions from "./store/actions/index";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import InviteRegistration from "./containers/InviteRegistration/InviteRegistraton";
import ResponseModal from "./components/Modals/ResponseModal/ResponseModal";
import VerifyEmail from "./containers/VerifyEmail/VerifyEmail";
import { Config } from "./config/config";
import VerifyNewsletter from "./containers/VerifyNewsletter/VerifyNewsletter";
import CookieSettings from "./containers/CookieSettings/CookieSettings";
import Assets from "./containers/Assets/Assets";
import ChatTest from "./test_container/ChatTest";
import ChatPage from "./containers/ChatPage/ChatPage";
import PrescriptionPage from "./containers/PrescriptionPage/PrescriptionPage";
import ServiceInitiated from "./components/ServiceInitiated/ServiceInitiated";
import Doctors from "./containers/Doctors/Doctors";
import DoctorPage from "./containers/Doctors/DoctorPage/DoctorPage";
import Institutions from "./containers/Institutions/Institutions";
import InstitutionPage from "./containers/Institutions/InstitutionPage/InstitutionPage";
import HowTo from "./containers/HowTo/HowTo";
import AboutUs from "./containers/AboutUs/AboutUs";
import ReservationEnd from "./components/ReservationEnd/ReservationEnd";
import RegistrationEnd from "./containers/MyProfile/RegistrationEnd/RegistrationEnd";
import FAQ from "./components/FAQ/FAQ";
import UnsubscribeNewsletter from "./containers/UnsubscribeNewsletter/UnsubscribeNewsletter";
import ServicePaymentModal from "./components/Modals/ServicePaymentModal/ServicePaymentModal";
import Landing2 from "./containers/Landing2/Landing2";
import Landing3 from "./containers/Landing3/Landing3";
import MedicalChatLanding from "./features/MedicalChatLanding/index.tsx";
import Blog from "./features/Blog/index.tsx";
import Qualifications from "./containers/Qualifications/Qualifications";
import QualificationsModal from "./components/Modals/QualificationsModal/QualificationsModal";
import RegistrationModal from "./components/Modals/RegistrationModal/RegistrationModal";
import PhoneVerificationModal from "./components/Modals/PhoneVerificationModal/PhoneVerificationModal";
import { setYellowBar } from "./utility";
import { get } from "./config/fetch";
import Rating from "./components/Rating/Rating";
import ReservationSummary from "./components/Reservation/ReservationSummary/ReservationSummary";
import ReservationSuccess from "./components/Reservation/ReservationEnd/ReservationEnd";
import NewMyProfile from "./containers/NewMyProfile/NewMyProfile";
import DutyFinder from "./containers/DutyFinder/DutyFinder";
import HelmetHandler from "./components/Navigation/Helmet/HelmetHandler";
import Reservation from "./containers/Reservation/Reservation";
import DocumentPage from "./containers/DocumentPage/DocumentPage";
import Landing from "./features/cms/landing/index.tsx";
import "./features/i18n/i18n";
import Progress from "./components/UI/Progress";
import useAuth from "./features/auth/useAuth";
import LoginModal from "./components/Modals/LoginModal/LoginModal";
import useTranslation from "./features/i18n/useTranslation";
import NextHealthInviteWrapper from "./features/nexthealth/NextHealthInviteWrapper";
import InnerLayout from "./components/Facelift/templates/InnerLayout";
import Services from "./components/Facelift/pages/Services";
import Subscriptions from "./components/Facelift/pages/Subscriptions";

const tagManagerArgs = {
    gtmId: Config.tagmanagerId,
};

const initAnalitics = () => {
    ReactGA.initialize(Config.googleAnalyticsId, { titleCase: false });
    TagManager.initialize(tagManagerArgs);
};

function usePageViews(props) {
    const location = useLocation();
    const [gaInitialised, setGaInitialised] = useState(false);
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (process.env.NODE_ENV !== "production") {
            return;
        }

        if (!gaInitialised) {
            initAnalitics();
            setGaInitialised(true);
        }

        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);

        window.gtag2("js", new Date());
        window.gtag2("config", "AW-976829289");
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        props.toggleSubscriptionDetails(false);

        if (!location.hash) {
            window.scrollTo(0, 0);
        }

        setYellowBar();

        if (isAuthenticated) {
            get("api/v1/subscription/get-subscription", false)
                .then((response) => props.setSubscription(response.data))
                .catch((error) => {
                    console.error(error);
                    props.setSubscription(null);
                });
        }
    }, [location, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps
}

const App = (props) => {
    usePageViews(props);
    const { isLoading: isAuthLoading, isAuthenticated } = useAuth();
    const { i18nRoute } = useTranslation();
    const navigate = useNavigate();

    const handleConsultationPress = useCallback(() => {
        navigate(i18nRoute("routes.services.duty"));
    }, [navigate, i18nRoute]);

    const handleManageSubscriptionPress = useCallback(() => {
        navigate(i18nRoute("routes.profile.paymentMethods"));
    }, [navigate, i18nRoute]);

    /*     useEffect(() => {
        if (!isAuthLoading && !isAuthenticated) {
            navigate("/");

        }
    }, [isAuthLoading, isAuthenticated, navigate]); */

    return (
        <>
            <NextHealthWrapper
                isAuthLoading={isAuthLoading}
                nhaBackend={process.env.REACT_APP_NEXTHEALTH_HOSTNAME_BACKEND}
                onConsultationPress={handleConsultationPress}
                onManageSubscriptionPress={handleManageSubscriptionPress}
            >
                <HelmetHandler />
                <Layout>
                    <NextHealthInviteWrapper>
                        <Routes>
                            <Route
                                path="/nha/*"
                                element={
                                    <div
                                        style={{
                                            backgroundColor: "var(--grey3)",
                                            paddingTop: "3rem",
                                            paddingBottom: "3.6rem",
                                        }}
                                    >
                                        <Container>
                                            <InnerLayout>
                                                <NextHealthContent />
                                            </InnerLayout>
                                        </Container>
                                    </div>
                                }
                            />

                            <Route
                                path="forgot-password"
                                element={<ForgotPassword />}
                            />
                            <Route path="/" element={<Landing2 />} />
                            <Route
                                path="elofizetes-ajanlat"
                                element={<Landing3 />}
                            />
                            <Route
                                path="orvosi-chat"
                                element={<MedicalChatLanding />}
                            />
                            <Route path="/blog" element={<Blog />} />
                            <Route
                                path="invitation"
                                element={<InviteRegistration />}
                            />
                            <Route
                                path="verify-email"
                                element={<VerifyEmail />}
                            />
                            <Route
                                path="verify-newsletter"
                                element={<VerifyNewsletter />}
                            />
                            <Route
                                path="unsubscribe-newsletter"
                                element={<UnsubscribeNewsletter />}
                            />
                            <Route
                                path="cookie-settings"
                                element={<CookieSettings />}
                            />
                            <Route path="chat-test" element={<ChatTest />} />
                            <Route
                                path="service-initiated"
                                element={<ServiceInitiated />}
                            />
                            <Route
                                path="reservation-end"
                                element={<ReservationEnd />}
                            />
                            <Route
                                path="idopont-kivalasztva"
                                element={<ReservationSummary />}
                            />
                            <Route
                                path="sikeres-foglalas"
                                element={<ReservationSuccess />}
                            />
                            <Route path="orvosaink" element={<Doctors />} />
                            <Route
                                path="orvosaink/:name"
                                element={<DoctorPage />}
                            />
                            <Route
                                path="partner-intezmenyek"
                                element={<Institutions />}
                            />
                            <Route
                                path="partner-intezmenyek/:name"
                                element={<InstitutionPage />}
                            />
                            <Route
                                path="szolgaltatasok/*"
                                element={<Services />}
                            />
                            <Route
                                path="elofizetesek"
                                element={<Subscriptions />}
                            />
                            <Route path="hogyan-mukodik" element={<HowTo />} />
                            <Route path="rolunk" element={<AboutUs />} />
                            <Route path="sugokozpont/*" element={<FAQ />} />
                            <Route
                                path="ugyeletkereso"
                                element={<DutyFinder />}
                            />
                            <Route
                                path="registration-end"
                                element={<RegistrationEnd />}
                            />
                            <Route
                                path="szakteruletek/*"
                                element={<Qualifications />}
                            />
                            <Route path="ertekeles" element={<Rating />} />
                            <Route path="aszf" element={<DocumentPage />} />
                            <Route
                                path="adatvedelmi_nyilatkozat"
                                element={<DocumentPage />}
                            />
                            <Route
                                path="idopontfoglalas"
                                element={<Reservation />}
                            />
                            <Route path="landing" element={<Landing />} />

                            {process.env.NODE_ENV === "development" && (
                                <Route path="assets" element={<Assets />} />
                            )}

                            {/* AUTH NEEDED */}
                            {!isAuthLoading && isAuthenticated && (
                                <>
                                    <Route
                                        path="profile/*"
                                        element={<NewMyProfile />}
                                    />
                                    <Route
                                        path="elozmenyek"
                                        element={
                                            <Navigate to="/profile/elozmenyek" />
                                        }
                                    />
                                    <Route
                                        path="elozmenyek/orvosi-chat"
                                        element={
                                            <Navigate to="/szolgaltatasok/orvosi-chat" />
                                        }
                                    />
                                    <Route
                                        path="elozmenyek/masodvelemeny"
                                        element={
                                            <Navigate to="/szolgaltatasok/masodvelemeny" />
                                        }
                                    />
                                    <Route
                                        path="elozmenyek/orvosi-chat/:identifier"
                                        element={<ChatPage />}
                                    />
                                    <Route
                                        path="elozmenyek/e-recept/:identifier"
                                        element={<PrescriptionPage />}
                                    />
                                    <Route
                                        path="elozmenyek/e-masodvelemeny/:identifier"
                                        element={
                                            <ChatPage isSecondOpinion={true} />
                                        }
                                    />
                                </>
                            )}
                        </Routes>
                    </NextHealthInviteWrapper>
                </Layout>

                <ResponseModal />
                <ServicePaymentModal />
                <LoginModal open={props.loginModalOpen} />
                <RegistrationModal open={props.registrationModalOpen} />
                {props.showVerificationModal && (
                    <PhoneVerificationModal
                        open={props.showVerificationModal}
                    />
                )}
                <QualificationsModal open={props.showQualificationsModal} />
            </NextHealthWrapper>
            <Progress open={props.loading} />
        </>
    );
};

const mapStateToProps = (state) => ({
    loading: state.common.loading,
    loginModalOpen: state.ui.loginModal,
    registrationModalOpen: state.ui.registrationModal,
    showQualificationsModal: state.ui.showQualificationsModal,
    showVerificationModal: state.common.showVerificationModal,
});

const mapDispatchToProps = (dispatch) => ({
    setSubscription: (subscription) =>
        dispatch(actions.setSubscription(subscription)),
    toggleSubscriptionDetails: (show) =>
        dispatch(actions.toggleSubscriptionDetails(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
