type AllowedTypes = boolean | string | number | Record<string, unknown> | null;

type StorageType = "local" | "session" | "both";

const getStorages = (type: StorageType): Storage[] => {
    switch (type) {
        case "local":
            return [localStorage];
        case "session":
            return [sessionStorage];
        case "both":
            return [sessionStorage, localStorage];
    }
};

export const setStorageValue = <T extends AllowedTypes>(
    key: string,
    value: T,
    storage: StorageType = "local"
) =>
    getStorages(storage).map((storage) =>
        storage.setItem(key, JSON.stringify(value))
    );

export const getStorageValue = <T extends AllowedTypes>(
    key: string,
    storage: StorageType = "local"
): T | null => {
    const jsonValue = getStorages(storage).reduce<string | null>(
        (previous, current) => previous ?? current.getItem(key),
        null
    );

    try {
        return jsonValue != null ? (JSON.parse(jsonValue) as T) : null;
    } catch (e) {
        // For backward compatibility, return raw storage value
        return jsonValue as T;
    }
};

export const removeStorageValue = (
    key: string,
    storage: StorageType = "local"
) => getStorages(storage).map((storage) => storage.removeItem(key));
