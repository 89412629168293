import { Grid } from "@material-ui/core";
import classes from "./DeviceRow.module.css";

const DeviceRow = (props) => (
    <Grid container alignItems="center" style={{ marginBottom: "2rem" }}>
        <Grid container item justifyContent="space-between" alignItems="center">
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <img src={props.icon} className={classes.icon} alt="" />
                    </Grid>
                    <Grid item>
                        <p className={classes.cardText}>
                            <span style={{ fontWeight: "600" }}>
                                {props.platform}
                            </span>{" "}
                            · {props.browser}
                        </p>
                        <p className={classes.cardText}>{props.date}</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <p className={classes.text} onClick={props.onClick}>
                    Kijelentkezés az eszközről
                </p>
            </Grid>
        </Grid>
    </Grid>
);

export default DeviceRow;
