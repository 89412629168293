import { Container, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    Chat as ChatIcon,
    Receipt as ReceiptIcon,
    ShoppingBag as ShoppingBagIcon,
} from "@carbon/icons-react";
import Breadcrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
// refacted layout UI elements to display Paperish design
import InnerLayout from "../templates/InnerLayout";
import Paper from "../atoms/Paper";
import Headline from "../atoms/Headline";
import WebshopProducts from "../organisms/WebshopProducts";
import WebshopCart from "../organisms/WebshopCart";
import WebshopStatus from "../organisms/WebshopStatus";
import { endLoading, get, post } from "../../../config/fetch";
import classes from "./Webshop.module.css";
import "../../../components/Facelift/typo.css";
import WebshopButton from "../organisms/WebshopButton";
import useTranslation from "../../../features/i18n/useTranslation";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";

const Webshop = () => {
    const navigate = useNavigate();
    const { i18nRoute } = useTranslation();

    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState({
        cart: {
            items: [],
        },
    });
    const [order, setOrder] = useState({
        isLoading: true,
        data: null,
    });

    useEffect(() => {
        getProducts();
        getCart();
        getLastOrder();
    }, []);

    const getProducts = () => {
        get("api/webshop/featured-products")
            .then((response) => {
                switch (response.status) {
                    case 200:
                        setProducts(response.data);

                        break;
                    default:
                        console.error(response.data);
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const getCart = () => {
        get("api/webshop/cart")
            .then((response) => {
                switch (response.status) {
                    case 200:
                        setCart(response.data);

                        break;
                    default:
                        console.error(response.data);
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const getLastOrder = () => {
        get("api/webshop/last-order")
            .then((response) => {
                switch (response.status) {
                    case 200:
                        setOrder({
                            isLoading: false,
                            data: response.data,
                        });

                        break;
                    default:
                        console.error(response.data);
                        setOrder({
                            isLoading: false,
                            data: null,
                        });
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                setOrder({
                    isLoading: false,
                    data: null,
                });
                endLoading();
            });
    };

    const redirectToWebshop = () => {
        post("api/v1/user/create-one-time-token")
            .then((res) => {
                switch (res.data.responseCode) {
                    case "OK":
                        window.open(
                            `https://patika.tritonlife.hu/?oneTimeToken=${res.data.token}`,
                            "_blank"
                        );
                        endLoading();

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className={classes.intro}>
            <Container>
                <InnerLayout>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 12,
                        }}
                    >
                        <Paper>
                            <Headline>
                                <Breadcrumbs />
                            </Headline>
                            <Headline>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <WebshopCart cart={cart.cart} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <WebshopStatus order={order} />
                                    </Grid>
                                </Grid>
                            </Headline>
                            <Headline>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <WebshopButton
                                            title={"Recept kiváltása"}
                                            icon={<ReceiptIcon size="40" />}
                                            onClick={() => {
                                                navigate(
                                                    i18nRoute(
                                                        "routes.profile.new-prescription-redeem"
                                                    )
                                                );
                                            }}
                                        ></WebshopButton>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <WebshopButton
                                            title={"E-recept felírás"}
                                            icon={<ChatIcon size="40" />}
                                            onClick={() => {
                                                navigate(
                                                    i18nRoute(
                                                        "routes.services.prescription"
                                                    )
                                                );
                                            }}
                                        ></WebshopButton>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <WebshopButton
                                            title={
                                                "Nem vényköteles termék vásárlás"
                                            }
                                            icon={<ShoppingBagIcon size="40" />}
                                            onClick={() => {
                                                redirectToWebshop();
                                            }}
                                        ></WebshopButton>
                                    </Grid>
                                </Grid>
                            </Headline>
                        </Paper>

                        <Paper>
                            <Headline>Kiemelt termékek</Headline>
                            <WebshopProducts products={products} />
                        </Paper>
                    </div>
                </InnerLayout>
            </Container>
        </div>
    );
};

export default Webshop;
