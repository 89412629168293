/* TODO:
Jelenleg a response-ban ez a reservationData tartalma. Ebben nincs se a vizit helyszíne
    se a címe, se dokumentumok.
    {
        "doctorName": "Tarnai Irén",
        "reservationId": 2341937,
        "reservationTime": "2023-03-02 15:00",
        "specializationName": "Dietetikai tanácsadás",
        "deleted": 0,
        "deletedAt": null,
        "price": 21990,
        "payedAmount": 19791,
        "type": "OUTPATIENT"
    }

    */

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { add, intlFormat, isAfter } from "date-fns";
import { Link } from "@material-ui/core";
import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowDropUp as ArrowDropUpIcon,
    Close as CloseIcon,
    DescriptionOutlined as DescriptionOutlinedIcon,
} from "@material-ui/icons";
import cn from "classnames";
import { endLoading, post } from "../../../config/fetch";
import { makeInitials, responseMessage } from "../../../utility";
// refacted layout UI elements to display Paperish design
import Avatar from "../atoms/Avatar";
import Tag from "../atoms/Tag";
import PaperSection from "../atoms/PaperSection";
import "../../../components/Facelift/typo.css";
import { ALERT_MESSAGES, EVENTS } from "../../../config/variables";
import classes from "./AppointmentCard.module.css";

const formatPrice = (number) =>
    new Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        maximumFractionDigits: 0,
    }).format(+number);

const AppointmentCard = ({ reservationData, transaction_id, id }) => {
    const navigate = useNavigate();
    const { reservationId } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(reservationData?.reservationId == reservationId);
    }, [reservationData, reservationId]);

    const downloadPdf = (transactionId) =>
        post("api/v1/event/get-invoice-pdf", { transactionId })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        window.open(response.data.invoiceLink, "_black");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const deleteReservation = (id) =>
        post("api/v1/reservation/delete-reservation", { id })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK": {
                        if (response.data.withCoupon) {
                            responseMessage(
                                "A kiválaszott időpontot lemondtad. A befizetett összeget kupon formájában jóváírtuk a számládon, melyet a következő foglalás alkalmával fel tudsz használni.",
                                "Sikeres művelet"
                            );
                        } else {
                            responseMessage(
                                "A kiválaszott időpontot lemondtad. A befizetett összeg visszatérítését megkezdtük, melynek átfutási ideje a számlavezető bankodtól függően 2-3 nap is lehet.",
                                "Sikeres művelet"
                            );
                        }

                        let currentPage = location.pathname;
                        navigate({ pathname: "/empty" });
                        navigate({ pathname: currentPage }, { replace: true });

                        break;
                    }
                    case "OVER_DEADLINE":
                        responseMessage(
                            "Az időpont kezdetétől számított 48 órán belül már nem mondható le a foglalás!"
                        );

                        break;
                    case "ALREADY_DELETED":
                        responseMessage("Az időpontot már lemondtad!");

                        break;
                    case "PAYMENT_ERROR":
                        responseMessage(
                            "Az időpontot lemondtad, azonban az árának visszatérítése során hiba lépett fel. Kérünk vedd fel a kapcsolatot ügyfélszolgálatunkkal!"
                        );

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });

    return (
        <PaperSection
            className={cn(classes.paperSection, {
                [classes.opened]: isOpen,
            })}
        >
            <div className={classes.appointment}>
                <header className={classes.appointmentHeader}>
                    <div className={classes.avatar}>
                        <Avatar
                            initials={makeInitials(reservationData.doctorName)}
                            source={null} // needs uploaded image
                        />
                    </div>
                    <div className={classes.appointmentData}>
                        <div className={classes.appointmentInfo}>
                            <h4 className={classes.name}>
                                {reservationData.doctorName}
                            </h4>
                            <div className={classes.appointmentInfoData}>
                                <Tag variant="outlined">
                                    {EVENTS[reservationData.type]}
                                </Tag>
                                <div style={{ display: "none" }}>
                                    <Tag
                                        icon={<DescriptionOutlinedIcon />}
                                        variant="outlined"
                                    >
                                        9
                                    </Tag>
                                </div>
                                <span className={classes.appointmentId}>
                                    Azonosító: {reservationData.reservationId}
                                </span>
                            </div>
                        </div>
                        <div className={classes.appointmentInfo}>
                            <p>
                                {reservationData.specializationName} -{" "}
                                {intlFormat(
                                    new Date(reservationData.reservationTime),
                                    {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                    },
                                    {
                                        locale: "hu-HU",
                                    }
                                )}
                            </p>
                            <div
                                className={classes.openDetails}
                                onClick={() =>
                                    setIsOpen((prevState) => !prevState)
                                }
                            >
                                <span>
                                    Részletek{" "}
                                    {isOpen ? "elrejtése" : "mutatása"}
                                </span>

                                <div className={classes.openDetailsIcon}>
                                    {isOpen ? (
                                        <ArrowDropUpIcon fontSize="large" />
                                    ) : (
                                        <ArrowDropDownIcon fontSize="large" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {isOpen && (
                    <div className={classes.appointmentRow}>
                        <div className={classes.appointmentCol}>
                            <div className={classes.details}>
                                <dl>
                                    <dt>Orvos:</dt>
                                    <dd>{reservationData.doctorName}</dd>
                                    <dt>Időpont:</dt>
                                    <dd>
                                        {intlFormat(
                                            new Date(
                                                reservationData.reservationTime
                                            ),
                                            {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                            },
                                            {
                                                locale: "hu-HU",
                                            }
                                        )}
                                    </dd>
                                    <dt>Lemondható:</dt>
                                    <dd>
                                        {intlFormat(
                                            add(
                                                new Date(
                                                    reservationData.reservationTime
                                                ),
                                                {
                                                    hours: 48,
                                                }
                                            ),
                                            {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                            },
                                            {
                                                locale: "hu-HU",
                                            }
                                        )}
                                        -ig
                                    </dd>
                                </dl>
                                <Link
                                    component="button"
                                    onClick={() => deleteReservation(id)}
                                    className={classes.link}
                                    disabled={isAfter(
                                        new Date(),
                                        new Date(
                                            reservationData.reservationTime
                                        )
                                    )}
                                >
                                    Foglalás lemondása
                                </Link>
                            </div>
                        </div>
                        <span />
                        <div className={classes.appointmentCol}>
                            <div className={classes.details}>
                                <dl>
                                    <dt>Vizit helyszíne:</dt>
                                    <dd>Tritonlife Róbert Magánkórház</dd>
                                    <dt>Cím:</dt>
                                    <dd>1135 Budapest, Lehel utca 59/c</dd>
                                    <dt>Foglalás díja:</dt>
                                    <dd>
                                        {formatPrice(reservationData.price)}
                                    </dd>
                                </dl>
                                <Link
                                    component="button"
                                    onClick={() => downloadPdf(transaction_id)}
                                    classes={{
                                        root: classes.link,
                                    }}
                                >
                                    Számla letöltése
                                </Link>
                            </div>
                        </div>
                        {/*<span />*/}
                        <div className={classes.appointmentCol}>
                            <div className={classes.details}>
                                <div className={classes.documents}>
                                    <ul>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                        <li>
                                            <span onClick={() => null}>
                                                laborvizsgalat-202200101.pdf
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => null}
                                            >
                                                <CloseIcon fontSize="large" />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <Link
                                    style={{ display: "none" }}
                                    href="#"
                                    classes={{
                                        root: classes.link,
                                    }}
                                >
                                    Dokumentum feltöltése
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </PaperSection>
    );
};

export default AppointmentCard;
