import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxList,
    ComboboxOption,
    ComboboxPopover,
} from "@reach/combobox";
import { Icons } from "../../../assets/Theme";
import classes from "./Search.module.css";

const Search = (props) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: "hu" },
        },
    });

    return (
        <div className={classes.inputContainer}>
            <img
                src={Icons.searchGreen}
                className={classes.searchIcon}
                alt=""
            />
            <Combobox
                onSelect={async (address) => {
                    setValue(address, false);
                    clearSuggestions();
                    try {
                        const results = await getGeocode({ address });
                        const { lat, lng } = getLatLng(results[0]);
                        props.onSearchAddress(address, lat, lng);
                    } catch (error) {
                        console.error(error);
                    }
                }}
            >
                <ComboboxInput
                    className={classes.searchInput}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={!ready}
                    placeholder="Cím szerinti keresés"
                />
                <ComboboxPopover>
                    <ComboboxList>
                        {status === "OK" &&
                            data.map(({ place_id, description }) => (
                                <ComboboxOption
                                    key={place_id}
                                    value={description}
                                ></ComboboxOption>
                            ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
        </div>
    );
};

export default Search;
