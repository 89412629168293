import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Images } from "../../../assets/Theme";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import { endLoading, post } from "../../../config/fetch";
import Btn from "../../../components/UI/Btn/Btn";
import CardContainer from "../../../components/UI/CardContainer/CardContainer";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import * as actions from "../../../store/actions/index";
import { ecommerceAddCart } from "../../../config/ecommerce";
import useTranslation from "../../../features/i18n/useTranslation";
import useAuth from "../../../features/auth/useAuth";
import classes from "./DoctorPage.module.css";

let doctorId = null;
let medioId = null;

const DoctorPage = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const { isAuthenticated } = useAuth();
    const [doctorData, setDoctorData] = useState({
        name: "",
        qualifications: [],
        image: Images.placeholder,
        appointment: true,
        videoConsultation: true,
        chat: true,
        secondOpinion: true,
        introduction: "",
        introduction2: null,
        introductionTitle: null,
        languages: [],
    });

    const [replaceKeys, setReplaceKeys] = useState({ doctor: "doctor" });
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        const profileLink = params.name;

        post("api/v1/medical/get-doctor", {
            profileLink,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        setDoctorData({
                            name: response.data.data.name,
                            qualifications: response.data.data.qualifications
                                ? response.data.data.qualifications
                                : [],
                            image: response.data.data.image,
                            appointment:
                                response.data.data.participateAppointment ==
                                "1",
                            chat: response.data.data.participateChat == "1",
                            introduction: response.data.data.introduction,
                            introduction2: response.data.data.introduction2,
                            introductionTitle:
                                response.data.data.introductionTitle,
                            videoConsultation:
                                response.data.data
                                    .participateVideoConsultation == "1",
                            secondOpinion:
                                response.data.data.participateSecondOpinion ==
                                "1",
                            languages: response.data.data.languages,
                        });
                        doctorId = response.data.data.id;
                        medioId = response.data.data.medioId;
                        setReplaceKeys({
                            [profileLink]: response.data.data.name,
                        });

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onReservationButtonClick = () => {
        ecommerceAddCart("OUTPATIENT", 0, props.subscription);
        navigate(
            `/idopontfoglalas?selectedDoctor=${medioId}&onlyOutpatient=true`
        );
    };

    const onVideoConsultationClick = () => {
        ecommerceAddCart("VIDEO_CONSULTATION", 0, props.subscription);
        navigate(`/idopontfoglalas?selectedDoctor=${medioId}&onlyVideo=true`);
    };

    const onChatButtonClick = () => {
        ecommerceAddCart("CHAT", 0, props.subscription);
        if (isAuthenticated) {
            navigate(
                i18nRoute("routes.services.chatStart", {
                    routeHash: `?doctor=${doctorId}`,
                })
            );
        } else {
            props.toggleLoginModal(true);
        }
    };

    const onSecondOpinionButtonClick = () => {
        ecommerceAddCart("SECOND_OPINION", 0, props.subscription);
        if (isAuthenticated) {
            navigate(
                i18nRoute("routes.services.secondOpinionStart", {
                    routeHash: `?doctor=${doctorId}`,
                })
            );
        } else {
            props.toggleLoginModal(true);
        }
    };

    const renderLanguages = () => {
        let languageString = "";
        const sortedLang = doctorData.languages.sort((a, b) =>
            a.name > b.name ? 1 : -1
        );
        sortedLang.forEach((l, index) => {
            if (index + 1 !== sortedLang.length) {
                languageString += `${l.name}, `;
            } else {
                languageString += l.name;
            }
        });

        return languageString;
    };

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs replaceKeys={replaceKeys} />
                <CardContainer className={classes.card}>
                    <div className={classes.header}>
                        <div className={classes.headerInfoCol}>
                            <div>
                                <p className={classes.name}>
                                    {doctorData.name}
                                </p>
                                <p className={classes.description}>
                                    {doctorData.qualifications
                                        .map((q) => q.name)
                                        .join(", ")}
                                </p>
                                {doctorData.languages.length > 0 && (
                                    <p
                                        className={classes.languages}
                                    >{`Beszélt nyelvek: ${renderLanguages()}`}</p>
                                )}
                            </div>
                            <div>
                                <div className={classes.buttonContainerInner}>
                                    {doctorData.chat && (
                                        <Btn
                                            text="Üzenetet írok"
                                            className={`${classes.button} ${classes.chatButton}`}
                                            onClick={onChatButtonClick}
                                        />
                                    )}
                                    {doctorData.secondOpinion && (
                                        <Btn
                                            text="Másodvéleményt kérek"
                                            className={`${classes.button} ${classes.chatButton}`}
                                            onClick={onSecondOpinionButtonClick}
                                        />
                                    )}
                                </div>
                                <div className={classes.buttonContainerInner}>
                                    {doctorData.appointment && (
                                        <Btn
                                            text="Járóbeteg ellátás"
                                            className={`${classes.button}`}
                                            onClick={onReservationButtonClick}
                                        />
                                    )}
                                    {doctorData.videoConsultation && (
                                        <Btn
                                            text="Videó konzultáció"
                                            className={`${classes.button}`}
                                            onClick={onVideoConsultationClick}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <img
                            src={doctorData.image}
                            className={classes.image}
                            alt=""
                        />
                    </div>
                    <div
                        className="editor-container"
                        style={props.contentContainerStyle}
                        dangerouslySetInnerHTML={{
                            __html: doctorData.introduction,
                        }}
                    />
                </CardContainer>
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    subscription: state.user.subscription,
});
const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DoctorPage);
