import "../content.module.css";
import type { ContentBlock } from "../../../ui/models";
import { mockApiPayload } from "./mock";
import ContentBlockComponent from "./ContentBlock";

const Landing = () => {
    const contentBlocks: JSX.Element[] = [];

    mockApiPayload.forEach((contentBlock: ContentBlock) =>
        contentBlocks.push(
            <ContentBlockComponent contentBlock={contentBlock} />
        )
    );

    return contentBlocks;
};

export default Landing;
