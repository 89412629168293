import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
    NavLink,
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import classes from "../FAQ.module.css";
import CustomSelect from "../../UI/CustomSelect/CustomSelect";
import useTranslation from "../../../features/i18n/useTranslation";
import Registration from "./Registration";
import Services from "./Services";
import Payments from "./Payment";
import Techonology from "./Technology";
import GDPR from "./GDPR";
import Analyze from "./Analyze";
import ConnectDevice from "./ConnectDevice";
import Alerts from "./Alerts";
import Authorize from "./Authorize";

const Topics = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 959;

    useEffect(() => {
        const section = location.hash ? location.hash.substr(1) : null;
        if (section) {
            let element = document.getElementById(section);
            if (element) {
                let top = element.offsetTop;
                window.scrollTo({
                    top: top - 100,
                    left: 0,
                    behavior: "smooth",
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [selectedTopic, setSelectedTopic] = useState(0);
    const { i18nRoute } = useTranslation();

    const desktopTopics = (
        // eslint-disable-next-line react/jsx-filename-extension
        <ul className={classes.links}>
            <li>
                <NavLink
                    to={i18nRoute("routes.faq.registration")}
                    className={({ isActive }) =>
                        isActive ? classes.link : classes.linkActive
                    }
                    onClick={() => onSelectChange(0)}
                >
                    Regisztráció
                </NavLink>
            </li>
            <li>
                <NavLink
                    to={i18nRoute("routes.faq.services")}
                    className={({ isActive }) =>
                        isActive ? classes.link : classes.linkActive
                    }
                    onClick={() => onSelectChange(1)}
                >
                    Szolgáltatások
                </NavLink>
            </li>
            <li>
                <NavLink
                    to={i18nRoute("routes.faq.payment")}
                    className={({ isActive }) =>
                        isActive ? classes.link : classes.linkActive
                    }
                    onClick={() => onSelectChange(2)}
                >
                    Fizetés, számlázás
                </NavLink>
            </li>
            <li>
                <NavLink
                    to={i18nRoute("routes.faq.technology")}
                    className={({ isActive }) =>
                        isActive ? classes.link : classes.linkActive
                    }
                    onClick={() => onSelectChange(3)}
                >
                    Technikai háttér
                </NavLink>
            </li>
            <li>
                <NavLink
                    to={i18nRoute("routes.faq.gdpr")}
                    className={({ isActive }) =>
                        isActive ? classes.link : classes.linkActive
                    }
                    onClick={() => onSelectChange(4)}
                >
                    GDPR
                </NavLink>
            </li>
            <li>
                <NavLink
                    to={i18nRoute("routes.faq.analize")}
                    className={({ isActive }) =>
                        isActive ? classes.link : classes.linkActive
                    }
                    onClick={() => onSelectChange(5)}
                >
                    Analízis központ
                </NavLink>
            </li>
            <li>
                <NavLink
                    to={i18nRoute("routes.faq.connectdevice")}
                    className={({ isActive }) =>
                        isActive ? classes.link : classes.linkActive
                    }
                    onClick={() => onSelectChange(6)}
                >
                    Eszköz csatlakoztatás
                </NavLink>
            </li>
            <li>
                <NavLink
                    to={i18nRoute("routes.faq.alerts")}
                    className={({ isActive }) =>
                        isActive ? classes.link : classes.linkActive
                    }
                    onClick={() => onSelectChange(7)}
                >
                    Riasztások
                </NavLink>
            </li>
            <li>
                <NavLink
                    to={i18nRoute("routes.faq.authorized")}
                    className={({ isActive }) =>
                        isActive ? classes.link : classes.linkActive
                    }
                    onClick={() => onSelectChange(8)}
                >
                    Meghatalmazottak
                </NavLink>
            </li>
        </ul>
    );

    const onSelectChange = (id) => {
        setSelectedTopic(id);
        switch (+id) {
            case 0:
                navigate(i18nRoute("routes.faq.registration"));

                break;
            case 1:
                navigate(i18nRoute("routes.faq.services"));

                break;
            case 2:
                navigate(i18nRoute("routes.faq.payment"));

                break;
            case 3:
                navigate(i18nRoute("routes.faq.technology"));

                break;
            case 4:
                navigate(i18nRoute("routes.faq.gdpr"));

                break;
            case 5:
                navigate(i18nRoute("routes.faq.analize"));

                break;
            case 6:
                navigate(i18nRoute("routes.faq.connectdevice"));

                break;
            case 7:
                navigate(i18nRoute("routes.faq.alerts"));

                break;
            case 8:
                navigate(i18nRoute("routes.faq.authorized"));

                break;
        }
    };

    const mobileTopics = (
        <CustomSelect
            options={[
                {
                    name: "Regisztráció",
                    id: 0,
                },
                {
                    name: "Szolgáltatások",
                    id: 1,
                },
                {
                    name: "Fizetés",
                    id: 2,
                },
                {
                    name: "Technikai háttér",
                    id: 3,
                },
                {
                    name: "GDPR",
                    id: 4,
                },
                {
                    name: "Analízis központ",
                    id: 5,
                },
                {
                    name: "Eszköz csatlakoztatás",
                    id: 6,
                },
                {
                    name: "Riasztások",
                    id: 7,
                },
                {
                    name: "Meghatalmazottak",
                    id: 8,
                },
            ]}
            controlStyle={{ width: "100%", marginBottom: "-2rem" }}
            value={selectedTopic}
            optionKey="name"
            optionValue="id"
            onChange={(val) => onSelectChange(val)}
            label="Téma kiválasztása"
        />
    );

    return (
        <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
                <h2 className={classes.topicsHeader}>Súgóközpont témakörök</h2>
                {isMobile ? mobileTopics : desktopTopics}
            </Grid>
            <Grid item xs={12} md={8}>
                <div className={classes.content}>
                    <Routes>
                        <Route path="regisztracio" element={<Registration />} />
                        <Route path="szolgaltatasok" element={<Services />} />
                        <Route path="fizetes" element={<Payments />} />
                        <Route
                            path="technikai-hatter"
                            element={<Techonology />}
                        />
                        <Route path="gdpr" element={<GDPR />} />
                        <Route path="analizis-kozpont" element={<Analyze />} />
                        <Route
                            path="eszkoz-csatlakoztatas"
                            element={<ConnectDevice />}
                        />
                        <Route path="riasztasok" element={<Alerts />} />
                        <Route
                            path="meghatalmazottak"
                            element={<Authorize />}
                        />

                        <Route
                            path="/"
                            render={() => (
                                <Navigate
                                    to={i18nRoute("routes.faq.registration", {
                                        defaultValue:
                                            "/sugokozpont/regisztracio",
                                    })}
                                />
                            )}
                        />
                    </Routes>
                </div>
            </Grid>
        </Grid>
    );
};

export default Topics;
