import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FooterInfo from "./FooterInfo/FooterInfo";
import FooterPayment from "./FooterPayment/FooterPayment";

const Footer = (props) => {
    let location = useLocation();

    const [hideInfo, setHideInfo] = useState(false);

    const [inApp] = useState(location.search === "?app=true");

    useEffect(() => {
        if (
            location.pathname === "/idopontfoglalas" ||
            location.pathname === "/idopont-kivalasztva" ||
            location.pathname === "/sikeres-foglalas"
        ) {
            setHideInfo(true);
        } else {
            setHideInfo(false);
        }
    }, [location]);

    return (
        <footer style={props.style}>
            {!hideInfo && !inApp ? <FooterInfo /> : null}
            {!inApp ? <FooterPayment /> : null}
        </footer>
    );
};

export default Footer;
