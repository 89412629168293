import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Switch as BaseSwitch } from "@material-ui/core";
import cn from "classnames";
import classes from "./Switch.module.css";

const AntSwitch = withStyles(() => ({
    root: {
        width: 50,
        height: 22,
        padding: 0,
        display: "flex",
        marginLeft: "auto",
    },
    switchBase: {
        padding: 1,
        color: "#f3f3f3",
        "&$checked": {
            transform: "translateX(28px)",
            color: "#fff",
            "& + $track": {
                opacity: 1,
                backgroundColor: "#1FBA9C",
                borderColor: "#1FBA9C",
            },
        },
    },
    thumb: {
        width: 20,
        height: 20,
        boxShadow: "none",
        color: "#fff",
        border: "1px solid #DEDFDF",
    },
    track: {
        border: "1px solid #F5F5F5",
        borderRadius: 100,
        opacity: 1,
        backgroundColor: "#F5F5F5",
    },
    checked: {},
}))(BaseSwitch);

const Switch = ({ name, value, hasLabel = true, onChange }) => {
    const [isChecked, setChecked] = useState(value || false);

    const handleChange = ({ target }) => {
        setChecked(target.checked);
        onChange?.(target.checked);
    };

    return (
        <div className={classes.formControl}>
            <AntSwitch
                name={name}
                value={value}
                checked={isChecked}
                onChange={handleChange}
            />
            {hasLabel && (
                <p className={cn({ [classes.off]: !isChecked })}>
                    {isChecked ? "Be" : "Ki"}
                </p>
            )}
        </div>
    );
};

export default Switch;
