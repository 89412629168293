import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import classNames from "classnames/bind";
import {
    DAYS,
    MOBILE_DAYS,
    getDayDate,
    getTimeOfDate,
    isMobile,
} from "../../reservationUtilities";
import ReservationButton from "../ReservationButton/ReservationButton";
import { useHumed } from "../../../../ui/hooks/useHumed";
import classes from "./Week.module.css";

const cx = classNames.bind(classes);

const Week = forwardRef((props, ref) => {
    const isHumed = useHumed();

    //Objektumfelépítési struktúra, nap indexre, azon belül délelőtt-délutánra lebontva
    const [sortedAppointments, setSortedAppointments] = useState({
        0: {
            AM: [],
            PM: [],
        },
        1: {
            AM: [],
            PM: [],
        },
        2: {
            AM: [],
            PM: [],
        },
        3: {
            AM: [],
            PM: [],
        },
        4: {
            AM: [],
            PM: [],
        },
        5: {
            AM: [],
            PM: [],
        },
        6: {
            AM: [],
            PM: [],
        },
    });
    const [emptyWeek, setEmptyWeek] = useState(false);

    const [visibleFullAM, setAMVisibility] = useState(false);
    const [visibleFullPM, setPMVisibility] = useState(false);

    const headerRef = useRef();

    useEffect(() => {
        if (props.appointments) {
            const appointments = {
                0: {
                    AM: [],
                    PM: [],
                },
                1: {
                    AM: [],
                    PM: [],
                },
                2: {
                    AM: [],
                    PM: [],
                },
                3: {
                    AM: [],
                    PM: [],
                },
                4: {
                    AM: [],
                    PM: [],
                },
                5: {
                    AM: [],
                    PM: [],
                },
                6: {
                    AM: [],
                    PM: [],
                },
            };
            let appointmentCounter = 0;
            for (let a of props.appointments) {
                appointmentCounter++;
                let date;
                if (typeof a === "object") {
                    date = a;
                } else {
                    date = new Date(a);
                }
                //Vasárnap 0 index alapból, én hétfőt akarom 0-nak
                const dayIndex = (date.getDay() + 6) % 7;
                if (date.getHours() < 12) {
                    appointments[dayIndex]["AM"].push(getTimeOfDate(date));
                } else {
                    appointments[dayIndex]["PM"].push(getTimeOfDate(date));
                }
            }
            setEmptyWeek(appointmentCounter === 0);
            setSortedAppointments(appointments);
        }
    }, [props.appointments]);

    const closeWeek = () => {
        setAMVisibility(false);
        setPMVisibility(false);
    };

    //A hét váltáskor a szülő hívja meg direktbe ezt a függvényt
    useImperativeHandle(ref, () => ({ closeWeek }), []);

    const toggleDay = (dayHalf, opening) => {
        if (opening) {
            if (dayHalf === "AM") {
                setAMVisibility(true);
            } else if (dayHalf === "PM") {
                setPMVisibility(true);
            }
        } else {
            if (dayHalf === "AM") {
                setAMVisibility(false);
                headerRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            } else if (dayHalf === "PM") {
                setPMVisibility(false);
                headerRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }
    };

    const renderDayHeaders = () => {
        const dayHeaders = [];
        for (let i = 0; i <= 6; i++) {
            dayHeaders.push(
                <div
                    className={classes.dayHeader}
                    key={"header" + i + props.start}
                >
                    <p className={classes.dayName}>
                        {isMobile ? MOBILE_DAYS[i] : DAYS[i]}
                    </p>
                    <p className={classes.dayDate}>
                        {getDayDate(i, props.start)}
                    </p>
                </div>
            );
        }

        return dayHeaders;
    };

    const renderDayAMs = () => {
        const halves = [];
        for (let i = 0; i <= 6; i++) {
            halves.push(
                <div className={classes.dayHalf} key={props.start + "AM" + i}>
                    {sortedAppointments[i]["AM"].map((element, index) => (
                        <ReservationButton
                            onClick={() =>
                                props.onDateClick({
                                    dayIndex: i,
                                    time: element,
                                    weekOffset: props.weekOffset,
                                })
                            }
                            key={props.start + element}
                            text={element}
                            hidden={index > 1 && !visibleFullAM}
                        />
                    ))}
                    <ReservationButton
                        onClick={() => toggleDay("AM", !visibleFullAM)}
                        text={visibleFullAM ? "Kevesebb" : "Több"}
                        hidden={sortedAppointments[i]["AM"].length < 3}
                        isToggle
                    />
                </div>
            );
        }

        return halves;
    };

    const renderDayPMs = () => {
        const halves = [];
        for (let i = 0; i <= 6; i++) {
            halves.push(
                <div className={classes.dayHalf} key={props.start + "PM" + i}>
                    {sortedAppointments[i]["PM"].map((element, index) => (
                        <ReservationButton
                            onClick={() =>
                                props.onDateClick({
                                    dayIndex: i,
                                    time: element,
                                    weekOffset: props.weekOffset,
                                })
                            }
                            key={props.start + element}
                            text={element}
                            hidden={index > 1 && !visibleFullPM}
                        />
                    ))}
                    <ReservationButton
                        onClick={() => toggleDay("PM", !visibleFullPM)}
                        text={visibleFullPM ? "Kevesebb" : "Több"}
                        hidden={sortedAppointments[i]["PM"].length < 3}
                        isToggle
                    />
                </div>
            );
        }

        return halves;
    };

    return (
        <div className={classes.week} ref={ref} key={props.key}>
            <div className={classes.dayHeaders} ref={headerRef}>
                {renderDayHeaders()}
            </div>
            <div className={classes.dayHalves}>{renderDayAMs()}</div>
            <div className={classes.dayHalves}>
                {renderDayPMs()}
                {emptyWeek && (
                    <div
                        className={cx(classes.jumpButton, { humed: isHumed })}
                        onClick={props.jumpToNextNotEmptyWeek}
                    >
                        Ugrás a következő szabad időpontra{" "}
                        <ChevronRightIcon classes={{ root: classes.chevron }} />{" "}
                    </div>
                )}
            </div>
        </div>
    );
});

Week.displayName = "Week";

export default Week;
