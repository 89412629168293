import { useMemo } from "react";
import { Link } from "@material-ui/core";
import { intlFormat } from "date-fns";
import { Grid } from "@material-ui/core";
import cn from "classnames";
import Tag from "../atoms/Tag";
import StatusBadge from "../atoms/StatusBadge";
import classes from "./EventRow.module.css";
import { endLoading, post } from "../../../config/fetch";
import { formatNumber, responseMessage } from "../../../utility";
import {
    ALERT_MESSAGES,
    EVENTS,
    PACKAGES,
    SYSTEM_FLAGS,
} from "../../../config/variables";

const generateName = (payload) => {
    const payloadObj = JSON.parse(payload);

    if (payloadObj?.doctorName) {
        return payloadObj.doctorName;
    }

    if (payloadObj?.doctor) {
        return payloadObj.doctor;
    }

    if (payloadObj?.type === "nextHealth") {
        return payloadObj.subject;
    }

    return null;
};

const generatePrice = (payload, event = null) => {
    const payloadObj = JSON.parse(payload);
    if (event.event_name === "LOGIN") {
        return "";
    }
    if (payloadObj === null) {
        return null;
    }
    if (event.price === null && event.transaction_id === null) {
        return " - Nincs még kifizetve";
    }
    if (event.price) {
        return `- ${formatNumber(event.price)} Ft`;
    }
    if (typeof payloadObj?.price === "object") {
        return `- ${formatNumber(payloadObj.price.price)} Ft`;
    }
    if (payloadObj?.price) {
        return `- ${formatNumber(payloadObj.price)} Ft`;
    }

    return null;
};

const generateText = (payload, event) => {
    const payloadObj = JSON.parse(payload);

    if (payloadObj === null) {
        return null;
    }

    switch (event) {
        case "CHANGE_SUBSCRIPTION":
            return `${PACKAGES[payloadObj?.to]} (${
                PACKAGES[payloadObj?.from]
            } csomagról)`;
        case "MONTHLY_CHARGE":
            return `Következő havi csomag: ${
                PACKAGES[payloadObj?.next_month_subscription]
            }`;
    }

    switch (payloadObj?.type) {
        case "newChatMessage":
            return payloadObj.author + " új üzenetet küldött";
        case "newPrescriptionMessage":
            return payloadObj.author + " új üzenetet küldött";
        case "recommendedService":
            return payloadObj.service + " szolgáltatást ajánlottak neked";
        case "newRatingAvailable":
            return "Értékeld legutóbb igénybevett szolgáltatásod";
        case "nextHealth":
            return payloadObj.subject || payloadObj.content;
        default:
            return "";
    }
};

const EventRowDefault = ({
    created_at,
    event_name,
    text,
    payload,
    transaction_id,
    price,
    chatData,
}) => {
    const invoice = useMemo(
        () =>
            transaction_id && event_name !== "OUTPATIENT" && price != 0
                ? transaction_id
                : null,
        [transaction_id, event_name, price]
    );

    const downloadPdf = (transactionId) =>
        post("api/v1/event/get-invoice-pdf", { transactionId })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        window.open(response.data.invoiceLink, "_black");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <Grid
            container
            className={cn(classes.row, {
                [classes.unread]: chatData?.patient_unread === 1,
            })}
            data-component="EventRowDefault"
        >
            <Grid item xs={12}>
                <div className={classes.innerRow}>
                    <div className={classes.avatar}>
                        <StatusBadge size="xxl" level={4} hasText={false} />
                    </div>
                    <div className={classes.content}>
                        <div className={classes.bar}>
                            <div className={classes.info}>
                                {SYSTEM_FLAGS.includes(event_name) && (
                                    <h3 className={classes.title}>
                                        Rendszerüzenet
                                    </h3>
                                )}
                                <div className={classes.date}>
                                    <p>
                                        {intlFormat(
                                            new Date(created_at),
                                            {
                                                weekday: "long",
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            },
                                            {
                                                locale: "hu-HU",
                                            }
                                        )}
                                    </p>
                                </div>
                                <div className={classes.indicators}>
                                    <div className={classes.tag}>
                                        <Tag variant="outlined">
                                            {EVENTS[event_name]}{" "}
                                            {generateName(payload)}{" "}
                                            {generatePrice(payload, {
                                                event_name,
                                                price,
                                                transaction_id,
                                            })}
                                        </Tag>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.date}>
                                <p>
                                    {intlFormat(
                                        new Date(created_at),
                                        {
                                            weekday: "long",
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        },
                                        {
                                            locale: "hu-HU",
                                        }
                                    )}
                                </p>
                            </div>
                        </div>
                        {(text ||
                            typeof generateText(payload, event_name) ===
                                "string") && (
                            <div className={classes.text}>
                                <p>
                                    {text || generateText(payload, event_name)}
                                </p>
                            </div>
                        )}
                        {invoice && (
                            <Link
                                href="#"
                                className={classes.link}
                                onClick={() => downloadPdf(invoice)}
                            >
                                Számla letöltése
                            </Link>
                        )}
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default EventRowDefault;
