import * as actionTypes from "../actions/actionTypes";

const initialState = {
    token: null,
    loading: false,
    showVerificationModal: false,
};

const startLoad = (state) => ({
    ...state,
    loading: true,
});

const endLoadLoading = (state) => ({
    ...state,
    loading: false,
});

const toggleVerificationModal = (state, action) => ({
    ...state,
    showVerificationModal: action.show,
});

const setUserPhone = (state, action) => ({
    ...state,
    phone: action.phone,
});

const reducer = (state = initialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case actionTypes.START_LOADING:
            return startLoad(state);
        case actionTypes.END_LOADING:
            return endLoadLoading(state);
        case actionTypes.TOGGLE_VERIFICATION_MODAL:
            return toggleVerificationModal(state, action);
        case actionTypes.SET_USER_PHONE:
            return setUserPhone(state, action);
    }

    return state;
};

export default reducer;
