import Card from "../Card/Card";

const CardContainer = (props) => {
    if (window.innerWidth < 600) {
        return (
            <div className={props.className} style={props.style}>
                {props.children}
            </div>
        );
    }

    return (
        <Card className={props.className} style={props.style}>
            {props.children}
        </Card>
    );
};

export default CardContainer;
