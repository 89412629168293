import cn from "classnames";
import classes from "./Tag.module.css";

const variants = {
    outlined: classes.outlined,
};

const Tag = ({ children, icon, variant }) => (
    <div className={cn(classes.tag, variants?.[variant])}>
        {icon} {children}
    </div>
);

export default Tag;
