import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

interface Props {
    label: string;
}

const ButtonContinue = ({ label }: Props) => {
    const buttonStyle = {
        color: "white",
        width: "140px",
        height: "54px",
        fontWeight: "bold",
        fontSize: "16px",
        borderRadius: "27px",
        backgroundColor: "rgba(38, 61, 80, 0.3)",
        border: "none",
        cursor: "pointer",
        lineHeight: "5.4rem",
        textAlign: "center",
        position: "relative",
        margin: "0 auto",
    } as const;

    const arrowStyle = {
        fontSize: "3rem",
        position: "relative",
        top: "18%",
    } as const;

    const labelStyle = {
        position: "relative",
        marginLeft: "28px",
    } as const;

    return (
        <button style={buttonStyle}>
            <span style={labelStyle}>{label}</span>
            <KeyboardArrowDownIcon style={arrowStyle} />
        </button>
    );
};

export default ButtonContinue;
