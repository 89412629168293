import { Icons } from "../../../assets/Theme";
import classes from "./ListingCard.module.css";

const ListingCard = (props) => {
    const onClickHandler = () => {
        if (props.onClick) {
            props.onClick();
        }
    };
    if (props.type === "doctor") {
        return (
            <div
                className={`${classes.ListingCard} ${props.className}`}
                style={props.style}
                onClick={onClickHandler}
            >
                <div className={classes.imageContainer}>
                    <img src={props.image} className={classes.image} alt="" />
                </div>
                <p className={classes.name}>{props.name}</p>
                {props.qualification && (
                    <p className={classes.qualification}>
                        {props.qualification}
                    </p>
                )}
            </div>
        );
    } else if (props.type === "institution") {
        return (
            <div
                className={`${classes.ListingCard} ${props.className}`}
                style={props.style}
                onClick={onClickHandler}
            >
                <div className={classes.imageContainer}>
                    <img src={props.image} className={classes.image} alt="" />
                </div>
                <p className={classes.city}>{props.city}</p>
                <p className={classes.name}>{props.name}</p>
                <div className={classes.serviceRow}>
                    {props.outPatient ? (
                        <img
                            src={Icons.tickGreen}
                            className={classes.acceptPatientIcon}
                            alt=""
                        />
                    ) : (
                        <img
                            src={Icons.xRed}
                            className={classes.acceptPatientIcon}
                            alt=""
                        />
                    )}
                    <span>Járóbeteg ellátás </span>
                </div>
                <div className={classes.serviceRow}>
                    {props.inPatient ? (
                        <img
                            src={Icons.tickGreen}
                            className={classes.acceptPatientIcon}
                            alt=""
                        />
                    ) : (
                        <img
                            src={Icons.xRed}
                            className={classes.acceptPatientIcon}
                            alt=""
                        />
                    )}
                    <span>Fekvőbeteg ellátás</span>
                </div>
            </div>
        );
    }
};

export default ListingCard;
