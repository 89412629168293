import type { MouseEvent } from "react";
import { Box } from "@material-ui/core";
import styled from "@emotion/styled";
import { Colors } from "../../assets/Theme";
import type { BlogItem } from "../../ui/models";
import { useResolution } from "../../ui/utils";
import ClearIcon from "./ClearIcon.svg";
import classes from "./styles.module.css";
import { getCategoryOccurrences, isTopicSelected } from "./helpers";

interface Props {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    topics: string[];
    blogs: BlogItem[];
    selectedTopic: string | null;
}

interface FilterButtonProps {
    isFiltered?: boolean;
}

const FilterButton = styled.button<FilterButtonProps>`
    background: ${(props: FilterButtonProps) =>
        props.isFiltered ? Colors.green : Colors.darkBlue};
    border-radius: 27px;
    border: none;
    padding-left: 2rem;
    padding-right: 2rem;
    width: auto;
    height: 54px;
    color: white;
    text-transform: uppercase;
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
`;

const Topics = ({ topics, blogs, onClick, selectedTopic }: Props) => {
    const isMobile = useResolution("mobile");

    const isFiltered = isTopicSelected(selectedTopic);

    const showOverflowedFilters = isMobile && !isFiltered && topics.length > 4;

    if (isFiltered && selectedTopic) {
        return (
            <Box px={2} mt={5}>
                <FilterButton
                    isFiltered
                    value={selectedTopic}
                    onClick={onClick}
                >
                    {selectedTopic}
                    <Box ml={2}>
                        <img src={ClearIcon} alt="Kategória szűrő törlése" />
                    </Box>
                </FilterButton>
            </Box>
        );
    }

    return (
        <Box
            px={2}
            mt={5}
            className={classes.container}
            style={
                showOverflowedFilters
                    ? { overflowX: "auto", whiteSpace: "nowrap" }
                    : undefined
            }
        >
            <Box
                {...(showOverflowedFilters && {
                    width: "750px",
                    display: "flex",
                    flexWrap: "wrap",
                })}
            >
                {topics.map((topic) => (
                    <>
                        <FilterButton value={topic} onClick={onClick}>
                            {`${topic} (${getCategoryOccurrences(
                                blogs,
                                topic
                            )})`}
                        </FilterButton>
                    </>
                ))}
            </Box>
        </Box>
    );
};

export default Topics;
