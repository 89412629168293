import InputMask from "./InputMask";

const DateInput = ({ name, value, className, style, onChange }) => (
    <InputMask
        name={name}
        value={value}
        options={{ date: true, datePattern: ["Y", "m", "d"] }}
        className={className}
        style={style}
        onChange={onChange}
    />
);

export default DateInput;
