import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import { formatDate } from "../../../utility";
import Tag from "../atoms/Tag";
import TdocIcon from "../../../assets/images/tdoc-icon.svg";
import classes from "./SubscriptionAccordion.module.css";

const NhSubscriptionAccordion = ({ subscriptionData }) => {
    const nhSubs = {
        pre_op: "PreOp csomag",
        pregnancy: "Várandósgondozás",
        diabetes: "Diabétesz csomag",
    };

    return (
        <div
            className={classes.root}
            style={{
                marginBottom: "2rem",
            }}
        >
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    className={classes.summary}
                    expandIcon={
                        <ArrowDropDownIcon
                            fontSize="large"
                            classes={{ root: classes.icon }}
                        />
                    }
                    aria-controls={`accordion-1`}
                    id={`accordion-1`}
                    classes={{
                        content: classes.content,
                        expandIcon: classes.expandIcon,
                    }}
                >
                    <img src={TdocIcon} alt="" className={classes.tdoc} />
                    <Typography className={classes.heading}>
                        {nhSubs[subscriptionData.service]}
                    </Typography>
                    <Tag>Aktív</Tag>
                    <div className={classes.showDetails}>
                        <p>Részletek mutatása</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <dl>
                        <dd>
                            <ul>
                                <li>
                                    <span>Lejárat: </span>
                                    <span>
                                        {formatDate(subscriptionData.expiresAt)}
                                    </span>
                                </li>
                                <li>
                                    <span>Eszközök száma: </span>
                                    <span>
                                        {subscriptionData.maxDeviceCount
                                            ? subscriptionData.maxDeviceCount
                                            : "∞"}
                                    </span>
                                </li>
                                <li>
                                    <span>Meghatalmazottak száma: </span>
                                    <span>
                                        {subscriptionData.maxRepresentativeCount
                                            ? subscriptionData.maxRepresentativeCount
                                            : "∞"}
                                    </span>
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default NhSubscriptionAccordion;
