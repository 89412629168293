import { useNavigate } from "react-router-dom";
import { Icons } from "../../../assets/Theme";
import useAuth from "../../../features/auth/useAuth";
import classes from "./SubscriptionSelect.module.css";

const SubscriptionSelect = (props) => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    const onClickHandler = () => {
        if (props.hoverable) {
            if (isAuthenticated) {
                props.packageClick();
            } else {
                navigate("/elofizetesek");
            }
        }
    };

    const renderSubText = () => props.data.subtext;

    return (
        <div
            className={`${classes.SubscriptionSelectOuter} ${
                props.asterisk && classes.discounted
            }`}
        >
            <div
                className={`${classes.SubscriptionSelect} ${
                    props.hoverable && classes.hover
                } ${props.selected && classes.selected}`}
                style={props.style}
                onClick={onClickHandler}
            >
                {props.selected && (
                    <img
                        src={Icons.tickGreen}
                        className={classes.icon}
                        alt=""
                    />
                )}
                <div className={classes.datas}>
                    <p className={classes.name}>{props.data.name}</p>
                    <p
                        className={classes.price}
                        style={
                            props.data.textColor && {
                                color: props.data.textColor,
                            }
                        }
                    >
                        {props.data.price} {props.asterisk}
                    </p>
                    <p className={classes.subtext}>{renderSubText()}</p>
                </div>
                {props.selected && <div className={classes.icon} />}
            </div>
            {props.cancellable && (
                <p
                    className={classes.cancelChange}
                    onClick={props.packageResetClick}
                >
                    Mégsem váltok csomagot
                </p>
            )}
        </div>
    );
};

export default SubscriptionSelect;
