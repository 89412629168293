import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Colors, Fonts, Icons, Images } from "../../../assets/Theme";
import ServicePage from "../../../components/UI/ServicePage/ServicePage";
import { formatNumber } from "../../../utility";
import { endLoading, post } from "../../../config/fetch";
import * as actions from "../../../store/actions/index";
import { ecommerceProductDetail } from "../../../config/ecommerce";
import ServiceFilter from "../ServiceFilter/ServiceFilter";
import useAuth from "../../../features/auth/useAuth";
import classes from "./VideoConsultation.module.css";

const VideoConsultation = () => {
    const { isAuthenticated } = useAuth();

    const [packages, setPackages] = useState(null);
    const [subscription, setSubscription] = useState(1);

    useEffect(() => {
        if (isAuthenticated) {
            const promise1 = post(
                "api/v1/subscription/get-service-prices-by-user",
                { service: "VIDEO_CONSULTATION" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });

            Promise.all([promise1]).then((responses) => {
                endLoading();
                const response1 = responses[0];
                let _packages = processPackages(
                    response1.packages,
                    response1.subscription ? response1.subscription : 1
                );
                setPackages(_packages);
            });
        } else {
            post("api/v1/subscription/get-service-prices-by-user", {
                service: "VIDEO_CONSULTATION",
            }).then((response) => {
                endLoading();
                if (response.data.responseCode === "OK") {
                    let _packages = processPackages(
                        response.data.packages,
                        response.data.subscription
                            ? response.data.subscription
                            : 1
                    );
                    setPackages(_packages);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const processPackages = (
        packages,
        subscribedIndex = 1,
        dynamicData = null
    ) => {
        const _packages = [];
        ecommerceProductDetail("VIDEO_CONSULTATION", 0, subscribedIndex);
        setSubscription(+subscribedIndex);
        packages.forEach((p, i) => {
            if (dynamicData && subscribedIndex - 1 == i) {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        dynamicData.price !== 0
                            ? `Akár ${formatNumber(dynamicData.price)} Ft-tól`
                            : `Ingyenes`,
                    subtext:
                        dynamicData.price === 0 &&
                        dynamicData.remainingServices > 0
                            ? `Hátralévő: ${dynamicData.remainingServices}`
                            : "",
                    selected: subscribedIndex - 1 == i,
                });
            } else {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        p.freePerMonth > 0
                            ? `${p.freePerMonth} ingyenes`
                            : `Akár ${formatNumber(p.price)} Ft-tól`,
                    selected: subscribedIndex - 1 == i,
                });
            }
        });

        return _packages;
    };

    return (
        <ServicePage
            icon={Icons.videoConsultationGreen}
            name="Videókonzultáció"
            description="Szakorvosi konzultáció időben és térben hozzád igazítva. Foglalj időpontot adatalapú videókonzultációra gyorsan és egyszerűen."
            image={Images.videoConsultation}
            packages={packages}
            originalSubscription={subscription}
            selectedPackage={subscription}
            packageClick={() => undefined}
            showImportantInfo={false}
            noButton={true}
            buttonText="Válassz szakterületet!"
            buttonDisabled={false}
        >
            <ServiceFilter type="video" withInstitution={false} />

            <p style={styles.title}>Mi az a videókonzultáció?</p>
            <p style={styles.text}>
                A videókonzultáció, másnévén a telemedicina a szakorvosi
                vizsgálat modernizált változata, amelyhez csak a számítógépedre,
                mobilodra vagy a tabletedre van szükséged. A járóbetegellátáshoz
                hasonlóan, itt is előre egyeztetett időpontban kerül sor a
                páciens - orvos kapcsolatra. A konzultáció színvonala egyenlő a
                személyes konzultáció színvonalával, a különbség mindössze
                annyi, hogy egy audiovizuális eszköz köti össze a két felet.
            </p>
            <p style={styles.title}>Mi az az adatalapú telemedicina?</p>
            <p style={styles.text}>
                Applikációnk (iOS és Android egyaránt) segítségével lehetőséged
                nyílik arra, hogy az okoseszközökön és az orvostechnikai
                eszközökön mért adatok elérhetővé váljanak a videókonzultációra
                kiválasztott szakorvos számára. Már az időpont lefoglalását
                követően lehetőséged van arra, hogy méréseket végezz a
                konzultációs időpontig, illetve a konzultáció során is tudsz
                újabb méréseket végezni. A mérésieredmények kiegészítő
                információkat jelenthetnek szakorvosodnak, melynek köszönhetően
                még hatékonyabbnak bizonyulhat az online térben történő
                konzultáció.
            </p>
            <p style={styles.title}>Hogyan működik a telemedicina?</p>
            <p style={styles.text}>
                Az időpontfoglalás gyors és egyszerű. Annak érdekében, hogy
                biztosan ne felejtsd el a foglalásod, közelgő időpontodról
                e-mailben is értesítünk. Mivel mindenkinek közbejöhet egy
                váratlan esemény, így 2 nappal (48 órával) a lefoglalt időpontot
                megelőzően lehetőséged van átfoglalni, vagy lemondani azt. A
                sikeres időpontfoglalását követően az adatalapú telemedicina
                funkció néhány percen belül aktiválódik, és a videókonzultáció
                végét követően is elérhető marad még néhány óráig. A funkció
                élesítését követően a mobil applikációt összekötheted felhő
                alapú szolgáltatásaiddal, mint a Apple Health, Google Fit,
                Garmin, illetve bizonyos orvostechnikai eszközöket közvetlenül
                is csatlakoztathatod hozzá.
            </p>
            <p style={styles.title}>Milyen adatokat mérhetek?</p>
            <p style={styles.text}>
                Az alábbi adatok kerülhetnek feldolgozásra:
            </p>
            <ul style={styles.text} className={classes.text}>
                <li>Pulzus</li>
                <li>Vérnyomás</li>
                <li>Véroxigénszint</li>
                <li>Vércukorszint</li>
                <li>Testsúly</li>
                <li>Testhőmérséklet</li>
                <li>Lépésszám</li>
                <li>Alvásidő</li>
            </ul>
            <p style={styles.title}>
                Milyen esetekben előnyös a videókonzultáció?
            </p>
            <ol style={styles.text} className={classes.text}>
                <li>
                    A videókonzultáció első sorban azok számára nyújthat
                    biztonságos és kényelmes megoldást, akik nem tudják vagy nem
                    akarják elhagyni otthonukat, de orvosi tanácsra van
                    szükségük. szükségük.
                </li>
                <li>
                    Személyes vizitet nem igénylő kontrollvizsgálatok esetén is
                    jó alternatívát jelent, még akkor is, ha az első konzultáció
                    személyesen történt. személyesen történt.
                </li>
                <li>
                    Tökéletes megoldást jelent azok számára is, akik meglévő
                    betegségükkel kapcsolatban szeretnének másodvéleményt, vagy
                    részletesebb és érthető magyarázatot kérni.
                </li>
                <li>
                    Vidéken vagy külföldön tartózkodók is könnyen és kényelmesen
                    elérhetik orvosukat a nélkül, hogy több száz kilométert
                    kelljen utazniuk.
                </li>
                <li>
                    Gyermekek esetében a megszokott, megnyugtató környezetben
                    történhet meg az online konzultáció, ami segítheti a
                    gyermek-orvos kapcsolat kialakítását, ezáltal diagnózis
                    felállítását. felállítását.
                </li>
            </ol>
            <p style={styles.title}>
                Mire van szükség az online (videó) konzultációhoz?
            </p>
            <p style={styles.text}>
                Eszköz szinten az online (videó) konzultációhoz egy
                okostelefonra, tabletre, laptopra vagy webkamerával kiegészített
                számítógépre van szükség. Okostelefonok esetében javasolt az
                előlapi kamera használata. Az eszközön felül mindössze stabil,
                szélessávú internet hozzáférésre van szükség, minden mást a
                T-DOC biztosít számodra. T-DOC biztosít számodra.
            </p>
        </ServicePage>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontWeight: 700,
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
        marginTop: "3rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
        marginBottom: "1rem",
    },
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
});

export default connect(null, mapDispatchToProps)(VideoConsultation);
