import { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Breadcrumbs from "../../components/UI/Breadcrumbs/Breadcrumbs";
import CardContainer from "../../components/UI/CardContainer/CardContainer";
import Button from "../../components/UI/Button/Button";
import * as actions from "../../store/actions/index";
import PageHeader from "../../components/UI/PageHeader/PageHeader";
import { Colors, Icons, Images } from "../../assets/Theme";
import { endLoading, get } from "../../config/fetch";
import CustomSlider from "../../components/UI/CustomSlider/CustomSlider";
import ListingCard from "../../components/UI/ListingCard/ListingCard";
import useTranslation from "../../features/i18n/useTranslation";
import useAuth from "../../features/auth/useAuth";
import classes from "./AboutUs.module.css";

const AboutUs = (props) => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [institutions, setInstitutions] = useState([]);
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        get("api/v1/medical/get-institutions", false)
            .then((response) => {
                endLoading();
                setInstitutions(response.data.data);
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    }, []);

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs />
                <CardContainer className={classes.card}>
                    <h1 className={classes.title}>Rólunk</h1>
                    <p className={classes.greenText}>
                        A T-DOC egy olyan innovatív 21. századi
                        egészségplatform, ami ötvözi a telemedicina elemeket a
                        klasszikus elemekkel, ezáltal minden egészségügyi kérdés
                        megoldásának kiindulópontja, vagy akár azonnali
                        megoldása lehet, ami felmerül a családban.
                    </p>
                    <h2 className={classes.subtitle}>Mit jelent ez?</h2>
                    <p className={classes.blackText}>
                        Külföldön már évek óta bevált gyakorlat, hogy a
                        páciensek bizonyos panaszaikkal távkonzultációban is
                        tudnak orvoshoz fordulni. Hazánkban ennek gyakorlatát és
                        jogi szabályozását a 2020-as pandémiás helyzet tette
                        szükségessé.
                    </p>
                    <p className={classes.blackText}>
                        A távkonzultációt tovább álmodtunk, és létrehoztuk a
                        T-DOC-ot, mely egyetlen weboldalon, vagy akár
                        mobilkészülékre letölthető applikációban elérhetővé
                        teszi az orvosi segítséget bármely szakterületen,
                        átjárhatóságot képezve a telemedicina és a személyes
                        vizsgálatok között. Minden felhasználónk az igényeinek
                        megfelelő, magas színvonalú segítséget kaphat néhány
                        kattintás után, várakozás nélkül.
                    </p>
                    <p className={classes.blackText}>
                        A gyors megoldás lehetőségével nem csak időt takarítunk
                        meg felhasználóinknak, de motiválttá is tesszük őket
                        abban, hogy problémáikkal időben orvoshoz forduljanak.
                    </p>
                    <h2 className={classes.subtitle}>
                        Hogy néz ki a gyakorlatban?
                    </h2>
                    <p className={classes.blackText}>
                        Ügyfeleink már a T-DOC honlapján vagy az applikációban
                        is megismerhetik szolgáltatásainkat, majd egy gyors és
                        egyszerű regisztráció után igénybe is vehetik őket. Az
                        egyes szolgáltatásokat közvetlenül a honlapról bárki
                        eléri, de aki bizonytalan abban, hogy melyik lenne a
                        leghatékonyabb megoldás, annak javasoljuk a hotline
                        hívását.
                    </p>
                    <p className={classes.blackText}>
                        Az egészségügyi végzettséggel rendelkező hotline
                        munkatársai segítenek annak eldöntésében, hogy az adott
                        probléma megoldására az orvosi ügyelet, a szakorvossal
                        történő chat, a szakorvossal történő videókonzultáció,
                        vagy már kezelés alatt álló panasz esetén másodvélemény
                        kérése lenne a legmegfelelőbb.
                    </p>
                    <p className={classes.blackText}>
                        Azon felhasználóinkat, akiknél kikerülhetetlen a
                        személyes orvosi konzultáció vagy diagnosztikai
                        vizsgálat, online időpontfoglaló rendszerünkön keresztül
                        gyorsan és könnyen időpontot foglalhatnak a megfelelő
                        szakemberhez.
                    </p>
                    {!isAuthenticated && (
                        <Button
                            text="Regisztráció"
                            className={classes.button}
                            onClick={() => props.toggleRegistrationModal(true)}
                        />
                    )}
                    <PageHeader
                        text="Ismerje meg orvosainkat"
                        className={classes.pageHeader}
                    />
                    <Grid spacing={window.innerWidth > 599 ? 5 : 0} container>
                        <Grid item xs={12} sm={6}>
                            <img
                                src={Images.doctors}
                                className={classes.image}
                                alt=""
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className={classes.doctorsBiggerText}>
                                Célunk, hogy egészséged a legjobb kezekben
                                tudhassuk
                            </p>
                            <p
                                className={`${classes.lightText} ${classes.doctorsLightText}`}
                            >
                                Éppen ezért az orvosi csapatunk kiválasztásánál
                                elsődleges szempontok közé tartozik a szaktudás,
                                a, professzionális hozzáállás és az
                                ügyfélközpontúság.
                            </p>
                            <Button
                                text="Orvosok megtekintése"
                                className={classes.doctorsButton}
                                onClick={() => navigate("/orvosaink")}
                            />
                        </Grid>
                    </Grid>
                    <PageHeader
                        text="Partner intézmények"
                        className={classes.pageHeader}
                    />
                    <p
                        className={`${classes.lightText} ${classes.institutionsSubheader}`}
                    >
                        Amit digitális úton nem tudunk biztosítani számodra, azt
                        a klasszikus értelemben vett szakorvosi vizsgálattal
                        egészítjük ki a legmagasabb szakmai színvonalat
                        képviselő partnerintézményeink egyikében.
                    </p>
                    {institutions.length > 0 && (
                        <CustomSlider
                            data={institutions}
                            slides={[3, 3, 2]}
                            renderFunction={(item) => (
                                <ListingCard
                                    className={classes.ListingCard}
                                    type="institution"
                                    image={item.image}
                                    name={item.name}
                                    city={item.full_address}
                                    outPatient={+item.out_patient_available}
                                    inPatient={+item.in_patient_available}
                                />
                            )}
                        />
                    )}
                    <Button
                        text="Összes intézmény"
                        className={classes.institutionsButton}
                        onClick={() => navigate("/partner-intezmenyek")}
                    />
                    <div className={classes.popularQuestionsHeaderRow}>
                        <h3 className={classes.popularQuestionsHeader}>
                            Népszerű kérdések
                        </h3>
                        <div className={classes.popularQuestionsHeaderRowRight}>
                            <Link
                                to={i18nRoute("routes.faq.registration")}
                                className={classes.popularQuestionsLink}
                            >
                                Kérdések böngészése{" "}
                            </Link>
                            <ChevronRightIcon
                                style={{
                                    height: "2rem",
                                    width: "2rem",
                                    marginTop: "0.3rem",
                                    color: Colors.green,
                                }}
                            />
                        </div>
                    </div>
                    <Grid
                        container
                        spacing={4}
                        classes={{ root: classes.popularQuestions }}
                    >
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.popularQuestion}>
                                <div className={classes.popularLeft}>
                                    <img
                                        src={Icons.tipGreen}
                                        className={classes.icon}
                                        alt=""
                                    />
                                </div>
                                <div className={classes.popularRight}>
                                    <p className={classes.popularText}>
                                        Regisztráltam, de nem tudok belépni a
                                        fiókomba.
                                    </p>
                                    <p>
                                        <Link
                                            className={classes.popularLink}
                                            to={i18nRoute(
                                                "routes.faq.registration",
                                                {
                                                    routeHash:
                                                        "#belepes-fiokba",
                                                }
                                            )}
                                        >
                                            Válasz megtekintése
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.popularQuestion}>
                                <div className={classes.popularLeft}>
                                    <img
                                        src={Icons.tipGreen}
                                        className={classes.icon}
                                        alt=""
                                    />
                                </div>
                                <div className={classes.popularRight}>
                                    <p className={classes.popularText}>
                                        Regisztráltam, de nem kaptam megerősítő
                                        e-mailt.
                                    </p>
                                    <p>
                                        <Link
                                            className={classes.popularLink}
                                            to={i18nRoute(
                                                "routes.faq.registration",
                                                {
                                                    routeHash:
                                                        "#megerosito-email",
                                                }
                                            )}
                                        >
                                            Válasz megtekintése
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.popularQuestion}>
                                <div className={classes.popularLeft}>
                                    <img
                                        src={Icons.tipGreen}
                                        className={classes.icon}
                                        alt=""
                                    />
                                </div>
                                <div className={classes.popularRight}>
                                    <p className={classes.popularText}>
                                        Egy e-mail címmel regisztrálhatok
                                        többször?
                                    </p>
                                    <p>
                                        <Link
                                            className={classes.popularLink}
                                            to={i18nRoute(
                                                "routes.faq.registration",
                                                {
                                                    routeHash:
                                                        "#tobbszoros-email",
                                                }
                                            )}
                                        >
                                            Válasz megtekintése
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.popularQuestion}>
                                <div className={classes.popularLeft}>
                                    <img
                                        src={Icons.tipGreen}
                                        className={classes.icon}
                                        alt=""
                                    />
                                </div>
                                <div className={classes.popularRight}>
                                    <p className={classes.popularText}>
                                        Nem kaptam meg az sms-t a hotline hívás
                                        indítását követően.
                                    </p>
                                    <p>
                                        <Link
                                            className={classes.popularLink}
                                            to={i18nRoute(
                                                "routes.faq.services",
                                                { routeHash: "#sms-hotline" }
                                            )}
                                        >
                                            Válasz megtekintése
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.popularQuestion}>
                                <div className={classes.popularLeft}>
                                    <img
                                        src={Icons.tipGreen}
                                        className={classes.icon}
                                        alt=""
                                    />
                                </div>
                                <div className={classes.popularRight}>
                                    <p className={classes.popularText}>
                                        Hol érem el az orvostól kapott
                                        üzeneteimet?
                                    </p>
                                    <p>
                                        <Link
                                            className={classes.popularLink}
                                            to={i18nRoute(
                                                "routes.faq.services",
                                                {
                                                    routeHash:
                                                        "#uzenetek-elerese",
                                                }
                                            )}
                                        >
                                            Válasz megtekintése
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.popularQuestion}>
                                <div className={classes.popularLeft}>
                                    <img
                                        src={Icons.tipGreen}
                                        className={classes.icon}
                                        alt=""
                                    />
                                </div>
                                <div className={classes.popularRight}>
                                    <p className={classes.popularText}>
                                        Mikor kerül levonásra az előfizetés díja
                                        a kártyámról?
                                    </p>
                                    <p>
                                        <Link
                                            className={classes.popularLink}
                                            to={i18nRoute(
                                                "routes.faq.payment",
                                                {
                                                    routeHash:
                                                        "#elofizetes-levonas",
                                                }
                                            )}
                                        >
                                            Válasz megtekintése
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </CardContainer>
            </div>
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleRegistrationModal: (show) =>
        dispatch(actions.toggleRegistrationModal(show)),
});

export default connect(null, mapDispatchToProps)(AboutUs);
