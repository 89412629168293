import { NextHealthProvider } from "@madmedical/sdk";
import { useMemo } from "react";
import type { ReactNode } from "react";
import { useSelector } from "react-redux"; // eslint-disable-line @typescript-eslint/no-restricted-imports
import { getEnv } from "../config";
import type { StateAuth } from "../store/model";
import useIsNha from "./useIsNha";

interface Props {
    children: ReactNode;
    nhaBackend?: string;
    isAuthLoading: boolean;
    onConsultationPress?: () => void;
    onManageSubscriptionPress?: () => void;
}

const NextHealthWrapper = ({
    nhaBackend,
    isAuthLoading,
    onConsultationPress,
    onManageSubscriptionPress,
    children,
}: Props) => {
    const token = useSelector((state: StateAuth) => state.auth.token);
    const { isNhaUser, isLoading: isNhaLoading } = useIsNha();

    const appConfig = useMemo(() => {
        const origin = window?.location?.origin;
        const schemeAndHost = origin?.startsWith("https://")
            ? origin
            : `https://${getEnv("HOSTNAME_FRONTEND")}`;

        return {
            integrator: "tdoc",
            integratorToken: isNhaUser ? token : null,
            integratorAuthLoading: isAuthLoading || isNhaLoading,
            isProduction: getEnv("ENVIRONMENT") === "production",
            mobileScheme: "tdoc",
            basePath: "/nha",
            baseUri: `${schemeAndHost}/nha`,
            appStoreUrl: "https://apps.apple.com/hu/app/t-doc/id1601837228",
            googlePlayUrl:
                "https://play.google.com/store/apps/details?id=hu.tdoc.tdoc",
            onConsultationPress,
            onManageSubscriptionPress,
            testingHostnameBackend: nhaBackend,
        };
    }, [
        isNhaUser,
        isNhaLoading,
        token,
        nhaBackend,
        isAuthLoading,
        onConsultationPress,
        onManageSubscriptionPress,
    ]);

    return (
        <NextHealthProvider appConfig={appConfig}>
            {children}
        </NextHealthProvider>
    );
};

export default NextHealthWrapper;
