import type { BlogItem } from "../../ui/models";

// Finding the number of occurrences of the blogs array's category item
// So each filter item can have a number next to it, e.g. "telemedicina (5)"
// Storing the occurrences in a Map, like "telemedicina -> 5"
export const getCategoryOccurrences = (blogs: BlogItem[], topic: string) => {
    const categories: string[] = [];
    blogs.map((blog) => categories.push(blog.category.toLowerCase()));

    return categories
        .reduce(
            (acc, e) => acc.set(e, (acc.get(e) ?? 0) + 1),
            new Map<string, number>()
        )
        .get(topic.toLowerCase());
};

// Boolean variable to show if the topic is selected from the filter list
export const isTopicSelected = (selectedTopic: string | null) =>
    selectedTopic !== null;

// Showing the certain blog itme only if it's selected
export const showBlogItem = (blogItem: BlogItem, selectedTopic: string) =>
    blogItem.category.toLowerCase() === selectedTopic.toLowerCase();
