import { Container } from "@material-ui/core";
import { Route, Routes, useLocation } from "react-router-dom";
import cn from "classnames";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import SelectService from "../templates/SelectService";
import Hotline from "../../../containers/Services/Hotline/Hotline";
import Duty from "../../../containers/Services/Duty/Duty";
import Chat from "../../../containers/Services/Chat/Chat";
import Prescription from "../../../containers/Services/Prescription/Prescription";
import StartPrescription from "../../../containers/Services/Prescription/StartPrescription/StartPrescription";
import SecondOpinion from "../../../containers/Services/SecondOpinion/SecondOpinion";
import VideoConsultation from "../../../containers/Services/VideoConsultation/VideoConsultation";
import OutPatient from "../../../containers/Services/OutPatient/OutPatient";
import StartChat from "../../../containers/Services/Chat/StartChat/StartChat";
import StartSecondOpinion from "../../../containers/Services/SecondOpinion/StartSecondOpinion/StartSecondOpinion";
import CovidHotline from "../../../containers/Services/Hotline/CovidHotline";
import CovidDuty from "../../../containers/Services/Duty/CovidDuty";
import StartCovidChat from "../../../containers/Services/Chat/StartChat/StartCovidChat";
import classes from "./Services.module.css";

const Services = () => {
    const location = useLocation();

    // TODO: if Facelift project has finished remove location check conditional
    return (
        <Container classes={{ root: classes.container }}>
            <div
                className={cn({
                    [classes.ServicesInner]:
                        location.pathname !== "/szolgaltatasok",
                })}
            >
                {location.pathname !== "/szolgaltatasok" && (
                    <div style={{ paddingTop: "5rem" }}>
                        <Breadcrumbs />
                    </div>
                )}
                <Routes>
                    <Route path="/" element={<SelectService />} />
                    <Route path="hotline" element={<Hotline />} />
                    <Route path="covid-hotline" element={<CovidHotline />} />
                    <Route path="orvosi-ugyelet" element={<Duty />} />
                    <Route path="covid-ugyelet" element={<CovidDuty />} />
                    <Route path="orvosi-chat" element={<Chat />} />
                    <Route path="e-recept" element={<Prescription />} />
                    <Route
                        path="e-recept/start"
                        element={<StartPrescription />}
                    />
                    <Route
                        path="covid-chat/start"
                        element={<StartCovidChat />}
                    />
                    <Route path="masodvelemeny" element={<SecondOpinion />} />
                    <Route
                        path="videokonzultacio"
                        element={<VideoConsultation />}
                    />
                    <Route path="idopontfoglalas" element={<OutPatient />} />
                    <Route path="orvosi-chat/start" element={<StartChat />} />
                    <Route
                        path="masodvelemeny/start"
                        element={<StartSecondOpinion />}
                    />
                </Routes>
            </div>
        </Container>
    );
};

export default Services;
