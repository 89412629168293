import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import "firebase/auth";
import { Link } from "react-router-dom";
import PageHeader from "../../UI/PageHeader/PageHeader";
import Input from "../../UI/Input/Input";
import { formatNumber } from "../../../utility";
import * as actions from "../../../store/actions/index";
import { Colors, Icons } from "../../../assets/Theme";
import Button from "../../UI/Button/Button";
import Checkbox from "../../UI/Checkbox/Checkbox";
import Modal from "../../UI/Modal/Modal";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./PaymentModal.module.css";

const PaymentModal = (props) => {
    const [open, toggleOpen] = useState(props.open);
    const [checkbox1, toggleCheckbox1] = useState(false);
    const [checkbox2, toggleCheckbox2] = useState(false);
    const { i18nRoute } = useTranslation();

    const couponRef = useRef();

    useEffect(() => {
        toggleOpen(props.open);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const isDisabledButton = () => {
        if (props.userSubscribed) {
            return !checkbox1;
        }

        return !checkbox1 || !checkbox2;
    };

    const couponHandler = () => {
        if (props.couponHandler) {
            props.couponHandler(couponRef.current.value);
        }
    };

    return (
        <>
            <Modal open={open} key={open} closeModal={props.closeModal}>
                <div className={classes.PaymentModalInner}>
                    <PageHeader text="Fizetés" style={styles.PageHeader} />
                    <p className={classes.infoText}>{props.infoText}</p>
                    <div className={classes.box}>
                        <img
                            src={Icons.tickGreen}
                            className={classes.tick}
                            alt=""
                        />
                        <p className={classes.boxTitle}>{props.boxTitle}</p>
                        <p className={classes.price}>
                            {props.price && formatNumber(props.price)} Ft
                        </p>
                    </div>
                    <div className={classes.couponRow}>
                        <div className={classes.inputContainer}>
                            <p className={classes.couponInputLabel}>
                                Kupon beváltása
                            </p>
                            <Input placeholder="Kupon kód" ref={couponRef} />
                        </div>
                        <Button
                            text="Beváltom"
                            className={classes.couponButton}
                            onClick={couponHandler}
                        />
                    </div>
                    <p className={classes.infoText}>
                        Amennyiben már van beváltott kuponod, vagy most váltasz
                        be, a rendszer automatikusan a legnagyobb kedvezményt
                        nyújtó kuponod használja fel a fizetés során.
                    </p>
                    <div className={classes.line}></div>
                    <div className={classes.checkboxes}>
                        <Checkbox
                            onChange={(checked) => toggleCheckbox1(checked)}
                            style={styles.checkbox}
                            label={
                                <p className={classes.checkboxLabel}>
                                    Elolvastam és elfogadom az{" "}
                                    <Link
                                        className={classes.link}
                                        to={"/adatvedelmi_nyilatkozat"}
                                        target="_blank"
                                    >
                                        általános adatkezelési irányelveket
                                    </Link>{" "}
                                    és a{" "}
                                    <Link
                                        className={classes.link}
                                        to={i18nRoute("routes.terms")}
                                        target="_blank"
                                    >
                                        felhasználási feltételeket.
                                    </Link>
                                </p>
                            }
                        />
                        {!props.userSubscribed && (
                            <Checkbox
                                onChange={(checked) => toggleCheckbox2(checked)}
                                style={styles.checkbox}
                                label={
                                    <p className={classes.checkboxLabel}>
                                        Tudomásul veszem és elfogadom, hogy a
                                        szolgáltatás igénybevétele fizetési
                                        kötelezettséggel jár, és hogy a
                                        profilomhoz hozzáadott bankkártya a
                                        szolgáltatás összegével beterhelésre
                                        kerüljön.
                                    </p>
                                }
                            />
                        )}
                    </div>
                    <div className={classes.line}></div>
                    <Button
                        text="Folytatás"
                        disabled={isDisabledButton()}
                        style={styles.button}
                        onClick={props.onContinueClick}
                    />
                    <Button
                        style={styles.whiteButton}
                        text="Mégsem"
                        onClick={props.closeModal}
                    />
                </div>
            </Modal>
        </>
    );
};

const styles = {
    PageHeader: {
        marginTop: "0rem",
    },
    checkbox: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    button: {
        marginTop: "2rem",
        marginBottom: "2rem",
    },
    whiteButton: {
        backgroundColor: "#fff",
        color: Colors.darkBlue,
        borderColor: "#929EA7",
    },
};

const mapStateToProps = (state) => ({
    phone: state.common.phone,
});

const mapDispatchToProps = (dispatch) => ({
    setUserPhone: (phone) => dispatch(actions.setUserPhone(phone)),
    toggleVerificationModal: (show) =>
        dispatch(actions.toggleVerificationModal(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
