import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/UI/Breadcrumbs/Breadcrumbs";
import Card from "../../components/UI/Card/Card";
import ChatRoom from "../ChatRoom/ChatRoom";
import classes from "./ChatPage.module.css";

const ChatPage = (props) => {
    const [secondOpinion, setSecondOpinion] = useState(
        props.isSecondOpinion ?? false
    );
    const { identifier } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let renderContainer = (content) => {
        if (window.innerWidth < 600) {
            return <div>{content}</div>;
        }

        return <Card className={classes.card}>{content}</Card>;
    };

    const changeSecondOpinion = (secondOpinion) =>
        setSecondOpinion(secondOpinion);

    return (
        <Container>
            <div className={classes.ChatPageInner}>
                <Breadcrumbs
                    replaceKeys={
                        !secondOpinion
                            ? { chat: "chat" }
                            : { chat: "second-opinion" }
                    }
                />
                {renderContainer(
                    <ChatRoom
                        identifier={identifier}
                        changeSecondOpinion={changeSecondOpinion}
                    />
                )}
            </div>
        </Container>
    );
};

export default ChatPage;
