import { forwardRef } from "react";
import classes from "./Card.module.css";

const Card = forwardRef((props, ref) => (
    <div
        className={`${classes.Card} ${props.className}`}
        style={props.style}
        ref={ref}
        onClick={() => {
            if (props.onClick) props.onClick();
        }}
    >
        {props.children}
    </div>
));

Card.displayName = "Card";

export default Card;
