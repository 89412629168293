import { forwardRef } from "react";
import classes from "./ScrollContainer.module.css";

const ScrollContainer = forwardRef(({ children }, ref) => (
    <div ref={ref} className={classes.container}>
        {children}
    </div>
));

ScrollContainer.displayName = "ScrollContainer";
export default ScrollContainer;
