import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import huLocale from "date-fns/locale/hu";

const CustomDatePicker = (props) => {
    const dateChangeHandler = (date) => {
        if (props.onChange) {
            props.onChange(date);
        }
    };

    return (
        <div className="customDatePicker" id="sdfsdfsdfsd" style={props.style}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={huLocale}>
                <KeyboardDatePicker
                    disableFuture
                    invalidDateMessage="Nem megfelelő dátum"
                    margin="normal"
                    id="date-picker-dialog"
                    label={props.label ? props.label : null}
                    value={props.value || new Date()}
                    format="yyyy.MM.dd"
                    onChange={(date) => dateChangeHandler(date)}
                    okLabel="Rendben"
                    cancelLabel="Mégsem"
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default CustomDatePicker;
