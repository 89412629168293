import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import cn from "classnames";
import * as actions from "../../../store/actions/index";
import { useLogoutMutation } from "../../../features/auth/api";
import { Icons } from "../../../assets/Theme";
import useAuth from "../../../features/auth/useAuth";
import useTranslation from "../../../features/i18n/useTranslation";
import Btn from "./Btn";
import classes from "./BtnAuth.module.css";

const BtnAuth = ({
    showUserMenu,
    toggleLoginModal,
    toggleUserMenu,
    className,
    notifications,
    isPrimary = true,
}) => {
    const { i18nRoute } = useTranslation();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [logout] = useLogoutMutation();

    const [isMenuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if (isPrimary) {
            setMenuOpen(showUserMenu);
        }
    }, [isPrimary, showUserMenu]);

    const profileButtonClick = () => {
        if (isAuthenticated) {
            if (isPrimary) {
                setMenuOpen(!showUserMenu);
                toggleUserMenu(!showUserMenu);
            } else {
                navigate("/profile/elozmenyek");
            }
        } else {
            toggleLoginModal(true);
        }
    };

    const profileMenuDropdownClick = (target, event) => {
        event.stopPropagation();
        toggleUserMenu(false);
        setMenuOpen(false);

        switch (target) {
            case "profile":
                navigate(i18nRoute("routes.profile.history")); // elozmenyek

                break;
            case "settings":
                navigate(i18nRoute("routes.profile.base"));

                break;
            case "faq":
                navigate(i18nRoute("routes.faq.base"));

                break;
            case "logout":
                logout();
                navigate("/");

                break;
            default:
                break;
        }
    };

    return (
        <Btn
            color={!isMenuOpen ? "green" : "white-green"}
            className={cn(
                classes.buttonAuth,
                { [classes.open]: isMenuOpen },
                className
            )}
            onClick={profileButtonClick}
        >
            {isAuthenticated && notifications > 0 ? (
                <div className={classes.notificationCounter}>
                    {notifications}
                </div>
            ) : null}

            <div className={classes.buttonInner}>
                <span>
                    {isAuthenticated
                        ? "Saját fiók megnyitása"
                        : "Belépés / Regisztráció"}
                </span>
            </div>

            {isMenuOpen && (
                <div className={classes.clickDropDownOuter}>
                    <div className={classes.clickDropDownInner}>
                        <div className={classes.triangle}></div>
                        <div className={classes.elements}>
                            <div
                                className={classes.element}
                                onClick={(e) =>
                                    profileMenuDropdownClick("profile", e)
                                }
                            >
                                <img
                                    alt=""
                                    src={Icons.notificationsGreen}
                                    className={classes.icon}
                                />
                                Saját fiók megnyitása
                            </div>
                            <div
                                className={classes.element}
                                onClick={(e) =>
                                    profileMenuDropdownClick("settings", e)
                                }
                            >
                                <img
                                    alt=""
                                    src={Icons.userGreen}
                                    className={classes.icon}
                                />
                                Fiókbeállítások
                            </div>
                            <div
                                className={classes.element}
                                onClick={(e) =>
                                    profileMenuDropdownClick("faq", e)
                                }
                            >
                                <img
                                    alt=""
                                    src={Icons.lightbulbGreen}
                                    className={classes.icon}
                                />
                                Súgóközpont
                            </div>
                            <div
                                className={classes.element}
                                onClick={(e) =>
                                    profileMenuDropdownClick("logout", e)
                                }
                            >
                                <img
                                    alt=""
                                    src={Icons.signOutGreen}
                                    className={classes.icon}
                                    style={{
                                        width: 18,
                                        margin: 4,
                                        marginRight: 10,
                                    }}
                                />
                                Kijelentkezés
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Btn>
    );
};

const mapStateToProps = (state) => ({
    showUserMenu: state.ui.showUserMenu,
});

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
    toggleUserMenu: (show) => dispatch(actions.toggleUserMenu(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BtnAuth);
