import classes from "./SubscriptionDetails.module.css";

// const hasDiscount = (props, service) => discounts[props.name].includes(service);

const SubscriptionDetails = (props) => {
    if (!props.open) {
        return null;
    }

    return (
        <ul className={classes.list}>
            <li>
                <span className={classes.left}>Egészség Hotline</span>
                <span className={classes.right}>
                    {props.name === "Vendég" ? (
                        <span>{props.data.hotline} Ft</span>
                    ) : (
                        <span className={classes.highlight}>korlátlan</span>
                    )}
                </span>
            </li>
            <li>
                <span className={classes.left}>
                    Azonnali orvosi konzultáció
                </span>
                <span className={classes.right}>
                    {props.name === "Vendég" && null}
                    {props.name === "Basic"
                        ? props.data.dutyWeekday === "korlátlan"
                            ? ""
                            : `- ${props.data.dutyP}%`
                        : ""}
                    {(props.name === "Optimum" || props.name === "Premium") && (
                        <div className={classes.highlight}>
                            {props.data.dutyP}
                        </div>
                    )}
                    <span style={{ display: "flex", flexDirection: "column" }}>
                        {props.data.dutyWeekend === "korlátlan" ? (
                            props.name === "Basic" ? (
                                "korlátlan"
                            ) : (
                                "0 Ft"
                            )
                        ) : (
                            <>
                                <span style={{ whiteSpace: "nowrap" }}>
                                    H-P {props.data.dutyWeekday} Ft
                                </span>
                                <span style={{ whiteSpace: "nowrap" }}>
                                    SZ-V {props.data.dutyWeekend} Ft
                                </span>
                            </>
                        )}
                    </span>
                </span>
            </li>
            <li>
                <span className={classes.left}>Orvosi chat</span>
                <span className={classes.right}>
                    {props.name === "Vendég" && null}
                    {props.name === "Basic" && `- ${props.data.chatP}%`}
                    {(props.name === "Optimum" || props.name === "Premium") && (
                        <div className={classes.highlight}>
                            {props.data.chatP}
                        </div>
                    )}
                    <span style={{ display: "flex", flexDirection: "column" }}>
                        {props.data.chat === "korlátlan"
                            ? "0 Ft"
                            : `${props.data.chat} Ft`}
                    </span>
                </span>
            </li>
            <li>
                <span className={classes.left}>Másodvélemény</span>
                <span className={classes.right}>
                    {props.name === "Vendég" ? null : (
                        <span
                            className={classes.right}
                        >{`-${props.data.secondOpinionP}%`}</span>
                    )}
                    <span style={{ display: "flex", flexDirection: "column" }}>
                        {props.data.secondOpinion} Ft
                    </span>
                </span>
            </li>
            <li>
                <span className={classes.left}>Videókonzultáció</span>
                <span className={classes.right}>
                    {props.name === "Vendég" ? null : (
                        <span
                            className={classes.right}
                        >{`-${props.data.videoConsultationP}%`}</span>
                    )}
                    <span style={{ display: "flex", flexDirection: "column" }}>
                        {props.data.videoConsultation}
                    </span>
                </span>
            </li>
            <li>
                <span className={classes.left}>Járóbetegellátás</span>
                <span className={classes.right}>
                    {props.name === "Vendég" ? null : (
                        <span
                            className={classes.right}
                        >{`-${props.data.outpatientP}%`}</span>
                    )}
                </span>
            </li>
            <li>
                <span className={classes.left}>
                    {props.name === "Vendég"
                        ? "Időpont foglalás listaáron"
                        : `Akár ${props.data.outpatientP}% kedvezmény a foglalás árából`}
                </span>
            </li>
        </ul>
    );
};

export default SubscriptionDetails;
