import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Colors, Icons } from "../../../assets/Theme";
import classes from "./Btn.module.css";

const getStyle = (color) => {
    switch (color) {
        case "green":
            return classes.green;
        case "yellow":
            return classes.yellow;
        case "transparent":
            return classes.transparent;
        case "transparent-green":
            return classes.transparentGreen;
        default:
            return classes.green;
    }
};

const BtnWithIcon = (props) => (
    <button
        className={`${classes.BtnWithIcon} ${getStyle(props.color)} ${
            props.className ? props.className : ""
        } ${props.selected ? classes.selected : ""}`}
        style={
            props.disabled
                ? { ...props.style, ...styles.disabled }
                : props.style
        }
        onClick={props.onClick}
    >
        {props.text || props.children}
        {props.selected && (
            <img src={Icons.tickGreen} className={classes.tick} alt="" />
        )}
        {!props.selected &&
            (props.image || <ChevronRightIcon style={styles.chevron} />)}
    </button>
);

const styles = {
    chevron: {
        width: "3rem",
        height: "100%",
        position: "absolute",
        top: "0",
        right: "2rem",
    },
    disabled: {
        borderColor: Colors.grey,
        backgroundColor: Colors.grey,
        color: "#fff",
        pointerEvents: "none",
    },
};

export default BtnWithIcon;
