import { useEffect, useRef, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import CardContainer from "../../../components/UI/CardContainer/CardContainer";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import Line from "../../../components/UI/Line/Line";
import Input from "../../../components/UI/Input/Input";
import CountryPicker from "../../../components/UI/CountryPicker/CountryPicker";
import SaveButton from "../../../components/UI/SaveButton/SaveButton";
import SubscriptionCard from "../../../components/UI/SubscriptionCard/SubscriptionCard";
import BtnWithIcon from "../../../components/UI/Btn/BtnWithIcon";
import Btn from "../../../components/UI/Btn/Btn";
import { Icons } from "../../../assets/Theme";
import { endLoading, get, post, startLoading } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import { convertDate, formatNumber, responseMessage } from "../../../utility";
import * as actions from "../../../store/actions";
import NotificationWarning from "../../../components/UI/NotificationWarning/NotificationWarning";
import { basic, optimum, premium } from "../../../config/subscriptions";
import useTranslation from "../../../features/i18n/useTranslation";
import useQuery from "../../../features/util/useQuery";
import CardInfos from "./CardInfos/CardInfos";
import classes from "./PaymentSettings.module.css";

const PackageInfos = (props) => (
    <>
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <p className={classes.title}>Előfizetési csomag információi</p>
            </Grid>
            <Grid item>
                <p className={classes.linkText} onClick={props.onEdit}>
                    {props.editing ? "Mégsem" : "Előfizetés módosítása"}
                </p>
            </Grid>
        </Grid>
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.infoWrapper}
        >
            <Grid item>
                <Grid container spacing={1}>
                    {props.package !== "Előfizetés nélkül" && (
                        <Grid item>
                            <img
                                src={Icons.tickGreen}
                                className={classes.icon}
                                alt=""
                            ></img>
                        </Grid>
                    )}
                    <Grid item>
                        <p
                            className={classes.cardText}
                            style={{ fontWeight: "bold" }}
                        >
                            {props.package !== "Előfizetés nélkül"
                                ? `${props.package} előfizetési csomag`
                                : "Előfizetés nélkül"}
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <p
            className={classes.cardText}
            style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
            Következő havi csomag: {props.nextPackage}{" "}
        </p>
        {props.editing && (
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <SubscriptionCard
                        name="Basic"
                        yellowText={
                            props.canUseTrial ? "30 napig ingyenes" : false
                        }
                        buttonComponent={props.getButton(2)}
                        // buttonComponent={userDatas.canUseTrial && <BtnWithIcon color="yellow" text="Kipróbálom" className={classes.button} />}
                        hasTrialPeriod={props.canUseTrial}
                        description={basic.text}
                        price={basic.price}
                        open={props.subscriptionDetails}
                        data={basic}
                    ></SubscriptionCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SubscriptionCard
                        name="Optimum"
                        buttonComponent={props.getButton(3)}
                        greenText="Ajánlott"
                        description={optimum.text}
                        price={optimum.price}
                        open={props.subscriptionDetails}
                        data={optimum}
                    ></SubscriptionCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SubscriptionCard
                        name="Premium"
                        buttonComponent={props.getButton(4)}
                        description={premium.text}
                        price={premium.price}
                        open={props.subscriptionDetails}
                        data={premium}
                    ></SubscriptionCard>
                </Grid>
            </Grid>
        )}
        <Line style={{ marginTop: "4rem" }} />
    </>
);

const CouponInfos = (props) => (
    <>
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <p className={classes.title}>Kuponjaim</p>
            </Grid>
        </Grid>
        {props.couponData}
        <Line style={{ marginTop: "4rem" }} />
    </>
);

const PaymentInfos = (props) => (
    <>
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <p className={classes.title}>Fizetési adatok</p>
            </Grid>
        </Grid>
        <p className={classes.cardText}>{props.packagePrice} Ft /hónap</p>
        <p className={classes.cardText}>
            Következő fizetés ütemezve: {props.nextCharge}
        </p>
        <p className={classes.cardText}>Hónapforduló: {props.nextPayment}</p>
        <p className={classes.cardText}>
            {props.defaultCard
                ? `Elsődleges bankkártya: ${props.defaultCard.name}`
                : ""}
        </p>
        <Line style={{ marginTop: "4rem" }} />
    </>
);

const ServiceInfos = (props) => (
    <>
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <p className={classes.title}>
                    Csomagban lévő havi ingyenes szolgáltatások
                </p>
            </Grid>
        </Grid>
        <p
            className={classes.cardText}
            style={{ fontWeight: "600", marginBottom: "1rem" }}
        >
            <span>Időszak:</span> {props.interval}
        </p>
        <Grid container>
            <Grid item md={3} xs={6}>
                <span className={classes.cardText}>Ovosi chat:</span>
            </Grid>
            <Grid item>
                <span className={classes.cardText}>{props.chat}</span>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item lg={3} md={4} xs={6}>
                <span className={classes.cardText}>Ügyelet hívása:</span>
            </Grid>
            <Grid item>
                <span className={classes.cardText}>{props.duty}</span>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item lg={3} md={4} xs={6}>
                <span className={classes.cardText}>Hotline hívása:</span>
            </Grid>
            <Grid item>
                <span className={classes.cardText}>{props.hotline}</span>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item lg={3} md={4} xs={6}>
                <span className={classes.cardText}>Másodvélemény:</span>
            </Grid>
            <Grid item>
                <span className={classes.cardText}>{props.secondOpinion}</span>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item lg={3} md={4} xs={6}>
                <span className={classes.cardText}>Videókonzultáció:</span>
            </Grid>
            <Grid item>
                <span className={classes.cardText}>
                    {props.videoConsultation}
                </span>
            </Grid>
        </Grid>
        <Line style={{ marginTop: "4rem" }} />
    </>
);

const BillingInfos = (props) => (
    <>
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <div className={classes.titleContainer}>
                    {props.empty && (
                        <NotificationWarning style={{ marginRight: "1rem" }} />
                    )}
                    <p className={classes.title}>Számlázási adatok</p>
                </div>
            </Grid>
            {!props.editing && (
                <Grid item>
                    <p className={classes.linkText} onClick={props.onEditClick}>
                        Szerkesztés
                    </p>
                </Grid>
            )}
        </Grid>
        {!props.editing ? (
            <>
                <p className={classes.cardText} style={{ fontWeight: "bold" }}>
                    Számlázási név
                </p>
                <p className={classes.cardText}>{props.billingName}</p>
                <p
                    className={classes.cardText}
                    style={{ marginTop: "2rem", fontWeight: "bold" }}
                >
                    Cím
                </p>
                <p className={classes.cardText}>{props.billing}</p>
                <p
                    className={classes.cardText}
                    style={{ marginTop: "2rem", fontWeight: "bold" }}
                >
                    Adószám
                </p>
                <p className={classes.cardText}>{props.taxNumber}</p>
            </>
        ) : (
            props.editingComponent
        )}
        <Line style={{ marginTop: "4rem" }} />
    </>
);

let originalBillingData = {};
const prices = [null, 0, 4990, 9990, 29990];

const PaymentSettings = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();
    const [coupons, setCoupons] = useState(null);
    const [cards, setCards] = useState(null);
    const [userDatas, setUserDatas] = useState({
        billing: null,
        billingName: null,
        billingCountry: null,
        billingZip: null,
        billingSettlement: null,
        billingStreet: null,
        billingHouseNumber: null,
        billingFloorDoor: null,
        billingTaxNumber: null,
        currentPackage: false,
        currentPackageId: 1,
        nextMonthPackage: false,
        canUseTrial: false,
    });
    const [editBilling, setEditBilling] = useState(false);
    const [editSubscription, setEditSubscription] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [userSubscription, setUserSubscription] = useState(null);

    const [monthlyEvents, setMonthlyEvents] = useState(null);
    const [extraServices, setExtraServices] = useState(null);

    const inputRef = useRef();
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (query.has("TransactionId")) {
            const promise1 = paymentCallback();
            const promise2 = getProfileSummary();
            Promise.all([promise1, promise2]);
        } else {
            getProfileSummary();
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getCoupons();
    }, []);

    useEffect(() => {
        get("user/get-service-data").then((response) => {
            switch (response.data.responseCode) {
                case "OK":
                    setExtraServices(response.data.extraServices);
                    setMonthlyEvents(response.data.monthlyEvents);

                    break;
                default:
                    break;
            }
            endLoading();
        });
    }, []);

    useEffect(() => {
        if (query.has("openSub")) {
            setEditSubscription(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // New card added
    const paymentCallback = () => {
        if (query.has("TransactionId")) {
            const transactionId = query.get("TransactionId");
            const immediateToken = query.get("immediateToken");
            post("api/v1/payment/payment-init-callback", {
                transactionId,
                cardName: localStorage.getItem("newCardName") || null,
                immediateToken,
                oneClick: "true",
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage(
                                succesFullResponseText(
                                    response.data.ProviderTransactionId,
                                    immediateToken
                                )
                            );
                            endLoading();

                            navigate({ pathname: "/empty" });
                            navigate(
                                {
                                    pathname: i18nRoute(
                                        "routes.profile.paymentMethods"
                                    ),
                                },
                                { replace: true }
                            );

                            break;
                        case "PAYMENT_ERROR":
                            switch (response.data.errorCode) {
                                case "ERROR":
                                    responseMessage(
                                        unSuccesfullResponseText(
                                            response.data.ProviderTransactionId
                                        )
                                    );
                                    endLoading();

                                    break;
                                case "CANCELED":
                                    responseMessage(
                                        "Megszakítottad a fizetési folyamatot. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "TIMEOUT":
                                    responseMessage(
                                        "Túllépted a tranzakció elindításakor megengedett biztonságos időkaput. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "PENDING":
                                    startLoading();
                                    setTimeout(paymentCallback, 3000);

                                    break;
                            }

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                            endLoading();
                    }
                    localStorage.removeItem("newCardName");
                    navigate("/profile/payment-methods", { replace: true });
                })
                .catch((error) => {
                    console.error(error);
                    navigate("/profile/payment-methods", { replace: true });
                    endLoading();
                });
        }
    };

    const succesFullResponseText = (transactionId, addedSubscription) => (
        <>
            <p style={styles.responseText}>Sikeres tranzakció</p>
            <p style={styles.responseText}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
            {addedSubscription && (
                <p style={styles.responseText}>
                    A kiválasztott előfizetésed aktiváltuk!
                </p>
            )}
        </>
    );

    const unSuccesfullResponseText = (transactionId) => (
        <>
            <p style={styles.responseText}>Sikertelen tranzakció</p>
            <p style={styles.responseText}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
            <p style={styles.responseText}>
                Kérjük, ellenőrizze a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adott meg, a
                visszautasítás okának kivizsgálása érdekében kérjük,
                szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
            </p>
        </>
    );

    // Basic functions

    const getProfileSummary = () =>
        get("user/get-profile-summary").then((response) => {
            if (response.data.responseCode === "OK") {
                setSubscription(response.data.subscription);
                setUserSubscription(response.data.userSubscription);
                let b = response.data.billing[0] || {};
                b.country = b.country || "Magyarország";
                let billing = billingAddress(
                    b.country,
                    b.zip,
                    b.settlement,
                    b.street,
                    b.house_number,
                    b.floor_door
                );
                const billingData = {
                    billing,
                    billingName: b.name,
                    billingCountry: b.country,
                    billingZip: b.zip,
                    billingSettlement: b.settlement,
                    billingStreet: b.street,
                    billingHouseNumber: b.house_number,
                    billingFloorDoor: b.floor_door,
                    billingTaxNumber: b.tax_number,
                    currentPackage: response.data.subscription.name,
                    currentPackageId: +response.data.subscription.id,
                    nextMonthPackage:
                        response.data.subscription.nextMonthPackage,
                    canUseTrial: response.data.userSubscription.canUseTrial,
                };
                setUserDatas(billingData);
                originalBillingData = billingData;
                let cards = [];
                for (let c of response.data.cards) {
                    if (!c.name) c.name = "Bankártya";
                    c["defaultCard"] =
                        response.data.user.defaultCard == c.id ? 1 : 0;
                    cards.push(c);
                }
                cards.sort((a, b) => b.defaultCard - a.defaultCard);
                setCards(cards);
            }
            endLoading();
        });

    const billingAddress = (
        country,
        zip,
        settlement,
        street,
        houseNumber,
        floorDoor
    ) => {
        if (!country || !zip || !settlement || !street || !houseNumber) {
            return null;
        }

        return (
            country +
            " " +
            zip +
            ", " +
            settlement +
            " " +
            street +
            " " +
            houseNumber +
            " " +
            (floorDoor ? floorDoor : "")
        );
    };

    const setUserDatasBykey = (key, value) =>
        setUserDatas({
            ...userDatas,
            [key]: value,
        });

    // Coupon functions

    const getCoupons = () =>
        get("api/v1/coupon/get-coupons")
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        setCoupons(response.data.data);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });

    const explainCouponType = (coupon) => {
        switch (+coupon.type) {
            case 1:
                switch (+coupon.packageId) {
                    case 2:
                        return `Ajándék Basic előfizetés ${coupon.discountIntervalDays} napig`;
                    case 3:
                        return `Ajándék Optimum előfizetés ${coupon.discountIntervalDays} napig`;
                    case 4:
                        return `Ajándék Premium előfizetés ${coupon.discountIntervalDays} napig`;
                }

                break;
            case 2:
                return `Előfizetésre ${formatNumber(
                    coupon.discountAmount
                )} Ft kedvezmény`;
            case 3:
                return `Előfizetésre ${coupon.discountPercentage}% kedvezmény`;
            case 4:
                switch (coupon.service) {
                    case "HOTLINE":
                        return `Hotline hívása ${coupon.discountIntervalDays} napig ingyen`;
                    case "DUTY":
                        return `Orvosi ügyelet hívása ${coupon.discountIntervalDays} napig ingyen`;
                    case "CHAT":
                        return `Orvosi chat ${coupon.discountIntervalDays} napig ingyen`;
                    case "SECOND_OPINION":
                        return `Orvosi másodvélemény ${coupon.discountIntervalDays} napig ingyen`;
                }

                break;
            case 5:
                switch (coupon.service) {
                    case "HOTLINE":
                        return `Hotline hívása ${formatNumber(
                            coupon.discountAmount
                        )} Ft kedvezménnyel`;
                    case "DUTY":
                        return `Orvosi ügyelet hívása ${formatNumber(
                            coupon.discountAmount
                        )} Ft kedvezménnyel`;
                    case "CHAT":
                        return `Orvosi chat ${formatNumber(
                            coupon.discountAmount
                        )} Ft kedvezménnyel`;
                    case "SECOND_OPINION":
                        return `Orvosi másodvélemény ${formatNumber(
                            coupon.discountAmount
                        )} Ft kedvezménnyel`;
                    case "VIDEO_CONSULTATION":
                        return `Videó konzultáció ${formatNumber(
                            coupon.discountAmount
                        )} Ft kedvezménnyel`;
                    case "OUTPATIENT":
                        return `Járóbeteg időpontfoglalás ${formatNumber(
                            coupon.discountAmount
                        )} Ft kedvezménnyel`;
                }

                break;
            case 6:
                switch (coupon.service) {
                    case "HOTLINE":
                        return `Hotline hívása ${coupon.discountPercentage}% kedvezménnyel`;
                    case "DUTY":
                        return `Orvosi ügyelet hívása ${coupon.discountPercentage}% kedvezménnyel`;
                    case "CHAT":
                        return `Orvosi chat ${coupon.discountPercentage}% kedvezménnyel`;
                    case "SECOND_OPINION":
                        return `Orvosi másodvélemény ${coupon.discountPercentage}% kedvezménnyel`;
                }

                break;
        }
    };

    const getCouponStatus = (coupon) => {
        let expiresAt = coupon.expiresAt
            ? new Date(coupon.expiresAt.replace(" ", "T"))
            : null;
        if (
            coupon.available == "1" &&
            (expiresAt === null || expiresAt.getTime() > new Date().getTime())
        ) {
            return "Felhasználható";
        } else if (expiresAt && expiresAt.getTime() < new Date().getTime()) {
            return "Lejárt";
        } else if (coupon.available != "1" && coupon.used == "1") {
            return "Felhasznált";
        } else if (coupon.available != "1" && coupon.used != "1") {
            return "Nem felhasznált";
        }
    };

    const getCouponEndStatus = (coupon) => {
        let expiresAt = coupon.expiresAt
            ? new Date(coupon.expiresAt.replace(" ", "T"))
            : null;
        if (
            coupon.available == "1" &&
            (expiresAt === null || expiresAt.getTime() > new Date().getTime())
        ) {
            if (expiresAt) {
                return `${convertDate(expiresAt, ".", true)}-ig`;
            }

            return "";
        } else if (expiresAt && expiresAt.getTime() < new Date().getTime()) {
            return `${convertDate(expiresAt, ".", true)}`;
        } else if (coupon.available != "1" && coupon.used == "1") {
            return `${convertDate(
                new Date(coupon.usedAt.replace(" ", " ")),
                ".",
                true
            )}`;
        } else if (coupon.available != "1" && coupon.used != "1") {
            return "";
        }
    };

    const couponElement = (coupon) => (
        <div key={coupon.id} style={{ marginBottom: "2rem" }}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <p
                        className={classes.cardText}
                        style={{ fontWeight: "bold" }}
                    >
                        {explainCouponType(coupon)}
                    </p>
                </Grid>
                <Grid item>
                    <p
                        className={classes.cardText}
                        style={{ fontWeight: "bold" }}
                    >
                        {getCouponStatus(coupon)}
                    </p>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="space-between"
                style={{ marginTop: "2rem" }}
            >
                <Grid item>
                    <p className={classes.cardText}>
                        Kupon kód: {coupon.couponCode}
                    </p>
                </Grid>
                <Grid item>
                    <p className={classes.cardText}>
                        {getCouponEndStatus(coupon)}
                    </p>
                </Grid>
            </Grid>
            <p className={classes.cardText}>
                Beváltás ideje:{" "}
                {convertDate(
                    new Date(coupon.createdAt.replace(" ", " ")),
                    ".",
                    true
                )}
            </p>
            {coupon.publicComment && (
                <p className={classes.cardText}>
                    Komment: {coupon.publicComment}
                </p>
            )}
        </div>
    );

    const redeemCoupon = () => {
        const code = inputRef.current.value;
        if (code) {
            post("api/v1/coupon/redeem-coupon", { couponCode: code })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK": {
                            responseMessage("A kupont sikeresen beváltottad!");
                            let currentPage = location.pathname;

                            navigate({ pathname: "/empty" });
                            navigate(
                                { pathname: currentPage },
                                { replace: true }
                            );

                            break;
                        }
                        case "WRONG_CODE":
                            responseMessage("Hibás kuponkód!");

                            break;
                        case "ALREADY_REDEEMED":
                            responseMessage(
                                "Ezt a kupont már beváltottad egyszer!"
                            );

                            break;
                        case "ONLY_WITHOUT_SUBSCRIPTION":
                            responseMessage(
                                "Ezt a kupont csak előfizetés nélkül lehet beváltani!"
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    // Billing functions

    const renderBillingEditingComponent = () => (
        <div style={{ fontSize: "1.6rem" }}>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item lg={2} md={3} xs={12}>
                    <span>
                        Számlázási név<sup>*</sup>
                    </span>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Input
                        className={classes.input}
                        defaultValue={userDatas.billingName}
                        onChange={(e) =>
                            setUserDatasBykey("billingName", e.target.value)
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item lg={2} md={3} xs={12}>
                    <span>
                        Ország<sup>*</sup>
                    </span>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <CountryPicker
                        value={userDatas.billingCountry}
                        onChange={(e) => setUserDatasBykey("billingCountry", e)}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item lg={2} md={3} xs={12}>
                    <span>
                        Irányítószám<sup>*</sup>
                    </span>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Input
                        className={classes.input}
                        type="number"
                        defaultValue={userDatas.billingZip}
                        onChange={(e) =>
                            setUserDatasBykey("billingZip", e.target.value)
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item lg={2} md={3} xs={12}>
                    <span>
                        Település<sup>*</sup>
                    </span>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Input
                        className={classes.input}
                        defaultValue={userDatas.billingSettlement}
                        onChange={(e) =>
                            setUserDatasBykey(
                                "billingSettlement",
                                e.target.value
                            )
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item lg={2} md={3} xs={12}>
                    <span>
                        Utca<sup>*</sup>
                    </span>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Input
                        className={classes.input}
                        defaultValue={userDatas.billingStreet}
                        onChange={(e) =>
                            setUserDatasBykey("billingStreet", e.target.value)
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item lg={2} md={3} xs={12}>
                    <span>
                        Házszám<sup>*</sup>
                    </span>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Input
                        className={classes.input}
                        defaultValue={userDatas.billingHouseNumber}
                        onChange={(e) =>
                            setUserDatasBykey(
                                "billingHouseNumber",
                                e.target.value
                            )
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item lg={2} md={3} xs={12}>
                    <span>Emelet / Ajtó</span>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Input
                        className={classes.input}
                        defaultValue={userDatas.billingFloorDoor}
                        onChange={(e) =>
                            setUserDatasBykey(
                                "billingFloorDoor",
                                e.target.value
                            )
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item lg={2} md={3} xs={12}>
                    <span>Adószám</span>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Input
                        className={classes.input}
                        defaultValue={userDatas.billingTaxNumber}
                        onChange={(e) =>
                            setUserDatasBykey(
                                "billingTaxNumber",
                                e.target.value
                            )
                        }
                    />
                </Grid>
            </Grid>
            <p
                className={classes.cardText}
                style={{ fontWeight: "bold", marginTop: "2rem" }}
            >
                <sup>*</sup> Kötelező mező
            </p>
            <SaveButton
                withCancel
                saveHandler={saveBillingHandler}
                cancelHandler={() => {
                    setEditBilling(false);
                    setUserDatas(originalBillingData);
                }}
            />
        </div>
    );

    const saveBillingHandler = () => {
        let billing = billingAddress(
            userDatas.billingCountry,
            userDatas.billingZip,
            userDatas.billingSettlement,
            userDatas.billingStreet,
            userDatas.billingHouseNumber,
            userDatas.billingFloorDoor
        );
        setUserDatas({ ...userDatas, billing });
        post("payment/update-user-billing-data", {
            data: {
                name: userDatas.billingName,
                country: userDatas.billingCountry,
                zip: userDatas.billingZip,
                settlement: userDatas.billingSettlement,
                street: userDatas.billingStreet,
                house_number: userDatas.billingHouseNumber,
                floor_door: userDatas.billingFloorDoor,
                tax_number: userDatas.billingTaxNumber,
            },
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        setEditBilling(false);

                        break;
                    case "EMPTY_BILLING_NAME":
                        responseMessage("Hiányzó számlázási név!");

                        break;
                    case "EMPTY_BILLING_COUNTRY":
                        responseMessage("Hiányzó számlázási ország!");

                        break;
                    case "EMPTY_BILLING_ZIP":
                        responseMessage("Hiányzó számlázási irányítószám!");

                        break;
                    case "EMPTY_BILLING_SETTLEMENT":
                        responseMessage("Hiányzó számlázási település!");

                        break;
                    case "EMPTY_BILLING_STREET":
                        responseMessage("Hiányzó számlázási utca!");

                        break;
                    case "EMPTY_BILLING_HOUSE_NUMBER":
                        responseMessage("Hiányzó számlázási házszám!");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    // Subscription functions

    const getButton = (packageId) => {
        const buttonClick = () => buttonClickHandler(packageId);
        if (userDatas.currentPackageId == packageId) {
            return (
                <Btn
                    color="transparent-red"
                    text="Lemondás"
                    onClick={buttonClick}
                />
            );
        }
        switch (+packageId) {
            case 2:
                if (userDatas.canUseTrial) {
                    return (
                        <BtnWithIcon
                            color="yellow"
                            text="Kipróbálom"
                            onClick={buttonClick}
                        />
                    );
                }

                return (
                    <Btn
                        color="transparent-green"
                        text="Váltás"
                        onClick={buttonClick}
                    />
                );

            case 3:
                return (
                    <Btn
                        color="transparent-green"
                        text="Váltás"
                        onClick={buttonClick}
                    />
                );
            case 4:
                return (
                    <Btn
                        color="transparent-green"
                        text="Váltás"
                        onClick={buttonClick}
                    />
                );
        }
    };

    const buttonClickHandler = (packageId) => {
        if (packageId > userDatas.currentPackageId) {
            post("api/v1/subscription/init-subscription-change", { packageId })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            props.toggleResponseModal(true, {
                                title: "Csomag váltás",
                                content: `Jó döntés!\n Nagyobb előfizetésre szeretnél váltani. A váltás megerősítését követően az elsődleges kártyádat beterheljük a jelenlegi előfizetésed és a kiválasztott előfizetés közötti árkülönbözetet (${formatNumber(
                                    response.data.scale
                                )} Ft). Kattints a Megerősítem gombra, és rögtön élvezheted új előfizetésed minden előnyét.`,
                                confirmButton: true,
                                confirmButtonText: "Megerősítem",
                                confirmButtonCallback: () =>
                                    subscriptionChangeHandler(packageId),
                                cancelButton: true,
                                cancelButtonText: "Mégsem",
                                cancelButtonCallback: null,
                                disableBackdropClick: false,
                            });

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                });
        } else if (packageId < userDatas.currentPackageId) {
            props.toggleResponseModal(true, {
                title: "Csomag váltás",
                content: `Biztosan kisebb előfizetésre szeretnél váltani? Kisebb előfizetésre történő váltás esetén jelenlegi csomagod előnyei hónapfordulóval elvesznek. Megerősítés esetén jelenlegi előfizetésed a hónap fordulóig tudod használni, ezt követően aktiváljuk kisebb előfizetésed. Új előfizetésed díja: ${formatNumber(
                    prices[packageId]
                )} Ft.`,
                confirmButton: true,
                confirmButtonText: "Megerősítem",
                confirmButtonCallback: () =>
                    subscriptionChangeHandler(packageId),
                cancelButton: true,
                cancelButtonText: "Mégsem",
                cancelButtonCallback: null,
                disableBackdropClick: false,
            });
        } else if (packageId == userDatas.currentPackageId) {
            cancelSubscriptionHandler();
        }
    };

    const subscriptionChangeHandler = (subscription) =>
        post("api/v1/subscription/change-subscription", {
            packageId: subscription,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("");
                        let currentPage = location.pathname;

                        navigate({ pathname: "/empty" });
                        navigate({ pathname: currentPage }, { replace: true });

                        props.toggleResponseModal(true, {
                            content: `Előfizetését sikeresen módosította!`,
                            confirmButton: true,
                            confirmButtonText: "Rendben",
                            confirmButtonCallback: () => {
                                // noop?
                            },
                            cancelButton: false,
                            disableBackdropClick: true,
                        });

                        break;
                    }
                    case "PAYMENT_FAILED":
                        responseMessage(
                            "Hiba lépett fel fizetés közben. Kérjük, próbáld meg újból!"
                        );

                        break;
                    case "EMPTY_DEFAULT_CARD":
                        responseMessage("Nincs megadva elsődleges kártya.");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });

    const cancelSubscriptionHandler = () =>
        props.toggleResponseModal(true, {
            title: "Előfizetés lemondása",
            content: `Biztosan le szeretnéd mondani az előfizetésed? Amennyiben igen, előfizetésed hónapfordulójáig még élvezheted annak minden előnyét. A hónapfordulót követően szolgáltatásainkat alap áron tudod majd igénybe venni.`,
            confirmButton: true,
            confirmButtonText: "Megerősítem",
            confirmButtonCallback: () => subscriptionChangeHandler(1),
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    // Service functions

    const getInterval = (endDate) => {
        let date = new Date(endDate);
        date.setDate(date.getDate() - 30);
        let dateString = date.toISOString().split("T")[0]; // "2016-06-08"

        return `${dateString.replace(/-/g, ".")} - ${endDate.replace(
            /-/g,
            "."
        )}`;
    };

    const getRemaining = (eventName) => {
        let remaining;
        remaining = extraServices.filter(
            (s) =>
                s.service_name === eventName &&
                s.available == "1" &&
                (s.expires_at === null ||
                    new Date(s.expires_at.replace(" ", "T")) > new Date())
        ).length;
        if (eventName === "CHAT") {
            remaining = +subscription.free_chat_per_month + remaining;
            if (
                monthlyEvents.filter((e) => e.event_name === eventName)
                    .length >= +subscription.free_chat_per_month
            ) {
                remaining -= +subscription.free_chat_per_month;
            }
        }
        if (eventName === "DUTY") {
            remaining = +subscription.free_chat_per_month + remaining;
            if (
                monthlyEvents.filter((e) => e.event_name === eventName)
                    .length >= +subscription.free_duty_per_month
            ) {
                remaining -= +subscription.free_duty_per_month;
            }
        }
        if (remaining < 0) {
            remaining = 0;
        }

        return remaining;
    };

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs />
                <CardContainer className={classes.card}>
                    <Grid container>
                        <Grid sm={2} xs={12} item>
                            <img
                                src={Icons.paymentGreen}
                                style={{ fill: "#f0f" }}
                                className={classes.mainIcon}
                                alt=""
                            />
                        </Grid>
                        <Grid sm={8} xs={12} item>
                            <h2 className={classes.header}>
                                Fizetési beállítások
                            </h2>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={2} xs={12}></Grid>
                        <Grid item sm={8} xs={12}>
                            <div>
                                <p className={classes.title}>
                                    Kupon, vagy utalvány beváltása
                                </p>
                                <p className={classes.cardText}>Kuponkód</p>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <Input bigger ref={inputRef} />
                                    </Grid>
                                    <Grid item>
                                        <div
                                            className={classes.redeemBtn}
                                            onClick={() => redeemCoupon()}
                                        >
                                            <span
                                                className={classes.redeemText}
                                            >
                                                Érvényesít
                                            </span>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Line style={{ marginTop: "4rem" }} />
                            </div>
                            {subscription && (
                                <PackageInfos
                                    // onCancel={cancelSubscriptionHandler}
                                    package={subscription.name}
                                    nextPackage={subscription.nextMonthPackage}
                                    getButton={getButton}
                                    canUseTrial={userDatas.canUseTrial}
                                    onEdit={() =>
                                        setEditSubscription((prev) => !prev)
                                    }
                                    editing={editSubscription}
                                    subscriptionDetails={
                                        props.subscriptionDetails
                                    }
                                />
                            )}
                            {coupons ? (
                                <CouponInfos
                                    couponData={coupons.map((coupon) =>
                                        couponElement(coupon)
                                    )}
                                />
                            ) : (
                                <Skeleton variant="rect" height={400} />
                            )}

                            {subscription && cards && (
                                <PaymentInfos
                                    packagePrice={formatNumber(
                                        subscription.package_price
                                    )}
                                    nextCharge={
                                        userSubscription.next_charge
                                            ? userSubscription.next_charge.replace(
                                                  /-/g,
                                                  "."
                                              )
                                            : "-"
                                    }
                                    nextPayment={
                                        userSubscription.payment_next_date
                                            ? userSubscription.payment_next_date.replace(
                                                  /-/g,
                                                  "."
                                              )
                                            : "-"
                                    }
                                    defaultCard={cards.find(
                                        (c) => c.defaultCard == 1
                                    )}
                                />
                            )}
                            {cards !== null && (
                                <CardInfos
                                    onAddCard={() => getProfileSummary()}
                                    cards={cards}
                                    empty={cards.length === 0}
                                />
                            )}
                            {userSubscription &&
                                extraServices &&
                                monthlyEvents &&
                                subscription && (
                                    <ServiceInfos
                                        interval={
                                            userSubscription.payment_next_date
                                                ? getInterval(
                                                      userSubscription.payment_next_date
                                                  )
                                                : ""
                                        }
                                        chat={
                                            subscription
                                                ? ` ${
                                                      subscription.free_chat_per_month
                                                  } - Hátralévő: ${getRemaining(
                                                      "CHAT"
                                                  )}`
                                                : ""
                                        }
                                        duty={
                                            subscription
                                                ? ` ${
                                                      subscription.free_duty_per_month
                                                  } - Hátralévő: ${getRemaining(
                                                      "DUTY"
                                                  )}`
                                                : ""
                                        }
                                        hotline={
                                            subscription
                                                ? ` 0 - Hátralévő: ${getRemaining(
                                                      "HOTLINE"
                                                  )}`
                                                : ""
                                        }
                                        secondOpinion={
                                            subscription
                                                ? ` 0 - Hátralévő: ${getRemaining(
                                                      "SECOND_OPINION"
                                                  )}`
                                                : ""
                                        }
                                        videoConsultation={
                                            subscription
                                                ? ` 0 - Hátralévő: ${getRemaining(
                                                      "VIDEO_CONSULTATION"
                                                  )}`
                                                : ""
                                        }
                                    />
                                )}

                            <BillingInfos
                                empty={!userDatas.billing}
                                onEditClick={() => setEditBilling(true)}
                                editing={editBilling}
                                editingComponent={renderBillingEditingComponent()}
                                billingName={
                                    userDatas.billingName
                                        ? userDatas.billingName
                                        : "Nincs megadva"
                                }
                                billing={
                                    userDatas.billing
                                        ? userDatas.billing
                                        : "Nincs megadva"
                                }
                                taxNumber={
                                    userDatas.billingTaxNumber
                                        ? userDatas.billingTaxNumber
                                        : "Nincs megadva"
                                }
                            />
                        </Grid>
                    </Grid>
                </CardContainer>
            </div>
        </Container>
    );
};

const styles = {
    responseText: {
        marginBottom: "1rem",
    },
};

const mapStateToProps = (state) => ({
    subscriptionDetails: state.ui.subscriptionDetails,
});

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSettings);
