import { connect } from "react-redux";
import { endLoading, post } from "../config/fetch";

let file = null;

const ChatTest = () => <input type="file" id="input" onChange={fileChange} />;

const upload = (file) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("userId", "48");

        post("admin/upload", formData)
            .then(() => endLoading())
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    } catch (error) {
        console.error(error);
    }
};

const fileChange = (e) => {
    if (e?.target.files[0]) {
        file = e.target.files[0];
        upload(file);
    } else {
        file = null;
    }
};
const mapStateToProps = (store) => ({
    token: store.auth.token,
});

export default connect(mapStateToProps)(ChatTest);
