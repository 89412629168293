export enum ContentTypes {
    CAROUSEL = "carousel",
    GALLERY = "gallery",
    SINGLEIMAGE = "singleImage",
    IMAGEWITHTEXT = "imageWithText",
    VIDEOSECTION = "videoSection",
    TEXTFIELD = "textField",
    FORMSECTION = "formSection",
}

export interface Carousel {
    type: ContentTypes.CAROUSEL;
    sectionLabel?: string;
    images: string[];
}

export interface TextField {
    type: ContentTypes.TEXTFIELD;
    sectionLabel?: string;
    text: string;
    contained?: boolean;
    url?: string;
}

export interface ImageWithText {
    type: ContentTypes.IMAGEWITHTEXT;
    sectionLabel?: string;
    image: string;
    text: string;
    url: string;
}

export type GalleryItem = {
    image: string;
    title?: string;
    description?: string;
};

export interface Gallery {
    type: ContentTypes.GALLERY;
    sectionLabel?: string;
    galleryDescription?: string;
    galleryItems: GalleryItem[];
}

export interface SingleImage {
    type: ContentTypes.SINGLEIMAGE;
    sectionLabel?: string;
    image: string;
}

export interface VideoSection {
    type: ContentTypes.VIDEOSECTION;
    sectionLabel?: string;
    video: string;
    description: string;
}

export interface FormSection {
    type: ContentTypes.FORMSECTION;
    sectionLabel?: string;
    description: string;
}

export type ContentBlock =
    | Carousel
    | TextField
    | FormSection
    | Gallery
    | ImageWithText
    | SingleImage
    | VideoSection;

export interface BlogItem {
    category: string;
    title: string;
    image: string;
    date: string;
}
