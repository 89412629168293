import { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import dials from "../../../../assets/dials.json";
import { Flags } from "../../../../assets/Theme";
import classes from "./CountryPicker.module.css";

const CountryPicker = (props) => {
    const [value, setValue] = useState(props.value || "Magyarország");

    const renderOptions = () => {
        dials.sort((a, b) => a.key.localeCompare(b.key));

        return dials.map((item) => {
            let image =
                `flag${item.value}` in Flags
                    ? Flags[`flag${item.value}`]
                    : Flags["emptyFlag"];

            return (
                <MenuItem value={item.key} key={item.key}>
                    <div className={classes.optionContainer}>
                        <img src={image} className={classes.image} alt="" />
                        <span className={classes.selectText}>{item.key}</span>
                    </div>
                </MenuItem>
            );
        });
    };

    const handleChange = (event) => {
        setValue(event.target.value);
        if (props.onChange) {
            props.onChange(event.target.value);
        }
    };

    return (
        <Select
            style={{ width: "100%", ...props.style }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={handleChange}
        >
            {renderOptions()}
        </Select>
    );
};

export default CountryPicker;
