import { forwardRef, useEffect, useState } from "react";
import classNames from "classnames/bind";
import { useHumed } from "../../../../ui/hooks/useHumed";
import classes from "./Textarea.module.css";

const cx = classNames.bind(classes);

const Textarea = forwardRef(({ rows, value, onChange, maxLength }, ref) => {
    const isHumed = useHumed();
    const [text, setText] = useState(value);

    useEffect(() => {
        setText(value);
    }, [value]);

    const onChangeText = (e) => {
        if (e.target.value.length <= 500) {
            onChange(e.target.value);
        }
    };

    return (
        <div>
            <div className={cx(classes.container, { humed: isHumed })}>
                <textarea
                    className={classes.textarea}
                    ref={ref}
                    onChange={onChangeText}
                    rows={rows || 10}
                    maxLength={maxLength}
                    value={text}
                ></textarea>
            </div>
        </div>
    );
});

Textarea.displayName = "Textarea";

export default Textarea;
