import classes from "./classes.module.css";

const Alerts = () => (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
        <h3 className={classes.title} id="riasztas">
            Riasztást kaptam a mérésemet követően, ilyenkor mi a teendő?
        </h3>
        <p className={classes.text}>
            Először is győződj meg arról, hogy a mérési protokollt betartva
            végezted el a mérést. Ha lehetőséged van rá, akkor ismételd meg a
            mérést. Amennyiben továbbra is kiugró eredményt tapasztalsz, akkor
            hívd az Orvosi ügyeletet.
        </p>

        <h3 className={classes.title} id="elteres-ertesites">
            Eltérésről (figyelmeztetés) kaptam értesítést a mérésemet követően,
            mit csináljak?
        </h3>
        <p className={classes.text}>
            Először is győződj meg arról, hogy a mérési protokollt betartva
            végezted el a mérést. Ha lehetőséged van rá, akkor ismételd meg a
            mérést. Amennyiben továbbra is hasonló eredményt tapasztalsz, akkor
            keresd az Egészégügyi hotline-t, akik segítenek a megfelelő
            szakrendelés kiválasztásában.
        </p>

        <h3 className={classes.title} id="riasztasi-szint">
            Miért egy adott értéknél kapok riasztást? Ki határozza meg ezt a
            riasztási szintet?
        </h3>
        <p className={classes.text}>
            A személyre szabott riasztási és figyelmeztetési szintet a
            kezelőorvosod határozza meg.
        </p>

        <h3 className={classes.title} id="riasztasi-szintek">
            Milyen riasztási szintek vannak? Mi az alap érték?
        </h3>
        <p className={classes.text}>
            I. fokú figyelmeztetést a rendszer „Eltérés”-nek hívja és sárga
            színnel jelöli.
            <br />
            II. fokú riasztást a rendszer „Riasztás”-nak hívja és piros színnel
            jelöli.
            <br />
            Ha minden érték rendben van, akkor „Normál” címkével látja el a
            rendszer és zöld színnel jelöli.
        </p>

        <h3 className={classes.title} id="riasztas-gyakorisag">
            Milyen gyakran kapok riasztást/figyelmeztetést?
        </h3>
        <p className={classes.text}>
            Alapesetben minden mérés egyesével is kiértékelésre kerül, de
            riasztást, illetve figyelmeztetést ugyanazon méréstípus pl.
            vérnyomás esetén 30 percenként csak egyszer fogsz megkapni.
        </p>

        <h3 className={classes.title} id="ertesites-beallitasok">
            Nem kaptam értesítést, csak a grafikonon láttam, hogy rossz a mérési
            eredményem. Hol tudom beállítani?
        </h3>
        <p className={classes.text}>
            A mérési értesítéseket a T-DOC alkalmazásban az értesítési
            beállítások között tudod testre szabni.
        </p>
    </>
);

export default Alerts;
