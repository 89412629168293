import { OverlayView } from "@react-google-maps/api";
import classes from "./CustomMarker.module.css";

const CustomMarker = (props) => (
    <OverlayView
        position={props.position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
        <div onClick={props.onClick}>
            <div
                className={classes.assetMapImageMarker}
                style={{ backgroundColor: props.color }}
            >
                <div
                    className="image"
                    style={{ backgroundImage: `url(${props.image})` }}
                ></div>
            </div>
            <div
                className={classes.arrow}
                style={{ borderColor: `${props.color} transparent` }}
            ></div>
        </div>
    </OverlayView>
);

export default CustomMarker;
