import { Grid, Hidden } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Colors, Fonts } from "../../../assets/Theme";
import classes from "./DoctorRow.module.css";

const DoctorRow = (props) => {
    const data = props.data;

    const onReservationClick = () => {
        if (props.onReservationClick) {
            props.onReservationClick();
        }
    };

    return (
        <Grid container className={classes.infoRow} alignItems="center">
            <Hidden only={["xs"]}>
                <Grid container item md={10} sm={9}>
                    <Grid item xs={2}>
                        {data.image ? (
                            <img
                                className={classes.image}
                                src={data.image}
                                alt=""
                            ></img>
                        ) : (
                            <div
                                className={classes.image}
                                style={{ backgroundColor: "#E6E6E6" }}
                            ></div>
                        )}
                    </Grid>
                    <Grid item xs={10} className={classes.infoText}>
                        <p style={{ ...styles.title }}>{data.name}</p>
                        <p style={{ ...styles.text }}>
                            {data.qualifications.map((q) => q.name).join(", ")}
                        </p>
                        <p
                            style={{
                                ...styles.link,
                                fontWeight: "bold",
                                marginTop: "1rem",
                            }}
                            onClick={props.onLinkClick}
                        >
                            Adatlap megtekintése
                        </p>
                    </Grid>
                </Grid>

                <Grid item md={2} sm={3}>
                    <div
                        className={classes.reservationBtn}
                        onClick={onReservationClick}
                    >
                        <span style={styles.btnText}>Időpontok</span>
                    </div>
                </Grid>
            </Hidden>
            <Hidden only={["xl", "lg", "md", "sm"]}>
                <Grid container item>
                    <Grid container item>
                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={2}>
                                {data.image ? (
                                    <img
                                        className={classes.image}
                                        src={data.image}
                                        alt=""
                                    ></img>
                                ) : (
                                    <div
                                        className={classes.image}
                                        style={{ backgroundColor: "#E6E6E6" }}
                                    ></div>
                                )}
                            </Grid>
                            <Grid item xs={10} className={classes.infoText}>
                                <p
                                    style={{
                                        ...styles.title,
                                        ...props.mapStyle,
                                    }}
                                >
                                    {data.name}
                                </p>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{ padding: "0", paddingTop: "1rem" }}
                        >
                            <p style={{ ...styles.text }}>
                                {data.qualifications
                                    .map((q) => q.name)
                                    .join(", ")}
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} justifyContent="space-between">
                        <p
                            style={{ ...styles.link, fontWeight: "bold" }}
                            onClick={props.onLinkClick}
                        >
                            Adatlap megtekintése
                        </p>
                        <div className={classes.mobileBtn}>
                            <span
                                style={{
                                    ...styles.text,
                                    color: "#1FBA9C",
                                    fontWeight: "700",
                                    marginRight: "1rem",
                                    ...props.mapStyle,
                                }}
                                onClick={onReservationClick}
                            >
                                Időpontok
                            </span>
                            <ArrowForwardIosIcon
                                style={{
                                    color: "#1FBA9C",
                                    marginBottom: "0.5rem",
                                    ...props.mapStyle,
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Hidden>
        </Grid>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.segoe,
        lineHeight: "2rem",
        color: Colors.grey,
        marginBottom: "0.5rem",
        opacity: 1,
    },
    link: {
        fontSize: "1.6rem",
        fontFamily: Fonts.segoe,
        lineHeight: "2rem",
        color: Colors.linkBlue,
        cursor: "pointer",
        opacity: 1,
    },
    btnText: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        color: "white",
    },
};

export default DoctorRow;
