import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Icons } from "../../../assets/Theme";
import CustomSelect from "../../../components/UI/CustomSelect/CustomSelect";
import { removeAccents } from "../../../utility";
import classes from "./DoctorFilter.module.css";

let allQualifications = [];
let allInstitutions = [];
let allLanguages = [];

const abcFilter = [
    { id: 0, name: <span style={{ opacity: 0.6 }}>Válasszon</span> },
    { id: [97, 101], name: "A-E" },
    { id: [102, 106], name: "F-J" },
    { id: [107, 111], name: "K-O" },
    { id: [112, 116], name: "P-T" },
    { id: [117, 122], name: "U-Z" },
];

const serviceFilter = [
    { id: 0, name: <span style={{ opacity: 0.6 }}>Válasszon</span> },
    { id: "duty", name: "Orvosi ügyelet" },
    { id: "appointment", name: "Járóbetegellátás" },
    { id: "videoConsultation", name: "Videó konzultáció" },
    { id: "chat", name: "Orvosi chat" },
    { id: "secondOpinion", name: "Másodvélemény" },
];

let filterValues = {
    abc: null,
    qualification: null,
    institution: null,
    service: null,
    language: null,
};

const DoctorFilter = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    const [abc, setAbc] = useState(0);
    const [qualification, setQualification] = useState(0);
    const [institution, setInstitution] = useState(0);
    const [service, setService] = useState(0);
    const [language, setLanguage] = useState(0);

    const [filterText, setFilterText] = useState("");

    const doctorsData = props.doctorsData;

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        doctorsData.map((doctor) => {
            allQualifications = [
                ...allQualifications,
                ...doctor.qualifications,
            ];
            allQualifications.sort((a, b) => (a.name > b.name ? 1 : -1));
            allInstitutions = [...allInstitutions, ...doctor.institutions];
            allInstitutions.sort((a, b) => (a.name > b.name ? 1 : -1));
            allLanguages = [...allLanguages, ...doctor.languages];
            allLanguages.sort((a, b) => (a.name > b.name ? 1 : -1));
        });

        allQualifications = filterDuplicates(allQualifications);
        allInstitutions = filterDuplicates(allInstitutions);
        allLanguages = filterDuplicates(allLanguages);

        allQualifications = [
            { id: 0, name: <span style={{ opacity: 0.6 }}>Válasszon</span> },
            ...allQualifications,
        ];
        allInstitutions = [
            { id: 0, name: <span style={{ opacity: 0.6 }}>Válasszon</span> },
            ...allInstitutions,
        ];
        allLanguages = [
            { id: 0, name: <span style={{ opacity: 0.6 }}>Válasszon</span> },
            ...allLanguages,
        ];

        return () => {
            allQualifications = [];
            allInstitutions = [];
            allLanguages = [];
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterDuplicates = (arr) => {
        let ids = arr.map((o) => o.id);

        return arr.filter(({ id }, index) => !ids.includes(id, index + 1));
    };

    const toggleFilters = () => {
        setIsOpen((prevState) => !prevState);
        resetFilters();
    };

    const generateFilterText = () => {
        let filterTextArr = [];
        for (const f in filterValues) {
            if (filterValues[f]) {
                filterTextArr = [...filterTextArr, filterValues[f]];
            }
        }
        if (filterTextArr.length > 0) {
            setFilterText(filterTextArr.join(", "));
        } else {
            setFilterText("");
        }
    };

    const resetFilters = () => {
        setAbc(0);
        setQualification(0);
        setInstitution(0);
        setService(0);
        setLanguage(0);

        setFilterText("");

        filterValues = {
            abc: null,
            qualification: null,
            institution: null,
            service: null,
            language: null,
        };

        props.onResetFilters();
    };

    const handleFilterSelect = (value, filter) => {
        props.onFilterChange(value, filter);
        let name;
        switch (filter) {
            case "abc":
                setAbc(value);
                if (value !== 0) {
                    name = abcFilter.find((el) => el.id == value).name;
                    filterValues[filter] = name;
                } else {
                    filterValues[filter] = null;
                }

                break;
            case "qualification":
                setQualification(value);
                if (value !== 0) {
                    name = allQualifications.find((el) => el.id == value).name;
                    filterValues[filter] = name;
                } else {
                    filterValues[filter] = null;
                }

                break;
            case "institution":
                setInstitution(value);
                if (value !== 0) {
                    name = allInstitutions.find((el) => el.id == value).name;
                    filterValues[filter] = name;
                } else {
                    filterValues[filter] = null;
                }

                break;
            case "service":
                setService(value);
                if (value !== 0) {
                    name = serviceFilter.find((el) => el.id == value).name;
                    filterValues[filter] = name;
                } else {
                    filterValues[filter] = null;
                }

                break;
            case "language":
                setLanguage(value);
                if (value !== 0) {
                    name = allLanguages.find((el) => el.id == value).name;
                    filterValues[filter] = name;
                } else {
                    filterValues[filter] = null;
                }

                break;
            default:
                break;
        }
        generateFilterText();
    };

    const formatText = (text) =>
        removeAccents(text).toLowerCase().trim().replace(/\s+/g, "");

    const handleSearchTextChange = (e) => {
        if (isOpen) {
            setIsOpen(false);
        }
        const text = e.target.value;
        const formattedText = formatText(text);
        setSearchText(text);
        props.onSearchTextChange(formattedText);
    };

    return (
        <div style={{ marginBottom: "2rem" }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item md={4} xs={12}>
                    <div className={classes.inputContainer}>
                        <input
                            type="text"
                            className={classes.input}
                            value={searchText}
                            placeholder="Szakterület vagy orvos keresése"
                            onChange={(e) => handleSearchTextChange(e)}
                        />
                        <img
                            src={Icons.searchGreen}
                            className={classes.searchIcon}
                            alt=""
                        />
                    </div>
                </Grid>
                <Grid item>
                    <div
                        className={classes.filterButtonContainer}
                        onClick={toggleFilters}
                    >
                        <span className={classes.openFilter}>SZŰRŐK</span>
                        {!isOpen ? (
                            <ExpandMoreIcon className={classes.expandIcon} />
                        ) : (
                            <ExpandLessIcon className={classes.expandIcon} />
                        )}
                    </div>
                </Grid>
            </Grid>
            {isOpen && (
                <div>
                    <Grid
                        className={classes.mainContainer}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid
                            item
                            md={2}
                            xs={12}
                            className={classes.filterSelect}
                        >
                            <CustomSelect
                                controlStyle={styles.select}
                                options={abcFilter}
                                value={abc}
                                optionKey="name"
                                optionValue="id"
                                onChange={(val) =>
                                    handleFilterSelect(val, "abc")
                                }
                                label="ABC szerint"
                            />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            className={classes.filterSelect}
                        >
                            <CustomSelect
                                controlStyle={styles.select}
                                options={allQualifications}
                                value={qualification}
                                optionKey="name"
                                optionValue="id"
                                onChange={(val) =>
                                    handleFilterSelect(val, "qualification")
                                }
                                label="Szakterület szerint"
                            />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            className={classes.filterSelect}
                        >
                            <CustomSelect
                                controlStyle={styles.select}
                                options={allInstitutions}
                                value={institution}
                                optionKey="name"
                                optionValue="id"
                                onChange={(val) =>
                                    handleFilterSelect(val, "institution")
                                }
                                label="Intézmény szerint"
                            />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            className={classes.filterSelect}
                        >
                            <CustomSelect
                                controlStyle={styles.select}
                                options={serviceFilter}
                                value={service}
                                optionKey="name"
                                optionValue="id"
                                onChange={(val) =>
                                    handleFilterSelect(val, "service")
                                }
                                label="Szolgáltatás szerint"
                            />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            className={classes.filterSelect}
                        >
                            <CustomSelect
                                controlStyle={styles.select}
                                options={allLanguages}
                                value={language}
                                optionKey="name"
                                optionValue="id"
                                onChange={(val) =>
                                    handleFilterSelect(val, "language")
                                }
                                label="Nyelvtudás szerint"
                            />
                        </Grid>
                    </Grid>
                    {filterText && (
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <span className={classes.filterText}>
                                    Találatok a következőre:{" "}
                                </span>
                                <span className={classes.filterText}>
                                    <strong>{filterText}</strong>
                                </span>
                            </Grid>
                            <Grid item>
                                <p
                                    className={classes.resetFilters}
                                    onClick={resetFilters}
                                >
                                    Szürők alaphelyzetbe
                                </p>
                            </Grid>
                        </Grid>
                    )}
                </div>
            )}
        </div>
    );
};

const styles = {
    select: {
        width: "100%",
    },
};

export default DoctorFilter;
