import classes from "./classes.module.css";

const Authorize = () => (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
        <h3 className={classes.title} id="meghatalmazott">
            Ki lehet meghatalmazott?
        </h3>
        <p className={classes.text}>
            Meghatalmazott bárki lehet, akit Te, mint felhasználó meghatalmazol,
            hogy megtekinthesse méréseidet, megjegyzéseidet, kiértékeléseidet és
            hozzád hasonlóan riasztást, illetve figyelmeztetést kapjon a mérési
            eredményeid alapján.
        </p>

        <h3 className={classes.title} id="pontosan-meghatalmazott">
            Mit jelent pontosan, hogy meghatalmazott?
        </h3>
        <p className={classes.text}>
            A meghatalmazott egy olyan felhasználó, akinek megtekintési
            jogosultságot adsz, hogy elérje a T-DOC rendszerében, az adatalapú
            telemedicina szolgáltatásban szereplő adataidat.
        </p>

        <h3 className={classes.title} id="adatok-meghatalmazott">
            Milyen adataimat láthatja a meghatalmazott?
        </h3>
        <p className={classes.text}>
            A meghatalmazott láthatja az eszközzel végzett méréseidet, a
            manuálisan rögzített méréseidet, a kezelőorvosod által készített
            orvosi kiértékeléseidet, a dokumentumaidat, valamint hozzád
            hasonlóan megkaphatja a riasztásokat, illetve figyelmeztetéseket,
            amelyeket a mérési eredményeid alapján a rendszer generált.
        </p>

        <h3 className={classes.title} id="hany-meghatalmazott">
            Hány meghatalmazottat hívhatok meg, hogy a profilomat
            megtekinthesse?
        </h3>
        <p className={classes.text}>
            T-DOC előfizetéstől, illetve vásárolt szolgáltatástól függ, hogy
            hány meghatalmazottat tudsz meghívni egyidejűleg a fiókodba.
        </p>

        <h3 className={classes.title} id="visszavon-meghatalmazast">
            Vissza tudom vonni egy korábban meghatalmazást? Már nem akarom, hogy
            lássa a mérésimet.
        </h3>
        <p className={classes.text}>
            Természetesen bármikor visszavonhatod korábbi meghatalmazásodat a
            Meghatalmazottak menüpontban, a megfelelő sor végén szereplő Törlés
            gombra kattintva.
        </p>
    </>
);

export default Authorize;
