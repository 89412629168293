//AUTH
export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";
export const TOGGLE_VERIFICATION_MODAL = "TOGGLE_VERIFICATION_MODAL";
export const SET_USER_PHONE = "SET_USER_PHONE";

//UI
export const TOGGLE_RESPONSE_MODAL = "TOGGLE_RESPONSE_MODAL";
export const SET_CARD_NAME = "SET_CARD_NAME";
export const TOGGLE_LOGIN_MODAL = "TOGGLE_LOGIN_MODAL";
export const TOGGLE_REGISTRATION_MODAL = "TOGGLE_REGISTRATION_MODAL";
export const TOGGLE_NOTIFICATIONS = "TOGGLE_NOTIFICATIONS";
export const TOGGLE_SERVICE_PAYMENT_MODAL = "TOGGLE_SERVICE_PAYMENT_MODAL";
export const TOGGLE_QUALIFICATIONS_MODAL = "TOGGLE_QUALIFICATIONS_MODAL";
export const SET_YELLOW_BAR = "SET_YELLOW_BAR";
export const TOGGLE_HAMBURGER = "TOGGLE_HAMBURGER";
export const TOGGLE_USERMENU = "TOGGLE_USERMENU";
export const TOGGLE_SUBSCRIPTION_AND_SERVICE_MODAL =
    "TOGGLE_SUBSCRIPTION_AND_SERVICE_MODAL";
export const SET_SUBSCRIPTION_AND_SERVICE_MODAL =
    "SET_SUBSCRIPTION_AND_SERVICE_MODAL";
export const TOGGLE_SUBSCRIPTION_DETAILS = "TOGGLE_SUBSCRIPTION_DETAILS";

//COOKIE
export const SET_COOKIE = "SET_COOKIE";
export const SET_COOKIE_SETTINGS = "SET_COOKIE_SETTINGS";

//CHAT
export const SET_CHAT_ACCESS_TOKEN = "SET_CHAT_ACCESS_TOKEN";

//USER
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";

//RESERVATION

//Function
export const SET_POST_LOAD_FUNCTION = "SET_POST_LOAD_FUNCTION";
export const DELETE_POST_LOAD_FUNCTION = "DELETE_POST_LOAD_FUNCTION";
