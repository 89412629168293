import "./assets/reset.css";
import "./index.css";
import { createRoot } from "react-dom/client";
import invariant from "tiny-invariant";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Root from "./Root";

const container = document.getElementById("root");
invariant(container);
const root = createRoot(container);

root.render(
    <Root>
        <App />
    </Root>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
