import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import type { Lng } from "../i18n";
import { LngTitle, supportedLngs } from "../i18n";
import huIcon from "../Icons/hu.svg";
import enIcon from "../Icons/en.svg";
import deIcon from "../Icons/de.svg";
import skIcon from "../Icons/sk.svg";
import roIcon from "../Icons/ro.svg";
import { styles } from "../../../components/Navigation/Toolbar/Toolbar";

interface Props {
    classes: {
        readonly [key: string]: string;
    };
}

const LanguageSwitch = ({ classes }: Props) => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng: Lng) => i18n.changeLanguage(lng);

    const getLngIcon = (lng: Lng) => {
        switch (lng) {
            case "en":
                return enIcon;
            case "de":
                return deIcon;
            case "sk":
                return skIcon;
            case "ro":
                return roIcon;
            default:
                return huIcon;
        }
    };

    return (
        <div className={classes.dropdown}>
            <div className={classes.menuItem}>
                <img
                    src={getLngIcon(i18n.language as Lng)}
                    className={classes.lngicon}
                    alt=""
                />
                {LngTitle[i18n.language as Lng]}
                <KeyboardArrowDownIcon style={styles.down} />
            </div>
            <div className={classes.dropdownContainer}>
                {supportedLngs.map((lng, i) => (
                    <div
                        key={i}
                        className={classes.menuItem}
                        onClick={() => changeLanguage(lng)}
                    >
                        <img
                            src={getLngIcon(lng)}
                            className={classes.lngicon}
                            alt=""
                        />
                        {LngTitle[lng]}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LanguageSwitch;
