import Line from "../../../../components/UI/Line/Line";
import NotificationWarning from "../../../../components/UI/NotificationWarning/NotificationWarning";
import classes from "./UserSettingRow.module.css";

const UserSettingRow = (props) => (
    <div>
        <div className={classes.innerContainer}>
            <div>
                <div className={classes.titleContainer}>
                    {props.empty && (
                        <NotificationWarning style={{ marginRight: "1rem" }} />
                    )}
                    <p className={classes.title}>{props.title}</p>
                </div>
                {!props.editing ? (
                    <p className={classes.cardText}>{props.text}</p>
                ) : (
                    <div style={{ fontSize: "1.6rem" }}>
                        {props.editingComponent}
                    </div>
                )}
            </div>
        </div>
        <Line />
    </div>
);

export default UserSettingRow;
