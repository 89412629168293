import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import { Colors, Icons } from "../../assets/Theme";
import CustomSelect from "../UI/CustomSelect/CustomSelect";
import { removeAccents } from "../../utility";
import useTranslation from "../../features/i18n/useTranslation";
import classes from "./Summary.module.css";
import faqDATA from "./Topics/topics.json";
import Hit from "./Hit";
import ContactForm from "./ContactForm";

let selectInit = true;

const Summary = () => {
    const [selectedTopic, setSelectedTopic] = useState(-1);
    const [hits, setHits] = useState([]);
    const [inputText, setInputText] = useState("");
    const { i18nRoute } = useTranslation();

    const onSelectChange = (id) => {
        selectInit = false;
        setSelectedTopic(id);
    };

    useEffect(() => {
        if (!selectInit) {
            if (selectedTopic === -1 && !inputText) {
                setHits([]);
            } else {
                onChangeText(inputText, true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTopic]);

    const formatText = (text) => removeAccents(text).toLowerCase().trim();

    const onChangeText = (input, selectChange = false) => {
        if (!selectChange) {
            setInputText(input);
        }
        if (
            (input && input.length > 2) ||
            selectChange ||
            selectedTopic !== -1
        ) {
            setHits([]);
            let data = faqDATA.data;
            let searchText = formatText(input);
            if (selectedTopic === -1) {
                for (let d of data) {
                    let title = formatText(d.title);
                    let text = formatText(d.text);
                    if (
                        title.indexOf(searchText) > -1 ||
                        text.indexOf(searchText) > -1
                    ) {
                        setHits((prevValue) => [...prevValue, d]);
                    }
                }
            } else {
                let topic;
                switch (+selectedTopic) {
                    case 0:
                        topic = "regisztracio";

                        break;
                    case 1:
                        topic = "szolgaltatasok";

                        break;
                    case 2:
                        topic = "fizetes";

                        break;
                    case 3:
                        topic = "technikai-hatter";

                        break;
                    case 4:
                        topic = "gdpr";

                        break;
                    case 5:
                        topic = "analizis-kozpont";

                        break;
                    case 6:
                        topic = "eszkoz-csatlakoztatas";

                        break;
                    case 7:
                        topic = "riasztasok";

                        break;
                    case 8:
                        topic = "meghatalmazottak";

                        break;
                }
                for (let d of data) {
                    let title = formatText(d.title);
                    let text = formatText(d.text);
                    if (
                        (title.indexOf(searchText) > -1 ||
                            text.indexOf(searchText) > -1) &&
                        topic === d.topic
                    ) {
                        setHits((prevValue) => [...prevValue, d]);
                    }
                }
            }
        } else {
            setHits([]);
        }
    };

    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <>
            <h2 className={classes.header}>Súgóközpont</h2>
            <p className={classes.subHeader}>
                Ha nem találod kérdésedre a választ, nézz körül
                súgóközpontunkban. A felhasználók által leggyakrabban feltett
                kérdéseket, és azokra adott válaszainkat találod meg itt. A
                listát folyamatosan bővítjük kérdéseitek és visszajelzéseitek
                alapján.{" "}
            </p>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <CustomSelect
                        options={[
                            {
                                name: (
                                    <span style={{ opacity: 0.6 }}>
                                        Milyen témát érintő kérdése van?
                                    </span>
                                ),
                                id: -1,
                            },
                            {
                                name: "Regisztráció",
                                id: 0,
                            },
                            {
                                name: "Szolgáltatások",
                                id: 1,
                            },
                            {
                                name: "Fizetés",
                                id: 2,
                            },
                            {
                                name: "Technikai háttér",
                                id: 3,
                            },
                            {
                                name: "GDPR",
                                id: 4,
                            },
                            {
                                name: "Analízis központ",
                                id: 5,
                            },
                            {
                                name: "Eszköz csatlakoztatás",
                                id: 6,
                            },
                            {
                                name: "Riasztások",
                                id: 7,
                            },
                            {
                                name: "Meghatalmazottak",
                                id: 8,
                            },
                        ]}
                        controlStyle={{ width: "100%", marginBottom: "-2rem" }}
                        value={selectedTopic}
                        optionKey="name"
                        optionValue="id"
                        onChange={(val) => onSelectChange(val)}
                        label="Téma kiválasztása"
                    />
                    ;
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.inputContainer}>
                        <input
                            type="text"
                            className={classes.input}
                            placeholder="Súgócikkek keresése"
                            onChange={(e) => onChangeText(e.target.value)}
                        />
                        <img
                            src={Icons.searchGreen}
                            className={classes.searchIcon}
                            alt=""
                        />
                    </div>
                </Grid>
            </Grid>
            <div className={classes.hits}>
                {hits.map((element) => (
                    <Hit
                        title={element.title}
                        text={element.text}
                        key={element.id}
                    />
                ))}
            </div>
            <div className={classes.popularQuestionsHeaderRow}>
                <h3 className={classes.popularQuestionsHeader}>
                    Népszerű kérdések
                </h3>
                <div className={classes.popularQuestionsHeaderRowRight}>
                    <Link
                        to={i18nRoute("routes.faq.registration")}
                        className={classes.popularQuestionsLink}
                    >
                        Kérdések böngészése{" "}
                    </Link>
                    <ChevronRightIcon
                        style={{
                            height: "2rem",
                            width: "2rem",
                            marginTop: "0.3rem",
                            color: Colors.green,
                        }}
                    />
                </div>
            </div>
            <Grid
                container
                spacing={4}
                classes={{ root: classes.popularQuestions }}
            >
                <Grid item xs={12} sm={6} md={4}>
                    <div className={classes.popularQuestion}>
                        <div className={classes.popularLeft}>
                            <img
                                src={Icons.tipGreen}
                                className={classes.icon}
                                alt=""
                            />
                        </div>
                        <div className={classes.popularRight}>
                            <p className={classes.popularText}>
                                Regisztráltam, de nem tudok belépni a fiókomba.
                            </p>
                            <p>
                                <Link
                                    className={classes.popularLink}
                                    to={i18nRoute("routes.faq.registration", {
                                        routeHash: "#belepes-fiokba",
                                    })}
                                >
                                    Válasz megtekintése
                                </Link>
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div className={classes.popularQuestion}>
                        <div className={classes.popularLeft}>
                            <img
                                src={Icons.tipGreen}
                                className={classes.icon}
                                alt=""
                            />
                        </div>
                        <div className={classes.popularRight}>
                            <p className={classes.popularText}>
                                Regisztráltam, de nem kaptam megerősítő e-mailt.
                            </p>
                            <p>
                                <Link
                                    className={classes.popularLink}
                                    to={i18nRoute("routes.faq.registration", {
                                        routeHash: "#megerosito-email",
                                    })}
                                >
                                    Válasz megtekintése
                                </Link>
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div className={classes.popularQuestion}>
                        <div className={classes.popularLeft}>
                            <img
                                src={Icons.tipGreen}
                                className={classes.icon}
                                alt=""
                            />
                        </div>
                        <div className={classes.popularRight}>
                            <p className={classes.popularText}>
                                Egy e-mail címmel regisztrálhatok többször?
                            </p>
                            <p>
                                <Link
                                    className={classes.popularLink}
                                    to={i18nRoute("routes.faq.registration", {
                                        routeHash: "#tobbszoros-email",
                                    })}
                                >
                                    Válasz megtekintése
                                </Link>
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div className={classes.popularQuestion}>
                        <div className={classes.popularLeft}>
                            <img
                                src={Icons.tipGreen}
                                className={classes.icon}
                                alt=""
                            />
                        </div>
                        <div className={classes.popularRight}>
                            <p className={classes.popularText}>
                                Nem kaptam meg az sms-t a hotline hívás
                                indítását követően.
                            </p>
                            <p>
                                <Link
                                    className={classes.popularLink}
                                    to={i18nRoute("routes.faq.services", {
                                        routeHash: "#sms-hotline",
                                    })}
                                >
                                    Válasz megtekintése
                                </Link>
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div className={classes.popularQuestion}>
                        <div className={classes.popularLeft}>
                            <img
                                src={Icons.tipGreen}
                                className={classes.icon}
                                alt=""
                            />
                        </div>
                        <div className={classes.popularRight}>
                            <p className={classes.popularText}>
                                Hol érem el az orvostól kapott üzeneteimet?
                            </p>
                            <p>
                                <Link
                                    className={classes.popularLink}
                                    to={i18nRoute("routes.faq.services", {
                                        routeHash: "#uzenetek-elerese",
                                    })}
                                >
                                    Válasz megtekintése
                                </Link>
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div className={classes.popularQuestion}>
                        <div className={classes.popularLeft}>
                            <img
                                src={Icons.tipGreen}
                                className={classes.icon}
                                alt=""
                            />
                        </div>
                        <div className={classes.popularRight}>
                            <p className={classes.popularText}>
                                Mikor kerül levonásra az előfizetés díja a
                                kártyámról?
                            </p>
                            <p>
                                <Link
                                    className={classes.popularLink}
                                    to={i18nRoute("routes.faq.payment", {
                                        routeHash: "#elofizetes-levonas",
                                    })}
                                >
                                    Válasz megtekintése
                                </Link>
                            </p>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <ContactForm />
        </>
    );
};

export default Summary;
