import ExtendedRedeemRow from "./ExtendedRedeemRow";
import SimpleRedeemRow from "./SimpleRedeemRow";

const RedeemRow = ({ redeem, products, isOpened, onRowClicked }) => {
    for (let i = 0; i < redeem.prescriptionRedeemProducts.length; i++) {
        const productId = parseInt(
            redeem.prescriptionRedeemProducts[i]["piqProductId"]
        );
        const product = products?.find((p) => p.id === productId) ?? null;
        redeem.prescriptionRedeemProducts[i]["name"] =
            product?.name ?? `Termék #${productId}`;
    }

    return isOpened ?? false ? (
        <ExtendedRedeemRow onRowClicked={onRowClicked} redeem={redeem} />
    ) : (
        <SimpleRedeemRow onRowClicked={onRowClicked} redeem={redeem} />
    );
};

export default RedeemRow;
