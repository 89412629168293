import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Colors, Fonts, Icons, Images } from "../../../assets/Theme";
import ServicePage from "../../../components/UI/ServicePage/ServicePage";
import CustomSlider from "../../../components/UI/CustomSlider/CustomSlider";
import { endLoading, get, post, startLoading } from "../../../config/fetch";
import { formatNumber, isWeekend, responseMessage } from "../../../utility";
import { ALERT_MESSAGES, PAYMENT } from "../../../config/variables";
import PaymentModal from "../../../components/Modals/PaymentModal/PaymentModal";
import * as actions from "../../../store/actions/index";
import {
    ecommerceAddCart,
    ecommerceCheckout,
    ecommerceProductDetail,
    ecommercePurchase,
} from "../../../config/ecommerce";
import SubscriptionAndServiceModal from "../../../components/Modals/SubscriptionAndServiceModal/SubscriptionAndServiceModal";
import useTranslation from "../../../features/i18n/useTranslation";
import useAuth from "../../../features/auth/useAuth";
import useQuery from "../../../features/util/useQuery";

let price = null;
let subscription;

const CovidDuty = (props) => {
    const navigate = useNavigate();
    const query = useQuery();
    const { isAuthenticated } = useAuth();
    const [packages, setPackages] = useState(null);
    const [paymentModal, togglePaymentModal] = useState(false);
    const [people, setPeople] = useState([]);
    const [available] = useState(false);
    const [userSubscribed, setUserSubscribed] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(1);
    const [originalSubscription, setOriginalSubscription] = useState(false);
    const [subscriptionAndServiceModal, toggleSubscriptionAndServiceModal] =
        useState(0);
    const [subscriptionAndServiceModalData, setSubscriptionAndServiceModal] =
        useState({
            freeOccasions: null,
            servicePrices: [],
            service: null,
        });
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        paymentCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paymentCallback = () => {
        if (query.has("TransactionId")) {
            const transactionId = query.get("TransactionId");
            const immediateToken = query.get("immediateToken");
            const oneClickEnabled = query.get("oneClickEnabled");
            const subscription = query.get("subscription");
            const price = query.get("price");
            post("api/v1/payment/payment-init-callback", {
                transactionId,
                cardName: "Bankkártya",
                immediateToken,
                oneClick: oneClickEnabled,
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            endLoading();
                            ecommercePurchase("DUTY", price, subscription);
                            responseMessage(
                                succesFullResponseText(
                                    response.data.ProviderTransactionId
                                )
                            );
                            navigate("/service-initiated?service=duty", {
                                replace: true,
                            });

                            break;
                        case "PAYMENT_ERROR":
                            switch (response.data.errorCode) {
                                case "ERROR":
                                    responseMessage(
                                        unSuccesfullResponseText(
                                            response.data.ProviderTransactionId
                                        )
                                    );
                                    endLoading();

                                    break;
                                case "CANCELED":
                                    responseMessage(
                                        "Megszakítottad a fizetési folyamatot. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "TIMEOUT":
                                    responseMessage(
                                        "Túllépted a tranzakció elindításakor megengedett biztonságos időkaput. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "PENDING":
                                    startLoading();
                                    setTimeout(paymentCallback, 3000);

                                    break;
                            }
                            navigate(i18nRoute("routes.services.covidDuty"), {
                                replace: true,
                            });

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                            endLoading();
                            navigate(i18nRoute("routes.services.covidDuty"), {
                                replace: true,
                            });
                    }

                    localStorage.removeItem("newCardName");
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    navigate(i18nRoute("routes.services.covidDuty"), {
                        replace: true,
                    });
                });
        }
    };

    const succesFullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikeres tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
        </>
    );

    const unSuccesfullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikertelen tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
            <p style={{ marginBottom: "1rem" }}>
                Kérjük, ellenőrizze a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adott meg, a
                visszautasítás okának kivizsgálása érdekében kérjük,
                szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
            </p>
        </>
    );
    const processPackages = (packages, subscribedIndex, dynamicData = null) => {
        setSelectedPackage(+subscribedIndex);
        setOriginalSubscription(+subscribedIndex);
        const _packages = [];
        const transactionId = query.get("TransactionId");
        if (!transactionId) {
            if (dynamicData) {
                ecommerceProductDetail(
                    "DUTY",
                    dynamicData.price,
                    +dynamicData.userPackage
                );
            } else {
                ecommerceProductDetail(
                    "DUTY",
                    packages[0].price[isWeekend ? 1 : 0],
                    subscribedIndex
                );
            }
        }

        const packagePrices = [null];
        const freeOccasions = [null];
        packages.forEach((p, i) => {
            packagePrices.push(p.price);
            freeOccasions.push(p.freePerMonth);

            if (dynamicData && subscribedIndex - 1 == i) {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        dynamicData.price !== 0
                            ? `${formatNumber(dynamicData.price)} Ft`
                            : `Ingyenes`,
                    subtext:
                        dynamicData.price === 0 &&
                        dynamicData.remainingServices > 0
                            ? `Hátralévő: ${dynamicData.remainingServices}`
                            : "",
                });
            } else {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        p.freePerMonth > 0
                            ? `${p.freePerMonth} ingyenes`
                            : `${formatNumber(p.price[0])} Ft / hívás`,
                    subtext:
                        p.freePerMonth > 0
                            ? `*utána ${formatNumber(
                                  p.price[0]
                              )} Ft / ${formatNumber(p.price[1])} Ft /hívás`
                            : `*hétvégén ${formatNumber(p.price[1])} Ft /hívás`,
                });
            }
        });
        setSubscriptionAndServiceModal({
            service: "DUTY",
            servicePrices: packagePrices,
            freeOccasions,
        });

        return _packages;
    };

    useEffect(() => {
        getPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPrices = () => {
        if (isAuthenticated) {
            const promise1 = post(
                "api/v1/subscription/get-service-prices-by-user",
                { service: "DUTY" }
            ).then((response) => {
                if (response.data.responseCode === "OK") {
                    return response.data;
                }
            });
            const promise2 = post(
                "api/v1/subscription/check-user-service-price",
                { service: "DUTY" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        price = response.data.data.price;
                        setUserSubscribed(response.data.data.price < 1);

                        return response.data;
                }
            });
            Promise.all([promise1, promise2]).then((responses) => {
                endLoading();
                const response1 = responses[0];
                const response2 = responses[1];
                let _packages = processPackages(
                    response1.packages,
                    response1.subscription ? response1.subscription : 1,
                    response2.data
                );
                setPackages(_packages);
            });
        } else {
            post("api/v1/subscription/get-service-prices-by-user", {
                service: "DUTY",
            }).then((response) => {
                endLoading();
                if (response.data.responseCode === "OK") {
                    let _packages = processPackages(
                        response.data.packages,
                        response.data.subscription
                            ? response.data.subscription
                            : 1
                    );
                    setPackages(_packages);
                }
            });
        }
    };

    useEffect(() => {
        get("api/v1/medical/get-duty-doctors", false)
            .then((response) => setPeople(response.data.data))
            .catch((error) => console.error(error));
    }, []);

    const startButtonHandler = () => {
        if (isAuthenticated) {
            post("api/v1/subscription/check-user-service-price", {
                service: "DUTY",
            })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            price = response.data.data.price;
                            subscription = response.data.data.userPackage;
                            ecommerceAddCart(
                                "DUTY",
                                response.data.data.price,
                                response.data.data.userPackage
                            );
                            toggleSubscriptionAndServiceModal(2);

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                });
        } else {
            props.toggleLoginModal(true);
        }
    };

    const redeemCoupon = (code) => {
        if (code) {
            post("api/v1/coupon/redeem-coupon", { couponCode: code })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("A kupont sikeresen beváltottad!");
                            getPrices();

                            break;
                        case "WRONG_CODE":
                            responseMessage("Hibás kuponkód!");

                            break;
                        case "ALREADY_REDEEMED":
                            responseMessage(
                                "Ezt a kupont már beváltottad egyszer!"
                            );

                            break;
                        case "ONLY_WITHOUT_SUBSCRIPTION":
                            responseMessage(
                                "Ezt a kupont csak előfizetés nélkül lehet beváltani!"
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const initCall = () => {
        ecommerceCheckout("DUTY", price, subscription);
        post("api/v1/call/init-call", { type: "DUTY" })
            .then((response) => {
                endLoading();
                toggleSubscriptionAndServiceModal(0);
                switch (response.data.responseCode) {
                    case "OK":
                        ecommercePurchase("DUTY", price, subscription);
                        navigate("/service-initiated?service=duty");

                        break;
                    case "PAYMENT_FAILED":
                        responseMessage(PAYMENT.PAYMENT_FAILED);

                        break;
                    case "EMPTY_DEFAULT_CARD":
                        post("api/v1/subscription/check-user-service-price", {
                            service: "DUTY",
                        })
                            .then((response) => {
                                endLoading();
                                switch (response.data.responseCode) {
                                    case "OK":
                                        props.toggleServicePaymentModal(
                                            true,
                                            response.data.data.summaryAmount,
                                            "DUTY"
                                        );

                                        break;
                                    default:
                                        responseMessage(
                                            ALERT_MESSAGES.errorTryAgain
                                        );

                                        break;
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                endLoading();
                            });

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const resetSubscription = () => setSelectedPackage(originalSubscription);

    const onPackageClick = (pckg) => {
        setSelectedPackage(pckg);
        toggleSubscriptionAndServiceModal(1);
    };

    return (
        <ServicePage
            icon={Icons.dutyGreen}
            name="Covid-19 Orvosi ügyelet"
            description="Telefonon, a nap 12 órájában elérhető Covid-19 orvosi ügyelet. Megbízható információk tünetekről, tesztekről és oltásokról."
            image={Images.orvosiUgyelet}
            imageAlt="Általános orvosi konzultáció | T-DOC"
            packages={packages}
            selectedPackage={selectedPackage}
            originalSubscription={originalSubscription}
            // packageInfoText='* Eredeti ár: Hétköznap - 14 990 Ft | Hétvégén - 19 990 Ft. A kedvezményes ár 2021.09.30-ig érvényes.'
            // packageInfo2Text='** Eredeti ár: Hétköznap - 9 990 Ft | Hétvégén - 11 990 Ft. A kedvezményes ár 2021.09.30-ig érvényes.'
            importantInfoIcon={Images.gearIcon}
            importantInfoText="FIGYELEM! A szolgáltatás jelenleg nem elérhető. A Covid-19 orvosi ügyelet minden nap 08:00 és 20:00 között hívható. "
            showImportantInfo={!available}
            showImportantInfo2={available}
            buttonText="Kérem a telefonszámot"
            buttonCallback={startButtonHandler}
            buttonDisabled={!available}
            packageResetClick={resetSubscription}
            packageClick={onPackageClick}
            asterisks={[]}
        >
            <h1 style={{ ...styles.title, fontSize: "3rem" }}>
                TELEFONOS ÁLTALÁNOS ORVOSI KONZULTÁCIÓ
            </h1>

            <h2 style={styles.title}>
                MIKOR ÉRDEMES IGÉNYBE VENNI A TELEFONOS ÁLTALÁNOS ORVOSI
                KONZULTÁCIÓT?
            </h2>
            <p style={styles.text}>
                Hirtelen jelentkező, fájdalommal vagy kényelmetlenséggel járó
                panaszok esetén érdemes azonnal hívni rendelkezésre álló
                orvosunkat. Sok esetben ugyan csak pihenésre lenne szükségünk,
                de ezt nehéz megítélni még úgy is, hogy jól ismerjük már
                magunkat vagy családtagunkat. Ha bizonytalanok vagyunk abban,
                hogy mi a teendő, kérjünk segítséget, vegyük igénybe a T-DOC
                telefonos általános orvosi konzultáció szolgáltatását!
                <br />
                <br />
                Ismert alapbetegségeink egyik percről a másikra történő
                súlyosbodása, a tünetek felerősödése esetén is érdemes orvosi
                segítséget kérni. A vonal végén lévő orvosaink tapasztalt,
                magasan képzett szakemberek, akik képesek lehetnek az elmondott
                tünetek alapján időben kiszűrni a komoly következményekkel vagy
                szövődményekkel járó egészségügyi problémákat. A hívó fél gyors
                és határozott választ kaphat felmerülő problémáira, kérdéseire.
            </p>

            <h2 style={styles.title}>
                A TELEFONOS ÁLTALÁNOS ORVOSI KONZULTÁCIÓ MŰKÖDÉSE
            </h2>
            <p style={styles.text}>
                Panasz esetén{" "}
                <span style={styles.strong}>
                    orvos kollégáink rendelkezésedre állnak az év 365 napján
                    08:00 – 20:00 óráig
                </span>
                . Ha kérdésed van, segítséget vagy tanácsot kérnél, akkor hívd
                orvosainkat! Regisztrálj, válaszd ki a számodra megfelelő T-DOC
                előfizetést, vagy vidd végig a fizetési folyamatot, és már
                hívhatod is éppen elérhető orvosaink egyikét.
            </p>

            <h2 style={styles.title}>
                BETEGELLÁTÁS A TELEFONOS SEGÍTSÉGKÉRÉS UTÁN
            </h2>
            <p style={styles.text}>
                Orvosi ügyeletes orvosunkkal történő egyeztetést követően sem
                hagyunk egyedül! Amennyiben nem akut egészségügyi ellátásra van
                szükséged, úgy segítünk eljutni a megfelelő szakorvoshoz is.
                Orvosunk tanácsa alapján választhatod a T-DOC oldalán történő
                online kapcsolatfelvételt szakorvosaink egyikével, személyes
                vizit esetén pedig segítünk kiválasztani a megfelelő szakembert,
                egészségügyi intézményt és időpontot is.
                <br />
                <br />
                Ha szeretnéd, hogy egy „saját egészségügyi asszisztens” kísérjen
                végig az úton, úgy hívd díjmentesen előfizetéseinken belül
                Egészségügyi Hotline-os kollégáinkat, akik segítenek abban, hogy
                a leggyorsabban, a legjobb kezekbe kerülj.
            </p>

            <h2 style={styles.title}>
                TELEFONOS ÁLTALÁNOS ORVOSI KONZULTÁCIÓ ELÉRHETŐSÉG
            </h2>
            <p style={styles.text}>
                Az év 365 napján, reggel 8:00-tól este 20:00-ig.
            </p>

            <h2 style={styles.title}>Orvosi csapatunk</h2>
            <p style={styles.text}>
                Általános orvosi konzultáción több éve praktizáló, háziorvosi
                ügyeletet és telefonos ügyeletet végző tapasztalt és jól
                felkészült orvosi csapatunk érhető el.
            </p>
            <CustomSlider data={people} withAvailability people />
            <PaymentModal
                closeModal={() => togglePaymentModal(false)}
                open={paymentModal}
                userSubscribed={userSubscribed}
                infoText="A folytatásra kattintva tárcsázhatja az ügyeletet, rendszerünk a számlájáról pedig automatikusan levonja a megjelölt összeget"
                boxTitle={
                    !price
                        ? "Előfizetésben fogalt ügyelet hívása"
                        : "Előfizetés ügyelet hívási díja"
                }
                price={price}
                onContinueClick={initCall}
                couponHandler={redeemCoupon}
            />
            <SubscriptionAndServiceModal
                outerSelectedPackage={selectedPackage}
                selectCallback={setSelectedPackage}
                normalInit={initCall}
                open={subscriptionAndServiceModal}
                data={subscriptionAndServiceModalData}
                toggleSubscriptionAndServiceModal={
                    toggleSubscriptionAndServiceModal
                }
                succesfulSubscribeAndServiceCallback={() =>
                    navigate("/service-initiated?service=duty")
                }
            />
        </ServicePage>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontWeight: 700,
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
        marginTop: "3rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        marginBottom: "1rem",
        color: Colors.darkBlue,
    },
    strong: {
        fontWeight: 700,
    },
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
    toggleServicePaymentModal: (show, price, service) =>
        dispatch(actions.toggleServicePaymentModal(show, price, service)),
});

export default connect(null, mapDispatchToProps)(CovidDuty);
