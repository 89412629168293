import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import CardContainer from "../UI/CardContainer/CardContainer";
import PageHeader from "../UI/PageHeader/PageHeader";
import Button from "../UI/Btn/Btn";
import { responseMessage } from "../../utility";
import Textarea from "../UI/Textarea/Textarea";
import Checkbox from "../UI/Checkbox/Checkbox";
import { ALERT_MESSAGES } from "../../config/variables";
import { endLoading, post } from "../../config/fetch";
import useQuery from "../../features/util/useQuery";
import useTranslation from "../../features/i18n/useTranslation";
import useAuth from "../../features/auth/useAuth";
import classes from "./Rating.module.css";

const Rating = () => {
    const navigate = useNavigate();
    const { i18nRoute } = useTranslation();
    const query = useQuery();
    const [rating, setRating] = useState(null);
    const [review, setReview] = useState("");
    const [checked, toggleChecked] = useState(false);
    const [rated, setRated] = useState(null);

    const hash = query.get("hash");

    const { isAuthenticated } = useAuth();

    useEffect(() => {
        window.scrollTo(0, 0);

        if (!isAuthenticated) {
            responseMessage("Kérjük jelentkezz be az értékeléshez!");
            navigate("/");

            return;
        }

        post("api/v1/rating/get-rating", { hash })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "RATED": {
                        const data = response.data.rating;
                        setRated(true);
                        setRating(data.rating);
                        setReview(data.review);

                        break;
                    }
                    case "NOT_RATED":
                        setRated(false);

                        break;
                    case "OVER_DEADLINE":
                        responseMessage("Lejárt értékelés!");
                        navigate("/");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash]);

    const buttonClickHandler = () => {
        if (!rating) {
            responseMessage("Kérünk add meg értékelésed!");

            return;
        }
        if (!checked) {
            responseMessage(
                "Kérünk fogadd el az általános adatkezelési irányelveket és a felhasználási feltételeket!"
            );

            return;
        }

        post("api/v1/rating/service", {
            hash,
            rating: +rating,
            review,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Köszönjük visszajelzésedet!");
                        navigate("/");

                        break;
                    case "ALREADY_RATED":
                        responseMessage("Már értékelted ezt a szolgáltatást!");

                        break;
                    case "WRONG_HASH":
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                    case "OVER_DEADLINE":
                        responseMessage("Lejárt értékelés!");
                        navigate("/");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });

        // navigate(i18nRoute('routes.profile.base'));
    };

    const buttonCancelHandler = () => navigate("/");

    return (
        <Container>
            <div className={classes.innerContainer}>
                <CardContainer className={classes.cardContainer}>
                    <PageHeader text="Visszajelzés" style={styles.pageHeader} />
                    <p className={classes.questionText}>
                        Mennyire voltál elégedett a szolgáltatással?
                    </p>
                    <div className={classes.starsWrapper}>
                        {rated === false && (
                            <ReactStars
                                count={4}
                                onChange={(value) => setRating(value)}
                                size={48}
                                color="#DBDFE2"
                                activeColor="#FFD943"
                            />
                        )}
                        {rating && rated && (
                            <ReactStars
                                count={4}
                                value={+rating}
                                size={48}
                                color="#DBDFE2"
                                activeColor="#FFD943"
                                edit={false}
                            />
                        )}
                    </div>
                    <Textarea
                        rows={4}
                        value={review}
                        placeholder="Írd le véleményedet (nem kötelező)"
                        disabled={!!rated}
                        onChange={(text) => setReview(text)}
                    />
                    {!rated && (
                        <>
                            <div className={classes.checkboxes}>
                                <Checkbox
                                    onChange={(checked) =>
                                        toggleChecked(checked)
                                    }
                                    style={styles.checkbox}
                                    label={
                                        <p className={classes.checkboxLabel}>
                                            Elolvastam és elfogadom az{" "}
                                            <a
                                                href={i18nRoute(
                                                    "routes.privacy"
                                                )}
                                                className={classes.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                általános adatkezelési
                                                irányelveket
                                            </a>{" "}
                                            és a{" "}
                                            <a
                                                href={i18nRoute("routes.terms")}
                                                className={classes.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                felhasználási feltételeket.
                                            </a>
                                        </p>
                                    }
                                />
                            </div>
                            <Button
                                text="Visszajelzés elküldése"
                                className={
                                    checked
                                        ? classes.button
                                        : classes.disabledButton
                                }
                                onClick={buttonClickHandler}
                            />
                            <Button
                                text="Mégsem"
                                className={classes.button}
                                color="transparent"
                                onClick={buttonCancelHandler}
                            />
                        </>
                    )}
                </CardContainer>
            </div>
        </Container>
    );
};

const styles = {
    pageHeader: {
        marginTop: 0,
        marginBottom: "3rem",
    },
    checkbox: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
};

export default Rating;
