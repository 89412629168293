import { endLoading, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import classes from "./WebshopProductCard.module.css";

const WebshopProductCard = ({ image, name, price, url }) => {
    const openProductPage = (url) => {
        post("api/v1/user/create-one-time-token")
            .then((res) => {
                switch (res.data.responseCode) {
                    case "OK":
                        window.open(
                            `${url}?oneTimeToken=${res.data.token}`,
                            "_blank"
                        );
                        endLoading();

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <a
            onClick={() => openProductPage(url)}
            target="_blank"
            className={classes.link}
            rel="noreferrer"
        >
            <div className={classes.card}>
                <figure className={classes["image-container"]}>
                    <img src={image} alt="" className={classes.image} />
                </figure>
                <h3 className={classes.title}>{name}</h3>
                <span className={classes.price}>
                    {new Intl.NumberFormat("hu-HU", {
                        style: "currency",
                        currency: "HUF",
                        currencyDisplay: "symbol",
                        minimumFractionDigits: 0,
                    }).format(price)}
                </span>
            </div>
        </a>
    );
};

export default WebshopProductCard;
