import { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { endLoading, get } from "../../config/fetch";
import ReservationPicker from "../../components/Reservation/ReservationPicker/ReservationPicker";
import Breadcrumbs from "../../components/UI/Breadcrumbs/Breadcrumbs";
import PageHeader from "../../components/UI/PageHeader/PageHeader";
import useTranslation from "../../features/i18n/useTranslation";
import { useHumed } from "../../ui/hooks/useHumed";
import useQuery from "../../features/util/useQuery";
import useAuth from "../../features/auth/useAuth";
import classes from "./Reservation.module.css";

const sortData = (data, onlyVideo, onlyOutpatient) => {
    const datas = [];
    let i = 0;
    for (let d of data) {
        if (
            (onlyVideo && !d.video_consultation) ||
            (onlyOutpatient && d.video_consultation)
        )
            continue;
        datas.push({
            id: i++,
            appointmentId: d.amnt_doctors_id,
            institution: d.institution_name,
            institutionId: d.institution_id,
            institutionAddress: d.institution_address,
            institutionImage: d.institution_image,
            profileImageUrl: d.profile_image_url,
            qualification: d.qualification_name,
            qualificationId: d.qualification_id,
            service: d.specialization_name,
            serviceId: d.specialization_id,
            doctor: d.doctor_name,
            doctorId: d.doctor_id,
            isVideo: d.video_consultation,
            price: d.amount,
        });
    }

    return datas;
};

const Loader = () => (
    <div className={classes.loaderContainer}>
        <CircularProgress color="primary" />
        <p>Egy pillanat és kész vagyunk</p>
    </div>
);

const Reservation = () => {
    const query = useQuery();
    const isHumed = useHumed();
    const { isAuthenticated } = useAuth();

    const [filterData, setFilterData] = useState(null);
    const { i18nRoute } = useTranslation();
    let selectedQualification = query.get("selectedQualification");
    if (selectedQualification) {
        selectedQualification = +selectedQualification;
    }
    let selectedDoctor = query.get("selectedDoctor");
    if (selectedDoctor) {
        selectedDoctor = +selectedDoctor;
    }
    let selectedInstitution = query.get("selectedInstitution");
    if (selectedInstitution) {
        selectedInstitution = +selectedInstitution;
    }
    let selectedService = query.get("selectedService");
    if (selectedService) {
        selectedService = +selectedService;
    }
    let onlyVideo = query.get("onlyVideo") === "true";
    let onlyOutpatient = query.get("onlyOutpatient") === "true";
    let noCache = query.get("noCache") === "true";

    useEffect(() => {
        get(
            isAuthenticated
                ? "api/v1/reservation/get-possibilities"
                : "api/v1/reservation/get-possibilities-public",
            false
        ).then((response) => {
            endLoading();
            setFilterData(
                sortData(response.data.data, onlyVideo, onlyOutpatient)
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderBreadcrumbs = () => {
        if (onlyVideo && !onlyOutpatient) {
            return (
                <Breadcrumbs
                    customTree={[
                        {
                            name: "Videókonzultáció",
                            url: i18nRoute("routes.services.videoConsultation"),
                        },
                        {
                            name: "Időpontfoglalás",
                            url: i18nRoute("routes.reservation", {
                                routeHash: '?onlyVideo=true"',
                            }),
                        },
                    ]}
                />
            );
        } else if (onlyOutpatient && !onlyVideo) {
            return (
                <Breadcrumbs
                    customTree={[
                        {
                            name: "Járóbeteg ellátás",
                            url: i18nRoute("routes.services.outPatient"),
                        },
                        {
                            name: "Időpontfoglalás",
                            url: i18nRoute("routes.reservation", {
                                routeHash: "?onlyOutpatient=true",
                            }),
                        },
                    ]}
                />
            );
        }

        return (
            <Breadcrumbs
                customTree={[
                    {
                        name: "Időpontfoglalás",
                        url: i18nRoute("routes.reservation"),
                    },
                ]}
            />
        );
    };

    return (
        <div style={{ padding: "0rem" }}>
            {!isHumed && (
                <div
                    style={{
                        maxWidth: "1280px",
                        margin: "0rem auto",
                        padding: "2.4rem 2.4rem 0rem 2.4rem",
                    }}
                >
                    {!isHumed && renderBreadcrumbs()}

                    {onlyVideo ? (
                        <PageHeader text="videokonzultációs időpontok" />
                    ) : (
                        <PageHeader text="személyes vizit időpontok" />
                    )}
                </div>
            )}

            {filterData ? (
                <ReservationPicker
                    selectedQualification={selectedQualification}
                    selectedDoctor={selectedDoctor}
                    selectedInstitution={selectedInstitution}
                    selectedService={selectedService}
                    onlyVideo={onlyVideo}
                    filterData={filterData}
                    noCache={noCache}
                />
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default Reservation;
