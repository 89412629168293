import { Box } from "@material-ui/core";
import type { ChangeEvent, HTMLInputTypeAttribute } from "react";

interface Props {
    label?: string;
    placeholder?: string;
    required?: boolean;
    value?: HTMLInputTypeAttribute;
    name?: HTMLInputTypeAttribute;
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = ({
    label,
    placeholder,
    required,
    value,
    name,
    onChange,
}: Props) => (
    <Box py={3}>
        {label && (
            <Box px={1} pb={1}>
                <label>{label}</label>
            </Box>
        )}
        <textarea
            placeholder={placeholder}
            required={required}
            value={value}
            name={name}
            onChange={onChange}
        ></textarea>
    </Box>
);

export default TextArea;
