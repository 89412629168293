import { packagePrices } from "../../config/variables";
import * as actionTypes from "./actionTypes";

export const toggleResponseModal = (show, responseModal) => ({
    type: actionTypes.TOGGLE_RESPONSE_MODAL,
    show,
    responseModal,
});

export const setCardName = (cardName = null) => ({
    type: actionTypes.SET_CARD_NAME,
    cardName,
});

export const toggleLoginModal = (show) => ({
    type: actionTypes.TOGGLE_LOGIN_MODAL,
    show,
});

export const toggleRegistrationModal = (show) => ({
    type: actionTypes.TOGGLE_REGISTRATION_MODAL,
    show,
});

export const toggleNotifications = (show) => ({
    type: actionTypes.TOGGLE_NOTIFICATIONS,
    show,
});

export const toggleServicePaymentModal = (
    show,
    price = null,
    service = null,
    preScript = null,
    identifier = null,
    packageId = null,
    serviceText = null,
    appointmentData = null
) => ({
    type: actionTypes.TOGGLE_SERVICE_PAYMENT_MODAL,
    show,
    price,
    service,
    preScript,
    identifier,
    packageId,
    serviceText,
    appointmentData,
});

export const toggleQualificationsModal = (show) => ({
    type: actionTypes.TOGGLE_QUALIFICATIONS_MODAL,
    show,
});

export const setYellowBar = (yellowBar) => ({
    type: actionTypes.SET_YELLOW_BAR,
    yellowBar,
});

export const toggleUserMenu = (show) => ({
    type: actionTypes.TOGGLE_USERMENU,
    show,
});

export const toggleHamburger = (show) => ({
    type: actionTypes.TOGGLE_HAMBURGER,
    show,
});

export const toggleSubscriptionAndServiceModal = (show) => ({
    type: actionTypes.TOGGLE_SUBSCRIPTION_AND_SERVICE_MODAL,
    show,
});

export const setSubscriptionAndServiceModal = (
    service = null,
    servicePrices = [],
    freeOccasions = null,
    pckgPrices = packagePrices
) => ({
    type: actionTypes.SET_SUBSCRIPTION_AND_SERVICE_MODAL,
    service,
    packagePrices: pckgPrices,
    freeOccasions,
    servicePrices,
});

export const toggleSubscriptionDetails = (show) => ({
    type: actionTypes.TOGGLE_SUBSCRIPTION_DETAILS,
    show,
});
