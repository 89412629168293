import { Link } from "react-router-dom";
import { Icons } from "../../assets/Theme";
import useTranslation from "../../features/i18n/useTranslation";
import classes from "./Hit.module.css";

const Hit = (props) => {
    const { i18nRoute } = useTranslation();

    let firstHalf;
    let secondHalf;
    let link;
    if (props.text.indexOf(":howToLink") > -1) {
        let linkText = ":howToLink";
        firstHalf = props.text.substr(0, props.text.indexOf(linkText));
        secondHalf = props.text.substr(
            props.text.indexOf(linkText) + linkText.length
        );
        link = (
            <Link className={classes.link} to="/hogyan-mukodik">
                ide kattintva olvashatsz.
            </Link>
        );
    } else if (props.text.indexOf(":paymentsLink") > -1) {
        let linkText = ":paymentsLink";
        firstHalf = props.text.substr(0, props.text.indexOf(linkText));
        secondHalf = props.text.substr(
            props.text.indexOf(linkText) + linkText.length
        );
        link = (
            <Link className={classes.link} to="/profile/payments">
                Fizetési módok
            </Link>
        );
    } else if (props.text.indexOf(":subscriptionsLink") > -1) {
        let linkText = ":subscriptionsLink";
        firstHalf = props.text.substr(0, props.text.indexOf(linkText));
        secondHalf = props.text.substr(
            props.text.indexOf(linkText) + linkText.length
        );
        link = (
            <Link className={classes.link} to="/profile/subscriptions">
                Előfizetések
            </Link>
        );
    } else if (props.text.indexOf(":privacyLink") > -1) {
        let linkText = ":privacyLink";
        firstHalf = props.text.substr(0, props.text.indexOf(linkText));
        secondHalf = props.text.substr(
            props.text.indexOf(linkText) + linkText.length
        );
        link = (
            <a className={classes.link} href={i18nRoute("routes.privacy")}>
                {" "}
                adatvédelmi tájékoztatót!
            </a>
        );
    }

    return (
        <div className={classes.Hit}>
            <img
                src={Icons.lightbulbGreen}
                className={classes.icon}
                alt=""
            ></img>
            <div>
                <p className={classes.title}>{props.title}</p>
                <p className={classes.text}>
                    {firstHalf ? firstHalf : props.text} {link ? link : null}{" "}
                    {secondHalf ? secondHalf : null}
                </p>
            </div>
        </div>
    );
};

export default Hit;
