import { useRef } from "react";
import Slider from "react-slick";
import { Grid } from "@material-ui/core";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import Modal from "../../UI/Modal/Modal";
import CustomDatePicker from "../../UI/CustomDatePicker/CustomDatePicker";
import CountryPicker from "../../UI/CountryPicker/CountryPicker";
import { convertDate, responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import { post } from "../../../config/fetch";
import classes from "./UserDataModal.module.css";

// TODO: bejelentkezési modal után hova irányítsuk a usert
const UserDataModal = (props) => {
    const slider = useRef(null);
    const paymentNecessary = !!props.userData.packagePrice;

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: false,
        initialSlide: props.userDataModalStep,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        swipe: false,
    };

    const savePersonalData = () =>
        post(
            "user/update-user-personal-data",
            {
                data: {
                    birthDate: convertDate(props.userData.birthDate),
                    birthPlace: props.userData.birthPlace,
                    mothersName: props.userData.mothersName,
                    securityNumber: props.userData.securityNumber,
                },
            },
            false
        )
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        slider.current.slickNext();
                        props.setUserDataModalStep(1);

                        break;
                    case "INVALID_SECURITY_NUMBER":
                        responseMessage("A megadott TAJ szám hibás!");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                // slider.current.slickNext();
                // props.setUserDataModalStep(1);
            })
            .catch((error) => console.error(error));

    const saveBillingData = () =>
        post(
            "payment/update-user-billing-data",
            {
                data: {
                    name: props.userData.billingName,
                    country: props.userData.country,
                    zip: props.userData.zip,
                    settlement: props.userData.settlement,
                    street: props.userData.street,
                    house_number: props.userData.houseNumber,
                    floor_door: props.userData.floorDoor,
                    tax_number: props.userData.taxNumber,
                },
            },
            false
        ).catch((error) => console.error(error));

    const button0Handler = () => {
        if (
            props.userData.birthDate &&
            props.userData.birthPlace &&
            props.userData.securityNumber &&
            props.userData.mothersName
        ) {
            savePersonalData();
        } else {
            responseMessage("Minden adat megadása kötelező");
        }
    };
    const button1Handler = () => {
        if (
            props.userData.billingName &&
            props.userData.country &&
            props.userData.zip &&
            props.userData.settlement &&
            props.userData.street &&
            props.userData.houseNumber
        ) {
            saveBillingData();
            props.toggleFreeSubscriptionModal(false);
            if (paymentNecessary) {
                props.setUserDataModalStep(3);
            } else {
                props.setUserDataModalStep(-1);
            }
        } else {
            responseMessage("A csillaggal jelölt mezők kitöltése kötelező!");
        }
    };

    const back1Handler = () => {
        slider.current.slickPrev();
        props.setUserDataModalStep(0);
    };

    const back0Handler = () => props.closeModal();

    return (
        <Modal
            open={props.open}
            key="userDataModal"
            closeModal={props.closeModal}
            disableBackdropClick
            desktopCloseIcon
            confirmClose
        >
            <div className={`${classes.UserDataModal}`}>
                <Slider {...sliderSettings} ref={slider}>
                    <div className={classes.UserDataModalInner}>
                        <div className={classes.headerRow}>
                            <div className={classes.line}></div>
                            <span className={classes.header}>
                                Adatok kitöltése
                            </span>
                            <div className={classes.line}></div>
                        </div>
                        <p className={classes.subheader}>
                            2 perc alatt most sok időt és beállítást spórolhatsz
                            meg!
                        </p>
                        <p className={classes.infoText}>
                            Szolgáltatásaink igénybevételéhez néhány
                            információra még szükségünk lesz. 4 lépésben most
                            minden szükséges adatot beállíthatsz!
                        </p>
                        <p
                            className={classes.counter}
                            style={{ marginTop: "-2rem" }}
                        >
                            1 / {paymentNecessary ? 3 : 2}
                        </p>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.inputContainer}>
                                    <p className={classes.label}>
                                        Születési dátum
                                    </p>
                                    <CustomDatePicker
                                        style={{ fontSize: "1.1rem" }}
                                        value={props.userData.birthDate}
                                        onChange={(e) =>
                                            props.setUserDataByKey(
                                                "birthDate",
                                                e
                                            )
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.inputContainer}>
                                    <p className={classes.label}>
                                        Születési hely
                                    </p>
                                    <Input
                                        bigger
                                        className={classes.input}
                                        defaultValue={props.userData.birthPlace}
                                        onChange={(e) =>
                                            props.setUserDataByKey(
                                                "birthPlace",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.inputContainer}>
                                    <p className={classes.label}>TAJ szám</p>
                                    <Input
                                        bigger
                                        className={classes.input}
                                        type="taj"
                                        defaultValue={
                                            props.userData.securityNumber
                                        }
                                        onChange={(e) =>
                                            props.setUserDataByKey(
                                                "securityNumber",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.inputContainer}>
                                    <p className={classes.label}>
                                        Anyja leánykori neve
                                    </p>
                                    <Input
                                        bigger
                                        className={classes.input}
                                        defaultValue={
                                            props.userData.mothersName
                                        }
                                        onChange={(e) =>
                                            props.setUserDataByKey(
                                                "mothersName",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Button
                            text="Folytatás"
                            className={classes.button}
                            style={{ marginTop: "5rem" }}
                            onClick={button0Handler}
                        />
                        <Button
                            text="Kitöltés később"
                            className={`${classes.button} ${classes.inverseButton}`}
                            onClick={back0Handler}
                        />
                    </div>
                    <div className={classes.UserDataModalInner}>
                        <div className={classes.headerRow}>
                            <div className={classes.line}></div>
                            <span className={classes.header}>
                                Számlázási adatok
                            </span>
                            <div className={classes.line}></div>
                        </div>
                        <p className={classes.counter}>
                            2 / {paymentNecessary ? 3 : 2}
                        </p>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.inputContainer}>
                                    <p className={classes.label}>
                                        Számlázási név*
                                    </p>
                                    <Input
                                        bigger
                                        className={classes.input}
                                        defaultValue={
                                            props.userData.billingName
                                        }
                                        onChange={(e) =>
                                            props.setUserDataByKey(
                                                "billingName",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={0} sm={6}></Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={7}>
                                        <div className={classes.inputContainer}>
                                            <p className={classes.label}>
                                                Ország*
                                            </p>
                                            <CountryPicker
                                                value={props.userData.country}
                                                onChange={(e) =>
                                                    props.setUserDataByKey(
                                                        "country",
                                                        e
                                                    )
                                                }
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <div className={classes.inputContainer}>
                                            <p className={classes.label}>
                                                Irányítószám*
                                            </p>
                                            <Input
                                                bigger
                                                className={classes.input}
                                                type="number"
                                                defaultValue={
                                                    props.userData.zip
                                                }
                                                onChange={(e) =>
                                                    props.setUserDataByKey(
                                                        "zip",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.inputContainer}>
                                    <p className={classes.label}>Település*</p>
                                    <Input
                                        bigger
                                        className={classes.input}
                                        defaultValue={props.userData.settlement}
                                        onChange={(e) =>
                                            props.setUserDataByKey(
                                                "settlement",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.inputContainer}>
                                    <p className={classes.label}>Utca*</p>
                                    <Input
                                        bigger
                                        className={classes.input}
                                        defaultValue={props.userData.street}
                                        onChange={(e) =>
                                            props.setUserDataByKey(
                                                "street",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <div className={classes.inputContainer}>
                                            <p className={classes.label}>
                                                Házszám*
                                            </p>
                                            <Input
                                                bigger
                                                className={classes.input}
                                                defaultValue={
                                                    props.userData.houseNumber
                                                }
                                                onChange={(e) =>
                                                    props.setUserDataByKey(
                                                        "houseNumber",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className={classes.inputContainer}>
                                            <p className={classes.label}>
                                                Emelet/ajtó
                                            </p>
                                            <Input
                                                bigger
                                                className={classes.input}
                                                defaultValue={
                                                    props.userData.floorDoor
                                                }
                                                onChange={(e) =>
                                                    props.setUserDataByKey(
                                                        "floorDoor",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputContainer}>
                                    <p className={classes.label}>
                                        Adószám (nem kötelező)
                                    </p>
                                    <Input
                                        bigger
                                        className={classes.input}
                                        defaultValue={props.userData.taxNumber}
                                        onChange={(e) =>
                                            props.setUserDataByKey(
                                                "taxNumber",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <p className={classes.note}>
                            A *-al jelölt mezők kitöltése mindenképp szükséges.
                        </p>
                        <Button
                            text="Folytatás"
                            className={classes.button}
                            style={{ marginTop: "5rem" }}
                            onClick={button1Handler}
                        />
                        <p className={classes.link} onClick={back1Handler}>
                            {`<`} Visszalépés
                        </p>
                    </div>
                </Slider>
            </div>
        </Modal>
    );
};

export default UserDataModal;
