import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import firebase from "firebase/app";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import Line from "../UI/Line/Line";
import Input from "../UI/Input/Input";
import Textarea from "../UI/Textarea/Textarea";
import Btn from "../UI/Btn/Btn";
import Checkbox from "../UI/Checkbox/Checkbox";
import { isSafari, responseMessage } from "../../utility";
import { endLoading, post } from "../../config/fetch";
import { ALERT_MESSAGES } from "../../config/variables";
import classes from "./ContactForm.module.css";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [terms, setTerms] = useState("");
    const [sent, setSent] = useState(false);

    const [recaptchaBuilt, setRecaptchaBuilt] = useState(false);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        if (!firebase.apps.length) {
            const firebaseConfig = {
                apiKey: "AIzaSyCKPSGKm1w5ngLDBZnQuzvxuhlAlo1_sqg",
                authDomain: "t-doc-app.firebaseapp.com",
                databaseURL: "https://t-doc-app.firebaseio.com",
                projectId: "t-doc-app",
                storageBucket: "t-doc-app.appspot.com",
                messagingSenderId: "148544423521",
                appId: "1:148544423521:web:6667731e3b06a685794c20",
            };

            firebase.initializeApp(firebaseConfig);
        }
    }, []);

    useEffect(() => {
        if (!recaptchaBuilt) {
            firebase.auth().useDeviceLanguage();

            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: isSafari() ? "normal" : "invisible",
                    "error-callback": (response) =>
                        console.error("[RECAPTHCHA ERROR]: ", response),
                }
            );
            setRecaptchaBuilt(true);
        }

        if (sending) {
            window.recaptchaVerifier.verify().then(() => {
                handleSend();
                setSending(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recaptchaBuilt, sending]);

    const handleSend = () => {
        if (!name || !phone || !email || !message || !terms) {
            responseMessage("Minden adat megadása kötelező!");

            return;
        }

        //Email ellenörzés
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            responseMessage("Hibás email!");

            return;
        }

        //Telefon ellenörzés
        if (!isValidPhoneNumber(phone)) {
            responseMessage("Hibás telefonszám!");

            return;
        }

        const phoneNumber = parsePhoneNumber(phone);
        let phoneCountry;
        if (phoneNumber) {
            phoneCountry = phoneNumber.country;
        }

        post("api/v1/contact/create-message", {
            name,
            email,
            phone,
            phoneCountry,
            message,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setSent(true);
                        endLoading();

                        break;
                    case "MISSING_DATA":
                        responseMessage("Minden adat megadása kötelező!");
                        endLoading();

                        break;
                    case "WRONG_EMAIL":
                        responseMessage("Hibás email!");
                        endLoading();

                        break;
                    case "WRONG_PHONE":
                        responseMessage("Hibás telefonszám!");
                        endLoading();

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    return (
        <>
            <Line style={{ margin: "2rem 0" }} />
            <Grid container>
                <Grid item>
                    <h3 className={classes.popularQuestionsHeader}>
                        További kérdésed van?
                    </h3>
                    <p
                        className={classes.subHeader}
                        style={{ marginTop: "1rem" }}
                    >
                        Vedd fel velünk a kapcsolatot!
                    </p>
                </Grid>
            </Grid>
            {!sent && (
                <>
                    <Grid container spacing={2}>
                        <Grid item sm={4} xs={12}>
                            <p className={classes.formLabel}>Név</p>
                            <div className={classes.formInputContainer}>
                                <Input
                                    type="text"
                                    className={classes.input}
                                    placeholder="Név"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <p className={classes.formLabel}>Email</p>
                            <div className={classes.formInputContainer}>
                                <Input
                                    type="text"
                                    className={classes.input}
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <p className={classes.formLabel}>Telefonszám</p>
                            <div className={classes.formInputContainer}>
                                <Input
                                    type="phone"
                                    className={classes.input}
                                    placeholder="Telefonszám"
                                    onChange={(e) => setPhone(e)}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: "1rem" }}>
                        <Grid item xs={12}>
                            <Textarea
                                label="Üzenet"
                                placeholder="Üzenet"
                                maxLength="1500"
                                onChange={(e) => setMessage(e)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: "1rem" }}>
                        <Grid item xs={12}>
                            <Checkbox
                                onChange={(e) => setTerms(e)}
                                label={
                                    <span
                                        style={{
                                            position: "relative",
                                            top: 1,
                                            fontFamily: "var(--segoe)",
                                        }}
                                    >
                                        Elolvastam és elfogadom az{" "}
                                        <a
                                            href="/adatvedelmi_nyilatkozat"
                                            style={{
                                                color: "var(--green)",
                                                fontFamily:
                                                    "var(--segoe-semibold)",
                                            }}
                                            target="_blank"
                                        >
                                            általános adatkezelési irányelveket
                                        </a>{" "}
                                        és a{" "}
                                        <a
                                            href="/aszf"
                                            style={{
                                                color: "var(--green)",
                                                fontFamily:
                                                    "var(--segoe-semibold)",
                                            }}
                                            target="_blank"
                                        >
                                            felhasználási feltételeket
                                        </a>
                                        .
                                    </span>
                                }
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            {!sent && (
                <div style={{ marginTop: "4rem" }}>
                    <Btn onClick={() => setSending(true)}>Küldés</Btn>
                </div>
            )}

            {sent && (
                <>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <p className={classes.sentText}>
                                Köszönjük üzenetedet! Hamarosan felvesszük Veled
                                a kapcsolatot.
                            </p>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: "4rem" }}>
                        <Btn onClick={() => setSent(false)}>Vissza</Btn>
                    </div>
                </>
            )}

            <Grid
                container
                justifyContent="center"
                style={{ marginTop: isSafari() ? "4rem" : 0 }}
            >
                <Grid item>
                    <div id="recaptcha-container"></div>
                </Grid>
            </Grid>
        </>
    );
};

export default ContactForm;
