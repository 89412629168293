import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import CardContainer from "../../../components/UI/CardContainer/CardContainer";
import Button from "../../../components/UI/Button/Button";
import { Icons, Images } from "../../../assets/Theme";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import { endLoading, post } from "../../../config/fetch";
import classes from "./InstitutionPage.module.css";

const InstitutionPage = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [institutionData, setInstitutionData] = useState({
        name: "",
        city: "",
        fullAddress: "",
        image: Images.placeholder,
        outPatient: true,
        inPatient: true,
        introduction: "",
        medioId: null,
    });

    const [replaceKeys, setReplaceKeys] = useState({
        institution: "Intézmény",
    });

    useEffect(() => {
        post("api/v1/medical/get-institution", {
            link: params.name,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        setInstitutionData({
                            name: response.data.data.name,
                            image: response.data.data.image,
                            city: response.data.data.city,
                            fullAddress: response.data.data.full_address,
                            outPatient:
                                +response.data.data.out_patient_available,
                            inPatient: +response.data.data.in_patient_available,
                            introduction: response.data.data.introduction,
                            medioId: response.data.data.medio_id,
                        });
                        setReplaceKeys({
                            institution: response.data.data.name,
                        });

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onReservationButtonClick = () =>
        navigate(
            `/idopontfoglalas?selectedInstitution=${institutionData.medioId}`
        );

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs replaceKeys={replaceKeys} />
                <CardContainer className={classes.card}>
                    <div className={classes.header}>
                        <div className={classes.headerInfoCol}>
                            <div>
                                <p className={classes.name}>
                                    {institutionData.name}
                                </p>
                                <p className={classes.description}>
                                    {institutionData.fullAddress}
                                </p>
                                <div className={classes.serviceRow}>
                                    {institutionData.outPatient ? (
                                        <img
                                            src={Icons.tickGreen}
                                            className={
                                                classes.acceptPatientIcon
                                            }
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={Icons.xRed}
                                            className={
                                                classes.acceptPatientIcon
                                            }
                                            alt=""
                                        />
                                    )}
                                    <span>Járóbeteg ellátás </span>
                                </div>
                                <div className={classes.serviceRow}>
                                    {institutionData.inPatient ? (
                                        <img
                                            src={Icons.tickGreen}
                                            className={
                                                classes.acceptPatientIcon
                                            }
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={Icons.xRed}
                                            className={
                                                classes.acceptPatientIcon
                                            }
                                            alt=""
                                        />
                                    )}
                                    <span>Fekvőbeteg ellátás</span>
                                </div>
                            </div>
                            <div className={classes.buttonContainer}>
                                <Button
                                    text="Időpontot foglalok"
                                    className={`${classes.button} ${
                                        !institutionData.inPatient &&
                                        classes.disabled
                                    }`}
                                    onClick={onReservationButtonClick}
                                />
                            </div>
                        </div>
                        <img
                            src={institutionData.image}
                            className={classes.image}
                            alt=""
                        />
                    </div>
                    <div
                        className={classes.content}
                        style={props.contentContainerStyle}
                    >
                        <p className={classes.introductionHeader}>
                            Bemutatkozás
                        </p>
                        <p className={classes.introduction}>
                            {institutionData.introduction}
                        </p>
                    </div>
                </CardContainer>
            </div>
        </Container>
    );
};

export default InstitutionPage;
