export const description = [
    {
        title: "Milyen panaszok esetén hasznos az orvosi chat?",
        description: [
            "Egy családon belül gyakran fordulnak elő olyan egészségügyi kérdések, melyek megválaszolása orvosi szakértelmet igényel. Amennyiben nem akut kérdésről van szó, hanem egy régóta húzódó kellemetlen tünetről, ismert meglévő betegségről, úgy a T-DOC orvos válaszol funkció, azaz az orvosi chat lesz a tökéletes megoldás.",
            "Az orvos válaszol funkció lehetőséget biztosít arra is, hogy üzeneted mellé egy fájlt csatolj az általad kiválasztott szakorvosnak, így friss diagnosztikai vizsgálatod, laboreredményed értékelését is kérheted.",
            "Amennyiben orvosod a személyes vizitet és vizsgálatot követően rendszeres gyógyszeres kezelést ír fel, úgy az online receptírásra is jó megoldás lehet az orvosi chat. Ez azonban csak két esetben valósulhat meg. Az egyik az, ha a kezelést javasló orvosoddal veszed fel a kapcsolatot az orvos válaszol chat platformon keresztül. A másik eset az, ha a chatnél a kiválasztott orvosnak feltöltöd a gyógyszeres kezelésre vonatkozó javaslatot tartalmazó orvosi ambuláns lapot, mely nem régebbi 3 hónapnál.",
        ],
    },
    {
        title: "Mi minősül orvosi chatnek?",
        description: [
            "Fontos tudnod, hogy az orvos válaszol - orvosi chat nem egyenlő a másodvéleménnyel. Ezen szolgáltatás keretein belül orvosunktól egészségügyi problémáddal kapcsolatos kérdéseket tehetsz fel, melyet készségesen megválaszolunk.",
            "Amennyiben orvosod által felállított diagnózisról szeretnél egy ellenőrző véleményt, vagy éppen műtét előtt állsz, de szeretnél egy másik szaktekintélytől állásfoglalást kérni mielőtt megtörténik a beavatkozás, akkor válaszd Másodvélemény szolgáltatásunkat.",
            "Ha kérdésed mégis a meglévő diagnózis, leletek kiértékelésére irányul, és szakorvosunknak több oldalnyi ambuláns lapot kell átolvasnia, ez esetben a kiértékelés csak akkor történik meg, ha elfogadod az orvosi chat szolgáltatásod másodvéleménnyé való átalakítását. Természetesen ez nem egy automatikus folyamat, ehhez a Te beleegyezésedre van szükség. További részletekért olvad el az ÁSZF-ünket!",
        ],
    },
];
