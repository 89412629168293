import cn from "classnames";
import Line from "../Line/Line";
import { discounts } from "../../../config/subscriptions";
import classes from "./SubscriptionDetails.module.css";

// const hasDiscount = (props, service) => discounts[props.name].includes(service);

const SubscriptionDetails = (props) => {
    const discountClass = (service) =>
        discounts[props.name].includes(service) ? classes.discounted : "";

    const discountAsterisk = (service) =>
        discounts[props.name].includes(service) ? "*" : "";

    const ClosedBody = () => (
        <ul className={classes.list}>
            <li>
                <span className={classes.left}>Egészség Hotline</span>
                {props.name === "Vendég" ? (
                    <span className={classes.right}>
                        {props.data.hotline} Ft
                    </span>
                ) : (
                    <div className={classes.highlight}>korlátlan</div>
                )}
            </li>
            <Line style={{ margin: "1rem 0" }} />

            <li>
                <span className={classes.left}>Azonnali</span>
                {props.name === "Vendég" && (
                    <span
                        className={`${classes.right} ${discountClass(
                            "dutyWeekday"
                        )}`}
                    >
                        H-P {props.data.dutyWeekday} Ft{" "}
                        {discountAsterisk("dutyWeekday")}
                    </span>
                )}
                {props.name === "Basic" ? (
                    props.data.dutyWeekday === "korlátlan" ? (
                        <span
                            className={`${classes.right} ${discountClass(
                                "dutyWeekend"
                            )}`}
                        >
                            korlátlan {discountAsterisk("dutyWeekend")}*
                        </span>
                    ) : (
                        <span
                            className={classes.right}
                        >{`-${props.data.dutyP}% kedvezmény`}</span>
                    )
                ) : (
                    ""
                )}
                {(props.name === "Optimum" || props.name === "Premium") && (
                    <div className={classes.highlight}>{props.data.dutyP}</div>
                )}
            </li>
            <li
                className={cn({
                    [classes.discounted]: props.name === "Vendég",
                })}
            >
                <span className={classes.left}>orvosi konzultáció</span>
                {props.name === "Vendég" ? (
                    <span
                        className={`${classes.right} ${discountClass(
                            "dutyWeekend"
                        )}`}
                    >
                        SZ-V {props.data.dutyWeekend} Ft{" "}
                        {discountAsterisk("dutyWeekend")}
                    </span>
                ) : null}
            </li>
            <Line style={{ margin: "1rem 0" }} />

            <li
                className={cn({
                    [classes.discounted]: props.name === "Vendég",
                })}
            >
                <span className={classes.left}>Orvosi chat</span>
                {props.name === "Vendég" && (
                    <span
                        className={`${classes.right} ${discountClass("chat")}`}
                    >
                        {props.data.chat} Ft {discountAsterisk("chat")}
                    </span>
                )}
                {props.name === "Basic" && (
                    <span
                        className={classes.right}
                    >{`-${props.data.chatP}% kedvezmény`}</span>
                )}
                {(props.name === "Optimum" || props.name === "Premium") && (
                    <div className={classes.highlight}>{props.data.chatP}</div>
                )}
            </li>
            <Line style={{ margin: "1rem 0" }} />

            <li
                className={cn({
                    [classes.discounted]: props.name === "Vendég",
                })}
            >
                <span className={classes.left}>Másodvélemény</span>
                {props.name === "Vendég" ? (
                    <span
                        className={`${classes.right} ${discountClass(
                            "secondOpinion"
                        )}`}
                    >
                        {props.data.secondOpinion} Ft{" "}
                        {discountAsterisk("secondOpinion")}
                    </span>
                ) : (
                    <span
                        className={classes.right}
                    >{`-${props.data.secondOpinionP}% kedvezmény`}</span>
                )}
            </li>
            <Line style={{ margin: "1rem 0" }} />
            <li>
                <span className={classes.left}>Videókonzultáció</span>
                {props.name === "Vendég" ? (
                    <span className={classes.right}>
                        {props.data.videoConsultation}
                    </span>
                ) : (
                    <span
                        className={classes.right}
                    >{`-${props.data.videoConsultationP}% kedvezmény`}</span>
                )}
            </li>
            <Line style={{ margin: "1rem 0" }} />
            <li>
                <span className={classes.left}>Járóbetegellátás</span>
                {props.name === "Vendég" ? (
                    <span className={classes.right}>
                        {props.data.outpatient}
                    </span>
                ) : (
                    <span
                        className={classes.right}
                    >{`-${props.data.outpatientP}% kedvezmény`}</span>
                )}
            </li>
            <Line style={{ margin: "1rem 0" }} />
        </ul>
    );

    const OpenedBody = () => (
        <ul className={classes.list}>
            <li>
                <span className={classes.left}>Egészség Hotline</span>
                {props.name === "Vendég" ? (
                    <span className={classes.right}>
                        {props.data.hotline} Ft
                    </span>
                ) : (
                    <div className={classes.highlight}>korlátlan</div>
                )}
            </li>
            <Line style={{ margin: "1rem 0" }} />

            <li>
                <span className={classes.left}>
                    Azonnali orvosi konzultáció
                </span>
                {props.name === "Vendég" && null}
                {props.name === "Basic" ? (
                    props.data.dutyWeekday === "korlátlan" ? (
                        ""
                    ) : (
                        <span
                            className={classes.right}
                        >{`-${props.data.dutyP}%`}</span>
                    )
                ) : (
                    ""
                )}
                {(props.name === "Optimum" || props.name === "Premium") && (
                    <div className={classes.highlight}>{props.data.dutyP}</div>
                )}
            </li>
            <li>
                <span className={classes.left}>Ár</span>
                <span
                    className={`${classes.right} ${discountClass(
                        "dutyWeekday"
                    )}`}
                >
                    {props.data.dutyWeekend === "korlátlan"
                        ? props.name === "Basic"
                            ? "korlátlan"
                            : "0 Ft"
                        : `H-P ${props.data.dutyWeekday} Ft | SZ-V ${props.data.dutyWeekend} Ft`}
                    {discountAsterisk("dutyWeekday")}
                    {props.name === "Basic" && "*"}
                </span>
            </li>
            <Line style={{ margin: "1rem 0" }} />

            <li>
                <span className={classes.left}>Orvosi chat</span>
                {props.name === "Vendég" && null}
                {props.name === "Basic" && (
                    <span
                        className={classes.right}
                    >{`-${props.data.chatP}%`}</span>
                )}
                {(props.name === "Optimum" || props.name === "Premium") && (
                    <div className={classes.highlight}>{props.data.chatP}</div>
                )}
            </li>
            <li>
                <span className={classes.left}>Ár</span>
                <span className={`${classes.right} ${discountClass("chat")}`}>
                    {props.data.chat === "korlátlan"
                        ? "0 Ft"
                        : `${props.data.chat} Ft`}
                    {discountAsterisk("chat")}
                </span>
            </li>
            <Line style={{ margin: "1rem 0" }} />

            <li>
                <span className={classes.left}>Másodvélemény</span>
                {props.name === "Vendég" ? null : (
                    <span
                        className={classes.right}
                    >{`-${props.data.secondOpinionP}%`}</span>
                )}
            </li>
            <li>
                <span className={classes.left}>Ár</span>
                <span
                    className={`${classes.right} ${discountClass(
                        "secondOpinion"
                    )}`}
                >
                    {props.data.secondOpinion} Ft
                    {discountAsterisk("secondOpinion")}
                </span>
            </li>
            <Line style={{ margin: "1rem 0" }} />
            <li>
                <span className={classes.left}>Videókonzultáció</span>
                {props.name === "Vendég" ? null : (
                    <span
                        className={classes.right}
                    >{`-${props.data.videoConsultationP}%`}</span>
                )}
            </li>
            <li>
                <span className={classes.left}>Ár</span>
                <span className={classes.right}>
                    {props.data.videoConsultation}
                </span>
            </li>
            <Line style={{ margin: "1rem 0" }} />
            <li>
                <span className={classes.left}>Járóbetegellátás</span>
                {props.name === "Vendég" ? null : (
                    <span
                        className={classes.right}
                    >{`-${props.data.outpatientP}%`}</span>
                )}
            </li>
            <li>
                {props.name === "Vendég" ? (
                    <span className={classes.left}>
                        Időpont foglalás listaáron
                    </span>
                ) : (
                    <span
                        className={classes.left}
                    >{`akár ${props.data.outpatientP}% kedvezmény a foglalás árából`}</span>
                )}
            </li>
            <Line style={{ margin: "1rem 0" }} />
        </ul>
    );

    return props.open ? (
        props.openedBody ? (
            props.openedBody
        ) : (
            <OpenedBody />
        )
    ) : props.closedBody ? (
        props.closedBody
    ) : (
        <ClosedBody />
    );
};

export default SubscriptionDetails;
