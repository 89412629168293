import { Route, Routes } from "react-router-dom";
import QualificationPage from "../../components/Facelift/pages/Qualification";
import QualificationsSummary from "./QualificationsSummary";

const Qualifications = () => (
    <Routes>
        <Route path="/" element={<QualificationsSummary />} />
        <Route path=":qualification" element={<QualificationPage />} />
    </Routes>
);

export default Qualifications;
