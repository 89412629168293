import cn from "classnames";
import classes from "./Checkbox.module.css";

const Checkbox = ({ name, value, label, onChange, checked, isRaw = false }) => {
    const handleChange = (e) => {
        onChange?.(e);
    };

    return (
        <div
            className={cn(classes.checkboxWrapper, {
                [classes.raw]: isRaw,
            })}
        >
            <input
                type="checkbox"
                id={`_${name}-${value}`}
                name={name}
                value={value}
                onChange={handleChange}
                checked={checked}
            />
            <label
                htmlFor={`_${name}-${value}`}
                className={classes.checkboxLabel}
            >
                <span>{label}</span>
            </label>
        </div>
    );
};

export default Checkbox;
