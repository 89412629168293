import * as actionTypes from "./actionTypes";

export const startLoad = () => ({
    type: actionTypes.START_LOADING,
});

export const endLoad = () => ({
    type: actionTypes.END_LOADING,
});

export const toggleVerificationModal = (show) => ({
    type: actionTypes.TOGGLE_VERIFICATION_MODAL,
    show,
});

export const setUserPhone = (phone) => ({
    type: actionTypes.SET_USER_PHONE,
    phone,
});
