import classes from "./PhoneVerificationModal.module.css";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "firebase/auth";
import { ALERT_MESSAGES } from "../../../config/variables";
import { responseMessage } from "../../../utility";
import Modal from "../../UI/Modal/Modal";
import { endLoading, get, post } from "../../../config/fetch";
import * as actions from "../../../store/actions/index";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import { Images } from "../../../assets/Theme";
import { login } from "../../../features/auth/slice";
import { useDispatch } from "../../../store";
import useTranslation from "../../../features/i18n/useTranslation";
import { useNavigate } from "react-router-dom";

const PhoneVerificationModal = (props) => {
    // const location = useLocation();
    const navigate = useNavigate();
    // const [recaptchaBuilt, setRecaptchaBuilt] = useState(false);
    const [verificationCode, setVerificationCode] = useState(null);
    const [open, toggleOpen] = useState(props.open);
    // const [resendVisible, setResendVisible] = useState(false);
    const { i18nRoute } = useTranslation();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (!firebase.apps.length) {
    //         const firebaseConfig = {
    //             apiKey: "AIzaSyCKPSGKm1w5ngLDBZnQuzvxuhlAlo1_sqg",
    //             authDomain: "t-doc-app.firebaseapp.com",
    //             databaseURL: "https://t-doc-app.firebaseio.com",
    //             projectId: "t-doc-app",
    //             storageBucket: "t-doc-app.appspot.com",
    //             messagingSenderId: "148544423521",
    //             appId: "1:148544423521:web:6667731e3b06a685794c20",
    //         };
    //
    //         firebase.initializeApp(firebaseConfig);
    //     }
    // }, []); // TODO: visszarakni, ha működik code verification

    // useEffect(() => {
    //     if (recaptchaBuilt || !open) {
    //         return;
    //     }
    //
    //     firebase.auth().useDeviceLanguage();
    //     setTimeout(() => {
    //         window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //             "recaptcha-container",// TODO: visszarakni, ha működik code verification
    //             {
    //                 // size: "normal",
    //                 size: isSafari() ? "normal" : "invisible",
    //                 "error-callback": (response) =>
    //                     console.error("[RECAPTHCHA ERROR]: ", response),
    //             }
    //         );
    //         setRecaptchaBuilt(true);
    //         sendVerificationCode();
    //     }, 1000);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [recaptchaBuilt, open]); // TODO: visszarakni, ha működik code verification

    useEffect(() => {
        if (props.open) {
            // setInterval(() => { console.log('INT') }, 2000);
            // setTimeout(() => setResendVisible(true), 20 * 1000); // TODO: visszarakni, ha működik code verification
        }
    }, [props.open]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        toggleOpen(props.open);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
        sendEmail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    // const sendVerificationCode = () => {
    //     const phoneNumber = props.phone;
    //
    //     if (!phoneNumber) {
    //         return;
    //     }
    //
    //     firebase
    //         .auth()
    //         .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
    //         .then(function (confirmationResult) {
    //             window.confirmationResult = confirmationResult;
    //         })
    //         .catch(function (error) {
    //             responseMessage(ALERT_MESSAGES.errorTryAgain);
    //             console.error("[FIREBASE_ERROR]: ", error);
    //         });
    // };

    const sendEmail = () =>
        post("api/v1/auth/send-login-email", { phone: props.phone })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Az emailt elküldtük az email címére!");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });

    // const resendSMS = () => {
    //     startLoading();
    //     sendVerificationCode();
    //     setTimeout(() => endLoading(), 1000);
    // };

    const verifyUser = () => {
        if (!verificationCode || !verificationCode.length) {
            responseMessage("A kód nem maradhat üresen!");

            return false;
        }

        post("auth/verify-code", { verificationCode })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        dispatch(
                            login({
                                userId: response.data.id,
                                token: response.data.token,
                            })
                        );
                        close();
                        if (location.pathname === "/idopont-kivalasztva") {
                            get("api/v1/subscription/get-subscription", false)
                                .then((response) =>
                                    props.setSubscription(response.data)
                                )
                                .catch((error) => {
                                    console.error(error);
                                    props.setSubscription(null);
                                });

                            return;
                        }
                        if (
                            location.pathname.startsWith(
                                i18nRoute("routes.services.base", {
                                    defaultValue: "/szolgaltatasok",
                                })
                            )
                        ) {
                            let currentPage = location.pathname;
                            navigate({
                                pathname: "/empty",
                            });
                            navigate(
                                {
                                    pathname: currentPage,
                                },
                                { replace: true }
                            );
                        } else {
                            navigate(
                                i18nRoute("routes.profile.base", {
                                    routeHash: `?firstLogin=${response.data.firstLogin}`,
                                })
                            );
                        }

                        break;
                    case "AUTHENTICATION_FAILED":
                        responseMessage(
                            "Megadott kód helyten, kérjük próbáld újra!"
                        );

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error("[FIREBASE IDTOKEN]: ", error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                endLoading();
            });
    };

    // const verifyUser = () => {
    //     if (!verificationCode || !verificationCode.length) {
    //         responseMessage("A kód nem maradhat üresen!");
    //
    //         return false;
    //     }
    //     if (window.confirmationResult) {
    //         startLoading();
    //         window.confirmationResult
    //             .confirm(verificationCode)
    //             .then(() => {
    //                 firebase
    //                     .auth()
    //                     .currentUser.getIdToken(/* forceRefresh */ true)
    //                     .then(function (idToken) {
    //                         post("auth/verify-firebase", { idToken })
    //                             .then((response) => {
    //                                 endLoading();
    //                                 switch (response.data.responseCode) {
    //                                     case "OK":
    //                                         window.recaptchaVerifier.clear();
    //                                         dispatch(
    //                                             login({
    //                                                 userId: response.data.id,
    //                                                 token: response.data.token,
    //                                             })
    //                                         );
    //                                         props.setUserPhone(null);
    //                                         close();
    //                                         if (
    //                                             location.pathname ===
    //                                             "/idopont-kivalasztva"
    //                                         ) {
    //                                             get(
    //                                                 "api/v1/subscription/get-subscription",
    //                                                 false
    //                                             )
    //                                                 .then((response) =>
    //                                                     props.setSubscription(
    //                                                         response.data
    //                                                     )
    //                                                 )
    //                                                 .catch((error) => {
    //                                                     console.error(error);
    //                                                     props.setSubscription(
    //                                                         null
    //                                                     );
    //                                                 });
    //
    //                                             return;
    //                                         }
    //                                         if (
    //                                             location.pathname.startsWith(
    //                                                 i18nRoute(
    //                                                     "routes.services.base",
    //                                                     {
    //                                                         defaultValue:
    //                                                             "/szolgaltatasok",
    //                                                     }
    //                                                 )
    //                                             )
    //                                         ) {
    //                                             let currentPage =
    //                                                 location.pathname;
    //                                             navigate({
    //                                                 pathname: "/empty",
    //                                             });
    //                                             navigate(
    //                                                 {
    //                                                     pathname: currentPage,
    //                                                 },
    //                                                 { replace: true }
    //                                             );
    //                                         } else {
    //                                             navigate(
    //                                                 i18nRoute(
    //                                                     "routes.profile.base",
    //                                                     {
    //                                                         routeHash: `?firstLogin=${response.data.firstLogin}`,
    //                                                     }
    //                                                 )
    //                                             );
    //                                         }
    //
    //                                         break;
    //                                     case "AUTHENTICATION_FAILED":
    //                                         responseMessage(
    //                                             "Nem található felhasználó a megadott adatokkal!"
    //                                         );
    //
    //                                         break;
    //                                     default:
    //                                         responseMessage(
    //                                             ALERT_MESSAGES.errorTryAgain
    //                                         );
    //
    //                                         break;
    //                                 }
    //                             })
    //                             .catch((error) => {
    //                                 console.error(
    //                                     "[FIREBASE IDTOKEN]: ",
    //                                     error
    //                                 );
    //                                 responseMessage(
    //                                     ALERT_MESSAGES.errorTryAgain
    //                                 );
    //                                 endLoading();
    //                             });
    //                     })
    //                     .catch(() => {
    //                         responseMessage(ALERT_MESSAGES.errorTryAgain);
    //                         endLoading();
    //                     });
    //             })
    //             .catch(function (error) {
    //                 endLoading();
    //                 responseMessage(
    //                     "A megadott kód helytelen! Kérjük, próbáld meg újra."
    //                 );
    //                 console.error("[FIREBASE CATCH]: ", error);
    //             });
    //     }
    // };

    const close = () => {
        if (props.closeModal) {
            props.closeModal();
        } else {
            props.toggleVerificationModal(false);
        }
    };

    return (
        <>
            <Modal open={open} key={open} closeModal={close}>
                <div className={classes.PhoneVerificationModalInner}>
                    <div className={classes.headerRow}>
                        <div className={classes.line}></div>
                        <span className={classes.header}>Hitelesítés</span>
                        <div className={classes.line}></div>
                    </div>
                    <div className={classes.narrowCol}>
                        {/*<p*/}
                        {/*    className={classes.infoText}*/}
                        {/*    style={{marginTop: "3rem", marginBottom: "5rem"}}*/}
                        {/*>*/}
                        {/*    Kérünk add meg a 6 jegyű hitelesítő kódot, melyet*/}
                        {/*    elküldtünk az általad megadott telefonszámra.*/}
                        {/*</p>*/}
                        <div>
                            <p
                                className={classes.infoText}
                                style={{
                                    marginTop: "3rem",
                                    marginBottom: "5rem",
                                }}
                            >
                                Kérjük, kattints az emailben kapott linkre vagy
                                add meg lentebb ugyanebben az emailben kapott
                                hitelesítő kódot!
                            </p>
                            <div className={classes.inputContainer}>
                                <p className={classes.label}>Hitelesítő kód</p>
                                <Input
                                    bigger
                                    className={classes.input}
                                    onChange={(e) =>
                                        setVerificationCode(e.target.value)
                                    }
                                />
                            </div>
                            <Button
                                onClick={verifyUser}
                                className={classes.button}
                                rightIcon={Images.arrow}
                            >
                                Folytatás
                            </Button>
                        </div>
                        {open && <div id="recaptcha-container"></div>}

                        {/*<div className={classes.inputContainer}>*/}
                        {/*    <p className={classes.label}>Hitelesítő kód</p>*/}
                        {/*    <Input*/}
                        {/*        bigger*/}
                        {/*        className={classes.input}*/}
                        {/*        onChange={(e) =>*/}
                        {/*            setVerificationCode(e.target.value)*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<Button*/}
                        {/*    onClick={verifyUser}*/}
                        {/*    className={classes.button}*/}
                        {/*    rightIcon={Images.arrow}*/}
                        {/*>*/}
                        {/*    Folytatás*/}
                        {/*</Button>*/}
                        {/*<p className={classes.resend}>Nem kapta meg a kódot?</p>*/}
                        {/*<p className={classes.resend}>*/}
                        {/*    <span className={classes.link} onClick={resendSMS}>*/}
                        {/*        Kód újraküldése*/}
                        {/*    </span>{" "}*/}
                        {/*    vagy{" "}*/}
                        {/*    <span className={classes.link} onClick={sendEmail}>*/}
                        {/*        Email küldése*/}
                        {/*    </span>*/}
                        {/*</p>*/}
                    </div>
                </div>
            </Modal>
        </>
    );
};
const mapStateToProps = (state) => ({
    phone: state.common.phone,
});

const mapDispatchToProps = (dispatch) => ({
    setUserPhone: (phone) => dispatch(actions.setUserPhone(phone)),
    toggleVerificationModal: (show) =>
        dispatch(actions.toggleVerificationModal(show)),
    setSubscription: (subscription) =>
        dispatch(actions.setSubscription(subscription)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PhoneVerificationModal);
