import { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Container, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Breadcrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import { endLoading, get } from "../../../config/fetch";
import {
    ALERT_MESSAGES,
    APPOINTMENT_FLAGS,
    EVENT_FLAGS,
    SYSTEM_FLAGS,
} from "../../../config/variables";
import { responseMessage } from "../../../utility";
// import useTranslation from "../../../features/i18n/useTranslation";
// refacted layout UI elements to display Paperish design
import InnerLayout from "../templates/InnerLayout";
import ScrollContainer from "../atoms/ScrollContainer";
import Paper from "../atoms/Paper";
import PaperSection from "../atoms/PaperSection";
import Headline from "../atoms/Headline";
import EventRowRenderer from "../organisms/EventRowRenderer";
import FilterSelect from "../molecules/FilterSelect";
import classes from "./Events.module.css";
import "../../../components/Facelift/typo.css";

const items = [
    {
        label: "Összes értesítés",
        value: ["ALERT", ...EVENT_FLAGS, ...APPOINTMENT_FLAGS, ...SYSTEM_FLAGS],
    },
    { label: "Beszélgetések", value: ["CHAT"] },
    { label: "Másodvélemény", value: ["SECOND_OPINION"] },
    { label: "Időpont visszaigazolás", value: [...APPOINTMENT_FLAGS] },
    { label: "E-recept", value: ["PRESCRIPTION"] },
    { label: "Riasztások", value: ["ALERT"] },
    // { label: "Rendszer", value: [...SYSTEM_FLAGS] },
];

// Component
const Events = () => {
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const mergedEvents = [...events, ...notifications]
            .filter((event) => {
                if ([...SYSTEM_FLAGS].includes(event.event_name)) {
                    return false;
                }

                return true;
            })
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setFilteredEvents(mergedEvents);
    }, [events, notifications]);

    const getEvents = () =>
        get("api/v1/event/get-user-events")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setEvents(response.data.events);

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const getNotifications = () =>
        get("api/v1/event/get-user-notifications")
            .then((response) => {
                let notis;
                switch (response.data.responseCode) {
                    case "OK":
                        notis = response.data.notifications.map(
                            (notification) => ({
                                code: null,
                                created_at: notification.created_at,
                                event_generated_id: null,
                                event_id: null,
                                event_name: "NOTIFICATION",
                                id: notification.id,
                                in_subscription: null,
                                info: null,
                                other_user_id: null,
                                payload: notification.data,
                                price: undefined,
                                text: null,
                                transaction_id: undefined,
                                user_id: null,
                            })
                        );
                        setNotifications(notis);

                        break;
                    default:
                        responseMessage(
                            `default response: ${ALERT_MESSAGES.errorTryAgain}`
                        );

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(`catch: ${ALERT_MESSAGES.errorTryAgain}`);
            });

    useEffect(() => {
        getEvents();
        getNotifications();
    }, []);

    /* const markAsRead = (e) => {
        e.preventDefault();
    }; */

    const handleFilterSelect = useCallback(
        (item) => {
            const filtered = [...events, ...notifications].filter(
                ({ event_name, payload }) => {
                    if (!event_name) {
                        return false;
                    }

                    if (payload) {
                        const payloadObj = JSON.parse(payload);

                        if (
                            item.value.includes("ALERT") &&
                            payloadObj?.type === "nextHealth"
                        ) {
                            return true;
                        }
                    }

                    return item.value.includes(event_name);
                }
            );

            setFilteredEvents(filtered);
        },
        [events, notifications, setFilteredEvents]
    );

    const resetFilteredEvents = () =>
        setFilteredEvents([...events, ...notifications]);

    const sortedEvents = (events) =>
        events.sort((a, b) => {
            const aParam =
                a.event_name === "VIDEO_CONSULTATION"
                    ? a?.reservationData?.reservationTime
                    : a?.created_at;
            const bParam =
                b.event_name === "VIDEO_CONSULTATION"
                    ? b?.reservationData?.reservationTime
                    : b?.created_at;
            if (aParam < bParam) {
                return 1;
            }
            if (aParam > bParam) {
                return -1;
            }

            return 0;
        });

    return (
        <div className={classes.intro}>
            <Container>
                <InnerLayout>
                    <Paper>
                        <Headline>
                            <Breadcrumbs />
                        </Headline>
                        <Headline>
                            <Grid container spacing={5}>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className={classes.toolbarFirst}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <FilterSelect
                                        items={items}
                                        onSelect={handleFilterSelect}
                                    />
                                </Grid>
                                {/* <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className={classes.toolbarLast}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <Link
                                        href="#"
                                        onClick={markAsRead}
                                        className={classes.markAsReadLink}
                                    >
                                        <span>Összeset olvasottnak jelöl</span>
                                        <DraftsOutlinedIcon
                                            fontSize="large"
                                            classes={{ root: classes.icon }}
                                        />
                                    </Link>
                                </Grid> */}
                            </Grid>
                        </Headline>

                        <ScrollContainer>
                            <PaperSection className={classes.noPadding}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {filteredEvents?.length > 0 ? (
                                            sortedEvents(filteredEvents).map(
                                                (event) => (
                                                    <Fragment
                                                        key={`event-row-renderer-${event.id}`}
                                                    >
                                                        <EventRowRenderer
                                                            event={event}
                                                        />
                                                    </Fragment>
                                                )
                                            )
                                        ) : (
                                            <div
                                                style={{
                                                    padding:
                                                        "2rem 1.5rem 2rem 2rem",
                                                }}
                                            >
                                                <Alert
                                                    classes={{
                                                        root: classes.alert,
                                                        message:
                                                            classes.alertMessage,
                                                    }}
                                                    severity="info"
                                                    action={
                                                        <Button
                                                            color="inherit"
                                                            size="large"
                                                            onClick={
                                                                resetFilteredEvents
                                                            }
                                                        >
                                                            Visszaállítás
                                                        </Button>
                                                    }
                                                >
                                                    Nem találtunk a szűrésnek
                                                    megfelelő értesítést.
                                                </Alert>
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </PaperSection>
                        </ScrollContainer>
                    </Paper>
                </InnerLayout>
            </Container>
        </div>
    );
};

export default Events;
