import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Icons } from "../../../assets/Theme";
import CustomSelect from "../../../components/UI/CustomSelect/CustomSelect";
import { removeAccents } from "../../../utility";
import classes from "./InstitutionFilter.module.css";

let allQualifications = [];
let allCities = [];

const abcFilter = [
    { id: 0, name: <span style={{ opacity: 0.6 }}>Válasszon</span> },
    { id: [97, 101], name: "A-E" },
    { id: [102, 106], name: "F-J" },
    { id: [107, 111], name: "K-O" },
    { id: [112, 116], name: "P-T" },
    { id: [117, 122], name: "U-Z" },
];

let filterValues = {
    abc: null,
    qualification: null,
    city: null,
};

const InstitutionFilter = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    const [abc, setAbc] = useState(0);
    const [qualification, setQualification] = useState(0);
    const [city, setCity] = useState(0);

    const [filterText, setFilterText] = useState("");

    const institutionsData = props.institutionsData;

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        institutionsData.map((institution) => {
            allQualifications = [
                ...allQualifications,
                ...institution.qualifications,
            ];
            allQualifications.sort((a, b) => (a.name > b.name ? 1 : -1));
            allCities = [...allCities, institution.city];
            allCities.sort();
        });

        allQualifications = filterDuplicates(allQualifications);
        allCities = filterCities(allCities);
        allCities = allCities.map((city) => ({ id: city, name: city }));

        allQualifications = [
            { id: 0, name: <span style={{ opacity: 0.6 }}>Válasszon</span> },
            ...allQualifications,
        ];
        allCities = [
            { id: 0, name: <span style={{ opacity: 0.6 }}>Válasszon</span> },
            ...allCities,
        ];

        return () => {
            allQualifications = [];
            allCities = [];
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterDuplicates = (arr) => {
        let ids = arr.map((o) => o.id);

        return arr.filter(({ id }, index) => !ids.includes(id, index + 1));
    };

    const filterCities = (cities) => {
        let filtered = [];
        cities.forEach((city) => {
            if (!filtered.includes(city)) {
                filtered.push(city);
            }
        });

        return filtered;
    };

    const toggleFilters = () => {
        setIsOpen((prevState) => !prevState);
        resetFilters();
    };

    const generateFilterText = () => {
        let filterTextArr = [];
        for (const f in filterValues) {
            if (filterValues[f]) {
                filterTextArr = [...filterTextArr, filterValues[f]];
            }
        }
        if (filterTextArr.length > 0) {
            setFilterText(filterTextArr.join(", "));
        } else {
            setFilterText("");
        }
    };

    const resetFilters = () => {
        setAbc(0);
        setQualification(0);
        setCity(0);

        setFilterText("");

        filterValues = {
            abc: null,
            qualification: null,
            city: null,
        };

        props.onResetFilters();
    };

    const handleFilterSelect = (value, filter) => {
        props.onFilterChange(value, filter);
        let name;
        switch (filter) {
            case "abc":
                setAbc(value);
                if (value !== 0) {
                    name = abcFilter.find((el) => el.id == value).name;
                    filterValues[filter] = name;
                } else {
                    filterValues[filter] = null;
                }

                break;
            case "qualification":
                setQualification(value);
                if (value !== 0) {
                    name = allQualifications.find((el) => el.id == value).name;
                    filterValues[filter] = name;
                } else {
                    filterValues[filter] = null;
                }

                break;
            case "city":
                setCity(value);
                if (value !== 0) {
                    name = allCities.find((el) => el.id == value).name;
                    filterValues[filter] = name;
                } else {
                    filterValues[filter] = null;
                }

                break;
            default:
                break;
        }
        generateFilterText();
    };

    const formatText = (text) =>
        removeAccents(text).toLowerCase().trim().replace(/\s+/g, "");

    const handleSearchTextChange = (e) => {
        if (isOpen) {
            setIsOpen(false);
        }
        const text = e.target.value;
        const formattedText = formatText(text);
        setSearchText(text);
        props.onSearchTextChange(formattedText);
    };

    return (
        <div style={{ marginBottom: "2rem" }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item md={4} xs={12}>
                    <div className={classes.inputContainer}>
                        <input
                            type="text"
                            className={classes.input}
                            value={searchText}
                            placeholder="Szakterület vagy intézmény keresése"
                            onChange={(e) => handleSearchTextChange(e)}
                        />
                        <img
                            src={Icons.searchGreen}
                            className={classes.searchIcon}
                            alt=""
                        />
                    </div>
                </Grid>
                <Grid item>
                    <div
                        className={classes.filterButtonContainer}
                        onClick={toggleFilters}
                    >
                        <span className={classes.openFilter}>SZŰRŐK</span>
                        {!isOpen ? (
                            <ExpandMoreIcon className={classes.expandIcon} />
                        ) : (
                            <ExpandLessIcon className={classes.expandIcon} />
                        )}
                    </div>
                </Grid>
            </Grid>
            {isOpen && (
                <div>
                    <Grid
                        className={classes.mainContainer}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.filterSelect}
                        >
                            <CustomSelect
                                controlStyle={styles.select}
                                options={abcFilter}
                                value={abc}
                                optionKey="name"
                                optionValue="id"
                                onChange={(val) =>
                                    handleFilterSelect(val, "abc")
                                }
                                label="ABC szerint"
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.filterSelect}
                        >
                            <CustomSelect
                                controlStyle={styles.select}
                                options={allQualifications}
                                value={qualification}
                                optionKey="name"
                                optionValue="id"
                                onChange={(val) =>
                                    handleFilterSelect(val, "qualification")
                                }
                                label="Szakterület szerint"
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.filterSelect}
                        >
                            <CustomSelect
                                controlStyle={styles.select}
                                options={allCities}
                                value={city}
                                optionKey="name"
                                optionValue="id"
                                onChange={(val) =>
                                    handleFilterSelect(val, "city")
                                }
                                label="Város szerint"
                            />
                        </Grid>
                    </Grid>
                    {filterText && (
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <span className={classes.filterText}>
                                    Találatok a következőre:{" "}
                                </span>
                                <span className={classes.filterText}>
                                    <strong>{filterText}</strong>
                                </span>
                            </Grid>
                            <Grid item>
                                <p
                                    className={classes.resetFilters}
                                    onClick={resetFilters}
                                >
                                    Szürők alaphelyzetbe
                                </p>
                            </Grid>
                        </Grid>
                    )}
                </div>
            )}
        </div>
    );
};

const styles = {
    select: {
        width: "100%",
    },
};

export default InstitutionFilter;
