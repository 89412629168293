interface Env {
    readonly [key: string]: string;
}

declare global {
    interface Window {
        readonly env: Env;
    }
}

const getEnv = (envName: string) => window.env[envName];

export default getEnv;
