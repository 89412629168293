import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Colors } from "../../../../assets/Theme";
import CheckBoxIcon from "./CheckBoxIcon.svg";

interface Props {
    label: string | JSX.Element;
    checked?: boolean;
    disabled?: boolean;
}

const GreenCheckbox = withStyles({
    root: {
        "&:hover": {
            background: "none",
            backgroundColor: "transparent !important",
        },
    },
})(Checkbox);

const CheckBox = ({ label, checked }: Props) => (
    <>
        <FormControlLabel
            control={
                <GreenCheckbox
                    disableRipple
                    checked={checked}
                    icon={
                        <Box
                            border={`3px solid ${Colors.green}`}
                            height="40px"
                            width="40px"
                            borderRadius="3px"
                        ></Box>
                    }
                    checkedIcon={
                        <Box
                            bgcolor={Colors.green}
                            height="40px"
                            width="40px"
                            borderRadius="3px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img
                                src={CheckBoxIcon}
                                height="25px"
                                alt="Elfogadás"
                            />
                        </Box>
                    }
                />
            }
            label={label}
        />
    </>
);

export default CheckBox;
