import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import { formatNumber, responseMessage } from "../../../utility";
import { endLoading, get, post, startLoading } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import useQuery from "../../../features/util/useQuery";
import PageSubHeader from "../atoms/PageSubHeader";
import Panel from "../atoms/Panel";
import Tooltip from "../atoms/Tooltip";
import CreditCardInfo from "./CreditCardInfo";
import Btn from "../../UI/Btn/Btn";
import Input from "../molecules/Form/Input";
import Checkbox from "../molecules/Form/Checkbox";
import { Images } from "../../../assets/Theme";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./PaymentSettings.module.css";

const billingAddress = (
    country,
    zip,
    settlement,
    street,
    houseNumber,
    floorDoor
) => {
    if (!country || !zip || !settlement || !street || !houseNumber) {
        return null;
    }

    return (
        country +
        " " +
        zip +
        ", " +
        settlement +
        " " +
        street +
        " " +
        houseNumber +
        " " +
        (floorDoor ? floorDoor : "")
    );
};

const succesFullResponseText = (transactionId, addedSubscription) => (
    <>
        <p className={classes.responseText}>Sikeres tranzakció</p>
        <p className={classes.responseText}>
            SimplePay tranzakció azonosító: {transactionId}
        </p>
        {addedSubscription && (
            <p className={classes.responseText}>
                A kiválasztott előfizetésed aktiváltuk!
            </p>
        )}
    </>
);

const unSuccesfullResponseText = (transactionId) => (
    <>
        <p className={classes.responseText}>Sikertelen tranzakció</p>
        <p className={classes.responseText}>
            SimplePay tranzakció azonosító: {transactionId}
        </p>
        <p className={classes.responseText}>
            Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét.
            Amennyiben minden adatot helyesen adott meg, a visszautasítás okának
            kivizsgálása érdekében kérjük, szíveskedjen kapcsolatba lépni
            kártyakibocsátó bankjával.
        </p>
    </>
);

const PaymentSettings = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const { i18nRoute } = useTranslation();
    const [cards, setCards] = useState(null);
    const [addingCard, setAddingCard] = useState(false);
    const [newCardName, setNewCardName] = useState("");
    const [termsAccepted, toggleTerms] = useState(false);
    const [terms2Accepted, toggleTerms2] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [userSubscription, setUserSubscription] = useState(null);
    const [userDatas, setUserDatas] = useState({
        billing: "",
        billingName: "",
        billingCountry: "",
        billingZip: "",
        billingSettlement: "",
        billingStreet: "",
        billingHouseNumber: "",
        billingFloorDoor: "",
        billingTaxNumber: "",
        currentPackage: false,
        currentPackageId: 1,
        nextMonthPackage: false,
        canUseTrial: false,
    });

    const getProfileSummary = useCallback(() => {
        get("user/get-profile-summary").then((response) => {
            if (response.data.responseCode === "OK") {
                setSubscription(response.data.subscription);
                setUserSubscription(response.data.userSubscription);
                let b = response.data.billing[0] || {};
                b.country = b.country || "Magyarország";
                let billing = billingAddress(
                    b.country,
                    b.zip,
                    b.settlement,
                    b.street,
                    b.house_number,
                    b.floor_door
                );
                const billingData = {
                    billing,
                    billingName: b.name,
                    billingCountry: b.country,
                    billingZip: b.zip,
                    billingSettlement: b.settlement,
                    billingStreet: b.street,
                    billingHouseNumber: b.house_number,
                    billingFloorDoor: b.floor_door,
                    billingTaxNumber: b.tax_number,
                    currentPackage: response.data.subscription.name,
                    currentPackageId: +response.data.subscription.id,
                    nextMonthPackage:
                        response.data.subscription.nextMonthPackage,
                    canUseTrial: response.data.userSubscription.canUseTrial,
                };
                setUserDatas(billingData);
                // let originalBillingData = billingData;
                let cards = [];
                for (let c of response.data.cards) {
                    if (!c.name) c.name = "Bankártya";
                    c["defaultCard"] =
                        response.data.user.defaultCard == c.id ? 1 : 0;
                    cards.push(c);
                }
                cards.sort((a, b) => b.defaultCard - a.defaultCard);
                setCards(cards);
            }
            endLoading();
        });
    }, []);

    const paymentCallback = useCallback(() => {
        if (query.has("TransactionId")) {
            const transactionId = query.get("TransactionId");
            const immediateToken = query.get("immediateToken");
            post("api/v1/payment/payment-init-callback", {
                transactionId,
                cardName: localStorage.getItem("newCardName") || null,
                immediateToken,
                oneClick: "true",
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage(
                                succesFullResponseText(
                                    response.data.ProviderTransactionId,
                                    immediateToken
                                )
                            );
                            endLoading();

                            navigate({ pathname: "/empty" });
                            navigate(
                                {
                                    pathname: i18nRoute("routes.profile.base"),
                                },
                                { replace: true }
                            );

                            break;
                        case "PAYMENT_ERROR":
                            switch (response.data.errorCode) {
                                case "ERROR":
                                    responseMessage(
                                        unSuccesfullResponseText(
                                            response.data.ProviderTransactionId
                                        )
                                    );
                                    endLoading();

                                    break;
                                case "CANCELED":
                                    responseMessage(
                                        "Megszakítottad a fizetési folyamatot. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "TIMEOUT":
                                    responseMessage(
                                        "Túllépted a tranzakció elindításakor megengedett biztonságos időkaput. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "PENDING":
                                    startLoading();
                                    setTimeout(paymentCallback, 3000);

                                    break;
                            }

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                            endLoading();
                    }
                    localStorage.removeItem("newCardName");
                    navigate("/profile/payment-methods", { replace: true });
                })
                .catch((error) => {
                    console.error(error);
                    navigate("/profile/payment-methods", { replace: true });
                    endLoading();
                });
        }
    }, [i18nRoute, navigate, query]);

    useEffect(() => {
        if (query.has("TransactionId")) {
            const promise1 = paymentCallback();
            const promise2 = getProfileSummary();
            Promise.all([promise1, promise2]);
        } else {
            getProfileSummary();
        }
    }, [query, getProfileSummary, paymentCallback]);

    const initPayment = () => {
        localStorage.setItem(
            "newCardName",
            (newCardName && newCardName.trim()) || "Bankkártya"
        );

        post("api/v1/payment/add-card", {
            data: {
                cardName: newCardName,
                from: window.location.href,
            },
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        window.location.href = response.data.url;

                        break;

                    case "MISSING_DATA":
                        responseMessage(
                            "Hiányzó számlázási adatok! Kérjük, először számlázási adataid add meg.",
                            null
                            /* () =>
                                navigate(
                                    i18nRoute("routes.profile.paymentMethods")
                                ) */
                        );

                        break;

                    case "MISSING_PERSONAL_DATA":
                        responseMessage(
                            "Hiányzó személyes adatok! Kérjük, először személyes adataid add meg.",
                            null
                            /* () => navigate(i18nRoute("routes.profile.user")) */
                        );

                        break;

                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                endLoading();
                console.error(error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleInputChange = ({ target }) =>
        setUserDatas((prevState) => ({
            ...prevState,
            [target.name]: target.value,
        }));

    const handleOnCardDelete = (token) => {
        const modifiedCards = cards.filter((c) => c.tdoc_token !== token);
        setCards(modifiedCards);
    };

    const handleSetAsDefault = (token) => {
        const modifiedCards = cards.map((c) => {
            if (c.tdoc_token === token) {
                return { ...c, defaultCard: 1 };
            }

            return { ...c, defaultCard: 0 };
        });
        setCards(modifiedCards);
    };

    const handleAddNewCardClick = () => {
        if (!termsAccepted || !terms2Accepted) {
            responseMessage(
                "Az adattovábbítási- és az ismétlődő fizetésről történő nyilatkozat elfogadása kötelező!"
            );
        } else {
            initPayment();
        }
    };

    const handleBillingInfoSave = () => {
        let billing = billingAddress(
            userDatas.billingCountry,
            userDatas.billingZip,
            userDatas.billingSettlement,
            userDatas.billingStreet,
            userDatas.billingHouseNumber,
            userDatas.billingFloorDoor
        );
        setUserDatas({ ...userDatas, billing });
        post("payment/update-user-billing-data", {
            data: {
                name: userDatas.billingName,
                country: userDatas.billingCountry,
                zip: userDatas.billingZip,
                settlement: userDatas.billingSettlement,
                street: userDatas.billingStreet,
                house_number: userDatas.billingHouseNumber,
                floor_door: userDatas.billingFloorDoor,
                tax_number: userDatas.billingTaxNumber,
            },
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");

                        break;

                    case "EMPTY_BILLING_NAME":
                        responseMessage("Hiányzó számlázási név!");

                        break;

                    case "EMPTY_BILLING_COUNTRY":
                        responseMessage("Hiányzó számlázási ország!");

                        break;

                    case "EMPTY_BILLING_ZIP":
                        responseMessage("Hiányzó számlázási irányítószám!");

                        break;

                    case "EMPTY_BILLING_SETTLEMENT":
                        responseMessage("Hiányzó számlázási település!");

                        break;

                    case "EMPTY_BILLING_STREET":
                        responseMessage("Hiányzó számlázási utca!");

                        break;

                    case "EMPTY_BILLING_HOUSE_NUMBER":
                        responseMessage("Hiányzó számlázási házszám!");

                        break;

                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    return (
        <>
            {/* FIZETÉSI BEÁLLÍTÁSOK */}
            <div className={classes.paymentSettings}>
                <div className={classes.creditCards}>
                    <PageSubHeader className={classes.PageSubHeader}>
                        Elmentett bankkártyák
                    </PageSubHeader>
                    {cards && cards.length > 0 ? (
                        cards.map((card, index) => (
                            <div key={`stored-credit-card-row-${index}`}>
                                <CreditCardInfo
                                    onCardDelete={handleOnCardDelete}
                                    onSetAsDefault={handleSetAsDefault}
                                    defaultCard={card.defaultCard}
                                    token={card.tdoc_token}
                                    label={`${index + 1}. kártya`}
                                    card={`${card.card_number || "**** ****"} ${
                                        card.name
                                    }`}
                                />
                            </div>
                        ))
                    ) : (
                        <Alert
                            severity="info"
                            classes={{
                                root: classes.alert,
                                message: classes.alertMessage,
                            }}
                        >
                            A rendszerben jelenleg nincs elmentett bankártya
                        </Alert>
                    )}

                    <Link
                        component="button"
                        className={classes.link}
                        style={{ marginBottom: "2rem" }}
                        onClick={() => setAddingCard((prevState) => !prevState)}
                    >
                        {addingCard ? "Mégse" : "Új kártya hozzáadása"}
                    </Link>

                    {addingCard && (
                        <Panel className={classes.addCardElement}>
                            <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "0.8rem" }}
                            >
                                <Grid item xs={12}>
                                    <label className={classes.formLabel}>
                                        Kártya elnevezése a könnyebb azonosítás
                                        miatt
                                    </label>
                                    <Input
                                        value={newCardName}
                                        placeholder="pl.: Apa kártyája"
                                        onChange={(e) =>
                                            setNewCardName(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Panel style={{ marginBottom: 0 }}>
                                        <p className={classes.terms}>
                                            Elfogadom, hogy a T-DOC Service Kft.
                                            (1138 Budapest, Népfürdő utca 22. B.
                                            ép. 15. em.) által a www.t-doc.hu (a
                                            továbbiakban kereskedő) felhasználói
                                            adatbázisában tárolt alábbi
                                            személyes adataim átadásra
                                            kerüljenek a BIG FISH Payment
                                            Services Kft. (székhely: 1066
                                            Budapest, Nyugati tér 1-2.), mint
                                            adatfeldolgozó részére. A
                                            továbbított adatok köre: név-előtag,
                                            vezetéknév, keresztnév, IP-cím,
                                            számlázási cím, szállítási cím,
                                            telefonszám, e-mail cím,
                                            bankkártyaszám első hat és utolsó
                                            négy számjegye, bankkártya márkája.
                                            Az adattovábbítás célja: a fizetési
                                            tranzakciókhoz szükséges
                                            adatkommunikáció lebonyolítása a
                                            kereskedő és a fizetési szolgáltató
                                            rendszere között, illetve az adatok
                                            tárolása a tranzakciós logokban a
                                            tranzakciós adatok kereskedő részére
                                            történő visszakereshetőségének
                                            biztosítása céljából. Az
                                            adatfeldolgozó által végzett
                                            adatfeldolgozási tevékenység jellege
                                            és célja a SimplePay Adatkezelési
                                            tájékoztatóban, az alábbi linken
                                            tekinthető meg:{" "}
                                            <a
                                                href="http://simplepay.hu/vasarlo-aff"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={classes.termsLink}
                                            >
                                                http://simplepay.hu/vasarlo-aff
                                            </a>
                                        </p>
                                        <Checkbox
                                            name="termsAccepted"
                                            value={termsAccepted}
                                            label="Elfogadom a feltételeket"
                                            onChange={(checked) =>
                                                toggleTerms(checked)
                                            }
                                        />
                                    </Panel>
                                </Grid>
                                <Grid item xs={12}>
                                    <Panel style={{ marginBottom: 0 }}>
                                        <p className={classes.terms}>
                                            Az ismétlődő bankkártyás fizetés
                                            (továbbiakban: „Ismétlődő fizetés”)
                                            egy, a SimplePay által biztosított
                                            bankkártya elfogadáshoz tartozó
                                            funkció, mely azt jelenti, hogy a
                                            Vásárló által a regisztrációs
                                            tranzakció során megadott
                                            bankkártyaadatokkal a jövőben újabb
                                            fizetéseket lehet kezdeményezni a
                                            bankkártyaadatok újbóli megadása
                                            nélkül.
                                        </p>
                                        <p className={classes.terms}>
                                            Az Ismétlődő fizetés
                                            igénybevételéhez jelen nyilatkozat
                                            elfogadásával Ön hozzájárul, hogy a
                                            sikeres regisztrációs tranzakciót
                                            követően jelen webshopban
                                            (https://t-doc.hu) kezdeményezett
                                            későbbi fizetések a bankkártyaadatok
                                            újbóli megadása és az Ön
                                            tranzakciónként hozzájárulása nélkül
                                            a Kereskedő által kezdeményezve
                                            történjenek.
                                        </p>
                                        <p className={classes.terms}>
                                            Figyelem(!): a bankkártyaadatok
                                            kezelése a kártyatársasági
                                            szabályoknak megfelelően történik. A
                                            bankkártyaadatokhoz sem a Kereskedő,
                                            sem a SimplePay nem fér hozzá.
                                        </p>
                                        <p className={classes.terms}>
                                            A Kereskedő által tévesen vagy
                                            jogtalanul kezdeményezett ismétlődő
                                            fizetéses tranzakciókért közvetlenül
                                            a Kereskedő felel, Kereskedő
                                            fizetési szolgáltatójával
                                            (SimplePay) szemben bármilyen
                                            igényérvényesítés kizárt.
                                        </p>
                                        <p className={classes.terms}>
                                            Jelen tájékoztatót átolvastam, annak
                                            tartalmát tudomásul veszem és
                                            elfogadom.
                                        </p>
                                        <Checkbox
                                            name="terms2Accepted"
                                            value={terms2Accepted}
                                            onChange={(checked) =>
                                                toggleTerms2(checked)
                                            }
                                            label="Elfogadom a feltételeket"
                                        />
                                    </Panel>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert
                                        severity="info"
                                        style={{ marginBottom: 0 }}
                                        classes={{
                                            root: classes.alert,
                                            message: classes.alertMessage,
                                        }}
                                    >
                                        Kártyája hozzáadásához 1 Ft-ot terhelünk
                                        egyenlegére, melyet rögtön vissza is
                                        térítünk.
                                    </Alert>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Btn
                                        className={classes.button}
                                        onClick={handleAddNewCardClick}
                                    >
                                        Hozzáadás
                                    </Btn>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className={classes.paymentGateways}>
                                        <img
                                            className={classes.paymentImage}
                                            src={Images.simple}
                                            alt=""
                                        />
                                        <img
                                            className={classes.paymentImage}
                                            src={Images.bigfish}
                                            alt=""
                                        />
                                    </div>
                                    <p className={classes.paymentInfo}>
                                        Az online fizetést az OTP Simple Pay és
                                        a Big Fish Payment Gateway biztosítja.
                                    </p>
                                </Grid>
                            </Grid>
                        </Panel>
                    )}
                </div>

                <div className={classes.subscriptions}>
                    <PageSubHeader className={classes.PageSubHeader}>
                        Előfizetési információk
                    </PageSubHeader>
                    <Panel size="lg">
                        <p>
                            Előfizetés típusa:{" "}
                            {subscription
                                ? subscription.name !== "Előfizetés nélkül"
                                    ? `${subscription.name} előfizetési csomag`
                                    : "Előfizetés nélkül"
                                : "-"}
                        </p>
                        <p>
                            Előfizetés díja:{" "}
                            {subscription
                                ? `${formatNumber(
                                      subscription.package_price
                                  )} Ft`
                                : "-"}
                        </p>
                        <p>
                            Következő fizetés ütemezve:{" "}
                            {userSubscription
                                ? userSubscription.payment_next_date
                                    ? userSubscription.payment_next_date.replace(
                                          /-/g,
                                          "."
                                      )
                                    : "-"
                                : "-"}
                            .
                        </p>
                        <p>
                            Bankkártya beterhelése:{" "}
                            {userSubscription
                                ? userSubscription.next_charge
                                    ? userSubscription.next_charge.replace(
                                          /-/g,
                                          "."
                                      )
                                    : "-"
                                : "-"}
                        </p>
                    </Panel>
                    <Link href="/elofizetesek" className={classes.link}>
                        Előfizetés kezelése
                    </Link>
                </div>
            </div>

            {/* SZÁMLÁZÁSI ADATOK */}
            <div className={classes.billingInfo}>
                <PageSubHeader className={classes.PageSubHeader}>
                    Számlázási adatok
                </PageSubHeader>
                <Grid container spacing={2} style={{ marginBotton: 16 }}>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} md={4}>
                            <div className={classes.formControl}>
                                <label className={classes.formLabel}>
                                    Számlázási név
                                </label>
                                <Input
                                    required
                                    name="billingName"
                                    value={userDatas.billingName}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label className={classes.formLabel}>
                                <span>Ország</span>
                            </label>
                            <Input
                                required
                                name="billingCountry"
                                value={userDatas.billingCountry}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className={classes.formControl}>
                                <label className={classes.formLabel}>
                                    <span>Adószám (nem kötelező)</span>
                                    <Tooltip title="Kérjük akkor töltsd ki az Adószám mezőt, ha céges számlát szeretnél igényelni.">
                                        <InfoOutlinedIcon
                                            style={{ fontSize: "1.9rem" }}
                                        />
                                    </Tooltip>
                                </label>
                                <Input
                                    required
                                    name="billingTaxNumber"
                                    value={userDatas.billingTaxNumber}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} md={2}>
                            <div className={classes.formControl}>
                                <label className={classes.formLabel}>
                                    Irányítószám
                                </label>
                                <Input
                                    required
                                    name="billingZip"
                                    value={userDatas.billingZip}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className={classes.formControl}>
                                <label className={classes.formLabel}>
                                    Település
                                </label>
                                <Input
                                    required
                                    name="billingSettlement"
                                    value={userDatas.billingSettlement}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className={classes.formControl}>
                                <label className={classes.formLabel}>
                                    Utca
                                </label>
                                <Input
                                    required
                                    name="billingStreet"
                                    value={userDatas.billingStreet}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div className={classes.formControl}>
                                <label className={classes.formLabel}>
                                    Házszám
                                </label>
                                <Input
                                    required
                                    name="billingHouseNumber"
                                    value={userDatas.billingHouseNumber}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div className={classes.formControl}>
                                <label className={classes.formLabel}>
                                    Emelet / Ajtó
                                </label>
                                <Input
                                    name="billingFloorDoor"
                                    value={userDatas.billingFloorDoor}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <Btn
                                className={classes.button}
                                onClick={handleBillingInfoSave}
                            >
                                Adatok mentése
                            </Btn>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default PaymentSettings;
