import { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Container, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Breadcrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import { endLoading, get } from "../../../config/fetch";
import { ALERT_MESSAGES, APPOINTMENT_FLAGS } from "../../../config/variables";
import { responseMessage } from "../../../utility";
import classes from "./Events.module.css";
import InnerLayout from "../templates/InnerLayout";
import ScrollContainer from "../atoms/ScrollContainer";
import Paper from "../atoms/Paper";
import PaperSection from "../atoms/PaperSection";
import Headline from "../atoms/Headline";
import FilterSelect from "../molecules/FilterSelect";
import AppointmentCard from "../organisms/AppointmentCard";
import "../../../components/Facelift/typo.css";

const items = [
    {
        label: "Összes időpont",
        value: [...APPOINTMENT_FLAGS],
    },
    { label: "Személyes vizit", value: ["OUTPATIENT"] },
    { label: "Videókonzultáció", value: ["VIDEO_CONSULTATION"] },
];

// Component
const Appointments = () => {
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [appointments, setAppointments] = useState([]);

    const getAppointments = () =>
        get("api/v1/event/get-user-events")
            .then((response) => {
                let bookedAppointments;
                switch (response.data.responseCode) {
                    case "OK":
                        bookedAppointments = response.data.events.filter(
                            (event) =>
                                APPOINTMENT_FLAGS.includes(event.event_name)
                        );

                        setAppointments(bookedAppointments);
                        setFilteredAppointments(bookedAppointments);

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    useEffect(() => {
        getAppointments();
    }, []);

    const handleFilterSelect = useCallback(
        (item) => {
            const filtered = appointments.filter(({ event_name }) => {
                if (!event_name) {
                    return false;
                }

                return item.value.includes(event_name);
            });

            setFilteredAppointments(filtered);
        },
        [appointments, setFilteredAppointments]
    );

    const resetFilteredAppointments = () =>
        setFilteredAppointments(appointments);

    return (
        <div className={classes.intro}>
            <Container>
                <InnerLayout>
                    <Paper>
                        <Headline>
                            <Breadcrumbs />
                        </Headline>
                        <Headline>
                            <Grid container spacing={5}>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className={classes.toolbarFirst}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <FilterSelect
                                        items={items}
                                        onSelect={handleFilterSelect}
                                    />
                                </Grid>
                            </Grid>
                        </Headline>

                        <ScrollContainer>
                            <PaperSection className={classes.noPadding}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {filteredAppointments?.length > 0 ? (
                                            filteredAppointments.map(
                                                (event) => (
                                                    <Fragment
                                                        key={`event-row-appointment-${event.id}`}
                                                    >
                                                        <AppointmentCard
                                                            {...event}
                                                        />
                                                    </Fragment>
                                                )
                                            )
                                        ) : (
                                            <div
                                                style={{
                                                    padding:
                                                        "2rem 1.5rem 2rem 2rem",
                                                }}
                                            >
                                                <Alert
                                                    classes={{
                                                        root: classes.alert,
                                                        message:
                                                            classes.alertMessage,
                                                    }}
                                                    severity="info"
                                                    action={
                                                        <Button
                                                            color="inherit"
                                                            size="large"
                                                            onClick={
                                                                resetFilteredAppointments
                                                            }
                                                        >
                                                            Visszaállítás
                                                        </Button>
                                                    }
                                                >
                                                    Nem találtunk a szűrésnek
                                                    megfelelő időpontfoglalást.
                                                </Alert>
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </PaperSection>
                        </ScrollContainer>
                    </Paper>
                </InnerLayout>
            </Container>
        </div>
    );
};

export default Appointments;
