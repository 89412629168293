import { Box, Container, Hidden } from "@material-ui/core";
import { Link } from "react-scroll";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import classes from "../styles.module.css";
import { useResolution } from "../../../ui/utils";
import useWindowDimensions from "../../../ui/hooks/useWindowDimensions";
import BtnWithIcon from "../../../components/UI/Btn/BtnWithIcon";
import ButtonContinue from "../components/ButtonContinue";
import HeroBackgroundImage from "../assets/HeroBackgroundImage.jpg";
import { heroElements } from "../content/heroSection";
import { Colors } from "../../../assets/Theme";

interface Props {
    writeMessage: () => void;
}

const HeroSection = ({ writeMessage }: Props) => {
    const isTablet = useResolution("tablet");
    const isMobile = useResolution("mobile");

    const { height, width } = useWindowDimensions();

    const heroHeightDesktop = height - 137;
    const heroHeightMobile = height - 159; // levonjuk a felső navbart, ami mindig fix
    const imageHeight = width / 1.95; // a kép mobil/tablet nézeten mindig 100% szélességű, a szélesség 1.95x-osa a magasságnak, ebből számoljuk a magasságot
    const heroDescriptionHeightMobile = heroHeightMobile - imageHeight;

    const isWallpaperOwerflowed = !isTablet && width < 1085;

    const heroContent = (
        <>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                mt={isTablet && 2}
                style={{ height: "100%", width: "100%" }}
            >
                <Box
                    pt={isTablet && 2}
                    mt={isTablet && -width * 0.015}
                    px={isWallpaperOwerflowed && 2}
                    bgcolor={
                        isTablet
                            ? "white"
                            : isWallpaperOwerflowed
                            ? "rgba(255, 255, 255, 0.7)"
                            : undefined
                    }
                    borderRadius={isWallpaperOwerflowed && "1.2rem"}
                    maxWidth={isTablet ? "100%" : "63rem"}
                >
                    <Box
                        pb={2}
                        px={isMobile && 1}
                        maxWidth={"100%"}
                        textAlign={isTablet ? "center" : undefined}
                    >
                        <h2 style={{ fontSize: isTablet ? "26px" : "36px" }}>
                            Egészségügyi problémával küzdesz, de nincs kihez
                            fordulnod?
                        </h2>
                    </Box>
                    <Box
                        pb={2}
                        px={isMobile && 1}
                        bgcolor={isTablet && "white"}
                        maxWidth={isTablet ? "100%" : "60rem"}
                        textAlign={isTablet ? "center" : undefined}
                    >
                        <h4 style={{ fontSize: isTablet ? "26px" : "36px" }}>
                            Tedd fel első kérdésed szakorvos kollágánknak most
                            csak 3 490 Ft-ért!
                        </h4>
                    </Box>
                </Box>

                <Box
                    px={isMobile && 2}
                    bgcolor={isTablet && "white"}
                    display="flex"
                    justifyContent={isTablet ? "center" : "flex-end"}
                    width={isTablet ? undefined : "338px"}
                >
                    <BtnWithIcon
                        color="yellow"
                        text="Üzenetet írok"
                        className={classes.button}
                        onClick={writeMessage}
                    />
                </Box>

                <Hidden smDown>
                    <Box className={classes.heroBox}>
                        {heroElements.map((element, index) => (
                            <Box key={index} className={classes.heroBoxElement}>
                                <Box pb={2}>
                                    <img src={element.icon} width={80} alt="" />
                                </Box>
                                <Box pb={2}>
                                    <p
                                        style={{
                                            color: "rgba(38, 61, 80, 0.7)",
                                        }}
                                    >
                                        {element.text}
                                    </p>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box display="flex" width="100%" justifyContent="center">
                        <Link
                            to="section2"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            <ButtonContinue label="Tovább" />
                        </Link>
                    </Box>
                </Hidden>

                {isTablet && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        mt={0}
                        mb={!isMobile && 3}
                        pb={3}
                        bgcolor={isTablet && "white"}
                    >
                        <Link
                            to="section2"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            <KeyboardArrowDownIcon
                                style={{ fontSize: "4rem" }}
                            />
                        </Link>
                    </Box>
                )}
            </Box>
        </>
    );

    if (isTablet) {
        return (
            <section
                className={classes.hero}
                style={{
                    backgroundColor: "white",
                    height: heroHeightMobile,
                    color: Colors.darkBlue,
                }}
            >
                <Box>
                    <img
                        src={HeroBackgroundImage}
                        height="auto"
                        width="100%"
                        alt="Ikon"
                    />
                    <Box height={heroDescriptionHeightMobile}>
                        {heroContent}
                    </Box>
                </Box>
            </section>
        );
    }

    return (
        <section
            className={classes.hero}
            style={{
                backgroundColor: "#f8f8f8",
                height: heroHeightDesktop,
                color: Colors.darkBlue,
            }}
        >
            <Container style={{ height: heroHeightDesktop }}>
                {heroContent}
            </Container>
        </section>
    );
};

export default HeroSection;
