import type { ContentBlock } from "../../../ui/models";
import TextBlock from "./TextBlock";
import CarouselBlock from "./CarouselBlock/index";
import "../content.module.css";
import Gallery from "./GalleryBlock";
import ImageWithTextBlock from "./ImageWithTextBlock";
import FormBlock from "./FormBlock";
import SingleImageBlock from "./SingleImageBlock";
import VideoBlock from "./VideoBlock";

interface Props {
    contentBlock: ContentBlock;
}

const ContentBlockComponent = ({ contentBlock }: Props) => {
    switch (contentBlock.type) {
        case "carousel":
            return <CarouselBlock contentBlock={contentBlock} />;
        case "textField":
            return <TextBlock contentBlock={contentBlock} />;
        case "gallery":
            return <Gallery contentBlock={contentBlock} />;
        case "imageWithText":
            return <ImageWithTextBlock contentBlock={contentBlock} />;
        case "formSection":
            return <FormBlock contentBlock={contentBlock} />;
        case "singleImage":
            return <SingleImageBlock contentBlock={contentBlock} />;
        case "videoSection":
            return <VideoBlock contentBlock={contentBlock} />;
        default:
            throw new Error("Unexpected value");
    }
};

export default ContentBlockComponent;
