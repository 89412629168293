import { Container, Grid, Hidden } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Slider from "react-slick";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
    basic,
    discountText,
    noSub,
    optimum,
    premium,
} from "../../config/subscriptions";
import { ALERT_MESSAGES } from "../../config/variables";
import { responseMessage } from "../../utility";
import CustomSlider from "../../components/UI/CustomSlider/CustomSlider";
import { endLoading, get, post } from "../../config/fetch";
import * as actions from "../../store/actions/index";
import PageSubheader from "../../components/UI/PageSubheader/PageSubheader";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Input from "../../components/UI/Input/Input";
import ListingCard from "../../components/UI/ListingCard/ListingCard";
import SubscriptionCard from "../../components/UI/SubscriptionCard/SubscriptionCard";
import ServiceCard2 from "../../components/UI/ServiceCard2/ServiceCard2";
import { Icons, Images, WebP } from "../../assets/Theme";
import BtnWithIcon from "../../components/UI/Btn/BtnWithIcon";
import Btn from "../../components/UI/Btn/Btn";
import PageHeader from "../../components/UI/PageHeader/PageHeader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useTranslation from "../../features/i18n/useTranslation";
import { useDispatch } from "../../store";
import useAuth from "../../features/auth/useAuth";
import { login } from "../../features/auth/slice";
import useQuery from "../../features/util/useQuery";
import YellowBar from "../../components/Notifications/YellowBar";
import BtnAuth from "../../components/UI/Btn/BtnAuth";
import classes from "./Landing2.module.css";

const BenefitElement = (props) => (
    <div className={classes.benefit}>
        <div className={classes.greenCircle}>
            <img src={Icons.tickGreen} className={classes.tick} alt="" />
        </div>
        <div className={classes.content}>
            <span className={classes.benefitTitle}>{props.title}</span>
            <span className={classes.benefitText}>{props.text}</span>
        </div>
    </div>
);

const SliderElement = (props) => (
    <div className={classes.sliderElement}>
        <p className={classes.sliderText}>
            {props.text}
            <img src={Icons.quotes2} className={classes.quotesTop} alt="" />
            <img src={Icons.quotes} className={classes.quotesBottom} alt="" />
        </p>
        <div className={classes.imageRow}>
            <picture>
                <source srcSet={WebP[`${props.image}WEBP`]} type="image/webp" />
                <img
                    src={Images[props.image]}
                    className={classes.image}
                    alt=""
                />
            </picture>
            <span className={classes.name}>{props.name}</span>
        </div>
    </div>
);

const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const Landing2 = (props) => {
    const navigate = useNavigate();
    const query = useQuery();
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth();
    const slider = useRef(null);

    const [newsletterCheck, toggleNewsletterCheck] = useState(false);
    const inputRef = useRef();
    const { t, i18nRoute } = useTranslation();

    useEffect(() => {
        const immediateHash = query.get("imHash");
        if (immediateHash) {
            post("api/v1/auth/login-with-hash", { loginHash: immediateHash })
                .then((response) => {
                    endLoading();

                    switch (response.data.responseCode) {
                        case "OK":
                            dispatch(
                                login({
                                    userId: response.data.id,
                                    token: response.data.token,
                                })
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    endLoading();

                    console.error(error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const campaign = query.get("campaign");
        if (campaign) {
            sessionStorage.setItem("campaign", campaign);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const subscribeNewsletterHandler = () => {
        if (!newsletterCheck) {
            responseMessage(
                "Az ÁSZF és az Adatkezelési nyilatkozat elfogadása kötelező"
            );

            return false;
        }
        post("api/v1/user/public-subscribe-newsletter", {
            email: inputRef.current.value,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres feliratkozás!");

                        break;
                    case "INVALID_EMAIL":
                        responseMessage("Érvénytelen email cím!");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const [institutions, setInstitutions] = useState([]);

    useEffect(() => {
        get("api/v1/medical/get-institutions", false)
            .then((response) => setInstitutions(response.data.data))
            .catch((error) => console.error(error));
    }, []);

    const onTryoutClick = () => {
        if (isAuthenticated) {
            navigate(
                i18nRoute("routes.profile.paymentMethods", {
                    routeHash: "?openSub=true",
                })
            );
        } else {
            props.toggleRegistrationModal(true);
        }
    };

    return (
        <>
            <section className={classes.hero}>
                <Container className={classes.heroWrapper}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            className={classes.heroContent}
                        >
                            <h1>
                                {t("homepage-title", {
                                    defaultValue:
                                        "Orvosi segítség a családnak, csak egy gombnyomásra",
                                })}
                            </h1>
                            <p>
                                Üdvözlünk a T-DOC egészségplatformján, ahol
                                több, mint 250 szakorvostól kérhetsz online,
                                vagy személyes konzultációt, vizsgálatot, és
                                receptet! Okos eszközöd csatlakoztatásával pedig
                                megőrizzük egészséged stabilitását akkor is, ha
                                hosszan tartó, vagy folyamatos orvosi ellátásra
                                szorulsz.
                            </p>
                            <BtnAuth
                                isPrimary={false}
                                className={classes.buttonAuth}
                            />
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={false} md={1} />
                        </Hidden>
                        <Grid item xs={12} md={6}>
                            <picture>
                                <source
                                    srcSet={WebP.videokonferenciaWEBP}
                                    type="image/webp"
                                />
                                <img
                                    src={WebP.videokonferenciaWEBP}
                                    className={classes.image}
                                    alt=""
                                />
                            </picture>
                            <div className={classes.stores}>
                                <img src={Images.appStore} alt="" />
                                <img src={Images.googlePlay} alt="" />
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <YellowBar {...props} />
            </section>
            <Hidden mdUp>
                <div className={classes.mobileHeroboxes}>
                    <Container>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.heroBox}>
                                    <div className={classes.heroBoxElement}>
                                        <span className={classes.heroRowBox}>
                                            <img
                                                src={Icons.hotline2Green}
                                                className={classes.icon}
                                                alt=""
                                            />
                                            <p className={classes.title}>
                                                Szakterület választó
                                            </p>
                                        </span>
                                        <p className={classes.text}>
                                            Személyes, vagy online időpontok az
                                            ország legmegbízhatóbb
                                            intézményeibe, akár 24 órán belül.
                                        </p>
                                        <BtnWithIcon
                                            color="green"
                                            text="Szakterületek"
                                            className={classes.button}
                                            onClick={() =>
                                                props.toggleQualificationsModal(
                                                    true
                                                )
                                            }
                                        />
                                    </div>
                                    <div className={classes.heroBoxElement}>
                                        <span className={classes.heroRowBox}>
                                            <img
                                                src={Icons.duty2Green}
                                                className={classes.icon}
                                                alt=""
                                            />
                                            <p className={classes.title}>
                                                Általános orvosi konzultáció
                                            </p>
                                        </span>
                                        <p className={classes.text}>
                                            Hívja telefonos ügyeletünket,
                                            beszéljen közvetlenül háziorvos
                                            kollégáinkkal néhány kattintást
                                            követően.
                                        </p>
                                        <BtnWithIcon
                                            color="green"
                                            text="Ügyeletes orvosok"
                                            className={classes.button}
                                            onClick={() =>
                                                navigate(
                                                    i18nRoute(
                                                        "routes.services.duty"
                                                    )
                                                )
                                            }
                                        />
                                    </div>
                                    <div className={classes.heroBoxElement}>
                                        <span className={classes.heroRowBox}>
                                            <img
                                                src={Icons.qualificationsGreen}
                                                className={classes.icon}
                                                alt=""
                                            />
                                            <p className={classes.title}>
                                                Egészség hotline
                                            </p>
                                        </span>
                                        <p className={classes.text}>
                                            Egészségügyi kérdésekben szakképzett
                                            munkatársaink minden nap 08:00 –
                                            20:00 között állnak rendelkezésre.
                                        </p>
                                        <BtnWithIcon
                                            color="green"
                                            text="Egészség hotline"
                                            className={classes.button}
                                            onClick={() =>
                                                navigate(
                                                    i18nRoute(
                                                        "routes.services.hotline"
                                                    )
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Hidden>

            <section className={classes.twoColumns}>
                <Container>
                    <PageHeader text="telemedicina" />
                    <Grid spacing={window.innerWidth > 599 ? 5 : 2} container>
                        <Grid item xs={12} sm={5}>
                            <picture>
                                <img src={Images.telemedicina} alt="" />
                            </picture>
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={false} sm={1} />
                        </Hidden>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            container
                            direction="column"
                            justifyContent="center"
                        >
                            <ul>
                                <li>
                                    Lépj kapcsolatba választott orvosoddal
                                    percek alatt.
                                </li>
                                <li>
                                    Kaphatsz diagnózist, recepteket,
                                    laborvizsgálatot, vagy beutalót.
                                </li>
                                <li>
                                    Telefonon, írásban, vagy videocsevegésen
                                    keresztül is kérheted a segítséget.
                                </li>
                            </ul>

                            <Btn
                                className={classes.button}
                                color="dark-blue"
                                text="Távkonzultációs szolgáltatások"
                                onClick={() => navigate("/szolgaltatasok")}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section
                className={classes.twoColumns}
                style={{ backgroundColor: "#F8F8F8" }}
            >
                <Container>
                    <PageHeader text="Analízis központ" />
                    <Grid spacing={window.innerWidth > 599 ? 5 : 2} container>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            container
                            direction="column"
                            justifyContent="center"
                        >
                            <ul>
                                <li>
                                    Párosítsd eszközeidet az alkalmazással
                                    pillanatok alatt.
                                </li>
                                <li>
                                    Lásd a teljes képet a egészségedről percről
                                    percre, a nap minden órájában.
                                </li>
                                <li>
                                    Ha a mért értékeid szükségessé teszik,
                                    orvosaink azonnal felveszik a kapcsolatot.
                                </li>
                            </ul>

                            <Btn
                                className={classes.button}
                                color="dark-blue"
                                text="Adatalapú szolgáltatások"
                                onClick={() => navigate("/elofizetesek")}
                            />
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={false} sm={1} />
                        </Hidden>
                        <Grid item xs={12} sm={5}>
                            <picture>
                                <img src={Images.meroeszkozok} alt="" />
                            </picture>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            {(props.subscription === 1 || !isAuthenticated) && (
                <section className={classes.commercial}>
                    <Container>
                        <h2>T-DOC előfizetés most 30 napig díjmentesen!</h2>
                        <Btn
                            color="yellow"
                            text="Kipróbálom"
                            className={classes.button}
                            onClick={onTryoutClick}
                        />
                        <p>
                            <Link
                                className={classes.link}
                                to={i18nRoute("routes.terms")}
                                target="_blank"
                                style={{ textDecoration: "underline" }}
                            >
                                Általános szerződési feltételek.
                            </Link>{" "}
                            Az 1 hónapos díjmentes időszak csak az újonnan
                            regisztráló felhasználókra érvényes.
                            <br /> A díjmentes időszakot követően csak 4 990 ft
                            / hónap, amit bármikor lemondhatsz
                        </p>
                    </Container>
                </section>
            )}

            {/* TODO: előfizetés függvényében megjeleníteni */}
            <section className={classes.benefits}>
                <Container>
                    <PageHeader text="Mire jó az előfizetés?" />
                    <div className={classes.benefitsContainer}>
                        <BenefitElement
                            title="Privát egészségügyi asszisztens"
                            text="Egészség hotline szolgáltatásunk minden nap díjmentesen rendelkezésedre áll."
                        />
                        <BenefitElement
                            title="Az egész családnak"
                            text="A csomagba foglalt szolgáltatások és kedvezmények családtagjaidra is érvényesek"
                        />
                        <BenefitElement
                            title="Kedvezményes szolgáltatási díjak"
                            text="Minden szolgáltatásunk kedvezményes áron érhető el az előfizetéseken belül."
                        />
                        <BenefitElement
                            title="Adatalapú telemedicina"
                            text="Orvostechnikai és saját okoseszközeidből származó egészségügyi adatok gyűjtése és elemzése"
                        />
                    </div>
                    <Btn
                        color="transparent"
                        text="Előfizetési csomagok"
                        className={classes.button}
                        onClick={() => navigate("/elofizetesek")}
                    />
                </Container>
            </section>

            <section className={classes.services}>
                <Container>
                    <PageHeader text="Szolgáltatásaink" />
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <ServiceCard2
                                icon={Icons.hotlineGreen}
                                title="Egészség hotline"
                                text="Napi 12 órában elérhető egészségügyi hotline, mely kisebb egészségügyi kérdéseid megválaszolásában."
                                type="vertical"
                                onClick={() =>
                                    navigate(
                                        i18nRoute("routes.services.hotline")
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ServiceCard2
                                icon={Icons.dutyGreen}
                                title="Általános orvosi konzultáció"
                                text="Hívd általános orvosi konzultációs vonalunkat, beszélj közvetlenül háziorvos kollégáinkkal néhány kattintást követően!"
                                type="vertical"
                                onClick={() =>
                                    navigate(i18nRoute("routes.services.duty"))
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ServiceCard2
                                icon={Icons.messageExchangeGreen}
                                title="Orvosi chat"
                                text="Válasz szakorvostól, üzenet formájában 48 órán belül! 100% diszkréció."
                                type="vertical"
                                onClick={() =>
                                    navigate(i18nRoute("routes.services.chat"))
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ServiceCard2
                                icon={Icons.videoConsultationGreen}
                                title="Videókonzultáció"
                                text="Szakorvosi videókonzultáció időben és térben hozzád igazítva."
                                type="vertical"
                                onClick={() =>
                                    navigate(
                                        i18nRoute(
                                            "routes.services.videoConsultation"
                                        )
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ServiceCard2
                                icon={Icons.reservationGreen}
                                title="Járóbeteg ellátás"
                                text="Járóbetegellátásra történő online időpontfoglalás Magyarország legjobb magánegészségügyi intézményeibe."
                                type="vertical"
                                onClick={() =>
                                    navigate(
                                        i18nRoute("routes.services.outPatient")
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ServiceCard2
                                icon={Icons.reservationGreen}
                                title="Másodvélemény"
                                text="Ha bizonytalan vagy a diagnózisban, esetleg megerősítést szeretnél kérni, kérd ki szakorvosaink másodvéleményét."
                                type="vertical"
                                onClick={() =>
                                    navigate(
                                        i18nRoute(
                                            "routes.services.secondOpinion"
                                        )
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ServiceCard2
                                icon={Icons.prescriptionGreen}
                                title="E-recept"
                                text="Kérd gyógyszereid egyszerűen, otthonod kényelméből a T-DOC e-recept segítségével."
                                type="vertical"
                                onClick={() =>
                                    navigate(
                                        i18nRoute(
                                            "routes.services.prescription"
                                        )
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                </Container>
            </section>

            {!isAuthenticated && (
                <section className={classes.subscriptions}>
                    <Container>
                        <PageHeader text="Előfizetések" />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={3}>
                                <SubscriptionCard
                                    className={classes.card}
                                    name="Vendég"
                                    onButtonClick={() =>
                                        props.toggleRegistrationModal(true)
                                    }
                                    description="Vendég felhasználóink számára is elérhető minden szolgáltatásunk, de igénybevételükhöz nem jár semmilyen kedvezmény."
                                    data={noSub}
                                    price="0"
                                    open={props.subscriptionDetails}
                                ></SubscriptionCard>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <SubscriptionCard
                                    className={classes.card}
                                    name="Basic"
                                    yellowText="30 napig ingyenes"
                                    buttonComponent={
                                        <BtnWithIcon
                                            color="yellow"
                                            text="Kipróbálom"
                                            className={classes.button}
                                            onClick={() =>
                                                props.toggleRegistrationModal(
                                                    true
                                                )
                                            }
                                        />
                                    }
                                    hasTrialPeriod
                                    description="Az egészségügyi hotline a hét minden napján rendelkezésedre áll, így egészségügyi probléma esetén azonnal választ kaphatsz kérdéseidre."
                                    price="4990"
                                    data={basic}
                                    open={props.subscriptionDetails}
                                ></SubscriptionCard>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <SubscriptionCard
                                    className={classes.card}
                                    name="Optimum"
                                    greenText="Ajánlott"
                                    onButtonClick={() =>
                                        props.toggleRegistrationModal(true)
                                    }
                                    description="Hívd közvetlenül az orvosi ügyeletet vagy írd meg kérdésed üzenetben! Ha bizonytalan vagy, hogy kihez fordulj, az egészségügyi hotline segít eligazodnod."
                                    price="9990"
                                    data={optimum}
                                    open={props.subscriptionDetails}
                                ></SubscriptionCard>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <SubscriptionCard
                                    className={classes.card}
                                    name="Premium"
                                    onButtonClick={() =>
                                        props.toggleRegistrationModal(true)
                                    }
                                    description="Legyen mindig kéznél az orvosi segítség! Ha szükséged van rendszeres orvosi konzultációra, és szereted karban tartani egészséged, akkor válaszd a Premium csomagot."
                                    price="29990"
                                    data={premium}
                                    open={props.subscriptionDetails}
                                ></SubscriptionCard>
                            </Grid>
                        </Grid>
                        {discountText}
                    </Container>
                </section>
            )}

            <section className={classes.doctors}>
                <Container>
                    <PageHeader text="Ismerd meg orvosainkat" />
                    <Grid spacing={window.innerWidth > 599 ? 5 : 2} container>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            className={classes.imageWrapper}
                        >
                            <picture>
                                <source
                                    srcSet={WebP.doctorsWEBP}
                                    type="image/webp"
                                />
                                <img
                                    src={Images.doctors}
                                    className={classes.image}
                                    alt=""
                                />
                            </picture>
                        </Grid>
                        <Hidden smDown>
                            <Grid
                                item
                                xs={false}
                                sm={1}
                                className={classes.placeholder}
                            />
                        </Hidden>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            className={classes.contentWrapper}
                        >
                            <h2 className={classes.doctorsBiggerText}>
                                Célunk, hogy egészséged a legjobb kezekben
                                tudhassuk
                            </h2>
                            <p
                                className={`${classes.lightText} ${classes.doctorsLightText}`}
                            >
                                Éppen ezért az orvosi csapatunk kiválasztásánál
                                elsődleges szempontok közé tartozik a szaktudás,
                                a, professzionális hozzáállás és az
                                ügyfélközpontúság.
                            </p>
                            <Btn
                                color="transparent"
                                text="Orvosaink listája"
                                className={classes.button}
                                onClick={() => navigate("/orvosaink")}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section className={classes.testimonial}>
                <Container>
                    <PageHeader text="Ezeket mondták rólunk" />
                    <Container>
                        <Grid container spacing={2}>
                            <Hidden smDown>
                                <Grid item sm={1}>
                                    <div
                                        className={
                                            classes.navigationItemContainer
                                        }
                                    >
                                        <div
                                            className={classes.navigationItem}
                                            onClick={() =>
                                                slider.current.slickPrev()
                                            }
                                        >
                                            <ChevronLeftIcon
                                                style={styles.chevron}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>

                            <Grid item xs={12} md={10}>
                                <Slider {...sliderSettings} ref={slider}>
                                    <SliderElement
                                        image="willnerPeter"
                                        name="Dr. Willner Péter"
                                        text="Megoldás mindazok számára, akiknek gyors egészségügyi segítségre vagy tanácsra van szükségük."
                                    />
                                    <SliderElement
                                        image="vasvariBarbara"
                                        name="Dr. Vasvári Barbara"
                                        text="Végre egy olyan szolgáltatás, mely biztosítja a megfelelő betegút irányítást az egészségügyben!"
                                    />
                                    <SliderElement
                                        image="szaboZsolt"
                                        name="Dr. Szabó Zsolt"
                                        text="A T-DOC egészségplatform egyedi módon ötvözi a telemedicina elemeket a klasszikus elemekkel, ezáltal minden egészségügyi kérdés kiindulópontjává válhat."
                                    />
                                </Slider>
                            </Grid>
                            <Hidden smDown>
                                <Grid item sm={1}>
                                    <div
                                        className={
                                            classes.navigationItemContainer
                                        }
                                    >
                                        <div
                                            className={classes.navigationItem}
                                            onClick={() =>
                                                slider.current.slickNext()
                                            }
                                        >
                                            <ChevronRightIcon
                                                style={styles.chevron}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Grid item xs={12}>
                                    <div className={classes.sliderControls}>
                                        <div
                                            className={
                                                classes.navigationItemContainer
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.navigationItem
                                                }
                                                onClick={() =>
                                                    slider.current.slickPrev()
                                                }
                                            >
                                                <ChevronLeftIcon
                                                    style={styles.chevron}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes.navigationItemContainer
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.navigationItem
                                                }
                                                onClick={() =>
                                                    slider.current.slickNext()
                                                }
                                            >
                                                <ChevronRightIcon
                                                    style={styles.chevron}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Container>
                </Container>
            </section>

            {institutions.length > 0 && (
                <section className={classes.institutions}>
                    <Container>
                        <PageHeader text="Partner intézményeink" />
                        <CustomSlider
                            data={institutions}
                            renderFunction={(item) => (
                                <ListingCard
                                    className={classes.ListingCard}
                                    type="institution"
                                    image={item.image}
                                    name={item.name}
                                    city={item.full_address}
                                    onClick={() =>
                                        navigate(
                                            `/partner-intezmenyek/${item.profile_link}`
                                        )
                                    }
                                    outPatient={+item.out_patient_available}
                                    inPatient={+item.in_patient_available}
                                />
                            )}
                        />
                        <Btn
                            color="transparent"
                            text="Összes intézmény"
                            className={classes.button}
                            onClick={() => navigate(`/partner-intezmenyek`)}
                        />
                    </Container>
                </section>
            )}

            <section className={classes.dutyFinder}>
                <Container>
                    <PageHeader text="Ügyeletkereső" />
                    <Grid spacing={window.innerWidth > 599 ? 5 : 2} container>
                        <Grid item xs={12} sm={5}>
                            <img
                                src={WebP.ugyeletkeresoWEBP}
                                className={classes.image}
                                alt=""
                            />
                        </Grid>
                        <Hidden smDown>
                            <Grid item sm={1}></Grid>
                        </Hidden>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            className={classes.dutyContentWrapper}
                        >
                            <p className={classes.doctorsBiggerText}>
                                Sérülés, mérgezés, hirtelen fellépő légúti
                                panaszok esetén sürgős ellátásra lehet szükség.{" "}
                            </p>
                            <p
                                className={`${classes.lightText} ${classes.doctorsLightText}`}
                            >
                                Kereshető adatbázisunk segít megtalálni az önhöz
                                legközelebb eső gyermek és felnőtt ügyeleteket
                                Budapesten és Pest megyében az év minden napján.
                            </p>
                            <Btn
                                color="green"
                                text="Tovább az ügyeletkeresőre"
                                className={classes.button}
                                onClick={() => navigate("/ugyeletkereso")}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section className={classes.news}></section>

            <section className={classes.newsletter}>
                <Container>
                    <PageHeader text="Iratkozz fel hírlevelünkre" />
                    <PageSubheader text="Iratkozz fel hírlevelünkre, hogy első kézből értesülhess újdonságainkról és egyedi, kizárólag hírlevél feliratkozóknak járó kedvező ajánlatainkról." />
                    <Grid container>
                        <Grid item md={3}></Grid>
                        <Grid item md={6}>
                            <div className={classes.inputContainer}>
                                <p className={classes.label}>Email cím</p>
                                <Input
                                    bigger
                                    className={classes.input}
                                    type="email"
                                    ref={inputRef}
                                />
                            </div>
                            <Checkbox
                                onChange={(checked) =>
                                    toggleNewsletterCheck(checked)
                                }
                                style={{ marginTop: "3rem" }}
                                label={
                                    <span>
                                        Elolvastam és elfogadom az{" "}
                                        <Link
                                            className={classes.link}
                                            to={"/adatvedelmi_nyilatkozat"}
                                            target="_blank"
                                        >
                                            általános adatkezelési irányelveket
                                        </Link>{" "}
                                        és a{" "}
                                        <Link
                                            className={classes.link}
                                            to={i18nRoute("routes.terms")}
                                            target="_blank"
                                        >
                                            felhasználási feltételeket.
                                        </Link>
                                    </span>
                                }
                            />
                            <Btn
                                color="green"
                                text="Feliratkozás"
                                className={classes.button}
                                onClick={subscribeNewsletterHandler}
                            />
                        </Grid>
                        <Grid item md={3}></Grid>
                    </Grid>
                </Container>
            </section>
        </>
    );
};

const styles = {
    chevron: {
        width: "3rem",
        height: "3rem",
    },
};

const mapStateToProps = (state) => ({
    subscriptionDetails: state.ui.subscriptionDetails,
    subscription: state.user.subscription,
});

const mapDispatchToProps = (dispatch) => ({
    toggleQualificationsModal: (show) =>
        dispatch(actions.toggleQualificationsModal(show)),
    toggleRegistrationModal: (show) =>
        dispatch(actions.toggleRegistrationModal(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing2);
