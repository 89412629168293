import { Link } from "@material-ui/core";
import {
    Clear as ClearIcon,
    CreditCard as CreditCardIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { endLoading, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import { responseMessage } from "../../../utility";
import Panel from "../atoms/Panel";
import classes from "./CreditCardInfo.module.css";

const CreditCardInfo = ({
    label,
    card,
    token,
    defaultCard,
    onCardDelete,
    onSetAsDefault,
    deleteCard,
}) => {
    const handleCardDelete = () =>
        post("api/v1/payment/delete-card", { token })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres törlés!");
                        onCardDelete(token); // cards state setter: setCards

                        break;
                    }
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleCardDeleteClick = () => {
        const content = defaultCard
            ? "Biztosan törli a kártyát? Törlés esetén - amennyiben va - a következő felvitt kártyája lesz az alapértelmezett kártya. Ezt bármikor módosíthatja."
            : "Biztosan törli a kártyát? Ez nem visszavonható!";

        deleteCard(token, content, handleCardDelete);
    };

    const handleSetAsDefaultCardClick = () =>
        post("api/v1/payment/set-default-card", { token })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres beállítás!");
                        onSetAsDefault(token);

                        break;
                    }
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <div className={classes.item}>
            <label>{label}</label>
            <div className={classes.itemRow}>
                <Panel direction="row" style={{ marginBottom: 0 }}>
                    <CreditCardIcon
                        fontSize="large"
                        style={{ color: "#263D50" }}
                    />
                    <div className={classes.itemContent}>
                        {card}{" "}
                        {defaultCard ? (
                            <span>Elsődleges bankkártya</span>
                        ) : (
                            <button
                                type="button"
                                onClick={handleSetAsDefaultCardClick}
                                className={classes.setAsDefaultButton}
                            >
                                Beállítás alapértelmezettként
                            </button>
                        )}
                    </div>
                </Panel>
                <Link
                    component="button"
                    onClick={handleCardDeleteClick}
                    className={classes.revoke}
                >
                    <span>Törlés</span> <ClearIcon fontSize="large" />
                </Link>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    deleteCard: (cardToken, content, confirmButtonCallback) =>
        dispatch(
            actions.toggleResponseModal(true, {
                title: "Megerősítés szükséges",
                content,
                cancelButton: true,
                confirmButtonCallback: () => confirmButtonCallback(cardToken),
            })
        ),
    setCardName: (name) => dispatch(actions.setCardName(name)),
});

export default connect(null, mapDispatchToProps)(CreditCardInfo);
