import { forwardRef } from "react";
import classes from "./Textarea.module.css";

const Textarea = forwardRef((props, ref) => {
    const onChangeText = (e) => {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    };
    if (props.customRender) {
        return (
            <div>
                {props.label && <p className={classes.label}>{props.label}</p>}
                <div className={classes.container}>
                    <textarea
                        className={classes.textarea}
                        ref={ref}
                        onChange={onChangeText}
                        rows={props.rows || 10}
                        placeholder={props.placeholder}
                    ></textarea>
                    {props.customRenderFunction()}
                </div>
            </div>
        );
    }

    return (
        <div>
            {props.label && <p className={classes.label}>{props.label}</p>}
            <div className={classes.container}>
                <textarea
                    className={classes.textarea}
                    ref={ref}
                    onChange={onChangeText}
                    rows={props.rows || 10}
                    placeholder={props.placeholder}
                    maxLength={props.maxLength}
                    value={props.value}
                    disabled={props.disabled || false}
                ></textarea>
            </div>
        </div>
    );
});

Textarea.displayName = "Textarea";

export default Textarea;
