export const EVENTS = {
    REGISTRATION: "Regisztráció",
    LOGIN: "Bejelentkezés",
    PASSWORD_CHANGE: "Jelszó változtatás",
    CHAT: "Orvosi chat",
    SECOND_OPINION: "Orvosi másodvélemény",
    HOTLINE: "Hotline hívása",
    DUTY: "Ügyelet hívása",
    VIDEO_CONSULTATION: "Videókonzultáció foglalás",
    PRESCRIPTION: "E-recept",
    OUTPATIENT: "Járóbeteg időpontfoglalás",
    SUBSCRIBE: "Előfizetés",
    UNSUBSCRIBE: "Előfizetés lemondva",
    CHANGE_SUBSCRIPTION: "Előfizetés váltás",
    MONTHLY_CHARGE: "Havi fizetés",
    MONTHLY_SUBSCRIPTION_CHANGE: "Havi előfizetés váltás",
    MONTHLY_CHANGE: "Havi előfizetés váltás",
    NOTIFICATION: "Értesítés",
};

export const EVENT_FLAGS = [
    "CHAT",
    "SECOND_OPINION",
    "HOTLINE",
    "DUTY",
    "PRESCRIPTION",
];

export const APPOINTMENT_FLAGS = ["VIDEO_CONSULTATION", "OUTPATIENT"];

export const SYSTEM_FLAGS = [
    "REGISTRATION",
    "LOGIN",
    "PASSWORD_CHANGE",
    "SUBSCRIBE",
    "UNSUBSCRIBE",
    "CHANGE_SUBSCRIPTION",
    "MONTHLY_CHARGE",
    "MONTHLY_SUBSCRIPTION_CHANGE",
    "NOTIFICATION",
];

export const PACKAGES = {
    1: "Előfizetés nélkül",
    2: "Basic",
    3: "Optimum",
    4: "Premium",
};

export const ALERT_MESSAGES = {
    errorTryAgain: "Valami hiba történt. Kérjük, próbálja meg újból!",
};

export const MONTHS = [
    "január",
    "február",
    "március",
    "április",
    "május",
    "június",
    "július",
    "augusztus",
    "szeptember",
    "október",
    "november",
    "december",
];

export const PAYMENT = {
    PAYMENT_FAILED:
        "Hiba történt a fizetés során. Kérjük ellenőrizze, hogy kártyáján van-e elég fedezet!",
    EMPTY_DEFAULT_CARD:
        "A szolgáltatás igénybevételéhez kérünk állíts be egy elsődleges bankkártyát a saját profilodon belül. ",
};

export const packages = [
    "",
    "Előfizetés nélkül",
    "Basic",
    "Optimum",
    "Premium",
];

export const packagePrices = [0, 0, 4990, 9990, 29990];
