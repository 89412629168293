import type { Ulid } from "tdoc-common";
import { baseApi } from "tdoc-common";
import type { StoreInviteRequest } from "./model";

const api = baseApi.injectEndpoints({
    endpoints: (build) => ({
        storeInvite: build.mutation<{ id: Ulid }, StoreInviteRequest>({
            query: (body) => ({
                url: "nhinvites",
                method: "POST",
                body,
            }),
        }),
    }),
});

export const { useStoreInviteMutation } = api;
