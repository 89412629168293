import { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./YellowBar.module.css";

const getYellowBarType = (subscription) => {
    switch (+subscription) {
        case 1:
            return "loggedIn";
        case 2:
        case 3:
        case 4:
            return "subscriber";
        default:
            return "visitor";
    }
};

const YellowBar = (props) => {
    const [hideYellowBar, setHideYellowBar] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            location.pathname === "/idopontfoglalas" ||
            location.pathname === "/idopont-kivalasztva" ||
            location.pathname === "/sikeres-foglalas"
        ) {
            setHideYellowBar(true);
        } else {
            setHideYellowBar(false);
        }
    }, [location]);

    const yellowBarClick = () => {
        if (
            props.yellowBar[getYellowBarType(props.subscription)].target !==
            "modal:registration"
        ) {
            navigate(
                props.yellowBar[getYellowBarType(props.subscription)].target
            );
        } else {
            props.toggleRegistrationModal(true);
        }
    };

    return (
        props.yellowBar[getYellowBarType(props.subscription)].show &&
        !hideYellowBar && (
            <div className={classes.yellowBar} onClick={yellowBarClick}>
                <Container>
                    <div className={classes.yellowBarInner}>
                        <span className={classes.text}>
                            {
                                props.yellowBar[
                                    getYellowBarType(props.subscription)
                                ].text
                            }
                        </span>
                    </div>
                </Container>
            </div>
        )
    );
};

const mapStateToProps = (state) => ({
    yellowBar: state.ui.yellowBar,
    subscription: state.user.subscription,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(YellowBar);
