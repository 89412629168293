const WebshopButton = ({ icon, title, onClick }) => (
    <div
        style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "#1fba9c",
            borderRadius: "16px",
            cursor: "pointer",
        }}
        onClick={onClick}
    >
        <div
            style={{
                position: "relative",
                padding: "20px",
                width: "9rem",
                height: "9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "16rem",
            }}
        >
            {icon}
        </div>
        <div
            style={{
                color: "white",
                fontSize: "1.5rem",
                letterSpacing: "0.07px",
                fontWeight: "bold",
            }}
        >
            {title}
        </div>
    </div>
);

export default WebshopButton;
