import { useTranslation as useTranslationBase } from "react-i18next";
import { useCallback } from "react";

/**
 * Unfortunately this is not compatible with how react router 6 does nested routing,
 * so we need to revamp and re-apply for routes and links…
 */

interface RouteOptions {
    defaultValue?: string;
    routeParameter?: string;
    routeHash?: string;
}

export default () => {
    const { t, i18n } = useTranslationBase();

    const i18nRoute = useCallback(
        (
            route: string,
            { defaultValue, routeParameter, routeHash }: RouteOptions = {}
        ) => {
            const base =
                i18n.language === "hu"
                    ? `${t(route, { defaultValue })}`
                    : `/${i18n.language}/${t(route, { defaultValue })}`;

            if (routeParameter) {
                return `${base}/${routeParameter}`;
            }
            if (routeHash) {
                return `${base}${routeHash}`;
            }

            return base;
        },
        [i18n.language, t]
    );

    return { t, i18n, i18nRoute };
};
