import { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames/bind";
import { useDidMountEffect } from "../../reservationUtilities";
import classes from "./ReservationSelect.module.css";

const cx = classNames.bind(classes);

const ReservationSelect = (props) => {
    const [value, setValue] = useState(null);
    const onSelectChange = (event, newValue) => {
        setValue(newValue);
        if (props.onChange) {
            props.onChange(newValue ? newValue.id : null);
        }
    };

    useDidMountEffect(() => {
        if (props.options && props.options.length) {
            if (props.selectedId) {
                for (let o of props.options) {
                    if (o.id === props.selectedId) {
                        setValue(o);

                        return;
                    }
                }
                onSelectChange(null, null);
            } else if (props.selectedId === null) {
                // setValue(null)
            }
        } else {
            onSelectChange(null, null);
        }
    }, [props.options, props.selectedId]);

    return (
        <div className={classes.select}>
            <Autocomplete
                classes={{
                    root: cx(props.rootClass, "selectRoot"),
                    inputRoot: classes.inputRoot,
                    endAdornment: classes.endAdornment,
                    popupIndicator: classes.popupIndicator,
                    inputFocused: classes.inputFocused,
                    paper: classes.popper,
                }}
                // disableClearable={true}
                value={value}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event, newValue) => onSelectChange(event, newValue)}
                noOptionsText={props.noOptionsText}
                options={props.options || [{ id: -1, title: "Válasszon" }]}
                getOptionLabel={(option) => (option ? option.title : "")}
                style={{ width: "100%" }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        variant="outlined"
                    />
                )}
            />
        </div>
    );
};

export default ReservationSelect;
