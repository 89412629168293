import { Container } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/UI/Breadcrumbs/Breadcrumbs";
import PageHeader from "../../components/UI/PageHeader/PageHeader";
import { Icons } from "../../assets/Theme";
import Line from "../../components/UI/Line/Line";
import Qualification from "../../components/UI/Qualifications/Qualifications";
import { get } from "../../config/fetch";
import useTranslation from "../../features/i18n/useTranslation";
import { removeAccents } from "../../utility";
import classes from "./QualificationsSummary.module.css";

interface Qualifications {
    id: number;
    medioId?: number;
    name: string;
    url: string;
}

let originalQualifications: Qualifications[] = [];

const QualificationsSummary = () => {
    const navigate = useNavigate();
    const [qualifications, setQualifications] = useState(
        originalQualifications
    );
    const [qualificationsLoaded, setQualificationsLoaded] = useState(false);
    const { i18nRoute } = useTranslation();

    const formatText = (text: string) =>
        removeAccents(text).toLowerCase().trim();

    const onSearch = (text: string) => {
        if (text.length > 2) {
            const searchValue = formatText(text);
            const matches = [];
            for (const q of originalQualifications) {
                if (formatText(q.name).indexOf(searchValue) > -1) {
                    matches.push(q);
                }
            }
            setQualifications([...matches]);
        } else {
            setQualifications([...originalQualifications]);
        }
    };

    const sortQualifications = (qualifications: Qualifications[]) =>
        qualifications.sort((a, b) => a.name.localeCompare(b.name, "hu"));

    useEffect(() => {
        originalQualifications = [];
        setQualifications([]);
        setQualificationsLoaded(false);
        get("api/v1/medical/get-qualifications", false)
            .then((response) => {
                if (response.data.responseCode === "OK") {
                    const sortedQualifications = sortQualifications(
                        response.data.data
                    );
                    originalQualifications = [...sortedQualifications];
                    setQualifications(sortedQualifications);
                    setQualificationsLoaded(true);
                }
            })
            .catch((error) => console.error(error));
    }, []);

    const onQualificationClick = (url: string) => {
        // close();
        navigate(`${i18nRoute("routes.qualifications.base")}/${url}`);
    };

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs />
                <PageHeader
                    text="Szakterület választó"
                    style={styles.PageHeader}
                />
                <div className={classes.inputContainer}>
                    <input
                        type="text"
                        className={classes.input}
                        placeholder="Szakterületek keresése"
                        onChange={(e) => onSearch(e.target.value)}
                    />
                    <img
                        src={Icons.searchGreen}
                        className={classes.searchIcon}
                        alt=""
                    />
                </div>
                <Line className={classes.line} />
                <div className={classes.qualifications}>
                    {qualificationsLoaded ? (
                        <Qualification
                            qualifications={qualifications}
                            onQualificationClick={onQualificationClick}
                        />
                    ) : (
                        <div className={classes.skeletons}>
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};

const styles = {
    PageHeader: {
        marginBottom: "3rem",
    },
};

export default QualificationsSummary;
