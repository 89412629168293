import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./classes.module.css";

const GDPR = () => {
    const { i18nRoute } = useTranslation();

    return (
        <>
            <h3 className={classes.title} id="adat-hozzaferes">
                Ki férhet hozzá adataimhoz?
            </h3>
            <p className={classes.text}>
                Kérlek olvasd el az{" "}
                <a className={classes.link} href={i18nRoute("routes.privacy")}>
                    adatvédelmi tájékoztatót!
                </a>
            </p>
            <h3 className={classes.title} id="elozmeny-elerese">
                Mi történik a hanganyagokkal, a chat-beszélgetésekkel és a
                videókonzultációk anyagával?{" "}
            </h3>
            <p className={classes.text}>
                Kérlek olvasd el az{" "}
                <a className={classes.link} href={i18nRoute("routes.privacy")}>
                    adatvédelmi tájékoztatót!
                </a>
            </p>
        </>
    );
};

export default GDPR;
