import { useMemo } from "react";
import cn from "classnames";
import classes from "./Avatar.module.css";
import placeholder from "../../../assets/images/icons/default-avatar.svg";

const Avatar = ({ source, initials, status }) => {
    const imageSource = useMemo(
        () => (source ? source : initials ? undefined : placeholder),
        [source, initials]
    );

    return (
        <div
            className={classes.avatar}
            style={{ backgroundImage: `url('${imageSource}')` }}
        >
            {!imageSource && initials}
            {status && (
                <div
                    className={cn(classes.indicator, {
                        [classes.active]: status,
                    })}
                />
            )}
        </div>
    );
};

export default Avatar;
