import { Grid, Hidden } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Icons } from "../../../../assets/Theme";
import CustomSelect from "../../../../components/UI/CustomSelect/CustomSelect";
import Line from "../../../../components/UI/Line/Line";
import Textarea from "../../../../components/UI/Textarea/Textarea";
import SubscriptionSelect from "../../../../components/UI/SubscriptionSelect/SubscriptionSelect";
import UploadedFile from "../../../../components/UI/UploadedFile/UploadedFile";
import Button from "../../../../components/UI/Button/Button";
import { endLoading, get, post, startLoading } from "../../../../config/fetch";
import { formatNumber, responseMessage } from "../../../../utility";
import { ALERT_MESSAGES, PAYMENT } from "../../../../config/variables";
import PaymentModal from "../../../../components/Modals/PaymentModal/PaymentModal";
import {
    ecommerceCheckout,
    ecommercePurchase,
} from "../../../../config/ecommerce";
import PageSubheader from "../../../../components/UI/PageSubheader/PageSubheader";
import PageHeader from "../../../../components/UI/PageHeader/PageHeader";
import * as actions from "../../../../store/actions/index";
import SubscriptionAndServiceModal from "../../../../components/Modals/SubscriptionAndServiceModal/SubscriptionAndServiceModal";
import useTranslation from "../../../../features/i18n/useTranslation";
import useAuth from "../../../../features/auth/useAuth";
import useQuery from "../../../../features/util/useQuery";
import classes from "./StartPrescription.module.css";

let subscription;
let originalQualifications;
let originalDoctors;
let qualificationDoctors;
let doctorQualifications;

const StartPrescription = (props) => {
    const navigate = useNavigate();
    const query = useQuery();
    const { isAuthenticated } = useAuth();

    const [selectedDoctorId, setSelectedDoctorId] = useState(
        query.get("doctor") || 0
    );
    const [selectedQualificationId, setSelectedQualificationId] = useState(
        query.get("qualification") || 0
    );

    const [price, setPrice] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(1);
    const [originalSubscription, setOriginalSubscription] = useState(false);
    const [subscriptionAndServiceModal, toggleSubscriptionAndServiceModal] =
        useState(0);
    const [subscriptionAndServiceModalData, setSubscriptionAndServiceModal] =
        useState({
            freeOccasions: null,
            servicePrices: [],
            service: null,
        });

    const [files, setFiles] = useState([]);
    const [paymentModal, togglePaymentModal] = useState(false);

    const [chatInit, setChatInit] = useState({
        // symptoms: [{ name: 'Válasszon tünetet...', id: 0 }, { name: 'Egyéb', id: -1 }],
        qualifications: [
            { name: "Válasszon szakterületet...", id: 0 },
            { name: "Egyéb", id: -1 },
        ],
        doctors: [],
    });

    const [packages, setPackages] = useState(null);

    const inputRef = useRef();
    const uploadInputRef = useRef();
    const medicineRef = useRef();
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        if (!isAuthenticated) {
            Promise.all([
                getSymptomsAndQualifications(),
                getChatDoctors(),
            ]).then((response) => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                originalQualifications = response[0].qualifications;
                // eslint-disable-next-line react-hooks/exhaustive-deps
                originalDoctors = response[1];
                setChatInit({
                    // symptoms: [...chatInit.symptoms, ...response[0].symptoms],
                    qualifications: [
                        ...chatInit.qualifications,
                        ...response[0].qualifications,
                    ],
                    doctors: [
                        { doctor_id: 0, name: "Válasszon orvost..." },
                        { doctor_id: -1, name: "Általános e-recept írás " },
                        ...response[1],
                    ],
                });
                // symptomId = response[0][0].id;
                // illnessId = response[0][0].id;
                // doctorId = response[2][0].doctor_id;
                endLoading();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            const promise1 = post(
                "api/v1/subscription/get-service-prices-by-user",
                { service: "PRESCRIPTION" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });
            const promise2 = post(
                "api/v1/subscription/check-user-service-price",
                { service: "PRESCRIPTION" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });
            Promise.all([
                promise1,
                promise2,
                getSymptomsAndQualifications(),
                getChatDoctors(),
            ]).then((responses) => {
                endLoading();
                // eslint-disable-next-line react-hooks/exhaustive-deps
                originalQualifications = responses[2].qualifications;
                // eslint-disable-next-line react-hooks/exhaustive-deps
                originalDoctors = responses[3];

                if (selectedQualificationId != 0 && selectedDoctorId == 0) {
                    reduceDoctors(selectedQualificationId, true);
                }

                if (selectedQualificationId == 0 && selectedDoctorId != 0) {
                    reduceQualifications(selectedDoctorId, true);
                }

                if (selectedQualificationId == 0 && selectedDoctorId == 0) {
                    setChatInit({
                        qualifications: [
                            ...chatInit.qualifications,
                            ...responses[2].qualifications,
                        ],
                        doctors: [
                            { doctor_id: 0, name: "Válasszon orvost..." },
                            { doctor_id: -1, name: "Általános e-recept írás " },
                            ...responses[3],
                        ],
                    });
                }

                if (selectedQualificationId != 0 && selectedDoctorId != 0) {
                    reduceQualifications(selectedDoctorId, false);
                    reduceDoctors(selectedQualificationId, false);
                }

                const response1 = responses[0];
                const response2 = responses[1];
                let _packages = processPackages(
                    response1.packages,
                    response1.subscription ? response1.subscription : 1,
                    response2.data
                );
                setPackages(_packages);
            });
        } else {
            post("api/v1/subscription/get-service-prices-by-user", {
                service: "PRESCRIPTION",
            }).then((response) => {
                endLoading();
                if (response.data.responseCode === "OK") {
                    let _packages = processPackages(
                        response.data.packages,
                        response.data.subscription
                            ? response.data.subscription
                            : 1
                    );
                    setPackages(_packages);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        paymentCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paymentCallback = () => {
        if (query.has("TransactionId")) {
            let identifier = null;
            if (localStorage.getItem("identifier")) {
                identifier = localStorage.getItem("identifier");
            }
            const transactionId = query.get("TransactionId");
            const immediateToken = query.get("immediateToken");
            const oneClickEnabled = query.get("oneClickEnabled");
            post("api/v1/payment/payment-init-callback", {
                transactionId,
                cardName: "Bankkártya",
                immediateToken,
                oneClick: oneClickEnabled,
                identifier,
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            navigate(
                                "/service-initiated?service=prescription",
                                { replace: true }
                            );
                            endLoading();
                            responseMessage(
                                succesFullResponseText(
                                    response.data.ProviderTransactionId
                                )
                            );
                            localStorage.removeItem("identifier");

                            break;
                        case "PAYMENT_ERROR":
                            switch (response.data.errorCode) {
                                case "ERROR":
                                    responseMessage(
                                        unSuccesfullResponseText(
                                            response.data.ProviderTransactionId
                                        )
                                    );
                                    endLoading();

                                    break;
                                case "CANCELED":
                                    responseMessage(
                                        "Megszakítottad a fizetési folyamatot. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "TIMEOUT":
                                    responseMessage(
                                        "Túllépted a tranzakció elindításakor megengedett biztonságos időkaput. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "PENDING":
                                    startLoading();
                                    setTimeout(paymentCallback, 3000);

                                    break;
                            }

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                            endLoading();
                            navigate(i18nRoute("routes.services.hotline"), {
                                replace: true,
                            });
                    }

                    localStorage.removeItem("newCardName");
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    navigate(i18nRoute("routes.services.hotline"), {
                        replace: true,
                    });
                });
        }
    };

    const succesFullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikeres tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
        </>
    );

    const unSuccesfullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikertelen tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
            <p style={{ marginBottom: "1rem" }}>
                Kérjük, ellenőrizze a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adott meg, a
                visszautasítás okának kivizsgálása érdekében kérjük,
                szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
            </p>
        </>
    );

    const processPackages = (packages, subscribedIndex, dynamicData = null) => {
        const _packages = [];
        setSelectedPackage(+subscribedIndex);
        setOriginalSubscription(+subscribedIndex);
        const packagePrices = [null];
        const freeOccasions = [null];
        packages.forEach((p, i) => {
            packagePrices.push(p.price);
            freeOccasions.push(p.freePerMonth);
            if (dynamicData && subscribedIndex - 1 == i) {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        dynamicData.price !== 0
                            ? `${formatNumber(dynamicData.price)} Ft`
                            : `Ingyenes`,
                    subtext:
                        dynamicData.price === 0 &&
                        dynamicData.remainingServices > 0
                            ? `Hátralévő: ${dynamicData.remainingServices}`
                            : "",
                });
            } else {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        p.freePerMonth > 0
                            ? `${p.freePerMonth} ingyenes`
                            : `${formatNumber(p.price)} Ft`,
                    subtext:
                        p.freePerMonth > 0
                            ? `*utána ${formatNumber(p.price)} Ft /beszélgetés`
                            : "",
                });
            }
        });
        setSubscriptionAndServiceModal({
            service: "PRESCRIPTION",
            servicePrices: packagePrices,
            freeOccasions,
        });

        return _packages;
    };

    const renderDoctorSelect = (data) => {
        let qualifications = [];
        if (data.qualifications) {
            for (let q of data.qualifications) {
                qualifications.push(q.name);
            }
        }

        if (window.innerWidth > 599) {
            return (
                <div className={classes.doctorSelect}>
                    {data.image && (
                        <img
                            src={data.image}
                            className={classes.doctorImage}
                            alt=""
                        />
                    )}
                    <div
                        className={classes.textContainer}
                        style={{ display: "flex" }}
                    >
                        <p
                            className={classes.doctorName}
                            style={
                                !data.image
                                    ? { textTransform: "initial" }
                                    : { paddingTop: "10px" }
                            }
                        >
                            {data.name}
                        </p>
                        <p className={classes.qualification}>
                            {qualifications.length > 0
                                ? qualifications
                                      .sort((a, b) => a.localeCompare(b))
                                      .join(", ")
                                : ""}
                        </p>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.doctorSelect}>
                <div className={classes.row} style={{ display: "flex" }}>
                    {data.image && (
                        <img
                            src={data.image}
                            className={classes.doctorImage}
                            alt=""
                        />
                    )}
                    <p
                        className={classes.doctorName}
                        style={
                            !data.image ? { textTransform: "initial" } : null
                        }
                    >
                        {data.name}
                    </p>
                </div>
                <p className={classes.qualification}>
                    {qualifications.length > 0
                        ? qualifications
                              .sort((a, b) => a.localeCompare(b))
                              .join(", ")
                        : ""}
                </p>
            </div>
        );
    };

    const getSymptomsAndQualifications = async () =>
        get("api/v1/medical/get-symptoms-and-qualifications").then(
            (response) => response.data
        );

    const getChatDoctors = async () =>
        get("api/v1/medical/get-prescription-doctors").then((response) => {
            doctorQualifications = response.data.qualificationsByDoctor;
            qualificationDoctors = response.data.doctorsByQualification;

            return response.data.data;
        });
    const spacing = window.innerWidth > 599 ? 6 : 6;

    const startButtonHandler = () => {
        if (!inputRef.current.value) {
            responseMessage(
                "Az indoklás mező üresen maradt! Néhány mondatban foglald össze indoklásodat, hogy mihamarabb segíteni tudjunk."
            );

            return false;
        }
        post("api/v1/subscription/check-user-service-price", {
            service: "PRESCRIPTION",
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        setPrice(response.data.data.price);
                        subscription = response.data.data.userPackage;
                        ecommerceCheckout(
                            "PRESCRIPTION",
                            response.data.data.price,
                            subscription
                        );
                        toggleSubscriptionAndServiceModal(2);

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const getChatData = () => ({
        doctorId: selectedDoctorId,
        service: "PRESCRIPTION",
        message: inputRef.current.value,
        medicines: medicineRef.current.value,
        files,
    });

    const startChat = () => {
        let formData = new FormData();
        if (files.length > 0) {
            let i = 0;
            for (let f of files) {
                formData.append(`file${i++}`, f);
            }
        }
        formData.append("qualificationId", selectedQualificationId);
        formData.append("doctorId", selectedDoctorId);
        formData.append("service", "PRESCRIPTION");
        formData.append("message", inputRef.current.value);
        formData.append("medicines", medicineRef.current.value);
        post("api/v1/prescription/create-chat", formData)
            .then((response) => {
                endLoading();
                toggleSubscriptionAndServiceModal(0);
                switch (response.data.responseCode) {
                    case "OK":
                        ecommercePurchase("PRESCRIPTION", price, subscription);
                        navigate("/service-initiated?service=prescription");

                        break;
                    case "MISSING_SYMPTOM_OR_ILLNESS":
                        break;
                    case "MISSING_MESSAGE":
                        responseMessage(
                            "Az indoklás mező üresen maradt! Néhány mondatban foglald össze indoklásodat, hogy mihamarabb segíteni tudjunk."
                        );
                        togglePaymentModal(false);

                        break;
                    case "PAYMENT_FAILED":
                        responseMessage(PAYMENT.PAYMENT_FAILED);

                        break;
                    case "MISSING_PERSONAL_DATA":
                        responseMessage(
                            "Hiányzó személyes adatok! Kérjük, először személyes adataid add meg.",
                            null,
                            () => navigate(i18nRoute("routes.profile.user"))
                        );

                        break;
                    case "EMPTY_DEFAULT_CARD":
                        // responseMessage(PAYMENT.EMPTY_DEFAULT_CARD, null, () => navigate(i18nRoute('routes.profile.paymentMethods')));
                        localStorage.setItem(
                            "identifier",
                            response.data.identifier
                        );
                        props.toggleServicePaymentModal(
                            true,
                            price,
                            "PRESCRIPTION",
                            null,
                            response.data.identifier
                        );
                        togglePaymentModal(false);

                        break;
                    // case 'INVALID_FILE_QTY':
                    //     responseMessage('Figyelem! Csak 1 dokumentum feltöltése lehetséges.');
                    //     break;
                    // case 'INVALID_FILE_SIZE':
                    //     responseMessage('Figyelem! A dokumentum mérete maximum 10MB lehet.');
                    //     break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const fileAdded = (event) => {
        const file = event.target.files[0];
        setFiles([...files, file]);
    };

    const onDeleteClick = (e, index) => {
        let newFiles = files;
        newFiles.splice(index, 1);
        setFiles([...newFiles]);
    };

    const redeemCoupon = (code) => {
        if (code) {
            post("api/v1/coupon/redeem-coupon", { couponCode: code })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("A kupont sikeresen beváltottad!");
                            startButtonHandler();

                            break;
                        case "WRONG_CODE":
                            responseMessage("Hibás kuponkód!");

                            break;
                        case "ALREADY_REDEEMED":
                            responseMessage(
                                "Ezt a kupont már beváltottad egyszer!"
                            );

                            break;
                        case "ONLY_WITHOUT_SUBSCRIPTION":
                            responseMessage(
                                "Ezt a kupont csak előfizetés nélkül lehet beváltani!"
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const onQualificationChange = (qualificationId) => {
        setSelectedQualificationId(qualificationId);
        if (qualificationId > -1) {
            reduceDoctors(qualificationId);
        }
    };

    const reduceDoctors = (
        qualificationId,
        withOriginalQualifications = false
    ) => {
        let qualificationHasDoctor = false;
        let doctors = [
            { doctor_id: 0, name: "Válasszon orvost…" },
            {
                doctor_id: -1,
                name: "Általános kérdésem van, nem választok orvost ",
            },
        ];
        if (qualificationId != 0) {
            for (let q of qualificationDoctors) {
                if (q.qualification_id === qualificationId) {
                    qualificationHasDoctor = true;
                    let doctorIds = q.doctors.split(",");
                    if (
                        !doctorIds.includes(selectedDoctorId) &&
                        selectedDoctorId > 0
                    ) {
                        setSelectedDoctorId(0);
                    }
                    for (let d of originalDoctors) {
                        if (doctorIds.includes(d.doctor_id)) {
                            doctors.push(d);
                        }
                    }

                    break;
                }
            }
        } else {
            doctors = [...doctors, ...originalDoctors];
        }

        if (!qualificationHasDoctor && selectedDoctorId != -1) {
            setSelectedDoctorId(0);
        }
        setChatInit((prevValue) => ({
            qualifications: withOriginalQualifications
                ? originalQualifications
                : prevValue.qualifications,
            doctors,
        }));
    };

    const onDoctorChange = (doctorId) => {
        setSelectedDoctorId(doctorId);
        reduceQualifications(doctorId);
    };

    const reduceQualifications = (doctorId, withOriginalDoctors = false) => {
        let qualifications = [
            { name: "Válasszon szakterületet...", id: 0 },
            { name: "Egyéb", id: -1 },
        ];
        let qualificationsMatch = doctorQualifications[doctorId];
        if (doctorId != -1 && doctorId != 0) {
            if (qualificationsMatch.length > 0) {
                for (let q of originalQualifications) {
                    if (qualificationsMatch.includes(q.id)) {
                        qualifications.push(q);
                    }
                }
                setChatInit((prevValue) => ({
                    doctors: withOriginalDoctors
                        ? originalDoctors
                        : prevValue.doctors,
                    qualifications,
                }));
            }
        } else {
            setChatInit((prevValue) => ({
                doctors: withOriginalDoctors
                    ? originalDoctors
                    : prevValue.doctors,
                qualifications: [...qualifications, ...originalQualifications],
            }));
        }
    };

    const resetSubscription = () => setSelectedPackage(originalSubscription);

    const onPackageClick = (pckg) => {
        setSelectedPackage(pckg);
        toggleSubscriptionAndServiceModal(1);
    };

    return (
        <>
            <PageHeader text="E-recept" />
            <PageSubheader
                text="E-receptírás várakozás, sorban állás és felesleges utazás nélkül. A receptet elektronikusan felküldjük a felhőbe, mely segítségével gyógyszereid bármely gyógyszertárban át tudod venni."
                style={{
                    color: "#263D50",
                    lineHeight: "normal",
                    textAlign: "center",
                    fontWeight: 500,
                }}
            />
            <Line style={{ marginTop: "2rem", marginBottom: "4rem" }} />
            <Grid container spacing={spacing}>
                <Hidden xsDown>
                    <Grid item sm={1}></Grid>
                </Hidden>
                <Grid item xs={12} sm={10}>
                    <CustomSelect
                        controlStyle={styles.select}
                        options={chatInit.qualifications}
                        value={selectedQualificationId || 0}
                        optionKey="name"
                        optionValue="id"
                        onChange={(val) => onQualificationChange(val)}
                        label="Téma kiválasztása (Szakterület)"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={spacing}>
                <Hidden xsDown>
                    <Grid item sm={1}></Grid>
                </Hidden>
                <Grid item xs={12} sm={10}>
                    <Textarea
                        label="Gyógyszerek megadása"
                        placeholder="Gyógyszerek"
                        ref={medicineRef}
                        rows={1}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={spacing}>
                <Hidden xsDown>
                    <Grid item sm={1}></Grid>
                </Hidden>
                <Grid item xs={12} sm={10}>
                    {chatInit.doctors.length > 0 && (
                        <CustomSelect
                            controlStyle={styles.select}
                            options={chatInit.doctors}
                            value={selectedDoctorId || 0}
                            optionValue="doctor_id"
                            optionRender={renderDoctorSelect}
                            onChange={(val) => onDoctorChange(val)}
                            label="Orvos választása"
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={spacing}>
                <Hidden xsDown>
                    <Grid item sm={1}></Grid>
                </Hidden>
                <Grid item xs={12} sm={10}>
                    <p className={classes.label}>E-receptírás ára</p>
                    <div className={classes.subscriptionSelectContainer}>
                        {packages &&
                            packages.map((item, index) => (
                                <SubscriptionSelect
                                    selected={index + 1 === selectedPackage}
                                    data={item}
                                    cancellable={
                                        selectedPackage === index + 1 &&
                                        selectedPackage != originalSubscription
                                    }
                                    packageResetClick={resetSubscription}
                                    packageClick={() =>
                                        onPackageClick(index + 1)
                                    }
                                    hoverable={
                                        index + 1 >= originalSubscription
                                    }
                                    style={{
                                        backgroundColor:
                                            window.innerWidth > 599
                                                ? "white"
                                                : "",
                                    }}
                                    key={item.name}
                                    // asterisk={index === 0 ? '*' : ''}
                                />
                            ))}
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={spacing}>
                <Hidden xsDown>
                    <Grid item sm={1}></Grid>
                </Hidden>
                <Grid item xs={12} sm={10}>
                    {isAuthenticated ? (
                        <Textarea
                            label="Indoklás helye"
                            placeholder="Indoklás"
                            ref={inputRef}
                        />
                    ) : (
                        <>
                            <p className={classes.label}>Indoklás helye</p>
                            <div className={classes.bgTextArea}>
                                <p
                                    className={classes.label}
                                    style={{
                                        textAlign: "center",
                                        margin: "3rem",
                                    }}
                                >
                                    Az indoklás megírásához bejelentkezés
                                    szükséges
                                </p>
                                <div className={classes.buttons}>
                                    <Button
                                        style={{
                                            borderRadius: "2.7em",
                                            border: "1px solid #1FBA9C",
                                            marginBottom:
                                                window.innerWidth < 959
                                                    ? "2rem"
                                                    : "",
                                        }}
                                        className={classes.sendButton}
                                        text="Bejelentkezés"
                                        onClick={props.toggleLoginModal}
                                    />
                                    <Button
                                        style={{
                                            borderRadius: "2.7em",
                                            border: "1px solid #1FBA9C",
                                        }}
                                        className={classes.sendButton}
                                        text="Regisztráció"
                                        onClick={props.toggleRegistrationModal}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div className={classes.fileContainer}>
                        {files.map((item, index) => (
                            <UploadedFile
                                source={URL.createObjectURL(item)}
                                onDeleteClick={(e) => onDeleteClick(e, index)}
                                key={`key_${item.name}`}
                                name={item.name}
                                className={classes.uploadedFile}
                                deleteEnabled
                            />
                        ))}
                    </div>
                    <input
                        type="file"
                        className={classes.uploadInput}
                        ref={uploadInputRef}
                        onChange={fileAdded}
                    />
                    <div
                        className={classes.buttons}
                        style={{
                            pointerEvents: !isAuthenticated ? "none" : "",
                            opacity: !isAuthenticated ? "0.5" : "",
                        }}
                    >
                        <Button
                            style={{ borderRadius: "2.7em" }}
                            className={classes.button}
                            onClick={() => uploadInputRef.current.click()}
                        >
                            <div className={classes.uploadButtonInner}>
                                <img
                                    src={Icons.attachmentGreen}
                                    className={classes.uploadIcon}
                                    alt=""
                                />
                                <span className={classes.buttonText}>
                                    Új dokumentum csatolása
                                </span>
                            </div>
                        </Button>
                        <Button
                            style={{
                                borderRadius: "2.7em",
                                border: "1px solid #1FBA9C",
                            }}
                            className={classes.sendButton}
                            text="Folytatás"
                            onClick={startButtonHandler}
                        />
                    </div>
                    <div className={classes.alertContainer}>
                        <img
                            src={Icons.opinionBlack}
                            className={classes.alertIcon}
                            alt=""
                        />
                        <p className={classes.alertText}>
                            FIGYELEM! Abban az esetben, ha nem kerül feltöltésre
                            megfelelő dokumentum, vagy nem megfelelő az
                            indoklás, úgy az orvos megtagadhatja az e-recept
                            felírását.{" "}
                            <Link
                                className={classes.link}
                                to={i18nRoute("routes.services.prescription")}
                            >
                                Részletek {">>"}
                            </Link>
                        </p>
                    </div>
                </Grid>
            </Grid>
            <PaymentModal
                closeModal={() => togglePaymentModal(false)}
                open={paymentModal}
                userSubscribed={false}
                infoText="A folytatásra kattintva rendszerünk kézbesíti az üzeneted a címzettnek, a számládról pedig automatikusan levonja a megjelölt összeget"
                boxTitle={
                    !price
                        ? "Előfizetésben fogalt díjmentes üzenet"
                        : "Előfizetés üzenetváltási díja"
                }
                price={price}
                couponHandler={redeemCoupon}
                onContinueClick={startChat}
            />
            <SubscriptionAndServiceModal
                outerSelectedPackage={selectedPackage}
                selectCallback={setSelectedPackage}
                normalInit={startChat}
                open={subscriptionAndServiceModal}
                data={subscriptionAndServiceModalData}
                getChatData={getChatData}
                toggleSubscriptionAndServiceModal={
                    toggleSubscriptionAndServiceModal
                }
                succesfulSubscribeAndServiceCallback={() =>
                    navigate("/service-initiated?service=prescription")
                }
            />
        </>
    );
};

const styles = {
    select: {
        width: "100%",
        height: "auto",
        minHeight: "50px",
    },
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
    toggleRegistrationModal: (show) =>
        dispatch(actions.toggleRegistrationModal(show)),
    toggleServicePaymentModal: (show, price, service, preScript, identifier) =>
        dispatch(
            actions.toggleServicePaymentModal(
                show,
                price,
                service,
                preScript,
                identifier
            )
        ),
});

export default connect(null, mapDispatchToProps)(StartPrescription);
