import type { BlogItem } from "../../ui/models";

export const mockBlogs: BlogItem[] = [
    {
        category: "Telemedicina",
        title: "INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA. UT ENIM AD MINIM VENIAM, QUIS NOSTRUD EXERCITATION ULLAMCO LABORIS NISI UT ALIQUIP EX EA COMMODO CONSEQUAT. DUIS AUTE IRURE DOLOR IN REPREHENDERIT IN VOLUPTATE VELIT ESSE CILLUM DOLORE EU FUGIAT NULLA PARIATUR. EXCEPTEUR SINT OCCAECAT CUPIDATAT NON PROIDENT, SUNT IN CULPA QUI OFFICIA DESERUNT MOLLIT ANIM ID EST LABORUM",
        image: "https://khn.org/wp-content/uploads/sites/2/2018/03/telemedicine.jpg?w=1024",
        date: "november 15",
    },
    {
        category: "Orvos",
        title: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT, SED DO EIUSMOD TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA. UT ENIM AD MINIM VENIAM, QUIS NOSTRUD EXERCITATION ULLAMCO LABORIS NISI UT ALIQUIP EX EA COMMODO CONSEQUAT. DUIS AUTE IRURE DOLOR IN REPREHENDERIT IN VOLUPTATE VELIT ESSE CILLUM DOLORE EU FUGIAT NULLA PARIATUR. EXCEPTEUR SINT OCCAECAT CUPIDATAT NON PROIDENT, SUNT IN CULPA QUI OFFICIA DESERUNT MOLLIT ANIM ID EST LABORUM",
        image: "https://cdn1.vectorstock.com/i/1000x1000/08/25/portrait-male-doctor-in-medical-mask-vector-30570825.jpg",
        date: "november 15",
    },
    {
        category: "Sebeszet",
        title: "Titulus",
        image: "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/types-of-doctors-1600114658.jpg",
        date: "november 15",
    },
    {
        category: "Orvos",
        title: "Titulus",
        image: "https://img.freepik.com/free-vector/doctor-cartoon_76844-2834.jpg?w=2000",
        date: "november 15",
    },
    {
        category: "Orvos",
        title: "Titulus",
        image: "https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg?w=2000",
        date: "november 15",
    },
    {
        category: "Telemedicina",
        title: "Titulus",
        image: "https://thumbs.dreamstime.com/b/online-doctor-telemedicine-senior-woman-connecting-using-smartphone-app-having-professional-medical-consultation-198855583.jpg",
        date: "november 15",
    },
    {
        category: "Korhaz",
        title: "Titulus",
        image: "https://topszotar.hu/inc/image/entry/EN/h/hospital_jelentese.jpg",
        date: "november 15",
    },
    {
        category: "Korhaz",
        title: "Titulus",
        image: "https://img.freepik.com/free-vector/people-walking-sitting-hospital-building-city-clinic-glass-exterior-flat-vector-illustration-medical-help-emergency-architecture-healthcare-concept_74855-10130.jpg?w=2000",
        date: "november 15",
    },
    {
        category: "Gyogyszerek",
        title: "Titulus",
        image: "https://images.theconversation.com/files/256057/original/file-20190129-108364-17hlc1x.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=675.0&fit=crop",
        date: "november 15",
    },
];

export const mockKeyWords: string[] = [
    "telemedicina",
    "orvos",
    "sebeszet",
    "gyogyszerek",
    "korhaz",
    "topic 1",
    "topic 2",
    "topic 3",
    "topic 4",
];
