export const noSub = {
    name: "Vendég",
    text: "Vendég felhasználóink számára is elérhető minden szolgáltatásunk, de igénybevételükhöz nem jár semmilyen kedvezmény.",
    price: "0",

    hotline: "2 990",
    dutyWeekday: "14 990",
    dutyWeekend: "19 900",
    chat: "9 990",
    secondOpinion: "24 990",
    videoConsultation: "11 000 Ft-tól",
    outpatient: "19 000 Ft-tól",
    dutyP: 33,
    chatP: 40,
    secondOpinionP: 50,
};

export const basic = {
    name: "Basic",
    text: "Alapszintű előfizetőinknek a kedvezményes árú szolgáltatások mellett az egészség hotline korlátok nélkül áll rendelkezésére.",
    price: "4 990",

    hotline: "korlátlan",
    dutyWeekday: "9 990",
    dutyWeekend: "14 990",
    chat: "4 990",
    secondOpinion: "20 990",
    videoConsultation: "9 990 Ft-tól",
    outpatient: "5",

    hotlineP: "korlátlan",
    dutyP: 33,
    chatP: 49,
    secondOpinionP: 16,
    videoConsultationP: 10,
    outpatientP: 5,
};

export const optimum = {
    name: "Optimum",
    text: "A csomag magába foglalja a közvetlen kapcsolatot orvosához. Ideális választás ha biztonságban szeretné tudni az egészségét.",
    price: "9 990",

    hotline: "korlátlan",
    dutyWeekday: "korlátlan",
    dutyWeekend: "korlátlan",
    chat: "4 990",
    secondOpinion: "16 700",
    videoConsultation: "8 800 Ft-tól",
    outpatient: "10",

    hotlineP: "korlátlan",
    dutyP: "korlátlan",
    chatP: "1 db/hó",
    secondOpinionP: 33,
    videoConsultationP: 20,
    outpatientP: 10,
};

export const premium = {
    name: "Premium",
    text: "A prémium előfizetést azoknak ajánljuk, akik rendszeresen szorulnak egészségügyi ellátásra. Így mindig kéznél lesz az orvosi segítség.",
    price: "29 990",

    hotline: "korlátlan",
    dutyWeekday: "korlátlan",
    dutyWeekend: "korlátlan",
    chat: "korlátlan",
    secondOpinion: "12 495",
    videoConsultation: "7 700 Ft-tól",
    outpatient: "15",

    hotlineP: "korlátlan",
    dutyP: "korlátlan",
    chatP: "korlátlan",
    secondOpinionP: 50,
    videoConsultationP: 30,
    outpatientP: 15,
};

export const discounts = {
    Vendég: [],
    Basic: [],
    Optimum: [],
    Premium: [],
};

export const discountText = null;

export const promotionText = (
    <>
        <p
            style={{
                fontSize: "1.4rem",
                fontFamily: "Roboto",
                fontStyle: "italic",
                color: "#263D50",
                marginTop: "2rem",
            }}
        >
            Promóció részletei:
        </p>
        <p
            style={{
                fontSize: "1.4rem",
                fontFamily: "Roboto",
                fontStyle: "italic",
                color: "#263D50",
                marginTop: "0rem",
            }}
        >
            A Basic előfizetés minden új felhasználó részére 30 napig díjmentes.
            Az előfizetés igénybevételéhez regisztráció és teljes profil
            kitöltése szükséges.
        </p>
        <p
            style={{
                fontSize: "1.4rem",
                fontFamily: "Roboto",
                fontStyle: "italic",
                color: "#263D50",
                marginTop: "0rem",
            }}
        >
            A díjmentes időszak lejáratával a Basic előfizetés díja 4990 Ft /
            hó. Az előfizetés bármikor lemondható.
        </p>
    </>
);

// export const discountText = null;
