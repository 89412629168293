import { useEffect, useRef } from "react";
import ChatMessage from "../ChatMessage/ChatMessage";
import { MONTHS } from "../../../config/variables";
import { returnWithZero } from "../../../utility";
import classes from "./ChatWindow.module.css";

const ChatWindow = (props) => {
    const startRef = useRef();
    const endRef = useRef();
    const messagesRef = useRef();

    useEffect(() => {
        messagesRef.current.scrollTo({
            top: endRef.current.offsetTop,
            left: 0,
            behavior: "smooth",
        });
    }, [props.messages]);

    const renderDate = (date) =>
        `${MONTHS[date.getMonth()]} ${date.getDate()}, ${returnWithZero(
            date.getHours()
        )}:${returnWithZero(date.getMinutes())}`;
    const renderMessages = () => {
        let messages = [];
        props.messages.forEach((message, index) => {
            let withDate = false;
            if (
                index === 0 ||
                props.messages[index - 1].author != message.author ||
                message.dateCreated.getTime() -
                    props.messages[index - 1].dateCreated.getTime() >
                    1000 * 60 * 30
            ) {
                withDate = true;
            }
            let type;
            if (message.type === "text" || message.type === "closed") {
                type = message.type;
            } else if (message.type === "media") {
                if (
                    message.media.contentType === "image/png" ||
                    message.media.contentType === "image/jpeg"
                ) {
                    type = "image";
                } else {
                    type = "attachment";
                }
            }
            messages.push(
                <ChatMessage
                    key={message.sid}
                    direction={
                        message.author == props.author ||
                        message.author === "system"
                            ? "sent"
                            : "received"
                    }
                    type={type}
                    date={renderDate(message.dateCreated)}
                    withDate={withDate}
                    otherName={props.otherName}
                    text={message.body}
                    media={message.type === "media" ? message.media : null}
                />
            );
        });

        return messages;
    };

    return (
        <div className={classes.ChatWindow} style={props.style}>
            <div className={classes.header}>
                <span className={classes.title}>
                    {!props.secondOpinion ? "Üzenetváltás" : "Másodvélemény"}
                </span>
                <span className={classes.identifier}>
                    Azonosító: {props.identifier}
                </span>
            </div>
            <div className={classes.inner} ref={messagesRef}>
                <div className={classes.startDummy} ref={startRef}></div>
                {renderMessages()}
                <div
                    className={classes.endDummy}
                    ref={endRef}
                    id="endRef"
                ></div>
            </div>
        </div>
    );
};

export default ChatWindow;
