import CarouselComponent from "react-slick";
import Box from "@material-ui/core/Box";
import { Container } from "@material-ui/core";
import { useResolution } from "../../../../ui/utils";
import ArrowIcon from "../ArrowIcon";
import type { Carousel } from "../../../../ui/models";
import PageHeader from "../../../../ui/layout/PageHeader";
import { Colors } from "../../../../assets/Theme";

interface Props {
    contentBlock: Carousel;
}

const CarouselBlock = ({ contentBlock }: Props) => {
    const isTablet = useResolution("tablet");

    return (
        <Container>
            <Box color={Colors.darkBlue} my={4}>
                {contentBlock.sectionLabel && (
                    <PageHeader text={contentBlock.sectionLabel} />
                )}
                <CarouselComponent
                    autoplay={true}
                    autoplaySpeed={4000}
                    speed={1000}
                    nextArrow={<ArrowIcon type="carousel" direction="next" />}
                    prevArrow={<ArrowIcon type="carousel" direction="prev" />}
                >
                    {contentBlock.images.map((image: string, index) => (
                        <Box key={index} height={isTablet ? "501px" : "564px"}>
                            <img
                                style={{ objectFit: "cover" }}
                                src={image}
                                height="100%"
                                width="100%"
                                alt=""
                            />
                        </Box>
                    ))}
                </CarouselComponent>
            </Box>
        </Container>
    );
};

export default CarouselBlock;
