import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Colors, Fonts, Icons, Images } from "../../../assets/Theme";
import ServicePage from "../../../components/UI/ServicePage/ServicePage";
import CustomSlider from "../../../components/UI/CustomSlider/CustomSlider";
import { endLoading, get, post } from "../../../config/fetch";
import * as actions from "../../../store/actions/index";
import { formatNumber } from "../../../utility";
import {
    ecommerceAddCart,
    ecommerceProductDetail,
} from "../../../config/ecommerce";
import useTranslation from "../../../features/i18n/useTranslation";
import useAuth from "../../../features/auth/useAuth";
import useQuery from "../../../features/util/useQuery";
import classes from "./SecondOpinion.module.css";

let price;
let subscription;

const SecondOpinion = () => {
    const navigate = useNavigate();
    const query = useQuery();
    const { isAuthenticated } = useAuth();
    const [packages, setPackages] = useState(null);
    const [people, setPeople] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(1);
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        if (isAuthenticated) {
            const promise1 = post(
                "api/v1/subscription/get-service-prices-by-user",
                { service: "SECOND_OPINION" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });
            const promise2 = post(
                "api/v1/subscription/check-user-service-price",
                { service: "SECOND_OPINION" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });
            Promise.all([promise1, promise2]).then((responses) => {
                endLoading();
                const response1 = responses[0];
                const response2 = responses[1];
                let _packages = processPackages(
                    response1.packages,
                    response1.subscription ? response1.subscription : 1,
                    response2.data
                );
                setPackages(_packages);
            });
        } else {
            post("api/v1/subscription/get-service-prices-by-user", {
                service: "SECOND_OPINION",
            }).then((response) => {
                endLoading();
                if (response.data.responseCode === "OK") {
                    let _packages = processPackages(
                        response.data.packages,
                        response.data.subscription
                            ? response.data.subscription
                            : 1
                    );
                    setPackages(_packages);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        get("api/v1/medical/get-chat-doctors", false)
            .then((response) => setPeople(response.data.data))
            .catch((error) => console.error(error));
    }, []);

    const processPackages = (packages, subscribedIndex, dynamicData = null) => {
        setSelectedPackage(+subscribedIndex);
        const _packages = [];
        const transactionId = query.get("TransactionId");
        //Ha fizetésről tér vissza, ne fusson le mégegyszer
        if (!transactionId) {
            if (dynamicData) {
                ecommerceProductDetail(
                    "SECOND_OPINION",
                    dynamicData.price,
                    dynamicData.userPackage
                );
                price = dynamicData.price;
                subscription = dynamicData.userPackage;
            } else {
                ecommerceProductDetail(
                    "SECOND_OPINION",
                    packages[0].price,
                    subscribedIndex
                );
                price = packages[0].price;
                subscription = subscribedIndex;
            }
        }
        packages.forEach((p, i) => {
            if (dynamicData && subscribedIndex - 1 == i) {
                _packages.push({
                    name: `${p.packageName} előfizetéssel`,
                    price:
                        dynamicData.price !== 0
                            ? `${formatNumber(dynamicData.price)} Ft`
                            : `Ingyenes`,
                    subtext:
                        dynamicData.price === 0 &&
                        dynamicData.remainingServices > 0
                            ? `Hátralévő: ${dynamicData.remainingServices}`
                            : "",
                });
            } else {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        p.freePerMonth > 0
                            ? `${p.freePerMonth} ingyenes`
                            : `${formatNumber(p.price)} Ft`,
                });
            }
        });

        return _packages;
    };

    const onClickButton = () => {
        ecommerceAddCart("SECOND_OPINION", price, subscription);
        navigate("/szolgaltatasok/masodvelemeny/start");
    };

    const onPackageClick = () => {
        if (isAuthenticated) {
            navigate(i18nRoute("routes.profile.paymentMethods"));
        } else {
            navigate("/elofizetesek");
        }
    };

    return (
        <ServicePage
            icon={Icons.opinionGreen}
            name="Másodvélemény"
            description="A legjobbak véleménye mindig számít. Ha bizonytalan vagy a diagnózisban, esetleg megerősítést szeretnél kérni, mielőtt belevágnál egy kezelésbe, vagy műtét alá vetnéd magad, kérd ki szakorvosaink másodvéleményét."
            image={Images.masodvelemeny}
            imageAlt="Orvosi másodvélemény 72 órán belül | T-DOC"
            packages={packages}
            selectedPackage={selectedPackage}
            originalSubscription={selectedPackage}
            packageClick={onPackageClick}
            showImportantInfo={false}
            buttonCallback={onClickButton}
            buttonText="Másodvélemény igénylése"
            buttonDisabled={false}
            // packageInfoText='* Eredeti ár: 29 990 Ft. A kedvezményes ár 2021.07.31-ig érvényes. A szolgáltatás ára a kedvezményes időszakban minden előfizetési típusban azonos (14 990 Ft).'
            // asterisks={['*']}
        >
            <h1 style={{ ...styles.title, fontSize: "3rem" }}>
                Másodvélemény{" "}
            </h1>

            <p style={styles.text}>
                A legjobbak véleménye mindig számít. Ha bizonytalan vagy a
                diagnózisban, esetleg megerősítést szeretnél kérni, mielőtt
                belevágnál egy kezelésbe, vagy műtét alá vetnéd magad, kérd ki
                szakorvosaink másodvéleményét.
            </p>

            <h2 style={{ ...styles.title, marginTop: "2rem" }}>
                Mi számít másodvéleménynek
            </h2>
            <p style={styles.text}>
                <span style={styles.strong}>
                    A másodvélemény kérése azt jelenti, hogy a meglévő
                    vizsgálati eredményeinkkel, leleteinkkel, zárójelentésünkkel
                    felkészülve kikérjük egy másik orvos véleményét.
                </span>
            </p>
            <p style={styles.text}>
                Természetes, hogy egy nagyobb műtét, altatást igénylő
                beavatkozás során vagy súlyos mellékhatással járó gyógyszeres
                terápia esetén szeretnénk meggyőződni arról, hogy az adott
                kezelési mód a legmegfelelőbb számunkra.{" "}
                <span style={styles.strong}>
                    A másodvélemény nem az orvosunkkal szembeni bizalmatlanságot
                    jelenti, sokkal inkább a tudatos döntésre utal.{" "}
                </span>
                Előfordulhat, hogy másodlagos orvos véleménye megegyezik
                kezelőorvosunk véleményével, de az is, hogy egyéb kezelést
                javasol panaszaink megszüntetése, betegségünk gyógyítása
                érdekében.
            </p>

            <h2 style={{ ...styles.title, marginTop: "2rem" }}>
                Hogyan kérhetsz másodvéleményt?
            </h2>
            <p style={styles.text}>
                A másodvélemény esetében is egy{" "}
                <span style={styles.strong}>
                    chat felület a beszélgetés fóruma.{" "}
                </span>
                Ezen a felületen tudod elküldeni a már meglévő, korábbi
                kivizsgálások leleteit, diagnosztikai felvételeit, melyek
                alapján másodvéleményt kérhetsz a kiválasztott szakorvostól. A
                csatolt dokumentumokhoz lehetőséged van kísérő üzenetet is írni.
                A megküldött információk alapján a szakorvosunk megkezdi a
                kiértékelést, és{" "}
                <span style={styles.strong}>
                    72 órán belül megküldi számodra másodvéleményét.
                </span>
            </p>
            <p style={styles.text}>
                Előfordulhat, hogy a szakorvosunknak további információra lesz
                szüksége ahhoz, hogy elkészítse másodvéleményét. Ilyenkor chat
                formájában kérdéseket tehet fel, melyekről e-mailben és a
                fiókodon belüli üzenetekből értesülhetsz.
            </p>
            <p style={styles.text}>
                A szakorvosaink számára küldött leleteket{" "}
                <span style={styles.strong}>
                    100%-os diszkrécióval kezeljük,{" "}
                </span>
                harmadik fél számára nem bocsájtjuk ki az adatokat.
            </p>

            <h2 style={{ ...styles.title, marginTop: "2rem" }}>
                Mikor érdemes másodvéleményt kérni?
            </h2>
            <p style={styles.text}>
                Célszerű orvosi másodvéleményt kérni abban az esetben, ha
            </p>
            <ul style={styles.text} className={classes.uList}>
                <li>
                    <span style={styles.strong}>kétely merül fel </span>
                    bennünk a diagnózis helyességét illetően,
                </li>
                <li>
                    szeretnénk{" "}
                    <span style={styles.strong}>
                        csökkenteni a tévedés lehetőségét,
                    </span>
                </li>
                <li>
                    <span style={styles.strong}>több orvos véleménye </span>
                    alapján döntenénk a terápia módjáról,
                </li>
                <li>
                    <span style={styles.strong}>további információt </span>
                    szeretnénk tudni a rendelkezésre álló{" "}
                    <span style={styles.strong}>kezelésekről,</span>
                </li>
                <li>
                    az eddigi kezeléseink{" "}
                    <span style={styles.strong}>nem jártak eredménnyel.</span>
                </li>
            </ul>

            <h2 style={{ ...styles.title, marginTop: "2rem" }}>
                Milyen szakterületen tudunk segíteni?
            </h2>
            <p style={styles.text}>
                Orvosi csapatunk célja, hogy pácienseink{" "}
                <span style={styles.strong}>
                    minden lehetséges kezelési módról megfelelő tájékoztatást
                    kapjanak,{" "}
                </span>
                azok előnyeit és hátrányait részletesen tárgyalva. Többek között
                a{" "}
                <span style={styles.strong}>
                    következő szakterületeken kérhető másodvélemény:{" "}
                </span>
                csecsemő- és gyermekgyógyászat, dietetika, fül-orr-gégészet,
                gasztroenterológia, gerincgyógyászat, neurológia, nőgyógyászat,
                ortopédia és urológia.
            </p>
            <p style={styles.text}>
                A döntés minden esetben pácienseink kezében van. Orvosaink{" "}
                <span style={styles.strong}>
                    szakmai véleménnyel alátámasztott, a rendelkezésre álló
                    lehető legtöbb információ átadásával{" "}
                </span>
                segítik a legmegfelelőbb döntést meghozni.
            </p>

            <p style={{ ...styles.title, marginTop: "6rem" }}>
                Orvosi csapatunk
            </p>
            <CustomSlider data={people} people />
        </ServicePage>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontWeight: 700,
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
        marginBottom: "1rem",
    },
    strong: {
        fontWeight: 700,
    },
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
});

export default connect(null, mapDispatchToProps)(SecondOpinion);
