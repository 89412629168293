import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Client } from "twilio-chat";
import Textarea from "../../components/UI/Textarea/Textarea";
import UploadedFile from "../../components/UI/UploadedFile/UploadedFile";
import Button from "../../components/UI/Button/Button";
import { Icons } from "../../assets/Theme";
import { endLoading, post, startLoading } from "../../config/fetch";
import { formatNumber, responseMessage } from "../../utility";
import { ALERT_MESSAGES, PAYMENT } from "../../config/variables";
import * as actions from "../../store/actions/index";
import { getSocket } from "../../config/socket";
import PaymentModal from "../../components/Modals/PaymentModal/PaymentModal";
import { ecommercePurchase } from "../../config/ecommerce";
import Btn from "../../components/UI/Btn/Btn";
import SubscriptionAndServiceModal from "../../components/Modals/SubscriptionAndServiceModal/SubscriptionAndServiceModal";
import useTranslation from "../../features/i18n/useTranslation";
import useQuery from "../../features/util/useQuery";
import ChatWindow from "../../components/UI/ChatWindow/ChatWindow";
import classes from "./ChatRoom.module.css";

let chatClient;
let currentChannel;
let file;
let channelSID;
let doctorName;

const ChatRoom = (props) => {
    const navigate = useNavigate();
    const query = useQuery();

    const identifier = props.identifier;

    //TODO: chat szobákat kilogolni, lehet csak max 50-et ad vissza
    //SOCKET handler
    useEffect(() => {
        const socket = getSocket();
        socket.on("chatStatusUpdate", (msg) => {
            if (msg.identifier === identifier) {
                if (msg.status == "1") {
                    props.toggleResponseModal(true, {
                        renderContent: statusChangeBody(msg.difference),
                        title: "Státusz változás",
                        confirmButton: false,
                        disableBackdropClick: true,
                    });
                }
            }
        });
        socket.on("closeChat", (msg) => {
            if (msg.identifier === identifier) {
                responseMessage(
                    "A beszélgetést az orvos lezárta. Amennyiben úgy gondolod, hogy az orvos nem adott kielégítő választ és úgy zárta le a beszélgetést, kérünk vedd fel a kapcsolatot ügyfélszolgálatunkkal!"
                );
                setChannelClosed(true);
                const closedMessage = {
                    type: "closed",
                    sid: channelSID,
                    author: doctorName,
                    dateCreated: new Date(),
                    body: `${doctorName} lezárta a beszélgetést.`,
                };
                addMessage(closedMessage);
            }
        });

        return () => {
            socket.off("chatStatusUpdate");
            socket.off("closeChat");
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [secondOpinion, setSecondOpinion] = useState(false);
    const [messages, setMessages] = useState([]);
    const messagesRef = useRef();
    const inputRef = useRef();
    const fileInputRef = useRef();
    const [fileName, setFileName] = useState("");
    const [channelClosed, setChannelClosed] = useState(false);
    const [paymentModal, togglePaymentModal] = useState(false);
    const [userSubscribed, setUserSubscribed] = useState(false);
    const [chatPayed, setChatPayed] = useState(true);
    const [price, setPrice] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(1);
    const [originalSubscription, setOriginalSubscription] = useState(false);
    const [subscriptionAndServiceModalData, setSubscriptionAndServiceModal] =
        useState({
            freeOccasions: null,
            servicePrices: [],
            service: null,
            chatIdentifier: null,
        });

    const [withSubscriptionPrice, setWithSubscriptionPrice] = useState(false);
    const [paymentPending, setPaymentPending] = useState(false);
    const [reopenState, setReopenState] = useState(0);
    const { i18nRoute } = useTranslation();

    messagesRef.current = messages;

    const changeSecondOpinion = (secondOpinion) => {
        setSecondOpinion(secondOpinion);
        if (props.changeSecondOpinion) {
            props.changeSecondOpinion(secondOpinion);
        }
    };

    const openPayModal = (price) => {
        if (price !== null) {
            props.toggleResponseModal(true, {
                renderContent: unPayedBody(),
                title: `Fizetés (${formatNumber(price)} Ft)`,
                confirmButton: false,
                disableBackdropClick: false,
            });
        }
    };

    useEffect(() => {
        const transactionId = query.get("TransactionId");
        //Belépés a channelbe, acces token kérés
        post("api/v1/chat/enter-channel", { identifier })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        // endLoading();
                        // props.setChatAccessToken(serviceId, channelSID, response.data.accessToken);
                        //Access token alapján csatlakozás a service-hez
                        channelSID = response.data.channelSID;
                        doctorName = response.data.doctor;
                        setOriginalSubscription(+response.data.subscription);
                        createClient(response.data.accessToken);

                        if (response.data.payed == "0") {
                            setSubscriptionAndServiceModal({
                                freeOccasions: null,
                                servicePrices: [],
                                service:
                                    +response.data.status < 2
                                        ? "CHAT"
                                        : "SECOND_OPINION",
                                chatIdentifier: identifier,
                            });
                            setPaymentPending(true);
                            setChatPayed(false);
                            setChannelClosed(true);
                            setPrice(response.data.price);
                            if (
                                !transactionId &&
                                !response.data.withSubscription
                            ) {
                                setSelectedPackage(+response.data.subscription);
                                openPayModal(response.data.price);
                            } else if (
                                !transactionId &&
                                response.data.withSubscription
                            ) {
                                setSelectedPackage(
                                    +response.data.withSubscription
                                        .desiredPackageId
                                );
                                setWithSubscriptionPrice(
                                    response.data.withSubscription.summaryAmount
                                );
                                props.toggleResponseModal(true, {
                                    renderContent: unPayedBodyWithSubscription(
                                        response.data.price,
                                        response.data.withSubscription
                                            .summaryAmount
                                    ),
                                    title: `Fizetés szükséges`,
                                    confirmButton: false,
                                    disableBackdropClick: false,
                                });
                            }
                        }
                        switch (+response.data.status) {
                            case 0:
                                break;
                            case 1:
                                props.toggleResponseModal(true, {
                                    renderContent: statusChangeBody(
                                        response.data.difference
                                    ),
                                    title: "Státusz változás",
                                    confirmButton: false,
                                    disableBackdropClick: true,
                                });

                                break;
                            case 2:
                                changeSecondOpinion(true);

                                break;
                        }

                        break;

                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();

                        break;
                }
                // startButtonHandler(true, response.data.status !== '2' ? 'CHAT' : 'SECOND_OPINION');
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        paymentCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setEverythingToOpen = (transactionId = false) => {
        const _service = query.get("service");
        const payedAmount = query.get("price");
        const _subscription = query.get("subscription");
        setReopenState(0);
        setChatPayed(true);
        setChannelClosed(false);
        setPaymentPending(false);
        endLoading();
        if (transactionId) {
            responseMessage(succesFullResponseText(transactionId));
        } else {
            responseMessage("Sikeres tranzakció!");
        }
        ecommercePurchase(_service, payedAmount, _subscription);
    };

    const paymentCallback = () => {
        if (query.has("TransactionId")) {
            const transactionId = query.get("TransactionId");
            const immediateToken = query.get("immediateToken");
            const oneClickEnabled = query.get("oneClickEnabled");
            post("api/v1/payment/payment-init-callback", {
                transactionId,
                cardName: "Bankkártya",
                immediateToken,
                oneClick: oneClickEnabled,
                identifier,
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            setEverythingToOpen(
                                response.data.ProviderTransactionId
                            );

                            break;
                        case "PAYMENT_ERROR":
                            switch (response.data.errorCode) {
                                case "ERROR":
                                    responseMessage(
                                        unSuccesfullResponseText(
                                            response.data.ProviderTransactionId
                                        )
                                    );
                                    setPaymentPending(false);
                                    endLoading();

                                    break;
                                case "CANCELED":
                                    responseMessage(
                                        "Megszakítottad a fizetési folyamatot. Próbáld meg újra.",
                                        null,
                                        () => window.location.reload()
                                    );
                                    setPaymentPending(false);
                                    endLoading();

                                    break;
                                case "TIMEOUT":
                                    responseMessage(
                                        "Túllépted a tranzakció elindításakor megengedett biztonságos időkaput. Próbáld meg újra."
                                    );
                                    endLoading();
                                    setPaymentPending(false);

                                    break;
                                case "PENDING":
                                    startLoading();
                                    setPaymentPending(true);
                                    setTimeout(paymentCallback, 3000);

                                    break;
                            }

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                            endLoading();
                    }

                    localStorage.removeItem("newCardName");
                    navigate(`/elozmenyek/orvosi-chat/${identifier}`, {
                        replace: true,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    navigate(`/elozmenyek/orvosi-chat/${identifier}`, {
                        replace: true,
                    });
                });
        }
    };

    const succesFullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikeres tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
        </>
    );

    const unSuccesfullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikertelen tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
            <p style={{ marginBottom: "1rem" }}>
                Kérjük, ellenőrizze a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adott meg, a
                visszautasítás okának kivizsgálása érdekében kérjük,
                szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
            </p>
        </>
    );

    const createClient = (accessToken) =>
        Client.create(accessToken)
            .then((client) => {
                chatClient = client;
                //Aktuális channel kikeresése
                getUserChannels();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const paginatorLoop = (paginator, channels) => {
        if (paginator.hasNextPage) {
            paginator.nextPage().then((result) => {
                paginator = result;
                channels = [...channels, ...result.items];
                paginatorLoop(paginator, channels);
            });
        } else {
            matchChannels(channels);
        }
    };

    const getUserChannels = () =>
        chatClient.getUserChannelDescriptors().then(function (paginator) {
            let channels = [];
            channels = [...channels, ...paginator.items];
            //Az aszinkron függvény egészen addíg hívja meg önmagát az új result függvényében, amíg a result szerint nincs több channel
            paginatorLoop(paginator, channels);
        });

    const matchChannels = (channels) => {
        for (let i = 0; i < channels.length; i++) {
            const channel = channels[i];
            if (channel.sid === channelSID) {
                channel
                    .getChannel()
                    // eslint-disable-next-line no-loop-func
                    .then((response) => {
                        // eslint-disable-next-line no-loop-func
                        currentChannel = response;
                        // console.log(currentChannel);
                        if (response.attributes.closed) {
                            setChannelClosed(true);
                            //Channel üzeneteinek lekérése
                            getMessages(true);
                        } else {
                            //Channel üzeneteinek lekérése
                            getMessages();
                        }

                        //Beérkező üzenet eseménykezelője
                        // eslint-disable-next-line no-loop-func
                        currentChannel.on("messageAdded", (message) =>
                            addMessage(message)
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                    });
            }
        }
    };

    const getMessages = (isClosed = false) =>
        currentChannel.getMessages().then((response) => {
            endLoading();
            if (isClosed) {
                let messages = response.items;
                const closedMessage = {
                    type: "closed",
                    sid: channelSID,
                    author: doctorName,
                    dateCreated: new Date(),
                    body: `${doctorName} lezárta a beszélgetést.`,
                };
                const test = [...messages, closedMessage];
                setMessages(test);
            } else {
                setMessages(response.items);
            }
        });

    let addMessage = (message) =>
        setMessages([...messagesRef.current, message]);

    const sendMessage = (message) => {
        if (fileName !== "") {
            const formData = new FormData();
            formData.append("file", file);
            if (message) {
                currentChannel.sendMessage(message);
                setTimeout(() => currentChannel.sendMessage(formData), 500);
            } else {
                currentChannel.sendMessage(formData);
            }
        } else if (message) {
            currentChannel.sendMessage(message);
        }
    };

    const onSendButtonClick = () => {
        let message = inputRef.current.value;
        sendMessage(message);
        inputRef.current.value = null;
        setFileName("");
        file = null;
    };

    const onFileSelect = () => fileInputRef.current.click();

    const fileChange = (e) => {
        if (e && e.target.files[0]) {
            const files = messages.filter((m) => m.type === "media");
            if (!secondOpinion) {
                if (files.length === 1) {
                    responseMessage(
                        "Figyelem! Csak 1 dokumentum feltöltése lehetséges."
                    );

                    return;
                }
            }
            if (e.target.files[0].size > 52428800) {
                responseMessage(
                    "Figyelem! A dokumentum mérete maximum 50MB lehet."
                );

                return;
            }
            file = e.target.files[0];
            setFileName(file.name);
        } else {
            setFileName("");
            file = null;
        }
    };

    const documentMessageComponent = () => {
        if (["image/png", "image/jpeg"].includes(file?.type)) {
            return (
                <div className={classes.documentMessageContainer}>
                    <img
                        src={URL.createObjectURL(file)}
                        className={classes.imageMessage}
                        alt=""
                    />
                    <span className={classes.uploadFileName}>{file.name}</span>
                    <span
                        className={classes.delete}
                        onClick={() => fileChange(null)}
                    >
                        Mégsem
                    </span>
                </div>
            );
        }

        return (
            <div className={classes.documentMessageContainer}>
                <img
                    src={Icons.attachmentBlack}
                    className={classes.iconMessage}
                    alt=""
                />
                <span className={classes.uploadFileName}>{file.name}</span>
                <span
                    className={classes.delete}
                    onClick={() => fileChange(null)}
                >
                    Mégsem
                </span>
            </div>
        );
    };

    const deleteUploadedFile = (message) =>
        props.toggleResponseModal(true, {
            content: "Biztosan törli a feltöltött fájlt?",
            confirmButtonText: "Törlés",
            confirmButtonCallback: () => onDeleteConfirm(message),
            cancelButton: true,
            cancelButtonText: "Mégsem",
        });

    const onDeleteConfirm = (message) => message.remove();

    const renderUploadedFiles = () => {
        let files = [];
        for (let m of messages) {
            if (m.type === "media") {
                files.push(
                    <UploadedFile
                        media={m.media}
                        key={m.media.filename}
                        name={m.media.filename}
                        className={classes.uploadedFile}
                        deleteEnabled={false}
                        onDeleteClick={() => deleteUploadedFile(m)}
                    />
                );
            }
        }

        return files;
    };

    const statusChangeBody = (difference) => (
        <>
            <p>
                Orvosunk, akivel megkezdted a beszélgetést, üzeneted és a
                feltöltött dokumentumok alapján az Orvosi chatet
                másodvéleménynek jelölte meg. Amennyiben szeretnéd, hogy
                mindenre kiterjedő, részletes másodvéleményt kapj, úgy kérünk
                fogadd el az átminősítést. Elfogadás esetén a szolgáltatások
                árai közötti különbséget({formatNumber(difference)} Ft) a
                megadott bankkártyádra beterheljük. Természetesen dönthetsz úgy,
                hogy nem élsz ezzel a lehetőséggel, ebben az esetben azonban
                előfordulhat, hogy a leleteid kiértékelésére nem kerül sor.
            </p>
            <div className={classes.statusChangeButtons}>
                <Button
                    className={classes.statusChangeButton}
                    text="Elfogadom"
                    onClick={() => respondStatusChange(true)}
                ></Button>
                <Button
                    className={classes.statusChangeButton}
                    text="Nem kérem "
                    onClick={() => respondStatusChange(false)}
                ></Button>
            </div>
        </>
    );

    const unPayedBodyButtonClick = () => {
        props.toggleResponseModal(false);
        togglePaymentModal(true);
    };

    const unPayedBody = () => (
        <>
            <p>
                A kezdeményezett üzenetváltást még nem fizetted ki, ezért ezt
                nem tudjuk az orvos felé továbbítani. Az alábbi gombra kattintva
                teheted ezt meg.
            </p>
            <div className={classes.statusChangeButtons}>
                <Button
                    className={classes.statusChangeButton}
                    text="Fizetés"
                    onClick={unPayedBodyButtonClick}
                ></Button>
            </div>
        </>
    );

    const justOpen = () => setReopenState(1);

    const reopenAndSubscribe = () => setReopenState(2);

    const unPayedBodyWithSubscription = (
        priceOnlyService = 0,
        priceWithSubscription = 0
    ) => (
        <>
            <p>
                A kezdeményezett üzenetváltást még nem fizetted ki, ezért ezt
                nem tudjuk az orvos felé továbbítani. Lehetőséged van ezt a
                jelenlegi előfizetésed keretein belül (
                {formatNumber(priceOnlyService)} Ft) megtenned, vagy a
                kiválasztott előfizetési csommaggal együtt (
                {formatNumber(priceWithSubscription)} Ft).
            </p>
            <Btn
                color="green"
                text="Csak a szolgáltatás fizetése"
                style={{ marginTop: "2rem" }}
                onClick={justOpen}
            />
            <Btn
                color="green"
                text="Előfizetés és szolgáltatás fizetése"
                style={{ marginTop: "2rem" }}
                onClick={() => reopenAndSubscribe()}
            />
        </>
    );

    const respondStatusChange = (accept) =>
        post("api/v1/chat/respond-status-change", {
            accept,
            identifier: props.identifier,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        if (accept) {
                            changeSecondOpinion(true);
                        }
                        props.toggleResponseModal(false);

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });

    const handleReopen = () => {
        const service = !secondOpinion ? "CHAT" : "SECOND_OPINION";
        post("api/v1/subscription/check-user-service-price", { service })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        setPrice(response.data.data.price);
                        // price = response.data.data.price;
                        setUserSubscribed(response.data.data.price < 1);
                        togglePaymentModal(true);

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const reopenChat = () => {
        const service = !secondOpinion ? "CHAT" : "SECOND_OPINION";
        post("api/v1/chat/reopen-chat", {
            service,
            identifier,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        setChannelClosed(false);
                        togglePaymentModal(false);
                        responseMessage(
                            "Sikeresen újranyitottad az üzenetváltást!"
                        );

                        break;
                    case "EMPTY_DEFAULT_CARD":
                        responseMessage("Nincs regisztrált bankkártyád!");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const redeemCoupon = (code) => {
        if (code) {
            post("api/v1/coupon/redeem-coupon", { couponCode: code })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("A kupont sikeresen beváltottad!");
                            startButtonHandler(
                                false,
                                !secondOpinion ? "CHAT" : "SECOND_OPINION"
                            );

                            break;
                        case "WRONG_CODE":
                            responseMessage("Hibás kuponkód!");

                            break;
                        case "ALREADY_REDEEMED":
                            responseMessage(
                                "Ezt a kupont már beváltottad egyszer!"
                            );

                            break;
                        case "ONLY_WITHOUT_SUBSCRIPTION":
                            responseMessage(
                                "Ezt a kupont csak előfizetés nélkül lehet beváltani!"
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const startButtonHandler = (withoutPayment = false, service) =>
        post("api/v1/subscription/check-user-service-price", { service })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        setPrice(response.data.data.price);
                        setUserSubscribed(response.data.data.price < 1);
                        if (!withoutPayment) {
                            togglePaymentModal(true);
                        }

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });

    const payForChat = () => {
        const service = !secondOpinion ? "CHAT" : "SECOND_OPINION";
        post("api/v1/chat/pay-for-chat", {
            service,
            identifier,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres fizetés!");
                        setChatPayed(true);
                        setChannelClosed(false);
                        togglePaymentModal(false);
                        ecommercePurchase(
                            service,
                            price,
                            response.data.subscription
                        );
                        setReopenState(0);

                        break;
                    case "PAYMENT_FAILED":
                        responseMessage(PAYMENT.PAYMENT_FAILED);

                        break;
                    case "EMPTY_DEFAULT_CARD":
                        props.toggleServicePaymentModal(
                            true,
                            price,
                            service,
                            null,
                            identifier
                        );

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    console.log(paymentPending);

    return (
        <div className={classes.ChatRoom} style={props.style}>
            <ChatWindow
                secondOpinion={secondOpinion}
                identifier={props.identifier}
                otherName={doctorName}
                messages={messages}
                author={props.userId}
            ></ChatWindow>
            {!channelClosed && (
                <div className={classes.fileContainer}>
                    {renderUploadedFiles()}
                </div>
            )}
            {!channelClosed && (
                <>
                    <div className={classes.textareaContainer}>
                        <Textarea
                            rows={5}
                            placeholder="Üzenet"
                            ref={inputRef}
                            customRender={fileName !== ""}
                            customRenderFunction={documentMessageComponent}
                        />
                    </div>
                    <div className={classes.buttons}>
                        <input
                            type="file"
                            className={classes.uploadInput}
                            ref={fileInputRef}
                            onChange={fileChange}
                        />

                        <Button
                            className={classes.button}
                            onClick={onFileSelect}
                        >
                            <div className={classes.uploadButtonInner}>
                                <img
                                    src={Icons.attachmentGreen}
                                    className={classes.uploadIcon}
                                    alt=""
                                />
                                <span className={classes.buttonText}>
                                    Új dokumentum csatolása
                                </span>
                            </div>
                        </Button>
                        <Button
                            className={classes.sendButton}
                            onClick={onSendButtonClick}
                            text="Üzenet küldése"
                        />
                    </div>
                    {!secondOpinion && (
                        <div className={classes.alertContainer}>
                            <img
                                src={Icons.opinionBlack}
                                className={classes.alertIcon}
                                alt=""
                            />
                            <p className={classes.alertText}>
                                FIGYELEM! A feltöltött dokumentumok alapján az
                                orvosnak jogában áll az Ön által feltett kérdést
                                másodvélemény kategóriába sorolni. A
                                másodvéleményről{" "}
                                <Link
                                    className={classes.link}
                                    to={i18nRoute(
                                        "routes.services.secondOpinion"
                                    )}
                                >
                                    itt
                                </Link>{" "}
                                olvashat bővebben.
                            </p>
                        </div>
                    )}
                </>
            )}

            {!chatPayed && paymentPending && (
                <p className={classes.channelClosed}>
                    Fizetés folyamatban, kérjük várjon...
                </p>
            )}

            {channelClosed && chatPayed && (
                <p className={classes.channelClosed} onClick={handleReopen}>
                    Van még kérdésem
                </p>
            )}
            {!chatPayed && !withSubscriptionPrice && !paymentPending && (
                <p
                    className={classes.channelClosed}
                    onClick={() => setReopenState(1)}
                >{`Az üzenetküldés a meghiúsult bankkártyás fizetés miatt sajnos sikertelen volt. Kérjük próbáld meg újra. Sikeres fizetést követően a kiválasztott szakorvos azonnal megkapja üzeneted. Fizetendő összeg: ${
                    price ? formatNumber(price) : ""
                } Ft`}</p>
            )}

            {!chatPayed && withSubscriptionPrice && !paymentPending && (
                <p
                    className={classes.channelClosed}
                >{`Az üzenetküldés a meghiúsult bankkártyás fizetés miatt sajnos sikertelen volt. Kérjük próbáld meg újra. Sikeres fizetést követően a kiválasztott szakorvos azonnal megkapja üzeneted. `}</p>
            )}
            {!chatPayed && withSubscriptionPrice && (
                <p
                    className={classes.channelClosed}
                    onClick={() => setReopenState(1)}
                >{`Fizetendő összeg csak a szolgáltatás kifizetésével: ${
                    price ? formatNumber(price) : ""
                } Ft`}</p>
            )}

            {!chatPayed && withSubscriptionPrice && !paymentPending && (
                <p
                    className={classes.channelClosed}
                    onClick={() => setReopenState(2)}
                >{`Fizetendő összeg előfizetéssel együtt: ${
                    price ? formatNumber(withSubscriptionPrice) : ""
                } Ft`}</p>
            )}
            {chatPayed && (
                <PaymentModal
                    closeModal={() => togglePaymentModal(false)}
                    open={paymentModal}
                    userSubscribed={true}
                    infoText="A folytatásra kattintva rendszerünk újranyitja az üzenetváltást, a számládról pedig automatikusan levonja a megjelölt összeget"
                    boxTitle={
                        !price
                            ? "Előfizetésben fogalt díjmentes üzenet"
                            : "Előfizetés üzenetváltási díja"
                    }
                    price={price}
                    onContinueClick={reopenChat}
                    couponHandler={redeemCoupon}
                />
            )}
            {!chatPayed && (
                <PaymentModal
                    closeModal={() => togglePaymentModal(false)}
                    open={paymentModal}
                    userSubscribed={userSubscribed}
                    infoText="A folytatásra kattintva rendszerünk kézbesíti az üzeneted a címzettnek, a számládról pedig automatikusan levonja a megjelölt összeget"
                    boxTitle={
                        !price
                            ? "Előfizetésben fogalt díjmentes üzenet"
                            : "Előfizetés üzenetváltási díja"
                    }
                    price={price}
                    couponHandler={redeemCoupon}
                    onContinueClick={payForChat}
                />
            )}
            <SubscriptionAndServiceModal
                outerSelectedPackage={originalSubscription}
                selectCallback={setSelectedPackage}
                normalInit={payForChat}
                open={reopenState === 1 ? 2 : 0}
                data={subscriptionAndServiceModalData}
                toggleSubscriptionAndServiceModal={setReopenState}
                succesfulSubscribeAndServiceCallback={setEverythingToOpen}
            />
            <SubscriptionAndServiceModal
                outerSelectedPackage={selectedPackage}
                ignoreSubscription={true}
                selectCallback={setSelectedPackage}
                normalInit={payForChat}
                chatIdentifier={props.identifier}
                open={reopenState === 2 ? 2 : 0}
                data={subscriptionAndServiceModalData}
                toggleSubscriptionAndServiceModal={setReopenState}
                succesfulSubscribeAndServiceCallback={setEverythingToOpen}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    setChatAccessToken: (serviceId, channelSID, accessToken) =>
        dispatch(
            actions.setChatAccessToken(serviceId, channelSID, accessToken)
        ),
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
    toggleServicePaymentModal: (show, price, service, preScript, identifier) =>
        dispatch(
            actions.toggleServicePaymentModal(
                show,
                price,
                service,
                preScript,
                identifier
            )
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);
