import { Container, Grid } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import Btn from "../../../components/UI/Btn/Btn";
import { endLoading, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
// refacted layout UI elements to display Paperish design
import Paper from "../../../components/Facelift/atoms/Paper";
import PaperSection from "../../../components/Facelift/atoms/PaperSection";
import Headline from "../../../components/Facelift/atoms/Headline";
import PageSubHeader from "../../../components/Facelift/atoms/PageSubHeader";
import InnerLayout from "../../../components/Facelift/templates/InnerLayout";
import Input from "../../../components/Facelift/molecules/Form/Input";
import "../../../components/Facelift/typo.css";
import classes from "./Invite.module.css";

const Invite = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [email, setEmail] = useState(null);
    const inputRef = useRef();

    const onButtonClickHandler = () =>
        post("user/invite-user", { email })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        inputRef.current.value = "";
                        responseMessage(
                            "Köszönjük szépen az ajánlást! A meghívót elküldtük a megadott e-mail címre."
                        );

                        break;
                    case "EMAIL_ALREADY_TAKEN":
                        responseMessage(
                            "Ezzel az e-mail címmel már van regisztrált felhasználónk. Úgy tűnik, ismerősöd korábban már regisztrált a T-DOC platformra. Hívd meg a többi barátodat is! "
                        );

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                }
            })
            .catch(() => {
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <div className={classes.scope}>
            <Container>
                <InnerLayout>
                    <Paper>
                        <Headline>
                            <Breadcrumbs />
                        </Headline>

                        <PaperSection>
                            <PageSubHeader>
                                Küldj meghívót ismerőseidnek!
                            </PageSubHeader>

                            <p>
                                Segíts Te is Barátaidnak abban, hogy
                                egészségügyi panasz, betegség esetén a lehető
                                leggyorsabban és legrövidebb úton eljuthassanak
                                a megfelelő szakemberhez és megoldást találjanak
                                problémájukra
                            </p>
                            <p>
                                Add meg e-mail címüket, majd kattints a
                                “Meghívás” gombra. A rendszerünk egy automata
                                e-mailt küld a megadott e-mail címre, mely
                                tartalmazza a regisztrációs linket.
                            </p>

                            <label className={classes.formLabel}>
                                {" "}
                                Milyen Email címre küldjük a meghívót?
                            </label>

                            <Grid item xs={12} md={6}>
                                <Input
                                    ref={inputRef}
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>

                            <Btn
                                className={classes.button}
                                onClick={onButtonClickHandler}
                            >
                                Meghívó küldése
                            </Btn>
                        </PaperSection>
                    </Paper>
                </InnerLayout>
            </Container>
        </div>
    );
};

export default Invite;
