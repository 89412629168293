import { useEffect, useRef, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useLoadScript } from "@react-google-maps/api";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Colors, Fonts, Icons } from "../../assets/Theme";
import Breadcrumbs from "../../components/UI/Breadcrumbs/Breadcrumbs";
import PageHeader from "../../components/UI/PageHeader/PageHeader";
import CustomSelect from "../../components/UI/CustomSelect/CustomSelect";
import Line from "../../components/UI/Line/Line";
import { endLoading, get } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { Config } from "../../config/config";
import useTranslation from "../../features/i18n/useTranslation";
import DutyMap from "./DutyMap/DutyMap";
import Search from "./Search/Search";
import InfoRow from "./InfoRow/InfoRow";
import classes from "./DutyFinder.module.css";

const libraries = ["places"];

const DutyFinder = () => {
    const [duties, setDuties] = useState(null);
    const [closestDuties, setClosestDuties] = useState(null);
    const [allDuties, setAllDuties] = useState(null);
    const [searchedAddress, setSearchedAddress] = useState(null);
    const [selectedDuty, setSelectedDuty] = useState(null);
    const [userCoords, setUserCoords] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedType, setSelectedType] = useState("0");
    const [allTypes, setAllTypes] = useState(null);
    const [distance, setDistance] = useState(null);
    const { i18nRoute } = useTranslation();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: Config.googleApiKey,
        libraries,
    });

    const mapContainerRef = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        get("user/get-duties", false)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        const sortedDuties = response.data.duties.sort((a, b) =>
                            a.is_ad < b.is_ad ? 1 : -1
                        );
                        setDuties(sortedDuties);
                        setAllDuties(sortedDuties);
                        getTypeData(response.data.duties);

                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                setUserCoords({
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude,
                                });
                                const filteredArr = filterDataByDistance(
                                    {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude,
                                    },
                                    50000,
                                    response.data.duties
                                );
                                const sortedArr = getClosestDuties(
                                    filteredArr,
                                    {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude,
                                    }
                                );
                                setClosestDuties(sortedArr);
                                setDuties(filteredArr);
                                setSearchedAddress({
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude,
                                });
                            },
                            () => {
                                // Ha nincs bekapcsolva a helymeghatározás akkor Budapest a fókusz
                                setUserCoords({ lat: 47.4979, lng: 19.0402 });
                                const filteredArr = filterDataByDistance(
                                    { lat: 47.4979, lng: 19.0402 },
                                    50000,
                                    response.data.duties
                                );
                                const sortedArr = getClosestDuties(
                                    filteredArr,
                                    { lat: 47.4979, lng: 19.0402 }
                                );
                                setClosestDuties(sortedArr);
                                setDuties(filteredArr);
                                setSearchedAddress({
                                    lat: 47.4979,
                                    lng: 19.0402,
                                });
                            }
                        );

                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTypeData = (duties) => {
        const typeList = [
            { id: "0", name: <span style={{ opacity: 0.6 }}>Válasszon</span> },
        ];
        duties.forEach((d) =>
            d.types.forEach((t) => {
                if (!typeList.some((e) => e.id == t.id)) {
                    typeList.push(t);
                }
            })
        );
        setAllTypes(typeList);
    };

    const calculateDistance = (p1, p2) => {
        const rad = (x) => (x * Math.PI) / 180;

        const getDistance = (p1, p2) => {
            const R = 6378137; // Earth’s mean radius in meter
            const dLat = rad(p2.lat - p1.lat);
            const dLong = rad(p2.lng - p1.lng);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(rad(p1.lat)) *
                    Math.cos(rad(p2.lat)) *
                    Math.sin(dLong / 2) *
                    Math.sin(dLong / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return R * c; // returns the distance in meter
        };

        return getDistance(p1, p2);
    };

    const checkCoordinateDistance = (p1, p2, maxDistance) => {
        const rad = (x) => (x * Math.PI) / 180;

        const getDistance = (p1, p2) => {
            const R = 6378137; // Earth’s mean radius in meter
            const dLat = rad(p2.lat - p1.lat);
            const dLong = rad(p2.lng - p1.lng);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(rad(p1.lat)) *
                    Math.cos(rad(p2.lat)) *
                    Math.sin(dLong / 2) *
                    Math.sin(dLong / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return R * c; // returns the distance in meter
        };

        const distance = getDistance(p1, p2);

        return distance <= maxDistance;
    };

    const onSearchAddress = (address, lat, lng) => {
        // console.log(address);
        setSearchedAddress({ lat, lng });
        setSelectedDuty(null);
        const filteredArr = filterDataByDistance(
            { lat, lng },
            50000,
            allDuties
        );

        let filteredDuties;
        if (selectedType != "0") {
            filteredDuties = filterDutiesByType(selectedType, filteredArr);
            const sortedArr = getClosestDuties(filteredDuties, {
                lat,
                lng,
            });
            setClosestDuties(filterDutiesByType(selectedType, sortedArr));
            setDuties(filterDutiesByType(selectedType, filteredArr));
        } else {
            filteredDuties = filteredArr;
            const sortedArr = getClosestDuties(filteredDuties, {
                lat,
                lng,
            });
            setClosestDuties(sortedArr);
            setDuties(filteredArr);
        }
        getTypeData(filteredDuties);
    };

    const formatAndSetDistance = (data) => {
        let distance;
        if (searchedAddress) {
            distance = (
                calculateDistance(data, searchedAddress) / 1000
            ).toFixed(1);
        } else {
            distance = (calculateDistance(data, userCoords) / 1000).toFixed(1);
        }
        setDistance(distance);
    };

    const onListSelect = (data) => {
        mapContainerRef.current.scrollIntoView({ behavior: "smooth" });
        setSelectedDuty(data);
        formatAndSetDistance(data);
    };

    const selectedOnMap = (duty) => {
        setSelectedDuty(duty);
        formatAndSetDistance(duty);
    };

    const handleTypeSelect = async (val) => {
        setSelectedDuty(null);
        if (val == "0") {
            setSelectedType(val);
            // console.log(allDuties);
            if (searchedAddress) {
                const filteredArr = filterDataByDistance(
                    searchedAddress,
                    50000,
                    allDuties
                );
                const sortedArr = getClosestDuties(
                    filteredArr,
                    searchedAddress
                );
                setClosestDuties(sortedArr);
                setDuties(filteredArr);
            } else {
                getClosestDuties(allDuties);
                setDuties(allDuties);
            }

            return;
        }
        setSelectedType(val);
        if (searchedAddress) {
            const filteredArr = filterDataByDistance(
                searchedAddress,
                50000,
                allDuties
            );
            setDuties(filterDutiesByType(val, filteredArr));
            const sortedArr = getClosestDuties(filteredArr, searchedAddress);
            setClosestDuties(filterDutiesByType(val, sortedArr));
        } else {
            setDuties(filterDutiesByType(val, allDuties));
        }
    };

    const filterDutiesByType = (typeId, arr) => {
        const filteredDuties = [];
        arr.forEach((d) => {
            for (let i = 0; i < d.types.length; ++i) {
                if (d.types[i].id == typeId) {
                    filteredDuties.push(d);

                    break;
                }
            }
        });

        return filteredDuties;
    };

    const filterDataByDistance = (addressCoords, distance, arr) =>
        arr.filter((d) =>
            checkCoordinateDistance(
                { lat: d.lat, lng: d.lng },
                addressCoords,
                distance
            )
        );

    const onDragSearch = (coords) => {
        onSearchAddress(null, coords.lat, coords.lng);
        // setIsOpen(false);
    };

    const getClosestDuties = (duties, coords) =>
        duties.sort((a, b) =>
            calculateDistance(a, coords) > calculateDistance(b, coords) ? 1 : -1
        );

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs />
                <div ref={mapContainerRef}></div>
                <PageHeader text="Ügyeletkereső" style={styles.PageHeader} />
                {isLoaded && userCoords && duties && searchedAddress ? (
                    <DutyMap
                        userCoords={userCoords}
                        selectedDuty={selectedDuty}
                        distance={distance}
                        data={duties}
                        searchedAddress={searchedAddress}
                        selectedOnMap={selectedOnMap}
                        onDragSearch={onDragSearch}
                    />
                ) : (
                    <div className={classes.loader}>
                        <CircularProgress color="primary" />
                        <p>Térkép betöltése...</p>
                    </div>
                )}
                {isLoaded && userCoords && (
                    <div>
                        <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "0.5rem" }}
                        >
                            <Grid item md={2} xs={12}>
                                <div className={classes.filterButtonContainer}>
                                    <img
                                        src={Icons.searchGreen}
                                        className={classes.searchIcon}
                                        alt=""
                                    />
                                    <span className={classes.openFilter}>
                                        KERESÉS
                                    </span>
                                </div>
                            </Grid>
                            <Grid item md={5} xs={12}>
                                <Search onSearchAddress={onSearchAddress} />
                            </Grid>
                            <Grid item md={5} xs={12}>
                                <CustomSelect
                                    controlStyle={styles.select}
                                    options={allTypes}
                                    value={selectedType}
                                    optionKey="name"
                                    optionValue="id"
                                    onChange={(val) => handleTypeSelect(val)}
                                    label="Szakterület kiválasztása"
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
                <Line
                    style={{ backgroundColor: "#929EA7", marginTop: "2rem" }}
                />
                {closestDuties &&
                    userCoords &&
                    closestDuties
                        .sort((a, b) => b.is_ad - a.is_ad)
                        .slice(0, 5)
                        .map((d) => (
                            <div key={d.id}>
                                <InfoRow
                                    data={d}
                                    onListSelect={onListSelect}
                                    isSelected={
                                        !!(
                                            selectedDuty &&
                                            selectedDuty.id == d.id
                                        )
                                    }
                                />
                                <Line style={{ backgroundColor: "#929EA7" }} />
                            </div>
                        ))}

                {closestDuties &&
                    closestDuties.length == 0 &&
                    (selectedType || searchedAddress) && (
                        <div style={{ marginTop: "2rem" }}>
                            <span className={classes.noMatch}>
                                Nem található a közelében megfelelő ügyelet.
                            </span>
                        </div>
                    )}
                {closestDuties && closestDuties.length > 5 && (
                    <>
                        <div
                            className={classes.openMoreContainer}
                            onClick={() => setIsOpen((prev) => !prev)}
                            style={{ marginBottom: isOpen ? "2rem" : null }}
                        >
                            <span className={classes.openFilter}>
                                {!isOpen
                                    ? "TÖBB MUTATÁSA"
                                    : "KEVESEBB MUTATÁSA"}
                            </span>
                            {!isOpen ? (
                                <ExpandMoreIcon
                                    className={classes.expandIcon}
                                />
                            ) : (
                                <ExpandLessIcon
                                    className={classes.expandIcon}
                                />
                            )}
                        </div>
                        {isOpen &&
                            closestDuties
                                .slice(5, closestDuties.length)
                                .map((d) => (
                                    <div key={d.id}>
                                        <InfoRow
                                            data={d}
                                            onListSelect={onListSelect}
                                            isSelected={
                                                !!(
                                                    selectedDuty &&
                                                    selectedDuty.id == d.id
                                                )
                                            }
                                        />
                                        <Line
                                            style={{
                                                backgroundColor: "#929EA7",
                                            }}
                                        />
                                    </div>
                                ))}
                    </>
                )}
                {isOpen && (
                    <div
                        className={classes.openMoreContainer}
                        onClick={() => setIsOpen((prev) => !prev)}
                        style={{ marginBottom: isOpen ? "2rem" : null }}
                    >
                        <span className={classes.openFilter}>
                            {!isOpen ? "TÖBB MUTATÁSA" : "KEVESEBB MUTATÁSA"}
                        </span>
                        {!isOpen ? (
                            <ExpandMoreIcon className={classes.expandIcon} />
                        ) : (
                            <ExpandLessIcon className={classes.expandIcon} />
                        )}
                    </div>
                )}
                <h2 style={{ ...styles.title, marginTop: "4rem" }}>
                    Segítsd Te is a T-DOC ügyeletkereső fejlődését!
                </h2>
                <p style={styles.text}>
                    A T-DOC orvosi ügyeletkeresőn szereplő rendelőket és
                    kórházakat az online elérhető ügyeletek alapján gyűjtöttük
                    össze. Arra törekedtünk, hogy friss információkat gyűjtsünk
                    be, azonban előfordulhat, hogy valamelyik megjelenített adat
                    nem pontos, elavult. Éppen ezért mielőtt elindulnál az
                    ügyeletre, mindig tájékozódj telefonon is, hogy nyitva
                    vannak-e, ellátnak-e az adott ügyeleten.{" "}
                </p>
                <p style={styles.text}>
                    Amennyiben találkozol pontatlan adattal,{" "}
                    <span style={{ fontWeight: "bold" }}>
                        kérlek segíts nekünk, hogy kijavíthassuk
                    </span>
                    . Ha rossz a cím, telefonszám, az ügyelet neve, kérlek küldd
                    el nekünk az{" "}
                    <a className={classes.link} href="mailto: info@t-doc.hu">
                        info@t-doc.hu
                    </a>{" "}
                    e-mail címre.{" "}
                </p>
                <p style={styles.text}>
                    Ha van olyan megbízható orvosi ügyelet a közeledben, amit
                    ismersz és még nem szerepel ügyeletkereső térképünkön,
                    kérlek küldd el nekünk az{" "}
                    <a className={classes.link} href="mailto: info@t-doc.hu">
                        info@t-doc.hu
                    </a>{" "}
                    e-mail címre.
                    <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Építsük meg együtt Magyarország legnagyobb ügyeletkereső
                        adatbázisát!
                    </span>
                </p>
                <h2 style={{ ...styles.title, marginTop: "4rem" }}>
                    Magyarország orvosi ügyeletkereső
                </h2>
                <p style={styles.text}>
                    Az ügyeleti rendszer feladata, hogy a háziorvosok rendelési
                    idején túl, valamint hétvégén és ünnepnapokon biztosítsa a
                    sürgősségi ellátást.
                </p>
                <p style={styles.text}>
                    A T-DOC orvosi ügyeletkeresővel a célunk az volt, hogy
                    létrehozzunk egy olyan adatbázist és keresőt, mely
                    segítségével mindenki gyorsan és egyszerűen megtalálhatja a
                    környezetében lévő valamennyi orvosi ügyeletet. Arra is
                    ügyeltünk, hogy ne csak az általános orvosi vagy baleseti
                    ügyeleteket tüntessük fel, hanem az olyan ügyeleti típusokat
                    is, mint a{" "}
                    <span style={{ fontWeight: "bold" }}>
                        fogászati ügyelet, nőgyógyászati ügyelet, szemészeti
                        ügyelet
                    </span>{" "}
                    vagy a{" "}
                    <span style={{ fontWeight: "bold" }}>
                        gyermekorvosi ügyelet.
                    </span>
                </p>
                <h2 style={{ ...styles.title, marginTop: "4rem" }}>
                    Milyen tünetekkel érdemes nőgyógyászati ügyeletre menni?
                </h2>
                <p style={styles.text}>
                    Hirtelen fellépő, nem múló görcsös fájdalom, rendkívüli
                    vérzés esetén érdemes minél hamarabb konzultálni egy
                    nőgyógyász szakorvossal. Kiemelten igaz ez a kismamákra,
                    akiknek azonnali nőgyógyászati ügyeleti ellátásra lehet
                    szükségük.
                </p>
                <h2 style={{ ...styles.title, marginTop: "4rem" }}>
                    Milyen esetben szükséges gyermekorvosi ügyletre menni?
                </h2>
                <p style={styles.text}>
                    Mint minden ügyelet esetében, gyermekorvosi ügyelethez is
                    csak indokolt esetben forduljunk. Ilyen esetek lehetnek:
                </p>
                <ul className={classes.mainList}>
                    <li>hasfájás</li>
                    <li>csillapíthatatlan láz</li>
                    <li>nehézlégzés, fulladás, rohamszerű köhögés</li>
                    <li>nem múló hányás, hasmenés</li>
                    <li>véres széklet</li>
                    <li>kiütések </li>
                    <li>vizelési problémák (csípő, égő, véres vizelés)</li>
                    <li>fülfájás</li>
                </ul>
                <p style={styles.text}>
                    Az ügyelet nem szállít beteget, törések, sérülések, vérzések
                    esetén az ügyeletes baleseti kórházi ambulanciákat keressük
                    fel.
                </p>
                <p style={styles.text}>
                    Mielőtt útnak indulunk a gyermekorvosi ügyeletre, érdemes
                    figyelnünk arra, hogy legyen nálunk elegendő folyadék,
                    tápszer, pelenka.
                </p>
                <p style={styles.text}>
                    <Link
                        className={classes.link}
                        to={i18nRoute("routes.services.duty")}
                    >
                        Telefonon is kérhetünk az ügyelettől segítséget
                    </Link>
                    , ahol az ügyeletes orvos eldönti, hogy szükséges-e
                    ügyeletre vinni a gyermeket, bizonyos esetekben kimegy a
                    helyszínre, súlyosabb esetben pedig továbbküldi kórházba a
                    pácienst.
                </p>
                <h2 style={{ ...styles.title, marginTop: "4rem" }}>
                    Mikor menjünk fogászati ügyeletre?
                </h2>
                <p style={styles.text}>
                    A csillapíthatatlan fogfájás mindig komoly problémát jelez,
                    mellyel érdemes felkeresni a legközelebbi fogászati
                    ügyeletet. Fogászati ügyeletet a következő esetekben is
                    érdemes felkeresni:
                </p>
                <ul className={classes.mainList}>
                    <li>
                        mechanikai sérülést követően történő fogvesztés,
                        fogmozgás{" "}
                    </li>
                    <li>csillapíthatatlan fogfájás esetén</li>
                    <li>foghúzást követő, nem múló vérzés esetén</li>
                    <li>fogínyvérzés esetén</li>
                    <li>tartós fogszabályozó készülék meghibásodása esetén</li>
                </ul>
                <h2 style={{ ...styles.title, marginTop: "4rem" }}>
                    Mikor szükséges szemészeti ügyeletre menni?
                </h2>
                <p style={styles.text}>
                    Értelemszerűen szemünkkel kapcsolatos problémák esetén
                    forduljunk szemész szakorvoshoz. Ha külső mechanikai hatás
                    éri, ütés, szúrás vagy vágás, melyet követően látásvesztés,
                    látáskiesés vagy látászavar lép fel. Akkor is forduljunk
                    szakemberhez, ha vegyszer (takarítószer, tisztítószer, egyéb
                    háztartásban is megtalálható szerek) vagy kémiai anyag kerül
                    bele.
                </p>
                <p style={styles.text}>
                    Amennyiben nem tudod eldönteni, hogy szükséges-e azonnal
                    orvosi ügyeletre menned, hívd a T-DOC{" "}
                    <Link
                        className={classes.link}
                        to={i18nRoute("routes.services.hotline")}
                    >
                        egészség hotline-t
                    </Link>{" "}
                    vagy a telefonon elérhető{" "}
                    <Link
                        className={classes.link}
                        to={i18nRoute("routes.services.duty")}
                    >
                        általános orvosi konzultációt
                    </Link>
                </p>
            </div>
        </Container>
    );
};

const styles = {
    PageHeader: {
        marginBottom: "3rem",
    },
    select: {
        width: "100%",
    },
    title: {
        fontSize: "2rem",
        fontWeight: 700,
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
        marginBottom: "1rem",
    },
};

export default DutyFinder;
