import { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../UI/Modal/Modal";
import * as actions from "../../../store/actions/index";
import Chbx from "../../UI/Checkbox/Checkbox";
import { Images } from "../../../assets/Theme";
import { formatNumber, responseMessage } from "../../../utility";
import { endLoading, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import BtnWithIcon from "../../UI/Btn/BtnWithIcon";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./SubscriptionPaymentModal.module.css";

// TODO: bejelentkezési modal után hova irányítsuk a usert
const SubscriptionPaymentModal = (props) => {
    const navigate = useNavigate();
    const [termsAccepted, toggleTerms] = useState(false);
    const [terms2Accepted, toggleTerms2] = useState(false);
    const { i18nRoute } = useTranslation();

    const buttonContinueHandler = () => {
        if (!props.userData.selectedPackage) {
            responseMessage("Nem választott csomagot");

            return false;
        }

        if (!termsAccepted || !terms2Accepted) {
            responseMessage(
                "Az adattovábbítási- és az ismétlődő fizetésről történő nyilatkozat elfogadása kötelező!"
            );

            return false;
        }
        initPayment();
    };

    const initPayment = () => {
        localStorage.setItem("newCardName", "Bankkártya");
        post("api/v1/payment/add-card", {
            addType: "subscription",
            package: props.userData.selectedPackage,
            redeemHash: props.userData.redeemHash,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        window.location.href = response.data.url;

                        break;
                    case "MISSING_DATA":
                        responseMessage(
                            "Hiányzó számlázási adatok! Kérjük, először számlázási adataid add meg.",
                            null,
                            () => navigate(i18nRoute("routes.profile.user"))
                        );

                        break;
                    case "MISSING_PERSONAL_DATA":
                        responseMessage(
                            "Hiányzó személyes adatok! Kérjük, először személyes adataid add meg.",
                            null,
                            () => navigate(i18nRoute("routes.profile.user"))
                        );

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                endLoading();
                console.error(error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const backHandler = () => props.setUserDataModalStep(1);

    const getSubscription = () => {
        switch (+props.userData.selectedPackage) {
            case 2:
                return {
                    name: "BASIC - 30 napos próbaidőszak",
                    amount: 0,
                    info: "(a díjmentes időszak után 4 990 Ft)",
                };
            case 3:
                return {
                    name: "Optimum",
                    amount: props.userData.packagePrice,
                    info: "",
                };
            case 4:
                return {
                    name: "Premium",
                    amount: props.userData.packagePrice,
                    info: "",
                };
            default:
                return {
                    name: "Csomag",
                    amount: 0,
                    info: "",
                };
        }
    };

    return (
        <Modal
            open={props.open}
            key="SubscriptionPaymentModal"
            closeModal={props.closeModal}
            maxWidth="md"
            disableBackdropClick
            desktopCloseIcon
            confirmClose
        >
            <div className={classes.SubscriptionPaymentModal}>
                <div className={classes.SubscriptionPaymentModalInner}>
                    <div className={classes.headerRow}>
                        <div className={classes.line}></div>
                        <span className={classes.header}>Összegzés</span>
                        <div className={classes.line}></div>
                    </div>
                    <p className={classes.counter}>3 / 3</p>
                    <div className={classes.modalBody}>
                        <div className={classes.summary}>
                            <p>
                                Kiválasztott előfizetés:{" "}
                                <span className={classes.green}>
                                    {getSubscription().name}
                                </span>
                            </p>
                            <p>
                                Fizetendő összeg:{" "}
                                <span className={classes.green}>
                                    {formatNumber(getSubscription().amount)} Ft{" "}
                                </span>
                                <span className={classes.grey}>
                                    {getSubscription().info}
                                </span>{" "}
                            </p>
                            <p>Az előfizetés bármikor lemondható</p>
                        </div>
                        <Chbx
                            onChange={(checked) => toggleTerms(checked)}
                            label={
                                <p>
                                    Elolvastam és elfogadom az{" "}
                                    <Link
                                        className={classes.link}
                                        to={"/adatvedelmi_nyilatkozat"}
                                        target="_blank"
                                    >
                                        általános adatkezelési irányelveket
                                    </Link>{" "}
                                    és a{" "}
                                    <Link
                                        className={classes.link}
                                        to={i18nRoute("routes.terms")}
                                        target="_blank"
                                    >
                                        felhasználási feltételeket.
                                    </Link>
                                </p>
                            }
                        />
                        <Chbx
                            onChange={(checked) => toggleTerms2(checked)}
                            label={
                                <p>
                                    Elfogadom, hogy a T-DOC Service Kft. által a
                                    www.t-doc.hu felhasználói adatbázisában
                                    tárolt alábbi személyes adataim átadásra
                                    kerüljenek a BIG FISH Payment Services Kft,
                                    mint adatfeldolgozó részére.
                                </p>
                            }
                        />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <img
                            className={classes.paymentImage}
                            src={Images.simple}
                            alt="Simple Pay"
                        />
                        <img
                            className={classes.paymentImage}
                            src={Images.bigfish}
                            alt="Big Fish Payment Gateway"
                        />
                        {props.userData.selectedPackage === 2 && (
                            <p className={classes.paymentInfo}>
                                A kártya hozzáadásához 1 Ft-ot terhelünk
                                egyenlegedre, melyet rögtön vissza is térítünk.
                            </p>
                        )}
                    </div>
                    <BtnWithIcon
                        text="Tovább a fizetési oldalra"
                        color="green"
                        className={classes.button}
                        onClick={buttonContinueHandler}
                    />
                    <p className={classes.backLink} onClick={backHandler}>
                        {`<`} Visszalépés
                    </p>
                </div>
            </div>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleVerificationModal: (show) =>
        dispatch(actions.toggleVerificationModal(show)),
    setUserPhone: (phone) => dispatch(actions.setUserPhone(phone)),
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(SubscriptionPaymentModal);
