import { Box, Container } from "@material-ui/core";
import type { SingleImage } from "../../../../ui/models";
import PageHeader from "../../../../ui/layout/PageHeader";
import { Colors } from "../../../../assets/Theme";

interface Props {
    contentBlock: SingleImage;
}

const SingleImageBlock = ({ contentBlock }: Props) => (
    <Container>
        <Box color={Colors.darkBlue} my={4}>
            {contentBlock.sectionLabel && (
                <PageHeader text={contentBlock.sectionLabel} />
            )}
            <Box textAlign="center">
                <img
                    src={contentBlock.image}
                    style={{ maxHeight: "545px", maxWidth: "100%" }}
                    alt=""
                />
            </Box>
        </Box>
    </Container>
);

export default SingleImageBlock;
