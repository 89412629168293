import CloseIcon from "@material-ui/icons/Close";
import { Colors } from "../../../assets/Theme";
import classes from "./PageHeader.module.css";

const PageHeader = (props) => (
    <div
        className={`${classes.pageHeader} ${props.className}`}
        style={props.style}
    >
        <div className={classes.line}></div>
        {props.mainHeader ? (
            <h1 className={classes.text} style={props.textStyle}>
                {props.text}
            </h1>
        ) : (
            <span className={classes.text} style={props.textStyle}>
                {props.text}
            </span>
        )}

        <div className={classes.line}></div>
        {props.withClose && (
            <div
                style={{
                    ...styles.closeContainer,
                    backgroundColor: props.closeBackground,
                }}
                className={classes.closeContainer}
                onClick={props.closeFunction}
            >
                <CloseIcon style={styles.close} />
            </div>
        )}
    </div>
);

const styles = {
    close: {
        width: "3rem",
        height: "3rem",
        color: Colors.darkBlue,
    },
    closeContainer: {
        position: "absolute",
        top: "0.8rem",
        right: 0,
        paddingLeft: "1rem",
        paddingRight: "1.5rem",
    },
};

export default PageHeader;
