import cn from "classnames";
import classes from "./Panel.module.css";

const sizes = {
    lg: classes.lg,
};

const directions = {
    row: classes.row,
    column: classes.column,
};

const Panel = ({ size, children, style, className, direction = "column" }) => (
    <div
        className={cn(
            classes.panel,
            sizes?.[size],
            directions?.[direction],
            className
        )}
        style={style}
    >
        {children}
    </div>
);

export default Panel;
