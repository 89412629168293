import classNames from "classnames/bind";
import { useHumed } from "../../../../ui/hooks/useHumed";
import classes from "./ReservationButton.module.css";

interface Props {
    onClick: () => void;
    text: string;
    hidden: boolean;
    isToggle?: boolean;
}

const cx = classNames.bind(classes);

const ReservationButton = ({
    onClick,
    text,
    hidden,
    isToggle = false,
}: Props) => {
    const isHumed = useHumed();

    return (
        <div
            className={cx("button", {
                hidden: hidden,
                toggleButton: isToggle,
                humed: isHumed,
            })}
            onClick={onClick}
        >
            {text}
        </div>
    );
};

export default ReservationButton;
