import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import { convertDate } from "../../reservationUtilities";
import classes from "./DataForm.module.css";

const DataForm = forwardRef((props, ref) => {
    const [data, setData] = useState({
        age: null,
        gender: null,
        email: null,
        lastName: null,
        firstName: null,
        phone: null,
        birthPlace: null,
        birthDate: null,
        mothersName: null,
        tajNumber: null,
        idNumber: null,
        noTaj: null,
    });

    const onCheckBoxChange = (key, checked, value) => {
        if (checked) {
            setData({ ...data, [key]: value });
        } else {
            setData({ ...data, [key]: null });
        }
    };

    const onInputChange = (key, value) => {
        if (value) {
            setData({ ...data, [key]: value });
        } else {
            setData({ ...data, [key]: null });
        }
    };

    useEffect(() => {
        if (props.inputData) {
            setData(props.inputData);
        }
    }, [props.inputData]);

    useImperativeHandle(ref, () => ({ data }), [data]);

    return (
        <>
            <p className={classes.textLabel}>Kinek foglal?</p>
            <Checkbox
                label="Felnőtt"
                onChange={(checked) =>
                    onCheckBoxChange("age", checked, "adult")
                }
                checked={data.age === "adult"}
            />
            <Checkbox
                label="Gyermek"
                onChange={(checked) =>
                    onCheckBoxChange("age", checked, "child")
                }
                checked={data.age === "child"}
            />
            <p className={classes.textLabel}>Neme</p>
            <Checkbox
                label="Férfi"
                onChange={(checked) =>
                    onCheckBoxChange("gender", checked, "male")
                }
                checked={data.gender === "male"}
            />
            <Checkbox
                label="Nő"
                onChange={(checked) =>
                    onCheckBoxChange("gender", checked, "female")
                }
                checked={data.gender === "female"}
            />
            <p className={classes.textLabel}>Email cím</p>
            <Input
                defaultValue={data.email}
                onChange={(e) => onInputChange("email", e.target.value)}
            />

            <p className={classes.textLabel}>Vezetéknév</p>
            <Input
                defaultValue={data.lastName}
                onChange={(e) => onInputChange("lastName", e.target.value)}
            />

            <p className={classes.textLabel}>Keresztnév</p>
            <Input
                defaultValue={data.firstName}
                onChange={(e) => onInputChange("firstName", e.target.value)}
            />

            <p className={classes.textLabel}>Telefonszám</p>
            <Input
                type="phone"
                value={data.phone}
                onChange={(e) => onInputChange("phone", e)}
            />

            <p className={classes.textLabel}>Születési hely</p>
            <Input
                defaultValue={data.birthPlace}
                onChange={(e) => onInputChange("birthPlace", e.target.value)}
            />

            <p className={classes.textLabel} style={{ marginBottom: "0" }}>
                Születési idő
            </p>
            <CustomDatePicker
                style={{ marginBottom: "2rem" }}
                value={data.birthDate}
                onChange={(date) =>
                    setData({ ...data, birthDate: convertDate(date) })
                }
            />

            <p className={classes.textLabel}>Anyja neve</p>
            <Input
                defaultValue={data.mothersName}
                onChange={(e) => onInputChange("mothersName", e.target.value)}
            />

            {data.noTaj ? (
                <>
                    <p className={classes.textLabel}>
                        Személyi igazolvány szám
                    </p>
                    <p
                        className={classes.textLabel}
                        style={{ color: "#929EA7" }}
                    >
                        FONTOS! Annak a páciensnek a személyi igazolvány számát
                        írja be, aki az ellátást igénybe veszi.
                    </p>
                    <Input
                        style={{ marginBottom: "0" }}
                        key="idNum"
                        defaultValue={data.idNumber}
                        onChange={(e) =>
                            onInputChange("idNumber", e.target.value)
                        }
                    />
                </>
            ) : (
                <>
                    <p className={classes.textLabel}>TAJ szám</p>
                    <p
                        className={classes.textLabel}
                        style={{ color: "#929EA7" }}
                    >
                        FONTOS! Annak a páciensnek a tajszámát írja be, aki az
                        ellátást igénybe veszi.
                    </p>

                    <Input
                        type="taj"
                        style={{ marginBottom: "0" }}
                        key="tajNum"
                        defaultValue={data.tajNumber}
                        onChange={(e) =>
                            onInputChange("tajNumber", e.target.value)
                        }
                    />
                </>
            )}
            <Checkbox
                label="Nincs TAJ kártyája"
                checked={data.noTaj}
                onChange={(checked) => setData({ ...data, noTaj: checked })}
            />
        </>
    );
});

DataForm.displayName = "DataForm";

export default DataForm;
