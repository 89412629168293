import { Box } from "@material-ui/core";
import type { BlogItem } from "../../ui/models";
import { useResolution } from "../../ui/utils";
import { Colors } from "../../assets/Theme";

interface Props {
    blogItem: BlogItem;
}

const BlogItemComponent = ({ blogItem }: Props) => {
    const isMobile = useResolution("mobile");

    return (
        <Box px={2} py={2} mt={5} style={{ textTransform: "uppercase" }}>
            <Box height={isMobile ? "221px" : "186px"}>
                <img
                    style={{ objectFit: "cover" }}
                    src={blogItem.image}
                    height="100%"
                    width="100%"
                    alt=""
                />
            </Box>
            <Box mt={1} color={Colors.grey} fontSize="16px">
                {blogItem.category}
            </Box>
            <Box
                mt={1}
                color={Colors.darkBlue}
                fontSize="20px"
                maxHeight="108px"
                overflow="hidden"
            >
                {blogItem.title}
            </Box>
            <Box mt={1} color={Colors.grey} fontSize="16px">
                {blogItem.date}
            </Box>
        </Box>
    );
};

export default BlogItemComponent;
