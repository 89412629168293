import {
    Check as CheckIcon,
    InfoOutlined as InfoOutlinedIcon,
    NewReleases as NewReleasesIcon,
    Warning as WarningIcon,
} from "@material-ui/icons";
import cn from "classnames";
import classes from "./StatusBadge.module.css";

const variants = {
    1: {
        label: "Normál",
        variant: classes.ok,
        icon: <CheckIcon />,
    },
    2: {
        label: "Eltérés",
        variant: classes.warning,
        icon: <WarningIcon />,
    },
    3: {
        label: "Riasztás",
        variant: classes.alert,
        icon: <NewReleasesIcon />,
    },
    4: {
        label: "Info",
        variant: classes.info,
        icon: <InfoOutlinedIcon />,
    },
};

const StatusBadge = ({ level = 0, hasText = true }) => {
    if (!level) {
        return null;
    }

    return (
        <div
            className={cn(classes.badge, variants[level].variant, {
                [classes.badgeIcon]: !hasText,
            })}
        >
            {variants[level].icon} {hasText && variants[level].label}
        </div>
    );
};

export default StatusBadge;
