import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Colors, Fonts, Icons, Images } from "../../../assets/Theme";
import ServicePage from "../../../components/UI/ServicePage/ServicePage";
import CustomSlider from "../../../components/UI/CustomSlider/CustomSlider";
import { endLoading, get, post } from "../../../config/fetch";
import * as actions from "../../../store/actions/index";
import { formatNumber } from "../../../utility";
import {
    ecommerceAddCart,
    ecommerceProductDetail,
} from "../../../config/ecommerce";
import useTranslation from "../../../features/i18n/useTranslation";
import useAuth from "../../../features/auth/useAuth";
import useQuery from "../../../features/util/useQuery";

let price = null;
let subscription;

const Chat = () => {
    const navigate = useNavigate();
    const query = useQuery();
    const { isAuthenticated } = useAuth();
    const [packages, setPackages] = useState(null);
    const [people, setPeople] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(1);
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        if (isAuthenticated) {
            const promise1 = post(
                "api/v1/subscription/get-service-prices-by-user",
                { service: "CHAT" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });
            const promise2 = post(
                "api/v1/subscription/check-user-service-price",
                { service: "CHAT" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });
            Promise.all([promise1, promise2]).then((responses) => {
                endLoading();
                const response1 = responses[0];
                const response2 = responses[1];
                let _packages = processPackages(
                    response1.packages,
                    response1.subscription ? response1.subscription : 1,
                    response2.data
                );
                setPackages(_packages);
            });
        } else {
            post("api/v1/subscription/get-service-prices-by-user", {
                service: "CHAT",
            }).then((response) => {
                endLoading();
                if (response.data.responseCode === "OK") {
                    let _packages = processPackages(
                        response.data.packages,
                        response.data.subscription
                            ? response.data.subscription
                            : 1
                    );
                    setPackages(_packages);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        get("api/v1/medical/get-chat-doctors", false)
            .then((response) => setPeople(response.data.data))
            .catch((error) => console.error(error));
    }, []);

    const processPackages = (packages, subscribedIndex, dynamicData = null) => {
        setSelectedPackage(+subscribedIndex);
        const _packages = [];
        const transactionId = query.get("TransactionId");
        //Ha fizetésről tér vissza, ne fusson le mégegyszer
        if (!transactionId) {
            if (dynamicData) {
                ecommerceProductDetail(
                    "CHAT",
                    dynamicData.price,
                    dynamicData.userPackage
                );
                price = dynamicData.price;
                subscription = dynamicData.userPackage;
            } else {
                ecommerceProductDetail(
                    "CHAT",
                    packages[0].price,
                    subscribedIndex
                );
                price = packages[0].price;
                subscription = subscribedIndex;
            }
        }
        packages.forEach((p, i) => {
            if (i === 3) {
                _packages.push({
                    name: `${p.packageName} előfizetéssel`,
                    price: `Korlátlan`,
                });
            } else if (dynamicData && subscribedIndex - 1 == i) {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        dynamicData.price !== 0
                            ? `${formatNumber(dynamicData.price)} Ft`
                            : `Ingyenes`,
                    subtext:
                        dynamicData.price === 0 &&
                        dynamicData.remainingServices > 0
                            ? `Hátralévő: ${dynamicData.remainingServices}`
                            : dynamicData.isFirstPrice
                            ? "Első alkalommal"
                            : "",
                });
            } else {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        p.freePerMonth > 0
                            ? `${p.freePerMonth} ingyenes`
                            : `${formatNumber(p.price)} Ft`,
                    subtext:
                        p.freePerMonth > 0
                            ? `*utána ${formatNumber(p.price)} Ft /beszélgetés`
                            : "",
                });
            }
        });

        return _packages;
    };

    const onClickButton = () => {
        ecommerceAddCart("CHAT", price, subscription);
        navigate("/szolgaltatasok/orvosi-chat/start");
    };

    const onPackageClick = () => {
        if (isAuthenticated) {
            navigate(i18nRoute("routes.profile.paymentMethods"));
        } else {
            navigate("/elofizetesek");
        }
    };

    return (
        <ServicePage
            icon={Icons.messageExchangeGreen}
            name="Orvosi chat"
            description="Válasz szakorvostól, üzenet formájában 48 órán belül. 100% diszkréció: Kérdéseid kizárólag a kiválasztott szakorvos kapja meg, és az azokra adott választ is csak Te láthatod."
            image={Images.orvosiChat}
            imageAlt="Orvos válaszol, orvosi chat | T-DOC"
            packages={packages}
            selectedPackage={selectedPackage}
            originalSubscription={selectedPackage}
            packageClick={onPackageClick}
            showImportantInfo={false}
            buttonText="Üzenet küldése"
            buttonCallback={onClickButton}
            buttonDisabled={false}
        >
            <h1 style={{ ...styles.title, fontSize: "3rem" }}>
                Orvos válaszol – orvosi chat
            </h1>

            <h2 style={styles.title}>
                Milyen panaszok esetén hasznos az orvosi chat?
            </h2>
            <p style={styles.text}>
                Egy családon belül gyakran fordulnak elő olyan egészségügyi
                kérdések, melyek megválaszolása orvosi szakértelmet igényel.
                Amennyiben{" "}
                <span style={styles.strong}>
                    nem akut kérdésről van szó, hanem egy régóta húzódó
                    kellemetlen tünetről, ismert meglévő betegségről
                </span>
                , úgy a T-DOC{" "}
                <span style={styles.strong}>orvos válaszol funkció</span>, azaz
                az orvosi chat lesz a tökéletes megoldás.
                <br />
                <br />
                Az orvos válaszol funkció lehetőséget biztosít arra is, hogy
                üzeneted mellé egy fájlt csatolj az általad kiválasztott
                szakorvosnak, így{" "}
                <span style={styles.strong}>
                    friss diagnosztikai vizsgálatod, laboreredményed értékelését
                    is kérheted.
                </span>
                <br />
                <br />
                Amennyiben orvosod a személyes vizitet és vizsgálatot követően
                rendszeres gyógyszeres kezelést ír fel, úgy az{" "}
                <span style={styles.strong}>
                    online receptírásra is jó megoldás lehet az orvosi chat
                </span>
                . Ez azonban csak két esetben valósulhat meg. Az egyik az, ha a
                kezelést javasló orvosoddal veszed fel a kapcsolatot az orvos
                válaszol chat platformon keresztül. A másik eset az, ha a
                chatnél a kiválasztott orvosnak feltöltöd a gyógyszeres
                kezelésre vonatkozó javaslatot tartalmazó orvosi ambuláns lapot,
                mely nem régebbi 3 hónapnál.
            </p>

            <h2 style={styles.title}>
                Hogyan működik az orvos válaszol funkció?
            </h2>
            <p style={styles.text}>
                Az orvos válaszol funkció egy rövid konzultáció, ami egy konkrét
                probléma vagy kérdés kapcsán nyújt megoldást.
                <br />
                <br />
                A beküldött kérdéseidre 48 órán belül személyre szabott választ
                kapsz egy üzenetben. Nekünk fontos a diszkréció, ezért az
                üzenetet csak Te és az orvosod olvashatjátok el.
                <br />
                <br />
                Az orvosi chat során{" "}
                <span style={styles.strong}>
                    tetszőleges hosszúságú üzenetet írhatsz
                </span>{" "}
                a kiválasztott szakorvosunknak. Az üzenet mellé{" "}
                <span style={styles.strong}>
                    lehetőséged van 1 db fájl feltöltésére is
                </span>
                . Az elküldött üzeneteket, valamint a beérkező üzeneteket is
                saját fiókodban éred el. Az orvos által küldött üzenetekről
                értesítést kapsz fiókodban, valamint a megadott e-mail címeden
                e-mail formájában is értesítünk róla.
            </p>
            <h2 style={styles.title}>Mi minősül orvosi chatnek?</h2>
            <p style={styles.text}>
                Fontos tudnod, hogy az orvos válaszol - orvosi chat nem egyenlő
                a másodvéleménnyel. Ezen szolgáltatás keretein belül orvosunktól
                egészségügyi problémáddal kapcsolatos kérdéseket tehetsz fel,
                melyet készségesen megválaszolunk.
                <br />
                <br />
                Amennyiben orvosod által felállított diagnózisról szeretnél egy
                ellenőrző véleményt, vagy éppen műtét előtt állsz, de szeretnél
                egy másik szaktekintélytől állásfoglalást kérni mielőtt
                megtörténik a beavatkozás, akkor válaszd Másodvélemény
                szolgáltatásunkat.
                <br />
                <br />
                Ha kérdésed mégis a meglévő diagnózis, leletek kiértékelésére
                irányul, és szakorvosunknak több oldalnyi ambuláns lapot kell
                átolvasnia, ez esetben a kiértékelés csak akkor történik meg, ha
                elfogadod az orvosi chat szolgáltatásod másodvéleménnyé való
                átalakítását. Természetesen ez nem egy automatikus folyamat,
                ehhez a Te beleegyezésedre van szükség. További részletekért
                olvad el az ÁSZF-ünket!
            </p>
            <h2 style={styles.title}>Orvosi csapatunk</h2>
            <p style={{ ...styles.text, marginBottom: "2rem" }}>
                Orvosi csapatunk minden egyes tagja szakterületének
                specialistája, így szinte minden témában választ fogunk tudni
                adni felmerülő egészségügyi kérdéseidre. Fordulj hozzánk
                bizalommal!
            </p>
            <CustomSlider data={people} people />
        </ServicePage>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontWeight: 700,
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
        marginTop: "3rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
        marginBottom: "1rem",
    },
    strong: {
        fontWeight: 700,
    },
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
});

export default connect(null, mapDispatchToProps)(Chat);
