import * as actionTypes from "../actions/actionTypes";
import { packagePrices } from "../../config/variables";

const initialState = {
    loginModal: false,
    registrationModal: false,
    showResponseModal: false,
    responseModal: {
        title: null,
        content: null,
        confirmButton: true,
        confirmButtonText: "Rendben",
        confirmButtonCallback: null,
        cancelButton: false,
        cancelButtonText: "Mégsem",
        cancelButtonCallback: null,
        disableBackdropClick: false,
    },
    showNotifications: false,
    servicePaymentModal: {
        show: false,
        price: 11990,
        service: "CHAT",
        preScript: null,
        packageId: null,
        serviceText: null,
    },
    showQualificationsModal: false,
    yellowBar: {
        visitor: {
            text: null,
            linkText: null,
            target: null,
            show: false,
        },
        loggedIn: {
            text: null,
            linkText: null,
            target: null,
            show: false,
        },
        subscriber: {
            text: null,
            linkText: null,
            target: null,
            show: false,
        },
    },
    showHamburger: false,
    showUserMenu: false,
    showSubscriptionAndServiceModal: 0, //a komponensen belül két modal van
    subscriptionAndServiceModal: {
        packagePrices,
        servicePrices: [],
        freeOccasions: null,
        service: null,
    },
    subscriptionDetails: false,
};

export const toggleResponseModal = (state, action) => ({
    ...state,
    showResponseModal: action.show,
    responseModal: {
        ...{
            title: null,
            content: null,
            confirmButton: true,
            confirmButtonText: "Rendben",
            confirmButtonCallback: null,
            cancelButton: false,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        },
        ...action.responseModal,
    },
});

export const setCardName = (state, action) => ({
    ...state,
    cardName: action.cardName,
});

export const toggleLoginModal = (state, action) => ({
    ...state,
    loginModal: action.show,
});

export const toggleRegistrationModal = (state, action) => ({
    ...state,
    registrationModal: action.show,
});

export const toggleNotifications = (state, action) => ({
    ...state,
    showNotifications: action.show,
});

export const toggleServicePaymentModal = (state, action) => ({
    ...state,
    servicePaymentModal: {
        show: action.show,
        price: action.price,
        service: action.service,
        preScript: action.preScript,
        identifier: action.identifier,
        packageId: action.packageId,
        serviceText: action.serviceText,
        appointmentData: action.appointmentData,
    },
});

const toggleQualificationsModal = (state, action) => ({
    ...state,
    showQualificationsModal: action.show,
});

const setYellowBar = (state, action) => ({
    ...state,
    yellowBar: action.yellowBar,
});

const toggleHamburger = (state, action) => ({
    ...state,
    showHamburger: action.show,
});

const toggleUserMenu = (state, action) => ({
    ...state,
    showUserMenu: action.show,
});

const toggleSubscriptionAndServiceModal = (state, action) => ({
    ...state,
    showSubscriptionAndServiceModal: action.show,
});

const setSubscriptionAndServiceModal = (state, action) => ({
    ...state,
    subscriptionAndServiceModal: {
        service: action.service,
        packagePrices: action.packagePrices,
        freeOccasions: action.freeOccasions,
        servicePrices: action.servicePrices,
    },
});

export const toggleSubscriptionDetails = (state, action) => ({
    ...state,
    subscriptionDetails: action.show,
});

const reducer = (state = initialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case actionTypes.TOGGLE_RESPONSE_MODAL:
            return toggleResponseModal(state, action);
        case actionTypes.SET_CARD_NAME:
            return setCardName(state, action);
        case actionTypes.TOGGLE_LOGIN_MODAL:
            return toggleLoginModal(state, action);
        case actionTypes.TOGGLE_REGISTRATION_MODAL:
            return toggleRegistrationModal(state, action);
        case actionTypes.TOGGLE_NOTIFICATIONS:
            return toggleNotifications(state, action);
        case actionTypes.TOGGLE_SERVICE_PAYMENT_MODAL:
            return toggleServicePaymentModal(state, action);
        case actionTypes.TOGGLE_QUALIFICATIONS_MODAL:
            return toggleQualificationsModal(state, action);
        case actionTypes.SET_YELLOW_BAR:
            return setYellowBar(state, action);
        case actionTypes.TOGGLE_HAMBURGER:
            return toggleHamburger(state, action);
        case actionTypes.TOGGLE_USERMENU:
            return toggleUserMenu(state, action);
        case actionTypes.TOGGLE_SUBSCRIPTION_AND_SERVICE_MODAL:
            return toggleSubscriptionAndServiceModal(state, action);
        case actionTypes.SET_SUBSCRIPTION_AND_SERVICE_MODAL:
            return setSubscriptionAndServiceModal(state, action);
        case actionTypes.TOGGLE_SUBSCRIPTION_DETAILS:
            return toggleSubscriptionDetails(state, action);
    }

    return state;
};

export default reducer;
