import { Link } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {
    Chat,
    EventSchedule,
    IbmTelehealth,
    Location,
    Phone,
    ReportData,
} from "@carbon/icons-react";
//import cn from "classnames";
import useTranslation from "../../../features/i18n/useTranslation";
import classes from "./ServiceCard.module.css";
//import { formatNumber } from "../../../utility";

const services = {
    // Remote Service packages
    hotline: {
        icon: <Phone size="24" />,
        name: "Egészségügyi hotline",
        text: "Egészséggel és a szolgáltatásokkal kapcsolato információk. Minden nap, 8:00-20:00 között.",
        price: {
            text: "Korlátlan",
            isHighlighted: true,
        },
        path: "routes.services.hotline",
    },
    doctorOnCall: {
        icon: <Phone size="24" />,
        name: "Általános orvosi konzultáció",
        text: "Közvetlen orvosi kapcsolat egészségügyi panasz esetén. Minden nap, 7/24.",
        price: {
            text: "14 900 Ft",
            isHighlighted: false,
        },
        path: "routes.services.duty",
    },
    chat: {
        icon: <Chat size="24" />,
        name: "Orvosi chat",
        text: "Válasz szakorvostól, üzenet formájában 48 órán belül. 100% diszkréció.",
        price: {
            text: "Az első ingyenes",
            isHighlighted: true,
        },
        path: "routes.services.chat",
    },
    secondOpinion: {
        icon: <Chat size="24" />,
        name: "Másodvélemény",
        text: "Ha bizonytalan vagy a diagnózisban, esetleg megerősítést szeretnél kérni, kérd szakorvosaink másodvéleményét.",
        price: {
            text: "3 990 Ft",
            isHighlighted: false,
        },
        path: "routes.services.secondOpinion",
    },
    ePrescription: {
        icon: <Chat size="24" />,
        name: "E-recept",
        text: "Kérd gyógyszereid egyszerűen, otthonod kényelméből.",
        price: {
            text: "3 990 Ft",
            isHighlighted: false,
        },
        path: "routes.services.prescription",
    },
    videoConsultation: {
        icon: <IbmTelehealth size="24" />,
        name: "Videókonzultáció",
        text: "Időpontfoglalás szakorvosi videókonzultációra időben és térben hozzád igazítva.",
        price: {
            text: "19 900 Ft-tól",
            isHighlighted: false,
        },
        path: "routes.services.videoConsultation",
    },
    outPatient: {
        icon: <EventSchedule size="24" />,
        name: "Járóbeteg ellátás",
        text: "Járóbetegellátásra történő online időpontfoglalás Magyarország legjobb magánegészségügyi intézményeibe.",
        price: {
            text: "19 900 Ft-tól",
            isHighlighted: false,
        },
        path: "routes.services.outPatient",
    },
    dutyFinder: {
        icon: <Location size="24" />,
        name: "Ügyeletkereső",
        text: "Magyarország sürgősségi ellátásainak valós idejű térképe",
        price: {
            text: "Díjmentes",
            isHighlighted: true,
        },
        path: "routes.dutyFinder",
    },

    // Data as a Service packages
    preop: {
        icon: <ReportData size="24" />,
        name: "PreOp csomag",
        text: "A csomag segít a pácienseknek megőrizni egészségük stabilitását műtéti beavatkozások előtt és után",
        price: {
            text: "9 900 Ft",
            isHighlighted: false,
        },
        path: null,
    },
    manager: {
        icon: <ReportData size="24" />,
        name: "Manager csomag",
        text: "A csomagot azoknak ajánljuk, akik magasfokú stressznek vannak kitéve, ami veszélyezteti egészségi állapotukat",
        price: {
            text: "29 900 Ft",
            isHighlighted: false,
        },
        path: null,
    },
    diabetes: {
        icon: <ReportData size="24" />,
        name: "Diabétesz csomag",
        text: "A csomag biztonságos megoldást jelent, cukorbetegséggel küzdő páciensek számára",
        price: {
            text: "4 900 Ft / hónap",
            isHighlighted: false,
        },
        path: null,
    },
};

const ServiceCard = (props) => {
    const navigate = useNavigate();
    const { i18nRoute } = useTranslation();

    const handleRoute = (path) => {
        navigate(i18nRoute(path));
    };

    return (
        <>
            <aside className={classes.service}>
                <header>
                    <div className={classes.icon}>
                        {services[props.type]?.icon}
                    </div>
                    <h3 className={classes.name}>
                        {services[props.type]?.name}
                    </h3>
                </header>
                <p className={classes.text}>{services[props.type]?.text}</p>

                <footer>
                    {/* {services[props.type]?.price && (
                        <div
                            className={cn(classes.price, {
                                [classes.highlighted]:
                                    services[props.type].price.isHighlighted,
                            })}
                        >
                            {formatNumber(services[props.type].price.text)}
                        </div>
                    )} */}

                    <Link
                        component="button"
                        onClick={() => handleRoute(services[props.type].path)}
                        className={classes.link}
                        disabled={!services[props.type].path}
                    >
                        Kiválaszt
                    </Link>
                </footer>
            </aside>
        </>
    );
};

export default ServiceCard;
