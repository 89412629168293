import cn from "classnames";
import classes from "./Input.module.css";

const Input = ({
    name,
    value,
    type = "text",
    className,
    bigger,
    style,
    placeholder,
    prefix,
    suffix,
    disabled,
    onChange,
    required,
}) => {
    const handleChange = (e) => {
        onChange?.(e);
    };

    return (
        <div className={cn(classes.inputWrapper, className)}>
            {prefix && <div>{prefix}</div>}
            <input
                name={name}
                value={value}
                className={cn(classes.input, {
                    [classes.bigger]: bigger,
                })}
                style={style}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleChange}
                required={required}
                autoComplete="off"
            />
            {suffix && <div>{suffix}</div>}
        </div>
    );
};

export default Input;
