// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Container } from "@material-ui/core";
// refacted layout UI elements to display Paperish design
import InnerLayout from "../templates/InnerLayout";
import Paper from "../atoms/Paper";
import Breadcrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Headline from "../atoms/Headline";
import { endLoading, get } from "../../../config/fetch";
import HeaderRow from "../organisms/PrescriptionRedeem/HeaderRow";
import RedeemRow from "../organisms/PrescriptionRedeem/RedeemRow";
import classes from "./Webshop.module.css";
import "../../../components/Facelift/typo.css";
import { useEffect, useState } from "react";

const PrescriptionRedeems = () => {
    const [productsQuery, setProductsQuery] = useState({
        isLoading: true,
        data: null,
    });
    const [redeemsQuery, setRedeemsQuery] = useState({
        isLoading: true,
        data: null,
    });

    const [isOpenedRow, setIsOpenedRow] = useState([]);

    useEffect(() => {
        getProducts();
        getRedeems();
    }, []);

    // It is not the fastest solution to query the products for each page reload, but this way we can be sure that we are
    // in sync with the piq products.
    const getProducts = () => {
        get("api/webshop/products")
            .then((response) => {
                switch (response.status) {
                    case 200:
                        setProductsQuery({
                            isLoading: false,
                            data: response.data,
                        });

                        break;

                    default:
                        console.error(response.data);
                        setProductsQuery({
                            isLoading: false,
                            data: null,
                        });
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                setProductsQuery({
                    isLoading: false,
                    data: null,
                });
                endLoading();
            });
    };

    const getRedeems = () => {
        get("api/webshop/prescription-redeem")
            .then((response) => {
                switch (response.status) {
                    case 200:
                        setRedeemsQuery({
                            isLoading: false,
                            data: response.data,
                        });
                        if (response.data.length > 0) {
                            const arr = new Array(response.data.length).fill(
                                false
                            );
                            arr[0] = true;
                            setIsOpenedRow(arr);
                        }

                        break;

                    default:
                        console.error(response.data);
                        setRedeemsQuery({
                            isLoading: false,
                            data: null,
                        });
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                setRedeemsQuery({
                    isLoading: false,
                    data: null,
                });
                endLoading();
            });
    };

    return (
        <div className={classes.intro}>
            <Container>
                <InnerLayout>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 12,
                        }}
                    >
                        <Paper>
                            <Headline>
                                <Breadcrumbs />
                            </Headline>
                            <div>
                                {(productsQuery.isLoading ||
                                    redeemsQuery.isLoading) && (
                                    <div
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            gap: 12,
                                            fontSize: "20px",
                                        }}
                                    >
                                        Igények betöltése...
                                    </div>
                                )}
                                {!(
                                    productsQuery.isLoading ||
                                    redeemsQuery.isLoading
                                ) && (
                                    <div
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                            display: "flex",
                                            justifyContent: "center",
                                            justifyItems: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <HeaderRow />
                                        {!redeemsQuery.data && (
                                            <div
                                                style={{
                                                    marginTop: "20px",
                                                    marginBottom: "20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "row",
                                                    gap: 12,
                                                    fontSize: "20px",
                                                }}
                                            >
                                                Nincs igényelt gyógyszered
                                            </div>
                                        )}
                                        {redeemsQuery.data &&
                                            redeemsQuery.data.map(
                                                (redeem, idx) => (
                                                    <RedeemRow
                                                        key={idx}
                                                        redeem={redeem}
                                                        products={
                                                            productsQuery.data
                                                        }
                                                        isOpened={
                                                            isOpenedRow[idx]
                                                        }
                                                        onRowClicked={() => {
                                                            setIsOpenedRow(
                                                                isOpenedRow.map(
                                                                    (
                                                                        isOpened,
                                                                        index
                                                                    ) =>
                                                                        index ===
                                                                        idx
                                                                            ? !isOpened
                                                                            : false
                                                                )
                                                            );
                                                        }}
                                                    />
                                                )
                                            )}
                                    </div>
                                )}
                            </div>
                        </Paper>
                    </div>
                </InnerLayout>
            </Container>
        </div>
    );
};

export default PrescriptionRedeems;
