import type { ReactNode } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "../../store";
import { getStorageValue } from "../util/storage";
import useQuery from "../util/useQuery";
import { login } from "./slice";
import { useHumedLoginMutation, useLogoutMutation } from "./api";
import useAuth from "./useAuth";

interface Props {
    children: ReactNode;
}

const AuthWrapper = ({ children }: Props) => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { isLoading } = useAuth(); // TODO: This is weirdly not the auth loading state
    const [logout] = useLogoutMutation();
    const [humedLogin] = useHumedLoginMutation();
    const navigate = useNavigate();

    // Load from local/session storage
    useEffect(() => {
        // HuMed login from query string
        const humedUserId = query.get("userId");
        const humedSignature = query.get("signature");
        if (humedUserId && humedSignature) {
            humedLogin({
                userId: parseInt(humedUserId),
                signature: humedSignature,
            });

            return;
        }

        // Load from storage to redux
        const token = getStorageValue<string>("token", "both");
        const userId = getStorageValue<number>("userId", "both");

        if (token && userId) {
            dispatch(login({ userId, token, storeIn: "none" }));

            return;
        }

        logout();
    }, [dispatch, humedLogin, logout, query, navigate]);

    if (isLoading) {
        return null;
    }

    return <>{children}</>;
};

export default AuthWrapper;
