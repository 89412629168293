import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useGetDescription, useGetTitle } from "./helmet.js";

const HelmetHandler = () => {
    let location = useLocation();
    const title = useGetTitle(location.pathname);
    const description = useGetDescription(location.pathname);

    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
        </Helmet>
    );
};

export default HelmetHandler;
