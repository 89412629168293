import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { endLoading, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { useLogoutMutation } from "../../features/auth/api";
import useQuery from "../../features/util/useQuery";
import classes from "./ForgotPassword.module.css";

const ForgotPassword = () => {
    const query = useQuery();
    const [generated, setGenerated] = useState(false);
    const [logout] = useLogoutMutation();

    useEffect(() => {
        logout();
    }, [logout]);

    useEffect(() => {
        const hash = query.get("hash");
        if (hash) {
            post("auth/generate-password", { hash })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            setGenerated(true);

                            break;
                        default:
                            break;
                    }
                    endLoading();
                })
                .catch(() => {
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                    endLoading();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.ForgotPassword}>
            <Container>
                <div className={classes.ForgotPasswordInner}>
                    {generated && (
                        <>
                            <h3>Elfelejtett jelszó</h3>
                            <p>Kedves Felhasználó!</p>
                            <p>
                                A felhasználói profiljához új jelszót
                                generáltunk, melyet elküldtünk a megadott email
                                címére.
                            </p>
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default ForgotPassword;
