import type { MouseEvent } from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import Topics from "./Topics";
import BlogItems from "./BlogItems";
import { mockBlogs, mockKeyWords } from "./mock";

const Blog = () => {
    const [selectedTopic, setSelectedTopic] = useState<string | null>(null);

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (selectedTopic === null) {
            setSelectedTopic((e.target as HTMLButtonElement).value);
        } else setSelectedTopic(null);
    };

    return (
        <Container>
            <Topics
                selectedTopic={selectedTopic}
                topics={mockKeyWords}
                blogs={mockBlogs}
                onClick={handleClick}
            />
            <BlogItems selectedTopic={selectedTopic} blogs={mockBlogs} />
        </Container>
    );
};

export default Blog;
