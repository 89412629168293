import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { supportedLngsAsString } from "../../../features/i18n/i18n";
import classes from "./Breadcrumbs.module.css";

const Breadcrumbs = (props) => {
    const location = useLocation();
    const [tree, setTree] = useState(props.tree || []);

    let nameUrl = (url) => {
        if (props.replaceKeys && url in props.replaceKeys) {
            return props.replaceKeys[url];
        }
        switch (url) {
            case "szolgaltatasok":
                return "Szolgáltatásaink";
            case "hotline":
                return "Egészségügyi hotline";
            case "orvosi-ugyelet":
                return "Általános orvosi konzultáció";
            case "orvosi-chat":
                return "Üzenetváltás";
            case "e-recept":
                return "E-recept";
            case "e-masodvelemeny":
            case "masodvelemeny":
                return "Másodvélemény";
            case "videokonzultacio":
                return "Videókonzultáció";
            case "idopontfoglalas":
                return "Járóbeteg ellátás";
            case "start":
                return "Üzenet küldése";
            case "history":
                return "Előzmények";
            case "ugyeletkereso":
                return "Ügyeletkereső";
            case "orvosaink":
                return "Orvosaink";
            case "doctor":
                return "Orvos";
            case "partner-intezmenyek":
                return "Partner intézmények";
            case "institution":
                return "Intézmény";
            case "elofizetesek":
                return "Előfizetések";
            case "hogyan-mukodik":
                return "Hogyan működik?";
            case "rolunk":
                return "Rólunk";
            case "sugokozpont":
                return "Súgóközpont";
            case "fizetes":
                return "Fizetés, számlázás";
            case "technikai-hatter":
                return "Technikai háttér";
            case "gdpr":
                return "GDPR";
            case "regisztracio":
                return "Regisztráció";
            case "covid-hotline":
                return "COVID Hotline";
            case "covid-ugyelet":
                return "COVID Ügyelet";
            case "covid-chat":
                return "COVID Chat";
            case "szakteruletek":
                return "Szakterületek";
            case "test":
                return "Teszt";
            case "profile":
                return "Saját profil";
            case "gyogyszer-kiszallitas":
                return "Gyógyszer kiszállítás";
            case "igenyek":
                return "Igények";
            // case 'events':
            case "elozmenyek":
                return "Értesítések";
            case "uzenetek":
                return "Üzenetek";
            case "idopontok":
                return "Időpontok";
            case "security":
                return "Bejelentkezés és biztonság";
            case "payment-methods":
                return "Fizetési beállítások";
            case "user":
                return "Személyes adatok";
            case "invite":
                return "Barátok meghívása";
            case "notifications":
                return "Értesítések";
            case "aszf":
                return "ÁSZF";
            case "adatvedelmi_nyilatkozat":
                return "Adatvédelmi nyilatkozat";
            case "analize":
                return "Analízis központ";
            default:
                return url;
        }
    };

    useEffect(() => {
        if (!props.tree) {
            const stepsWithLng = location.pathname.split("/");
            const steps = stepsWithLng.filter(
                (item) => !supportedLngsAsString.includes(item)
            );
            let tree = [];
            if (steps[1] !== "profile") {
                // tree = [{ name: "Kezdőlap", url: "/" }];
                tree = [];
            }
            let currentLink = "";
            if (!props.customTree) {
                for (let s of steps) {
                    if (s) {
                        tree.push({
                            name: nameUrl(s),
                            url: (currentLink += "/" + s),
                        });
                    }
                }
            } else {
                for (let cT of props.customTree) {
                    let targetURL = cT.url;
                    if (targetURL === "current") {
                        targetURL = location.pathname + location.search;
                    }
                    tree.push({
                        name: cT.name,
                        url: targetURL,
                    });
                }
            }

            setTree(tree);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, props.replaceKeys]);

    const renderTree = () =>
        tree.map((item, index) => {
            if (index === 0) {
                return (
                    <Link className={classes.link} to={item.url} key={item.url}>
                        {item.name}
                    </Link>
                );
            }
            if (item.name) {
                return (
                    <div key={item.url} className={classes.element}>
                        <div className={classes.slash}>/</div>
                        <Link
                            className={classes.link}
                            to={item.url}
                            key={item.url}
                        >
                            {item.name}
                        </Link>
                    </div>
                );
            }

            return "";
        });

    if (props.steps) {
        return null;
    }

    return (
        <div className={classes.Breadcrumbs} style={props.style}>
            {renderTree()}
        </div>
    );
};

export default Breadcrumbs;
