import { Box, Container, Grid } from "@material-ui/core";
import BtnWithIcon from "../../../../components/UI/Btn/BtnWithIcon";
import { useResolution } from "../../../../ui/utils";
import type { ImageWithText } from "../../../../ui/models";
import DangerousHtml from "../../../../ui/layout/DangerousHtml";
import { Colors } from "../../../../assets/Theme";
import PageHeader from "../../../../ui/layout/PageHeader";

interface Props {
    contentBlock: ImageWithText;
}

const PictureWithTextBlock = ({ contentBlock }: Props) => {
    const { image, text, url } = contentBlock;

    const isTablet = useResolution("tablet");

    return (
        <Container>
            <Box color={Colors.darkBlue} my={4}>
                {contentBlock.sectionLabel && (
                    <PageHeader text={contentBlock.sectionLabel} />
                )}
                <Grid spacing={10} container>
                    <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box textAlign="center" width="486px">
                            <img src={image} width="100%" alt="" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box maxWidth={isTablet ? "100%" : "385px"}>
                            <Box>
                                <DangerousHtml>{text}</DangerousHtml>
                            </Box>
                            <Box pt={4}>
                                <BtnWithIcon
                                    style={{ width: "100%" }}
                                    color="transparent"
                                    text="Tovább a tartalomra"
                                    onClick={() => (window.location.href = url)}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default PictureWithTextBlock;
