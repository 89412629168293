import { Colors } from "../../../assets/Theme";
import classes from "./Btn.module.css";

const getStyle = (color) => {
    switch (color) {
        case "dark-blue":
            return classes.darkBlue;
        case "green":
            return classes.green;
        case "yellow":
            return classes.yellow;
        case "transparent":
            return classes.transparent;
        case "transparent-green":
            return classes.transparentGreen;
        case "transparent-red":
            return classes.transparentRed;
        case "white-green":
            return classes.whiteGreen;
        default:
            return classes.green;
    }
};

const Btn = (props) => (
    <div
        className={`${classes.Btn} ${getStyle(props.color)} ${
            props.className ? props.className : ""
        }`}
        style={
            props.disabled
                ? { ...styles.disabled, ...props.style }
                : props.style
        }
        onClick={props.onClick}
    >
        {props.text || props.children}
    </div>
);

const styles = {
    disabled: {
        borderColor: Colors.grey,
        color: Colors.grey,
        pointerEvents: "none",
    },
};

export default Btn;
