import { Container } from "@material-ui/core";
import { connect } from "react-redux";
import Card from "../../components/UI/Card/Card";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import * as actions from "../../store/actions/index";
import useTranslation from "../../features/i18n/useTranslation";
import classes from "./CookieSettings.module.css";

const CookieSettings = (props) => {
    const { i18nRoute } = useTranslation();
    const setAnaliticsCookie = (checked) =>
        props.setCookie("analitics", checked);

    return (
        <div className={classes.CookieSettings}>
            <Container>
                <div className={classes.CookieConsentInner}>
                    <div className={classes.textCol}>
                        <p className={classes.title}>
                            Ez a weboldal sütiket használ
                        </p>
                    </div>
                </div>
                <Card className={classes.card}>
                    <p>
                        Ez a weboldal a jobb működés és a felhasználói élmény
                        javítása érdekében HTTP-sütiket(Cookie) használ.
                        Amennyiben nem fogad el minden sütit, az oldal egyes
                        pontjai nem biztos, hogy megfelelően fognak működni.
                        Arról, hogy mik azok a sütik, itt olvashat
                        részletesebben. Azt, hogy a weboldal pontosan milyen
                        sütiket használ, részletesen megismerheti{" "}
                        <a href={i18nRoute("routes.privacy")}>
                            Adatvédelmi Tájékozatónkból.
                        </a>
                    </p>
                    <div className={classes.line}></div>
                    <div className={classes.checkboxContainer}>
                        <Checkbox
                            checked
                            disabled
                            style={{ marginBottom: "1rem", opacity: 0.6 }}
                            label="Szükséges cookiek"
                        />
                        <Checkbox
                            checked={props.cookies.analitics}
                            style={{ marginBottom: "1rem" }}
                            onChange={(checked) => setAnaliticsCookie(checked)}
                            label="Analitikai cookiek"
                        />
                    </div>
                    <p className={classes.subtitle}>Szükséges cookiek</p>
                    <p>
                        Ezek a cookie-k alapvető funkciókat aktiválnak, így a
                        weboldal megfelelő működéséhez szükségesek. Ezen
                        cookie-k nélkül a weboldal nem tud helyesen működni.
                    </p>
                    <p className={classes.subtitle}>Analitikai cookiek</p>
                    <p>
                        A statisztikai cookie-k visszajelzést nyújtanak nekünk,
                        hogy átlássuk, a látogatók milyen tartalmat kedvelnek a
                        weboldalon, így ezeket az információkat felhasználva
                        továbbfejleszthetők szolgáltatásaink. Az adatok nem
                        kapcsolódnak konkrét személyhez
                    </p>
                    <p>
                        A cookie beállításokat vármikor megváltoztathatja a
                        büöngészőjében (Firefox, Safari, Internet Explorer,
                        Microsof Edge) is.
                    </p>
                </Card>
            </Container>
        </div>
    );
};
const mapStateToProps = (state) => ({
    cookies: state.cookie,
});

const mapDispatchToProps = (dispatch) => ({
    setCookie: (cookie, enabled) =>
        dispatch(actions.setCookie(cookie, enabled)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieSettings);
