import { Container } from "@material-ui/core";
import { Images } from "../../../assets/Theme";
import EU from "../../../assets/tdoc_service_eu.pdf";
import FooterPaymentElement from "./FooterPaymentElement/FooterPaymentElement";
import classes from "./FooterPayment.module.css";

const footerPayment = () => (
    <div className={classes.FooterPayment}>
        <Container className={classes.FooterPaymentContainer}>
            <ul className={classes.FooterPaymentInner}>
                <FooterPaymentElement>
                    Pénzügyi partnereink:
                </FooterPaymentElement>
                <div className={classes.icons}>
                    <FooterPaymentElement className={classes.paymentService}>
                        <img
                            alt="Simple Pay"
                            src={Images.simple}
                            className={classes.FooterPaymentImage}
                        />
                    </FooterPaymentElement>
                    <FooterPaymentElement className={classes.paymentService}>
                        <a
                            href="https://www.paymentgateway.hu/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                alt="Big Fish Payment Gateway"
                                src={Images.bigfish}
                                className={classes.FooterPaymentImage}
                            />
                        </a>
                    </FooterPaymentElement>
                    {window.innerWidth <= 500 ? (
                        <div className={classes.linebreak}></div>
                    ) : (
                        ""
                    )}
                    <FooterPaymentElement>
                        <img
                            alt="Mastercard"
                            src={Images.mastercard}
                            className={classes.FooterPaymentImageSmaller}
                        />
                    </FooterPaymentElement>
                    <FooterPaymentElement>
                        <img
                            alt="Maestro"
                            src={Images.maestro}
                            className={classes.FooterPaymentImageSmaller}
                        />
                    </FooterPaymentElement>
                    <FooterPaymentElement>
                        <img
                            alt="Visa"
                            src={Images.visa}
                            className={classes.FooterPaymentImageSmaller}
                        />
                    </FooterPaymentElement>
                    <FooterPaymentElement>
                        <img
                            alt="Visa"
                            src={Images.visa2}
                            className={classes.FooterPaymentImageSmaller}
                        />
                    </FooterPaymentElement>
                    <FooterPaymentElement>
                        <img
                            alt="American Express"
                            src={Images.americanExpress}
                            className={classes.FooterPaymentImageSmaller}
                        />
                    </FooterPaymentElement>
                </div>
                <a href={EU} download="tdoc_service_eu.pdf" style={styles.link}>
                    <img
                        src={Images.euLogo}
                        style={styles.logo}
                        alt="T-DOC EU service PDF fájl letöltése"
                    />
                </a>
            </ul>
        </Container>
    </div>
);

const styles = {
    link: {
        display: "block",
        marginLeft: "auto",
    },
    logo: {
        width: "30rem",
        maxWidth: "100%",
    },
};
export default footerPayment;
