import { Grid } from "@material-ui/core";
import Tag from "../atoms/Tag";
import StatusBadge from "../atoms/StatusBadge";
import cn from "classnames";
import classes from "./EventRow.module.css";

const alertLevels = {
    normal: { level: 1, label: "Értesítés" },
    warning: { level: 2, label: "Figyelmeztetés" },
    alert: { level: 3, label: "Riasztás!" },
};

const EventRowAlert = ({ alertLevel, subject, content }) => (
    <Grid
        container
        className={cn(classes.row, {
            [classes.unread]: false, // isUnread check needed
        })}
    >
        <Grid item xs={12}>
            <div className={classes.innerRow}>
                <div className={classes.avatar}>
                    <StatusBadge
                        size="xxl"
                        level={alertLevels[alertLevel].level}
                        hasText={false}
                    />
                </div>
                <div className={classes.content}>
                    <div className={classes.bar}>
                        <div className={classes.info}>
                            <h3 className={classes.title}>
                                {alertLevels[alertLevel].label}
                            </h3>
                            {/* <div className={classes.date}>
                                <p>2022. szeptember 3. 12:34</p>
                            </div> */}
                            <div className={classes.indicators}>
                                <div className={classes.tag}>
                                    <Tag variant="outlined">{subject}</Tag>
                                </div>
                                <div className={classes.badge}>
                                    <StatusBadge size="xl" level={3} />
                                </div>
                            </div>
                        </div>
                        {/*  <div className={classes.date}>
                            <p>2022. szeptember 3. 12:34</p>
                        </div> */}
                    </div>

                    {subject && (
                        <div className={classes.text}>
                            <p>{content}</p>
                        </div>
                    )}
                </div>
            </div>
        </Grid>
    </Grid>
);

export default EventRowAlert;
