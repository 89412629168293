import { Container, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import huLocale from "date-fns/locale/hu";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import CardContainer from "../../../components/UI/CardContainer/CardContainer";
import { Icons } from "../../../assets/Theme";
import Input from "../../../components/UI/Input/Input";
import SaveButton from "../../../components/UI/SaveButton/SaveButton";
import CustomSelect from "../../../components/UI/CustomSelect/CustomSelect";
import CountryPicker from "../../../components/UI/CountryPicker/CountryPicker";
import { convertDate, responseMessage } from "../../../utility";
import { endLoading, get, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import UserSettingsRow from "./UserSettingRow/UserSettingRow";
import classes from "./UserSettings.module.css";

const UserSettings = () => {
    const [userDatas, setUserDatas] = useState({
        name: null,
        gender: null,
        birthDate: null,
        birthPlace: null,
        securityNumber: null,
        idNumber: null,
        noTAJ: false,
        mothersName: null,
        email: null,
        emailVerified: false,
        phone: null,
        country: null,
        zip: null,
        settlement: null,
        street: null,
        houseNumber: null,
        floorDoor: null,
    });

    const [startDate, setStartDate] = useState(new Date());
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        get("user/get-profile-summary").then((response) => {
            switch (response.data.responseCode) {
                case "OK":
                    if (response.data.user.birthDate) {
                        setStartDate(
                            new Date(
                                response.data.user.birthDate.replace(" ", "T")
                            )
                        );
                    }
                    setUserDatas({
                        name: response.data.user.name,
                        birthDate: response.data.user.birthDate,
                        birthPlace: response.data.user.birthPlace,
                        email: response.data.user.email,
                        emailVerified: response.data.user.emailVerified,
                        phone: response.data.user.phone,
                        gender: response.data.user.gender,
                        securityNumber: response.data.user.securityNumber,
                        idNumber: response.data.user.idNumber,
                        mothersName: response.data.user.mothersName,
                        country: response.data.user.country || "Magyarország",
                        zip: response.data.user.zip,
                        settlement: response.data.user.settlement,
                        street: response.data.user.street,
                        houseNumber: response.data.user.houseNumber,
                        floorDoor: response.data.user.floorDoor,
                        noTAJ:
                            !response.data.user.securityNumber &&
                            response.data.user.idNumber,
                    });

                    break;
                default:
                    break;
            }
            endLoading();
        });
    }, []);

    const dateChangeHandler = (date) => {
        setStartDate(date);
        setUserDatas({ ...userDatas, birthDate: convertDate(date) });
    };

    const setUserDatasBykey = (key, value) =>
        setUserDatas({
            ...userDatas,
            [key]: value,
        });

    const renderAddressEditingComponent = () => (
        <>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item sm={3} xs={12}>
                    <span>Ország</span>
                </Grid>
                <Grid item sm={5} xs={6}>
                    <CountryPicker
                        value={userDatas.country}
                        onChange={(e) => setUserDatasBykey("country", e)}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item sm={3} xs={12}>
                    <span>Irányítószám</span>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Input
                        className={classes.input}
                        type="number"
                        defaultValue={userDatas.zip}
                        onChange={(e) =>
                            setUserDatasBykey("zip", e.target.value)
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item sm={3} xs={12}>
                    <span>Település</span>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Input
                        className={classes.input}
                        defaultValue={userDatas.settlement}
                        onChange={(e) =>
                            setUserDatasBykey("settlement", e.target.value)
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item sm={3} xs={12}>
                    <span>Utca</span>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Input
                        className={classes.input}
                        defaultValue={userDatas.street}
                        onChange={(e) =>
                            setUserDatasBykey("street", e.target.value)
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item sm={3} xs={12}>
                    <span>Házszám</span>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Input
                        className={classes.input}
                        defaultValue={userDatas.houseNumber}
                        onChange={(e) =>
                            setUserDatasBykey("houseNumber", e.target.value)
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                style={{ marginBottom: "1rem" }}
            >
                <Grid item sm={3} xs={12}>
                    <span>Emelet / Ajtó</span>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Input
                        className={classes.input}
                        defaultValue={userDatas.floorDoor}
                        onChange={(e) =>
                            setUserDatasBykey("floorDoor", e.target.value)
                        }
                    />
                </Grid>
            </Grid>
        </>
    );

    const renderAddress = () => {
        if (
            userDatas.country &&
            userDatas.zip &&
            userDatas.settlement &&
            userDatas.street &&
            userDatas.houseNumber
        ) {
            let addressString = `${userDatas.country}, ${userDatas.zip}, ${userDatas.settlement}, ${userDatas.street} ${userDatas.houseNumber}`;
            if (userDatas.floorDoor) {
                addressString += ` ${userDatas.floorDoor}`;
            }

            return addressString;
        }

        return "Nincs megadva";
    };

    const savePersonalHandler = () => {
        if (!isValidPhoneNumber(userDatas.phone)) {
            responseMessage("Hibás telefonszám!");

            return false;
        }

        const phoneNumber = parsePhoneNumber(userDatas.phone);
        let phoneCountry;
        if (phoneNumber) {
            phoneCountry = phoneNumber.country;
        }

        post("user/update-user-all-personal-data", {
            data: {
                birthDate: convertDate(startDate),
                birthPlace: userDatas.birthPlace,
                mothersName: userDatas.mothersName,
                securityNumber: userDatas.securityNumber,
                idNumber: userDatas.idNumber,
                gender: userDatas.gender,
                email: userDatas.email,
                phone: userDatas.phone,
                country: userDatas.country,
                zip: userDatas.zip,
                settlement: userDatas.settlement,
                street: userDatas.street,
                houseNumber: userDatas.houseNumber,
                floorDoor: userDatas.floorDoor,
                phoneCountry,
            },
        })
            .then((response) => {
                endLoading();
                setEditing({ ...editing, personal: false });
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        setEditing(false);

                        break;
                    case "EMPTY_BIRTH_DATE":
                        responseMessage("A születési idő megadása kötelező!");

                        break;
                    case "EMPTY_BIRTH_PLACE":
                        responseMessage("A születési hely megadása kötelező!");

                        break;
                    case "EMPTY_SECURITY_OR_ID_NUMBER":
                        responseMessage(
                            "A TAJ vagy személyi igazolvány szám megadása kötelező!"
                        );

                        break;
                    case "EMPTY_MOTHERS_NAME":
                        responseMessage("Kérjük adja meg édesanyja nevét!");

                        break;
                    case "EMPTY_EMAIL":
                        responseMessage("Kérjük adja meg valós email cimét!");

                        break;
                    case "EMPTY_PHONE":
                        responseMessage("Kérjük adja meg valós telefonszámát!");

                        break;
                    case "WRONG_EMAIL":
                        responseMessage("Hibás e-mail formátum!");

                        break;
                    case "WRONG_PHONE":
                        responseMessage("Hibás telefonszám formátum!");

                        break;
                    case "EMAIL_TAKEN":
                        responseMessage("A megadott e-mail cím foglalt!");

                        break;
                    case "PHONE_TAKEN":
                        responseMessage("A megadott telefonszám foglalt!");

                        break;
                    case "INVALID_SECURITY_NUMBER":
                        responseMessage("A megadott TAJ szám hibás!");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                endLoading();
            });
    };

    const datePicker = (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={huLocale}>
            <KeyboardDatePicker
                disableFuture
                invalidDateMessage="Nem megfelelő dátum"
                margin="normal"
                id="date-picker-dialog"
                // label="Születési idő"
                format="yyyy.MM.dd"
                value={startDate}
                onChange={(date) => dateChangeHandler(date)}
                okLabel="Rendben"
                cancelLabel="Mégsem"
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
            />
        </MuiPickersUtilsProvider>
    );

    return (
        <Container>
            {userDatas && (
                <div className={classes.inner}>
                    <Breadcrumbs />
                    <CardContainer className={classes.card}>
                        <Grid container>
                            <Grid sm={2} xs={12} item>
                                <img
                                    src={Icons.personalDataGreen}
                                    alt="icon"
                                    style={{ fill: "#f0f" }}
                                    className={classes.icon}
                                />
                            </Grid>
                            <Grid sm={8} xs={12} item>
                                <h2 className={classes.header}>
                                    Személyes adatok
                                </h2>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item sm={2} xs={12}></Grid>
                            <Grid item sm={8} xs={12}>
                                {!editing ? (
                                    <p
                                        className={classes.text}
                                        onClick={() => setEditing(true)}
                                    >
                                        Szerkesztés
                                    </p>
                                ) : (
                                    <SaveButton
                                        withCancel
                                        saveHandler={() => {
                                            setEditing(false);
                                            savePersonalHandler();
                                        }}
                                        cancelHandler={() => setEditing(false)}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid container className="usersettings">
                            <Grid item sm={2} xs={12}></Grid>
                            <Grid item sm={8} xs={12}>
                                <UserSettingsRow
                                    title="Név"
                                    text={userDatas.name}
                                    empty={!userDatas.name}
                                    editable={false}
                                />
                                <UserSettingsRow
                                    title="Nem"
                                    text={
                                        userDatas.gender
                                            ? userDatas.gender
                                            : "Nincs megadva"
                                    }
                                    // empty={!userDatas.gender}
                                    editable={true}
                                    editing={editing}
                                    editingComponent={
                                        <CustomSelect
                                            contentContainerStyle={{
                                                width: "50rem",
                                            }}
                                            controlStyle={{ width: "25rem" }}
                                            options={[
                                                { value: "Férfi" },
                                                { value: "Nő" },
                                            ]}
                                            optionValue={"value"}
                                            optionKey={"value"}
                                            value={userDatas.gender}
                                            onChange={(val) =>
                                                setUserDatasBykey("gender", val)
                                            }
                                        />
                                    }
                                />
                                <UserSettingsRow
                                    title="Születési idő"
                                    empty={!userDatas.birthDate}
                                    text={
                                        userDatas.birthDate
                                            ? convertDate(
                                                  new Date(userDatas.birthDate),
                                                  "."
                                              )
                                            : "Nincs megadva"
                                    }
                                    editable={true}
                                    editing={editing}
                                    editingComponent={datePicker}
                                />
                                <UserSettingsRow
                                    title="Születési hely"
                                    empty={!userDatas.birthPlace}
                                    text={
                                        userDatas.birthPlace
                                            ? userDatas.birthPlace
                                            : "Nincs megadva"
                                    }
                                    editable={true}
                                    editing={editing}
                                    editingComponent={
                                        <Input
                                            className={classes.input}
                                            defaultValue={userDatas.birthPlace}
                                            onChange={(e) =>
                                                setUserDatasBykey(
                                                    "birthPlace",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    }
                                />
                                {!userDatas.noTAJ && (
                                    <UserSettingsRow
                                        title="TAJ szám"
                                        empty={!userDatas.securityNumber}
                                        text={
                                            userDatas.securityNumber ||
                                            "Nincs megadva"
                                        }
                                        editable={true}
                                        editing={editing}
                                        editingComponent={
                                            <>
                                                <Input
                                                    className={classes.input}
                                                    defaultValue={
                                                        userDatas.securityNumber
                                                            ? userDatas.securityNumber.replace(
                                                                  /-/g,
                                                                  ""
                                                              )
                                                            : ""
                                                    }
                                                    type="taj"
                                                    onChange={(e) =>
                                                        setUserDatasBykey(
                                                            "securityNumber",
                                                            e.target.value
                                                        )
                                                    }
                                                />{" "}
                                                <span
                                                    className={classes.noTAJ}
                                                    onClick={() =>
                                                        setUserDatasBykey(
                                                            "noTAJ",
                                                            true
                                                        )
                                                    }
                                                >
                                                    Nincs TAJ kártyám
                                                </span>
                                            </>
                                        }
                                    />
                                )}
                                {userDatas.noTAJ && (
                                    <UserSettingsRow
                                        title="Személyi igazolvány szám"
                                        empty={!userDatas.idNumber}
                                        text={
                                            userDatas.idNumber ??
                                            "Nincs megadva"
                                        }
                                        editable={true}
                                        editing={editing}
                                        editingComponent={
                                            <>
                                                <Input
                                                    className={classes.input}
                                                    defaultValue={
                                                        userDatas.idNumber
                                                            ? userDatas.idNumber.replace(
                                                                  /-/g,
                                                                  ""
                                                              )
                                                            : ""
                                                    }
                                                    onChange={(e) =>
                                                        setUserDatasBykey(
                                                            "idNumber",
                                                            e.target.value
                                                        )
                                                    }
                                                />{" "}
                                                <span
                                                    className={classes.noTAJ}
                                                    onClick={() =>
                                                        setUserDatasBykey(
                                                            "noTAJ",
                                                            false
                                                        )
                                                    }
                                                >
                                                    Van TAJ kártyám
                                                </span>
                                            </>
                                        }
                                    />
                                )}
                                <UserSettingsRow
                                    title="Anyja neve"
                                    empty={!userDatas.mothersName}
                                    text={
                                        userDatas.mothersName
                                            ? userDatas.mothersName
                                            : "Nincs megadva"
                                    }
                                    editable={true}
                                    editing={editing}
                                    editingComponent={
                                        <Input
                                            className={classes.input}
                                            defaultValue={userDatas.mothersName}
                                            onChange={(e) =>
                                                setUserDatasBykey(
                                                    "mothersName",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    }
                                />
                                <UserSettingsRow
                                    title="Email-cím"
                                    empty={
                                        !userDatas.emailVerified ||
                                        !userDatas.email
                                    }
                                    text={
                                        userDatas.email ? (
                                            userDatas.emailVerified ? (
                                                userDatas.email
                                            ) : (
                                                <>
                                                    {userDatas.email}{" "}
                                                    <span
                                                        className={
                                                            classes.notVerified
                                                        }
                                                    >
                                                        - Nincs megerősítve
                                                    </span>
                                                </>
                                            )
                                        ) : (
                                            "Nincs megadva"
                                        )
                                    }
                                    editable={true}
                                    editing={editing}
                                    editingComponent={
                                        <Input
                                            className={classes.input}
                                            type="email"
                                            defaultValue={userDatas.email}
                                            onChange={(e) =>
                                                setUserDatasBykey(
                                                    "email",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    }
                                />
                                <UserSettingsRow
                                    title="Telefonszám"
                                    empty={!userDatas.phone}
                                    text={
                                        userDatas.phone
                                            ? userDatas.phone
                                            : "Nincs megadva"
                                    }
                                    editable={true}
                                    editing={editing}
                                    editingComponent={
                                        <Input
                                            className={classes.input}
                                            value={userDatas.phone}
                                            type="phone"
                                            onChange={(e) =>
                                                setUserDatasBykey("phone", e)
                                            }
                                        />
                                    }
                                />
                                <UserSettingsRow
                                    title="Lakcím"
                                    text={renderAddress()}
                                    editable={true}
                                    editing={editing}
                                    editingComponent={renderAddressEditingComponent()}
                                />
                                {!editing ? (
                                    <p
                                        className={classes.text}
                                        onClick={() => setEditing(true)}
                                    >
                                        Szerkesztés
                                    </p>
                                ) : (
                                    <SaveButton
                                        withCancel
                                        saveHandler={() => {
                                            setEditing(false);
                                            savePersonalHandler();
                                        }}
                                        cancelHandler={() => setEditing(false)}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CardContainer>
                </div>
            )}
        </Container>
    );
};

export default UserSettings;
