import classes from "./SelectBtn.module.css";

const SelectBtn = (props) => (
    <div
        className={classes.selectBtn}
        style={props.selected ? styles.selected : styles.notSelected}
        onClick={props.onClick}
    >
        <span style={{ color: props.selected ? "white" : "#263D50" }}>
            {props.text}
        </span>
    </div>
);

const styles = {
    selected: {
        backgroundColor: "#929EA7",
        border: "1px solid #929EA7",
    },
    notSelected: {
        backgroundColor: "white",
        border: "1px solid #929EA7",
    },
};

export default SelectBtn;
