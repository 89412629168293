import type { MouseEventHandler } from "react";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Colors } from "../../../assets/Theme";
import { useResolution } from "../../../ui/utils";

const ARROW_POSITION_TABLET = `${108 / 2 + 30}px`;
const ARROW_POSITION_DESKTOP = `${209 / 2 - 20}px`;

interface Props {
    type: "carousel" | "gallery";
    onClick?: MouseEventHandler<unknown> | undefined;
    direction: "prev" | "next";
}

const ArrowIcon = ({ type, onClick, direction }: Props) => {
    const isMobile = useResolution("mobile");
    const isTablet = useResolution("tablet");

    const carouselArrowStyle = {
        ...(direction === "prev" ? { left: "4%" } : { right: "4%" }),
        top: "50%",
        color: "white",
        borderRadius: "50%",
        background: "rgba( 0, 0, 0, 0.1 )",
    };

    const galleryArrowStyle = {
        color: Colors.darkBlue,
        border: `1px solid ${Colors.darkBlue}`,
        borderRadius: "50%",
        top: isMobile
            ? "0"
            : isTablet
            ? ARROW_POSITION_TABLET
            : ARROW_POSITION_DESKTOP,
        ...(direction === "prev"
            ? { left: isMobile ? "52%" : "-8%" }
            : { right: isMobile ? "52%" : "-8%" }),
    };

    const styles = {
        arrowStyles: {
            zIndex: 1,
            position: "absolute",
            cursor: "pointer",
            fontSize: "4rem",
            ...(type === "carousel"
                ? { ...carouselArrowStyle }
                : { ...galleryArrowStyle }),
        },
    } as const;

    if (isMobile && type === "gallery") {
        if (direction === "prev") {
            return (
                <ChevronRight onClick={onClick} style={styles.arrowStyles} />
            );
        }

        return <ChevronLeft onClick={onClick} style={styles.arrowStyles} />;
    }
    if (direction === "prev") {
        return <ChevronLeft onClick={onClick} style={styles.arrowStyles} />;
    }

    return <ChevronRight onClick={onClick} style={styles.arrowStyles} />;
};

export default ArrowIcon;
