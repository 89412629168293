import * as actionTypes from "../actions/actionTypes";

const initialState = {};

//TODO: logout-nál üríteni az access tokeneket
const setChatAccessToken = (state, action) => ({
    ...state,
    [action.serviceId]: {
        ...state.serviceId,
        [action.channelSID]: action.accessToken,
    },
});

const reducer = (state = initialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case actionTypes.SET_CHAT_ACCESS_TOKEN:
            return setChatAccessToken(state, action);
    }

    return state;
};

export default reducer;
