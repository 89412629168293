import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { endLoading, post } from "../../../config/fetch";
import Button from "../../../components/UI/Button/Button";
import { Images } from "../../../assets/Theme";
import { responseMessage } from "../../../utility";
import useQuery from "../../../features/util/useQuery";
import classes from "./RegistrationEnd.module.css";
import { ALERT_MESSAGES } from "../../../config/variables";

const RegistrationEnd = () => {
    const query = useQuery();
    window.scrollTo(0, 0);

    const [newsletter, setNewsletter] = useState(true);

    useEffect(() => {
        let newsletterSubscription = query.get("newsletter") === "true";
        setNewsletter(newsletterSubscription);
        window.gtag2("event", "conversion", {
            send_to: "AW-457177574/AzpZCM_km4YDEObz_9kB",
        });
        redeemCoupon();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const redeemCoupon = () => {
        const userId = query.get("userId");
        const couponCode = query.get("couponCode");

        if (userId === "null" || couponCode === "null") {
            return;
        }

        post("api/v1/coupon/redeem-coupon", {
            couponCode: couponCode,
            userId: userId,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("A kupont sikeresen beváltottad!");

                        break;
                    case "WRONG_CODE":
                        responseMessage("Hibás kuponkód!");

                        break;
                    case "ALREADY_REDEEMED":
                        responseMessage(
                            "Ezt a kupont már beváltottad egyszer!"
                        );

                        break;
                    case "ONLY_WITHOUT_SUBSCRIPTION":
                        responseMessage(
                            "Ezt a kupont csak előfizetés nélkül lehet beváltani!"
                        );

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const subscribe = () => {
        let newsletterHash = query.get("newsletterHash");
        post("user/subscribe-newsletter", { hash: newsletterHash })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage(
                            "A feliratkozáshoz szükséges emailt elküldtük az email címedre!"
                        );

                        break;
                    case "ALREADY_SUBSCRIBED":
                        responseMessage("Már feliratkoztál hírlevelünkre!");

                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    return (
        <div className={classes.RegistrationEnd}>
            <Container>
                <div className={classes.RegistrationEndInner}>
                    <h3>Kedves Felhasználó!</h3>
                    <p>
                        Sikeresen regisztrált! Kérjük erősítse meg email címét!
                    </p>
                    <br />
                    {!newsletter && (
                        <>
                            <p>
                                Amennyiben szeretné megkapni legújabb
                                ajánlatainkat, iratkozzon fel a hírlevelünkre!
                            </p>
                            <br />
                            <Button
                                rightIcon={Images.arrow}
                                onClick={subscribe}
                            >
                                <span className={classes.buttonText}>
                                    Feliratkozom
                                </span>
                            </Button>
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default RegistrationEnd;
