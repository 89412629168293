import classes from "./classes.module.css";

const Registration = () => (
    <>
        <h3 className={classes.title} id="szukseges-adatok">
            Milyen adatokat kell megadnom a regisztráció során?
        </h3>
        <p className={classes.text}>
            Keresztnév, vezetéknév, e-mailcím és telefonszám. A regisztráció
            után minden további belépéshez SMS-ben küldünk ki egy megerősítő
            kódot.{" "}
        </p>
        <h3 className={classes.title} id="belepes-fiokba">
            Regisztráltam, de nem tudok belépni a fiókomba.
        </h3>
        <p className={classes.text}>
            A regisztráció után egy megerősítő e-mailt fogsz kapni tőlünk az
            általad megadott e-mail címre. Amennyiben az elsődleges leveleknél
            nem találod, kérünk nézd meg a spam mappádban is. A levélben
            található megerősítő linkre történő kattintással tudsz belépni első
            alkalommal fiókodba. Erre azért van szükség, hogy mások nevében
            e-mail címmel ne lehessen regisztrálni.
        </p>
        <h3 className={classes.title} id="megerosito-email">
            Regisztráltam, de nem kaptam megerősítő e-mailt.
        </h3>
        <p className={classes.text}>
            A regisztráció után egy megerősítő e-mailt fogsz kapni tőlünk az
            általad megadott e-mail címre. Amennyiben az elsődleges leveleknél
            nem találod, kérünk nézd meg a spam mappádban is. Amennyiben sehol
            sem találod a levelet, vedd fel velünk a kapcsolatot az
            info@t-doc.hu e-mail címen.
        </p>
        <h3 className={classes.title} id="tobbszoros-email">
            Egy e-mail címmel regisztrálhatok többször?
        </h3>
        <p className={classes.text}>
            Nem, egy e-mail címmel csak egy regisztráció lehetséges.
        </p>
        <h3 className={classes.title} id="tobbszoros-telefon">
            Egy telefonszámmal regisztrálhatok többször?
        </h3>
        <p className={classes.text}>
            Nem, egy telefonszámmal csak egy regisztráció lehetséges.
        </p>
        <h3 className={classes.title} id="gyerek-regisztracio">
            Hogyan regisztrálhatok gyermekem nevében?{" "}
        </h3>
        <p className={classes.text}>
            Külön regisztráció nem szükséges. Időpontfoglalás lehetséges majd
            más nevében is.{" "}
        </p>
    </>
);

export default Registration;
