import { useEffect, useState } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { formatNumber } from "../../../utility";
import Btn from "../../UI/Btn/Btn";
import SubscriptionDetails from "./SubscriptionDetails";
import { Icons } from "../../../assets/Theme";
import * as actions from "../../../store/actions";
import useAuth from "../../../features/auth/useAuth";
import classes from "./SubscriptionCard.module.css";

const SubscriptionCard = (props) => {
    const { isAuthenticated } = useAuth();
    const [open, toggleOpen] = useState(false);

    useEffect(() => {
        toggleOpen(props.open);
    }, [props.open]);

    const handleButtonClick = () => {
        if (props.subscriptionData.nextSubscription == props.packageId) {
            props?.cancelSubscriptionHandler?.();
        } else {
            props?.onButtonClick?.(props.packageId);
        }
    };

    const renderButtonProps = (packageId) => {
        if (isAuthenticated) {
            if (
                packageId == props.subscriptionData.nextSubscription &&
                props.packageId == 1
            ) {
                return {
                    text: "Aktív csomag",
                    style: {
                        pointerEvents: "none",
                        background: "var(--grey2)",
                        border: "var(--grey2)",
                        color: "var(--grey1)",
                    },
                };
            }

            if (packageId == props.subscriptionData.nextSubscription) {
                return {
                    text: "Lemondás",
                    style: {
                        background: "var(--amaranth)",
                        borderColor: "var(--amaranth)",
                        color: "#fff",
                    },
                };
            }

            return {
                text: "Kiválasztás",
            };
        }

        if (props.packageId == 2) {
            return {
                text: "Kipróbálom",
                style: {
                    background: "var(--yellow)",
                    borderColor: "var(--yellow)",
                    color: "var(--darkBlue)",
                },
            };
        }

        return {
            text: "Megvásárlás",
        };
    };

    return (
        <aside
            className={cn(classes.subscription, props.className, {
                [classes.trial]: props.yellowText,
                [classes.recommended]: props.greenText,
                [props.openClass]: open,
            })}
            onClick={() => null}
        >
            {props.yellowText && (
                <div className={cn(classes.extraTextContainer, classes.trial)}>
                    {props.yellowText}
                </div>
            )}
            {props.greenText && (
                <div
                    className={cn(
                        classes.extraTextContainer,
                        classes.recommended
                    )}
                >
                    {props.greenText}
                </div>
            )}

            <header>
                <img src={Icons.stethoscope} className={classes.icon} alt="" />
                <h3 className={classes.name}>{props.name}</h3>
            </header>

            {props.description && (
                <p className={classes.text}>{props.description}</p>
            )}

            {props.data && open && (
                <SubscriptionDetails
                    data={props.data}
                    name={props.name}
                    open={open}
                />
            )}

            <footer>
                {props.hasTrialPeriod && (
                    <p className={classes.trialText}>
                        A díjmentes időszak után
                    </p>
                )}
                <div className={classes.footerContent}>
                    <p className={classes.price}>
                        {formatNumber(props.price || 0)} Ft / hó
                    </p>
                    <p
                        className={classes.link}
                        onClick={() => {
                            toggleOpen(!open);
                            props.toggleSubscriptionDetails(!open);
                        }}
                    >
                        {open ? "Bezár" : "Részletek"}
                    </p>
                </div>
            </footer>

            {open && props.cards.length > 0 && (
                <div className={classes.buttonAndDetails}>
                    {props.buttonComponent || (
                        <Btn
                            disabled={props.buttonDisabled}
                            onClick={handleButtonClick}
                            className={classes.button}
                            color="transparent-green"
                            {...renderButtonProps(props.packageId)}
                        />
                    )}
                </div>
            )}
        </aside>
    );
};

const mapStateToProps = (state) => ({
    open: state.ui.subscriptionDetails,
});

const mapDispatchToProps = (dispatch) => ({
    toggleSubscriptionDetails: (show) =>
        dispatch(actions.toggleSubscriptionDetails(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCard);
