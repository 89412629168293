import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { removeStorageValue, setStorageValue } from "../util/storage";
import type { LoginPayload } from "./model";
import { setUser as setSentryUser } from "@sentry/react";

interface State {
    isLoading: boolean; // This is a global loading indicator, nothing to do with auth
    isAuthLoading: boolean;
    userId: number | null;
    token: string | null;
}

const initialState: State = {
    isLoading: true,
    isAuthLoading: true,
    userId: null,
    token: null,
};

// See AuthWrapper

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login(
            state,
            {
                payload: { userId, token, storeIn = "local" },
            }: PayloadAction<LoginPayload>
        ) {
            state.userId = userId;
            state.token = token;
            state.isLoading = false;
            state.isAuthLoading = false;

            if (storeIn !== "none") {
                setStorageValue("userId", userId, storeIn);
                setStorageValue("token", token, storeIn);
            }

            setSentryUser({
                id: userId.toString(),
                ip_address: "{{auto}}",
            });
        },
        clearAuth(state) {
            state.userId = null;
            state.token = null;
            state.isLoading = false;
            state.isAuthLoading = false;

            removeStorageValue("userId", "both");
            removeStorageValue("token", "both");

            setSentryUser(null);
        },
    },
});

export const { login, clearAuth } = slice.actions;

export const authReducer = slice.reducer;
