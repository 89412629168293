// previously <Input type === "passwordRegister" />
import { useEffect, useRef, useState } from "react";
import ReactPasswordStrength from "react-password-strength";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import cn from "classnames";
import classes from "./Password.module.css";
import "../../react-password-strength.css";

const scoreWords = ["GYENGE", "GYENGE", "KÖZEPES", "JÓ", "ERŐS"];
const tooShortWord = "TÚL RÖVID";

const Password = ({ name, value, style, className, bigger, onChange }) => {
    const ref = useRef(null);
    const [passWordVisible, setPasswordVisible] = useState(false);

    const handleChange = (input) => {
        onChange?.(input.password);
    };

    useEffect(() => {
        if (value === null) {
            ref.current.clear();
            setPasswordVisible(false);
        }
    }, [value]);

    return (
        <div className={classes.wrapper}>
            <ReactPasswordStrength
                ref={ref}
                inputProps={{
                    name,
                    value,
                    type: passWordVisible ? "text" : "password",
                    autoComplete: "off",
                }}
                minLength={6}
                className={cn(classes.input, className, {
                    [classes.bigger]: bigger,
                })}
                scoreWords={scoreWords}
                tooShortWord={tooShortWord}
                changeCallback={handleChange}
                style={style}
            />

            <div
                className={classes.visibility}
                onClick={() => setPasswordVisible((prevState) => !prevState)}
            >
                {passWordVisible ? (
                    <VisibilityIcon
                        fontSize="medium"
                        classes={{ root: classes.icon }}
                    />
                ) : (
                    <VisibilityOffIcon
                        fontSize="medium"
                        classes={{ root: classes.icon }}
                    />
                )}
            </div>
        </div>
    );
};

export default Password;
