import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Container,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import cn from "classnames";
import Logo from "../../Logo/Logo";
import { Icons, Images } from "../../../assets/Theme";
import Btn from "../../UI/Btn/Btn";
import * as actions from "../../../store/actions/index";
import { get, post } from "../../../config/fetch";
import { convertDate } from "../../../utility";
import { getSocket } from "../../../config/socket";
import LanguageSwitch from "../../../features/i18n/LanguageSwitch";
import { useResolution } from "../../../ui/utils";
import useWindowDimensions from "../../../ui/hooks/useWindowDimensions";
import useTranslation from "../../../features/i18n/useTranslation";
import { useLogoutMutation } from "../../../features/auth/api";
import useAuth from "../../../features/auth/useAuth";
import useQuery from "../../../features/util/useQuery";
import BtnAuth from "../../../components/UI/Btn/BtnAuth";
import NotificationBadgeAndMenu from "../../../components/Facelift/organisms/NotificationBadgeAndMenu";
import classes from "./Toolbar.module.css";

const toolbarHeight = 56;

const Toolbar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = useQuery();
    const { isAuthenticated } = useAuth();
    const isMobile = useResolution("tablet");

    const { width } = useWindowDimensions();

    const [employerLogo, setEmployerLogo] = useState(null);

    useEffect(() => {
        window.addEventListener("scroll", hamburgerChecker, true);

        return window.removeEventListener("scroll", hamburgerChecker);
    }, []);

    const mainPointsRef = useRef();

    const [stickyTopRow, toggleStickyTopRow] = useState(false);
    const [showHamburger, toggleHamburger] = useState(false);
    const [showNotifications, toggleNotifications] = useState(false);
    const [notificationCounter, setNotificationCounter] = useState(0);
    const [notifications, setNotifications] = useState([]);

    const [hideToolbar, setHideToolbar] = useState(false);
    const { i18nRoute } = useTranslation();

    const [logout] = useLogoutMutation();

    const [employer, setEmployer] = useState({
        isLoading: false,
        data: null,
    });

    const inApp = query.get("app");

    const [isScrollOnTop, setIsScrollOnTop] = useState(0);

    useEffect(() => {
        const onScroll = ({ target }) => {
            setIsScrollOnTop(target.documentElement.scrollTop > toolbarHeight); // 56px is the height of toolbar
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [isScrollOnTop]);

    useEffect(() => {
        props.toggleUserMenu(false);
        toggleNotifications(false);
        if (
            location.pathname === "/idopontfoglalas" ||
            location.pathname === "/idopont-kivalasztva" ||
            location.pathname === "/sikeres-foglalas"
        ) {
            setHideToolbar(true);
        } else {
            setHideToolbar(false);
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        get("api/v1/event/get-user-notifications", false).then((response) => {
            let unreadCounter = 0;
            setNotifications(response.data.notifications);
            for (let n of response.data.notifications) {
                if (!n.opened) {
                    unreadCounter++;
                }
            }
            setNotificationCounter(unreadCounter);
        });

        get("api/v1/user/get-employer-logo", false).then((response) => {
            if (response.data.responseCode === "OK") {
                setEmployerLogo(response.data.employerLogo);
            }
        });
        get("user/get-employer", false)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setEmployer({
                            isLoading: false,
                            data: response.data.employer,
                        });

                        break;
                    default:
                        setEmployer({
                            isLoading: false,
                            data: null,
                        });
                        console.log(
                            "Error in countMessages:",
                            response.data.responseCode
                        );

                        break;
                }
            })
            .catch((error) => {
                setEmployer({
                    isLoading: false,
                    data: null,
                });
                console.error(error);
            });

        const socket = getSocket();
        socket.on("notification", (msg) => {
            switch (msg.type) {
                case "newChatMessage":
                    if (
                        location.pathname.indexOf(`chat/${msg.identifier}`) ===
                        -1
                    ) {
                        let newNotification = {
                            created_at: new Date(),
                            data: JSON.stringify(msg),
                            opened: 0,
                            id: msg.id,
                        };

                        setNotifications((prevValue) => [
                            newNotification,
                            ...prevValue,
                        ]);
                        setNotificationCounter((prevValue) => ++prevValue);
                    }

                    break;
                default: {
                    let newNotification = {
                        created_at: new Date(),
                        data: JSON.stringify(msg),
                        opened: 0,
                        id: msg.id,
                    };

                    setNotifications((prevValue) => [
                        newNotification,
                        ...prevValue,
                    ]);
                    setNotificationCounter((prevValue) => ++prevValue);
                }
            }
        });

        return () => {
            socket.off("notification");
        };
    }, [isAuthenticated, location.pathname]);

    const hamburgerChecker = () => {
        if (window.pageYOffset > toolbarHeight) {
            toggleHamburger(true);
        } else if (window.pageYOffset <= toolbarHeight) {
            toggleHamburger(false);
            // toggleStickyTopRow(false);
        }
        if (window.pageYOffset === 0) {
            toggleStickyTopRow(false);
        }
    };

    const hamburgerClick = () => toggleStickyTopRow(!stickyTopRow);

    const backdropClick = () => {
        props.toggleUserMenu(false);
        toggleNotifications(false);
    };

    const NotificationElement = (props) => (
        <div
            className={`${classes.NotificationElement} ${
                props.unread && classes.unread
            }`}
            onClick={props.onClick}
            style={{ cursor: "pointer" }}
        >
            <div className={classes.dateRow}>
                <div className={classes.unreadCircle}></div>
                <span className={classes.date}>{props.date}</span>
            </div>
            <p className={classes.title}>{props.title}</p>
            <p className={classes.text}>{props.text}</p>
        </div>
    );

    const renderNotificationElement = (notification) => {
        let date =
            typeof notification.created_at === "object"
                ? notification.created_at
                : new Date(notification.created_at.replace(" ", "T"));
        let data = JSON.parse(notification.data);

        if (
            data.type === "newRatingAvailable" &&
            data.deadline &&
            new Date(data.deadline.replace(" ", "T")) < new Date()
        ) {
            return;
        }

        return (
            <NotificationElement
                title={notificationTitle(data)}
                text={notificationText(data)}
                date={convertDate(date, ".")}
                unread={!notification.opened}
                key={notification.id}
                onClick={() => onNotificationClick(notification)}
            />
        );
    };

    const notificationTitle = (body) => {
        switch (body.type) {
            case "newChatMessage":
                return body.author + " új üzenetet küldött";
            case "newPrescriptionMessage":
                return body.author + " új üzenetet küldött";
            case "recommendedService":
                return body.service + " szolgáltatást ajánlottak neked";
            case "newRatingAvailable":
                return "Értékeld legutóbb igénybevett szolgáltatásod";
            case "nextHealth":
                return body.subject;
            default:
                return "";
        }
    };

    const notificationText = (body) => {
        switch (body.type) {
            case "nextHealth":
                return body.content;
            default:
                return body.service;
        }
    };

    const onNotificationClick = (notification) => {
        notificationCallback(notification);
        post(
            "api/v1/event/mark-notification-read",
            { id: notification.id },
            false
        );
        let body = JSON.parse(notification.data);
        switch (body.type) {
            case "newChatMessage":
                navigate(`/elozmenyek/orvosi-chat/${body.identifier}`);

                break;
            case "newPrescriptionMessage":
                navigate(`/elozmenyek/e-recept/${body.identifier}`);

                break;
            case "recommendedService":
                navigate(body.link);

                break;
            case "newRatingAvailable":
                navigate(`/ertekeles?hash=${body.hash}`);

                break;
            case "nextHealth":
                navigate(`/nha${body.urlPath}`);

                break;
            default:
                break;
        }
    };

    const notificationCallback = (notification) => {
        let _notifications = [...notifications];
        let newNotification;
        let index = 0;
        for (let element of _notifications) {
            if (element.id === notification.id) {
                newNotification = { ...element, opened: 1 };
                delete _notifications[index];
                _notifications.splice(index, 1, newNotification);

                break;
            }
            index++;
        }
        setNotifications(_notifications);
        setNotificationCounter((prevValue) => --prevValue);
    };

    const mobileSignInOut = () => {
        if (isAuthenticated) {
            logout();
            navigate("/");
        } else {
            props.toggleLoginModal(true);
        }
    };

    const mobileNotificationsClick = () => {
        toggleNotifications(!showNotifications);
        props.toggleUserMenu(false);
    };

    const mobileMenuClick = () => {
        toggleNotifications(false);
        props.toggleUserMenu(!props.showUserMenu);
    };

    const desktopToolbar = (
        <>
            <header
                id="desktop-header"
                className={`${classes.alwaysShow} ${
                    stickyTopRow && classes.withTop
                }`}
            >
                {!hideToolbar && (
                    <div
                        className={`${classes.topRow} ${
                            stickyTopRow && classes.stickyTopRow
                        }`}
                    >
                        <Container>
                            <div className={classes.topRowInner}>
                                <Link
                                    to={i18nRoute("routes.dutyFinder")}
                                    className={classes.menuItem}
                                >
                                    Ügyeletkereső
                                </Link>
                                <Link
                                    to={i18nRoute("routes.howTo")}
                                    className={classes.menuItem}
                                >
                                    Hogyan működik?
                                </Link>
                                <Link
                                    to={i18nRoute("routes.doctors")}
                                    className={classes.menuItem}
                                >
                                    Orvosaink
                                </Link>
                                <Link
                                    to={i18nRoute("routes.services.base")}
                                    className={classes.menuItem}
                                >
                                    Szolgáltatások
                                </Link>
                                <Link
                                    to={i18nRoute("routes.subscriptions")}
                                    className={classes.menuItem}
                                >
                                    Előfizetések
                                </Link>
                                <a
                                    href="https://egeszsegcegednek.hu/"
                                    className={classes.menuItem}
                                >
                                    Cégeknek
                                </a>
                                <div className={classes.dropdown}>
                                    <div className={classes.menuItem}>
                                        Rólunk{" "}
                                        <KeyboardArrowDownIcon
                                            style={styles.down}
                                        />
                                    </div>
                                    <div className={classes.dropdownContainer}>
                                        <Link
                                            to={i18nRoute("routes.aboutUs")}
                                            className={classes.menuItem}
                                        >
                                            Rólunk
                                        </Link>
                                        <Link
                                            to={i18nRoute(
                                                "routes.institutions"
                                            )}
                                            className={classes.menuItem}
                                        >
                                            Partner intézmények
                                        </Link>
                                        <Link
                                            to={i18nRoute("routes.faq.base")}
                                            className={classes.menuItem}
                                        >
                                            Súgóközpont
                                        </Link>
                                    </div>
                                </div>
                                {process.env.REACT_APP_FEATURE_I18N && (
                                    <LanguageSwitch classes={classes} />
                                )}
                            </div>
                        </Container>
                    </div>
                )}
                <div
                    className={cn(
                        classes.mainHeader,
                        location.pathname === "/"
                            ? {
                                  [classes.fadeOut]: !isScrollOnTop,
                                  [classes.fadeIn]: isScrollOnTop,
                              }
                            : classes.fadeIn
                    )}
                >
                    <Container>
                        <div className={classes.mainHeaderInner}>
                            <Logo
                                home
                                logoImage={
                                    employerLogo
                                        ? employerLogo
                                        : location.pathname === "/"
                                        ? isScrollOnTop
                                            ? Images.logo
                                            : Images.logoWhite
                                        : Images.logo
                                }
                            />
                            {!hideToolbar && (
                                <div className={classes.mainPoints}>
                                    <div
                                        className={`${classes.point} ${
                                            classes.hamburgerContainer
                                        } ${!showHamburger && classes.hidden}`}
                                        onClick={hamburgerClick}
                                    >
                                        <img
                                            alt=""
                                            src={Icons.hamburgerBlack}
                                            className={classes.icon}
                                        />
                                    </div>
                                    <div
                                        className={classes.point}
                                        onClick={() =>
                                            props.toggleQualificationsModal(
                                                true
                                            )
                                        }
                                    >
                                        <img
                                            alt=""
                                            src={Icons.qualificationsGreen}
                                            className={classes.icon}
                                        />
                                        <span>Szakterületek</span>
                                    </div>
                                    <div
                                        className={classes.point}
                                        onClick={() =>
                                            navigate(
                                                i18nRoute(
                                                    "routes.services.duty"
                                                )
                                            )
                                        }
                                    >
                                        <img
                                            alt=""
                                            src={Icons.duty2Green}
                                            className={classes.icon}
                                        />
                                        <span>
                                            Általános orvosi konzultáció
                                        </span>
                                    </div>
                                    <div
                                        className={classes.point}
                                        onClick={() =>
                                            navigate(
                                                i18nRoute(
                                                    "routes.services.hotline"
                                                )
                                            )
                                        }
                                    >
                                        <img
                                            alt=""
                                            src={Icons.hotline2Green}
                                            className={classes.icon}
                                        />
                                        <span>Egészség hotline</span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "1rem",
                                        }}
                                    >
                                        <NotificationBadgeAndMenu
                                            notificationCounter={
                                                notificationCounter
                                            }
                                            mobileNotificationsClick={
                                                mobileNotificationsClick
                                            }
                                            showNotifications={
                                                showNotifications
                                            }
                                            notifications={notifications}
                                            renderNotificationElement={
                                                renderNotificationElement
                                            }
                                        />
                                        <BtnAuth
                                            notifications={notifications}
                                        />
                                    </div>
                                    <Backdrop
                                        style={{ zIndex: 100 }}
                                        open={
                                            props.showUserMenu ||
                                            showNotifications
                                        }
                                        onClick={backdropClick}
                                    />
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            </header>
        </>
    );

    const mobileToolbar = (
        <>
            <div className={classes.mobileNav}>
                <header className={classes.mobileToolbar}>
                    <Container>
                        <div className={classes.mobileToolbarInner}>
                            <Logo
                                home
                                logoImage={
                                    employerLogo ? employerLogo : Images.logo
                                }
                            />
                            {!hideToolbar && (
                                <div className={classes.mobileButtons}>
                                    {isAuthenticated ? (
                                        <div
                                            className={
                                                classes.notificationsContainer
                                            }
                                            onClick={mobileNotificationsClick}
                                        >
                                            <img
                                                alt=""
                                                src={
                                                    Icons.notificationSmallBlack
                                                }
                                                className={classes.icon}
                                            />
                                            {notificationCounter > 0 && (
                                                <div
                                                    className={
                                                        classes.counterContainer
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            classes.counter
                                                        }
                                                    >
                                                        {notificationCounter}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div
                                            className={classes.mobileUserButton}
                                            onClick={() =>
                                                props.toggleLoginModal(true)
                                            }
                                        >
                                            <img
                                                alt=""
                                                src={Icons.user}
                                                className={classes.icon}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={`${classes.hamburgerContainer}`}
                                        onClick={mobileMenuClick}
                                    >
                                        {!props.showUserMenu ? (
                                            <>
                                                <img
                                                    alt=""
                                                    src={Icons.hamburgerBlack}
                                                    className={classes.icon}
                                                />
                                                <span>Menü</span>
                                            </>
                                        ) : (
                                            <CloseIcon
                                                style={styles.closeIcon}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Container>
                </header>
            </div>

            {!hideToolbar && (
                <div className={classes.mainPoints}>
                    <Container>
                        <div
                            className={classes.mainPointsInner}
                            ref={mainPointsRef}
                        >
                            <div
                                className={classes.point}
                                onClick={() =>
                                    props.toggleQualificationsModal(true)
                                }
                            >
                                <img
                                    alt=""
                                    src={Icons.qualificationsGreen}
                                    className={classes.icon}
                                />
                                <span>Szakterületek</span>
                            </div>
                            <div
                                className={classes.point}
                                onClick={() =>
                                    navigate(i18nRoute("routes.services.duty"))
                                }
                            >
                                <img
                                    alt=""
                                    src={Icons.duty2Green}
                                    className={classes.icon}
                                />
                                <span>Orvosi ügyelet</span>
                            </div>
                            <div
                                className={classes.point}
                                onClick={() =>
                                    navigate(
                                        i18nRoute("routes.services.hotline")
                                    )
                                }
                            >
                                <img
                                    alt=""
                                    src={Icons.hotline2Green}
                                    className={classes.icon}
                                />
                                <span>Egészség hotline</span>
                            </div>
                            {(props.showUserMenu || showNotifications) && (
                                <div
                                    className={classes.mobileMenuExpanded}
                                    style={{ width: `${width - 50}px` }}
                                >
                                    {props.showUserMenu && (
                                        <>
                                            <div
                                                className={classes.element}
                                                onClick={() =>
                                                    props.toggleQualificationsModal(
                                                        true
                                                    )
                                                }
                                            >
                                                <img
                                                    alt=""
                                                    src={
                                                        Icons.qualificationsGreen
                                                    }
                                                    className={classes.icon}
                                                />
                                                <span className={classes.bold}>
                                                    Szakterületek
                                                </span>
                                            </div>
                                            <div
                                                className={classes.element}
                                                onClick={() =>
                                                    navigate(
                                                        i18nRoute(
                                                            "routes.services.duty"
                                                        )
                                                    )
                                                }
                                            >
                                                <img
                                                    alt=""
                                                    src={Icons.duty2Green}
                                                    className={classes.icon}
                                                />
                                                <span className={classes.bold}>
                                                    Orvosi ügyelet
                                                </span>
                                            </div>
                                            <div
                                                className={classes.element}
                                                onClick={() =>
                                                    navigate(
                                                        i18nRoute(
                                                            "routes.services.hotline"
                                                        )
                                                    )
                                                }
                                            >
                                                <img
                                                    alt=""
                                                    src={Icons.hotline2Green}
                                                    className={classes.icon}
                                                />
                                                <span className={classes.bold}>
                                                    Egészség hotline
                                                </span>
                                            </div>
                                            <div
                                                className={classes.element}
                                                onClick={() =>
                                                    navigate("/ugyeletkereso")
                                                }
                                            >
                                                Ügyeletkereső
                                            </div>
                                            <div
                                                className={classes.element}
                                                onClick={() =>
                                                    navigate(
                                                        i18nRoute(
                                                            "routes.howTo"
                                                        )
                                                    )
                                                }
                                            >
                                                Hogyan működik?
                                            </div>
                                            <div
                                                className={classes.element}
                                                onClick={() =>
                                                    navigate(
                                                        i18nRoute(
                                                            "routes.services.base"
                                                        )
                                                    )
                                                }
                                            >
                                                Szolgáltatások
                                            </div>
                                            <div
                                                className={classes.element}
                                                onClick={() =>
                                                    navigate("/elofizetesek")
                                                }
                                            >
                                                Előfizetések
                                            </div>
                                            <a
                                                href="https://egeszsegcegednek.hu/"
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                                className={classes.element}
                                            >
                                                Cégeknek
                                            </a>

                                            <Accordion
                                                square={true}
                                                classes={{
                                                    root: classes.accordion,
                                                    expanded:
                                                        classes.accordionExpanded,
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon
                                                            style={
                                                                styles.chevron
                                                            }
                                                        />
                                                    }
                                                    classes={{
                                                        expanded:
                                                            classes.accordionExpanded,
                                                        root: classes.accordionSummary,
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            classes.accordionInner
                                                        }
                                                    >
                                                        Rólunk
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div
                                                        className={
                                                            classes.accordionMore
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.element
                                                            }
                                                            onClick={() =>
                                                                navigate(
                                                                    "/rolunk"
                                                                )
                                                            }
                                                        >
                                                            Rólunk
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.element
                                                            }
                                                            onClick={() =>
                                                                navigate(
                                                                    "/orvosaink"
                                                                )
                                                            }
                                                        >
                                                            Orvosaink
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.element
                                                            }
                                                            onClick={() =>
                                                                navigate(
                                                                    "/partner-intezmenyek"
                                                                )
                                                            }
                                                        >
                                                            Partner intézmények
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            {isAuthenticated && (
                                                <Accordion
                                                    square={true}
                                                    classes={{
                                                        root: classes.accordion,
                                                        expanded:
                                                            classes.accordionExpanded,
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon
                                                                style={
                                                                    styles.chevron
                                                                }
                                                            />
                                                        }
                                                        classes={{
                                                            expanded:
                                                                classes.accordionExpanded,
                                                            root: classes.accordionSummary,
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                classes.accordionInner
                                                            }
                                                        >
                                                            <img
                                                                alt=""
                                                                src={
                                                                    Icons.userGreen
                                                                }
                                                                className={
                                                                    classes.icon
                                                                }
                                                            />
                                                            <span
                                                                className={
                                                                    classes.bold
                                                                }
                                                            >
                                                                Saját fiók
                                                            </span>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div
                                                            className={
                                                                classes.accordionMore
                                                            }
                                                        >
                                                            {employer.data
                                                                ?.delivery ===
                                                                1 && (
                                                                <div
                                                                    className={
                                                                        classes.element
                                                                    }
                                                                    onClick={() =>
                                                                        navigate(
                                                                            i18nRoute(
                                                                                "routes.profile.medicine-delivery"
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    Gyógyszer
                                                                    kiszállítás
                                                                </div>
                                                            )}
                                                            <div
                                                                className={
                                                                    classes.element
                                                                }
                                                                onClick={() =>
                                                                    navigate(
                                                                        i18nRoute(
                                                                            "routes.profile.user"
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                Személyes adatok
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.element
                                                                }
                                                                onClick={() =>
                                                                    navigate(
                                                                        i18nRoute(
                                                                            "routes.profile.messages"
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                Üzenetek
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.element
                                                                }
                                                                onClick={() =>
                                                                    navigate(
                                                                        i18nRoute(
                                                                            "routes.profile.security"
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                Bejelentkezés és
                                                                biztonság
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.element
                                                                }
                                                                onClick={() =>
                                                                    navigate(
                                                                        i18nRoute(
                                                                            "routes.profile.paymentMethods"
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                Fizetési
                                                                beállítások
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.element
                                                                }
                                                                onClick={() =>
                                                                    navigate(
                                                                        i18nRoute(
                                                                            "routes.profile.notifications"
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                Értesítések
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.element
                                                                }
                                                                onClick={() =>
                                                                    navigate(
                                                                        i18nRoute(
                                                                            "routes.profile.history"
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                Előzmények
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.element
                                                                }
                                                                onClick={() =>
                                                                    navigate(
                                                                        i18nRoute(
                                                                            "routes.profile.invite"
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                Ismerős
                                                                meghívása
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                            <div
                                                className={classes.element}
                                                onClick={() =>
                                                    navigate(
                                                        i18nRoute(
                                                            "routes.faq.base"
                                                        )
                                                    )
                                                }
                                            >
                                                <img
                                                    alt=""
                                                    src={Icons.lightbulbGreen}
                                                    className={classes.icon}
                                                />
                                                <span className={classes.bold}>
                                                    Súgóközpont
                                                </span>
                                            </div>
                                            <div
                                                className={classes.element}
                                                onClick={mobileSignInOut}
                                            >
                                                <img
                                                    alt=""
                                                    src={Icons.userGreen}
                                                    className={classes.icon}
                                                />
                                                <span className={classes.bold}>
                                                    {isAuthenticated
                                                        ? "Kijelentkezés"
                                                        : "Belépés / Regisztráció"}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                    {showNotifications && (
                                        <>
                                            <p
                                                className={
                                                    classes.notificationHeader
                                                }
                                            >
                                                Értesítések
                                            </p>
                                            <div
                                                className={
                                                    classes.elementsInner
                                                }
                                            >
                                                {notifications.map((element) =>
                                                    renderNotificationElement(
                                                        element
                                                    )
                                                )}
                                            </div>
                                            <Btn
                                                color="green"
                                                text="Összes előzmény"
                                                className={classes.button}
                                                onClick={() =>
                                                    navigate("/elozmenyek")
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            )}
            <Backdrop
                style={{ zIndex: 100 }}
                open={props.showUserMenu || showNotifications}
                onClick={backdropClick}
            />
        </>
    );

    return !inApp ? (isMobile ? mobileToolbar : desktopToolbar) : null;
};

export const styles = {
    down: {
        width: "2.5rem",
        height: "2.5rem",
        marginLeft: "0.5rem",
    },
    closeIcon: {
        width: "2rem",
        height: "2rem",
    },
};

const mapStateToProps = (state) => ({
    subscription: state.user.subscription,
    showHamburger: state.ui.showHamburger,
    showUserMenu: state.ui.showUserMenu,
});

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
    toggleRegistrationModal: (show) =>
        dispatch(actions.toggleRegistrationModal(show)),
    toggleHamburger: (show) => dispatch(actions.toggleHamburger(show)),
    toggleUserMenu: (show) => dispatch(actions.toggleUserMenu(show)),
    toggleQualificationsModal: (show) =>
        dispatch(actions.toggleQualificationsModal(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
