import {
    Delivery as DeliveryIcon,
    FaceSatisfied as FaceSatisfiedIcon,
    IncompleteCancel as IncompleteCancelIcon,
    Pending as PendingIcon,
} from "@carbon/icons-react";
import classes from "./WebshopToolbar.module.css";

const statuses = {
    pending: {
        text: "Függőben",
        icon: <PendingIcon size="32" />,
    },
    processing: {
        text: "Feldolgozás alatt",
        icon: <PendingIcon size="32" />,
    },
    shipped: {
        text: "Szállítás alatt",
        icon: <DeliveryIcon size="32" />,
    },
    cancelled: {
        text: "Megszakítva",
        icon: <IncompleteCancelIcon size="32" />,
    },
    delivered: {
        text: "Kiszállítva!",
        icon: <FaceSatisfiedIcon size="32" />,
    },
};

const WebshopStatus = ({ order }) => (
    <div className={classes.info}>
        <div className={classes.icon}>
            {!order.isLoading && order.data
                ? statuses[order.data.status].icon
                : statuses["pending"].icon}
        </div>
        <div>
            <h4 className={classes.headline}>
                {order.isLoading
                    ? "Utolsó megrendelés betöltése..."
                    : order.data
                    ? statuses[order.data.status].text
                    : "Nincs függőben megrendelés"}
            </h4>
            {!order.isLoading && order.data && (
                <p className={classes.text}>
                    #{order.data.piqOrderId} rendelés
                </p>
            )}
        </div>
    </div>
);

export default WebshopStatus;
