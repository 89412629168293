import { init, setTag } from "@sentry/react";
import { CaptureConsole, ExtraErrorData } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
import { getEnv } from "tdoc-common";

const ignoreErrors = ["Non-Error promise rejection captured"];

export const initSentry = () => {
    init({
        dsn: getEnv("SENTRY_DSN"),
        environment: getEnv("ENVIRONMENT"),
        release: getEnv("RELEASE"),
        attachStacktrace: true,
        integrations: [
            new ExtraErrorData(),
            new CaptureConsole({
                levels: ["error", "assert"],
            }),
            new BrowserTracing({
                tracingOrigins: [/t-doc/],
            }),
        ],
        ignoreErrors,
        tracesSampleRate: 0.1,
    });
    setTag("namespace", getEnv("NAMESPACE"));
};
