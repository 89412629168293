import Button from "../Button/Button";
import classes from "./SaveButton.module.css";

const SaveButton = (props) => {
    if (props.withCancel) {
        return (
            <div style={{ ...styles.container, ...props.style }}>
                <Button
                    style={{ ...styles.saveButtonWithCancel, ...props.style }}
                    onClick={() => {
                        if (props.saveHandler) props.saveHandler();
                    }}
                >
                    <p style={{ textAlign: "center", width: "100%" }}>
                        {props.btnText ? props.btnText : "Mentés"}
                    </p>
                </Button>
                {props.withCancel && (
                    <span
                        className={classes.cancel}
                        onClick={() => {
                            if (props.cancelHandler) props.cancelHandler();
                        }}
                    >
                        Mégsem
                    </span>
                )}
            </div>
        );
    }

    return (
        <Button
            style={{ ...styles.saveButton, ...props.style }}
            onClick={() => {
                if (props.saveHandler) props.saveHandler();
            }}
        >
            <p style={{ textAlign: "center", width: "100%" }}>
                {props.btnText ? props.btnText : "Mentés"}
            </p>
        </Button>
    );
};

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
    },
    saveButton: {
        marginLeft: "auto",
        marginRight: "auto",
        width: "23rem",
        lineHeight: "3.6rem",
        marginTop: "2rem",
    },
    saveButtonWithCancel: {
        marginRight: "4rem",
        width: "23rem",
        lineHeight: "3.6rem",
    },
    cancel: {},
};

export default SaveButton;
