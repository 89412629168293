import TagManager from "react-gtm-module";

const getSubscriptionName = (subscription) => {
    // eslint-disable-next-line default-case
    switch (+subscription) {
        case 2:
            return "Basic";
        case 3:
            return "Optimum";
        case 4:
            return "Premium";
        case 1:
        default:
            return "Vendég";
    }
};

const getProductName = (product) => {
    switch (product) {
        case "HOTLINE":
            return "Hotline";
        case "DUTY":
            return "Orvosi ügyelet";
        case "CHAT":
            return "Chat";
        case "SECOND_OPINION":
            return "Másodvélemény";
        case "VIDEO_CONSULTATION":
            return "Videókonzultáció";
        case "OUTPATIENT":
            return "Járóbeteg ellátás";
        case "PRESCRIPTION":
            return "E-recept";
        default:
            return product;
    }
};

const getProductId = (product) => {
    // eslint-disable-next-line default-case
    switch (product) {
        case "HOTLINE":
            return 1;
        case "DUTY":
            return 2;
        case "CHAT":
            return 3;
        case "SECOND_OPINION":
            return 4;
        case "VIDEO_CONSULTATION":
            return 5;
        case "OUTPATIENT":
            return 6;
        case "PRESCRIPTION":
            return 7;
    }
};

export const ecommerceProductDetail = (product, price, subscription) => {
    if (process.env.NODE_ENV !== "production") return;
    try {
        const productName = getProductName(product);
        const productId = getProductId(product);
        const subscriptionName = getSubscriptionName(subscription);
        let obj = {
            event: "detail",
            ecommerce: {
                detail: {
                    products: [
                        {
                            name: `${subscriptionName} - ${productName}`,
                            id: productId,
                            price: +price,
                            category: subscriptionName,
                        },
                    ],
                },
            },
        };
        TagManager.dataLayer({ ecommerce: null });
        TagManager.dataLayer({ dataLayer: obj });
    } catch (error) {
        console.error("[ECOMMERCE ERROR]: ", error);
    }
};

export const ecommerceAddCart = (product, price, subscription) => {
    if (process.env.NODE_ENV !== "production") return;
    try {
        const productName = getProductName(product);
        const productId = getProductId(product);
        const subscriptionName = getSubscriptionName(subscription);
        let obj = {
            event: "addToCart",
            ecommerce: {
                add: {
                    // 'add' actionFieldObject measures.
                    products: [
                        {
                            //  adding a product to a shopping cart.
                            name: `${subscriptionName} - ${productName}`,
                            id: productId,
                            price: +price,
                            category: subscriptionName,
                            quantity: 1,
                        },
                    ],
                },
            },
        };
        TagManager.dataLayer({ ecommerce: null });
        TagManager.dataLayer({ dataLayer: obj });
    } catch (error) {
        console.error("[ECOMMERCE ERROR]: ", error);
    }
};

export const ecommerceCheckout = (product, price, subscription) => {
    if (process.env.NODE_ENV !== "production") return;
    try {
        const productName = getProductName(product);
        const productId = getProductId(product);
        const subscriptionName = getSubscriptionName(subscription);
        let obj = {
            event: "checkout",
            ecommerce: {
                checkout: {
                    products: [
                        {
                            name: `${subscriptionName} - ${productName}`,
                            id: productId,
                            price: +price,
                            category: subscriptionName,
                            quantity: 1,
                        },
                    ],
                },
            },
        };
        TagManager.dataLayer({ ecommerce: null });
        TagManager.dataLayer({ dataLayer: obj });
    } catch (error) {
        console.error("[ECOMMERCE ERROR]: ", error);
    }
};

export const ecommercePurchase = (product, price, subscription) => {
    if (process.env.NODE_ENV !== "production") return;
    try {
        const productName = getProductName(product);
        const productId = getProductId(product);
        const subscriptionName = getSubscriptionName(subscription);
        let obj = {
            event: "purchase",
            ecommerce: {
                purchase: {
                    actionField: {
                        id: "T12345",
                    },
                    products: [
                        {
                            name: `${subscriptionName} - ${productName}`,
                            id: productId,
                            price: +price,
                            category: subscriptionName,
                            quantity: 1,
                        },
                    ],
                },
            },
        };
        TagManager.dataLayer({ ecommerce: null });
        TagManager.dataLayer({ dataLayer: obj });
    } catch (error) {
        console.error("[ECOMMERCE ERROR]: ", error);
    }
};
