import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../features/auth/api";
import useQuery from "../../features/util/useQuery";

const InviteRegistration = () => {
    const navigate = useNavigate();
    const query = useQuery();
    const [logout] = useLogoutMutation();

    useEffect(() => {
        logout();

        const hash = query.get("hash");
        const invitedEmail = query.get("email");
        if (!hash) {
            return;
        }

        navigate(
            {
                pathname: "/",
            },
            {
                state: {
                    inviteHash: hash,
                    invitedEmail,
                },
            }
        );
    }, [navigate, logout, query]);

    return null;
};

export default InviteRegistration;
