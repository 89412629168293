import { Container, Grid, Hidden } from "@material-ui/core";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../components/UI/PageHeader/PageHeader";
import BtnWithIcon from "../../components/UI/Btn/BtnWithIcon";
import { Icons, Images } from "../../assets/Theme";
import SubscriptionCard from "../../components/UI/SubscriptionCard/SubscriptionCard";
import SubscriptionCardMobile from "../../components/UI/SubscriptionCardMobile/SubscriptionCardMobile";
import PageSubheader from "../../components/UI/PageSubheader/PageSubheader";
import * as actions from "../../store/actions/index";
import {
    basic,
    optimum,
    premium,
    promotionText,
} from "../../config/subscriptions";
import useTranslation from "../../features/i18n/useTranslation";
import useAuth from "../../features/auth/useAuth";
import classes from "./Landing3.module.css";

const InfoTitle = (props) => (
    <div
        className={`${classes.infoTitle} ${props.className}`}
        style={props.style}
    >
        <div className={classes.line}></div>
        <div className={classes.infoTitleWrapper}>
            <span className={classes.text} style={props.textStyle}>
                {props.text}
            </span>
            <p className={classes.otherText}>{props.otherText}</p>
        </div>
    </div>
);

const InfoWrapper = (props) => (
    <div className={classes.infoWrapper} style={{ ...props.style }}>
        <Container style={{ height: "100%" }}>
            <div className={props.infoRowClass}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <InfoTitle
                            text={props.text}
                            otherText={props.otherText}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        justifyContent="flex-end"
                    >
                        <img
                            src={props.image}
                            className={classes.image}
                            alt=""
                        />
                    </Grid>
                </Grid>
            </div>
        </Container>
    </div>
);

const YoutubeEmbed = (props) => (
    <div className="video-responsive" style={props.style}>
        <iframe
            width="100%"
            height={props.height}
            src={`https://www.youtube.com/embed/${props.embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);

const ProcessBox = (props) => (
    <div style={props.style} className={classes.processBox}>
        <div className={classes.processBoxInner}>
            <div className={classes.processWrapper}>
                <img src={props.icon} className={classes.processIcon} alt="" />

                <p
                    className={classes.processText}
                    style={{ minHeight: "5.4rem" }}
                >
                    {props.mainText}
                </p>
                <p className={classes.otherText} style={{ margin: "2rem" }}>
                    {props.otherText}
                </p>
            </div>
        </div>
    </div>
);

const ProcessIndicator = (props) => (
    <div className={classes.processIndicatorWrapper}>
        <div
            className={classes.line}
            style={{
                visibility: props.withoutLeftLine ? "hidden" : "visible",
                ...props.leftLineStyle,
            }}
        ></div>
        <div className={classes.outerBox} style={{ ...props.middleStyle }}>
            <div className={classes.innerBox}></div>
        </div>
        <div
            className={classes.line}
            style={{
                visibility: props.withoutRightLine ? "hidden" : "visible",
                ...props.rightLineStyle,
            }}
        ></div>
    </div>
);

const VerticalLine = (props) => (
    <div className={classes.verticalLineWrapper}>
        <div
            className={classes.verticalLine}
            style={{ height: props.height }}
        ></div>
    </div>
);

const Landing3 = (props) => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const { i18nRoute } = useTranslation();

    const isMobile = window.innerWidth <= 959;
    const isSmallest = window.innerWidth <= 599;

    const onTryoutClick = () => {
        if (isAuthenticated) {
            navigate(
                i18nRoute("routes.profile.paymentMethods", {
                    routeHash: "?openSub=true",
                })
            );
        } else {
            props.toggleRegistrationModal(true);
        }
    };

    return (
        <>
            <section
                className={classes.hero}
                style={{ backgroundColor: "#f8f8f8" }}
            >
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <h1>
                                Személyes egészségügyi asszisztens az egész
                                családnak
                            </h1>
                            <h2>Most 30 napig ingyen</h2>
                            <div className={classes.heroBtn}>
                                <BtnWithIcon
                                    color="green"
                                    text="Kérem"
                                    className={classes.button}
                                    onClick={onTryoutClick}
                                />
                            </div>
                        </Grid>
                        <Hidden smDown>
                            <Grid item sm={12}>
                                <div className={classes.heroBox}>
                                    <div className={classes.heroBoxElement}>
                                        <img
                                            src={Icons.qualificationsGreen}
                                            className={classes.icon}
                                            alt=""
                                        />
                                        <div
                                            className={
                                                classes.boxElementWrapper
                                            }
                                        >
                                            <p className={classes.header}>
                                                50+
                                            </p>
                                            <p>Szakterület</p>
                                        </div>
                                    </div>
                                    <div className={classes.heroBoxElement}>
                                        <img
                                            src={Icons.duty2Green}
                                            className={classes.icon}
                                            alt=""
                                        />
                                        <div
                                            className={
                                                classes.boxElementWrapper
                                            }
                                        >
                                            <p className={classes.header}>
                                                70+
                                            </p>
                                            <p>Szakorvos</p>
                                        </div>
                                    </div>
                                    <div className={classes.heroBoxElement}>
                                        <img
                                            src={Icons.hotline2Green}
                                            className={classes.icon}
                                            alt=""
                                        />
                                        <div
                                            className={
                                                classes.boxElementWrapper
                                            }
                                        >
                                            <p className={classes.header}>5</p>
                                            <p>
                                                Mód, hogy kapcsolatba lépj
                                                velünk
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </section>
            <Hidden mdUp>
                <div
                    className={classes.mobileHeroboxes}
                    style={{ backgroundColor: "#f8f8f8" }}
                >
                    <Container>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.heroBox}>
                                    <div className={classes.heroBoxElement}>
                                        <img
                                            src={Icons.qualificationsGreen}
                                            className={classes.icon}
                                            alt=""
                                        />
                                        <div
                                            className={
                                                classes.boxElementWrapper
                                            }
                                        >
                                            <p className={classes.header}>
                                                50+
                                            </p>
                                            <p>Szakterület</p>
                                        </div>
                                    </div>
                                    <div className={classes.heroBoxElement}>
                                        <img
                                            src={Icons.duty2Green}
                                            className={classes.icon}
                                            alt=""
                                        />
                                        <div
                                            className={
                                                classes.boxElementWrapper
                                            }
                                        >
                                            <p className={classes.header}>
                                                70+
                                            </p>
                                            <p>Szakorvos</p>
                                        </div>
                                    </div>
                                    <div className={classes.heroBoxElement}>
                                        <img
                                            src={Icons.hotline2Green}
                                            className={classes.icon}
                                            alt=""
                                        />
                                        <div
                                            className={
                                                classes.boxElementWrapper
                                            }
                                        >
                                            <p className={classes.header}>5</p>
                                            <p>
                                                Mód, hogy kapcsolatba lépj
                                                velünk
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Hidden>
            <section
                className={classes.information}
                style={{ backgroundColor: isSmallest ? "#f8f8f8" : "" }}
            >
                <Container>
                    <PageHeader
                        text="Biztonság az egész család részére"
                        style={{
                            marginTop: !isSmallest ? "6rem" : "0rem",
                            paddingTop: isSmallest ? "3rem" : "0rem",
                        }}
                    />
                </Container>
                <Hidden smDown>
                    <InfoWrapper
                        text="Azonnali orvosi konzultáció"
                        otherText="Az év 365 napján, napi 12 órában"
                        image={Images.landing32}
                        style={{ backgroundColor: "#fff" }}
                        infoRowClass={classes.infoRowWithHalfBorder}
                    />
                    <InfoWrapper
                        text="Digitális egészségügyi asszisztens"
                        otherText="Ahol az egészséged biztos kezekben van"
                        image={Images.landing33}
                        infoRowClass={classes.infoRow}
                    />
                    <InfoWrapper
                        text="Távkonzultáció és személyes vizit"
                        otherText="Foglalj és konzultálj kedvezményes árakon"
                        image={Images.landing31}
                        style={{ backgroundColor: "#fff" }}
                        infoRowClass={classes.infoRow}
                    />
                </Hidden>

                <Hidden mdUp>
                    <div
                        className={classes.mobileHeroboxes}
                        style={{ backgroundColor: "#f8f8f8" }}
                    >
                        <Container>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div
                                        className={classes.heroBox}
                                        style={{ marginBottom: 0 }}
                                    >
                                        <div
                                            className={classes.heroBoxElement}
                                            style={{ padding: 0, margin: 0 }}
                                        >
                                            <img
                                                src={Images.landing32}
                                                className={classes.mobileImage}
                                                alt=""
                                            />
                                            <div
                                                className={
                                                    classes.boxElementWrapper
                                                }
                                            >
                                                <p
                                                    className={classes.header}
                                                    style={
                                                        styles.mobileMainInfoText
                                                    }
                                                >
                                                    Azonnali orvosi konzultáció
                                                </p>
                                                <p
                                                    style={
                                                        styles.mobileSecondaryText
                                                    }
                                                >
                                                    Az év 365 napján, napi 12
                                                    órában
                                                </p>
                                            </div>
                                        </div>
                                        <VerticalLine height={"3rem"} />
                                        <div
                                            className={classes.heroBoxElement}
                                            style={{ padding: 0, margin: 0 }}
                                        >
                                            <img
                                                src={Images.landing33}
                                                className={classes.mobileImage}
                                                alt=""
                                            />
                                            <div
                                                className={
                                                    classes.boxElementWrapper
                                                }
                                            >
                                                <p
                                                    className={classes.header}
                                                    style={
                                                        styles.mobileMainInfoText
                                                    }
                                                >
                                                    Digitális egészségügyi
                                                    asszisztens
                                                </p>
                                                <p
                                                    style={
                                                        styles.mobileSecondaryText
                                                    }
                                                >
                                                    Ahol az egészséged biztos
                                                    kezekben van
                                                </p>
                                            </div>
                                        </div>
                                        <VerticalLine height={"3rem"} />
                                        <div
                                            className={classes.heroBoxElement}
                                            style={{ padding: 0, margin: 0 }}
                                        >
                                            <img
                                                src={Images.landing31}
                                                className={classes.mobileImage}
                                                alt=""
                                            />
                                            <div
                                                className={
                                                    classes.boxElementWrapper
                                                }
                                            >
                                                <p
                                                    className={classes.header}
                                                    style={
                                                        styles.mobileMainInfoText
                                                    }
                                                >
                                                    Távkonzultáció és személyes
                                                    vizit
                                                </p>
                                                <p
                                                    style={
                                                        styles.mobileSecondaryText
                                                    }
                                                >
                                                    Foglalj és konzultálj
                                                    kedvezményes árakon
                                                </p>
                                            </div>
                                        </div>
                                        <VerticalLine height={"4rem"} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </Hidden>
            </section>
            <section className={classes.commercial}>
                <Container>
                    <h2>Próbáld ki most 30 napig ingyen!</h2>
                    <BtnWithIcon
                        color="yellow"
                        text="Kipróbálom"
                        className={classes.button}
                        onClick={onTryoutClick}
                    />
                    <p style={{ fontWeight: "bold" }}>
                        Basic előfizetés korlátlan hotline-al, számos további
                        kedvezménnyel. Az 1 hónapos díjmentes időszak csak az
                        újonnan regisztráló felhasználókra érvényes. <br />{" "}
                        Részletek{" "}
                        <Link
                            className={classes.link}
                            to={i18nRoute("routes.terms")}
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                        >
                            ÁSZF
                        </Link>
                        .
                    </p>
                </Container>
            </section>
            <section
                className={classes.video}
                style={{ backgroundColor: !isMobile ? "#fff" : "#f8f8f8" }}
            >
                <Container>
                    <PageHeader
                        text="A T-DOC érzés"
                        style={{ margin: "0", padding: "6rem 0" }}
                    />
                    <YoutubeEmbed
                        embedId="A-6Iy8F0Z4c"
                        style={{
                            marginBottom: "0rem",
                            paddingBottom: !isSmallest ? "6rem" : "0rem",
                        }}
                        height={isMobile ? "400" : "600"}
                    />
                </Container>
            </section>
            <section
                className={classes.startToFinish}
                style={{
                    backgroundColor: !isMobile ? "#fff" : "#f8f8f8",
                    height: "100%",
                }}
            >
                <Hidden mdUp>
                    <Container>
                        <PageHeader
                            text="Az elejétől a végéig"
                            style={{ margin: "0", padding: "6rem 0" }}
                        />
                    </Container>
                    <div className={classes.coloredBG}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div
                                        className={classes.heroBox}
                                        style={{ marginTop: "1rem" }}
                                    >
                                        <div
                                            className={classes.heroBoxElement}
                                            style={styles.startToFinishBox}
                                        >
                                            <img
                                                src={Icons.landing3Mobile}
                                                className={classes.processIcon}
                                                style={{ margin: 0 }}
                                                alt=""
                                            />
                                            <div
                                                className={
                                                    classes.boxElementWrapper
                                                }
                                            >
                                                <p
                                                    className={classes.header}
                                                    style={
                                                        styles.mobileMainInfoText
                                                    }
                                                >
                                                    Kezedben a döntés
                                                </p>
                                                <p
                                                    style={
                                                        styles.mobileSecondaryText
                                                    }
                                                >
                                                    Személyes vagy
                                                    távkonzultáció
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={classes.heroBoxElement}
                                            style={styles.startToFinishBox}
                                        >
                                            <img
                                                src={Icons.landing3Chevron}
                                                className={classes.processIcon}
                                                style={{ margin: 0 }}
                                                alt=""
                                            />
                                            <div
                                                className={
                                                    classes.boxElementWrapper
                                                }
                                            >
                                                <p
                                                    className={classes.header}
                                                    style={
                                                        styles.mobileMainInfoText
                                                    }
                                                >
                                                    Útba igazítunk
                                                </p>
                                                <p
                                                    style={
                                                        styles.mobileSecondaryText
                                                    }
                                                >
                                                    Segítünk eldönteni, hogy
                                                    merre indulj
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={classes.heroBoxElement}
                                            style={styles.startToFinishBox}
                                        >
                                            <img
                                                src={Icons.landing3Handshake}
                                                className={classes.processIcon}
                                                style={{ margin: 0 }}
                                                alt=""
                                            />
                                            <div
                                                className={
                                                    classes.boxElementWrapper
                                                }
                                            >
                                                <p
                                                    className={classes.header}
                                                    style={
                                                        styles.mobileMainInfoText
                                                    }
                                                >
                                                    Nem engedjük el a keze
                                                </p>
                                                <p
                                                    style={
                                                        styles.mobileSecondaryText
                                                    }
                                                >
                                                    Segítünk egészségügyi
                                                    problémád megoldásában
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={classes.heroBoxElement}
                                            style={styles.startToFinishBox}
                                        >
                                            <img
                                                src={Icons.landing3Doctor}
                                                className={classes.processIcon}
                                                style={{ margin: 0 }}
                                                alt=""
                                            />
                                            <div
                                                className={
                                                    classes.boxElementWrapper
                                                }
                                            >
                                                <p
                                                    className={classes.header}
                                                    style={
                                                        styles.mobileMainInfoText
                                                    }
                                                >
                                                    Veled vagyunk
                                                </p>
                                                <p
                                                    style={
                                                        styles.mobileSecondaryText
                                                    }
                                                >
                                                    Konzultációtól a műtéten át
                                                    a regenerációig
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </Hidden>
                <Hidden smDown>
                    <Container>
                        <PageHeader
                            text="Az elejétől a végéig"
                            style={{ margin: "0", padding: "6rem 0" }}
                        />
                        <Grid
                            container
                            style={{ minHeight: "431px" }}
                            spacing={4}
                        >
                            <Grid item sm={3}>
                                <ProcessBox
                                    // style={{backgroundColor: 'rgb(31, 186, 156, 1)'}}
                                    style={{
                                        background:
                                            "linear-gradient(to bottom right, rgb(60, 176, 153, 1), 50%, rgb(57, 172, 163, 1))",
                                    }}
                                    icon={Icons.landing3Mobile}
                                    mainText="Kezedben a döntés"
                                    otherText="Személyes vagy távkonzultáció"
                                />
                            </Grid>
                            <Grid item sm={3}>
                                <ProcessBox
                                    // style={{background: 'linear-gradient(to right, rgb(31, 186, 156, 1), rgb(17, 162, 237, 0.8))'}}
                                    style={{
                                        background:
                                            "linear-gradient(to bottom right, rgb(58, 173, 160, 1), 50%, rgb(55, 167, 182, 1))",
                                    }}
                                    icon={Icons.landing3Chevron}
                                    mainText="Útba igazítunk"
                                    otherText="Segítünk eldönteni, hogy merre indulj"
                                />
                            </Grid>
                            <Grid item sm={3}>
                                <ProcessBox
                                    style={{
                                        background:
                                            "linear-gradient(to bottom right, rgb(56, 168, 175, 1), 50%, rgb(54, 164, 201, 1))",
                                    }}
                                    icon={Icons.landing3Handshake}
                                    mainText="Nem engedjük el a kezed"
                                    otherText="Segítünk egészségügyi problémád megoldásában"
                                />
                            </Grid>
                            <Grid item sm={3}>
                                <ProcessBox
                                    // style={{backgroundColor: 'rgb(17, 162, 237, 0.9)'}}
                                    style={{
                                        background:
                                            "linear-gradient(to bottom right, rgb(54, 166, 189, 1), 50%, rgb(54, 162, 215, 1))",
                                    }}
                                    icon={Icons.landing3Doctor}
                                    mainText="Veled vagyunk"
                                    otherText="Konzultációtól a műtéten át a regenerációig"
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item sm={3}>
                                <ProcessIndicator
                                    withoutLeftLine={true}
                                    middleStyle={{
                                        backgroundColor: "rgb(57, 172, 163, 1)",
                                    }}
                                    rightLineStyle={{
                                        background:
                                            "linear-gradient(to right, rgb(57, 172, 163, 1), rgb(58, 173, 160, 1))",
                                    }}
                                />
                            </Grid>
                            <Grid item sm={3}>
                                <ProcessIndicator
                                    leftLineStyle={{
                                        background:
                                            "linear-gradient(to right,  rgb(58, 173, 160, 1), rgb(55, 167, 182, 1))",
                                    }}
                                    middleStyle={{
                                        backgroundColor: "rgb(55, 167, 182, 1)",
                                    }}
                                    rightLineStyle={{
                                        background:
                                            "linear-gradient(to right,  rgb(55, 167, 182, 1), rgb(56, 168, 175, 1))",
                                    }}
                                />
                            </Grid>
                            <Grid item sm={3}>
                                <ProcessIndicator
                                    leftLineStyle={{
                                        background:
                                            "linear-gradient(to right, rgb(56, 168, 175, 1), rgb(54, 164, 201, 1))",
                                    }}
                                    middleStyle={{
                                        backgroundColor: "rgb(54, 164, 201, 1)",
                                    }}
                                    rightLineStyle={{
                                        background:
                                            "linear-gradient(to right, rgb(54, 164, 201, 1), rgb(54, 166, 189, 1))",
                                    }}
                                />
                            </Grid>
                            <Grid item sm={3}>
                                <ProcessIndicator
                                    withoutRightLine={true}
                                    leftLineStyle={{
                                        background:
                                            "linear-gradient(to right, rgb(54, 166, 189, 1), rgb(54, 162, 215, 1))",
                                    }}
                                    middleStyle={{
                                        backgroundColor: "rgb(54, 162, 215, 1)",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Hidden>
            </section>
            <section className={classes.subscriptions}>
                <Container>
                    <PageHeader
                        text="Nagyban kezdenéd?"
                        style={{ marginTop: "6rem" }}
                    />
                    <PageSubheader
                        text="Válaszd ki a hozzád illő csomagot!"
                        style={{
                            fontSize: !isSmallest ? "3.2rem" : "2.4rem",
                            marginBottom: isSmallest ? "4rem" : "",
                        }}
                    ></PageSubheader>
                    <Hidden smDown>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <SubscriptionCard
                                    className={classes.card}
                                    name="Basic"
                                    yellowText="30 napig ingyenes"
                                    buttonComponent={
                                        <BtnWithIcon
                                            color="yellow"
                                            text="Kipróbálom"
                                            className={classes.button}
                                            onClick={() =>
                                                props.toggleRegistrationModal(
                                                    true
                                                )
                                            }
                                        />
                                    }
                                    hasTrialPeriod
                                    description="Az egészségügyi hotline a hét minden napján rendelkezésedre áll, így egészségügyi probléma esetén azonnal választ kaphatsz kérdéseidre."
                                    price="4990"
                                    data={basic}
                                    open={props.subscriptionDetails}
                                ></SubscriptionCard>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <SubscriptionCard
                                    className={classes.card}
                                    name="Optimum"
                                    greenText="Ajánlott"
                                    onButtonClick={() =>
                                        props.toggleRegistrationModal(true)
                                    }
                                    description="Hívd közvetlenül az orvosi ügyeletet vagy írd meg kérdésed üzenetben! Ha bizonytalan vagy, hogy kihez fordulj, az egészségügyi hotline segít eligazodnod."
                                    price="9990"
                                    data={optimum}
                                    open={props.subscriptionDetails}
                                ></SubscriptionCard>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <SubscriptionCard
                                    className={classes.card}
                                    name="Premium"
                                    onButtonClick={() =>
                                        props.toggleRegistrationModal(true)
                                    }
                                    description="Legyen mindig kéznél az orvosi segítség! Ha szükséged van rendszeres orvosi konzultációra, és szereted karban tartani egészséged, akkor válaszd a Premium csomagot."
                                    price="29990"
                                    data={premium}
                                    open={props.subscriptionDetails}
                                ></SubscriptionCard>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <SubscriptionCardMobile
                                    className={classes.card}
                                    name="Basic"
                                    yellowText="30 napig ingyenes"
                                    buttonComponent={
                                        <BtnWithIcon
                                            color="yellow"
                                            text="Kipróbálom"
                                            className={classes.button}
                                            onClick={() =>
                                                props.toggleRegistrationModal(
                                                    true
                                                )
                                            }
                                        />
                                    }
                                    hasTrialPeriod
                                    description="Az egészségügyi hotline a hét minden napján rendelkezésedre áll, így egészségügyi probléma esetén azonnal választ kaphatsz kérdéseidre."
                                    price="4990"
                                    data={basic}
                                    open={props.subscriptionDetails}
                                ></SubscriptionCardMobile>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <SubscriptionCardMobile
                                    className={classes.card}
                                    name="Optimum"
                                    greenText="Ajánlott"
                                    onButtonClick={() =>
                                        props.toggleRegistrationModal(true)
                                    }
                                    description="Hívd közvetlenül az orvosi ügyeletet vagy írd meg kérdésed üzenetben! Ha bizonytalan vagy, hogy kihez fordulj, az egészségügyi hotline segít eligazodnod."
                                    price="9990"
                                    data={optimum}
                                    open={props.subscriptionDetails}
                                ></SubscriptionCardMobile>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <SubscriptionCardMobile
                                    className={classes.card}
                                    name="Premium"
                                    onButtonClick={() =>
                                        props.toggleRegistrationModal(true)
                                    }
                                    description="Legyen mindig kéznél az orvosi segítség! Ha szükséged van rendszeres orvosi konzultációra, és szereted karban tartani egészséged, akkor válaszd a Premium csomagot."
                                    price="29990"
                                    data={premium}
                                    open={props.subscriptionDetails}
                                ></SubscriptionCardMobile>
                            </Grid>
                        </Grid>
                    </Hidden>
                    {promotionText}
                </Container>
            </section>
        </>
    );
};

const styles = {
    mobileMainInfoText: {
        textTransform: "uppercase",
        textAlign: "center",
        margin: "2rem",
        fontSize: window.innerWidth <= 599 ? "2rem" : "2.6rem",
    },
    mobileSecondaryText: {
        marginBottom: "2rem",
    },
    startToFinishBox: {
        backgroundColor: "#fff",
        borderRadius: "18px",
    },
};

const mapStateToProps = (state) => ({
    subscriptionDetails: state.ui.subscriptionDetails,
    subscription: state.user.subscription,
});

const mapDispatchToProps = (dispatch) => ({
    toggleQualificationsModal: (show) =>
        dispatch(actions.toggleQualificationsModal(show)),
    toggleRegistrationModal: (show) =>
        dispatch(actions.toggleRegistrationModal(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing3);
