import { useState } from "react";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import cn from "classnames";
import classes from "./FilterSelect.module.css";
import { Icons } from "../../../assets/Theme";

const FilterSelect = ({ items, onSelect }) => {
    const [isOpen, setOpen] = useState(false);
    const [selected, setSelected] = useState(false);

    const handleSelect = (item) => {
        setSelected(item);
        onSelect?.(item);
        setOpen(false);
    };

    return (
        <div className={classes.filterSelect}>
            <div
                className={cn(classes.select, {
                    [classes.active]: isOpen,
                })}
                onClick={() => setOpen((prevState) => !prevState)}
            >
                <img src={Icons.filter} alt="" className={classes.filterIcon} />
                <span className={classes.placeholder}>
                    {selected.label || "Válassz"}
                </span>
                <ArrowDropDownIcon
                    fontSize="large"
                    classes={{ root: classes.icon }}
                />
            </div>

            {isOpen && (
                <ul className={classes.dropdown}>
                    {items?.length > 0 &&
                        items.map((item, index) => (
                            <li
                                key={`filter-select-item-${index}`}
                                onClick={() => handleSelect(item)}
                                className={cn({
                                    [classes.active]:
                                        item.value === selected.value,
                                })}
                            >
                                {item.label}
                            </li>
                        ))}
                </ul>
            )}
        </div>
    );
};

export default FilterSelect;
