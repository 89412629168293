import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Colors, Fonts, Icons, Images } from "../../../assets/Theme";
import ServicePage from "../../../components/UI/ServicePage/ServicePage";
import CustomSlider from "../../../components/UI/CustomSlider/CustomSlider";
import { endLoading, get, post, startLoading } from "../../../config/fetch";
import { formatNumber, isWeekend, responseMessage } from "../../../utility";
import { ALERT_MESSAGES, PAYMENT } from "../../../config/variables";
import PaymentModal from "../../../components/Modals/PaymentModal/PaymentModal";
import * as actions from "../../../store/actions/index";
import {
    ecommerceAddCart,
    ecommerceCheckout,
    ecommerceProductDetail,
    ecommercePurchase,
} from "../../../config/ecommerce";
import SubscriptionAndServiceModal from "../../../components/Modals/SubscriptionAndServiceModal/SubscriptionAndServiceModal";
import useTranslation from "../../../features/i18n/useTranslation";
import useAuth from "../../../features/auth/useAuth";
import useQuery from "../../../features/util/useQuery";

let price = null;
let subscription;

const Duty = (props) => {
    const navigate = useNavigate();
    const query = useQuery();
    const { isAuthenticated } = useAuth();
    const [packages, setPackages] = useState(null);
    const [paymentModal, togglePaymentModal] = useState(false);
    const [people, setPeople] = useState([]);
    const [userSubscribed, setUserSubscribed] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(1);
    const [originalSubscription, setOriginalSubscription] = useState(false);
    const [subscriptionAndServiceModal, toggleSubscriptionAndServiceModal] =
        useState(0);
    const [subscriptionAndServiceModalData, setSubscriptionAndServiceModal] =
        useState({
            freeOccasions: null,
            servicePrices: [],
            service: null,
        });
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        paymentCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paymentCallback = () => {
        if (query.get("TransactionId")) {
            const transactionId = query.get("TransactionId");
            const immediateToken = query.get("immediateToken");
            const oneClickEnabled = query.get("oneClickEnabled");
            const subscription = query.get("subscription");
            const price = query.get("price");
            post("api/v1/payment/payment-init-callback", {
                transactionId,
                cardName: "Bankkártya",
                immediateToken,
                oneClick: oneClickEnabled,
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            endLoading();
                            ecommercePurchase("DUTY", price, subscription);
                            responseMessage(
                                succesFullResponseText(
                                    response.data.ProviderTransactionId
                                )
                            );
                            navigate("/service-initiated?service=duty", {
                                replace: true,
                            });

                            break;
                        case "PAYMENT_ERROR":
                            switch (response.data.errorCode) {
                                case "ERROR":
                                    responseMessage(
                                        unSuccesfullResponseText(
                                            response.data.ProviderTransactionId
                                        )
                                    );
                                    endLoading();

                                    break;
                                case "CANCELED":
                                    responseMessage(
                                        "Megszakítottad a fizetési folyamatot. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "TIMEOUT":
                                    responseMessage(
                                        "Túllépted a tranzakció elindításakor megengedett biztonságos időkaput. Próbáld meg újra."
                                    );
                                    endLoading();

                                    break;
                                case "PENDING":
                                    startLoading();
                                    setTimeout(paymentCallback, 3000);

                                    break;
                            }
                            navigate(i18nRoute("routes.services.duty"), {
                                replace: true,
                            });

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                            endLoading();
                            navigate(i18nRoute("routes.services.duty"), {
                                replace: true,
                            });
                    }

                    localStorage.removeItem("newCardName");
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    navigate(i18nRoute("routes.services.duty"), {
                        replace: true,
                    });
                });
        }
    };

    const succesFullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikeres tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
        </>
    );

    const unSuccesfullResponseText = (transactionId) => (
        <>
            <p style={{ marginBottom: "1rem" }}>Sikertelen tranzakció</p>
            <p style={{ marginBottom: "1rem" }}>
                SimplePay tranzakció azonosító: {transactionId}
            </p>
            <p style={{ marginBottom: "1rem" }}>
                Kérjük, ellenőrizze a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adott meg, a
                visszautasítás okának kivizsgálása érdekében kérjük,
                szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
            </p>
        </>
    );

    const processPackages = (packages, subscribedIndex, dynamicData = null) => {
        setSelectedPackage(+subscribedIndex);
        setOriginalSubscription(+subscribedIndex);
        const _packages = [];
        const transactionId = query.get("TransactionId");
        if (!transactionId) {
            if (dynamicData) {
                ecommerceProductDetail(
                    "DUTY",
                    dynamicData.price,
                    +dynamicData.userPackage
                );
            } else {
                ecommerceProductDetail(
                    "DUTY",
                    packages[0].price[isWeekend ? 1 : 0],
                    subscribedIndex
                );
            }
        }
        const packagePrices = [null];
        const freeOccasions = [null];

        packages.forEach((p, i) => {
            packagePrices.push(p.price);
            freeOccasions.push(p.freePerMonth);

            if (i === 2 || i === 3) {
                _packages.push({
                    name: `${p.packageName} előfizetéssel`,
                    price: `Korlátlan`,
                });
            } else if (dynamicData && subscribedIndex - 1 == i) {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        dynamicData.price !== 0
                            ? `${formatNumber(dynamicData.price)} Ft`
                            : `Ingyenes`,
                    subtext:
                        dynamicData.price === 0 &&
                        dynamicData.remainingServices > 0
                            ? `Hátralévő: ${dynamicData.remainingServices}`
                            : "",
                });
            } else {
                _packages.push({
                    name:
                        i !== 0
                            ? `${p.packageName} előfizetéssel`
                            : "Előfizetés nélkül",
                    price:
                        p.freePerMonth > 0
                            ? `${p.freePerMonth} ingyenes`
                            : `${formatNumber(p.price[0])} Ft / hívás`,
                    subtext:
                        p.freePerMonth > 0
                            ? `*utána ${formatNumber(
                                  p.price[0]
                              )} Ft / ${formatNumber(p.price[1])} Ft /hívás`
                            : `*hétvégén ${formatNumber(p.price[1])} Ft /hívás`,
                });
            }
        });
        setSubscriptionAndServiceModal({
            service: "DUTY",
            servicePrices: packagePrices,
            freeOccasions,
        });

        return _packages;
    };

    useEffect(() => {
        getPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPrices = () => {
        if (isAuthenticated) {
            const promise1 = post(
                "api/v1/subscription/get-service-prices-by-user",
                { service: "DUTY" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        return response.data;
                }
            });
            const promise2 = post(
                "api/v1/subscription/check-user-service-price",
                { service: "DUTY" }
            ).then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        price = response.data.data.price;
                        setUserSubscribed(response.data.data.price < 1);

                        return response.data;
                }
            });
            Promise.all([promise1, promise2]).then((responses) => {
                endLoading();
                const response1 = responses[0];
                const response2 = responses[1];
                let _packages = processPackages(
                    response1.packages,
                    response1.subscription ? response1.subscription : 1,
                    response2.data
                );
                setPackages(_packages);
            });
        } else {
            post("api/v1/subscription/get-service-prices-by-user", {
                service: "DUTY",
            }).then((response) => {
                endLoading();
                if (response.data.responseCode === "OK") {
                    let _packages = processPackages(
                        response.data.packages,
                        response.data.subscription
                            ? response.data.subscription
                            : 1
                    );
                    setPackages(_packages);
                }
            });
        }
    };

    useEffect(() => {
        get("api/v1/medical/get-duty-doctors", false)
            .then((response) => setPeople(response.data.data))
            .catch((error) => console.error(error));
    }, []);

    const startButtonHandler = () => {
        if (isAuthenticated) {
            post("api/v1/subscription/check-user-service-price", {
                service: "DUTY",
            })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            price = response.data.data.price;
                            subscription = response.data.data.userPackage;
                            ecommerceAddCart(
                                "DUTY",
                                response.data.data.price,
                                response.data.data.userPackage
                            );
                            // setUserSubscribed(response.data.data.price < 1);
                            // togglePaymentModal(true);
                            toggleSubscriptionAndServiceModal(2);

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                });
        } else {
            props.toggleLoginModal(true);
        }
    };

    const initCall = () => {
        ecommerceCheckout("DUTY", price, subscription);
        post("api/v1/call/init-call", { type: "DUTY" })
            .then((response) => {
                endLoading();
                toggleSubscriptionAndServiceModal(0);
                switch (response.data.responseCode) {
                    case "OK":
                        ecommercePurchase("DUTY", price, subscription);
                        navigate("/service-initiated?service=duty");

                        break;
                    case "PAYMENT_FAILED":
                        responseMessage(PAYMENT.PAYMENT_FAILED);

                        break;
                    case "EMPTY_DEFAULT_CARD":
                        post("api/v1/subscription/check-user-service-price", {
                            service: "DUTY",
                        })
                            .then((response) => {
                                endLoading();
                                switch (response.data.responseCode) {
                                    case "OK":
                                        props.toggleServicePaymentModal(
                                            true,
                                            response.data.data.summaryAmount,
                                            "DUTY"
                                        );

                                        break;
                                    default:
                                        responseMessage(
                                            ALERT_MESSAGES.errorTryAgain
                                        );

                                        break;
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                endLoading();
                            });

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    };

    const redeemCoupon = (code) => {
        if (code) {
            post("api/v1/coupon/redeem-coupon", { couponCode: code })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("A kupont sikeresen beváltottad!");
                            getPrices();

                            break;
                        case "WRONG_CODE":
                            responseMessage("Hibás kuponkód!");

                            break;
                        case "ALREADY_REDEEMED":
                            responseMessage(
                                "Ezt a kupont már beváltottad egyszer!"
                            );

                            break;
                        case "ONLY_WITHOUT_SUBSCRIPTION":
                            responseMessage(
                                "Ezt a kupont csak előfizetés nélkül lehet beváltani!"
                            );

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const resetSubscription = () => setSelectedPackage(originalSubscription);

    const onPackageClick = (pckg) => {
        setSelectedPackage(pckg);
        toggleSubscriptionAndServiceModal(1);
    };

    return (
        <ServicePage
            icon={Icons.dutyGreen}
            name="Általános orvosi konzultáció"
            description="Közvetlen orvosi kapcsolat egészségügyi panasz esetén. Vedd igénybe általános orvos konzultációnkat, beszélj közvetlenül orvos kollégáinkkal néhány kattintást követően."
            image={Images.orvosiUgyelet}
            imageAlt="Általános orvosi konzultáció | T-DOC"
            packages={packages}
            selectedPackage={selectedPackage}
            originalSubscription={originalSubscription}
            buttonText="Kérem a telefonszámot"
            buttonCallback={startButtonHandler}
            packageResetClick={resetSubscription}
            packageClick={onPackageClick}
            asterisks={[]}
        >
            <p style={styles.text}>
                Közvetlen orvosi kapcsolat egészségügyi panasz esetén. Vedd
                igénybe telefonos általános orvosi konzultációnkat, beszélj
                közvetlenül orvos kollégáinkkal néhány kattintást követően.
            </p>

            <h1 style={{ ...styles.title, fontSize: "3rem" }}>
                Általános telefonos orvosi konzultáció
            </h1>

            <h2 style={styles.title}>
                Mikor érdemes igénybe venni a telefonos általános orvosi
                konzultációt?
            </h2>
            <p style={styles.text}>
                <ul style={styles.ul}>
                    <li>
                        Prevenció, egészségmegőrzéssel és egészséges életmóddal
                        kapcsolatos kérdések esetén.
                    </li>
                    <li>
                        Általános orvosi tanácsadás telefonon keresztül az
                        alábbi témákban:
                        <ul style={styles.ul}>
                            <li>
                                Laboratóriumi eredmények és ezekből eredő
                                összefüggések magyarázata.
                            </li>
                            <li>Általános orvosi eljárások magyarázata</li>
                            <li>Orvosi szakkifejezések magyarázata</li>
                        </ul>
                    </li>
                    <li>
                        Sürgősségi, orvosi, fogorvosi, gyógyszertári ügyeletek
                        elérhetősége és ügyelete idejéről történő általános
                        tájékoztatás. Ügyeletek elérhetősége térképes{" "}
                        <a href="https://t-doc.hu/ugyeletkereso">
                            ügyeletkereső
                        </a>{" "}
                        oldalunkon is elérhető.
                    </li>
                </ul>
            </p>

            <h2 style={styles.title}>
                A telefonos általános orvosi konzultáció működése
            </h2>
            <p style={styles.text}>
                Panasz esetén{" "}
                <span style={styles.strong}>
                    háziorvos kollégáink rendelkezésedre állnak az év 365 napján
                </span>
                . Ha kérdésed van, segítséget vagy tanácsot kérnél, akkor hívd
                orvosainkat! Regisztrálj, válaszd ki a számodra megfelelő T-DOC
                előfizetést, vagy vidd végig a fizetési folyamatot, és már
                hívhatod is éppen elérhető orvosaink egyikét.
            </p>

            <h2 style={styles.title}>
                Fontos információk az általános orvosi konzultációról
            </h2>

            <p style={styles.text}>
                Az általános telefonos orvosi konzultáció nem minősül
                egészségügyi ellátásnak. A konzultáció során nyújtott
                információk kizárólag tájékoztató jellegűek, és nem
                helyettesíthetők a személyes orvosi vizsgálatot. Receptfelírást,
                kórházba és egészségügyi intézményekbe történő beutalást nem
                végez.
                <br />
                <br />
                <span style={styles.strong}>
                    Milyen esetben NE hívd az általános telefonos orvosi
                    konzultációt?
                </span>
                <ul style={styles.ul}>
                    <li>Ügyeleti jellegű kérdésekkel (sürgősségi ellátás)</li>
                    <li>Nehézlégzés, mellkasi fájdalom</li>
                    <li>Súlyos egészségügyi panasz, életveszély esetén</li>
                    <li>Eszméletvesztés, csillapíthatatlan fájdalom</li>
                    <li>
                        Súlyos sérülés, súlyos allergiás tünet, mérgezés,
                        csillapíthatatlan vérzés
                    </li>
                    <li>Vérköpés, vérhányás, elhúzódó hányás vagy hasmenés</li>
                    <li>
                        A mentális állapot megváltozása, zavartság, szokatlan
                        viselkedés, öngyilkossági gondolatok
                    </li>
                    <li>
                        Hirtelen fellépő, soha nem tapasztalt erősségű fejfájás,
                        látászavar
                    </li>
                    <li>Ellátást igénylő seb, égési sérülés</li>
                    <li>
                        Törött csont, fejsérülés, hirtelen keletkező ízületi
                        fájdalom
                    </li>
                    <li>Nem szűnő szédülés, gyengeség</li>
                    <li>Tartós, csillapíthatatlan láz</li>
                    <li>Szapora és/vagy rendszertelen szívdobogásérzés</li>
                    <li>
                        40/perc alatti pulzusszám, melyhez gyengeség, szédülés,
                        a tudatállapot megváltozása, ájulásérzés, illetve
                        eszméletvesztés társul.
                    </li>
                </ul>
                Amennyiben a fenti tünetek valamelyikét tapasztalod, keresd fel
                a területileg illetékes sürgősségi ellátó helyet, vagy hívd az
                országos mentőszolgálatot.
            </p>
            <h2 style={styles.title}>
                Betegellátás a telefonos segítségkérés után
            </h2>
            <p style={styles.text}>
                Orvosunkkal történő egyeztetést követően sem hagyunk egyedül!
                Amennyiben nem akut egészségügyi ellátásra van szükséged, úgy{" "}
                <span style={styles.strong}>
                    segítünk eljutni a megfelelő szakorvoshoz is
                </span>
                . Orvosunk tanácsa alapján választhatod a T-DOC oldalán történő
                online kapcsolatfelvételt szakorvosaink egyikével, személyes
                vizit esetén pedig segítünk kiválasztani a megfelelő szakembert,
                egészségügyi intézményt és időpontot is.
                <br />
                <br />
                Ha szeretnéd, hogy egy{" "}
                <span style={styles.strong}>
                    „saját egészségügyi asszisztens” kísérjen végig az úton
                </span>
                , úgy hívd díjmentesen előfizetéseinken belül Egészségügyi
                Hotline-os kollégáinkat, akik segítenek abban, hogy a
                leggyorsabban, a legjobb kezekbe kerülj.
            </p>

            <h2 style={styles.title}>
                Telefonos általános orvosi konzultáció elérhetőség
            </h2>
            <p style={styles.text}>Az év 365 napján, 7/24.</p>

            <CustomSlider data={people} withAvailability people />
            <PaymentModal
                closeModal={() => togglePaymentModal(false)}
                open={paymentModal}
                userSubscribed={userSubscribed}
                infoText="A folytatásra kattintva tárcsázhatja az ügyeletet, rendszerünk a számlájáról pedig automatikusan levonja a megjelölt összeget"
                boxTitle={
                    !price
                        ? "Előfizetésben fogalt ügyelet hívása"
                        : "Előfizetés ügyelet hívási díja"
                }
                price={price}
                couponHandler={redeemCoupon}
                onContinueClick={initCall}
            />
            <SubscriptionAndServiceModal
                outerSelectedPackage={selectedPackage}
                selectCallback={setSelectedPackage}
                normalInit={initCall}
                open={subscriptionAndServiceModal}
                data={subscriptionAndServiceModalData}
                toggleSubscriptionAndServiceModal={
                    toggleSubscriptionAndServiceModal
                }
                succesfulSubscribeAndServiceCallback={() =>
                    navigate("/service-initiated?service=duty")
                }
            />
        </ServicePage>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontWeight: 700,
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
        marginTop: "3rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
        marginBottom: "1rem",
    },
    strong: {
        fontWeight: 700,
    },
    ul: {
        listStyle: "circle",
        paddingLeft: "3rem",
    },
};

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (show) => dispatch(actions.toggleLoginModal(show)),
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
    toggleServicePaymentModal: (show, price, service) =>
        dispatch(actions.toggleServicePaymentModal(show, price, service)),
});

export default connect(null, mapDispatchToProps)(Duty);
