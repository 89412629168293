import { forwardRef, useImperativeHandle, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import CountryPicker from "../CountryPicker/CountryPicker";
import Input from "../Input/Input";
import classes from "./BillingForm.module.css";

const Title = (props) => (
    <p
        className={classes.textLabel}
        style={{
            color: !props.value && props.required ? "red" : null,
            ...props.style,
        }}
    >
        {props.text}
    </p>
);

const BillingForm = forwardRef((props, ref) => {
    const billingData = props.data;

    const [data, setData] = useState({
        name: billingData.name ?? props.defaultBillingName,
        country: billingData.country,
        zip: billingData.zip,
        settlement: billingData.settlement,
        street: billingData.street,
        houseNumber: billingData.house_number,
        floorDoor: billingData.floor_door,
        taxNumber: billingData.tax_number,
    });

    const [expanded, setExpanded] = useState(false);

    const onInputChange = (key, value) => {
        if (value) {
            setData({ ...data, [key]: value });
        } else {
            setData({ ...data, [key]: null });
        }
    };

    useImperativeHandle(ref, () => ({ data }), [data]);

    const useStyles = makeStyles({
        accordion: {
            borderRadius: "4px",
            "&::before": {
                display: "none",
            },
            border: "1px solid #929ea7",
            // border: 'none',
            boxShadow: "none",
        },
        expanded: {
            // border: 'none'
            padding: "1.6rem",
        },
        summaryExpanded: {
            paddingLeft: "0",
            fontWeight: "bold",
        },
        accordionDetails: {
            display: "block",
            padding: "0",
        },
    });

    const styles = useStyles();

    return (
        <>
            <p className={classes.mainTitle}>{props.mainTitle}</p>

            {!billingData.name && (
                <>
                    <Title
                        value={data.name}
                        text="Számlázási név"
                        required={true}
                    />
                    <Input
                        defaultValue={data.name}
                        onChange={(e) => onInputChange("name", e.target.value)}
                    />
                </>
            )}

            {!billingData.country && (
                <>
                    <Title
                        value={data.country}
                        text="Ország"
                        required={false}
                    />
                    <CountryPicker
                        value={data.country}
                        onChange={(e) => onInputChange("country", e)}
                        style={{ marginBottom: "2rem" }}
                    />
                </>
            )}

            {!billingData.zip && (
                <>
                    <Title
                        value={data.zip}
                        text="Irányítószám"
                        required={true}
                    />
                    <Input
                        defaultValue={data.zip}
                        onChange={(e) => onInputChange("zip", e.target.value)}
                    />
                </>
            )}

            {!billingData.settlement && (
                <>
                    <Title
                        value={data.settlement}
                        text="Település"
                        required={true}
                    />
                    <Input
                        defaultValue={data.settlement}
                        onChange={(e) =>
                            onInputChange("settlement", e.target.value)
                        }
                    />
                </>
            )}

            {!billingData.street && (
                <>
                    <Title value={data.street} text="Utca" required={true} />
                    <Input
                        defaultValue={data.street}
                        onChange={(e) =>
                            onInputChange("street", e.target.value)
                        }
                    />
                </>
            )}

            {!billingData.house_number && (
                <>
                    <Title
                        value={data.houseNumber}
                        text="Házszám"
                        required={true}
                    />
                    <Input
                        defaultValue={data.houseNumber}
                        onChange={(e) =>
                            onInputChange("houseNumber", e.target.value)
                        }
                    />
                </>
            )}

            <Accordion
                classes={{ root: styles.accordion, expanded: styles.expanded }}
                expanded={expanded}
                onChange={() => setExpanded((prev) => !prev)}
            >
                <AccordionSummary
                    classes={{ expanded: styles.summaryExpanded }}
                    expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1bh-content"
                    // id="panel1bh-header"
                >
                    <p style={{ fontWeight: "bold" }}>Számlázási adatok</p>
                </AccordionSummary>
                <AccordionDetails classes={{ root: styles.accordionDetails }}>
                    {billingData.name && (
                        <>
                            <Title
                                value={data.name}
                                text="Számlázási név"
                                required={true}
                            />
                            <Input
                                defaultValue={data.name}
                                onChange={(e) =>
                                    onInputChange("name", e.target.value)
                                }
                            />
                        </>
                    )}

                    {billingData.country && (
                        <>
                            <Title
                                value={data.country}
                                text="Ország"
                                required={true}
                            />
                            <CountryPicker
                                value={data.country}
                                onChange={(e) => onInputChange("country", e)}
                                style={{ marginBottom: "2rem" }}
                            />
                        </>
                    )}

                    {billingData.zip && (
                        <>
                            <Title
                                value={data.zip}
                                text="Irányítószám"
                                required={true}
                            />
                            <Input
                                defaultValue={data.zip}
                                onChange={(e) =>
                                    onInputChange("zip", e.target.value)
                                }
                            />
                        </>
                    )}

                    {billingData.settlement && (
                        <>
                            <Title
                                value={data.settlement}
                                text="Település"
                                required={true}
                            />
                            <Input
                                defaultValue={data.settlement}
                                onChange={(e) =>
                                    onInputChange("settlement", e.target.value)
                                }
                            />
                        </>
                    )}

                    {billingData.street && (
                        <>
                            <Title
                                value={data.street}
                                text="Utca"
                                required={true}
                            />
                            <Input
                                defaultValue={data.street}
                                onChange={(e) =>
                                    onInputChange("street", e.target.value)
                                }
                            />
                        </>
                    )}

                    {billingData.house_number && (
                        <>
                            <Title
                                value={data.houseNumber}
                                text="Házszám"
                                required={true}
                            />
                            <Input
                                defaultValue={data.houseNumber}
                                onChange={(e) =>
                                    onInputChange("houseNumber", e.target.value)
                                }
                            />
                        </>
                    )}

                    <>
                        <Title
                            value={data.floorDoor}
                            text="Emelet / Ajtó"
                            required={false}
                        />
                        <Input
                            defaultValue={data.floorDoor}
                            onChange={(e) =>
                                onInputChange("floorDoor", e.target.value)
                            }
                        />
                    </>

                    <>
                        <Title
                            value={data.taxNumber}
                            text="Adószám"
                            required={false}
                        />
                        <Input
                            defaultValue={data.taxNumber}
                            onChange={(e) =>
                                onInputChange("taxNumber", e.target.value)
                            }
                        />
                    </>
                </AccordionDetails>
            </Accordion>
        </>
    );
});

BillingForm.displayName = "BillingForm";

export default BillingForm;
