import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export enum Lng {
    hu = "hu",
    en = "en",
    de = "de",
    sk = "sk",
    ro = "ro",
}

export enum LngTitle {
    hu = "Magyar",
    en = "English",
    de = "Deutsch",
    sk = "Slovenský",
    ro = "Română",
}

export const supportedLngs = [Lng.hu, Lng.en, Lng.de, Lng.sk, Lng.ro];
export const supportedLngsAsString = supportedLngs.map((lng) => lng.toString());

const lng = process.env.REACT_APP_FEATURE_I18N ? undefined : Lng.hu;

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: Lng.hu,
        lng,
        supportedLngs,
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
