import MessageIcon from "../assets/Message.svg";
import AttachmentIcon from "../assets/Attachment.svg";
import ResponseMessageIcon from "../assets/ResponseMessage.svg";

export const heroElements = [
    {
        icon: MessageIcon,
        text: "Korlátlan számú karakter",
    },
    {
        icon: AttachmentIcon,
        text: "Fájl vagy fotó feltöltés",
    },
    {
        icon: ResponseMessageIcon,
        text: "48 órán belüli válaszadás",
    },
];
