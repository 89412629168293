import { Link } from "react-router-dom";
import { Images } from "../../assets/Theme";
import classes from "./Logo.module.css";

const logo = (props) => {
    const logoImage = props.logoImage || Images.logoWhite;
    if (props.home) {
        return (
            <Link to="/" style={props.style}>
                <img
                    alt="Főoldal"
                    src={logoImage}
                    className={`${classes.Logo} ${classes.homeLogo}`}
                />
            </Link>
        );
    }

    return <img alt="" src={Images.logo} className={classes.Logo} />;
};

export default logo;
