import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop } from "@material-ui/core";

interface Props {
    open?: boolean;
}

const Progress = ({ open = true }: Props) => (
    <Backdrop open={open} style={{ zIndex: 10000 }}>
        <CircularProgress color="primary" />
    </Backdrop>
);

export default Progress;
