import { Link } from "@material-ui/core";
import {
    Clear as ClearIcon,
    Devices as DevicesIcon,
    PhoneIphone as PhoneIphoneIcon,
} from "@material-ui/icons";
import Panel from "../atoms/Panel";
import classes from "./DeviceInfo.module.css";

const DeviceInfo = ({ label, device, type, onClick }) => {
    const handleClick = () => {
        onClick?.();
    };

    return (
        <div className={classes.item}>
            <label>{label}</label>
            <div className={classes.itemRow}>
                <Panel direction="row" style={{ marginBottom: 0 }}>
                    {type === "Desktop" ? (
                        <DevicesIcon
                            fontSize="large"
                            style={{ color: "#263D50" }}
                        />
                    ) : (
                        <PhoneIphoneIcon
                            fontSize="large"
                            style={{ color: "#263D50" }}
                        />
                    )}
                    <div>{device}</div>
                </Panel>
                <Link
                    component="button"
                    onClick={handleClick}
                    className={classes.revoke}
                >
                    <span>Kijelentkezés</span> <ClearIcon fontSize="large" />
                </Link>
            </div>
        </div>
    );
};

export default DeviceInfo;
