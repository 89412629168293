import { Container } from "@material-ui/core";
import { Route, Routes } from "react-router-dom";
import Breadcrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import CardContainer from "../UI/CardContainer/CardContainer";
import classes from "./FAQ.module.css";
import Topics from "./Topics/Topics";
import Summary from "./Summary";

const FAQ = () => (
    <Container>
        <div className={classes.inner}>
            <Breadcrumbs replaceKeys={{ szolgaltatasok: "Szolgáltatások" }} />
            <CardContainer className={classes.card}>
                <Routes>
                    <Route path="/" element={<Summary />} />
                    <Route path="*" element={<Topics />} />
                </Routes>
            </CardContainer>
        </div>
    </Container>
);

export default FAQ;
