import { Box, Grid } from "@material-ui/core";
import type { BlogItem } from "../../ui/models";
import BlogItemComponent from "./BlogItem";
import { showBlogItem } from "./helpers";

interface Props {
    blogs: BlogItem[];
    selectedTopic: string | null;
}

const BlogItems = ({ blogs, selectedTopic }: Props) => {
    if (selectedTopic !== null) {
        return (
            <Grid container>
                {blogs.map((blogItem: BlogItem, index) => {
                    if (showBlogItem(blogItem, selectedTopic)) {
                        return (
                            <Grid
                                key={`blog-item-grid-${index}`}
                                item
                                xs={12}
                                sm={6}
                                md={3}
                            >
                                <Box display="flex" justifyContent="center">
                                    <BlogItemComponent blogItem={blogItem} />
                                </Box>
                            </Grid>
                        );
                    }

                    return null;
                })}
            </Grid>
        );
    }

    return (
        <Grid container>
            {blogs.map((blogItem: BlogItem, index) => (
                <Grid
                    key={`blog-item-grid2-${index}`}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                >
                    <Box display="flex" justifyContent="center">
                        <BlogItemComponent blogItem={blogItem} />
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default BlogItems;
