import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../assets/Theme";
import AlertLine from "../../components/UI/AlertLine/AlertLine";
import Breadcrumbs from "../../components/UI/Breadcrumbs/Breadcrumbs";
import PageHeader from "../../components/UI/PageHeader/PageHeader";
import ListingCard from "../../components/UI/ListingCard/ListingCard";
import Line from "../../components/UI/Line/Line";
import { endLoading, get } from "../../config/fetch";
import { removeAccents } from "../../utility";
import useTranslation from "../../features/i18n/useTranslation";
import InstitutionFilter from "./InstitutionFilter/InstitutionFilter";
import classes from "./Institutions.module.css";

let filters = {
    abc: null,
    qualification: null,
    city: null,
};

const Institutions = () => {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const [institutions, setInstitutions] = useState([]);
    const [filteredInstitutions, setFilteredInstitutions] = useState([]);
    const { i18nRoute } = useTranslation();

    useEffect(() => {
        get("api/v1/medical/get-institutions")
            .then((response) => {
                endLoading();
                setInstitutions(response.data.data);
                setFilteredInstitutions(response.data.data);
                setIsLoaded(true);
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    }, []);

    const onAlertClick = () =>
        navigate(i18nRoute("routes.services.outPatient"));

    const filterByAbc = (institutions, arr) => {
        let filteredInstitutions;
        const startCode = arr[0];
        const endCode = arr[1];
        filteredInstitutions = institutions.filter((inst) => {
            const code = inst.name.toLowerCase().substring(0, 1).charCodeAt(0);

            return code >= startCode && code <= endCode;
        });

        return filteredInstitutions;
    };

    const filterByQualification = (institutions, id) =>
        institutions.filter((inst) =>
            inst.qualifications.some((q) => q.id == id)
        );

    const filterByCity = (institutions, id) =>
        institutions.filter((inst) => inst.city == id);

    const onFilterChange = (value, filter) => {
        switch (filter) {
            case "abc":
                if (value === 0) {
                    filters["abc"] = null;
                } else {
                    filters["abc"] = value;
                }

                break;
            case "qualification":
                if (value === 0) {
                    filters["qualification"] = null;
                } else {
                    filters["qualification"] = value;
                }

                break;
            case "institution":
                if (value === 0) {
                    filters["institution"] = null;
                } else {
                    filters["institution"] = value;
                }

                break;
            case "city":
                if (value === 0) {
                    filters["city"] = null;
                } else {
                    filters["city"] = value;
                }

                break;
            default:
                break;
        }
        let filteredInstitutionArr = institutions;
        for (const f in filters) {
            if (filters[f]) {
                switch (f) {
                    case "abc":
                        filteredInstitutionArr = filterByAbc(
                            filteredInstitutionArr,
                            filters[f]
                        );

                        break;
                    case "qualification":
                        filteredInstitutionArr = filterByQualification(
                            filteredInstitutionArr,
                            filters[f]
                        );

                        break;
                    case "city":
                        filteredInstitutionArr = filterByCity(
                            filteredInstitutionArr,
                            filters[f]
                        );

                        break;
                    default:
                        break;
                }
            }
        }
        setFilteredInstitutions(filteredInstitutionArr);
    };

    const resetFilters = () => {
        setFilteredInstitutions(institutions);
        filters = {
            abc: null,
            qualification: null,
            city: null,
        };
    };

    const formatText = (text) =>
        removeAccents(text)
            .toLowerCase()
            .trim()
            .replace(/\s+/g, "")
            .replace(".", "");

    const onSearchTextChange = (text) => {
        if (text.length < 3) {
            setFilteredInstitutions(institutions);

            return;
        }
        let filteredInstitutionArr = institutions.filter((inst) => {
            const nameString = formatText(inst.name);
            const qualificationArr = inst.qualifications.map((q) => q.name);
            const qualificationString = formatText(qualificationArr.join(""));

            return (
                nameString.includes(text) || qualificationString.includes(text)
            );
        });
        setFilteredInstitutions(filteredInstitutionArr);
    };

    return (
        <Container>
            <div className={classes.inner}>
                <Breadcrumbs />
                <PageHeader
                    text="Partner intézmények"
                    style={styles.PageHeader}
                />
                <AlertLine
                    className={classes.AlertLine}
                    icon={Icons.reservationBlack}
                    text="Kattintson, ha azonnali időpontot szertne foglalni az itt látható egészségügyi intézmények valamelyikébe!"
                    actionText="Időpontot foglalok"
                    style={{ marginBottom: "4rem" }}
                    onClick={onAlertClick}
                />
                {isLoaded && (
                    <InstitutionFilter
                        institutionsData={institutions}
                        onSearchTextChange={(text) => onSearchTextChange(text)}
                        onFilterChange={(value, filter) =>
                            onFilterChange(value, filter)
                        }
                        onResetFilters={resetFilters}
                    />
                )}
                <Line
                    style={{ backgroundColor: "#263D50", marginBottom: "4rem" }}
                ></Line>
                <div className={classes.institutionsContainer}>
                    {filteredInstitutions.map((element) => (
                        <div className={classes.cardContainer} key={element.id}>
                            <ListingCard
                                type="institution"
                                onClick={() =>
                                    navigate({
                                        pathname: `/partner-intezmenyek/${element.profile_link}`,
                                    })
                                }
                                city={element.full_address}
                                outPatient={+element.out_patient_available}
                                inPatient={+element.in_patient_available}
                                image={element.image}
                                name={element.name}
                            />
                        </div>
                    ))}
                </div>
                {filteredInstitutions.length === 0 && isLoaded && (
                    <span className={classes.noMatch}>
                        Nem található a keresésnek megfelelő intézmény.
                    </span>
                )}
            </div>
        </Container>
    );
};

const styles = {
    PageHeader: {
        marginBottom: "3rem",
    },
};

export default Institutions;
