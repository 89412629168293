// previously <Input type === "phone" />
import "react-phone-number-input/style.css";
import BasePhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import cn from "classnames";
import classes from "./PhoneInput.module.css";

const PhoneInput = ({
    onChange,
    name,
    required,
    value,
    bigger,
    className,
    style,
}) => (
    <BasePhoneInput
        onChange={(value) => {
            onChange?.({
                target: {
                    name,
                    value,
                },
            });
        }}
        name={name}
        value={value}
        required={required}
        defaultCountry="HU"
        international
        countryCallingCodeEditable={false}
        className={cn(classes.input, className, {
            [classes.bigger]: bigger,
        })}
        style={style}
        limitMaxLength
        flags={flags}
        autoComplete="off"
    />
);

export default PhoneInput;
