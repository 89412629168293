import { useRef } from "react";
import { Container, Grid } from "@material-ui/core";
// refacted layout UI elements to display Paperish design
import ScrollContainer from "../../../components/Facelift/atoms/ScrollContainer";
import Paper from "../../../components/Facelift/atoms/Paper";
import PaperSection from "../../../components/Facelift/atoms/PaperSection";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import Headline from "../../../components/Facelift/atoms/Headline";
import Tabs from "../../../components/Facelift/molecules/Tabs";
import PageSubHeader from "../../../components/Facelift/atoms/PageSubHeader";
import ServiceCard from "../../../components/Facelift/organisms/ServiceCard";
import classes from "./SelectService.module.css";
import "../../../components/Facelift/typo.css";

const Services = () => {
    const scrollRef = useRef(null);

    return (
        <>
            <div className={classes.intro}>
                <Container>
                    <Paper>
                        <Headline>
                            <Breadcrumbs />
                        </Headline>
                        <Tabs
                            tabs={[
                                {
                                    label: "Távkonzultációs szolgáltatások",
                                    scrollToId: "#remote-services",
                                },
                                {
                                    label: "Adatalapú szolgáltatások",
                                    scrollToId: "#data-as-a-service",
                                    isHidden: true,
                                },
                            ]}
                            scrollRef={scrollRef}
                        />
                        <ScrollContainer ref={scrollRef}>
                            <PaperSection id="remote-services">
                                <PageSubHeader className={classes.PageHeader}>
                                    Távkonzultációs szolgáltatások
                                </PageSubHeader>

                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="hotline" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="doctorOnCall" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="chat" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="secondOpinion" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="ePrescription" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="videoConsultation" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="outPatient" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="dutyFinder" />
                                    </Grid>
                                </Grid>
                            </PaperSection>
                            {/* TODO: uncomment if Data services became active */}
                            {/* <PaperSection id="data-as-a-service">
                                <PageSubHeader className={classes.PageHeader}>
                                    Adatalapú szolgáltatások
                                </PageSubHeader>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="preop" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="manager" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <ServiceCard type="diabetes" />
                                    </Grid>
                                </Grid>
                            </PaperSection> */}
                        </ScrollContainer>
                    </Paper>
                </Container>
            </div>
        </>
    );
};

export default Services;
