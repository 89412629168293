import { Container } from "@material-ui/core";
import classNames from "classnames/bind";
import { useNavigate } from "react-router-dom";
import CardContainer from "../../UI/CardContainer/CardContainer";
import PageHeader from "../ReservationSummary/PageHeader/PageHeader";
import Button from "../ReservationSummary/Button/Button";
import { ReactComponent as TickSvg } from "../ReservationSummary/tick.svg";
import useTranslation from "../../../features/i18n/useTranslation";
import useQuery from "../../../features/util/useQuery";
import { useHumed } from "../../../ui/hooks/useHumed";
import classes from "./ReservationEnd.module.css";

const cx = classNames.bind(classes);

const ReservationEnd = () => {
    const navigate = useNavigate();
    const isHumed = useHumed();
    const query = useQuery();
    const { i18nRoute } = useTranslation();

    const date = query.get("selectedTime");
    const doctor = query.get("doctor");
    const service = query.get("service");

    return (
        <Container>
            <div className={classes.innerContainer}>
                <CardContainer className={classes.cardContainer}>
                    <PageHeader
                        text="Sikeres foglalás!"
                        style={styles.pageHeader}
                    />
                    <div className={cx(classes.tick, { humed: isHumed })}>
                        <TickSvg />
                    </div>

                    <div className={classes.infoWrapper}>
                        <p>Időpont lefoglalva:</p>
                        <p className={cx(classes.infoText, { humed: isHumed })}>
                            {date}
                        </p>
                        <p>Orvos:</p>
                        <p className={cx(classes.infoText, { humed: isHumed })}>
                            {doctor}
                        </p>
                        <p>Szolgáltatás:</p>
                        <p className={cx(classes.infoText, { humed: isHumed })}>
                            {service}
                        </p>
                    </div>

                    {!isHumed && (
                        <div className={classes.btnWrapper}>
                            <Button
                                text="Befejezés"
                                textMargin={"11rem"}
                                className={classes.button}
                                onClick={() =>
                                    navigate(i18nRoute("routes.profile.base"))
                                }
                            />
                        </div>
                    )}
                </CardContainer>
            </div>
        </Container>
    );
};

const styles = {
    pageHeader: {
        marginTop: 0,
        marginBottom: "3rem",
    },
};

export default ReservationEnd;
