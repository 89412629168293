import { Box, Container } from "@material-ui/core";
import DangerousHtml from "../../../../ui/layout/DangerousHtml";
import type { VideoSection } from "../../../../ui/models";
import { Colors } from "../../../../assets/Theme";
import PageHeader from "../../../../ui/layout/PageHeader";

interface Props {
    contentBlock: VideoSection;
}

const VideoBlock = ({ contentBlock }: Props) => {
    const { video, description } = contentBlock;

    return (
        <Container>
            <Box color={Colors.darkBlue} my={4}>
                {contentBlock.sectionLabel && (
                    <PageHeader text={contentBlock.sectionLabel} />
                )}
                <iframe
                    width="100%"
                    height="622px"
                    src={video}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
                <Box mt={4}>
                    <DangerousHtml>{description}</DangerousHtml>
                </Box>
            </Box>
        </Container>
    );
};

export default VideoBlock;
