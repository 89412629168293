import type { FC, HTMLProps } from "react";

interface Props extends HTMLProps<HTMLDivElement> {
    children: string;
    as?: "div" | "span";
}

const DangerousHtml: FC<Props> = ({ children, as = "div", ...props }) => {
    const Component = as;

    return (
        <Component dangerouslySetInnerHTML={{ __html: children }} {...props} />
    );
};

export default DangerousHtml;
