import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import type { ReactNode } from "react";
import { initSentry } from "./sentry";

initSentry();

interface Props {
    readonly children: ReactNode;
}

const ErrorBoundary = ({ children }: Props) => (
    <SentryErrorBoundary>{children}</SentryErrorBoundary>
);

export default ErrorBoundary;
