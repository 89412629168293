import { Container, Grid } from "@material-ui/core";
import { useState } from "react";
import ReservationCalendar from "../ReservationCalendar/ReservationCalendar";
import ReservationFilter from "../ReservationFilter/ReservationFilter";
import { getMonday } from "../reservationUtilities";
import { useHumed } from "../../../ui/hooks/useHumed";
import classes from "./ReservationPicker.module.css";

const ReservationPicker = (props) => {
    const isHumed = useHumed();
    const [fittingAppointments, setFittingAppointments] = useState([]);

    return (
        <div className={classes.picker}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} hidden={isHumed}>
                        <ReservationFilter
                            selectedQualificationId={
                                props.selectedQualification
                            }
                            selectedDoctorId={props.selectedDoctor}
                            selectedInstitutionId={props.selectedInstitution}
                            selectedServiceId={props.selectedService}
                            filterData={props.filterData}
                            withoutInstitution={props.onlyVideo}
                            dataSetter={setFittingAppointments}
                        />
                    </Grid>
                    <Grid item xs={12} sm={isHumed ? 12 : 8}>
                        {fittingAppointments.map((element) => (
                            <ReservationCalendar
                                key={element.id}
                                id={element.id}
                                appointmentId={element.appointmentId}
                                doctorName={element.doctor}
                                doctorId={element.doctorId}
                                qualifications={element.qualification}
                                service={element.service}
                                serviceId={element.serviceId}
                                institution={element.institution}
                                institutionId={element.institutionId}
                                institutionAddress={element.institutionAddress}
                                institutionImage={element.institutionImage}
                                profileImageUrl={element.profileImageUrl}
                                isVideo={element.isVideo}
                                price={isHumed ? 0 : element.price}
                                noCache={props.noCache}
                                firstWeek={getMonday()}
                            />
                        ))}
                        {!fittingAppointments.length && (
                            <p className={classes.nothingSelectedText}>
                                Válaszd ki a vizsgálat típusát vagy az orvost az
                                időpontok megjelenítéséhez.
                            </p>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default ReservationPicker;
