import InputMask from "./InputMask";

const SecurityNumberInput = ({
    name,
    value,
    className,
    style,
    required,
    disabled,
    onChange,
}) => (
    <InputMask
        name={name}
        value={value}
        options={{ delimiter: "-", blocks: [3, 3, 3], numericOnly: true }}
        className={className}
        style={style}
        onChange={onChange}
        required={required}
        disabled={disabled}
    />
);

export default SecurityNumberInput;
