import logo from "./images/logo.svg";
import mastercard from "./images/mastercard.svg";
import visa from "./images/visa.svg";
import visa2 from "./images/visa-2.svg";
import americanExpress from "./images/american-express.svg";
import arrow from "./images/arrow.svg";
import arrowGrey from "./images/arrowGrey.svg";
import arrowLeftInactive from "./images/arrowLeftInactive.svg";
import arrowLeftActive from "./images/arrowLeftActive.svg";
import arrowRightInactive from "./images/arrowRightInactive.svg";
import arrowRightActive from "./images/arrowRightActive.svg";
import landingInnerArrow from "./images/landingInnerArrow3.svg";
import landingEndArrow from "./images/landingEndArrow.svg";
import profileIcon from "./images/profile.svg";
import sheetIcon from "./images/sheet.svg";
import gearIcon from "./images/gear.svg";
import starIcon from "./images/star.svg";
import clockIcon from "./images/clock.svg";
import userInviteIcon from "./images/userInvite.svg";
import cardIcon from "./images/card.svg";
import phoneIcon from "./images/phone.svg";
import profileIconWhite from "./images/profileIconWhite.svg";
import sheetIconWhite from "./images/sheetIconWhite.svg";
import gearIconWhite from "./images/gearIconWhite.svg";
import starIconWhite from "./images/starIconWhite.svg";
import clockIconWhite from "./images/clockIconWhite.svg";
import userInviteIconWhite from "./images/userInviteIconWhite.svg";
import cardIconWhite from "./images/cardIconWhite.svg";
import phoneIconWhite from "./images/phoneIconWhite.svg";
import deleteIcon from "./images/deleteIcon.svg";
import facebook from "./images/facebook.svg";
import google from "./images/google.svg";
import logoWhite from "./images/logo_feher.svg";
import googlePlay from "./images/GooglePlay.svg";
import appStore from "./images/AppStore.svg";
import attachmentWhite from "./images/icons/attachmentWhite.svg";
import desktopWhite from "./images/icons/desktopWhite.svg";
import downloadWhite from "./images/icons/downloadWhite.svg";
import dutyWhite from "./images/icons/dutyWhite.svg";
import envelopeWhite from "./images/icons/envelopeWhite.svg";
import exclamationWhite from "./images/icons/exclamationWhite.svg";
import giftWhite from "./images/icons/giftWhite.svg";
import hamburgerWhite from "./images/icons/hamburgerWhite.svg";
import historyWhite from "./images/icons/historyWhite.svg";
import hotlineWhite from "./images/icons/hotlineWhite.svg";
import inviteWhite from "./images/icons/inviteWhite.svg";
import laptopWhite from "./images/icons/laptopWhite.svg";
import lightbulbWhite from "./images/icons/lightbulbWhite.svg";
import messageExchangeWhite from "./images/icons/messageExchangeWhite.svg";
import mobileWhite from "./images/icons/mobileWhite.svg";
import nightWhite from "./images/icons/nightWhite.svg";
import notificationSmallWhite from "./images/icons/notificationSmallWhite.svg";
import notificationBigWhite from "./images/icons/notificationBigWhite.svg";
import notificationsWhite from "./images/icons/notificationsWhite.svg";
import opinionWhite from "./images/icons/opinionWhite.svg";
import paymentWhite from "./images/icons/paymentWhite.svg";
import personalDataWhite from "./images/icons/personalDataWhite.svg";
import phoneMessageWhite from "./images/icons/phoneMessageWhite.svg";
import prescriptionWhite from "./images/icons/prescriptionWhite.svg";
import profileWhite from "./images/icons/profileWhite.svg";
import reservationWhite from "./images/icons/reservationWhite.svg";
import safetyWhite from "./images/icons/safetyWhite.svg";
import searchWhite from "./images/icons/searchWhite.svg";
import signOutWhite from "./images/icons/signOutWhite.svg";
import starWhite from "./images/icons/starWhite.svg";
import tipWhite from "./images/icons/tipWhite.svg";
import videoConsultationWhite from "./images/icons/videoConsultationWhite.svg";
import xWhite from "./images/icons/xWhite.svg";
import attachmentBlack from "./images/icons/attachmentBlack.svg";
import desktopBlack from "./images/icons/desktopBlack.svg";
import downloadBlack from "./images/icons/downloadBlack.svg";
import dutyBlack from "./images/icons/dutyBlack.svg";
import envelopeBlack from "./images/icons/envelopeBlack.svg";
import exclamationBlack from "./images/icons/exclamationBlack.svg";
import giftBlack from "./images/icons/giftBlack.svg";
import hamburgerBlack from "./images/icons/hamburgerBlack.svg";
import historyBlack from "./images/icons/historyBlack.svg";
import hotlineBlack from "./images/icons/hotlineBlack.svg";
import inviteBlack from "./images/icons/inviteBlack.svg";
import laptopBlack from "./images/icons/laptopBlack.svg";
import lightbulbBlack from "./images/icons/lightbulbBlack.svg";
import messageExchangeBlack from "./images/icons/messageExchangeBlack.svg";
import mobileBlack from "./images/icons/mobileBlack.svg";
import nightBlack from "./images/icons/nightBlack.svg";
import notificationSmallBlack from "./images/icons/notificationSmallBlack.svg";
import notificationBigBlack from "./images/icons/notificationBigBlack.svg";
import notificationsBlack from "./images/icons/notificationsBlack.svg";
import opinionBlack from "./images/icons/opinionBlack.svg";
import paymentBlack from "./images/icons/paymentBlack.svg";
import personalDataBlack from "./images/icons/personalDataBlack.svg";
import phoneMessageBlack from "./images/icons/phoneMessageBlack.svg";
import prescriptionBlack from "./images/icons/prescriptionBlack.svg";
import profileBlack from "./images/icons/profileBlack.svg";
import reservationBlack from "./images/icons/reservationBlack.svg";
import safetyBlack from "./images/icons/safetyBlack.svg";
import searchBlack from "./images/icons/searchBlack.svg";
import signOutBlack from "./images/icons/signOutBlack.svg";
import starBlack from "./images/icons/starBlack.svg";
import tickBlack from "./images/icons/tickBlack.svg";
import tipBlack from "./images/icons/tipBlack.svg";
import videoConsultationBlack from "./images/icons/videoConsultationBlack.svg";
import xBlack from "./images/icons/xBlack.svg";
import attachmentGreen from "./images/icons/attachmentGreen.svg";
import desktopGreen from "./images/icons/desktopGreen.svg";
import downloadGreen from "./images/icons/downloadGreen.svg";
import dutyGreen from "./images/icons/dutyGreen.svg";
import envelopeGreen from "./images/icons/envelopeGreen.svg";
import exclamationGreen from "./images/icons/exclamationGreen.svg";
import giftGreen from "./images/icons/giftGreen.svg";
import hamburgerGreen from "./images/icons/hamburgerGreen.svg";
import historyGreen from "./images/icons/historyGreen.svg";
import hotlineGreen from "./images/icons/hotlineGreen.svg";
import inviteGreen from "./images/icons/inviteGreen.svg";
import laptopGreen from "./images/icons/laptopGreen.svg";
import lightbulbGreen from "./images/icons/lightbulbGreen.svg";
import messageExchangeGreen from "./images/icons/messageExchangeGreen.svg";
import mobileGreen from "./images/icons/mobileGreen.svg";
import nightGreen from "./images/icons/nightGreen.svg";
import notificationSmallGreen from "./images/icons/notificationSmallGreen.svg";
import notificationBigGreen from "./images/icons/notificationBigGreen.svg";
import notificationsGreen from "./images/icons/notificationsGreen.svg";
import opinionGreen from "./images/icons/opinionGreen.svg";
import paymentGreen from "./images/icons/paymentGreen.svg";
import personalDataGreen from "./images/icons/personalDataGreen.svg";
import phoneMessageGreen from "./images/icons/phoneMessageGreen.svg";
import prescriptionGreen from "./images/icons/prescriptionGreen.svg";
import profileGreen from "./images/icons/profileGreen.svg";
import reservationGreen from "./images/icons/reservationGreen.svg";
import safetyGreen from "./images/icons/safetyGreen.svg";
import searchGreen from "./images/icons/searchGreen.svg";
import signOutGreen from "./images/icons/signOutGreen.svg";
import starGreen from "./images/icons/starGreen.svg";
import tickGreen from "./images/icons/tickGreen.svg";
import tickWhite from "./images/icons/tickWhite.svg";
import tipGreen from "./images/icons/tipGreen.svg";
import videoConsultationGreen from "./images/icons/videoConsultationGreen.svg";
import xRed from "./images/icons/xRed.svg";
import tickRed from "./images/icons/tickRed.svg";
import exclamationRed from "./images/icons/exclamationRed.svg";
import starGrey from "./images/icons/starGrey.svg";
import starYellow from "./images/icons/starYellow.svg";
import calendar from "./images/icons/calendar.svg";
import quotes from "./images/icons/quotes.svg";
import quotes2 from "./images/icons/quotes_2.svg";
import hotline2White from "./images/icons/hotline2White.svg";
import hotline2Black from "./images/icons/hotline2Black.svg";
import hotline2Green from "./images/icons/hotline2Green.svg";
import duty2White from "./images/icons/duty2White.svg";
import duty2Black from "./images/icons/duty2Black.svg";
import duty2Green from "./images/icons/duty2Green.svg";
import qualificationsWhite from "./images/icons/qualificationsWhite.svg";
import qualificationsBlack from "./images/icons/qualificationsBlack.svg";
import qualificationsGreen from "./images/icons/qualificationsGreen.svg";
import imageIcon from "./images/icons/imageIcon.svg";
import locationIcon from "./images/icons/locationIcon.svg";
import messageIcon from "./images/icons/messageIcon.svg";
import videoIcon from "./images/icons/videoIcon.svg";
import facebook2 from "./images/icons/facebook.svg";
import youtube from "./images/icons/youtube.svg";
import instagram from "./images/icons/instagram.svg";
import linkedin from "./images/icons/linkedin.svg";
import user from "./images/icons/user.svg";
import userGreen from "./images/icons/userGreen.svg";
import landing3Mobile from "./images/icons/ikon_01.svg";
import landing3Chevron from "./images/icons/ikon_02.svg";
import landing3Handshake from "./images/icons/ikon_03.svg";
import landing3Doctor from "./images/icons/ikon_04.svg";
import stethoscope from "./images/icons/stethoscope.svg";
import filter from "./images/icons/filter.svg";
//IMAGES
import hero from "./images/original/hero.jpg";
import phone from "./images/original/phone.png";
import hotline from "./images/original/hotline.jpg";
import idopont from "./images/original/idopont.jpg";
import masodvelemeny from "./images/original/masodvelemeny.jpg";
import orvosiChat from "./images/original/orvosi_chat.jpg";
import orvosiUgyelet from "./images/original/orvosi_ugyelet.jpg";
import eRecept from "./images/original/prescription.jpg";
import videoConsultation from "./images/original/video_konzultacio.jpg";
import woman from "./images/original/woman.png";
import face1 from "./images/original/face1.jpg";
import face2 from "./images/original/face2.jpg";
import face3 from "./images/original/face3.jpg";
import bigfish from "./images/original/bigfish.png";
import beach from "./images/original/beach.jpg";
import euLogo from "./images/original/eulogo.png";
import userPlaceholder from "./images/original/user_placeholder.jpg";
import doctors from "./images/original/doctors.jpg";
import carouselHotline from "./images/original/carousel_hotline.jpg";
import carouselHotline2 from "./images/original/carousel_hotline_2.jpg";
import carouselDuty from "./images/original/carousel_duty.jpg";
import carouselDuty2 from "./images/original/carousel_duty_2.jpg";
import carouselCovidDuty from "./images/original/carousel_covid_duty.jpg";
import carouselChat from "./images/original/carousel_chat.jpg";
import newHero from "./images/original/newHero.jpg";
import dutyFinder from "./images/original/dutyFinder.png";
import maestro from "./images/original/maestro.png";
import simple from "./images/original/simple-logo.png";
import landing31 from "./images/original/landing3_1.jpg";
import landing32 from "./images/original/landing3_2.jpg";
import landing33 from "./images/original/landing3_3.jpg";
import fulopIstvan from "./images/testimonials/fulop_istvan.jpg";
import rezessyBalint from "./images/testimonials/rezessy_balint.jpg";
import szaboZsolt from "./images/testimonials/szabo_zsolt.jpg";
import vasvariBarbara from "./images/testimonials/vasvari_barbara.jpg";
import willnerPeter from "./images/testimonials/willner_peter.jpg";
import meroeszkozok from "./images/original/meroeszkozok.png";
import telemedicina from "./images/original/telemedicina.png";
//WEBP
import doctorsWEBP from "./images/webp/doctors.webp";
import newHeroWEBP from "./images/webp/newHero.webp";
import fulopIstvanWEBP from "./images/webp/fulop_istvan.webp";
import rezessyBalintWEBP from "./images/webp/rezessy_balint.webp";
import szaboZsoltWEBP from "./images/webp/szabo_zsolt.webp";
import vasvariBarbaraWEBP from "./images/webp/vasvari_barbara.webp";
import willnerPeterWEBP from "./images/webp/willner_peter.webp";
import videokonferenciaWEBP from "./images/webp/videokonferencia.webp";
import ugyeletkeresoWEBP from "./images/webp/ugyeletkereso.webp";

export const Images = {
    logo,
    mastercard,
    maestro,
    simple,
    visa,
    visa2,
    americanExpress,
    hero,
    arrow,
    arrowGrey,
    hotline,
    idopont,
    masodvelemeny,
    orvosiChat,
    orvosiUgyelet,
    woman,
    videoConsultation,
    arrowLeftActive,
    arrowLeftInactive,
    arrowRightActive,
    arrowRightInactive,
    face1,
    face2,
    face3,
    landingInnerArrow,
    landingEndArrow,
    phone,
    profileIcon,
    sheetIcon,
    gearIcon,
    starIcon,
    clockIcon,
    userInviteIcon,
    cardIcon,
    phoneIcon,
    profileIconWhite,
    sheetIconWhite,
    gearIconWhite,
    starIconWhite,
    clockIconWhite,
    userInviteIconWhite,
    cardIconWhite,
    phoneIconWhite,
    deleteIcon,
    facebook,
    google,
    logoWhite,
    googlePlay,
    appStore,
    bigfish,
    fulopIstvan,
    rezessyBalint,
    szaboZsolt,
    vasvariBarbara,
    willnerPeter,
    placeholder: beach,
    euLogo,
    userPlaceholder,
    doctors,
    calendar,
    carouselHotline,
    carouselHotline2,
    carouselDuty,
    carouselDuty2,
    carouselCovidDuty,
    carouselChat,
    newHero,
    dutyFinder,
    eRecept,
    landing31,
    landing32,
    landing33,
    meroeszkozok,
    telemedicina,
};

export const WebP = {
    newHeroWEBP,
    fulopIstvanWEBP,
    rezessyBalintWEBP,
    szaboZsoltWEBP,
    vasvariBarbaraWEBP,
    willnerPeterWEBP,
    doctorsWEBP,
    videokonferenciaWEBP,
    ugyeletkeresoWEBP,
};

export const Colors = {
    green: "#1FBA9C",
    green2: "#ccebe5",
    green3: "#F2FBF9",
    lightGreen: "#A4F2E3",
    darkBlue: "#263D50",
    yellow: "#FFD943",
    grey: "#929EA7",
    grey2: "#dadee1",
    linkBlue: "#11A2ED",
    red: "#ED1124",
};

export const Fonts = {
    segoe: "'Segoe', Tahoma, Verdana, sans-serif",
    segoeBold: "'SegoeBold', Tahoma, Verdana, sans-serif",
    segoeSemibold: "'SegoeSemibold', Tahoma, Verdana, sans-serif",
    segoeItalic: "'SegoeItalic', Tahoma, Verdana, sans-serif",
    segoeLight: "'SegoeLight', Tahoma, Verdana, sans-serif",
    roboto: "'Roboto', sans-serif",
};

export const Flags = {
    flagAD: require(".//images/flags/AD.png"),
    flagAE: require(".//images/flags/AE.png"),
    flagAF: require(".//images/flags/AF.png"),
    flagAG: require(".//images/flags/AG.png"),
    flagAI: require(".//images/flags/AI.png"),
    flagAL: require(".//images/flags/AL.png"),
    flagAM: require(".//images/flags/AM.png"),
    flagAO: require(".//images/flags/AO.png"),
    flagAR: require(".//images/flags/AR.png"),
    flagAS: require(".//images/flags/AS.png"),
    flagAT: require(".//images/flags/AT.png"),
    flagAU: require(".//images/flags/AU.png"),
    flagAW: require(".//images/flags/AW.png"),
    flagAX: require(".//images/flags/AX.png"),
    flagAZ: require(".//images/flags/AZ.png"),
    flagBA: require(".//images/flags/BA.png"),
    flagBB: require(".//images/flags/BB.png"),
    flagBD: require(".//images/flags/BD.png"),
    flagBE: require(".//images/flags/BE.png"),
    flagBF: require(".//images/flags/BF.png"),
    flagBG: require(".//images/flags/BG.png"),
    flagBH: require(".//images/flags/BH.png"),
    flagBI: require(".//images/flags/BI.png"),
    flagBJ: require(".//images/flags/BJ.png"),
    flagBL: require(".//images/flags/BL.png"),
    flagBM: require(".//images/flags/BM.png"),
    flagBN: require(".//images/flags/BN.png"),
    flagBO: require(".//images/flags/BO.png"),
    flagBR: require(".//images/flags/BR.png"),
    flagBS: require(".//images/flags/BS.png"),
    flagBT: require(".//images/flags/BT.png"),
    flagBW: require(".//images/flags/BW.png"),
    flagBY: require(".//images/flags/BY.png"),
    flagBZ: require(".//images/flags/BZ.png"),
    flagCA: require(".//images/flags/CA.png"),
    flagCC: require(".//images/flags/CC.png"),
    flagCD: require(".//images/flags/CD.png"),
    flagCF: require(".//images/flags/CF.png"),
    flagCG: require(".//images/flags/CG.png"),
    flagCH: require(".//images/flags/CH.png"),
    flagCI: require(".//images/flags/CI.png"),
    flagCK: require(".//images/flags/CK.png"),
    flagCL: require(".//images/flags/CL.png"),
    flagCM: require(".//images/flags/CM.png"),
    flagCN: require(".//images/flags/CN.png"),
    flagCO: require(".//images/flags/CO.png"),
    flagCR: require(".//images/flags/CR.png"),
    flagCU: require(".//images/flags/CU.png"),
    flagCV: require(".//images/flags/CV.png"),
    flagCX: require(".//images/flags/CX.png"),
    flagCY: require(".//images/flags/CY.png"),
    flagCZ: require(".//images/flags/CZ.png"),
    flagDE: require(".//images/flags/DE.png"),
    flagDJ: require(".//images/flags/DJ.png"),
    flagDK: require(".//images/flags/DK.png"),
    flagDM: require(".//images/flags/DM.png"),
    flagDO: require(".//images/flags/DO.png"),
    flagDZ: require(".//images/flags/DZ.png"),
    flagEC: require(".//images/flags/EC.png"),
    flagEE: require(".//images/flags/EE.png"),
    flagEG: require(".//images/flags/EG.png"),
    flagER: require(".//images/flags/ER.png"),
    flagES: require(".//images/flags/ES.png"),
    flagET: require(".//images/flags/ET.png"),
    flagFI: require(".//images/flags/FI.png"),
    flagFJ: require(".//images/flags/FJ.png"),
    flagFK: require(".//images/flags/FK.png"),
    flagFM: require(".//images/flags/FM.png"),
    flagFO: require(".//images/flags/FO.png"),
    flagFR: require(".//images/flags/FR.png"),
    flagGA: require(".//images/flags/GA.png"),
    flagGB: require(".//images/flags/GB.png"),
    flagGD: require(".//images/flags/GD.png"),
    flagGE: require(".//images/flags/GE.png"),
    flagGF: require(".//images/flags/GF.png"),
    flagGG: require(".//images/flags/GG.png"),
    flagGH: require(".//images/flags/GH.png"),
    flagGI: require(".//images/flags/GI.png"),
    flagGL: require(".//images/flags/GL.png"),
    flagGM: require(".//images/flags/GM.png"),
    flagGN: require(".//images/flags/GN.png"),
    flagGP: require(".//images/flags/GP.png"),
    flagGQ: require(".//images/flags/GQ.png"),
    flagGR: require(".//images/flags/GR.png"),
    flagGS: require(".//images/flags/GS.png"),
    flagGT: require(".//images/flags/GT.png"),
    flagGU: require(".//images/flags/GU.png"),
    flagGW: require(".//images/flags/GW.png"),
    flagGY: require(".//images/flags/GY.png"),
    flagHK: require(".//images/flags/HK.png"),
    flagHN: require(".//images/flags/HN.png"),
    flagHR: require(".//images/flags/HR.png"),
    flagHT: require(".//images/flags/HT.png"),
    flagHU: require(".//images/flags/HU.png"),
    flagID: require(".//images/flags/ID.png"),
    flagIE: require(".//images/flags/IE.png"),
    flagIL: require(".//images/flags/IL.png"),
    flagIM: require(".//images/flags/IM.png"),
    flagIN: require(".//images/flags/IN.png"),
    flagIO: require(".//images/flags/IO.png"),
    flagIQ: require(".//images/flags/IQ.png"),
    flagIR: require(".//images/flags/IR.png"),
    flagIS: require(".//images/flags/IS.png"),
    flagIT: require(".//images/flags/IT.png"),
    flagJE: require(".//images/flags/JE.png"),
    flagJM: require(".//images/flags/JM.png"),
    flagJO: require(".//images/flags/JO.png"),
    flagJP: require(".//images/flags/JP.png"),
    flagKE: require(".//images/flags/KE.png"),
    flagKG: require(".//images/flags/KG.png"),
    flagKH: require(".//images/flags/KH.png"),
    flagKI: require(".//images/flags/KI.png"),
    flagKM: require(".//images/flags/KM.png"),
    flagKN: require(".//images/flags/KN.png"),
    flagKP: require(".//images/flags/KP.png"),
    flagKR: require(".//images/flags/KR.png"),
    flagKW: require(".//images/flags/KW.png"),
    flagKY: require(".//images/flags/KY.png"),
    flagKZ: require(".//images/flags/KZ.png"),
    flagLA: require(".//images/flags/LA.png"),
    flagLB: require(".//images/flags/LB.png"),
    flagLC: require(".//images/flags/LC.png"),
    flagLI: require(".//images/flags/LI.png"),
    flagLK: require(".//images/flags/LK.png"),
    flagLR: require(".//images/flags/LR.png"),
    flagLS: require(".//images/flags/LS.png"),
    flagLT: require(".//images/flags/LT.png"),
    flagLU: require(".//images/flags/LU.png"),
    flagLV: require(".//images/flags/LV.png"),
    flagLY: require(".//images/flags/LY.png"),
    flagMA: require(".//images/flags/MA.png"),
    flagMC: require(".//images/flags/MC.png"),
    flagMD: require(".//images/flags/MD.png"),
    flagME: require(".//images/flags/ME.png"),
    flagMG: require(".//images/flags/MG.png"),
    flagMH: require(".//images/flags/MH.png"),
    flagMK: require(".//images/flags/MK.png"),
    flagML: require(".//images/flags/ML.png"),
    flagMM: require(".//images/flags/MM.png"),
    flagMN: require(".//images/flags/MN.png"),
    flagMO: require(".//images/flags/MO.png"),
    flagMP: require(".//images/flags/MP.png"),
    flagMQ: require(".//images/flags/MQ.png"),
    flagMR: require(".//images/flags/MR.png"),
    flagMS: require(".//images/flags/MS.png"),
    flagMT: require(".//images/flags/MT.png"),
    flagMU: require(".//images/flags/MU.png"),
    flagMV: require(".//images/flags/MV.png"),
    flagMW: require(".//images/flags/MW.png"),
    flagMX: require(".//images/flags/MX.png"),
    flagMY: require(".//images/flags/MY.png"),
    flagMZ: require(".//images/flags/MZ.png"),
    flagNA: require(".//images/flags/NA.png"),
    flagNC: require(".//images/flags/NC.png"),
    flagNE: require(".//images/flags/NE.png"),
    flagNF: require(".//images/flags/NF.png"),
    flagNG: require(".//images/flags/NG.png"),
    flagNI: require(".//images/flags/NI.png"),
    flagNL: require(".//images/flags/NL.png"),
    flagNO: require(".//images/flags/NO.png"),
    flagNP: require(".//images/flags/NP.png"),
    flagNR: require(".//images/flags/NR.png"),
    flagNU: require(".//images/flags/NU.png"),
    flagNZ: require(".//images/flags/NZ.png"),
    flagOM: require(".//images/flags/OM.png"),
    flagPA: require(".//images/flags/PA.png"),
    flagPE: require(".//images/flags/PE.png"),
    flagPF: require(".//images/flags/PF.png"),
    flagPG: require(".//images/flags/PG.png"),
    flagPH: require(".//images/flags/PH.png"),
    flagPK: require(".//images/flags/PK.png"),
    flagPL: require(".//images/flags/PL.png"),
    flagPM: require(".//images/flags/PM.png"),
    flagPN: require(".//images/flags/PN.png"),
    flagPR: require(".//images/flags/PR.png"),
    flagPS: require(".//images/flags/PS.png"),
    flagPT: require(".//images/flags/PT.png"),
    flagPW: require(".//images/flags/PW.png"),
    flagPY: require(".//images/flags/PY.png"),
    flagQA: require(".//images/flags/QA.png"),
    flagRE: require(".//images/flags/RE.png"),
    flagRO: require(".//images/flags/RO.png"),
    flagRS: require(".//images/flags/RS.png"),
    flagRU: require(".//images/flags/RU.png"),
    flagRW: require(".//images/flags/RW.png"),
    flagSA: require(".//images/flags/SA.png"),
    flagSB: require(".//images/flags/SB.png"),
    flagSC: require(".//images/flags/SC.png"),
    flagSD: require(".//images/flags/SD.png"),
    flagSE: require(".//images/flags/SE.png"),
    flagSG: require(".//images/flags/SG.png"),
    flagSH: require(".//images/flags/SH.png"),
    flagSI: require(".//images/flags/SI.png"),
    flagSK: require(".//images/flags/SK.png"),
    flagSL: require(".//images/flags/SL.png"),
    flagSM: require(".//images/flags/SM.png"),
    flagSN: require(".//images/flags/SN.png"),
    flagSO: require(".//images/flags/SO.png"),
    flagSR: require(".//images/flags/SR.png"),
    flagST: require(".//images/flags/ST.png"),
    flagSV: require(".//images/flags/SV.png"),
    flagSY: require(".//images/flags/SY.png"),
    flagSZ: require(".//images/flags/SZ.png"),
    flagTC: require(".//images/flags/TC.png"),
    flagTD: require(".//images/flags/TD.png"),
    flagTG: require(".//images/flags/TG.png"),
    flagTH: require(".//images/flags/TH.png"),
    flagTJ: require(".//images/flags/TJ.png"),
    flagTK: require(".//images/flags/TK.png"),
    flagTL: require(".//images/flags/TL.png"),
    flagTM: require(".//images/flags/TM.png"),
    flagTN: require(".//images/flags/TN.png"),
    flagTO: require(".//images/flags/TO.png"),
    flagTR: require(".//images/flags/TR.png"),
    flagTT: require(".//images/flags/TT.png"),
    flagTV: require(".//images/flags/TV.png"),
    flagTW: require(".//images/flags/TW.png"),
    flagTZ: require(".//images/flags/TZ.png"),
    flagUA: require(".//images/flags/UA.png"),
    flagUG: require(".//images/flags/UG.png"),
    flagUS: require(".//images/flags/US.png"),
    flagUY: require(".//images/flags/UY.png"),
    flagUZ: require(".//images/flags/UZ.png"),
    flagVA: require(".//images/flags/VA.png"),
    flagVC: require(".//images/flags/VC.png"),
    flagVE: require(".//images/flags/VE.png"),
    flagVG: require(".//images/flags/VG.png"),
    flagVI: require(".//images/flags/VI.png"),
    flagVN: require(".//images/flags/VN.png"),
    flagVU: require(".//images/flags/VU.png"),
    flagWF: require(".//images/flags/WF.png"),
    flagWS: require(".//images/flags/WS.png"),
    flagYE: require(".//images/flags/YE.png"),
    flagYT: require(".//images/flags/YT.png"),
    flagZA: require(".//images/flags/ZA.png"),
    flagZM: require(".//images/flags/ZM.png"),
    flagZW: require(".//images/flags/ZW.png"),
    emptyFlag: require(".//images/flags/emptyFlag.png"),
};

export const Icons = {
    attachmentWhite,
    desktopWhite,
    downloadWhite,
    dutyWhite,
    envelopeWhite,
    exclamationWhite,
    giftWhite,
    hamburgerWhite,
    historyWhite,
    hotlineWhite,
    inviteWhite,
    laptopWhite,
    lightbulbWhite,
    messageExchangeWhite,
    mobileWhite,
    nightWhite,
    notificationSmallWhite,
    notificationBigWhite,
    notificationsWhite,
    opinionWhite,
    paymentWhite,
    personalDataWhite,
    phoneMessageWhite,
    prescriptionWhite,
    profileWhite,
    reservationWhite,
    safetyWhite,
    searchWhite,
    signOutWhite,
    starWhite,
    tipWhite,
    videoConsultationWhite,
    xWhite,
    attachmentBlack,
    desktopBlack,
    downloadBlack,
    dutyBlack,
    envelopeBlack,
    exclamationBlack,
    giftBlack,
    hamburgerBlack,
    historyBlack,
    hotlineBlack,
    inviteBlack,
    laptopBlack,
    lightbulbBlack,
    messageExchangeBlack,
    mobileBlack,
    nightBlack,
    notificationSmallBlack,
    notificationBigBlack,
    notificationsBlack,
    opinionBlack,
    paymentBlack,
    personalDataBlack,
    phoneMessageBlack,
    prescriptionBlack,
    profileBlack,
    reservationBlack,
    safetyBlack,
    searchBlack,
    signOutBlack,
    starBlack,
    tickBlack,
    tipBlack,
    videoConsultationBlack,
    xBlack,
    attachmentGreen,
    desktopGreen,
    downloadGreen,
    dutyGreen,
    envelopeGreen,
    exclamationGreen,
    giftGreen,
    hamburgerGreen,
    historyGreen,
    hotlineGreen,
    inviteGreen,
    laptopGreen,
    lightbulbGreen,
    messageExchangeGreen,
    mobileGreen,
    nightGreen,
    notificationSmallGreen,
    notificationBigGreen,
    notificationsGreen,
    opinionGreen,
    paymentGreen,
    personalDataGreen,
    phoneMessageGreen,
    prescriptionGreen,
    profileGreen,
    reservationGreen,
    safetyGreen,
    searchGreen,
    signOutGreen,
    starGreen,
    tickGreen,
    tipGreen,
    videoConsultationGreen,
    xRed,
    tickRed,
    exclamationRed,
    starGrey,
    starYellow,
    tickWhite,
    quotes,
    quotes2,
    hotline2Green,
    hotline2White,
    hotline2Black,
    duty2Green,
    duty2White,
    duty2Black,
    qualificationsGreen,
    qualificationsWhite,
    qualificationsBlack,
    imageIcon,
    locationIcon,
    messageIcon,
    videoIcon,
    facebook: facebook2,
    instagram,
    youtube,
    linkedin,
    user,
    userGreen,
    landing3Chevron,
    landing3Doctor,
    landing3Handshake,
    landing3Mobile,
    stethoscope,
    filter,
};
