import { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom";
import { Colors, Fonts } from "../../../assets/Theme";
import SelectBtn from "../../../components/UI/SelectBtn/SelectBtn";
import DoctorRow from "../../../components/UI/DoctorRow/DoctorRow";
import Line from "../../../components/UI/Line/Line";
import { endLoading, get, post } from "../../../config/fetch";
import Qualifications from "../../../components/UI/Qualifications/Qualifications";
import classes from "./ServiceFilter.module.css";
import InstitutionSelect from "./InstitutionSelect/InstitutionSelect";

const abcFilter = [
    { id: [97, 101], name: "A-E" },
    { id: [102, 106], name: "F-J" },
    { id: [107, 111], name: "K-O" },
    { id: [112, 116], name: "P-T" },
    { id: [117, 122], name: "U-Z" },
];

const ServiceFilter = (props) => {
    const navigate = useNavigate();
    const [qualificationsSelected, setQualificationsSelected] = useState(true);
    const [doctorsSelected, setDoctorsSelected] = useState(false);
    const [doctorFilter, setDoctorFilter] = useState("Összes");
    const [institutions, setInstitutions] = useState(null);
    const [selectedInstituion, setSelectedInstitution] = useState(null);

    const [data, setData] = useState({
        allDoctors: null,
        filteredDoctors: null,
    });

    const [qualifications, setQualifications] = useState({
        allQualifications: null,
        filteredQualifications: null,
    });

    useEffect(() => {
        get("api/v1/medical/get-doctors")
            .then((response) => {
                endLoading();
                const doctorsData = response.data.data;
                const filteredDoctorsData = doctorsData.filter((d) => {
                    switch (props.type) {
                        case "outpatient":
                            return d.appointment === 1;
                        case "video":
                            return d.videoConsultation === 1;
                        default:
                            return d;
                    }
                });
                setData({
                    ...data,
                    allDoctors: filteredDoctorsData,
                    filteredDoctors: filteredDoctorsData,
                });
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        get("api/v1/medical/get-institutions")
            .then((response) => {
                endLoading();
                setInstitutions(response.data.data);
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
    }, []);

    useEffect(() => {
        post("api/v1/medical/get-qualifications", { type: props.type }, false)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        endLoading();
                        let q = response.data.data;
                        q = sortQualifications(q);
                        setQualifications({
                            ...qualifications,
                            allQualifications: q,
                            filteredQualifications: q,
                        });

                        break;
                    }
                    default:
                        endLoading();

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedInstituion !== null) {
            if (doctorsSelected) {
                const doctors = filterDoctorsByInstitution(selectedInstituion);
                setData({ ...data, filteredDoctors: doctors });
            }
            if (qualificationsSelected) {
                const q = filterQualificationsByInstitution(selectedInstituion);
                setQualifications({
                    ...qualifications,
                    filteredQualifications: q,
                });
            }
        } else {
            if (doctorsSelected) {
                setDoctorFilter("Összes");
                setData({ ...data, filteredDoctors: data.allDoctors });
            }
            if (qualificationsSelected) {
                setQualifications({
                    ...qualifications,
                    filteredQualifications: qualifications.allQualifications,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectDoctorFilter = (selected) => {
        setDoctorFilter(selected);
        if (selected === "Összes") {
            if (selectedInstituion) {
                const doctors = filterDoctorsByInstitution(selectedInstituion);
                setData({ ...data, filteredDoctors: doctors });
            } else {
                setData({ ...data, filteredDoctors: data.allDoctors });
            }
        } else {
            if (selectedInstituion) {
                const doctors = filterDoctorsByInstitution(selectedInstituion);
                const filter = abcFilter.find((f) => f.name === selected);
                const arr = filter.id;
                setData({
                    ...data,
                    filteredDoctors: filterByAbc(doctors, arr),
                });
            } else {
                const filter = abcFilter.find((f) => f.name === selected);
                const arr = filter.id;
                setData({
                    ...data,
                    filteredDoctors: filterByAbc(data.allDoctors, arr),
                });
            }
        }
    };

    const sortQualifications = (qualifications) =>
        qualifications.sort((a, b) => a.name.localeCompare(b.name, "hu"));

    const onSelectQualifications = () => {
        if (selectedInstituion) {
            const q = filterQualificationsByInstitution(selectedInstituion);
            setQualifications({ ...qualifications, filteredQualifications: q });
        } else {
            setQualifications({
                ...qualifications,
                filteredQualifications: qualifications.allQualifications,
            });
        }
        setQualificationsSelected(true);
        setDoctorsSelected(false);
    };

    const onSelectDoctors = () => {
        if (selectedInstituion) {
            const doctors = filterDoctorsByInstitution(selectedInstituion);
            setData({ ...data, filteredDoctors: doctors });
        } else {
            setData({ ...data, filteredDoctors: data.allDoctors });
        }
        setQualificationsSelected(false);
        setDoctorsSelected(true);
        setDoctorFilter("Összes");
    };

    const getReservationFilterType = () =>
        props.type === "video" ? "onlyVideo=true" : "onlyOutpatient=true";

    const onQualificationClick = (url, qualificationId) =>
        navigate(
            `/idopontfoglalas?selectedQualification=${qualificationId}&${getReservationFilterType()}`
        );

    const filterByAbc = (doctors, arr) => {
        let filteredDoctors;
        const startCode = arr[0];
        const endCode = arr[1];
        filteredDoctors = doctors.filter((doctor) => {
            const code = doctor.lastName
                .toLowerCase()
                .substring(0, 1)
                .charCodeAt(0);

            return code >= startCode && code <= endCode;
        });

        return filteredDoctors;
    };

    const filterDoctorsByInstitution = (institutionId) => {
        let filteredDoctors = [];
        // setDoctorFilter('Összes');
        data.allDoctors.forEach((d) => {
            if (d.institutions.length > 0) {
                d.institutions.forEach((i) => {
                    if (i.id == institutionId) {
                        filteredDoctors = [...filteredDoctors, d];
                    }
                });
            }
        });

        return filteredDoctors;
    };

    const filterQualificationsByInstitution = (institutionId) => {
        let filteredQualifications = [];
        const institution = institutions.find((i) => i.id == institutionId);

        if (institution.qualifications.length > 0) {
            institution.qualifications.forEach((q) => {
                filteredQualifications = [...filteredQualifications, q];
            });
        }

        // filteredQualifications = filterDuplicates(filteredQualifications);
        return filteredQualifications;
    };

    return (
        <>
            <p style={styles.title}>Szakterület vagy orvos kiválasztása</p>

            <Grid container style={{ margin: "4rem 0rem 2rem 0rem" }}>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <SelectBtn
                                selected={qualificationsSelected}
                                onClick={() => onSelectQualifications()}
                                text="Szakterület kiválasztása"
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <SelectBtn
                                selected={doctorsSelected}
                                onClick={() => onSelectDoctors()}
                                text="Orvos kiválasztása"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {props.withInstitution && (
                    <Grid item md={6} xs={12}>
                        <div className={classes.reservationFilter}>
                            {institutions && (
                                <InstitutionSelect
                                    rootClass={classes.muiSelect}
                                    label="Helyszín"
                                    noOptionsText="Nem található ilyen helyszín"
                                    options={institutions}
                                    onChange={(id) =>
                                        setSelectedInstitution(id)
                                    }
                                    selectedId={selectedInstituion}
                                />
                            )}
                        </div>
                    </Grid>
                )}
            </Grid>

            {qualificationsSelected && !doctorsSelected && (
                <div className={classes.qualifications}>
                    {qualifications.filteredQualifications ? (
                        <Qualifications
                            altNoMatch={true}
                            qualifications={
                                qualifications.filteredQualifications
                            }
                            onQualificationClick={onQualificationClick}
                        />
                    ) : (
                        <div className={classes.skeletons}>
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                            <Skeleton
                                animation="wave"
                                height={30}
                                classes={{ root: classes.skeleton }}
                            />
                        </div>
                    )}
                </div>
            )}

            {doctorsSelected && !qualificationsSelected && (
                <div style={{ marginBottom: "4rem" }}>
                    <Grid
                        container
                        spacing={1}
                        style={{ marginBottom: "2rem" }}
                    >
                        <Grid item md={2} xs={9}>
                            <SelectBtn
                                selected={doctorFilter === "Összes"}
                                // onClick={() => setDoctorFilter('Összes')}
                                onClick={() => onSelectDoctorFilter("Összes")}
                                text="Összes"
                            />
                        </Grid>
                        <Grid item md={1} xs={3}>
                            <SelectBtn
                                selected={doctorFilter === "A-E"}
                                // onClick={() => setDoctorFilter('A-E')}
                                onClick={() => onSelectDoctorFilter("A-E")}
                                text="A - E"
                            />
                        </Grid>
                        <Grid item md={1} xs={3}>
                            <SelectBtn
                                selected={doctorFilter === "F-J"}
                                // onClick={() => setDoctorFilter('F-J')}
                                onClick={() => onSelectDoctorFilter("F-J")}
                                text="F - J"
                            />
                        </Grid>
                        <Grid item md={1} xs={3}>
                            <SelectBtn
                                selected={doctorFilter === "K-O"}
                                // onClick={() => setDoctorFilter('K-O')}
                                onClick={() => onSelectDoctorFilter("K-O")}
                                text="K - O"
                            />
                        </Grid>
                        <Grid item md={1} xs={3}>
                            <SelectBtn
                                selected={doctorFilter === "P-T"}
                                // onClick={() => setDoctorFilter('P-T')}
                                onClick={() => onSelectDoctorFilter("P-T")}
                                text="P - T"
                            />
                        </Grid>
                        <Grid item md={1} xs={3}>
                            <SelectBtn
                                selected={doctorFilter === "U-Z"}
                                // onClick={() => setDoctorFilter('U-Z')}
                                onClick={() => onSelectDoctorFilter("U-Z")}
                                text="U - Z"
                            />
                        </Grid>
                    </Grid>

                    {data.filteredDoctors && (
                        <div>
                            <Line style={{ backgroundColor: "#929EA7" }} />
                            {data.filteredDoctors.map((d) => (
                                <div key={d.id}>
                                    <DoctorRow
                                        data={{
                                            name: d.name,
                                            image: d.image,
                                            qualifications: d.qualifications,
                                        }}
                                        onReservationClick={() =>
                                            navigate(
                                                `/idopontfoglalas?${getReservationFilterType()}&selectedDoctor=${
                                                    d.medioId
                                                }`
                                            )
                                        }
                                        onLinkClick={() =>
                                            navigate(
                                                `/orvosaink/${d.profileLink}`
                                            )
                                        }
                                    />
                                    <Line
                                        style={{
                                            backgroundColor: "#929EA7",
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {data.filteredDoctors &&
                        data.filteredDoctors.length === 0 && (
                            <p
                                className={classes.noMatch}
                                style={{ marginTop: "2rem" }}
                            >
                                Nem található a keresésnek megfelelő orvos.
                            </p>
                        )}
                </div>
            )}
        </>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontWeight: 700,
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
    },
    selected: {
        backgroundColor: "#929EA7",
        border: "1px solid #929EA7",
    },
    notSelected: {
        backgroundColor: "white",
        border: "1px solid #929EA7",
    },
};

export default ServiceFilter;
