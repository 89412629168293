import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux"; // eslint-disable-line @typescript-eslint/no-restricted-imports
import type { StateAuth } from "../store/model";
import { useIsNhaUserQuery } from "./api";

export default () => {
    const token = useSelector((state: StateAuth) => state.auth.token);
    const { data, isLoading, refetch } = useIsNhaUserQuery(
        !token ? skipToken : undefined
    );

    return {
        isNhaUser: data?.isNhaUser,
        isLoading,
        refetch,
    };
};
