import classes from "./SocialButton.module.css";

const SocialButton = (props) => (
    <div
        className={classes.SocialButton}
        style={props.style}
        onClick={props.onClick}
    >
        <img alt="" src={props.image} className={classes.image} />
        <span className={classes.text}>{props.text}</span>
    </div>
);

export default SocialButton;
