import { useState } from "react";
import Switch from "react-switch";
import classes from "./CustomSwitch.module.css";

const CustomSwitch = (props) => {
    const [enabled, setEnabled] = useState(props.enabled);

    const handleChange = (checked) => {
        setEnabled(checked);
        props.onChangeSwitch(checked);
    };

    return (
        <div className={`${classes.container}`} style={props.style}>
            <p className={classes.text}>
                {enabled ? "Engedélyezve" : "Kikapcsolva"}
            </p>
            <Switch
                onChange={(checked) => handleChange(checked)}
                checked={enabled}
                height={24}
                width={48}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={"#11A2ED"}
                offColor={"#BDC4CA"}
            />
        </div>
    );
};

export default CustomSwitch;
