import { useEffect, useState } from "react";
import { FormControl, FormLabel, Grid } from "@material-ui/core";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import { endLoading, get, post } from "../../../config/fetch";
import { convertDate, responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import Btn from "../../UI/Btn/Btn";
import PageSubHeader from "../atoms/PageSubHeader";
import Password from "../molecules/Form/Password";
import Tooltip from "../atoms/Tooltip";
import DeviceInfo from "./DeviceInfo";
import classes from "./SecuritySettings.module.css";

const initialPasswords = {
    oldPassword: null,
    password1: null,
    password2: null,
};

const SecuritySettings = () => {
    const [devices, setDevices] = useState(null);
    const [passwordModifiedAt, setPasswordModifiedAt] = useState(null);
    const [passwords, setPasswords] = useState(initialPasswords);

    useEffect(() => {
        get("user/get-password-and-devices").then((response) => {
            switch (response.data.responseCode) {
                case "OK":
                    setDevices(response.data.devices);
                    setPasswordModifiedAt(response.data.passwordModified);

                    break;
                default:
                    break;
            }
            endLoading();
        });
    }, []);

    const savePassWordHandler = () => {
        if (passwords.password1 === null || passwords.password2 === null) {
            return false;
        }

        if (passwords.password1.length < 6 || passwords.password2.length < 6) {
            responseMessage(
                "A jelszónak legalább 6 karakter hosszúságúnak kell lennie!"
            );

            return false;
        }

        if (!passwords.oldPassword) {
            responseMessage("A régi jelszó megadása kötelező!");

            return false;
        }

        if (
            passwords.password1 &&
            passwords.password2 &&
            passwords.password1 === passwords.password2
        ) {
            post("auth/change-user-password", {
                password: passwords.password1,
                oldPassword: passwords.oldPassword,
            })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            setPasswords(initialPasswords);
                            setPasswordModifiedAt(convertDate(new Date(), "."));
                            responseMessage("A jelszó mentése sikeres!");

                            break;
                        case "EMPTY_PASSWORD":
                            responseMessage("A jelszó nem maradhat üresen!");

                            break;
                        case "MISSING_OLD_PASSWORD":
                            responseMessage(
                                "A régi jelszó nem maradhat üresen!"
                            );

                            break;
                        case "INVALID_OLD_PASSWORD":
                            responseMessage("A régi jelszó helytelen!");

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        } else {
            responseMessage("A jelszavak nem egyeznek meg!");
        }
    };

    const handleDeviceLogout = (id) =>
        post("user/logout-device", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres kijelentkezés!");
                        const modifiedDevices = devices.filter(
                            (d) => d.id != id
                        );
                        setDevices(modifiedDevices);

                        break;
                    }
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <div className={classes.securitySettings}>
            <PageSubHeader className={classes.PageSubHeader}>
                Jelszó módosítása
            </PageSubHeader>
            {passwordModifiedAt && (
                <p className={classes.modifiedAt}>
                    Utolsó frissítés:{" "}
                    {passwordModifiedAt.substr(0, 10).replace(/-/g, ".")}
                </p>
            )}

            <Grid container spacing={2} style={{ marginBottom: "3rem" }}>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} md={4}>
                        <FormControl className={classes.formControl}>
                            <FormLabel className={classes.label}>
                                Régi jelszó
                            </FormLabel>
                            <Password
                                name="oldPassword"
                                value={passwords.oldPassword}
                                onChange={(e) =>
                                    setPasswords({
                                        ...passwords,
                                        oldPassword: e,
                                    })
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl className={classes.formControl}>
                            <FormLabel className={classes.label}>
                                <span>Új jelszó</span>
                                <Tooltip title="Új jelszavad létrehozásánál törekedj arra, hogy legalább 8 karaktert tartalmazzon, és legyen benne kis- és nagybetű, szám és egyedi karakter is.">
                                    <InfoOutlinedIcon
                                        style={{ fontSize: "1.9rem" }}
                                    />
                                </Tooltip>
                            </FormLabel>
                            <Password
                                name="password1"
                                value={passwords.password1}
                                onChange={(e) =>
                                    setPasswords({
                                        ...passwords,
                                        password1: e,
                                    })
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl className={classes.formControl}>
                            <FormLabel className={classes.label}>
                                Új jelszó mégegyszer
                            </FormLabel>
                            <Password
                                name="password2"
                                value={passwords.password2}
                                onChange={(e) =>
                                    setPasswords({
                                        ...passwords,
                                        password2: e,
                                    })
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Btn
                            className={classes.button}
                            onClick={savePassWordHandler}
                        >
                            Jelszó mentése
                        </Btn>
                    </Grid>
                </Grid>
            </Grid>

            <PageSubHeader className={classes.PageSubHeader}>
                Bejelentkezett eszközök
            </PageSubHeader>
            {devices && devices.length > 0 ? (
                devices.map((d, index) => (
                    <div key={`device-info-row-${index}`}>
                        <DeviceInfo
                            label={`${index + 1}. eszköz`}
                            device={`${d.platform || "Ismeretlen eszköz"} • ${
                                d.browser || "Ismeretlen böngésző"
                            } • ${d.created_at.replace(/-/g, ".")}`}
                            type={d.device_type}
                            onClick={() => handleDeviceLogout(d.id)}
                        />
                    </div>
                ))
            ) : (
                <p>Nincs bejelentkezett eszköz.</p>
            )}
        </div>
    );
};

export default SecuritySettings;
