import classes from "./PaperSection.module.css";
import cn from "classnames";

const PaperSection = ({ children, style, className, id, isInverted }) => (
    <section
        id={id}
        className={cn(classes.section, className, {
            [classes.inverted]: isInverted,
        })}
        style={style}
    >
        {children}
    </section>
);

export default PaperSection;
