import { Box, Container, Grid, Hidden } from "@material-ui/core";
import classes from "../styles.module.css";
import { useResolution } from "../../../ui/utils";
import PageHeader from "../../../ui/layout/PageHeader/index";
import BtnWithIcon from "../../../components/UI/Btn/BtnWithIcon";
import Phone from "../assets/Phone.png";
import GooglePlay from "../assets/GooglePlay.svg";
import AppStore from "../assets/AppStore.svg";
import { description } from "../content/chatServiceSection";
import { Colors } from "../../../assets/Theme";
import ChatPromotion from "../components/ChatPromotion";

interface Props {
    writeMessage: () => void;
}

const ChatServiceSection = ({ writeMessage }: Props) => {
    const isTablet = useResolution("tablet");

    const GOOGLE_PLAY_LINK =
        "https://play.google.com/store/apps/details?id=hu.tdoc.tdoc";
    const APP_STORE_LINK =
        "https://apps.apple.com/hu/app/t-doc/id1601837228?l=hu";

    const handleDownload = (platform: "android" | "ios") =>
        platform === "android" ? GOOGLE_PLAY_LINK : APP_STORE_LINK;

    return (
        <section style={{ backgroundColor: "#F8F8F8", color: Colors.darkBlue }}>
            <Box>
                {isTablet ? (
                    <PageHeader text="Gyors és hiteles válasz szakorvostól" />
                ) : (
                    <Container>
                        <PageHeader text="Gyors és hiteles válasz szakorvostól" />
                    </Container>
                )}
            </Box>
            <Container>
                <Box pb={isTablet ? 7 : 5}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Hidden smDown>
                                <Box position="relative" top={0} left={0}>
                                    <Box
                                        style={{
                                            position: "absolute",
                                            top: "0px",
                                            left: "270px",
                                            zIndex: 1,
                                        }}
                                    >
                                        <ChatPromotion
                                            text={[
                                                "AZ ELSŐ",
                                                "SZAKORVOSI CHAT",
                                                "CSAK 3 490 FT",
                                            ]}
                                        />
                                    </Box>
                                    <img
                                        src={Phone}
                                        className={classes.icon}
                                        style={{
                                            position: "relative",
                                            top: "0",
                                            left: "0",
                                        }}
                                        alt="PhonePicture "
                                    />
                                </Box>
                            </Hidden>
                            <Box ml={5}>
                                <Hidden smDown>
                                    <Box my={4}>
                                        <h5
                                            style={{
                                                textTransform: "uppercase",
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            TÖLTSD LE A T-DOC APPOT!
                                        </h5>
                                    </Box>
                                    <Box>
                                        <a href={handleDownload("android")}>
                                            <img
                                                src={GooglePlay}
                                                className={classes.icon}
                                                alt="Google Play "
                                            />
                                        </a>
                                    </Box>
                                    <Box mt={2}>
                                        <a href={handleDownload("ios")}>
                                            <img
                                                src={AppStore}
                                                className={classes.icon}
                                                alt="App Store "
                                            />
                                        </a>
                                    </Box>
                                </Hidden>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            spacing={4}
                            direction="column"
                            container
                        >
                            <Box ml={2} width="100%">
                                <Box>
                                    {description.map((textSection, index) => (
                                        <Box key={index} mt={6}>
                                            <Box mt={3}>
                                                <h5
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                        fontSize: "20px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {textSection.title}
                                                </h5>
                                            </Box>
                                            {textSection.description.map(
                                                (descriptionItem, index) => (
                                                    <Box key={index} mt={3}>
                                                        <p>{descriptionItem}</p>
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                                <Box
                                    mb={isTablet ? 0 : 5}
                                    mt={6}
                                    display="flex"
                                    justifyContent={
                                        isTablet ? "center" : "flex-end"
                                    }
                                    width={isTablet ? undefined : "338px"}
                                >
                                    <BtnWithIcon
                                        color="yellow"
                                        text="Üzenetet írok"
                                        className={classes.button}
                                        onClick={writeMessage}
                                    />
                                </Box>
                                <Hidden mdUp>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        my={4}
                                    >
                                        <h5
                                            style={{
                                                textTransform: "uppercase",
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            TÖLTSD LE A T-DOC APPOT!
                                        </h5>
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                        <a href={handleDownload("android")}>
                                            <img
                                                src={GooglePlay}
                                                className={classes.icon}
                                                alt="Google Play"
                                            />
                                        </a>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        mt={2}
                                    >
                                        <a href={handleDownload("ios")}>
                                            <img
                                                src={AppStore}
                                                className={classes.icon}
                                                alt="App Store"
                                            />
                                        </a>
                                    </Box>
                                </Hidden>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </section>
    );
};

export default ChatServiceSection;
