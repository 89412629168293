import classes from "./Alert.module.css";

const Alert = ({ label, button, children }) => (
    <div role="alert" className={classes.alert}>
        {label && <span className={classes.label}>{label}</span>}
        <span className={classes.text}>{children}</span>
        {button}
    </div>
);

export default Alert;
