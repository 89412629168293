import { Grid, Hidden } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Colors, Fonts } from "../../../assets/Theme";
import SvgMarkerGrey from "../../../assets/images/markerGrey.svg";
import SvgMarkerRed from "../../../assets/images/markerRed.svg";
import classes from "./InfoRow.module.css";

const InfoRow = (props) => {
    const data = props.data;

    const handleSelect = () => {
        if (props.onMap) {
            return;
        }
        props.onListSelect(data);
    };

    const handleReservation = () => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
        );

        let link;
        if (isNaN(data.medio_id)) {
            link = data.medio_id;
            if (isSafari) {
                window.location.href = link;
            } else {
                window.open(link, "_blank");
            }
        } else {
            console.error("Unexpected duty.medio_id");
        }
    };

    return (
        <Grid
            container
            className={props.onMap ? classes.mapRow : classes.infoRow}
            style={{
                backgroundColor: props.isSelected
                    ? "RGBA(38,61,80, 0.1)"
                    : null,
            }}
            alignItems="center"
        >
            <Hidden only={["xs"]}>
                <Grid
                    container
                    item
                    md={10}
                    sm={9}
                    onClick={() => handleSelect()}
                    style={{ cursor: "pointer" }}
                >
                    <Grid item xs={2}>
                        {data.image ? (
                            <img
                                className={classes.image}
                                src={data.image}
                                alt=""
                            ></img>
                        ) : props.onMap ? (
                            <img
                                className={classes.image}
                                src={SvgMarkerRed}
                                alt=""
                            ></img>
                        ) : props.isSelected ? (
                            <img
                                className={classes.image}
                                src={SvgMarkerRed}
                                alt=""
                            ></img>
                        ) : (
                            <img
                                className={classes.image}
                                src={SvgMarkerGrey}
                                alt=""
                            ></img>
                        )}
                    </Grid>
                    <Grid item xs={10} className={classes.infoText}>
                        {/* eslint-disable-next-line eqeqeq */}
                        {data.is_ad == "1" && (
                            <p style={{ ...styles.text, color: "#FFD943" }}>
                                hirdetés
                            </p>
                        )}
                        {props.onMap ? (
                            <Grid container>
                                <Grid item xs={10}>
                                    <p
                                        style={{
                                            ...styles.title,
                                            ...props.mapStyle,
                                        }}
                                    >
                                        {data.name}
                                    </p>
                                </Grid>
                                <Grid item xd={2}>
                                    <p
                                        style={{
                                            ...styles.text,
                                            ...props.mapStyle,
                                        }}
                                    >
                                        Távolság: {props.distance} km
                                    </p>
                                </Grid>
                            </Grid>
                        ) : (
                            <p style={{ ...styles.title, ...props.mapStyle }}>
                                {data.name}
                            </p>
                        )}
                        <p style={{ ...styles.text, ...props.mapStyle }}>
                            Ügyelet: {data.types.map((t) => t.name).join(", ")}
                        </p>
                        <p style={{ ...styles.text, ...props.mapStyle }}>
                            Cím: {data.address}
                        </p>
                        {data.phone !== "null" && !props.onMap && (
                            <p style={{ ...styles.text, ...props.mapStyle }}>
                                Tel:{" "}
                                <a href={`tel:${data.phone}`}>{data.phone}</a>
                            </p>
                        )}
                        {data.phone !== "null" && props.onMap && (
                            <p style={{ ...styles.text, ...props.mapStyle }}>
                                Tel: {data.phone}
                            </p>
                        )}
                    </Grid>
                </Grid>
                {data.medio_id && (
                    <Grid item md={2} sm={3}>
                        <div
                            className={classes.reservationBtn}
                            onClick={handleReservation}
                        >
                            <span style={styles.btnText}>Időpontfoglalás</span>
                        </div>
                    </Grid>
                )}
            </Hidden>
            <Hidden only={["xl", "lg", "md", "sm"]}>
                <Grid container item>
                    <Grid
                        container
                        item
                        onClick={() => handleSelect()}
                        style={{ cursor: "pointer" }}
                    >
                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={2}>
                                {data.image ? (
                                    <img
                                        className={classes.image}
                                        src={data.image}
                                        alt=""
                                    ></img>
                                ) : props.onMap ? (
                                    <img
                                        className={classes.image}
                                        src={SvgMarkerRed}
                                        alt=""
                                    ></img>
                                ) : props.isSelected ? (
                                    <img
                                        className={classes.image}
                                        src={SvgMarkerRed}
                                        alt=""
                                    ></img>
                                ) : (
                                    <img
                                        className={classes.image}
                                        src={SvgMarkerGrey}
                                        alt=""
                                    ></img>
                                )}
                            </Grid>
                            <Grid item xs={10} className={classes.infoText}>
                                {props.onMap ? (
                                    <Grid container>
                                        {/* eslint-disable-next-line eqeqeq */}
                                        {data.is_ad == "1" && (
                                            <Grid item xs={5}>
                                                <p
                                                    style={{
                                                        ...styles.text,
                                                        color: "#FFD943",
                                                    }}
                                                >
                                                    hirdetés
                                                </p>
                                            </Grid>
                                        )}
                                        <Grid item xs={7}>
                                            <p
                                                style={{
                                                    ...styles.text,
                                                    ...props.mapStyle,
                                                }}
                                            >
                                                Távolság: {props.distance} km
                                            </p>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    data.is_ad == "1" && (
                                        <p
                                            style={{
                                                ...styles.text,
                                                color: "#FFD943",
                                            }}
                                        >
                                            hirdetés
                                        </p>
                                    )
                                )}
                                <p
                                    style={{
                                        ...styles.title,
                                        ...props.mapStyle,
                                    }}
                                >
                                    {data.name}
                                </p>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{ padding: "0", paddingTop: "1rem" }}
                        >
                            <p style={{ ...styles.text, ...props.mapStyle }}>
                                Ügyelet:{" "}
                                {data.types.map((t) => t.name).join(", ")}
                            </p>
                            <p style={{ ...styles.text, ...props.mapStyle }}>
                                Cím: {data.address}
                            </p>
                            {data.phone !== "null" && !props.onMap && (
                                <p
                                    style={{
                                        ...styles.text,
                                        ...props.mapStyle,
                                    }}
                                >
                                    Tel:{" "}
                                    <a href={`tel:${data.phone}`}>
                                        {data.phone}
                                    </a>
                                </p>
                            )}
                            {data.phone !== "null" && props.onMap && (
                                <p
                                    style={{
                                        ...styles.text,
                                        ...props.mapStyle,
                                    }}
                                >
                                    Tel: {data.phone}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                    {data.medio_id && (
                        <Grid container item xs={12} justifyContent="flex-end">
                            <div
                                className={classes.mobileBtn}
                                onClick={handleReservation}
                            >
                                <span
                                    style={{
                                        ...styles.text,
                                        color: "#1FBA9C",
                                        fontWeight: "700",
                                        marginRight: "1rem",
                                        ...props.mapStyle,
                                    }}
                                >
                                    Időpontfoglalás
                                </span>
                                <ArrowForwardIosIcon
                                    style={{
                                        color: "#1FBA9C",
                                        marginBottom: "0.5rem",
                                        ...props.mapStyle,
                                    }}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Hidden>
            {!props.onMap && props.isSelected && (
                <Grid container>
                    <Grid item>
                        <div
                            className="editor-container"
                            style={props.contentContainerStyle}
                            dangerouslySetInnerHTML={{
                                __html:
                                    data.description === "null"
                                        ? ""
                                        : data.description,
                            }}
                        ></div>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

const styles = {
    title: {
        fontSize: "2rem",
        fontFamily: Fonts.segoe,
        textTransform: "uppercase",
        color: Colors.darkBlue,
        marginBottom: "2rem",
    },
    text: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        lineHeight: "2rem",
        color: Colors.darkBlue,
        marginBottom: "0.5rem",
        // color: '#929EA7',
        opacity: 1,
    },
    btnText: {
        fontSize: "1.6rem",
        fontFamily: Fonts.roboto,
        color: "white",
    },
};

export default InfoRow;
