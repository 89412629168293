import classes from "./Radio.module.css";

const Radio = ({ name, value, label, checked, onChange }) => {
    const handleChange = (e) => {
        onChange?.(e);
    };

    return (
        <div className={classes.radioWrapper}>
            <input
                type="radio"
                id={`_${name}-${value}`}
                name={name}
                value={value}
                onChange={handleChange}
                checked={checked}
            />
            <label htmlFor={`_${name}-${value}`} className={classes.radioLabel}>
                <span>{label}</span>
            </label>
        </div>
    );
};

export default Radio;
