import useTranslation from "../../../features/i18n/useTranslation";

export const useSwitchTitle = (locationPath) => {
    const { i18nRoute } = useTranslation();
    switch (locationPath) {
        case "/":
            return "Telemedicina és Betegútirányítási Rendszer | T-DOC";
        case "/cookie-settings":
            return "NOINDEX";
        case "/elofizetesek":
            return "Egészségügyi Szolgáltatás Előfizetés | T-DOC";
        case i18nRoute("routes.howTo"):
            return "Hogyan Működik? Bemutatjuk! | T-DOC";
        case "/orvosaink":
            return "Orvosaink és Szakembereink | T-DOC";
        case "/partner-intezmenyek":
            return "Stratégiai és Partner Intézmények | T-DOC";
        case "/rolunk":
            return "Bemutatkozunk - Ismerj Meg Minket | T-DOC";
        case i18nRoute("routes.faq.base"):
            return "Kérdésed van? Találd Meg A Választ Súgóközpontunkban | T-DOC";
        case i18nRoute("routes.faq.payment"):
            return "Fizetéssel Kapcsolatos Tudnivalók | T-DOC";
        case i18nRoute("routes.faq.registration"):
            return "Regisztrációval Kapcsolatos Tudnivalók | T-DOC";
        case i18nRoute("routes.faq.services"):
            return "Szolgáltatásokkal Kapcsolatos Tudnivalók | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/altalanos-gyogyaszat`:
            return "Általános Gyógyászat, Háziorvos | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/dietetikai-tanacsadas`:
            return "Dietetikai Tanácsadás Online és Személyesen | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/gasztroenterologia`:
            return "Gasztroenterológiai Vizsgálat Online és Személyesen | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/gyermekorvos`:
            return "Gyermekorvosi Vizsgálat Online és Személyesen | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/ortopedia`:
            return "Ortopédiai Vizsgálat Online és Személyesen | T-DOC";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/szuleszet-nogyogyaszat`:
            return "Szülészet-Nőgyógyászat Online és Személyesen | T-DOC";
        case i18nRoute("routes.services.base"):
            return "Ismerd Meg Szolgáltatásainkat | T-DOC";
        case i18nRoute("routes.services.covidDuty"):
            return "Covid-19 Telefonos Ügyelet | T-DOC";
        case i18nRoute("routes.services.hotline"):
            return "Hotline: A Személyes Egészségügyi Asszisztens | T-DOC";
        case i18nRoute("routes.services.outPatient"):
            return "Időpontfoglalás Szakorvoshoz | T-DOC";
        case i18nRoute("routes.services.secondOpinion"):
            return "Szakorvosi Másodvélemény 72 Órán Belül | T-DOC";
        case i18nRoute("routes.services.chat"):
            return "Orvos Válaszol, Orvosi Chat | T-DOC";
        case i18nRoute("routes.services.duty"):
            return "Általános orvosi konzultáció | T-DOC";
        case i18nRoute("routes.services.videoConsultation"):
            return "Telemedicina, Videókonzultáció Orvossal | T-DOC";
        case "/ugyeletkereso":
            return "Országos Ügyeletkereső, Ügyelet a Közelben | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/ful-orr-gegeszet`:
            return "Fül-orr-gégészet Vizsgálat, Online Szakorvosi Konzultáció  | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/gyermekpszichologia`:
            return "Gyermekpszichológia, Online Szakorvosi Konzultáció  | T-DOC";
        case "kteruletek/urologia":
            return "Urológia Vizsgálat, Online Szakorvosi Konzultáció  | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/endokrinologia`:
            return "Endokrinológia Vizsgálat, Online Szakorvosi Konzultáció  | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/kardiologia`:
            return "Kardiológia Vizsgálat, Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/neurologia`:
            return "Neurológia Vizsgálat, Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}`:
            return "Szakterületek ABC Sorrendben | T-DOC";
        case i18nRoute("routes.services.covidHotline"):
            return "Covid-19 Hotline | T-DOC";
        case i18nRoute("routes.services.covidChatStart"):
            return "Covid-19 Orvosi Chat | T-DOC";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/allergologia-klinikai-immunologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/andrologia`:
            return "Andrológia Vizsgálat, Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/angiologia`:
            return "Angiológia Vizsgálat, Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/audiologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/belgyogyaszat`:
            return "Belgyógyászat Vizsgálat, Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/belgyogyaszati-immunologia`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/bor-es-nemibeteg-ellatas`:
            return "Bőr- és Nemibeteg Ellátás, Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/borgyogyaszat`:
            return "Bőrgyógyászat Vizsgálat, Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/csecsemo-gyermek-ful-orr-gegeszet`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/csecsemo-gyermekkardiologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/diabetologia`:
            return "Diabetológia Vizsgálat és Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/egynapos-sebeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/ersebeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/fejlodesneurologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/fizioterapia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/foglalkozas-orvostan`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gerincsebeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-diabetologia`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/gyermek-endokrinologia`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/gyermek-ful-orr-gegeszet`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/gyermek-gasztroenterologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-kardiologia`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/gyermek-klinikai-szakpszichologus`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-nogyogyaszat`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-ortopedia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-reumatologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-sebeszet`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/gyermek-tudogyogyaszat`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-urologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermekborgyogyaszat`:
            return "Gyermekbőrgyógyászat Vizsgálat, Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/gyermekgyogyaszat`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermekneurologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyogytorna`:
            return "Gyógytorna, Online Gyógytorna Órák | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/hasi-sebeszet`:
            return "Hasi Sebészet és Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/haziorvostan`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/hematologia`:
            return "Hematológia Vizsgálat és Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/hepatologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/immunologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/kezsebeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/logopedia`:
            return "Logopédia, Online Logopédiai Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/meddosegi-specialista`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/neonatologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/nogyogyaszat`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/nogyogyaszati-onkologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/ortoped-sebeszet`:
            return "Ortopéd Sebészet és Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/pajzsmirigy-sebeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/proktologia`:
            return "Proktólógia Vizsgálat, Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/pszichologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/radiologia`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/reprodukcios-endoszkopos-sebeszeti-szakerto`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/repuloorvostan`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/sebeszet`:
            return "Sebészeti Vizsgálat és Online Szakorvosi Konzultáció | T-DOC";
        case `${i18nRoute("routes.qualifications.base")}/sportorvostan`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/szedules-ambulancia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/szemeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/szonografia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/szuleszno`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/ultrahang`:
            return "";
        default:
            return "T-DOC";
    }
};

export const useGetTitle = (locationPath) =>
    useSwitchTitle(locationPath) || "T-DOC";

export const useSwitchDescription = (locationPath) => {
    const { i18nRoute } = useTranslation();

    switch (locationPath) {
        case "/":
            return "21. századi hybrid egészségügyi szolgáltatás mely összehangolja a modern telemedicina elemeket a klasszikus személyes vizsgálatokkal és konzultációval ";
        case "/cookie-settings":
            return "";
        case "/elofizetesek":
            return "T-DOC magán egészségügy előfizetések. Három választható csomag, korlátlan egészségügyi hotline-nal, kedvező áron. Válaszd az egészséget!";
        case i18nRoute("routes.howTo"):
            return "Rövid leírás a T-DOC egészségügyi szolgáltatásainak működéséről és használatáról. Átlátható, pontokba szedett útmutató a T-DOC szolgáltatásairól.";
        case "/orvosaink":
            return "A T-DOC és stratégiai partnerintézmények magasan képzett, ügyfélközpontú szakorvosai és szakemberei.";
        case "/partner-intezmenyek":
            return "Személyes vizit esetén megszervezzük időpontod stratégia és partner intézményeinknél. Magasan képzett, ügyfélközpontú szakembergárda.";
        case "/rolunk":
            return "Ismerd meg a T-DOC történetét és céljait. A T-DOC egy 21. századi, hybrid egészségügyi szolgáltató, mely ötvözi az offline és online szolgáltatásokat.";
        case i18nRoute("routes.faq.base"):
            return "Súgóközpontunkban szolgáltatásokkal, fizetéssel, regisztrációval kapcsolatos kérdésekre megtalálhatod a választ. ";
        case i18nRoute("routes.faq.payment"):
            return "Hogyan történik a fizetés? Bankkártya hozzáadással, egyszeri fizetéssel és előfizetéssel kapcsolatos kérdéseidre itt megtalálhatod a választ. ";
        case i18nRoute("routes.faq.registration"):
            return "Hogyan tudok regisztrálni? Regisztrációval, regisztráció megerősítésével és adatok megadásával kapcsolatos kérdéseidre itt megtalálhatod a választ. ";
        case i18nRoute("routes.faq.services"):
            return "Milyen típusú szolgáltatások vannak, és azok hogyan működnek? Keresd meg kérdéseidre a választ a szolgáltatásokkal kapcsolatos súgóközpontunkban.";
        case `${i18nRoute("routes.qualifications.base")}/altalanos-gyogyaszat`:
            return "Általános egészségügyi kérdéseid vannak? Nem tudod elérni házirorvosod? Vedd fel a kapcsolatot háziorvos kollégánkkal akár azonnal. ";
        case `${i18nRoute("routes.qualifications.base")}/dietetikai-tanacsadas`:
            return "Speciális, egyéni étrendre van szükséged? Konzultálj a T-DOC dietetikusaival dietetikai tanácsadás keretén belül! ";
        case `${i18nRoute("routes.qualifications.base")}/gasztroenterologia`:
            return "Haspuffadás, gyomorégés, székrekedés és hasi fájdalmak esetén konzultálj a T-DOC gasztroenterológus csapatával! Gasztroenterológia az év 365 napján.";
        case `${i18nRoute("routes.qualifications.base")}/gyermekorvos`:
            return "Gyermekorvost keres? Gyors segítségre van szüksége? Konzultáljon online vagy személyesen gyermekorvosainkkal. Csecsemő- és gyermekgyógyászat.";
        case `${i18nRoute("routes.qualifications.base")}/ortopedia`:
            return "Ortopédia szakrendelés magas tapasztalattal rendelkező ortopéd szakorvosokkal, orvosi chat, videókonzultáció és személyes vizit  keretén belül.";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/szuleszet-nogyogyaszat`:
            return "Nőgyógyászati panasz esetén keresse fel online vagy személyesen nőgyógyász szakorvos kollégáinkat. Nőgyógyászati vizsgálatok és konzultáció.";
        case i18nRoute("routes.services.base"):
            return "Online orvos – nálunk megtalálod! Beszélj telefonon, vagy videókonzultáción keresztül orvosainkkal, vagy válaszd az online orvosi chat platformot.";
        case i18nRoute("routes.services.covidDuty"):
            return "Koronavírusra utaló tüneteid vannak? Nem tudod eldönteni mit tegyél covid-19 fertőzöttként? Hívd covid-19 általános orvosi konzultációt.";
        case i18nRoute("routes.services.hotline"):
            return "Eleged van bolyongásból az egészségügyi rendszerben? Legyen saját egészségügyi asszisztensed! T-DOC Hotline - 365 nap, napi 12 óra.";
        case i18nRoute("routes.services.outPatient"):
            return "Foglalj gyorsan és egyszerűen időpontot szakorvosi vizsgálatra. Nem tudod milyen szakorvosra lenne szükséged? Segítünk!";
        case i18nRoute("routes.services.secondOpinion"):
            return "Szakorvosi másodvélemény a legjobbaktól. Szeretnéd kikérni egy másik orvos véleményét, mielőtt döntenél egészségeddel kapcsolatban? T-DOC másodvélemény, ";
        case i18nRoute("routes.services.chat"):
            return "Kérdésed van? Az orvos válaszol! 48 órán belül orvosi segítséget kaphatsz üzenet formájában, 100% diszkrécióval.  ";
        case i18nRoute("routes.services.duty"):
            return "Általános orvosi konzultáció 08:00 – 20:00 óra között. Hirtelen jelentkező panaszok esetén hívja Általános orvosi konzultációt az év 365 napján.";
        case i18nRoute("routes.services.videoConsultation"):
            return "Biztonságos és kényelmes telemedicina, online videókonzultáció bárhol is tartózkodj. Online orvosi konzultáció előre egyeztetett időpontban.";
        case "/ugyeletkereso":
            return "Már több mint 300 ügyelet az országos ügyeletkereső térképen. Keresd meg gyorsan és egyszerűan a hozzád legközelebbi orvosi ügyeletet.";
        case `${i18nRoute("routes.qualifications.base")}/ful-orr-gegeszet`:
            return "Fül-orr-gégészet konzultáció az év bármely napján! Hosszabb ideje tartó orrdugulás, torok- és mandulaproblémák, fülfájdalom esetén fordulj hozzánk!";
        case `${i18nRoute("routes.qualifications.base")}/gyermekpszichologia`:
            return "Gyermekpszichológia online konzultáció a gyermek egészséges testi- és lelki fejlődéséért! Azonnali orvosi segítség az ország bármely pontjáról.";
        case "kteruletek/urologia":
            return "Urológiai problémával kapcsolatos kérdésed van? Konzultálj gyorsan és egyszerűen urológus szakorvossal akár 24 órán belül!";
        case `${i18nRoute("routes.qualifications.base")}/endokrinologia`:
            return "Endokrinológia a T-DOC-kal! Bárhol is vagy, endokrinológiai konzultációnk online formában mindig elérhető számodra.";
        case `${i18nRoute("routes.qualifications.base")}/neurologia`:
            return "Neurológiai panaszok esetén forduljon neurológus szakorvos kollégáinkhoz személyes vizit vagy online konzultáción keresztül. ";
        case `${i18nRoute("routes.qualifications.base")}`:
            return "Keresd meg a megfelelő szakorvosi vizsgálatot szakterület választónk segítségével. ";
        case i18nRoute("routes.services.covidHotline"):
            return "Iránymutatást, tájékoztatást szeretnél kapni Covid-19-el kapcsolatban? Vedd fel Covid-19 Hotline-al a kapcsolatot. ";
        case i18nRoute("routes.services.covidChatStart"):
            return "Covid-19-el kapcsolatos kérdésed van? Küld el üzenet formában szakorvosunknak, aki rövid időn belül megválaszolja kérdéseid. ";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/allergologia-klinikai-immunologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/andrologia`:
            return "Andrológia szakorvosi vizsgálatok és online konzultációs lehetőség. Foglalj időpontot andrológiai személyes vizitre, vagy egyeztess szakorvossal online.";
        case `${i18nRoute("routes.qualifications.base")}/angiologia`:
            return "Angiológiai vizsgálat magasan képzett szakorvosoknál. Konzultálj online vagy személyesen angiológus orvossal akár 48 órán belül.";
        case `${i18nRoute("routes.qualifications.base")}/audiologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/belgyogyaszat`:
            return "Konzultálj egyszerűen és gyorsan belgyógyász szakorvossal. Belgyógyászati szakorvosi vizsgálatok és online konzultáció. ";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/belgyogyaszati-immunologia`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/bor-es-nemibeteg-ellatas`:
            return "Nemibeteg ellátás és online szakorvosi konzultáció 100% diszkréció mellett. A nemi betegség kezelése nem várhat, keresse fel szakorvosunkat.";
        case `${i18nRoute("routes.qualifications.base")}/borgyogyaszat`:
            return "Magasan képzett, sok évnyi tapasztalattal bíró bőrgyógyász szakorvosok várják személyes és online konzultációra egyaránt. ";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/csecsemo-gyermek-ful-orr-gegeszet`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/csecsemo-gyermekkardiologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/diabetologia`:
            return "I. és II. típusú diabétesz kezelése. Konzultálj diabetológus szakorvossal személyesen vagy online. Cukorbetegséggel kapcsolatos szakorvosi konzultáció.";
        case `${i18nRoute("routes.qualifications.base")}/egynapos-sebeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/ersebeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/fejlodesneurologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/fizioterapia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/foglalkozas-orvostan`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gerincsebeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-diabetologia`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/gyermek-endokrinologia`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/gyermek-ful-orr-gegeszet`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/gyermek-gasztroenterologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-kardiologia`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/gyermek-klinikai-szakpszichologus`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-nogyogyaszat`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-ortopedia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-reumatologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-sebeszet`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/gyermek-tudogyogyaszat`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermek-urologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermekborgyogyaszat`:
            return "Kiütések, hólyagosodás, bőrpír esetén konzultájon gyermekbőrgyógyász szakorvos kollégánkkal személyesen van online konzultáció keretében.";
        case `${i18nRoute("routes.qualifications.base")}/gyermekgyogyaszat`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyermekneurologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/gyogytorna`:
            return "Gyógytorna otthonod kényelméből videókonzultáción keresztül. Folytasd felépülésed otthon, szakember felügyeletével. ";
        case `${i18nRoute("routes.qualifications.base")}/hasi-sebeszet`:
            return "Hasi sebészet szakorvosi konzultáció személyesen és videókonzultáción keresztül. Professzionális, magasan képzett hasi sebész szakorvosok.";
        case `${i18nRoute("routes.qualifications.base")}/haziorvostan`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/hematologia`:
            return "Konzultálj személyesen hematológus szakorvossal, vagy kérd laboreredményed kiértékelését egyszerűen és gyorsan online.";
        case `${i18nRoute("routes.qualifications.base")}/hepatologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/immunologiav`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/kardiologia`:
            return "Szív és keringésrendszeri problémák esetén konzultálj magasan képzett kardiológus szakorvosainkkal személyesen vagy videókonzultáción keresztül.";
        case `${i18nRoute("routes.qualifications.base")}/kezsebeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/logopedia`:
            return "Beszédproblémák, raccsolás és selypítés esetén vedd fel időben a kapcsolatot logopédus szakemberekkel. Logopédia online konzultációs lehetőség.";
        case `${i18nRoute("routes.qualifications.base")}/meddosegi-specialista`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/neonatologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/nogyogyaszat`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/nogyogyaszati-onkologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/ortoped-sebeszet`:
            return "Ortopédiai műtétre van szükséged? Vedd fel a kapcsolatot Magyarország legjobb ortopéd sebészeivel. Konzultálj személyesen vagy online!";
        case `${i18nRoute("routes.qualifications.base")}/pajzsmirigy-sebeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/proktologia`:
            return "Magasan képzett, professzionális proktológus szakorvosaink várják jelentkezésed személyesen, vagy online konzultáció keretében.";
        case `${i18nRoute("routes.qualifications.base")}/pszichologia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/radiologia`:
            return "";
        case `${i18nRoute(
            "routes.qualifications.base"
        )}/reprodukcios-endoszkopos-sebeszeti-szakerto`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/repuloorvostan`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/sebeszet`:
            return "Sebész szakorvosi konzultáció személyesen és videókonzultáción keresztül. Professzionális, magasan képzett sebész szakorvosok.";
        case `${i18nRoute("routes.qualifications.base")}/sportorvostan`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/szedules-ambulancia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/szemeszet`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/szonografia`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/szuleszno`:
            return "";
        case `${i18nRoute("routes.qualifications.base")}/ultrahang`:
            return "";
        default:
            return "T-DOC";
    }
};

export const useGetDescription = (locationPath) =>
    useSwitchDescription(locationPath) || "T-sdf";
