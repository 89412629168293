import cn from "classnames";
import useAuth from "../../../features/auth/useAuth";
import Sidebar from "../molecules/Sidebar";
import classes from "./InnerLayout.module.css";

const InnerLayout = ({ children, hasSidebar = true }) => {
    const { isAuthenticated } = useAuth();

    if (!children) {
        return null;
    }

    return (
        <section
            className={cn(
                classes.layout,
                isAuthenticated
                    ? classes.authenticatedLayout
                    : classes.unAuthenticatedLayout
            )}
        >
            {isAuthenticated && hasSidebar && <Sidebar />}
            <div className={classes["overflow-scroll"]}>{children}</div>
        </section>
    );
};

export default InnerLayout;
